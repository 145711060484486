import React, { ReactNode } from 'react';
import { Language, T } from '../../../Translate';
import styles from './PublicLayout.module.scss';

import { History } from 'history';
import { PageInfo } from '../../../pages/Page';
import { NotificationContainer } from 'react-notifications';
import { PublicHeader } from './PublicHeader';

interface PublicLayoutProps {
  history: History;
  page: PageInfo;
  onLanguageChanged: (language: Language) => void;
  children: ReactNode;
  person?: boolean;
  ypresPeaceLogo?: boolean;
}
export const PublicLayout = (props: PublicLayoutProps) => {
  const { page, onLanguageChanged, children, person, ypresPeaceLogo } = props;

  return (
    <div className={styles.layout}>
      <NotificationContainer />
      <div className='container'>
        <PublicHeader
          page={page}
          onLanguageChanged={onLanguageChanged}
          person={person}
          ypresPeaceLogo={ypresPeaceLogo}
        />
      </div>
      {children}
    </div>
  );
}
