import React from 'react';
import {
  IMilitaryEntityStructureEvent,
  MilitaryEntityEvent,
  IMilitaryEntityTrackEvent
} from '../../models/MilitaryEntity';
import api from '../../api';
import { T } from '../../Translate';
import { EditStructureEventModal } from './EditStructureEventModal';
import { MilitaryEntityEventView } from './MilitaryEntityEventView';
import { EditTrackEventModal } from './EditTrackEventModal';
import { ConfirmationModal } from '../../modals/Confirmation';
import { NotificationManager } from 'react-notifications';
import { useModalContext } from '../../modals/ModalContext';

interface MilitaryEventEditorProps {
  militaryEntityId?: string;
  index: number;
  events: MilitaryEntityEvent[];
  setEvents: (events: MilitaryEntityEvent[]) => void;
}
export const MilitaryEventEditor = (props: MilitaryEventEditorProps) => {
  const { militaryEntityId, index, events, setEvents } = props;
  const modals = useModalContext();

  const updateEvent = async (index: number, event: MilitaryEntityEvent) => {
    const newEvents = [...events];
    newEvents[index] = event;
    if (militaryEntityId) {
      const updatedEntity = await api.updateMilitaryEntity({
        _id: militaryEntityId,
        history: newEvents
      });
      NotificationManager.success(T('page.militaryEntity.event.updated'));
      setEvents(updatedEntity.history || []);
    } else {
      setEvents(newEvents);
    }
  };
  
  const handleClickedEditEvent = async (event: MilitaryEntityEvent) => {
    const index = events.findIndex(x => x === event);
    if (index < 0)
      return;
    
    if (event.type === 'track') {
      const newEvent = await modals.show<IMilitaryEntityTrackEvent|null>(props => (
        <EditTrackEventModal
          militaryEntityId={militaryEntityId}
          event={event}
          index={index}
          {...props}
        />
      ));
      if (newEvent)
        updateEvent(index, newEvent);
    } else {
      const newEvent = await modals.show<IMilitaryEntityStructureEvent|null>(props => (
        <EditStructureEventModal
          militaryEntityId={militaryEntityId}
          event={event}
          index={index}
          {...props}
        />
      ));
      if (newEvent)
        updateEvent(index, newEvent);
    }
  };

  const handleClickedRemoveEvent = async (event: MilitaryEntityEvent) => {
    const confirmed = await modals.show<boolean>(props => (
      <ConfirmationModal
        title={T('page.militaryEntity.removeEvent.title')}
        message={T('page.militaryEntity.removeEvent.message')}
        warning={T('generic.cannotUndo')}
        acceptText={T('generic.action.remove')}
        acceptStyle='danger'
        rejectText={T('generic.action.cancel')}
        {...props}
      />
    ));
    if (!confirmed)
      return;
    
    const index = events.indexOf(event);
    if (index < 0)
      return;
    
    const newEvents = events.filter((x, i) => i !== index);
    if (militaryEntityId) {
      await api.updateMilitaryEntity({
        _id: militaryEntityId,
        history: newEvents
      });
      NotificationManager.success(T('page.militaryEntity.removeEvent.done'));
    }
    setEvents(newEvents);
  };

  return (
    <MilitaryEntityEventView
      key={index}
      event={events[index]}
      onClickedEdit={handleClickedEditEvent}
      onClickedRemove={handleClickedRemoveEvent}
    />
  );
}
