import React from 'react';
import { useElementID } from '../../utils/ElementID';
import { InvalidFeedback } from './InvalidFeedback';

interface PasswordInputProps {
  labelColumns?: number;

  name: string;
  label: string;
  value: string;
  error?: string;
  readOnly?: boolean;
  validated?: boolean;

  score?: number;

  onChange?: (value: string) => void;
  onPressedEnter?: () => void;
}

export const PasswordInput = (props: PasswordInputProps) => {
  const {
    name,
    labelColumns = 4,
    label,
    value,
    error,
    readOnly,
    validated,
    score,
    onChange,
    onPressedEnter
  } = props;

  const handleKeyPress = onPressedEnter && ((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter')
      onPressedEnter();
  }); 
  const handleChange = onChange && ((e: React.SyntheticEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  });
  const id = useElementID('input');
  const inputClass = readOnly ? 'form-control-plaintext' : (error ? 'form-control is-invalid' : (validated ? 'form-control is-valid' : 'form-control'));
  return (
    <div className="form-group row">
      <label htmlFor={id} className={`col-sm-${labelColumns} col-form-label`}>{label}</label>
      <div className={`col-sm-${12 - labelColumns}`}>
        <input
          id={id}
          name={name}
          type="password"
          className={inputClass}
          value={value}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
        {score !== undefined && <StrengthBar score={score} />}
        {error && <InvalidFeedback error={error} />}
      </div>
    </div>
  );
}

interface StrengthBarProps {
  score: number;
}

const StrengthBar = (props: StrengthBarProps) => {
  const { score } = props;
  const scoreClass = score < 60 ? 'progress-bar bg-danger' : (score < 80 ? 'progress-bar bg-warning' : 'progress-bar bg-success');
  const scoreCapped = Math.min(100, score);

  return (
    <div className="progress" style={{ height: '0.5rem' }}>
      <div
        className={scoreClass}
        role="progressbar"
        style={{ width: scoreCapped + '%' }}
        aria-valuenow={scoreCapped}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  );
}
