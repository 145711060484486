import React, { useMemo } from 'react';
import { FormCard } from '../../../components/FormCard';
import { Icon } from '../../../components/Icons';
import { SelectInputOnly } from '../../../components/inputs/SelectInput';
import { Person } from '../../../models/Person';
import { personEventSorter, PersonEventType } from '../../../models/PersonEvent';
import { IPersonStory2, PersonStoryTag, translatePersonStoryTag } from '../../../models/PersonStory2';
import { T } from '../../../Translate';
import { TextView } from '../../ViewPerson/TextView';
import EventCheckbox from './EventCheckbox';
import TagView from './TagView';
import CompactCheckboxInput from "../../../components/inputs/CompactCheckboxInput";

interface QualificationsTabProps {
  story?: IPersonStory2;
  person?: Person;
  readOnly: boolean;
  onChanged: (story: IPersonStory2) => void;
}

export default (props: QualificationsTabProps) => {
  const { story, person, readOnly, onChanged } = props;

  const events = useMemo(() => {
    let events = (person && person.events) || [];
    events = events.filter(e => e.type !== PersonEventType.CWXRM);
    events.sort(personEventSorter);
    return events;
  }, [person]);

  const linkedEventIds = (story && story.coupling_use_events_with_places_refs) || [];
  const tags = (story && story.coupling_tags) || [];

  const handleChanged = (checked: boolean, id: string) => {
    const newLinkedEventIds = checked
      ? [...linkedEventIds, id]
      : linkedEventIds.filter(x => x !== id);
    onChanged(Object.assign({}, story, { coupling_use_events_with_places_refs: newLinkedEventIds }));
  };

  const handleChangedOrphan = (checked: boolean, id: string) => {
    const newLinkedEventIds = checked
      ? [...linkedEventIds, id]
      : linkedEventIds.filter(x => x !== id);
    onChanged(Object.assign({}, story, { coupling_use_events_with_places_refs: newLinkedEventIds }));
  };

  const handleTagSelected = (tag: string) => {
    const newTags = [...tags, tag];
    onChanged(Object.assign({}, story, { coupling_tags: newTags }));
  };

  const handleDeleteTag = (tag: string) => {
    const newTags = tags.filter(t => t !== tag);
    onChanged(Object.assign({}, story, { coupling_tags: newTags }));
  };

  const tagOptions = useMemo(() => {
    const result: JSX.Element[] = [
      <option key='' value=''>{T('storytag.select')}</option>
    ];
    for (var name in PersonStoryTag) {
      var value = PersonStoryTag[name as keyof typeof PersonStoryTag];
      if (tags.includes(value))
        continue;

      result.push(<option key={value} value={value}>{translatePersonStoryTag(value)}</option>);
    }
    return result;
  }, [tags]);

  return <>
    <TextView style={{marginBottom: 8}} text={T('personstories.qualifications.info')}/>
    <FormCard icon={Icon.Link} title={T('personstories.qualifications.places.title')}>
      <TextView text={T('personstories.qualifications.places.info')} />
      <div style={{ marginTop: '0.5em'}}>
      {events.filter(ev => ev.place_id !== undefined && ev.extend_place).map(ev => (
        <EventCheckbox
          key={ev._id}
          event={ev}
          onChanged={handleChanged}
          readOnly={readOnly}
          linkedEventIds={linkedEventIds}
        />
      ))}
      </div>
      <div>
        {linkedEventIds.filter(id => !events.find(ev => ev._id === id)).map(id => (
          <CompactCheckboxInput
            key = {id}
            label={T('personstories.qualifications.unknown.event')}
            value={linkedEventIds.includes(id)}
            onChange={checked => handleChangedOrphan(checked, id)}
            readOnly={readOnly}
            labelStyle={{ fontSize: '0.875rem' }}
          />
        ))}
      </div>
      <div style={{ height: '1em' }} />
    </FormCard>
    <FormCard icon={Icon.Tags} title={T('personstories.qualifications.tags')}>
      <TextView text={T('personstories.qualifications.tags.info')}/>
      <div style={{ margin: '0.5em'}}>
        {tags.map(tag => (
          <TagView tag={tag as PersonStoryTag} readOnly={readOnly} onClickedDelete={handleDeleteTag} />
        ))}
      </div>
      {!readOnly && (
        <SelectInputOnly
          value=''
          onChange={handleTagSelected}
          optionElements={tagOptions}
          style={{ width: 250, marginTop: '1em' }}
        />
      )}
    </FormCard>
  </>;
};
