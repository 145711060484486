import {PageProps} from '../Page';
import {CSSProperties, useEffect, useRef, useState} from "react";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {KeyLocation} from "./KeyLocation";
import api from "./api";
import keypin from '../../assets/images/keypin.png';
import {mapboxToken} from '../../assets/settings.json';
import './index.scss'
import {T} from "../../Translate";
import {Language} from "../../models/PersonStory2";

mapboxgl.accessToken = mapboxToken;

const center: google.maps.LatLngLiteral = {lat: 50.85121, lng: 2.88591};

const containerStyle: CSSProperties = {
  width: '100%',
  height: '100%'
};

interface ViewMapKeyLocationPageProps extends PageProps {
  lang: Language
}

export const ViewMapKeyLocationPage = (props: ViewMapKeyLocationPageProps) => {

  const { lang} = props;

  const map = useRef<mapboxgl.Map | null>(null);
  const markersAdded = useRef<boolean | null>(false);
  const [lng, setLng] = useState(center.lng);
  const [lat, setLat] = useState(center.lat);
  const [zoom, setZoom] = useState(14);

  const [markers, setMarkers] = useState<KeyLocation[]>([]);

  const [mapLoaded, setMapLoaded] = useState<boolean>(false);

  useEffect(() => {
    api.getMapLocations().then((locations) => {
      setMarkers(locations)
    })
  }, []);

  const generateHtmlForKeyLocation = (keyLocation: KeyLocation) => {
    // https://namenlijst.net/#/public/key_locations/2000fe68-6720-4ea4-922a-afcb331a5092
    const target: string = `#/public/key_locations/${keyLocation.id}`;
    return `
      <div class="container">
        <div class="row">
          <div class="col-12 text-left mb-2 mt-2"
            <div style="font-size: 16px; font-weight: 600">
              <bold><i class="fas fa-key-skeleton"></i><span>&nbsp;</span>${keyLocation.name}</bold>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-justify">
                <p style="font-size: 12px">
                   ${keyLocation.notes[lang]}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-right right mt-4">
                <p style="font-size: 12px">
                    <a href="${target}" target="_blank">
                        ${T("page.public.keylocationmap.openkeylocation")}
                    </a>
                </p>
            </div>
        </div>
    </div>`
  }

  const placeMarkers = (map: mapboxgl.Map, markers: KeyLocation[]) => {
    if (markers.length === 0) return;

    if (markersAdded.current) {
      return;
    }

    markersAdded.current = true;

    map.loadImage(
      keypin,
      function (error, image) {
        if (error || image === undefined) throw error;
        map.addImage("custom-marker", image);

        map.addLayer({
          id: 'markers',
          type: 'symbol',
          source: 'markers',
          layout: {
            'icon-image': 'custom-marker',
            'icon-anchor': 'bottom',
            // 'text-field': ['get', 'title'],
            // 'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            // 'text-offset': [0, 1.25],
            // 'text-anchor': 'top'
          }
        });
    
        map.on('click', 'markers', (e) => {
          // Copy coordinates array.
          if (!(e.features && e.features.length > 0))
            return;
    
          const feature = e.features[0];
          const geometry = feature.geometry
    
          let coordinates: number[] = [];
          if ("coordinates" in geometry) {
            coordinates = geometry.coordinates as number[];
          }
          const name = feature.properties?.title;
          const html = feature.properties?.html;
    
          new mapboxgl.Popup({closeButton:false})
            .setLngLat([coordinates[0], coordinates[1]])
            .setHTML(html)
            .setOffset(60)
            .addTo(map);
        });
    
      }
    );

    map.addSource('markers', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: markers.map(marker => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [marker.position.lon, marker.position.lat]
          },
          properties: {
            title: marker.name,
            name: marker.name,
            id: marker.id,
            html: generateHtmlForKeyLocation(marker)
          }
        }))
      }
    });
  };


  useEffect(() => {
    if (markers && mapLoaded && map.current)
      placeMarkers(map.current!, markers)
  }, [markers]);

  useEffect(() => {
    if (markers && mapLoaded && map.current)
      placeMarkers(map.current!, markers)
  }, [mapLoaded]);

  const handleContainerRef = (ref: HTMLDivElement) => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: ref,
      style: 'mapbox://styles/bitmunks/clt1iuj9x00hs01mebh1e8fgo',
      center: [lng, lat],
      zoom: zoom
    });
    map.current!.on('load', () => {
      setMapLoaded(true);
    });
  };

  return (
    <div style={containerStyle}>
      <div style={containerStyle} ref={handleContainerRef} className="map-container"/>
    </div>
  );
}
