import React, { useMemo } from 'react';

import { PageProps, Page } from '../Page';
import { Icon } from '../../components/Icons';
import { personEventSorter } from '../../models/PersonEvent';
import api from '../../api';
import { PersonQueryOptions, Gender } from '../../models/Person';
import { EventView } from './EventView';
import { HeaderView } from './HeaderView';
import { T } from '../../Translate';
import { PersonFeedbackModal } from './PersonFeedbackModal';
import { TopTabs, TopTab } from '../../components/TopTabs';
import { ContactPersonEventView } from "../EditContactPerson/ContactPersonEventView";
import {
  usePersonContactMoments,
  usePerson,
  usePersonRelatedDocuments,
  usePersonStories
} from '../../FunctionalAPI';
import { NoDataView } from "./NoDataView"
import ProjectsTab from './tabs/ProjectsTab';
import RelatedPersonsTab from './tabs/RelatedPersonsTab';
import { PageID, getPageVia } from '../../utils/PageLinking';
import { PersonStoriesTab } from "./tabs/PersonStoriesTab";
import { IPersonStory2 } from "../../models/PersonStory2";
import ListDocumentsTab from "../ViewPlace/ListDocumentsTab";

interface ViewPersonPageProps extends PageProps {
  id: string;
}

export const ViewPersonPage = (props: ViewPersonPageProps) => {
  const { id, loading, modals, history, via } = props;

  const user = api.user;
  const person = usePerson(loading, id, [PersonQueryOptions.EventsWithPlaces, PersonQueryOptions.Documents, PersonQueryOptions.RelationPersons]);
  const contactMoments = usePersonContactMoments(loading, user.isGuest() ? undefined : id);
  const documents = usePersonRelatedDocuments(loading, id);
  const personStories = usePersonStories(loading, user.isGuest() ? undefined : id);

  const contactMomentViews = useMemo(() => {
    if (contactMoments.length === 0) {
      return (
        <NoDataView text={T('page.person.noContacts')}/>
      );
    }

    return contactMoments.map(cm => (
      <ContactPersonEventView key={cm.contactPersonId} event={cm} readonly={true} />
    ));
  }, [contactMoments]);

  const eventViews = useMemo(() => {
    if (!person)
      return <div />;

    person.events.sort(personEventSorter);
    return person.events.map(event => <EventView key={event._id} event={event} />);
  }, [person]);

  const handleClickedFeedback = () => {
    if (!person)
      return;

    modals.show(props => <PersonFeedbackModal person={person} {...props} />);
  };

  const handlePersonStorySelected = (personStory?: IPersonStory2) => {
    if (!personStory)
      return;

    const via = PageID.ViewPerson
    history.push(`/personstories/${personStory._id}/edit?via=${via}~${person?.id}`);

  }

  const renderEventsTab = () => <>{eventViews}</>;
  const renderContactsTab = () => contactMomentViews;
  const renderRelationsTab = () => {
    return <RelatedPersonsTab relations={person?.relations || []} gender={person?.gender || Gender.Undefined} />;
  }
  const renderDocumentationTab = () => {
    if (!person) {
      return (<></>)
    } else {
      return (
        <ListDocumentsTab
          ref_id={person.id}
          ref_name={person.name}
          ref_type='person'
          history={history}
          loading={loading}
          modals={modals}
          via={getPageVia(PageID.ViewPerson, person.id)}
          readonly={true}
          pageStateKey='editPlaceDocumentation'
        />
      );
    }
  };
  const renderProjectsTab = () => {
    return <ProjectsTab projectMemberships={person?.projects || []} />;
  }
  const renderStoriesTab = () => {
    return <PersonStoriesTab personStories={personStories} onPersonStorySelected={handlePersonStorySelected}/>;
  }

  const tabs: TopTab[] = [];
  tabs.push({
    id: 'events',
    title: T('page.person.tab.events'),
    icon: Icon.Info,
    content: renderEventsTab,
    badgeContent: () => person?.events ? person?.events?.length.toString() : "0"
  });
  tabs.push({
    icon: Icon.Link,
      title: T('page.person.tab.relations'),
    id: 'relations',
    content: renderRelationsTab,
    badgeContent: () => person?.relations.length.toString() ?? "0"
  });
  tabs.push({
    id: 'documentation',
    title: T('page.person.tab.documentation'),
    icon: Icon.Document,
    content: renderDocumentationTab,
    badgeContent: () => documents.length.toString()
  });
  if (!user.isGuest()) {
    tabs.push({
      id: 'contacts',
      title: T('page.person.tab.contacts'),
      icon: Icon.AddressBook,
      content: renderContactsTab,
      badgeContent: () => contactMoments.length.toString()
    });
    tabs.push({
      id: 'stories',
      title: T('page.person.tab.stories'),
      icon: Icon.Books,
      content: renderStoriesTab,
      badgeContent: () => personStories.length.toString()
    });
    tabs.push({
      id: 'projects',
      title: T('page.person.tab.projects'),
      icon: Icon.Book,
      content: renderProjectsTab,
      badgeContent: () => person && person.projects && person.projects.length.toString()
    });
  }

  const title = person ? person.firstName + ' ' + person.lastName.toUpperCase() : '';
  return (
    <Page
      id={PageID.ViewPerson}
      entity={id}
      icon={Icon.User}
      title={title}
      via={via || PageID.ListPersons}
    >
      {person && (
        <div style={{ marginTop: 15 }}>
          <HeaderView user={user} person={person} handleClickedFeedback={handleClickedFeedback}/>
          <TopTabs
            id='personTabs'
            defaultActiveKey='events'
            tabs={tabs}
            storeKey='viewPerson'
          />
        </div>
      )}
    </Page>
  );
}
