import React from 'react';
import styles from './AddPhotoControl.module.scss';

interface SimpleImagePreviewProps {
  url: string;
  label?: string;
  onClick?: () => void;
}

export default (props: SimpleImagePreviewProps) => {
  const { url, label, onClick } = props;

  return (
    <div className={styles.photo}>
      <div className={styles.wrapper} onClick={onClick}>
        <img src={url} alt='' />
        <span>{label || <>&nbsp;</>}</span>
      </div>
    </div>
  );
}
