import React, { useMemo, useState } from 'react';
import { History } from 'history';
import { Button } from 'react-bootstrap';
import api from '../../../api';
import { FormCard } from '../../../components/FormCard';
import { Icon } from '../../../components/Icons';
import { SortDirection, Table } from '../../../components/table/Table';
import { ConfirmationModal } from '../../../modals/Confirmation';
import { useModalContext } from '../../../modals/ModalContext';
import { IPersonStory2 } from '../../../models/PersonStory2';
import { T } from '../../../Translate';
import { getPageLinkWithVia, PageID } from '../../../utils/PageLinking';
import { getPersonStoryColumns } from '../../ListPersonStories/Columns';
import SearchStoryModal from './SearchStoryModal';
import ListMatchingPersonStories from "./ListMatchingPersonStories";
import { Loading } from "../../../utils/Loading";
import { TextStyles } from "../../../config/styles";

interface LinkedStoriesTabProps {
  history: History;
  loading: Loading;
  story?: IPersonStory2;
  readOnly: boolean;
  onChanged: (story: IPersonStory2) => void;
  onMatchingItemsChanged: (numberOfItems: number) => void;
}

const PageSize = 10;
export default (props: LinkedStoriesTabProps) => {
  const { history, loading, story, readOnly, onChanged } = props;

  const modals = useModalContext();
  const user = api.user;

  const links = story?.coupling_stories || [];
  const [page, setPage] = useState(0);
  const [sortDirection, setSortDirection] = useState(SortDirection.Up);
  const [sortColumn, setSortColumn] = useState('name');

  const handleSortChanged = (column: string, direction: SortDirection) => {
    setSortColumn(column);
    setSortDirection(direction);
  };

  const displayedLinks = useMemo(
    () => links.slice(PageSize * page, PageSize * (page + 1)),
    [links, page]
  );

  const excludedPersonStoryIds: string[] = useMemo(
    () => story ? [story._id, ...links.map(item  => item._id)] : [],
    [links, story]
  );

  const columns = useMemo(() => {
    const handleClickedDelete = async (personStory: IPersonStory2) => {
      const confirmed = await modals.show<boolean>(props => (
        <ConfirmationModal
          title={T('page.personstory.linkTable.remove.title')}
          message={T('page.personstory.linkTable.remove.message', { name: personStory.calculated_full_name || '?' })}
          {...props}
        />
      ));
      if (confirmed) {
        const newLinks = links.filter(link => link._id !== personStory._id);
        const linkIds = story?.coupling_story_refs || [];
        const newLinkedIds = linkIds.filter(id => id !== personStory._id);
        onChanged(Object.assign({}, story, {
          coupling_stories: newLinks,
          coupling_story_refs: newLinkedIds
        }));
      }
    };

    return getPersonStoryColumns(!readOnly && user.canEdit(), handleClickedDelete);
  }, [story, links, user, modals, readOnly, onChanged]);

  const handleClickedAdd = async () => {
    if (!story)
      return;

    const selectedStory = await modals.show<IPersonStory2|undefined>(props => (
      <SearchStoryModal withChildStoryOnly={false} excludePersonStoryIdList={excludedPersonStoryIds} {...props} />
    ));
    if (!selectedStory)
      return;

    const newLinks = [...links, selectedStory];
    const linkIds = story?.coupling_story_refs || [];
    const newLinkedIds = [...linkIds, selectedStory._id];
    onChanged(Object.assign({}, story, {
      coupling_stories: newLinks,
      coupling_story_refs: newLinkedIds
    }));
  };

  const button = (
    <Button onClick={handleClickedAdd}>
      <i className={Icon.Plus} />
      <div style={{ display: 'inline-block', width: '0.5em' }} />
      {T('page.personstory.linkTable.add')}
    </Button>
  );

  const handleClickedRow = (personStory: IPersonStory2) => {
    const link = getPageLinkWithVia(PageID.EditPersonStory, personStory._id, PageID.EditPersonStory, story?._id);
    history.push(link);
  };


  const renderMatchingPersonStories = () => {
    return (
      <>
        <p style={{...TextStyles.text.medium}}>
          {T('page.personstory.tab.automatic.links.explain')}
        </p>
        <FormCard
          icon={Icon.Link}
          title={T('page.personstory.tab.automatic.links')}
        >
          <ListMatchingPersonStories
            personStory={story}
            history={history}
            loading={loading}
            modals={modals}
            onMatchingItemsChanged={props.onMatchingItemsChanged}
            pending={[]}
          />
        </FormCard>
      </>
    );
  }

  const renderManualPersonStories = () => {
    return <>
      <p style={{...TextStyles.text.medium}}>
        {T('page.personstory.tab.manual.links.explain')}
      </p>
      <FormCard
        icon={Icon.Link}
        title={T('page.personstory.tab.manual.links')}
        buttons={!readOnly && button}
      >
        <Table
          columns={columns}
          displayedItems={displayedLinks}
          noun='personStory'
          onPageChanged={setPage}
          sortDirection={sortDirection}
          sortColumn={sortColumn}
          onSortChanged={handleSortChanged}
          page={page}
          pageSize={10}
          totalItems={links.length}
          onClickItem={handleClickedRow}
        />
      </FormCard>
    </>;
  }

  return <>
    {renderMatchingPersonStories()}
    {renderManualPersonStories()}
  </>;
}
