import React from 'react';
import { Icon } from './Icons';
import { Button } from 'react-bootstrap';
import { DocumentFileUploader, PageFileUploader } from './FileUploader';
import { IDocument, IDocumentElement } from '../models/Document';
import { DocumentElementPreview } from '../pages/EditCollectionDocument/DocumentElementPreview';
import { IDocumentTag } from '../models/DocumentTag';
import { NotificationManager } from 'react-notifications';
import { T } from '../Translate';


interface DocumentPreviewProps {
  index: number;
  element: IDocumentElement;
  onClickedDelete: () => void;
}
export const DocumentPreview = (props: DocumentPreviewProps) => {
  const { index, element, onClickedDelete } = props;
  return (
    <div style={{ cursor: 'pointer', marginRight: 15, position: 'relative' }}>
      <DocumentElementPreview
        index={index}
        element={element}
        selected={false}
        readOnly={true}
        onClick={() => {}}
        onSelected={() => {}}
      />
      <Button
        variant='link'
        onClick={onClickedDelete}
        style={{ position: 'absolute', right: 0, bottom: 0 }}
      >
        <i className={Icon.TimesCircle} />
      </Button>
    </div>
  );
}

interface DocumentUploadListViewProps {
  tags: IDocumentTag[];
  documents: IDocument[];
  feedbackSession: string;

  onClickedDelete: (document: IDocument, index: number) => void;
  onCreated: (document: IDocument) => void;
  onUpdated: (document: IDocument) => void;
}
export const DocumentUploadListView = (props: DocumentUploadListViewProps) => {
  const { tags, documents, onClickedDelete, onCreated, onUpdated, feedbackSession } = props;

  const handleCreated = (document: IDocument|'NOK') => {
    if (document === 'NOK') {
      NotificationManager.error(T('generic.errors.fileUploadFailed'));
    } else {
      onCreated(document);
    }
  }

  const handleUpdated = (document: IDocument|'NOK') => {
    if (document === 'NOK') {
      NotificationManager.error(T('generic.errors.fileUploadFailed'));
    } else {
      onUpdated(document);
    }
  }

  const documentViews = documents.reduce<JSX.Element[]>((elements, document) => {
    document.elements.forEach((element, index) => {
      elements.push(
        <DocumentPreview
          key={document._id + '#' + index}
          index={index}
          element={element}
          onClickedDelete={() => onClickedDelete(document, index)}
        />
      );
    });
    return elements;
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {documentViews}
      {documents.length === 0 ? (
        <DocumentFileUploader
          tags={tags}
          feedbackSession={feedbackSession}
          onSaved={handleCreated}
          buttonStyle={{ width: 150, height: 200, borderRadius: 0 }}
        />
      ) : (
        <PageFileUploader
          documentId={documents[0]._id}
          documentElement={documents[0].elements.length}
          feedbackSession={feedbackSession}
          onSaved={handleUpdated}
          buttonStyle={{ width: 150, height: 200, borderRadius: 0 }}
        />
      )}
    </div>
  );
}
