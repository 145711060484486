import React from 'react';
import moment from 'moment';
import { ITableColumn, IColumnOptions, DefaultColumnOptions, ICellProps } from "../../components/table/Fields";

export class TimestampTableColumn<T> implements ITableColumn<T> {
  id: string;
  title: string;
  getter: (item: T) => string;
  options: IColumnOptions;

  constructor(
    id: string,
    title: string,
    getter: (item: T) => string,
    options?: Partial<IColumnOptions>
  ) {
    this.id = id;
    this.title = title;
    this.getter = getter;
    this.options = Object.assign({}, DefaultColumnOptions, options);
  }

  render(item: T, props: ICellProps): JSX.Element {
    const value = this.getter(item);
    return <td key={this.id} {...props}>{value === undefined ? 'N/A' : value}</td>;
  }

  _parseTimestamp(item: T) {
    const value = this.getter(item);
    return moment(value, 'DD-MM-YY HH:mm').unix();
  }

  sort = (a: T, b: T): number => {
    return this._parseTimestamp(a) - this._parseTimestamp(b);
  }
}
