import React from 'react';
import { ContactMomentContribution } from '../../models/ContactPerson';
import { Icon } from '../../components/Icons';
import { Button } from 'react-bootstrap';
import { PageID } from '../../utils/PageLinking';
import PageLink from '../../components/PageLink';

interface ContributionViewProps {
  contribution: ContactMomentContribution;
  onClickedDelete?: () => void;
}
export const ContributionView = (props: ContributionViewProps) => {
  const { contribution, onClickedDelete } = props;
  const icon = contribution.type === 'person' ? Icon.User : Icon.Document;
  const linkPage = contribution.type === 'person' ? PageID.ViewPerson : PageID.EditDocument;
  return (
    <div>
      <PageLink style={{fontSize: 14, textDecorationLine: 'underline'}} page={linkPage} param={contribution.targetId}>
        <i className={icon} />
        {' '}
        {contribution.name || contribution.targetId}
      </PageLink>
      {onClickedDelete && (
        <Button variant='link' onClick={onClickedDelete} style={{color: "red", paddingLeft: 8, paddingTop:12}}>
          <i className={Icon.TimesCircle} />
        </Button>
      )}
    </div>
  );
};
