import { getFileTypeFromMimeType, getLargeIcon, getTinyIcon } from "../utils/MimeTypes";

export interface IAttachment {
  _id: string;
  collection?: string;
  collection_id?: string;
  mimetype: string;
  extension: string;
  position: string;
  description: string;
  urls: {
    original: string;
    tiny?: string;
    preview?: string;
  }
}

export function getAttachmentPreviewUrl(attachment: IAttachment) {
  if (!attachment.urls.preview && (!attachment.mimetype || attachment.mimetype === 'image/jpeg' || attachment.mimetype === 'image/png')) {
    return `https://s3-eu-west-1.amazonaws.com/bitmunks-ff/attachments_l_${attachment._id}.png`;
  }

  const type = getFileTypeFromMimeType(attachment.mimetype);
  return attachment.urls.preview || getLargeIcon(type);
};

export function getAttachmentTinyUrl(attachment: IAttachment) {
  if (!attachment.urls.preview && (!attachment.mimetype || attachment.mimetype === 'image/jpeg' || attachment.mimetype === 'image/png')) {
    return `https://s3-eu-west-1.amazonaws.com/bitmunks-ff/attachments_xs_${attachment._id}.png`;
  }

  const type = getFileTypeFromMimeType(attachment.mimetype);
  return attachment.urls.tiny || getTinyIcon(type);
};
