import React, { useCallback } from 'react';
import api from '../../../api';
import { useElementID } from '../../../utils/ElementID';
import { InputGroup } from '../../../components/inputs/InputGroup';
import { StringAutocomplete } from '../../../components/inputs/StringAutocomplete';
import { T } from '../../../Translate';

interface RegimentInputProps {
  value: string;
  onChange: (value: string) => void;
  army: string;
}
/**
 * Input field for a regiment name. Supports autocomplete, which suggests names
 * from existing regiments in the military entities collection with the
 * specified army.
 */
export const RegimentInput = (props: RegimentInputProps) => {
  const { value, onChange, army } = props;

  const search = useCallback(
    value => api.getRegimentList({ army }, value, 0, 10),
    [army]
  );

  return (
    <StringAutocomplete
      placeholder={T('event.militaryRegiment')}
      value={value}
      onChange={onChange}
      search={search}
    />
  );
}

interface RegimentInputGroupProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  army: string;
}
export const RegimentInputGroup = (props: RegimentInputGroupProps) => {
  const { value, onChange, army } = props;
  const id = useElementID('regimentInput');
  return (
    <InputGroup id={id} {...props}>
      <RegimentInput value={value} onChange={onChange} army={army} />
    </InputGroup>
  );
}
