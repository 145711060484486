import React, { ReactNode } from 'react';
import styles from './ButtonRow.module.scss';
import { ModalFooter } from 'react-bootstrap';

export const ButtonRow = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <div className={styles.buttonRow}>
      {children}
    </div>
  );
}

export const ButtonRowTop = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <div className={styles.buttonRowTop}>
      {children}
    </div>
  );
}

export const BottomButtonRow = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <div className={styles.bottomRow}>
      <div className='container'>
        <div className={styles.buttonRow}>
          {children}
        </div>
      </div>
    </div>
  );
}

export const ButtonRowModalFooter = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <ModalFooter className={styles.buttonRow}>
      {children}
    </ModalFooter>
  );
}
