import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import api from '../../../api';
import { Person } from '../../../models/Person';
import { IPersonStory2 } from '../../../models/PersonStory2';
import { T } from '../../../Translate';
import { useElementID } from '../../../utils/ElementID';

interface SearchStoryInputProps {
  onSelected: (story: IPersonStory2) => void;
  excludePersonStoryIdList?: string[];
}

interface SearchResult {
  item: IPersonStory2;
  name: string;
}

export default (props: SearchStoryInputProps) => {
  const { onSelected } = props;

  const id = useElementID('typeahead');

  const [options, setOptions] = useState<SearchResult[]>([]);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const handleSearch = (query: string) => {
    if (query === '')
      return;

    setLoadingCounter(counter => counter + 1);

    api.findPersonStories2({}, 0, 20, undefined, query, ['person'])
      .then(stories => {
        // filter out the stories that must be excluded
        let filteredStories = stories.data;
        if (props.excludePersonStoryIdList) {
          filteredStories = filteredStories.filter(item => !props.excludePersonStoryIdList?.includes(item._id))
        }
        setOptions(filteredStories.map(item => ({
          item,
          name: (item.person && new Person(item.person).name) || '?'
        })));
      })
      .finally(() => {
        setLoadingCounter(counter => counter - 1);
      });
  };


  return (
    <AsyncTypeahead
      delay={300}
      useCache={true}
      defaultInputValue=''
      filterBy={()=>true}
      id={id}
      options={options}
      labelKey='name'
      selected={[]}
      placeholder={T('component.search.typeToSearch')}
      onSearch={handleSearch}
      onChange={selected => selected.length > 0 && onSelected(selected[0].item)}
      isLoading={loadingCounter > 0}
    />
  );
}
