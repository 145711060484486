import React, { CSSProperties } from 'react';
import { IPersonStory2 } from "../../../models/PersonStory2";
import { T } from "../../../Translate";
import { Form } from "react-bootstrap";
import { TextStyles } from "../../../config/styles";
import CompactCheckboxInput from '../../../components/inputs/CompactCheckboxInput';


export interface SummaryCheckboxProps {
  personStory?: IPersonStory2
}

const checkStyle: CSSProperties = {
  fontSize: 14,
  color: '#9A9A9A'
}

export const SummaryCheckbox = (props: SummaryCheckboxProps) => {
  const { personStory } = props;

  const publishTargets = personStory?.targets || [];
  const published = personStory?.published_date !== null && personStory?.published_date !== undefined;
  const publishedToMuseum = published && publishTargets.includes('museum');
  const publishedToKey = published && publishTargets.includes('key');  

  return (
    <Form.Group>
      <CompactCheckboxInput
        label={T('personstories.check.published.museum')}
        value={publishedToMuseum}
        readOnly={true}
        labelStyle={checkStyle}
      />
      <CompactCheckboxInput
        label={T('personstories.check.published.key')}
        value={publishedToKey}
        readOnly={true}
        labelStyle={checkStyle}
      />
      {personStory?.published_date !== null && personStory?.published_date !== undefined &&
          <Form.Text style={{...TextStyles.text.medium}}>
          {T('personstories.check.published.description.published') +
              new Date(personStory.published_date).toLocaleDateString() + ' ' +
              new Date(personStory.published_date).toLocaleTimeString()}
          </Form.Text>
      }
      {!published && (
        <Form.Text style={{...TextStyles.text.medium}}>
          {T('personstories.check.published.description.unpublished')}
        </Form.Text>
      )}
    </Form.Group>
  );
};
