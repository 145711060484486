import React, { ReactNode } from 'react';
import { Colors } from "../../../config/styles";

const textStyle = {
  fontSize:18,
  textDecorationLine: 'uppercase',
  color: Colors.lightGray,
}

interface EmptyMessageProps {
  message: string
  height?: string | number
  children?: ReactNode
}

const EmptyMessage = (props: EmptyMessageProps) => {
  const { message, children } = props;

  const height = props.height ?? '100%'

  return (
    <div style={{height:height, display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <p style={textStyle}>
        {children ? children :  message}
      </p>
    </div>)
}

export default EmptyMessage;
