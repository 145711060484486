import React from 'react';
import { Badge } from 'react-bootstrap';
import { Icon } from '../../../components/Icons';
import { PersonStoryTag } from '../../../models/PersonStory2';
import { T, TranslationKey } from '../../../Translate';
import { classes } from '../../../utils/Styles';
import styles from './TagView.module.scss';

interface TagViewProps {
  tag: PersonStoryTag;
  readOnly: boolean;
  onClickedDelete: (tag: PersonStoryTag) => void;
}

export default (props: TagViewProps) => {
  const { tag, readOnly, onClickedDelete } = props;

  return <>
    <Badge pill variant='secondary'>
      {T('storytag.' + tag as TranslationKey)}
      {' '}
      {!readOnly && <i className={classes(Icon.Times, styles.remove)} onClick={() => onClickedDelete(tag)} />}
    </Badge>
    &nbsp;
  </>;
}
