import React from 'react';
import { IPersonStory2 } from "../../../models/PersonStory2";
import { Col, Row } from "react-bootstrap";
import { T } from "../../../Translate";
import { ButtonLink } from "../../../components/ButtonLink";
import { PersonStoryHelper } from "../../../models/wrappers/PersonStoryHelper";

interface PersonStoriesTabProps {
  personStories: IPersonStory2[];
  onPersonStorySelected: (personStory?:IPersonStory2) => void;
}
export const PersonStoriesTab = (props: PersonStoriesTabProps) => {

  const textStyle = {
    fontSize:18,
    textDecorationLine: 'uppercase',
    color: '#9A9A9A',
  }

  const { personStories, onPersonStorySelected } = props;

  const links = personStories.map(personStory => (
    <ButtonLink
      key={personStory._id}
      title={PersonStoryHelper.personName(personStory)}
      onClick={() => {onPersonStorySelected(personStory)}}>
      {PersonStoryHelper.personName(personStory)}
    </ButtonLink>
  ));

  if (personStories.length === 0) {
    return (
      <Row  style={{marginTop: 50}}>
        <Col sm={3}/>
        <Col sm={6} className={'d-flex justify-content-center'}>
          <p style={textStyle}>
            {T("page.person.tab.personStories.noStoriesAvailable")}
            {links}
          </p>
        </Col>
        <Col sm={3}/>
      </Row>
    )
  } else {
    return (
      <Row  style={{marginTop: 50}}>
        <Col sm={3}/>
        <Col sm={6} className={'d-flex justify-content-center'}>
          <p style={textStyle}>
            {T("page.person.tab.personStories.storyAvailable")}
            {links}
          </p>
        </Col>
        <Col sm={3}/>
      </Row>
    )
  }
};
