import React from 'react';
import { IPersonProjectMembership } from '../../../models/Person';
import { useProjects } from '../../../FunctionalAPI';
import { FormCard } from '../../../components/FormCard';
import { Icon } from '../../../components/Icons';
import { T } from '../../../Translate';
import { NoDataView } from '../NoDataView';
import { useAppContext } from '../../../utils/AppContext';

interface ProjectTabProps {
  projectMemberships: IPersonProjectMembership[];
}
export default (props: ProjectTabProps) => {
  const { projectMemberships } = props;
  const { loading } = useAppContext();
  const projects = useProjects(loading);

  if (projectMemberships.length === 0)
    return <NoDataView text={T('page.person.tab.projects.none')}/>;
  
  return (
    <FormCard icon={Icon.Books} title={T('page.person.tab.projects')}>
      <ul>
        {projectMemberships.map(membership => {
          const project = projects.find(p => p._id === membership.project);
          return <li key={membership.project}>{project ? project.title : '...'}</li>
        })}
      </ul>
    </FormCard>
  );
}
