import React, { useMemo } from 'react';
import { IPersonRelation, PersonRelationType, Person, Gender } from '../../models/Person';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { T } from '../../Translate';
import { Icon } from '../../components/Icons';
import { PageID } from '../../utils/PageLinking';
import PageLink from '../../components/PageLink';

interface LinkedPersonViewProps {
  gender: Gender;
  relation: IPersonRelation;
  index: number;
  onChange: (index: number, value: IPersonRelation) => void;
  onClickedDelete: (index: number) => void;
}
const PersonRelationTypes = [
  PersonRelationType.Family,
  PersonRelationType.Married,
  PersonRelationType.Parent,
  PersonRelationType.Child,
  PersonRelationType.Sibling,
  PersonRelationType.Friend,
  PersonRelationType.Fieldmate,
  PersonRelationType.Cousin,
  PersonRelationType.Grandchild,
  PersonRelationType.Grandparent,
  PersonRelationType.Other
];


export const LinkedPersonView = (props: LinkedPersonViewProps) => {
  const { gender, relation, index, onChange, onClickedDelete } = props;
  const relationTypeOptions = useMemo(
    () => [
      ...PersonRelationTypes.map(type => ({value: type, title: Person.describePersonalRelationType(type, gender)}))
    ].map(item => <option key={item.value} value={item.value}>{item.title}</option>),
    [gender]
  );

  const handleRelationChanged = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    const newRelation = Object.assign(
      {},
      relation,
      { relation: e.currentTarget.value as PersonRelationType }
    );
    onChange(index, newRelation);
  };
  const handleCommentsChanged = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    const newRelation = Object.assign(
      {},
      relation,
      { comments: e.currentTarget.value }
    );
    onChange(index, newRelation);
  };
  const handleClickedDelete = () => onClickedDelete(index);

  return <>
    <Card style={{paddingLeft: 8, paddingRight: 8, paddingTop: 8, paddingBottom: 2, backgroundColor: '#f5f5f5'}}>
    <Row>
      <Col sm={6} style={{paddingLeft: 20}}>
        <PageLink page={PageID.ViewPerson} param={relation.person_id} style={{marginLeft: 10}}>
          {relation.person && (relation.person.surname + ' ' + relation.person.familyname)}
        </PageLink>
        <Button
          variant='link'
          onClick={handleClickedDelete}
          style={{ paddingBottom: 5 }}>
          <i className={Icon.TimesCircle} />
        </Button>
          <select
            className='form-control'
            value={relation.relation}
            onChange={handleRelationChanged}
            defaultValue={PersonRelationType.Other}
          >
            {relationTypeOptions}
          </select>
      </Col>
      <Col sm={6}>
        <textarea
          value={relation.comments}
          placeholder={T('person.relation.notes.placeholder')}
          className='form-control'
          rows={3}
          onChange={handleCommentsChanged}
        />
      </Col>
    </Row>
    </Card>
  </>;
}
