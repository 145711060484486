import React from 'react';
import { LoadingItem } from '../utils/Loading';
import { TranslationKey, listOf, T } from '../Translate';
import { Icon } from './Icons';
import { Card } from 'react-bootstrap';
import { Backdrop } from './Backdrop';

interface LoadingModalProps {
  loading: LoadingItem[];
}
export const LoadingModal = (props: LoadingModalProps) => {
  const { loading } = props;

  const grouped = loading.reduce((result, item) => {
    const index = result.findIndex(x => x.action === item.action);
    if (index >= 0)
      result[index].items.push(item.displayName);
    else
      result.push({ action: item.action, items: [item.displayName] });
    return result;
  }, [] as { action: TranslationKey, items: string[] }[]);

  // only use the first element in the group to show as loading text
  const filteredGroup = grouped.length > 1 ? [grouped[0]] : grouped
  // and only show the first item in the action items list
  const messages = filteredGroup.map(entry => <p key={entry.action}>{T(entry.action, { x: listOf([entry.items[0]])})}</p>)
  return (
    <Backdrop>
      <Card>
        <Card.Body style={{ textAlign: 'center' }}>
          <i className={`${Icon.Loading} fa-lg`} style={{ margin: 10 }} />
          {messages}
        </Card.Body>
      </Card>
    </Backdrop>
  )
};
