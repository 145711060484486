import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { IPlace } from "../../models/Place";
import {
  getEventIcon,
  getMajorPriorityForEventType,
  PersonEventType
} from "../../models/PersonEvent";
import React from "react";
import { Colors, TextStyles } from "../../config/styles";
import { T } from "../../Translate";
import styles from './index.module.scss';


export interface PlaceEventsViewProps {
  place: IPlace
  onClickedPlaceEvent: (place: IPlace, type: PersonEventType, count: number) => void
}


export const PlaceEventsView = (props: PlaceEventsViewProps) => {

  /** Props **/

  const { place, onClickedPlaceEvent } = props;


  /** event handling **/

  const handleClickedPlaceEvents = (place: IPlace, type: PersonEventType, count: number) => {
    onClickedPlaceEvent(place, type, count)
  }

  /** Helper functions **/

  const sortedEventKeys = (keys: string[]) : string[] => {
    const result = keys.sort( (obj1, obj2) => {
      if (getMajorPriorityForEventType(obj1 as PersonEventType) > getMajorPriorityForEventType(obj2 as PersonEventType))
        return 1
      else if (getMajorPriorityForEventType(obj1 as PersonEventType) < getMajorPriorityForEventType(obj2 as PersonEventType))
        return -1
      else
        return 0
    })
    return result;
  }

  /** Rendering **/

  const events = place.extend_events_by_type || {};
  const badges: JSX.Element[] = [];
  const eventKeys = sortedEventKeys(Object.keys(events))
  for (var key in eventKeys) {
    const type = eventKeys[key] as PersonEventType;
    const count = events[type];
    badges.push(
      <Badge
        key={key}
        className={styles.personEventBadge}
        onClick={(e: React.SyntheticEvent<HTMLElement>) => {
          e.preventDefault();
          e.stopPropagation();
          document.body.click();
          handleClickedPlaceEvents(place, type, count);
        }}
      >
        <i className={getEventIcon(type)}/>{' ' + events[type].toString()}
      </Badge>
    );
  }
  const badgesComponent = <><div style={{display: "flex", flexDirection: "column", justifyContent:'flex-start', alignItems:'baseline'}}>
    {badges}
  </div></>;

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title>
        <span style={TextStyles.text.medium}>{T('event.select')}</span>
      </Popover.Title>
      <Popover.Content>
        {badgesComponent}
      </Popover.Content>
    </Popover>
  );

  // place event view with popover for when there is more then 1 event

  if (eventKeys.length > 0) {

    const firstType = eventKeys[0] as PersonEventType;
    const firstCount = events[firstType];


    const firstEventBadge = (
      <>
        {/*Show the first event badge*/}
        <Badge
          key={'FIRST_EVENTS_BADGE' + place._id}
          className={styles.personEventBadge}
          onClick={(e: React.SyntheticEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            document.body.click();
            handleClickedPlaceEvents(place, firstType, firstCount);
          }}>
          <i className={getEventIcon(firstType)}/>{' ' + firstCount.toString()}
        </Badge>{/*Show the second badge with the extra number of events*/}
      </>)

    const secondEventBadge = (eventKeys.length > 1) ?
      <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
        <Badge
          key={'EVENTS_BADGE' + place._id}
          className={styles.personEventBadge}>
          +{eventKeys.length - 1}
        </Badge>
      </OverlayTrigger> :
      <></>

    return (<>{firstEventBadge}{secondEventBadge}</>)

  } else {

      // place event view when no events are connected to the place
      const badgeNoEvents = (
        <Badge
          key={'NO_EVENTS_BADGE' + place._id}
          style={{margin: 3, fontSize: '100%', backgroundColor: Colors.lightGray, color: Colors.white}}
        >
          {T('generic.none')}
        </Badge>
      )

      return <>{badgeNoEvents}</>
    }

};
