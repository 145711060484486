import React, { useMemo, useState } from 'react';
import {
  IBasicSpecialFunctionInput,
  IRepeatableSpecialFunctionInput,
  ISelectSpecialFunctionInput,
  ISpecialFunctionDateArgument,
  ISpecialFunctionDateTimeArgument,
  ISpecialFunctionRepeatableArgument,
  ISpecialFunctionTextArgument,
  ITextSpecialFunctionInput,
  SpecialFunctionArgument,
  SpecialFunctionInput,
  TextMode,
  ValidationErrors
} from '../../models/SpecialFunction';
import { CheckboxInput } from '../../components/inputs/CheckboxInput';
import { ISelectOption, SelectInput } from '../../components/inputs/SelectInput';
import { COUNTRY_SELECT_OPTIONS } from '../../Countries';
import { DateInput, DateInputValue, emptyDate } from '../../components/inputs/DateInput';
import { DateTimeInput, DateTimeInputValue, emptyDateTime } from '../../components/inputs/DateTimeInput';
import { T, TranslationKey } from '../../Translate';
import { SpecialFunctionTypeSelector } from './SpecialFunctionTypeSelector';
import { getErrorDescription, ValidationError } from '../../models/ValidationResult';
import { useElementID } from '../../utils/ElementID';
import { PlaceInputComponent } from '../../components/inputs/PlaceInput';
import { Place } from '../../models/Place';
import { IMemorial } from '../../models/Memorial';
import { MemorialInputComponent } from '../../components/inputs/MemorialInput';
import { Icon } from '../../components/Icons';

const nameTranslations: { [key: string]: TranslationKey } = {
  limit: "specialFunctions.params.limit",
  firstName: "specialFunctions.params.firstName",
  lastName: "specialFunctions.params.lastName",
  firstNameAlt: "specialFunctions.params.firstNameAlt",
  lastNameAlt: "specialFunctions.params.lastNameAlt",
  bornFrom: "specialFunctions.params.bornFrom",
  bornTo: "specialFunctions.params.bornTo",
  diedFrom: "specialFunctions.params.diedFrom",
  diedTo: "specialFunctions.params.diedTo",
  from: "specialFunctions.params.from",
  to: "specialFunctions.params.to",
  user: "specialFunctions.params.user",
  collection: "specialFunctions.params.collection",
  place: "specialFunctions.params.place",
  startFrom: "specialFunctions.params.startFrom",
  startTo: "specialFunctions.params.startTo",
  endFrom: "specialFunctions.params.endFrom",
  endTo: "specialFunctions.params.endTo",
  type: "specialFunctions.params.type",
  army: "specialFunctions.params.army",
  regiment: "specialFunctions.params.regiment",
  unit: "specialFunctions.params.unit",
  unit_nr: "specialFunctions.params.unitNr",
  victim_type: "specialFunctions.params.victimType",
  victim_type_details: "specialFunctions.params.victimTypeDetails",
  address: "specialFunctions.params.address",
  dateFrom: "specialFunctions.params.dateFrom",
  dateTo: "specialFunctions.params.dateTo",
  moveType: "specialFunctions.params.moveType",
  filters: "specialFunctions.params.filters",
  school: "specialFunctions.params.school",
  direction: "specialFunctions.params.direction",
  memorial: "specialFunctions.params.memorial",
  number: "specialFunctions.params.number",
  decoration: "specialFunctions.params.decoration",
  citation: "specialFunctions.params.citation",
  company: "specialFunctions.params.company",
  reason: "specialFunctions.params.reason", /*typologie van gebeurtenissen*/
  wound: "specialFunctions.params.wound",
  cause: "specialFunctions.params.cause",
  profession: "specialFunctions.params.profession",
  ageFrom: "specialFunctions.params.ageFrom",
  ageTo: "specialFunctions.params.ageTo",
  gender: "specialFunctions.params.gender",
  topo: "specialFunctions.params.topo",
  sub: "specialFunctions.params.sub",
  rank: "specialFunctions.params.rank",
  ref: "specialFunctions.params.ref",
  kind: "specialFunctions.params.kind",
  civilian: "specialFunctions.params.civilian",
  message: "specialFunctions.params.message",
  description: "specialFunctions.params.description",
  division: "specialFunctions.params.division",
  brigade: "specialFunctions.params.brigade",
  jailedType: "specialFunctions.params.jailedType",
  bornPlace: "specialFunctions.params.bornPlace",
  livingPlace: "specialFunctions.params.livingPlace",
  dataset: "specialFunctions.params.dataset",
  project: "specialFunctions.params.project",
  country: "specialFunctions.params.country",
  entityId: "specialFunctions.params.entityId",
  personId: "specialFunctions.params.personId"
};
interface RepeatableTranslation {
  header: TranslationKey;
  typeHeader: TranslationKey;
  types: { [key: string]: TranslationKey };
}
const repeatableTranslations: { [key: string]: RepeatableTranslation } = {
  filters: {
    header: 'specialFunctions.repeatable.filters.header',
    typeHeader: 'specialFunctions.repeatable.filters.typeHeader',
    types: {
      'born': 'specialFunctions.repeatable.filters.type.born',
      'injured': 'specialFunctions.repeatable.filters.type.injured',
      'school': 'specialFunctions.repeatable.filters.type.school',
      'memorated': 'specialFunctions.repeatable.filters.type.memorated',
      'enlisted': 'specialFunctions.repeatable.filters.type.enlisted',
      'decorated': 'specialFunctions.repeatable.filters.type.decorated',
      'work': 'specialFunctions.repeatable.filters.type.work',
      'where': 'specialFunctions.repeatable.filters.type.where',
      'event': 'specialFunctions.repeatable.filters.type.event',
      'died': 'specialFunctions.repeatable.filters.type.died',
      'jailed': 'specialFunctions.repeatable.filters.type.jailed'
    }
  }
};
interface RepeatableTranslated {
  header: string;
  typeHeader: string;
  types: { [key: string]: string };
}
function translateRepeatable(input: IRepeatableSpecialFunctionInput) {
  const translation = repeatableTranslations[input.name];
  if (translation) {
    const types: { [key: string]: string } = {};
    input.types.forEach(type => types[type.name] = translation.types[type.name]
      ? T(translation.types[type.name])
      : type.name);
    return {
      header: T(translation.header),
      typeHeader: T(translation.typeHeader),
      types
    };
  } else {
    const types: { [key: string]: string } = {};
    input.types.forEach(type => types[type.name] = type.name);

    return {
      header: input.name,
      typeHeader: input.name + ': ',
      types
    };
  }
}

const selectInputTranslations: { [key: string]: { [key: string]: TranslationKey } } = {
  gender: {
    any: 'generic.filter.any',
    MALE: 'gender.male',
    FEMALE: 'gender.female',
    UNDEFINED: 'gender.undefined'
  },
  victim_type: {
    all: 'generic.filter.any',
    MILITARY: 'victimType.military',
    CIVILIAN: 'victimType.civilian'
  },
  victim_type_details: {
    all: 'generic.filter.any',
    UNKNOWN: 'victimSubType.unknown',
    DEPORTATED: 'victimSubType.deportated',
    EXECUTED: 'victimSubType.executed'
  },
  filters_type: {
    born: 'event.born',
    injured: 'event.injured',
    school: 'event.school',
    memorated: 'event.memorated',
    enlisted: 'event.military',
    decorated: 'event.decorated',
    work: 'event.work',
    where: 'event.where',
    event: 'page.advancedSearch.addFilter.event',
    died: 'event.died',
    jailed: 'event.jailed'
  },
  reason: {
    any: 'generic.filter.any',
    'enlisted': 'militaryEventType.enlisted',
    'active service': 'militaryEventType.activeService',
    'posted to': 'militaryEventType.postedTo',
    'attached to': 'militaryEventType.attachedTo',
    'renumbered': 'militaryEventType.renumbered',
    'transferred to': 'militaryEventType.transferredTo',
    'promoted': 'militaryEventType.promoted',
    'any active service': 'militaryEventType.anyActiveService'
  },
  moveType: {
    any: 'generic.filter.any',
    homeaddress: 'whereEventType.homeAddress',
    fugitive: 'whereEventType.fugitive',
    evacuated: 'whereEventType.evacuated',
    deportated: 'whereEventType.deportated',
    moved: 'whereEventType.moved'
  },
  kind: {
    any: 'generic.filter.any',
    none: 'memoratedKind.none',
    current: 'memoratedKind.current',
    original: 'memoratedKind.original'
  },
  military_event_type: {
    any: 'generic.filter.any',
    structure: 'militaryEntityEventType.sub.structure',
    attachment: 'militaryEntityEventType.sub.attached'
  },
  jailedType: {
    any: 'generic.filter.any',
    jailed: 'jailedType.jailed',
    captive: 'jailedType.captive'
  },
  dataset: {
    any: 'dataset.any',
    namenlijst: 'dataset.namelist',
    deaths: 'dataset.deaths',
    survivors: 'dataset.survivors',
    stories: 'dataset.stories',
    with_documentation: 'dataset.withDocumentation',
    contacts: 'dataset.contacts',
    irish_memorial_records: 'dataset.imr',
    military: 'dataset.military'
  },
  military_event_track_type: {
    frontier_service: 'militaryEntityTrackType.frontierService',
    support: 'militaryEntityTrackType.support',
    half_rest: 'militaryEntityTrackType.halfRest',
    rest: 'militaryEntityTrackType.rest'
  },
  kiosk: {
    '1': 'kiosk.1',
    '2': 'kiosk.2',
    '3': 'kiosk.3',
    '4': 'kiosk.4'
  },
  project: {
    'any': 'generic.filter.any'
  },
  relation: {
    'married': 'relation.married',
    'parent': 'relation.parent',
    'child': 'relation.child',
    'sibling': 'relation.sibling',
    'friend': 'relation.friend',
    'fieldmate': 'relation.fieldmate',
    'cousin': 'relation.cousin',
    'other': 'relation.other',
    'grandchild': 'relation.grandchild',
    'grandparent': 'relation.grandparent',
    'family': 'relation.family'
  }
};

function getInputLabel(input: SpecialFunctionInput) {
  return T(nameTranslations[input.name]) || input.name;
}

interface InputProps {
  name: string;
  value?: SpecialFunctionArgument;
  onChange: (name: string, value: SpecialFunctionArgument) => void;
  errors: ValidationErrors;
  renderRepeatable?: (field: IRepeatableSpecialFunctionInput, onClickedAdd: (type: string) => void) => JSX.Element | undefined;
}
export type InputClass = (props: InputProps) => JSX.Element;

export function getSpecialInputClass(input: SpecialFunctionInput): InputClass {
  switch (input.type) {
    case 'text':
      return TextFunctionInput(input as ITextSpecialFunctionInput);
    case 'checkbox':
      return CheckboxFunctionInput(input as IBasicSpecialFunctionInput);
    case 'country':
      return CountryFunctionInput(input as IBasicSpecialFunctionInput);
    case 'date':
      return DateFunctionInput(input as IBasicSpecialFunctionInput);
    case 'datetime':
      return DateTimeFunctionInput(input as IBasicSpecialFunctionInput);
    case 'location':
      return DummyInput;
    case 'select':
      return SelectFunctionInput(input as ISelectSpecialFunctionInput);
    case 'repeatable':
      return RepeatableInput(input as IRepeatableSpecialFunctionInput);
    default:
      return DummyInput;
  }
}

const DummyInput = (props: InputProps) => <div />;

const TextFunctionInput = (input: ITextSpecialFunctionInput) => {
  return (props: InputProps) => {
    const handleChange = (value: ISpecialFunctionTextArgument) =>
      props.onChange(input.name, value);

    return (
      <TextFunctionInputComponent
        input={input}
        value={(props.value || { value: '', mode: TextMode.Head }) as ISpecialFunctionTextArgument}
        onChange={handleChange}
        error={getErrorDescription(props.errors[input.name] as ValidationError)}
      />
    );
  }
}

interface TextFunctionInputComponentProps {
  input: ITextSpecialFunctionInput;
  value: ISpecialFunctionTextArgument;
  error?: string;
  onChange: (value: ISpecialFunctionTextArgument) => void;
}
const TextFunctionInputComponent = (props: TextFunctionInputComponentProps) => {
  const { input, value, error, onChange } = props;
  const label = useMemo(() => getInputLabel(input), [input]);

  const id = useElementID('input');
  const handleModeChanged = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    onChange({ value: value.value, mode: e.currentTarget.value as TextMode });
  };

  return (
    <div className="form-group row" style={{ maxWidth: 600 }}>
      <label htmlFor={id} className={`col-sm-3 control-label`}>{label}:</label>
      <div className='col-sm-6'>
        <TextFunctionInputInnerComponent
          id={id}
          name={input.name}
          input={input}
          value={value}
          error={error}
          onChange={onChange}
        />
        {error && <div className="invalid-feedback" style={{ display: 'inherit' }}>{error}</div>}
      </div>
      <div className='col-sm-3'>
        {value.mode === TextMode.Selected ? (
          <select disabled className='form-control'>
            <option value='selected'>{T('textSearchMode.complete')}</option>
          </select>
        ) : (
          <select value={value.mode} className='form-control' onChange={handleModeChanged}>
            <option value={TextMode.Head}>{T('textSearchMode.head')}</option>
            <option value={TextMode.Tail}>{T('textSearchMode.tail')}</option>
            <option value={TextMode.Complete}>{T('textSearchMode.complete')}</option>
            <option value={TextMode.Contains}>{T('textSearchMode.contains')}</option>
            <option value={TextMode.Empty}>{T('textSearchMode.empty')}</option>
            <option value={TextMode.NotEqual}>{T('textSearchMode.notEqual')}</option>
          </select>
        )}
      </div>
    </div>
  );
}

interface TextFunctionInputInnerComponentProps {
  id: string;
  name: string;
  input: ITextSpecialFunctionInput;
  value: ISpecialFunctionTextArgument;
  error?: string;
  onChange: (value: ISpecialFunctionTextArgument) => void;
}
const TextFunctionInputInnerComponent = (props: TextFunctionInputInnerComponentProps) => {
  const { id, name, input, value, error, onChange } = props;

  const inputClass = error ? 'form-control is-invalid' : 'form-control';
  const handleInputChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onChange({
      value: e.currentTarget.value,
      mode: value.mode === TextMode.Selected ? prevMode : value.mode
    });
  };
  const [place, setPlace] = useState<Place|undefined>(undefined);
  const [memorial, setMemorial] = useState<IMemorial|undefined>(undefined);
  const [prevMode, setPrevMode] = useState<TextMode>(value.mode);

  if (input.autocomplete === 'place') {
    const handlePlaceSelected = (place: Place) => {
      setPlace(place);
      setPrevMode(value.mode);
      onChange({
        value: place.name,
        mode: TextMode.Selected,
        id: place.id
      });
    };
    return (
      <PlaceInputComponent
        value={value.value}
        place={place}
        onChange={v => onChange({ value: v, mode: value.mode })}
        style={{ width: '100%' }}
        onSelected={handlePlaceSelected}
        searchMode={value.mode}
      />
    );
  } else if (input.autocomplete === 'memorial') {
    const handleMemorialSelected = (memorial: IMemorial) => {
      setMemorial(memorial);
      setPrevMode(value.mode);
      onChange({
        value: memorial.name,
        mode: TextMode.Selected,
        id: memorial._id
      });
    };
    return (
      <MemorialInputComponent
        value={value.value}
        memorial={memorial}
        onChange={v => onChange({ value: v, mode: value.mode })}
        onSelected={handleMemorialSelected}
        searchMode={value.mode}
      />
    );
  } else {
    return (
      <input
        id={id}
        name={name}
        type="text"
        className={inputClass}
        value={value.value}
        onChange={handleInputChanged}
      />
    );
  }
}

const CheckboxFunctionInput = (input: IBasicSpecialFunctionInput) => {
  const label = getInputLabel(input);
  return (props: InputProps) => {
    const onChange = (checked: boolean) => props.onChange(input.name, checked);
    return (
      <CheckboxInput
        label={label}
        value={(props.value || false) as boolean}
        onChange={onChange}
        error={getErrorDescription(props.errors[input.name] as ValidationError)}
      />
    );
  };
};

const CountryFunctionInput = (input: IBasicSpecialFunctionInput) => {
  const label = getInputLabel(input);
  return (props: InputProps) => {
    const onChange = (value: string) => props.onChange(input.name, value);
    return (
      <SelectInput
        name={input.name}
        label={label}
        labelColumns={3}
        inputColumns={6}
        value={(props.value || '__') as string}
        onChange={onChange}
        options={COUNTRY_SELECT_OPTIONS}
        error={getErrorDescription(props.errors[input.name] as ValidationError)}
        style={{ maxWidth: 600 }}
      />
    );
  };
};

const DateFunctionInput = (input: IBasicSpecialFunctionInput) => {
  const label = getInputLabel(input);
  return (props: InputProps) => {
    const onChange = (value: DateInputValue) => props.onChange(input.name, value);
    return (
      <DateInput
        name={props.name}
        label={label}
        labelColumns={3}
        value={(props.value || emptyDate()) as ISpecialFunctionDateArgument}
        onChange={onChange}
        style={{ maxWidth: 600 }}
      />
    );
  };
};

const DateTimeFunctionInput = (input: IBasicSpecialFunctionInput) => {
  const label = getInputLabel(input);
  return (props: InputProps) => {
    const onChange = (value: DateTimeInputValue) => props.onChange(input.name, value);
    return (
      <DateTimeInput
        name={props.name}
        label={label}
        value={(props.value || emptyDateTime()) as ISpecialFunctionDateTimeArgument}
        onChange={onChange}
        style={{ maxWidth: 600 }}
      />
    );
  };
};

function getSelectOptionLabel(field: string, value: string) {
  const key: TranslationKey|undefined = (selectInputTranslations[field] || {})[value];
  return key ? T(key) : value;
}
const SelectFunctionInput = (input: ISelectSpecialFunctionInput) => {
  const label = getInputLabel(input);
  const titles = input.titles || {};
  const options = input.options.map(option => (
    <option key={option} value={option}>{titles[option] || getSelectOptionLabel(input.name, option)}</option>
  ));
  return (props: InputProps) => {
    const onChange = (value: string) => props.onChange(input.name, value);
    return (
      <SelectInput
        name={input.name}
        label={label}
        value={(props.value || input.options[0]) as string}
        onChange={onChange}
        optionElements={options}
        labelColumns={3}
        inputColumns={6}
        style={{ maxWidth: 600 }}
      />
    );
  }
}

const RepeatableInput = (input: IRepeatableSpecialFunctionInput) => {
  const translated = translateRepeatable(input);
  const typeOptions = input.types.map(type => ({ value: type.name, title: type.name }));
  return (props: InputProps) => (
    <RepeatableInputComponent
      translation={translated}
      input={input}
      value={props.value as ISpecialFunctionRepeatableArgument[] || []}
      errors={props.errors[input.name] as ValidationErrors[] || []}
      typeOptions={typeOptions}
      onChange={props.onChange}
      renderRepeatable={props.renderRepeatable}
    />
  );
}

interface RepeatableInputComponentProps {
  translation: RepeatableTranslated;
  input: IRepeatableSpecialFunctionInput;
  value: ISpecialFunctionRepeatableArgument[];
  typeOptions: ISelectOption[];
  errors: ValidationErrors[];
  onChange: (name: string, value: ISpecialFunctionRepeatableArgument[]) => void;
  renderRepeatable?: (field: IRepeatableSpecialFunctionInput, onClickedAdd: (type: string) => void) => JSX.Element | undefined;
}
const RepeatableInputComponent = (props: RepeatableInputComponentProps) => {
  const { translation, input, value, typeOptions, onChange, errors, renderRepeatable } = props;

  const handleParameterChanged = (index: number, name: string, val: SpecialFunctionArgument) => {
    const element = value[index];
    const newInputs = Object.assign({}, element.inputs, { [name]: val });
    const newElement = { type: element.type, inputs: newInputs };
    const newValue = value.map((x, i) => i === index ? newElement : x);
    onChange(input.name, newValue);
  };
  const [type, setType] = useState(typeOptions[0].value);
  const handleTypeChanged = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    setType(e.currentTarget.value);
  };
  const handleClickedAdd = () => {
    const newValue = [...value, { type, inputs: {} }];
    onChange(input.name, newValue);
  };
  const handleClickedAdd2 = (type: string) => {
    const newValue = [...value, { type, inputs: {} }];
    onChange(input.name, newValue);
  }
  const handleDelete = (index: number) => {
    const newValue = value.filter((x, i) => i !== index);
    onChange(input.name, newValue);
  }
  return <>
    {value.map((value, index) => (
      <RepeatableInputGroup
        key={index}
        header={translation.typeHeader + translation.types[value.type]}
        input={input}
        value={value}
        index={index}
        errors={errors[index] || {}}
        onChange={handleParameterChanged}
        onDelete={handleDelete}
      />
    ))}
    <h4>{translation.header}</h4>
    {(renderRepeatable && renderRepeatable(input, handleClickedAdd2)) || (
      <SpecialFunctionTypeSelector
        label={translation.typeHeader}
        value={type}
        onChange={handleTypeChanged}
        options={typeOptions}
        onClickedAdd={handleClickedAdd}
      />
    )}
  </>;
}

interface RepeatableInputGroupProps {
  header: string;
  input: IRepeatableSpecialFunctionInput;
  value: ISpecialFunctionRepeatableArgument;
  errors: ValidationErrors;
  index: number;
  onChange: (index: number, parameter: string, value: SpecialFunctionArgument) => void;
  onDelete: (index: number) => void;
}
const RepeatableInputGroup = (props: RepeatableInputGroupProps) => {
  const { header, input, value, index, onChange, onDelete, errors } = props;
  const type = value.type;

  const inputs: [SpecialFunctionInput, InputClass][] = useMemo(() => {
    const typeObject = input.types.find(t => t.name === type);
    if (!typeObject)
      return [];

    return typeObject.inputs.map(i => [i, getSpecialInputClass(i)]);
  }, [input, type]);
  const handleChange = (name: string, value: SpecialFunctionArgument) => {
    onChange(index, name, value);
  };
  const handleDelete = () => onDelete(index);
  return <div className='function-input-group'>
    <h4>{header} <i className={Icon.TimesCircle} style={{ color: '#888', cursor: 'pointer' }} onClick={handleDelete} /></h4>
    <div className='function-input-group-inputs'>
      {inputs.map(([input, Input]) => (
        <Input
          key={input.name}
          name={input.name}
          value={value.inputs[input.name]}
          onChange={handleChange}
          errors={errors}
        />
      ))}
    </div>
  </div>;
}
