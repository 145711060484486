import React, { CSSProperties } from 'react';
import styles from './LabelView.module.scss';

interface LabelViewProps {
  text: String;
}

export const LabelView = (props: LabelViewProps) => {
  const { text } = props;
  return (
    <span className={styles.label}>{text}</span>
  );
}

export const TitleLabelView = (props: LabelViewProps) => {
  const style: CSSProperties = {
    fontSize: 14,
    color: '#9A9A9A',
    textTransform: "uppercase",
  }

  const { text } = props;
  return (
    <span style={style}>{text}</span>
  );
}

export const TextLabelView = (props: LabelViewProps) => {
  const style: CSSProperties = {
    fontSize: 14,
    color: '#9A9A9A',
  }

  const { text } = props;
  return (
    <span style={style}>{text}</span>
  );
}
