import React from 'react';
import { IPersonProjectMembership } from '../../models/Person';
import { IProject } from '../../models/Project';
import { FormCard } from '../../components/FormCard';
import { Icon } from '../../components/Icons';
import { T } from '../../Translate';
import { CheckboxInput } from '../../components/inputs/CheckboxInput';

interface PersonProjectsTabProps {
  memberships: IPersonProjectMembership[];
  projects: IProject[];
  onMembershipsChanged: (memberships: IPersonProjectMembership[]) => void;
}
export const PersonProjectsTab = (props: PersonProjectsTabProps) => {
  const { memberships, projects, onMembershipsChanged } = props;

  const handleChecked = (project: IProject, checked: boolean) => {
    const wasMember = memberships.find(m => m.project === project._id) !== undefined;
    if (checked === wasMember)
      return;

    if (checked) {
      const newMemberships = [...memberships, { project: project._id, notes: '' }];
      onMembershipsChanged(newMemberships);
    } else {
      const newMemberships = memberships.filter(m => m.project !== project._id);
      onMembershipsChanged(newMemberships);
    }
  };
  const checkboxes = projects.map(project => (
    <CheckboxInput
      key={project._id}
      label={project.title}
      value={memberships.find(m => m.project === project._id) !== undefined}
      onChange={checked => handleChecked(project, checked)}
    />
  ));
  return (
    <FormCard icon={Icon.Books} title={T('page.person.tab.projects')}>
      {checkboxes}
    </FormCard>
  )
};
