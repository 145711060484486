/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * Idea stolen from: https://stackoverflow.com/a/55075818/1526448
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export default function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useDelayedUpdateEffect(
  callback: EffectCallback,
  delay: number,
  deps?: DependencyList
) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const timeout = setTimeout(callback, delay);
      return () => clearTimeout(timeout);
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
