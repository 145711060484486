import React, { useMemo } from 'react';
import { markdown2html } from '../utils/Markdown';

interface MarkdownViewProps {
  content: string;
}
export const MarkdownView = (props: MarkdownViewProps) => {
  const { content } = props;
  const rendered = useMemo(() => markdown2html(content), [content]);
  return <div dangerouslySetInnerHTML={rendered} />;
}
