import React from 'react';
import { BasePage, PageProps, PageState } from '../Page';
import { Col, Row, Button } from 'react-bootstrap';
import { FormCard } from '../../components/FormCard';
import { Icon } from '../../components/Icons';
import { T, singular } from '../../Translate';
import { DateTimeInput, DateTimeInputValue, emptyDateTime, inputDateTimeToISO, inputDateTimeFromISO } from '../../components/inputs/DateTimeInput';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { BottomButtonRow } from '../../components/ButtonRow';
import { ContactMomentContactPersonEvent, ContactMomentContribution, IContactPerson, ContactMomentType, translateContactMomentType, ContactMomentTypes } from '../../models/ContactPerson';
import api, { IAPIError } from '../../api';
import { NotificationManager } from 'react-notifications';
import { SelectInput } from '../../components/inputs/SelectInput';
import { ContributionList } from '../RecordContactMoment/ContributionList';
import { PageID } from '../../utils/PageLinking';
import { UnsavedChangesModal } from '../../modals/UnsavedChangesModal';
import ValidationResult from "../../utils/ValidationError";

interface EditContactMomentProps extends PageProps {
  id: string;
}
interface EditContactMomentState extends PageState {
  person?: IContactPerson;
  contactMoment?: ContactMomentContactPersonEvent;

  contactMethod: ContactMomentType,
  startsAt: DateTimeInputValue;
  startsAtError?: string;
  endsAt: DateTimeInputValue;
  endsAtError?: string;
  transcript: string;
  contributions: ContactMomentContribution[];
}
export default class EditContactMomentPage extends BasePage<EditContactMomentProps, EditContactMomentState> {
  contactMethodOptions: JSX.Element[];

  constructor(props: EditContactMomentProps) {
    super(props);

    this.state = {
      page: {
        id: PageID.EditContactMoment,
        entity: props.id,
        icon: Icon.Comment,
        title: T('page.editContactMoment.title', { name: '...' }),
        confirmNavigation: this.confirmNavigation
      },
      contactMethod: ContactMomentType.Visit,
      startsAt: emptyDateTime(),
      endsAt: emptyDateTime(),
      transcript: '',
      contributions: []
    };

    this.contactMethodOptions = ContactMomentTypes.map(type => (
      <option key={type} value={type}>{translateContactMomentType(type)}</option>
    ));
  }

  shouldLoadContent(oldProps: EditContactMomentProps, newProps: EditContactMomentProps) {
    return oldProps.id !== newProps.id;
  }

  hasChanged = () => {
    const {
      contactMoment,
      contactMethod,
      startsAt,
      endsAt,
      transcript,
      contributions
    } = this.state;

    if (contactMoment === undefined)
      return true;

    if (contactMethod !== contactMoment.contactMethod)
      return true;
    if (inputDateTimeToISO(startsAt) !== contactMoment.start)
      return true;
    if (inputDateTimeToISO(endsAt) !== contactMoment.end)
      return true;
    if (transcript !== contactMoment.transcript)
      return true;
    if (contributions.length !== contactMoment.contributions.length)
      return true;

    for (let i = 0; i < contributions.length; i++) {
      const a = contributions[i];
      const b = contactMoment.contributions[i];
      if (a.type !== b.type || a.targetId !== b.targetId)
        return true;
    }

    return false;
  }

  confirmNavigation = async () => {
    if (this.hasChanged()) {
      this.props.modals.show(UnsavedChangesModal);
      return false;
    } else {
      return true;
    }
  }

  loadContent() {
    const { id, via } = this.props;
    this.loading(api.getContactPersonEvent(id), singular('contactMoment'))
      .then(contactPersonEvent => {
        const event = contactPersonEvent as ContactMomentContactPersonEvent;
        this.setState({
          contactMoment: event,
          contactMethod: event.contactMethod,
          startsAt: inputDateTimeFromISO(event.start),
          endsAt: inputDateTimeFromISO(event.end),
          transcript: event.transcript,
          contributions: event.contributions
        });
        this.loading(api.getContactPerson(event.contactPersonId), singular('contactPerson'))
          .then(contactPerson => this.setState({
            person: contactPerson,
            page: {
              id: PageID.EditContactMoment,
              entity: id,
              icon: Icon.Comment,
              title: T(
                'page.editContactMoment.title',
                { name: contactPerson.firstName + ' ' + contactPerson.lastName }
              ),
              confirmNavigation: this.confirmNavigation,
              via: via || 'contact_person-' + contactPerson._id,
              viaTitle: via ? undefined : contactPerson.firstName + ' ' + contactPerson.lastName
            }
          }));
      });
  }

  handleContactMethodChanged = (value: string) => {
    this.setState({ contactMethod: value as ContactMomentType });
  }

  handleStartsAtChanged = (value: DateTimeInputValue) => {
    this.setState({ startsAt: value })
  }

  handleEndsAtChanged = (value: DateTimeInputValue) => {
    this.setState({ endsAt: value });
  }

  handleTranscriptChanged = (value: string) => {
    this.setState({ transcript: value });
  }

  handleDeleteContribution = (contribution: ContactMomentContribution) => {
    const { contributions } = this.state;
    const newContributions = contributions.filter(c => c.targetId !== contribution.targetId);
    this.setState({ contributions: newContributions });
  }

  handleInsertContribution = (contribution: ContactMomentContribution) => {
    const {contributions} = this.state;
    let already_added_contribution = contributions.find(
      i => i.targetId === contribution.targetId
    )
    if (already_added_contribution === undefined) {
      this.setState({contributions: [...contributions, contribution]});
    } else {
      NotificationManager.info(T('validationError.dataAlreadyExists'));
    }
  }

  handleClickedSave = () => {
    const { id } = this.props;
    const { contactMethod, startsAt, endsAt, transcript, contributions } = this.state;
    const start = inputDateTimeToISO(startsAt);
    if (start === undefined) {
      this.setState({ startsAtError: T('validationError.invalidDate') });
      return;
    }
    const end = inputDateTimeToISO(endsAt);
    if (end === undefined) {
      this.setState({ endsAtError: T('validationError.invalidDate') });
      return;
    }

    api.updateContactPersonEvent({
      _id: id,
      contactMethod,
      start,
      end,
      transcript,
      contributions
    }).then(event => {
      NotificationManager.success(T('page.editContactMoment.saved'));
      this.setState({ contactMoment: event as ContactMomentContactPersonEvent }, () => {
        setTimeout(() => {
          // give the state a moment to update
          const { history } = this.props;
          history.goBack();
        });
      });
    }).catch(err => {
      this.handleAPIError(err)
    });
  }

  handleAPIError(err: IAPIError) {
    // handle validation error
    if (err.code === 1013 && err.data) {
      const validationResult = new ValidationResult(err.data) ;
      if (validationResult.name?.toUpperCase() === 'end_date'.toUpperCase())
        this.setState({ endsAtError: T('validationError.invalidDate') });
    }
  }

  renderContent() {
    const { contactMethod, startsAt, endsAt, transcript, contributions } = this.state;

    return <>
      <Row style={{ marginTop: '1em', marginBottom: '2.5em' }}>
        <Col md={6}>
          <FormCard icon={Icon.Info} title={T('page.recordContactMoment.info')}>
            <SelectInput
              name='contact-method'
              label={T('contactMoment.contactMethod')}
              labelColumns={3}
              value={contactMethod}
              onChange={this.handleContactMethodChanged}
              optionElements={this.contactMethodOptions}
            />
            <DateTimeInput
              name='start'
              label={T('contactMoment.start')}
              labelColumns={3}
              value={startsAt}
              onChange={this.handleStartsAtChanged}
              error={this.state.startsAtError}
            />
            <DateTimeInput
              name='end'
              label={T('contactMoment.end')}
              labelColumns={3}
              value={endsAt}
              onChange={this.handleEndsAtChanged}
              error={this.state.endsAtError}
            />
            <TextareaInput
              name='transcript'
              label={T('contactMoment.transcript')}
              labelColumns={3}
              value={transcript}
              onChange={this.handleTranscriptChanged}
              rows={10}
            />
          </FormCard>
        </Col>
        <Col md={6}>
          <FormCard icon={Icon.ListUl} title={T('page.recordContactMoment.contributions')}>
            <ContributionList
              contributions={contributions}
              onDelete={this.handleDeleteContribution}
              onInsert={this.handleInsertContribution}
            />
          </FormCard>
        </Col>
      </Row>
      <BottomButtonRow>
        <Button variant='primary' onClick={this.handleClickedSave}>
            {T('generic.action.save')}
        </Button>
      </BottomButtonRow>
    </>;
  }
}
