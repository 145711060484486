
export interface IPersonPlaceErrors {
  firstNameError?: string
  familyNameError?: string
  communityError?: string
  areaError?: string
  countryCodeError?: string
  placeEventError?: string;
}

export const personPlaceErrorsCount =(personPlaceErrors?: IPersonPlaceErrors): number => {
  if (personPlaceErrors === undefined)
    return 0

  let count = 0
  for (const key in personPlaceErrors) {
    if (personPlaceErrors[key as keyof IPersonPlaceErrors] !== undefined) {
      count++;
    }
  }
  return count
}

