import React, { CSSProperties } from 'react';
import { FormCard } from '../../../components/FormCard';
import { Icon } from '../../../components/Icons';
import { T } from '../../../Translate';
import { IPersonStory2 } from "../../../models/PersonStory2";
import { Col, Form } from "react-bootstrap";
import { TextInputColumn } from "../../../components/inputs/TextInputColumn";
import { ResetToOrginalLink } from "./ResetToOriginalLink";
import { IPerson } from "../../../models/Person";
import { IPersonPlaceErrors } from "./PersonPlaceData";
import PageLink from '../../../components/PageLink';
import { PageID } from '../../../utils/PageLinking';

export interface PersonFormData {
  firstName: string
  familyName: string
}

export interface PersonFormProps {
  personStory?: IPersonStory2;
  person?: IPerson;
  errors: IPersonPlaceErrors;
  readOnly: boolean;
  onUpdate: (data: PersonFormData) => void;
}


const textStyle: CSSProperties = {
  fontSize: 14,
  color: '#9A9A9A',
}

export const PersonForm = (props: PersonFormProps) => {
  const { personStory, person, readOnly, onUpdate, errors } = props;

  const familyName = personStory?.family_name || ''
  const firstName = personStory?.first_name || ''
  const bornYear = person?.born_year || ''
  const diedYear = person?.died_year || ''

  function handleFirstNameChanged(newName:string) {
    onUpdate({firstName: newName, familyName: familyName || ''})
  }

  function handleFamilyNameChanged(newName:string) {
    onUpdate({firstName: firstName || '', familyName: newName})
  }

  function renderNameResetLink(currentValue: string, personStoryValue: string, onClick: () => void) {
    if  (currentValue !== personStoryValue) {
      return (<ResetToOrginalLink onClick={onClick}/>)
    } else {
      return;
    }
  }

  return (
    <FormCard icon={Icon.User} title={T('personstories.formcard.title.person')}>
      <div className="row">
        <div className="col col-6">
          <Form>
            <Form.Row>
              <Col sm={6}>
                <Form.Text style={textStyle}>
                  {T('personstories.linkedWith')} <PageLink page={PageID.ViewPerson} param={person?._id}>{(person?.surname || '') + ' ' + (person?.familyname || '')}</PageLink>
                </Form.Text>
              </Col>
            </Form.Row>
            <div className="form-row align-items-center">
              <div className="col-6">
                <TextInputColumn
                  name='first-name'
                  value={firstName}
                  label={T('person.firstName')}
                  placeholder={T('person.firstName')}
                  onChange={handleFirstNameChanged}
                  readOnly={readOnly}
                  error={errors.firstNameError}
                />
              </div>
              <div className="col-6">
                <div style={{position: "relative", top: 10}}>
                  {!readOnly && renderNameResetLink(firstName, person?.surname || '', () => {
                    handleFirstNameChanged(person?.surname || '')
                  })}
                </div>
              </div>
            </div>

            <div className="form-row align-items-center">
              <div className="col-6">
                <TextInputColumn
                  name='last-name'
                  value={familyName}
                  label={T('person.lastName')}
                  placeholder={T('person.lastName')}
                  onChange={handleFamilyNameChanged}
                  readOnly={readOnly}
                  error={errors.familyNameError}
                />
              </div>
              <div className="col-6">
                <div style={{position: "relative", top: 10}}>
                  {!readOnly && renderNameResetLink(familyName, person?.familyname || '', () => {
                    handleFamilyNameChanged(person?.familyname || '')
                  })}
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="col">
          <div className="form-row align-items-center">
            <div className="col-6">
              <TextInputColumn
                name='born-year'
                value={bornYear}
                label={T('page.personstory.bornyear')}
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-row align-items-center">
            <div className="col-6">
              <TextInputColumn
                name='died-year'
                value={diedYear}
                label={T('page.personstory.diedyear')}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </div>
    </FormCard>
  )
};
