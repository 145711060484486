import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';

export interface ModalProps<T> {
  key?: number;
  resolve: (value: T) => void;
  reject: () => void;
  onClosed: () => void;
}

export function useModal<T>(props: ModalProps<T>)
  : [boolean, (result: T) => void]
{
  const [isOpen, setOpen] = useState(true);
  const resolve = useCallback((result: T) => {
    props.resolve(result);
    setOpen(false);
    props.onClosed();
  }, [props.resolve, props.onClosed]); // eslint-disable-line react-hooks/exhaustive-deps
  return [isOpen, resolve];
}

export interface BaseModalState {
  visible: boolean;
}
export default class BaseModal<T, P extends ModalProps<T>, S extends BaseModalState> extends React.Component<P, S> {
  accepted: boolean;
  size: 'sm'|'lg'|'xl'|undefined = undefined;

  constructor(props: P, size: 'sm'|'lg'|'xl'|undefined = undefined) {
    super(props);

    this.size = size;

    this.state = {
      visible: true,
    } as S;

    this.accepted = false;

    this.handleHide = this.handleHide.bind(this);
  }

  componentWillReceiveProps(newProps: P) {
    this.setState({ visible: true });
  }

  resolve(result: T) {
    this.accepted = true;

    this.setState({ visible: false });
    this.props.resolve(result);
  }

  close() {
    this.props.reject();
    this.setState({ visible: false });
    
    if (this.props.onClosed)
      this.props.onClosed();
  }

  handleHide() {
    if (!this.accepted)
      this.props.reject();

    this.setState({ visible: false });

    if (this.props.onClosed)
      this.props.onClosed();
  }

  renderModal(): JSX.Element {
    // implemented by subclass
    throw Error("not implemented");
  }

  render() {
    return (
      <Modal show={this.state.visible} onHide={this.handleHide} size={this.size}>
        {this.renderModal()}
      </Modal>
    );
  }
}
