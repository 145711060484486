export enum ProjectType {
  TemporaryExhibition = 'temporary_exhibition'
}

export interface IProjectFields {
  title: string;
  type: ProjectType;
  fromDay: number|null;
  fromMonth: number|null;
  fromYear: number|null;
  toDay: number|null;
  toMonth: number|null;
  toYear: number|null;
  description: string;
}

export interface IProject extends IProjectFields {
  _id: string;
}
