import React from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from './Icons';
import styles from './EditModeButton.module.scss';
import { classes } from '../utils/Styles';

interface EditModeButtonProps {
  inverted: boolean;
  disabled: boolean;
  onClick: () => void;
}
export const EditModeButton = (props: EditModeButtonProps) => {
  const { inverted, disabled, onClick } = props;
  return (
    <Button variant='link' onClick={onClick} disabled={disabled} className={classes(styles.editbutton, inverted ? styles.inverted : '')}>
      <i className={Icon.Edit} />
    </Button>
  );
}
