import React, { useState } from 'react';
import { ModalProps, useModal } from './BaseModal';
import { T } from '../Translate';
import API, { IAuthenticationResponse } from '../api';
import { LoadingIcon } from '../components/Icons';
import { TextInput } from '../components/inputs/TextInput';
import { PasswordInput } from '../components/inputs/PasswordInput';
import { Modal, Button, Alert } from 'react-bootstrap';
import { ModalHeader } from './ModalHeader';

type LoginModalProps = ModalProps<IAuthenticationResponse|null>;

const LoginModal = (props: LoginModalProps) => {
  const [isOpen, resolve] = useModal(props);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(API.user.username);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleClose = () => resolve(null);
  const handleClickedLogin = async () => {
    setLoading(true);
    try {
      const loginInfo = await API.login(username, password);
      setLoading(false);
      API.loginWith(loginInfo);
      resolve(loginInfo);
    } catch(error) {
      setLoading(false);
      if (error.code === 2)
        setError(T('page.login.invalidLogin'));
      else if (error.code === 8)
        setError(T('page.login.accountNotActivated'));
    }
  };

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <ModalHeader>{T('modal.relogin.title')}</ModalHeader>
      <Modal.Body>
        {T('modal.relogin.message')}

        <form style={{ marginTop: '2em' }}>
          <TextInput
            name='username'
            value={username}
            label={T('page.login.username')}
            onChange={setUsername}
          />
          <PasswordInput
            name='password'
            value={password}
            label={T('page.login.password')}
            onChange={setPassword}
            onPressedEnter={handleClickedLogin}
          />
          {error && <>
            <Alert variant='danger'>{error}</Alert>
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </>}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' data-dismiss="modal">
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={handleClickedLogin}>
          {T('generic.action.login')}{' '}
          {loading && (<span>&nbsp;<LoadingIcon /></span>)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default LoginModal;
