import { DocumentReference, IDocumentReference } from "./DocumentReference";
import { DocumentTagDefinition, DocumentTagField, DummyDocumentTagDefinition } from "./DocumentTagDefinition";
import { IndexedList } from "./IndexedList";
import { getActiveLanguage } from "../Translate";

export function getFullTagName(tags: IndexedList<DocumentTagDefinition>, tag: DocumentTagDefinition): string {
  const name = tag.translations[getActiveLanguage()];
  const parentTag = tag.parent && tags.indexed[tag.parent];
  if (parentTag) {
    const parentName = getFullTagName(tags, parentTag);
    return parentName + ' > ' + name;
  } else {
    return name;
  }
}

export function getTagName(tag: IDocumentTag, tagDefinitions: IndexedList<DocumentTagDefinition>): string {
  return tagDefinitions.indexed[tag.tag].translations[getActiveLanguage()]
}

type IDocumentTagFieldValue = string | IDocumentReference | null;
type IDocumentTagFields = { [key: string]: IDocumentTagFieldValue };

export interface IDocumentTag {
  tag: string;
  fields: IDocumentTagFields;
}

export type DocumentTagFieldValue = string | DocumentReference | null;
type DocumentTagFields = { [key: string]: DocumentTagFieldValue };

export function isReferenceFieldValue(value: IDocumentTagFieldValue): value is IDocumentReference {
  return value !== undefined && value !== null && (value as any).type !== undefined;
}
export function isReferenceFieldObject(value: DocumentTagFieldValue): value is DocumentReference {
  return value !== undefined && value !== null && (value as any).toJSON !== undefined;
}

export class DocumentTag {
  static fromJSON(json: IDocumentTag, tagDefinitions: IndexedList<DocumentTagDefinition>) {

    let fields = json.fields;
    const transformedFields: DocumentTagFields = {};
    for (let key in fields) {
      const value = fields[key];
      transformedFields[key] = isReferenceFieldValue(value)
        ? DocumentReference.fromJSON(value)
        : value;
    }

    const definition = tagDefinitions.indexed[json.tag] || DummyDocumentTagDefinition;
    const allFields = getAllTagFields(definition, tagDefinitions);
    return new DocumentTag(
      definition,
      transformedFields,
      allFields,
      getFullTagName(tagDefinitions, definition)
    );
  }

  static create(tag: DocumentTagDefinition, tagDefinitions: IndexedList<DocumentTagDefinition>) {
    const allFields = getAllTagFields(tag, tagDefinitions);
    return new DocumentTag(
      tag,
      {},
      allFields,
      getFullTagName(tagDefinitions, tag)
    );
  }

  tag: DocumentTagDefinition;
  fields: DocumentTagFields;
  allFields: DocumentTagField[];
  parent?: DocumentTagDefinition;

  fullName: string;

  constructor(
    tag: DocumentTagDefinition,
    fields: DocumentTagFields,
    allFields: DocumentTagField[],
    fullName: string) {

    this.tag = tag;
    this.fields = fields;
    this.allFields = allFields;
    this.fullName = fullName;
  }

  equals(other: DocumentTag) {
    if (this.tag !== other.tag)
      return false;

    for (var property of this.allFields) {
      const valueA = this.fields[property.key];
      const valueB = other.fields[property.key];
      if (valueA && valueB && typeof(valueA) === 'object' && typeof(valueB) === 'object') {
        if (valueA.type !== valueB.type || valueA.id !== valueB.id)
          return false;
      } else if (valueA !== valueB) {
        return false;
      }
    }

    return true;
  }

  withFieldValue(field: string, value: DocumentTagFieldValue) {
    const update: DocumentTagFields = {};
    update[field] = value;
    return new DocumentTag(
      this.tag,
      Object.assign({}, this.fields, update),
      this.allFields,
      this.fullName
    );
  }

  toJSON(): IDocumentTag {
    let fields: IDocumentTagFields = {};
    for (let key in this.fields) {
      let field = this.fields[key];
      if (field && isReferenceFieldObject(field)) {
        fields[key] = field.toJSON();
      } else {
        fields[key] = field;
      }
    }

    return {
      tag: this.tag.id,
      fields: fields
    };
  }
}

export function getAllTagFields(
  tag: DocumentTagDefinition,
  tags: IndexedList<DocumentTagDefinition>
): DocumentTagField[] {
  const parent = tag.parent && tags.indexed[tag.parent];
  if (!parent)
    return tag.fields;

  return getAllTagFields(parent, tags).concat(tag.fields);
}
