import { DocumentKind, Document, DocumentStatus } from "../../models/Document";
import { DocumentTag } from "../../models/DocumentTag";
import { DocumentReference } from "../../models/DocumentReference";

export interface IDocumentFormState {
  kind: DocumentKind;
  status: DocumentStatus;
  title: string;
  description: string;
  source: string;
  institution: string;
  inventoryId: string;
  url: string;
  wiseProfile: string;
  wiseBranch: string;
  wiseLocation: string;
  wiseId: string;
  pages: string;
  disclosed: boolean;
  tags: DocumentTag[];
  references: DocumentReference[];
  titleError?: string
  urlError?: string
}

export function getFormStateForDocument(document: Document): IDocumentFormState {
  return {
    kind: document.kind,
    source: document.source || '',
    title: document.title || '',
    description: (document.collection && document.collection.description) || document.description || '',
    institution: document.institution || '',
    inventoryId: document.inventoryId || '',
    url: document.url || '',
    wiseProfile: document.wiseProfile || 'ieper',
    wiseBranch: document.wiseBranch || '',
    wiseLocation: document.wiseLocation || '',
    wiseId: document.wiseId || '',
    pages: document.pages || '',
    status: document.status,
    disclosed: document.disclosed,
    tags: document.tags,
    references: document.references,
    titleError: '',
    urlError: ''
  };
}

export function didFormStateChange(
  original: IDocumentFormState,
  changed: IDocumentFormState
) {
  return original.kind !== changed.kind
    || original.source !== changed.source
    || original.title !== changed.title
    || original.description !== changed.description
    || original.institution !== changed.institution
    || original.inventoryId !== changed.inventoryId
    || original.url !== changed.url
    || original.wiseProfile !== changed.wiseProfile
    || original.wiseBranch !== changed.wiseBranch
    || original.wiseLocation !== changed.wiseLocation
    || original.wiseId !== changed.wiseId
    || original.pages !== changed.pages
    || original.status !== changed.status
    || original.disclosed !== changed.disclosed;
}
