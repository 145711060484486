import React, { useMemo, useState } from 'react';
import { DocumentReference, DocumentReferenceType } from '../../models/DocumentReference';
import { ReferenceView } from './ReferenceView';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import { T } from '../../Translate';
import { ReferenceInput } from '../../components/inputs/ReferenceTypeaheadInput';
import { NotificationManager } from 'react-notifications';
import { TextStyles } from "../../config/styles";

interface DocumentReferencesProps {
  references: DocumentReference[];
  setReferences: (references: DocumentReference[]) => void;
  readOnly: boolean;
}
export const DocumentReferences = (props: DocumentReferencesProps) => {
  const { references, setReferences, readOnly } = props;

  const [addingReferenceType, setAddingReferenceType] = useState(DocumentReferenceType.Empty);

  const handleReferenceSelected = (item: DocumentReference) => {
    let already_added = references.find(
      i => i.id === item.id
    )

    if (!already_added) {
      setReferences([...references, item]);
    } else {
      NotificationManager.info(T('validationError.ConnectionAlreadyExists'));
    }
  };

  const referenceViews = useMemo(() => {
    const handleRemoveReference = (item: DocumentReference) => {
      let index = references.indexOf(item);
      if (index < 0)
        return;

      const newReferences = [...references];
      newReferences.splice(index, 1);
      setReferences(newReferences);
    };

    return references.map(reference => (
      <ReferenceView
        key={reference.id}
        item={reference}
        onClickedRemove={handleRemoveReference}
        readOnly={readOnly}
      />
    ));
  }, [references, setReferences, readOnly]);

  return (
    <Form.Group>
      <label className='control-label'>{T('document.references')}</label>
      <Col>
        {referenceViews.length > 0 && <div style={{ padding: '0.5em' }}>{referenceViews}</div>}
        {referenceViews.length === 0 && <Alert variant='secondary' style={{...TextStyles.text.medium}}>{T('document.references.none')}</Alert>}
        {!readOnly && <p style={{...TextStyles.text.small}}>{T('document.references.callToAction')}:</p>}
        {!readOnly && (
          <Row>
            <Col sm={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
              <Form.Control
                as="select"
                name='add-reference'
                value={addingReferenceType}
                onChange={e => setAddingReferenceType(e.currentTarget.value as DocumentReferenceType)}
              >
                <option value={DocumentReferenceType.Empty}>{T('document.referencetype.select')}</option>
                <option value={DocumentReferenceType.Person}>{T('document.referencetype.person')}</option>
                <option value={DocumentReferenceType.Place}>{T('document.referencetype.place')}</option>
                <option value={DocumentReferenceType.Memorial}>{T('document.referencetype.memorial')}</option>
                <option value={DocumentReferenceType.MilityEntity}>{T('document.referencetype.military_entity')}</option>
              </Form.Control>
            </Col>
            <Col sm={8} style={{ paddingTop: 0, paddingBottom: 0 }}>
              {addingReferenceType !== DocumentReferenceType.Empty && (
                <ReferenceInput
                  type={addingReferenceType}
                  onSelected={handleReferenceSelected}
                />
              )}
            </Col>
          </Row>
        )}
      </Col>
    </Form.Group>
  );
}
