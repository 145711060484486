import { IPersonStory2, IPersonStoryText, Language, StoryStatus } from "../PersonStory2";
import { StoryIndex } from "../../pages/EditPersonStory/Stories/StoryTextView";

export class PersonStoryWrapper {
  personStory: IPersonStory2;

  constructor(personStory: IPersonStory2) {
    this.personStory = personStory
  }

  /** updates **/

  public updateStoryWithText(newText: string, index: StoryIndex, language: Language) {
    const storyTextFieldName = this.getStoryTextFieldName(index);
    let textForLanguage: IPersonStoryText  = this.getOrCreateStoryText(language);
    textForLanguage[storyTextFieldName] = newText
  }

  public updateStoryWithStatus(newStatus: StoryStatus, index: StoryIndex, language: Language) {
    const storyStatusName = this.getStoryTextStatusName(index);
    let textForLanguage: IPersonStoryText  = this.getOrCreateStoryText(language);
    textForLanguage[storyStatusName] = newStatus
  }

  /** get or create when not there **/

  public getOrCreateStoryText(language: Language): IPersonStoryText {
    if (this.personStory.story_text === null || this.personStory.story_text === undefined) {
      this.personStory.story_text = {};
    }

    let textForLanguage = (this.personStory.story_text[language])
    if (textForLanguage === undefined || textForLanguage === null) {
      let newStoryText = this.createEmptyStoryText(language)
      this.personStory.story_text[language] = newStoryText;
      return newStoryText;
    } else {
      return textForLanguage
    }
  }

  /** get **/

  public getStory(index: StoryIndex, language: Language): string {
    const storyTextFieldName = this.getStoryTextFieldName(index)
    if (this.personStory !== undefined) {
      const storyText = this.personStory.story_text
      if (storyText !== undefined && storyText !== null) {
        const t = storyText[language];
        if (t) {
          const story = t[storyTextFieldName] || ''
          return story;
        }
      }
    }
    return ''
  }

  public getStoryStatus(index: StoryIndex, language: Language): StoryStatus {
    const storyTextStatusName = this.getStoryTextStatusName(index)
    if (this.personStory !== undefined) {
      const storyText = this.personStory.story_text
      if (storyText !== undefined && storyText !== null) {
        const t = storyText[language];
        if (t) {
          const story = t[storyTextStatusName] || ''
          return story;
        }
      }
    }
    return StoryStatus.IN_PROGRESS
  }


  /** private helpers **/

  private getStoryTextFieldName(index: StoryIndex): 'story_part_1'|'story_part_2'|'story_child_part_1'|'story_child_part_2' {
    switch (index) {
      case StoryIndex.STORY_1:
        return 'story_part_1'
      case StoryIndex.STORY_2:
        return 'story_part_2'
      case StoryIndex.STORY_CHILD_1:
        return 'story_child_part_1'
      case StoryIndex.STORY_CHILD_2:
        return 'story_child_part_2'
    }
  }

  private getStoryTextStatusName(index: StoryIndex): 'story_status'|'story_child_status' {
    switch (index) {
      case StoryIndex.STORY_1:
        return 'story_status'
      case StoryIndex.STORY_2:
        return 'story_status'
      case StoryIndex.STORY_CHILD_1:
        return 'story_child_status'
      case StoryIndex.STORY_CHILD_2:
        return 'story_child_status'
    }
  }

  private createEmptyStoryText(language: Language): IPersonStoryText {
    return {
      story_part_1: '',
      story_part_2: '',
      story_status: StoryStatus.IN_PROGRESS,
      story_child_part_1: '',
      story_child_part_2: '',
      story_child_status: StoryStatus.IN_PROGRESS,
      language: language
    }
  }
}
