import React, { ReactNode } from 'react';
import styles from './DocumentElementPreview.module.scss';
import { Colors, TextStyles } from "../../config/styles";


interface DocumentElementPreviewErrorHeaderProps {
  children: ReactNode
}
export const DocumentElementPreviewErrorHeaderView = (props: DocumentElementPreviewErrorHeaderProps) => {
  return (
    <div className={styles.centerTop} style={{...TextStyles.text.mediumBold, width: '100%', backgroundColor: Colors.errorRed, color: Colors.verylightGray}}>
        {props.children}
    </div>)
};


