import React from 'react';
import { ModalProps } from './BaseModal';
import { T } from '../Translate';
import { NotificationModal } from './NotificationModal';

export const UnsavedChangesModal = (props: ModalProps<void>) => {
  return (
    <NotificationModal
      title={T('unsavedChanges.title')}
      message={T('unsavedChanges.message')}
      {...props}
    />
  );
}
