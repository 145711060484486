import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Person } from '../../models/Person';
import { T, plural } from '../../Translate';
import { Icon } from '../../components/Icons';
import { rowKey, generatePersonsColumns } from '../ListPersons';
import { IQueryResult } from '../../models/Generic';
import api from '../../api';
import { ConfirmationModal } from '../../modals/Confirmation';
import { ITableColumn } from '../../components/table/Fields';
import { PageProps } from '../Page';
import { ListState, ListPage } from '../ListPage';
import { useProject } from '../../FunctionalAPI';
import { useModalContext } from '../../modals/ModalContext';
import { usePageState } from '../../redux/Actions';
import { NotificationManager } from 'react-notifications';
import { PageID, getPageLink, getPageVia } from '../../utils/PageLinking';

export type ViewProjectStateProps = ListState;

interface ViewProjectPageOwnProps extends PageProps {
  id: string;
}

const PageSize = 10;
export default (props: ViewProjectPageOwnProps) => {
  const { history, loading, id, via } = props;

  const modals = useModalContext();
  const [items, setItems] = useState<IQueryResult<Person>>();
  const [pageState, updatePageState] = usePageState('project');
  const project = useProject(loading, id);
  const { filter, tablePage, sortColumn, sortDirection } = pageState;

  const refresh = useCallback(() => {
    loading.loading(
      api.findPersons(
        { 'project_memberships.project': id },
        filter,
        tablePage * PageSize,
        PageSize,
        [[sortColumn, sortDirection]]
      ),
      plural('person')
    ).then(x => setItems({
      total: x.total,
      data: x.data.map(y => new Person(y))
    }));
  }, [loading, filter, tablePage, sortColumn, sortDirection, id]);
  useEffect(refresh, [refresh]);

  const handleClickedRow = (person: Person) => {
    history.push(getPageLink(PageID.ViewPerson, person.id) + '?via=' + getPageVia(PageID.ViewProject, id));
  };

  const columns: ITableColumn<Person>[] = useMemo(
    () => {
      const handleDeletionConfirmed = async (person: Person) => {
        await api.removePerson(person.id);
        refresh();
        NotificationManager.success(T('modal.removePerson.removed', { name: person.name }));
      };

      const handleClickedRemove = (person: Person) => {
        modals.show(props => (
          <ConfirmationModal
            title={T('modal.removePerson.title')}
            message={T('modal.removePerson.message', { name: person.name })}
            warning={T('generic.cannotUndo')}
            acceptText={T('generic.action.remove')}
            acceptStyle='danger'
            rejectText={T('generic.action.cancel')}
            {...props}
          />
        )).then(confirmed => confirmed && handleDeletionConfirmed(person));
      };
    
      return generatePersonsColumns(handleClickedRemove);
    },
    [modals, refresh]
  );
  
  return (
    <ListPage
      id={PageID.ViewProject}
      entity={id}
      icon={Icon.Book}
      title={T('page.projectView.title', { name: project ? project.title : '...' })}
      via={via || PageID.ListProjects}
      noun='project'
      columns={columns}
      items={items}
      state={pageState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedRow={handleClickedRow}
    />
  );
}
