import React, { CSSProperties } from 'react';
import { InvalidFeedback } from './InvalidFeedback';

interface InputGroupProps {
  id: string;
  style?: CSSProperties;
  label: string;
  labelColumns?: number;
  inputColumns?: number;
  children: React.ReactNode;
  error?: string;
  info?: string;
}

/**
 * Adds a label and optionally an error message and info text to an input
 * component.
 */
export const InputGroup = (props: InputGroupProps) => {
  const {
    id,
    style,
    label,
    labelColumns = 4,
    inputColumns,
    children,
    error,
    info
  } = props;

  return (
    <div className="form-group row" style={style}>
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}:</label>
      <div className={`col-sm-${inputColumns || (12 - labelColumns)}`}>
        {children}
        {error && <InvalidFeedback error={error} />}
        {info && <small className='form-text text-muted'>{info}</small>}
      </div>
    </div>
  );
}
