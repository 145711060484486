import React, { CSSProperties } from 'react';
import { PageID, getPageLink } from '../utils/PageLinking';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../utils/Functional';

interface PageLinkProps {
  page: PageID;
  param?: string;
  className?: string;
  title?: string;
  style?: CSSProperties;
  target?: string;
  children: React.ReactNode;
}
export default (props: PageLinkProps) => {
  const { page, param, children, style, ...otherProps } = props;
  const link = getPageLink(page, param || '');
  const currentPage = useAppSelector(state => state.activePageState);
  const via = currentPage.id + (currentPage.entity ? ('~' + currentPage.entity) : '');
  return <Link to={`${link}?via=${via}`} style={style as any} {...otherProps}>{children}</Link>;
}
