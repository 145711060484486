import React, { useRef, useState, useMemo, useCallback } from 'react';
import { useModal, ModalProps } from '../../../modals/BaseModal';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { T } from '../../../Translate';
import { ModalHeader } from '../../../modals/ModalHeader';
import { ButtonRowModalFooter } from '../../../components/ButtonRow';
import { TextInputColumn } from '../../../components/inputs/TextInputColumn';
import CompactCheckboxInput from '../../../components/inputs/CompactCheckboxInput';

import template1image from '../../../assets/images/phototemplates/template1.png';
import template2image from '../../../assets/images/phototemplates/template2.png';
import template3image from '../../../assets/images/phototemplates/template3.png';
import { ButtonLink } from '../../../components/ButtonLink';
import ChooseTemplateModal from './ChooseTemplateModal';
import { useModalContext } from '../../../modals/ModalContext';
import ChoosePhotoModal from './ChoosePhotoModal';
import { Person } from '../../../models/Person';
import { ChosenPhoto } from './ChosenPhoto';
import EditPhotoPreview from './EditPhotoPreview';
import { IPhotoSource, DocumentPhotoSource, IPhotoMetadata, TemporaryPhotoSource, PhotoDimension } from './PhotoSource';
import { IPersonStory2, Language } from '../../../models/PersonStory2';
import { Icon } from '../../../components/Icons';
import { TextareaInput } from '../../../components/inputs/TextareaInput';

interface PersonPhotoModalProps extends ModalProps<IPersonStory2|undefined> {
  person: Person;
  personStoryId: string;
  target: number|'profile'|'new';
  photo?: IPhotoSource;
  metadata?: IPhotoMetadata;
  captions?: {[key: string]: string};
}

function useParsedNumber(stringValue: string) {
  const lastCorrectValue = useRef(0);
  return useMemo(() => {
    const value = parseInt(stringValue);
    if (isNaN(value)) {
      return lastCorrectValue.current;
    } else {
      lastCorrectValue.current = value;
      return value;
    }
  }, [stringValue]);
}

const defaultMetaForProfile: IPhotoMetadata = {
  template: 1,
};

const defaultMetaForOther: IPhotoMetadata = {
  template: 0,
  dimension: PhotoDimension.Landscape
};

export default (props: PersonPhotoModalProps) => {
  const {
    person,
    personStoryId,
    target,
    metadata = target === 'profile' ? defaultMetaForProfile : defaultMetaForOther,
    captions = {}
  } = props;

  const [isOpen, resolve] = useModal(props);
  const handleClose = () => resolve(undefined);
  const modals = useModalContext();

  const recalculateImageSize = useRef(false);

  const [dimension, setDimension] = useState(metadata.dimension || PhotoDimension.Profile);
  const { targetWidth, targetHeight } = useMemo(() => {
    switch (dimension) {
      case PhotoDimension.Portrait:
        return { targetWidth: 960, targetHeight: 1280 };
      case PhotoDimension.Landscape:
        return { targetWidth: 1080, targetHeight: 810 };
      case PhotoDimension.Profile:
      default:
        return { targetWidth: 1080, targetHeight: 1280 };
    }
  }, [dimension]);

  const [saving, setSaving] = useState(false);
  const [xAsString, setX] = useState((metadata.x || 0).toString());
  const [yAsString, setY] = useState((metadata.y || 0).toString());
  const [widthAsString, setWidth] = useState((metadata.width || targetWidth).toString());
  const [heightAsString, setHeight] = useState((metadata.height || targetHeight).toString());
  const [grayscale, setGrayscale] = useState(metadata.grayscale || false);
  const [template, setTemplate] = useState(metadata.template);
  const [photo, setPhoto] = useState<IPhotoSource|undefined>(props.photo);
  const [ratioLock, setRatioLock] = useState(true);
  const [originalSize, setOriginalSize] = useState({
    width: metadata.width || targetWidth,
    height: metadata.height || targetHeight
  });

  const [captionNL, setCaptionNL] = useState(captions[Language.NL] || '');
  const [captionEN, setCaptionEN] = useState(captions[Language.EN] || '');
  const [captionFR, setCaptionFR] = useState(captions[Language.FR] || '');
  const [captionDE, setCaptionDE] = useState(captions[Language.DE] || '');


  const x = useParsedNumber(xAsString);
  const y = useParsedNumber(yAsString);
  const width = useParsedNumber(widthAsString);
  const height = useParsedNumber(heightAsString);

  const setWidthString = (widthString: string) => {
    if (ratioLock) {
      const width = parseInt(widthString)
      if (isNaN(width))
        return;

      const newHeight = Math.floor(width * originalSize.height / originalSize.width);
      setHeight(newHeight.toString());
    }

    setWidth(widthString);
  }

  const setHeightString = (heightString: string) => {
    if (ratioLock) {
      const height = parseInt(heightString)
      if (isNaN(height))
        return;

      const newWidth = Math.floor(height * originalSize.width / originalSize.height);
      setWidth(newWidth.toString());
    }

    setHeight(heightString);
  }

  const hasChanged = props.photo === undefined || props.metadata === undefined || (
       metadata.x !== x
    || metadata.y !== y
    || metadata.width !== width
    || metadata.height !== height
    || metadata.grayscale !== grayscale
    || metadata.template !== template
    || metadata.dimension !== dimension
    || props.photo !== photo
    || (captions[Language.NL] || '') !== captionNL
    || (captions[Language.EN] || '') !== captionEN
    || (captions[Language.FR] || '') !== captionFR
    || (captions[Language.DE] || '') !== captionDE
  );

  const handleClickedChooseTemplate = async () => {
    const options = [
      { value: 1, image: template1image },
      { value: 2, image: template2image },
      { value: 3, image: template3image }
    ];
    const template = await modals.show<number|undefined>(
      props => <ChooseTemplateModal options={options} {...props} />
    );
    if (template === undefined)
      return;

    setTemplate(template);
  };

  const handleClickedChoosePhoto = async () => {
    const photo = await modals.show<ChosenPhoto|undefined>(props => (
      <ChoosePhotoModal
        createAsDocument={target === 'profile'}
        storyId={personStoryId}
        person={person}
        {...props}
      />
    ));
    if (photo === undefined)
      return;

    recalculateImageSize.current = true;
    setX("0");
    setY("0");

    if (photo.source === 'document') {
      setPhoto(new DocumentPhotoSource(photo.document, photo.page, personStoryId, target));
    } else {
      setPhoto(new TemporaryPhotoSource(photo.attachment, personStoryId, target));
    }
  };

  const handlePositionChanged = useCallback((x: number, y: number) => {
    setX(x.toString());
    setY(y.toString());
  }, []);

  const handleSizeChanged = useCallback((width: number, height: number) => {
    setWidth(width.toString());
    setHeight(height.toString());
  }, []);

  const handlePhotoLoaded = useCallback((image: HTMLImageElement) => {
    setOriginalSize({ width: image.naturalWidth, height: image.naturalHeight });
    if (recalculateImageSize.current) {
      recalculateImageSize.current = false;
      const desiredAspectRatio = targetHeight / targetWidth;
      const photoAspectRatio = image.naturalHeight / image.naturalWidth;
      if (photoAspectRatio > desiredAspectRatio) {
        handleSizeChanged(
          Math.floor(targetWidth * desiredAspectRatio / photoAspectRatio),
          targetHeight);
      } else {
        handleSizeChanged(
          targetWidth,
          Math.floor(targetHeight * photoAspectRatio / desiredAspectRatio)
        );
      }
    }
  }, [handleSizeChanged, targetWidth, targetHeight]);

  const handleClickedLockRatio = () => setRatioLock(!ratioLock);

  const hasCaptions = target !== 'profile';
  const handleClickedSave = async () => {
    if (!photo)
      return;

    setSaving(true);
    try {
      const captions = hasCaptions ? {
        'nl': captionNL,
        'en': captionEN,
        'fr': captionFR,
        'de': captionDE
      } : undefined;
      
      const updatedStory = await photo.save({ x, y, width, height, template, grayscale, dimension, legend: captions });
      resolve(updatedStory);
    } catch {
      // TODO: error
    } finally {
      setSaving(false);
    }
  };

  const templateUrl = useMemo(() => {
    switch (template) {
      case 1: return template1image;
      case 2: return template2image;
      case 3: return template3image;
      default: return undefined;
    }
  }, [template]);

  return (
    <Modal show={isOpen} onHide={handleClose} size={hasCaptions ? 'xl' : 'lg'}>
      <ModalHeader>{T('page.personstory.editPersonPhoto.title')}</ModalHeader>
      <Modal.Body>
        <Row>
          <Col xl={hasCaptions ? 4 : 6} lg={6}>
            <EditPhotoPreview
              x={x}
              y={y}
              width={width}
              height={height}
              targetWidth={targetWidth}
              targetHeight={targetHeight}
              setPosition={handlePositionChanged}
              setSize={handleSizeChanged}
              image={photo?.getUrl()}
              overlay={templateUrl}
              grayscale={grayscale}
              onImageLoaded={handlePhotoLoaded}
            />
            <Row>
              <Col sm={7}>
                {target === 'profile' ? (
                  <ButtonLink onClick={handleClickedChooseTemplate}>
                    {T('page.personstory.editPersonPhoto.chooseTemplate')}
                  </ButtonLink>
                ) : (
                  <select className='form-control' value={dimension} onChange={e => setDimension(e.currentTarget.value as PhotoDimension)}>
                    <option value={PhotoDimension.Landscape}>{T('page.personstory.editPersonPhoto.dimension.landscape')}</option>
                    <option value={PhotoDimension.Portrait}>{T('page.personstory.editPersonPhoto.dimension.portrait')}</option>
                    <option value={PhotoDimension.Profile}>{T('page.personstory.editPersonPhoto.dimension.profile')}</option>
                  </select>
                )}
              </Col>
              <Col sm={5} style={{ textAlign: 'right' }}>
                <ButtonLink onClick={handleClickedChoosePhoto}>
                  {T('page.personstory.editPersonPhoto.choosePhoto')}
                </ButtonLink>
              </Col>
            </Row>
          </Col>
          <Col xl={hasCaptions ? 4 : 6} lg={6}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}>
              <Row>
                <Col sm={4}>
                  <TextInputColumn
                    label='x'
                    name='x'
                    type='number'
                    value={xAsString}
                    onChange={setX}
                  />
                </Col>
                <Col sm={4}>
                  <TextInputColumn
                    label='y'
                    name='y'
                    type='number'
                    value={yAsString}
                    onChange={setY}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <TextInputColumn
                    name='width'
                    label={T('page.personstory.editPersonPhoto.width')}
                    type='number'
                    value={widthAsString}
                    onChange={setWidthString}
                  />
                </Col>
                <Col sm={4}>
                  <TextInputColumn
                    name='height'
                    label={T('page.personstory.editPersonPhoto.height')}
                    type='number'
                    value={heightAsString}
                    onChange={setHeightString}
                  />
                </Col>
                <Col sm={4}>
                  <i className={ratioLock ? Icon.LockAlt : Icon.UnlockAlt} style={{ cursor: 'pointer', position: 'relative', top: '2.4rem' }} onClick={handleClickedLockRatio} />
                </Col>
              </Row>
              <CompactCheckboxInput
                label={T('page.personstory.editPersonPhoto.grayscale')}
                value={grayscale}
                onChange={setGrayscale}
              />
              <div style={{ flexGrow: 1 }} />
              <small className='form-text text-muted'>{T('page.personstory.editPersonPhoto.info')}</small>
            </div>
          </Col>
          {hasCaptions && (
            <Col xl={{ offset: 0, span: 4 }} lg={{ offset: 6, span: 6 }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}>
                <Form>
                  <TextareaInput
                    name='caption-nl'
                    label={T('page.personstory.editPersonPhoto.caption', { language: 'NL'})}
                    value={captionNL}
                    onChange={setCaptionNL}
                    vertical={true}
                  />
                  <TextareaInput
                    name='caption-fr'
                    label={T('page.personstory.editPersonPhoto.caption', { language: 'FR'})}
                    value={captionFR}
                    onChange={setCaptionFR}
                    vertical={true}
                  />
                  <TextareaInput
                    name='caption-en'
                    label={T('page.personstory.editPersonPhoto.caption', { language: 'EN'})}
                    value={captionEN}
                    onChange={setCaptionEN}
                    vertical={true}
                  />
                  <TextareaInput
                    name='caption-de'
                    label={T('page.personstory.editPersonPhoto.caption', { language: 'DE'})}
                    value={captionDE}
                    onChange={setCaptionDE}
                    vertical={true}
                  />
                </Form>
              <div style={{ flexGrow: 1 }} />
            </div>
            </Col>
          )}
        </Row>
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button onClick={handleClickedSave} disabled={photo === undefined || saving || !hasChanged}>
          {T('generic.action.save')}{' '}{saving && <i className={`${Icon.Loading} fa-lg`} />}
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
