import React from "react";
import { EventLinkView } from "./EventLinkView"
import { EventLinksDataWithSelected } from "./EventLinks"


interface EventLinksProps {
  data: EventLinksDataWithSelected[]
  onClick?: (selected: number) => void;
}

export const EventLinksView = (props: EventLinksProps ) => {
  let { data, onClick } = props;

  const onClickHandler = (selected: number) => {
    if (onClick !== undefined) {
      onClick(selected)
    }
  };

  return <>
    {data.map((el, index) => (
      <EventLinkView
        key={index}
        text={el.text}
        onClick={() => onClickHandler(index)}
        selected={el.selected}
      />
    ))}
  </>;
}
