import React, { useMemo } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { ModalProps, useModal } from './BaseModal';
import { T } from '../Translate';
import API from '../api';
import { Gender, VictimType, IPerson, IPersonFields } from '../models/Person';
import { ModalHeader } from './ModalHeader';
import { TextInput } from '../components/inputs/TextInput';
import { DateInput, emptyDate } from "../components/inputs/DateInput";
import { isValidationResult, IValidationResult } from "../models/ValidationResult";
import { NotificationManager } from "react-notifications";
import { SelectInput } from "../components/inputs/SelectInput";
import { RadioInput } from "../components/inputs/RadioInput";
import { useStateWithError } from '../utils/Functional';
import { ButtonRowModalFooter } from '../components/ButtonRow';

interface CreatePersonModalProps extends ModalProps<IPerson|undefined> {
  value: string;
}

function splitName(name: string) {
  let space = name.indexOf(' ');
  if (space >= 0) {
    return {
      firstName: name.substring(0, space),
      lastName: name.substring(space + 1)
    };
  } else {
    return {
      firstName: '',
      lastName: name
    };
  }
}

export const CreatePersonModal = (props: CreatePersonModalProps) => {
  const { value } = props;
  const [isOpen, resolve] = useModal(props);
  const name = splitName(value);

  const genderOptions = useMemo(() => [
    { value: Gender.Male, title: T('gender.male') },
    { value: Gender.Female, title: T('gender.female') },
    { value: Gender.Undefined, title: T('gender.undefined') }
  ], []);
  const warCasualtyOptions = useMemo(() => [
    { value: 'N', title: T('person.survivor') },
    { value: 'Y', title: T('person.victim') }
  ], []);
  const victimTypeOptions = useMemo(() => [
    { value: VictimType.Military, title: T('victimType.military')},
    { value: VictimType.Civilian, title: T('victimType.civilian') }
  ], []);
  const namelisterOptions = useMemo(() => [
    { value: 'Y', title: T('yes') },
    { value: 'N', title: T('no') }
  ], []);

  const [firstName, setFirstName, firstNameError, setFirstNameError]
    = useStateWithError(name.firstName);
  const [lastName, setLastName, lastNameError, setLastNameError]
    = useStateWithError(name.lastName);
  const [gender, setGender, genderError]
    = useStateWithError(Gender.Undefined);
  const [warCasualty, setWarCasualty, warCasualtyError]
    = useStateWithError(true);
  const [isNamelister, setNamelister, isNamelisterError]
    = useStateWithError(true);
  const [diedDate, setDiedDate, diedDateError, setDiedDateError]
    = useStateWithError(emptyDate());
  const [victimType, setVictimType, victimTypeError]
    = useStateWithError(VictimType.Civilian);
    
  const handleChangedGender = (value: string) => {
    setGender(value as Gender);
  };

  const handleWarCasualtyChanged = (value: string) => {
    setWarCasualty(value === 'Y');
  };

  const handleVictimTypeChanged = (value: string) => {
    setVictimType(value as VictimType);
  };

  const handleNamelisterChanged = (value: string) => {
    setNamelister(value === 'Y');
  };

  const personObjectFromState = (): IPersonFields => {
    const newPerson: IPersonFields = {
      surname: firstName,
      familyname: lastName,
      initials: '',
      alternative_familynames: [],
      alternative_surnames: [],
      gender: gender,
      nationality: '',
      description: '',
      memorials: [],
      relations: [],
      project_memberships: [],
      born_day: '?',
      born_month: '?',
      born_year: '?',
      sort_born_date: '', // not actually needed here
      died_age: 0,
      died_day: diedDate.day,
      died_month: diedDate.month,
      died_year: diedDate.year,
      sort_died_date: '', // not actually needed here
      victim_type: victimType,
      victim_type_details: 'NONE',
      war_casualty: warCasualty,
      in_namelist: isNamelister,
      cwxrm_remembered: false
    };
    return newPerson
  }

  const setValidationErrors = (result: IValidationResult): boolean => {
    if (result.status === 'OK')
      return false;

    const errors = result.data || {};
    setFirstNameError(errors.surname);
    setLastNameError(errors.familyname);
    setDiedDateError(errors.died_date);

      // emailError: getErrorDescription(errors.email),
      // phoneError: getErrorDescription(errors.phone),
      // streetError: getErrorDescription(errors.street),
      // streetNumberError: getErrorDescription(errors.streetNumber),
      // placeError: getErrorDescription(errors.placeId),
      // notesError: getErrorDescription(errors.notes)
    return true;
  };

  const handleClickedCreate = async () => {
    try {
      const newPerson: IPersonFields = personObjectFromState()
      const result = await API.validatePerson(newPerson,'create')
      if (result.status !== 'OK') {
        throw (result)
      }
      const person = await API.createPerson(newPerson);
      NotificationManager.success(T('page.contactPerson.save.success'));
      resolve(person)
    }
    catch(result) {
      if (isValidationResult(result)) {
        setValidationErrors(result);
      } else {
        NotificationManager.error(T('page.contactPerson.save.failure'));
      }
    }
  }

  const handleClickedCancel = () => {
    resolve(undefined);
  };

  return (
    <Modal show={isOpen} onHide={handleClickedCancel}>
      <ModalHeader>{T('modal.createperson.title')}</ModalHeader>
      <Modal.Body>
        <Form style={{fontSize: 14}}>
          <TextInput
            name='first-name'
            label={T('modal.createperson.firstName')}
            value={firstName}
            error={firstNameError}
            onChange={setFirstName}
          />
          <TextInput
            name='last-name'
            label={T('modal.createperson.lastName')}
            value={lastName}
            error={lastNameError}
            onChange={setLastName}
          />
          <DateInput
            name='died'
            value={diedDate}
            label={T('person.died')}
            onChange={setDiedDate}
            error={diedDateError}
          />
          <SelectInput
            name='gender'
            options={genderOptions}
            label={T('person.gender')}
            value={gender}
            onChange={handleChangedGender}
            error={genderError}
          />
          <RadioInput
            horizontal
            options={warCasualtyOptions}
            value={warCasualty ? 'Y' : 'N'}
            onChange={handleWarCasualtyChanged}
            error={warCasualtyError}
          />
          <RadioInput
            horizontal
            options={victimTypeOptions}
            value={victimType}
            onChange={handleVictimTypeChanged}
            error={victimTypeError}
          />
          {warCasualty && (
            <RadioInput
              horizontal
              options={namelisterOptions}
              value={isNamelister ? 'Y' : 'N'}
              onChange={handleNamelisterChanged}
              error={isNamelisterError}
            />
          )}
        </Form>
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='secondary' onClick={handleClickedCancel}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={handleClickedCreate}>
          {T('generic.action.save')}
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
