import moment from 'moment';
import { DocumentCollection } from './Collection';
import { DocumentReference } from './DocumentReference';
import { IndexedList } from './IndexedList';
import { DocumentTagDefinition } from './DocumentTagDefinition';
import { DocumentTag } from './DocumentTag';
import { T, TranslationKey } from '../Translate';
import {
  DocumentCategory,
  DocumentKind,
  DocumentStatus,
  IDocument,
  IDocumentElement
} from './IDocument'
import { zeroPad } from "../utils/Formatting";


export function getDocumentCategoryName(category: DocumentCategory) {
  return T(('document.category.' + category.toLowerCase()) as TranslationKey);
}


export interface DocumentProps {
  category: DocumentCategory;
  kind: DocumentKind;
  parentId?: string;
  status: DocumentStatus;
  disclosed: boolean;

  description: string|null;

  // AdlibCollectionDocument
  filename?: string;
  collectionId?: string;

  // Adlib
  adlibId?: string;
  adlibCode?: string;

  // Archive
  institution?: string;
  inventoryId?: string;

  // Diborn
  source?: string;
  counter?: number

  // Web
  url?: string;

  // Wise
  wiseProfile?: string;
  wiseBranch?: string;
  wiseLocation?: string;
  wiseId?: string;
  pages?: string;
  id: string
  parent?: Document
  tags?: DocumentTag[],
  elements?: IDocumentElement[],
  references?: DocumentReference[],
  referenced_entities?: DocumentReference[],
  title?: string,
  created?: moment.Moment
  modified?: moment.Moment
  deleted?: boolean
  collection?: DocumentCollection


}

export class Document {

  static fromProps(params: DocumentProps): Document {
    return new Document(
      params.id,
      params.category,
      params.kind,
      params.parentId,
      params.parent,
      params.tags,
      params.status,
      params.disclosed,
      params.elements,
      params.references,
      params.referenced_entities,
      params.title || undefined,
      params.description || undefined,
      params.created,
      params.modified,
      params.deleted,
      params.collectionId,
      params.collection,
      params.filename,
      params.adlibId,
      params.adlibCode,
      params.institution,
      params.inventoryId,
      params.source,
      params.counter,
      params.url,
      params.wiseProfile,
      params.wiseBranch,
      params.wiseLocation,
      params.wiseId,
      params.pages
    )
  }


  static fromJSON(json: IDocument, definitions: IndexedList<DocumentTagDefinition>): Document {
    return new Document(
      json._id,
      json.category,
      json.kind,
      json.parentId,
      json.parent && Document.fromJSON(json.parent, definitions),
      json.tags.map(tag => DocumentTag.fromJSON(tag, definitions)),
      json.status,
      json.disclosed,
      json.elements,
      json.references.map(reference => DocumentReference.fromJSON(reference)),
      json.referenced_entities.map(reference => DocumentReference.fromJSONRef(reference)),
      json.title || undefined,
      json.description || undefined,
      moment(json.created),
      moment(json.modified),
      json.deleted || false,

      // AdlibCollectionDocument
      json.collection_id,
      json.collection && DocumentCollection.fromJSON(json.collection),
      json.filename,
      // Adlib
      json.adlibId,
      json.adlibCode,
      // Archive
      json.institution,
      json.inventoryId,
      // Digital
      json.source,
      json.counter,
      // Web
      json.url,
      // Wise
      json.wiseProfile,
      json.wiseBranch,
      json.wiseLocation,
      json.wiseId,
      json.pages
    );
  }

  static empty() {
    return new Document(
      '',
      DocumentCategory.Adlib,
      DocumentKind.Original,
      undefined,
      undefined,
      [],
      DocumentStatus.Todo,
      false,
      [],
      [],
      [],
      undefined,
      undefined,
      moment(),
      moment(),
      undefined,
    );
  }

  id: string;
  category: DocumentCategory;
  kind: DocumentKind;
  parentId: string | null;
  parent: Document | undefined;
  tags: DocumentTag[];
  status: DocumentStatus;
  disclosed: boolean;
  elements: IDocumentElement[];
  references: DocumentReference[];
  referenced_entities: DocumentReference[];
  description?: string;
  created: moment.Moment;
  modified: moment.Moment;
  deleted: boolean;

  filename: string;
  collectionId: string | null;
  collection?: DocumentCollection;

  adlibId?: string;
  adlibCode?: string;

  institution?: string;
  inventoryId?: string;

  source?: string;
  counter?: number;

  url?: string;

  wiseProfile?: string;
  wiseBranch?: string;
  wiseLocation?: string;
  wiseId?: string;
  pages?: string;

  _title?: string;
  filterLower?: string;

  constructor(
    id: string,
    category: DocumentCategory,
    kind: DocumentKind,
    parentId?: string,
    parent?: Document,
    tags?: DocumentTag[],
    status?: DocumentStatus,
    disclosed?: boolean,
    elements?: IDocumentElement[],
    references?: DocumentReference[],
    referenced_entities?: DocumentReference[],
    title?: string,
    description?: string,
    created?: moment.Moment,
    modified?: moment.Moment,
    deleted?: boolean,
    collectionId?: string | null,
    collection?: DocumentCollection,
    filename?: string,
    adlibId?: string,
    adlibCode?: string,
    institution?: string,
    inventoryId?: string,
    source?: string,
    counter?: number,
    url?: string,
    wiseProfile?: string,
    wiseBranch?: string,
    wiseLocation?: string,
    wiseId?: string,
    pages?: string
  ) {
    this.id = id;
    this.category = category;
    this.kind = kind;
    this.parentId = parentId || null;
    this.parent = parent;
    this.tags = tags || [];
    this.status = status || DocumentStatus.Todo;
    this.disclosed = disclosed || false;
    this.elements = elements || [];
    this.references = references || [];
    this.referenced_entities = referenced_entities || [];
    this._title = title;
    this.description = description;
    this.created = created || moment();
    this.modified = modified || this.created;
    this.deleted = deleted || false;

    this.collectionId = collectionId || null;
    this.filename = filename || '';
    this.collection = collection;

    this.adlibId = adlibId;
    this.adlibCode = adlibCode;

    this.institution = institution;
    this.inventoryId = inventoryId;

    this.source = source;
    this.counter = counter;

    this.url = url;

    this.wiseProfile = wiseProfile;
    this.wiseBranch = wiseBranch;
    this.wiseLocation = wiseLocation;
    this.wiseId = wiseId;
    this.pages = pages;
  }

  matchesFilterLower(filter: string) {
    if (this.filterLower === undefined)
      this.filterLower = this.title.toLowerCase();

    return this.filterLower.indexOf(filter) >= 0;
  }

  clone() {
    return new Document(
      this.id,
      this.category,
      this.kind,
      this.parentId || undefined,
      this.parent,
      this.tags,
      this.status,
      this.disclosed,
      this.elements,
      this.references,
      this.referenced_entities,
      this.title,
      this.description,
      this.created,
      this.modified,
      this.deleted,

      this.collectionId,
      this.collection,
      this.filename,

      this.adlibId,
      this.adlibCode,

      this.institution,
      this.inventoryId,

      this.source,
      this.counter,

      this.url,

      this.wiseProfile,
      this.wiseBranch,
      this.wiseLocation,
      this.wiseId,
      this.pages
    );
  }


  toJSON(): IDocument {
    return {

      // diborn
      _id: this.id,
      category: this.category,
      title: this.title || null,
      description: this.description || null,
      source: this.source,
      tags: this.tags.map(tag => tag.toJSON()),
      kind: this.kind,
      status: this.status,
      elements: this.elements,
      references: this.references.map(reference => reference.toJSON()),
      parentId: this.parentId ?? undefined,
      deleted: this.deleted,
      disclosed: this.disclosed,
      created: 0,
      modified: 0,
      referenced_entities: this.referenced_entities.map(entity => entity.toJSONRef()),

      // archive
      institution: this.institution,
      inventoryId: this.inventoryId,

      // web
      url: this.url,

      // Diborn
      counter: this.counter,

      // wise
      wiseProfile: this.wiseProfile,
      wiseBranch: this.wiseBranch,
      wiseLocation: this.wiseLocation,
      wiseId: this.wiseId,
      pages: this.pages
    }
  }

  get documentId() {
    return (this.collection && this.collection.adlibCode) || this.adlibCode || this.wiseLocation || this.inventoryId || '';
  }

  get title() {
    if (this._title === undefined || this._title === '')
      if (this.category === DocumentCategory.Adlib ||
        this.category === DocumentCategory.AdlibCollectionDocument) {
        return ''
      } else {
        return ''
      }
    return this._title;
  }

  static renderDocumentCounter(counter: number | undefined) {
    let result: string = ''
    if (counter === undefined || counter < 0) {
      result = ''
    } else {
      result = 'DIBO' + zeroPad(counter, 6)
    }
    return result
  }


  static generateTitle(tags: DocumentTag[]) {
    let result = "";
    let first = true;

    for (let i = 0; i < tags.length; i++) {
      let tag = tags[i];
      let tagDefinition = tag.tag;

      if (first) {
        first = false;
      } else {
        result += ", ";
      }

      result += tagDefinition.generateDescription(tag);
    }
    return result;
  }
}

export * from './IDocument'
