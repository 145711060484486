import { useMemo } from 'react';
import { Icon } from '../../components/Icons';
import { useKeyLocation } from '../../FunctionalAPI';
import { getActiveLanguage, T } from '../../Translate';
import { PageID } from '../../utils/PageLinking';
import { PageProps, usePageHeader } from '../Page';
import { PublicLink } from '../PublicViewPersonStory/PublicButton';
import styles from './index.module.scss';
import storyStyles from '../PublicViewPersonStory/index.module.scss';
import { Card, Col, Row } from "react-bootstrap";
import ImageCarrousel from '../PublicViewPersonStory/ImageCarrousel';
import { IPersonStory2 } from '../../models/PersonStory2';
import { MarkdownView } from '../../components/MarkdownView';
import PageLink from '../../components/PageLink';
import storyViewStyles from '../PublicViewPersonStory/StoryView.module.scss';
import { IKeyLocation } from '../../models/KeyLocation';
import { MapMarkerInput, MapMarkerInputMap, MapMarkerInputValue } from '../../components/inputs/MapMarkerInput';

interface ViewKeyLocationPageProps extends PageProps {
  id: string;
}

const namelistLinks: {[key: string]: string} = {
  'nl': 'https://www.inflandersfields.be/nl/kenniscentrum/namenlijst/',
  'en': 'https://www.inflandersfields.be/en/kenniscentrum-e/namenlijst-e',
  'fr': 'https://www.inflandersfields.be/fr/kenniscentrum-f/namenlijst-f',
  'de': 'https://www.inflandersfields.be/de/kenniscentrum-d/namenlijst-d'
};

const LocationWithPhotos: ('photos'|'stories')[] = ['photos', 'stories'];

export const ViewKeyLocationPage = (props: ViewKeyLocationPageProps) => {
  const { id, loading } = props;

  const keyLocation = useKeyLocation(loading, id, LocationWithPhotos);
  const keyStories = keyLocation?.stories || [];

  const subtitles = useMemo(() => [T('page.keyLocation.public.subtitle1'), T('page.keyLocation.public.subtitle2')], []);
  usePageHeader(
    PageID.PublicViewKeyLocation,
    id,
    undefined,
    T('page.keyLocation.public.title'),
    '',
    undefined,
    undefined,
    undefined,
    subtitles
  );

  return <>
    <div className={styles.header}>
      <div className='container'>
        <div style={{ float: 'right' }}>
          <PublicLink style={{ marginLeft: 10 }} url={`https://www.inflandersfields.be/lostkeys`}>
            {T('page.keyLocation.public.extraInformation')}
          </PublicLink>
        </div>
      </div>
    </div>
    {keyLocation && <KeyLocationView keyLocation={keyLocation} />}
    <div className='container'>
      <div className={storyStyles.timeline}>
        {keyStories.filter(story => story.published_date).map(story => (
          <StoryView key={story._id} story={story} />
        ))}
      </div>
    </div>
  </>;
}

interface KeyLocationViewProps {
  keyLocation: IKeyLocation;
}

function KeyLocationView(props: KeyLocationViewProps) {
  const { keyLocation } = props;

  const carrouselImages = useMemo(() => {
    return (keyLocation?.photos || []).map(photo => ({
      url: photo.urls.preview || photo.urls.original,
      fullSizeUrl: photo.urls.original
    }));
  }, [keyLocation]);

  return (
    <div className={storyViewStyles.story}>
      <div className='container'>
        <div className={storyViewStyles.storyWrapper}>
          <div className={storyViewStyles.storyLine} />
          <div className={storyViewStyles.storyIconWrapper}>
            <div className={storyViewStyles.storyIcon}>
              <i className={Icon.Key} />
            </div>
          </div>
          <div className={storyViewStyles.storyContent} style={{padding: '1em', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}>
            <MapMarkerInputMap
              containerElement={<div style={{ height: 300, width: 400 }} />}
              mapElement={<div style={{ height: `100%` }} />}
              readOnly
              position={MapMarkerInputValue.fromPosition(keyLocation.position)}
              defaultZoom={15}
              onChange={() => {}}
            />

            {/*<MarkdownView content={keyLocation.notes[getActiveLanguage()] || ''} />*/}
            {keyLocation && keyLocation.photos && keyLocation.photos.length > 0 && (
              <ImageCarrousel images={carrouselImages} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface StoryViewProps {
  story: IPersonStory2;
}

function StoryView(props: StoryViewProps) {
  const { story } = props;

  return (
    <div className={storyStyles.event}>
      <div className={storyStyles.eventLineTop} />
      <div className={storyStyles.eventLineBottom} />
      <div className={storyStyles.eventIconWrapper}>
        <div className={storyStyles.eventIcon}>
          <i className={Icon.User} />
        </div>
      </div>
      <div className={storyStyles.eventInfo}>
        <Card style={{ marginBottom: 0 }}>
          <Card.Header>
            <h4 style={{fontSize: '1.5rem'}}>
              {story.first_name} {story.family_name}
            </h4>
          </Card.Header>
          <Card.Body style={{ paddingTop: 0, display: 'flex', flexDirection: 'column' }}>
            <div className={styles.storyPreview}>
              {story.story_text?.[getActiveLanguage()]?.story_part_1 || ''}
            </div>
            <div style={{textAlign: 'right'}}>
              <PageLink page={PageID.PublicViewPersonStory} param={story._id}>
                {T('page.keyLocation.public.readStory')}
              </PageLink>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}