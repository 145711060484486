import React from 'react';
import BaseModal, { ModalProps, BaseModalState } from '../../modals/BaseModal';
import { IProject, IProjectFields, ProjectType } from '../../models/Project';
import { DateInputValue, DateInput } from '../../components/inputs/DateInput';
import { Modal, Form, Button } from 'react-bootstrap';
import { T } from '../../Translate';
import { TextInput } from '../../components/inputs/TextInput';
import { ISelectOption, SelectInput } from '../../components/inputs/SelectInput';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import api from '../../api';
import { getErrorDescription } from '../../models/ValidationResult';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { ModalHeader } from '../../modals/ModalHeader';

interface EditProjectModalProps extends ModalProps<IProjectFields> {
  project?: IProject;
}
interface EditProjectModalState extends BaseModalState {
  title: string;
  titleError?: string;
  type: ProjectType;
  typeError?: string;
  fromDate: DateInputValue;
  fromDateError?: string;
  toDate: DateInputValue;
  toDateError?: string;
  description: string;
  descriptionError?: string;
}
export default class EditProjectModal extends BaseModal<IProjectFields, EditProjectModalProps, EditProjectModalState> {
  typeOptions: ISelectOption[];

  constructor(props: EditProjectModalProps) {
    super(props);

    const project = props.project || {
      title: '',
      type: ProjectType.TemporaryExhibition,
      fromDay: null,
      fromMonth: null,
      fromYear: null,
      toDay: null,
      toMonth: null,
      toYear: null,
      description: ''
    };

    this.typeOptions = [
      { value: ProjectType.TemporaryExhibition, title: T('projectType.temporaryExhibition') }
    ];

    this.state = {
      visible: true,

      title: project.title,
      type: project.type,
      fromDate: {
        day: project.fromDay === null ? '' : project.fromDay.toString(),
        month: project.fromMonth === null ? '' : project.fromMonth.toString(),
        year: project.fromYear === null ? '' : project.fromYear.toString()
      },
      toDate: {
        day: project.toDay === null ? '' : project.toDay.toString(),
        month: project.toMonth === null ? '' : project.toMonth.toString(),
        year: project.toYear === null ? '' : project.toYear.toString()
      },
      description: project.description
    };
  }

  handleTitleChanged = (title: string) => {
    this.setState({
      title,
      titleError: undefined
    });
  }

  handleTypeChanged = (value: string) => {
    this.setState({
      type: value as ProjectType,
      typeError: undefined
    });
  }

  handleFromDateChanged = (date: DateInputValue) => {
    this.setState({ fromDate: date, fromDateError: undefined });
  }

  handleToDateChanged = (date: DateInputValue) => {
    this.setState({ toDate: date, toDateError: undefined });
  }

  handleDescriptionChanged = (description: string) => {
    this.setState({
      description,
      descriptionError: undefined
    });
  }

  handleClickedSave = () => {
    const { project } = this.props;
    const {
      title,
      type,
      fromDate,
      toDate,
      description
    } = this.state;

    const data: IProjectFields & { _id?: string } = {
      _id: project ? project._id : undefined,
      title,
      type,
      fromDay: fromDate.day ? parseInt(fromDate.day) : null,
      fromMonth: fromDate.month ? parseInt(fromDate.month) : null,
      fromYear: fromDate.year ? parseInt(fromDate.year) : null,
      toDay: toDate.day ? parseInt(toDate.day) : null,
      toMonth: toDate.month ? parseInt(toDate.month) : null,
      toYear: toDate.year ? parseInt(toDate.year) : null,
      description
    };
    api.validateProject(data, project ? 'update' : 'create').then(result => {
      if (result.status === 'OK') {
        this.resolve(data);
        return;
      }

      const errors = result.data || {};
      this.setState({ 
        titleError: getErrorDescription(errors.title),
        typeError: getErrorDescription(errors.type),
        fromDateError: getErrorDescription(errors.fromDay),
        toDateError: getErrorDescription(errors.toDay),
        descriptionError: getErrorDescription(errors.description)
      });
    });
  }

  renderModal() {
    const { project } = this.props;
    const {
      title,
      titleError,
      type,
      typeError,
      fromDate,
      fromDateError,
      toDate,
      toDateError,
      description,
      descriptionError
    } = this.state;

    return <>
      <ModalHeader>
        {T(project ? 'modal.editProject.editTitle' : 'modal.editProject.createTitle')}
      </ModalHeader>  
      <Modal.Body>
        <Form>
          <TextInput
            name='title'
            label={T('modal.editProject.title')}
            value={title}
            onChange={this.handleTitleChanged}
            error={titleError}
          />
          <SelectInput
            name='type'
            label={T('modal.editProject.type')}
            value={type}
            onChange={this.handleTypeChanged}
            error={typeError}
            options={this.typeOptions}
          />
          <DateInput
            name='from-date'
            label={T('modal.editProject.fromDate')}
            value={fromDate}
            onChange={this.handleFromDateChanged}
            error={fromDateError}
          />
          <DateInput
            name='to-date'
            label={T('modal.editProject.toDate')}
            value={toDate}
            onChange={this.handleToDateChanged}
            error={toDateError}
          />
          <TextareaInput
            name='description'
            label={T('modal.editProject.description')}
            value={description}
            onChange={this.handleDescriptionChanged}
            error={descriptionError}
          />
        </Form>
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='secondary' onClick={this.handleHide}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={this.handleClickedSave}>
          {T('generic.action.save')}{' '}
        </Button>
      </ButtonRowModalFooter>
    </>;
  }
}
