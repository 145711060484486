import React from 'react';
import moment from 'moment';
import { BasePage, PageState, PageProps } from '../Page';
import { Icon } from '../../components/Icons';
import api from '../../api';
import { IPersonFeedback, PersonFeedbackLoadOptions } from '../../models/PersonFeedback';
import { Card, ButtonGroup } from 'react-bootstrap';
import styles from './index.module.scss';
import { guessFileType, getLargeIcon } from '../../utils/MimeTypes';
import { IAttachment } from '../../models/Attachment';
import { MarkdownView } from '../../components/MarkdownView';
import { T } from '../../Translate';
import { Paginator } from '../../components/Paginator';
import { SortDirection } from '../../components/table/Table';
import { PageID } from '../../utils/PageLinking';
import PageLink from '../../components/PageLink';

interface AttachmentViewProps {
  attachment: IAttachment;
  onClickedAdd: (attachment: IAttachment) => void;
}
const AttachmentView = (props: AttachmentViewProps) => {
  const { attachment, onClickedAdd } = props;
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <img src={attachment.urls.preview || getLargeIcon(guessFileType(attachment.urls.original))} alt='preview' />
      <div  style={{ position: 'absolute', left: 0, right: 0, bottom: 0, textAlign: 'center' }}>
        <ButtonGroup>
          <a
            href={attachment.urls.original}
            className='btn btn-primary btn-sm btn-round'
            style={{ padding: '6px 8px', marginRight: 5 }}
            title={T('page.personFeedback.openFile')} target='_black'
          >
            <i className={Icon.Eye} />
          </a>
          <button
            className='btn btn-primary btn-sm btn-round'
            title={T('page.personFeedback.addAsDocument')}
            onClick={() => onClickedAdd(attachment)}
          >
            <i className={Icon.Plus} />
          </button>
        </ButtonGroup>
      </div>
    </div>
  );
}

interface FeedbackViewProps {
  feedback: IPersonFeedback;
  onClickedAddAttachment: (feedback: IPersonFeedback, attachment: IAttachment) => void;
}
const FeedbackView = (props: FeedbackViewProps) => {
  const { feedback, onClickedAddAttachment } = props;
  const attachments = (feedback.extend_attachments ?? []).map(attachment => 
    <AttachmentView
      key={attachment._id}
      attachment={attachment}
      onClickedAdd={attachment => onClickedAddAttachment(feedback, attachment)}
    />);
  return (
    <Card className={styles.card}>
      <Card.Header className={styles.title}>
        <span className={styles.name}>{feedback.name}</span> (<a href={"mailto:" + feedback.email}>{feedback.email}</a>) - on <PageLink page={PageID.ViewPerson} param={feedback.person_id}>{feedback.extend_person_name ?? '?'}</PageLink> ({moment(feedback.timestamp).format('DD/MM/YYYY HH:mm')})
      </Card.Header>
      <Card.Body>
        <MarkdownView content={feedback.description} />
      </Card.Body>
      {feedback.extend_attachments && feedback.extend_attachments.length > 0 && (
        <Card.Footer>
          {attachments}
        </Card.Footer>
      )}
    </Card>
  );
};

interface ListPersonFeedbackProps extends PageProps {

}
interface ListPersonFeedbackState extends PageState {
  filter: string;
  tablePage: number;
  sortColumn: string;
  sortDirection: SortDirection;

  total: number;
  items: IPersonFeedback[];
}
const PageSize = 10;

export default class ListPersonFeedback extends BasePage<ListPersonFeedbackProps, ListPersonFeedbackState> {
  refreshTimeout?: NodeJS.Timeout;

  constructor(props: ListPersonFeedbackProps) {
    super(props);

    this.state = {
      page: {
        id: PageID.ListPersonFeedback,
        icon: Icon.Comments,
        title: T('page.personFeedback.title'),
      },

      filter: '',
      tablePage: 0,
      sortColumn: 'timestamp',
      sortDirection: SortDirection.Down,

      total: 0,
      items: []
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.load();
  }

  getSortColumn(name: string) {
    return name;
  }

  load = () => {
    const { filter, tablePage, sortColumn, sortDirection } = this.state;
    return this.loading(api.findPersonFeedback(
      filter,
      tablePage * PageSize,
      PageSize,
      [[this.getSortColumn(sortColumn), sortDirection]],
      [PersonFeedbackLoadOptions.PersonName, PersonFeedbackLoadOptions.Attachments]
    ), 'feedback')
      .then(results => {
        this.setState({
          total: results.total,
          items: results.data
        })
      });
  }

  handlePageSelected = (page: number) => {
    this.setState({ tablePage: page }, this.load);
  }

  handleClickedAddAttachment = async (feedback: IPersonFeedback, attachment: IAttachment) => {
    /* functionality removed */
  }

  renderContent() {
    const { tablePage, items, total } = this.state;
    const itemViews = items.map(item => (
      <FeedbackView
        key={item._id}
        feedback={item}
        onClickedAddAttachment={this.handleClickedAddAttachment}
      />
    ));
    return <>
      <div style={{ marginTop: 20 }}>
        {itemViews}
      </div>
      <Paginator
        page={tablePage}
        pageSize={PageSize}
        shownItems={items.length}
        totalItems={total}
        onPageSelected={this.handlePageSelected}
        noun='personFeedback'
      />
    </>;
  }
}
