import React from 'react';
import { Person } from '../../models/Person';
import { T } from '../../Translate';

import styles from './index.module.scss';
import { TextStyles } from "../../config/styles";


interface PersonInfoViewProps {
  person: Person;
}

export const PersonInfoView = (props: PersonInfoViewProps) => {
  const { person } = props;
  const hasBorn = person.hasBornDate;
  const hasDied = person.hasDiedDate;
  const age = person.describeAge();
  const hasAge = age.length > 0;
  return (
    <div className={styles.personInfo}>
      <p>
        {hasBorn && <>&deg;{person.bornYear}</>}
        {hasBorn && hasDied && <> &mdash; </>}
        {hasDied && <>&dagger;{person.diedYear}</>}
        {hasAge && <> ({age})</>}
        {person.inNamelist && ` (${T('person.isNamelist')})`}
      </p>
      <p style={TextStyles.text.medium}>{person.familyInfo}</p>
    </div>
  );
}
