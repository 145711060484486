import React from 'react';
import { Link } from 'react-router-dom';
import { version } from '../../version.json';
import styles from './SideBar.module.scss';

export enum SideBarColor {
  Purple = 'purple',
  Blue = 'blue',
  Green = 'green',
  Orange = 'orange',
  Red = 'red',
}
interface SideBarProps {
  logo: string;
  title: string;
  backgroundImage: string;
  color: SideBarColor;
  children: JSX.Element|(JSX.Element|false|undefined)[]|false;
}
export const SideBar = (props: SideBarProps) => {
  const { logo, title, backgroundImage, color, children } = props;

  return (
    <div className='sidebar' data-color={color} data-image={backgroundImage} style={{ overflow: 'hidden' }}>
      <div className='sidebar-wrapper'>
        <div className='logo clearfix'>
          <Link className='simple-text logo-mini' to='/' style={{ width: 'auto', marginLeft: 5 }}>
            <img src={logo} alt='logo' />
          </Link>
          <Link className='simple-text logo-normal' to='/'>
            {title}
          </Link>
        </div>
        {children}
      </div>
      <div
        className='sidebar-background'
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
      <div className={styles.version}>{version}</div>
    </div>
  )
};
