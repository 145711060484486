import { useMemo, useState } from "react";
import { T } from "../../Translate";
import { FormCard } from "../../components/FormCard";
import { Icon } from "../../components/Icons";
import { SortDirection, Table } from "../../components/table/Table";
import { IKeyLocation } from "../../models/KeyLocation";
import { IPersonStory2 } from "../../models/PersonStory2";
import { PageID, getPageLinkWithVia } from "../../utils/PageLinking";
import { History } from 'history';
import { deletePersonStory } from "../ListPersonStories/actions";
import { getPersonStoryColumns } from "../ListPersonStories/Columns";
import { useModalContext } from "../../modals/ModalContext";

interface LocationStoriesTabProps {
  history: History;
  keyLocation?: IKeyLocation;
  refresh: () => void;
}

const None: never[] = [];
const PageSize = 10;

export function LocationStoriesTab(props: LocationStoriesTabProps) {
  const {history, keyLocation, refresh} = props;
  const {stories = None } = keyLocation || {};
  const [page, setPage] = useState(0);
  const [sortDirection, setSortDirection] = useState(SortDirection.Up);
  const [sortColumn, setSortColumn] = useState('name');
  const modals = useModalContext();

  const handleSortChanged = (column: string, direction: SortDirection) => {
    setSortColumn(column);
    setSortDirection(direction);
  };

  const displayedStories = useMemo(
    () => stories.slice(PageSize * page, PageSize * (page + 1)),
    [stories, page]
  );

  const handleClickedRow = (personStory: IPersonStory2) => {
    const link = getPageLinkWithVia(PageID.EditPersonStory, personStory._id, PageID.EditKeyLocation, keyLocation?._id);
    history.push(link);
  };

  const columns = useMemo(() => {
    const handleClickedDelete = (personStory: IPersonStory2) => {
      deletePersonStory(personStory, modals, () => {refresh();})
    };

    return getPersonStoryColumns(false, handleClickedDelete);
  }, [modals, refresh]);

  return (
    <FormCard icon={Icon.Link} title={T('page.keyLocation.tab.stories.title')}>
      {keyLocation ? (
        <Table
          columns={columns}
          displayedItems={displayedStories}
          noun='personStory'
          onPageChanged={setPage}
          sortDirection={sortDirection}
          sortColumn={sortColumn}
          onSortChanged={handleSortChanged}
          page={page}
          pageSize={PageSize}
          totalItems={stories.length}
          onClickItem={handleClickedRow}
        />
      ) : <p>{T('page.keyLocation.tab.stories.notSavedYet')}</p>}
    </FormCard>
  );
}
