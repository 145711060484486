import React, { useMemo } from 'react';
import { SpecialFunctionArguments, ISpecialFunction, ValidationErrors, SpecialFunctionArgument, SpecialFunctionInput, IRepeatableSpecialFunctionInput } from '../../models/SpecialFunction';
import { getSpecialInputClass, InputClass } from './SpecialFunctionInput';

interface SpecialFunctionFormProps {
  args: SpecialFunctionArguments;
  func: ISpecialFunction;
  errors: ValidationErrors;
  onArgumentsChange: (args: SpecialFunctionArguments) => void;
  renderRepeatable?: (field: IRepeatableSpecialFunctionInput, onClickedAdd: (type: string) => void) => JSX.Element|undefined;
}

export const SpecialFunctionForm = (props: SpecialFunctionFormProps) => {
  const { args, func, errors, onArgumentsChange, renderRepeatable } = props;
  const inputs: [SpecialFunctionInput, InputClass][] = useMemo(
    () => func.inputs.map(input => [input, getSpecialInputClass(input)]),
    [func]
  );
  const handleParameterChanged = (name: string, value: SpecialFunctionArgument) => {
    const newArguments = Object.assign({}, args, { [name]: value });
    onArgumentsChange(newArguments);
  };
  return (
    <div>
      {inputs.map(([input, Input]) => (
        <Input
          key={input.name}
          value={args[input.name]}
          name={input.name}
          onChange={handleParameterChanged}
          errors={errors}
          renderRepeatable={renderRepeatable}
        />
      ))}
    </div>
  );
}
