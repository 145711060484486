import React, { useState, useMemo } from 'react';
import { ModalProps, useModal } from '../../modals/BaseModal';
import { IMilitaryEntityStructureEvent, MilitaryEntityType } from '../../models/MilitaryEntity';
import { emptyDate, DateInput, inputDateToSortable, parseInputDateFromSortable } from '../../components/inputs/DateInput';
import { Modal, Form, Button } from 'react-bootstrap';
import { SelectInput, ISelectOption } from '../../components/inputs/SelectInput';
import { T } from '../../Translate';
import { MilitaryEntityInput } from '../../components/inputs/MilitaryEntityInput';
import api from '../../api';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { ModalHeader } from '../../modals/ModalHeader';
import { useStateWithError } from '../../utils/Functional';

interface EditStructureEventModalProps extends ModalProps<IMilitaryEntityStructureEvent|null> {
  index?: number;
  militaryEntityId?: string;
  event?: IMilitaryEntityStructureEvent;
}

const SelectableTypes = [MilitaryEntityType.Division, MilitaryEntityType.Brigade, MilitaryEntityType.Regiment];

export const EditStructureEventModal = (props: EditStructureEventModalProps) => {
  const { index, militaryEntityId, event } = props;

  const [isOpen, resolve] = useModal(props);
  const handleClose = () => resolve(null);

  const typeOptions: ISelectOption[] = useMemo(() => [
    { value: 'structure', title: T('militaryEntityEventType.sub.structure') },
    { value: 'attached', title: T('militaryEntityEventType.sub.attached') }
  ], []);
  const [type, setType] = useState<'structure'|'attached'>(event ? event.type : 'structure');
  const [fromDate, setFromDate, fromDateError, setFromDateError]
    = useStateWithError(event ? parseInputDateFromSortable(event.from_date) : emptyDate());
  const [toDate, setToDate, toDateError, setToDateError]
    = useStateWithError(event ? parseInputDateFromSortable(event.to_date) : emptyDate());
  const [entity, setEntity, entityError, setEntityError]
    = useStateWithError(event ? event.entity : undefined);
  const [description, setDescription] = useState(event ? event.description : '');

  const handleTypeChanged = (value: string) => setType(value as 'structure'|'attached');

  const handleClickedSave = async () => {
    const document: IMilitaryEntityStructureEvent = {
      army: entity ? entity.army : '',
      type,
      from_date: inputDateToSortable(fromDate),
      to_date: inputDateToSortable(toDate),
      entity_id: entity ? entity._id : '',
      entity: entity,
      description
    };
    const result = await api.validateMilitaryEntityHistory(
      document,
      index ? 'update' : 'create',
      militaryEntityId,
      index
    );
    if (result.status === 'OK') {
      resolve(document);
      return;
    }

    const errors = result.data || {};
    setFromDateError(errors.from_date);
    setToDateError(errors.to_date);
    setEntityError(errors.entity_id || errors.army);
  }

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <ModalHeader>Gebeurtenis: militaire organisatie</ModalHeader>
      <Modal.Body>
        <Form>
          <SelectInput
            label={T('page.militaryEntity.event.type')}
            name='type'
            value={type}
            onChange={handleTypeChanged}
            options={typeOptions}
          />
          <DateInput
            label={T('page.militaryEntity.event.from')}
            name='from'
            value={fromDate}
            onChange={setFromDate}
            error={fromDateError}
          />
          <DateInput
            label={T('page.militaryEntity.event.to')}
            name='to'
            value={toDate}
            onChange={setToDate}
            error={toDateError}
          />
          <MilitaryEntityInput
            label={T('page.militaryEntity.event.entity')}
            value={entity}
            onSelected={setEntity}
            types={SelectableTypes}
            error={entityError}
          />
          <TextareaInput
            vertical
            label={T('page.militaryEntity.event.description')}
            name='description'
            value={description}
            onChange={setDescription}
          />
        </Form>
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='primary' onClick={handleClickedSave}>
          {T('generic.action.save')}
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
