import commonmark from 'commonmark';

const markdownReader = new commonmark.Parser();
const markdownRenderer = new commonmark.HtmlRenderer();


function duplicateNewLines(input: string): string {
  // eslint-disable-next-line no-control-regex
  return input.replace(new RegExp('\n', 'g'), '\n\n');
}

export function markdown2html(markdown: string): { __html: string } {

  // Some hacking to convert a single newline to a new paragraph.
  // We just replace a single newline by a double newline before we
  // let is be parsed by the MarkdownReader.
  const newMarkdown = duplicateNewLines(markdown)
  const parsed = markdownReader.parse(newMarkdown);
  const rendered = markdownRenderer.render(parsed);

  return {__html: rendered };
}
