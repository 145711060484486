import audioIcon from '../assets/images/mimetypes/128/audio.png';
import binaryIcon from '../assets/images/mimetypes/128/binary.png';
import compressedIcon from '../assets/images/mimetypes/128/compressed.png';
import csvIcon from '../assets/images/mimetypes/128/csv.png';
import emptyIcon from '../assets/images/mimetypes/128/empty.png';
import excelIcon from '../assets/images/mimetypes/128/excel.png';
import htmlIcon from '../assets/images/mimetypes/128/html.png';
import imageIcon from '../assets/images/mimetypes/128/image.png';
import pdfIcon from '../assets/images/mimetypes/128/pdf.png';
import powerpointIcon from '../assets/images/mimetypes/128/powerpoint.png';
import svgIcon from '../assets/images/mimetypes/128/svg.png';
import textIcon from '../assets/images/mimetypes/128/text.png';
import unknownIcon from '../assets/images/mimetypes/128/unknown.png';
import videoIcon from '../assets/images/mimetypes/128/video.png';
import wordIcon from '../assets/images/mimetypes/128/word.png';

import audioIconTiny from '../assets/images/mimetypes/32/audio.png';
import binaryIconTiny from '../assets/images/mimetypes/32/binary.png';
import compressedIconTiny from '../assets/images/mimetypes/32/compressed.png';
import csvIconTiny from '../assets/images/mimetypes/32/csv.png';
import emptyIconTiny from '../assets/images/mimetypes/32/empty.png';
import excelIconTiny from '../assets/images/mimetypes/32/excel.png';
import htmlIconTiny from '../assets/images/mimetypes/32/html.png';
import imageIconTiny from '../assets/images/mimetypes/32/image.png';
import pdfIconTiny from '../assets/images/mimetypes/32/pdf.png';
import powerpointIconTiny from '../assets/images/mimetypes/32/powerpoint.png';
import svgIconTiny from '../assets/images/mimetypes/32/svg.png';
import textIconTiny from '../assets/images/mimetypes/32/text.png';
import unknownIconTiny from '../assets/images/mimetypes/32/unknown.png';
import videoIconTiny from '../assets/images/mimetypes/32/video.png';
import wordIconTiny from '../assets/images/mimetypes/32/word.png';

// only contains mimetypes known to us
export enum FileType {
  PDF,
  Word,
  Excel,
  PowerPoint,
  Compressed,
  Audio,
  Empty,
  Image,
  ImageSVG,
  Video,
  CSV,
  HTML,
  Text,
  Unknown,
  Binary,
}

const extensions: {[extension: string]: FileType} = {
  'pdf': FileType.PDF,
  'doc': FileType.Word,
  'docx': FileType.Word,
  'ppt': FileType.PowerPoint,
  'pptx': FileType.PowerPoint,
  'zip': FileType.Compressed,
  '7z': FileType.Compressed,
  'rar': FileType.Compressed,
  'tar': FileType.Compressed,
  'gz': FileType.Compressed,
  'tgz': FileType.Compressed,
  'mp3': FileType.Audio,
  'wav': FileType.Audio,
  'ogg': FileType.Audio,
  'jpg': FileType.Image,
  'png': FileType.Image,
  'jpeg': FileType.Image,
  'tif': FileType.Image,
  'tiff': FileType.Image,
  'svg': FileType.ImageSVG,
  'mp4': FileType.Video,
  'csv': FileType.CSV,
  'tsv': FileType.CSV,
  'html': FileType.HTML,
  'htm': FileType.HTML,
  'txt': FileType.Text,
  'dat': FileType.Binary
}

export function isImageType(filetype: FileType) {
  return filetype === FileType.Image || filetype === FileType.ImageSVG;
}

const mimetypes: {[mimetype: string]: FileType} = {
  'application/pdf': FileType.PDF,
  'application/msword': FileType.Word,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': FileType.Word,
  'application/vnd.ms-powerpoint': FileType.PowerPoint,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': FileType.PowerPoint,
  'application/zip': FileType.Compressed,
  'application/x-7z-compressed': FileType.Compressed,
  'application/vnd.rar': FileType.Compressed,
  'application/x-tar': FileType.Compressed,
  'application/gzip': FileType.Compressed,
  'image/svg+xml': FileType.ImageSVG,
  'text/csv': FileType.CSV,
  'text/html': FileType.HTML,
  'text/plain': FileType.Text,
  'application/octet-stream': FileType.Binary
}

export function guessFileType(filename: string): FileType {
  const dot = filename.lastIndexOf('.');
  if (dot < 0)
    return FileType.Empty;

  const extension = filename.substring(dot + 1).toLocaleLowerCase();
  const type = extensions[extension];
  return type === undefined ? FileType.Unknown : type;
}

export function getFileTypeFromMimeType(mimetype: string | undefined): FileType {
  if (!mimetype) {
    return FileType.Unknown
  }

  const knownType = mimetypes[mimetype];
  if (knownType !== undefined)
    return knownType;

  if (mimetype.startsWith('image/'))
    return FileType.Image;
  else if (mimetype.startsWith('audio/'))
    return FileType.Audio;
  else if (mimetype.startsWith('video/'))
    return FileType.Video;
  else
    return FileType.Unknown;
}

export function getLargeIcon(type: FileType): string {
  switch (type) {
    case FileType.PDF:
      return pdfIcon;
    case FileType.Word:
      return wordIcon;
    case FileType.Excel:
      return excelIcon;
    case FileType.PowerPoint:
      return powerpointIcon;
    case FileType.Compressed:
      return compressedIcon;
    case FileType.Audio:
      return audioIcon;
    case FileType.Empty:
      return emptyIcon;
    case FileType.Image:
      return imageIcon;
    case FileType.ImageSVG:
      return svgIcon;
    case FileType.CSV:
      return csvIcon;
    case FileType.HTML:
      return htmlIcon;
    case FileType.Text:
      return textIcon;
    case FileType.Binary:
      return binaryIcon;
    case FileType.Video:
      return videoIcon;
    case FileType.Unknown:
    default:
      return unknownIcon;
  }
}

export function getTinyIcon(type: FileType): string {
  switch (type) {
    case FileType.PDF:
      return pdfIconTiny;
    case FileType.Word:
      return wordIconTiny;
    case FileType.Excel:
      return excelIconTiny;
    case FileType.PowerPoint:
      return powerpointIconTiny;
    case FileType.Compressed:
      return compressedIconTiny;
    case FileType.Audio:
      return audioIconTiny;
    case FileType.Empty:
      return emptyIconTiny;
    case FileType.Image:
      return imageIconTiny;
    case FileType.ImageSVG:
      return svgIconTiny;
    case FileType.CSV:
      return csvIconTiny;
    case FileType.HTML:
      return htmlIconTiny;
    case FileType.Text:
      return textIconTiny;
    case FileType.Binary:
      return binaryIconTiny;
    case FileType.Video:
      return videoIconTiny;
    case FileType.Unknown:
    default:
      return unknownIconTiny;
  }
}
