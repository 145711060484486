import React from "react";
import { IMemorial } from "../../models/Memorial";
import { Place } from "../../models/Place";
import { getActiveLanguage } from "../../Translate";
import { Badge } from "react-bootstrap";

interface MemorialPlaceViewProps {
  memorial: IMemorial;
}

export const MemorialPlaceView = (props:  MemorialPlaceViewProps) => {
  const { memorial } = props;

  const memorialPlaceViewWithoutPlace = (memorial: IMemorial) => {
    return <span> - </span>
  }

  const memorialPlaceViewWithPlace = (memorial: IMemorial) => {
    // Show nothing if the memorial does not hold any place information
    if (!memorial.extend_place) {
      return <></>
    }

    // We will split the place description ( a comma separated list of strings ) on 2 lines
    const commaSeparatedString: string = new Place(memorial.extend_place).describe(getActiveLanguage())
    const elements = commaSeparatedString.split(', ')

    // We make a two views that we will show on these separate lines
    // the first view holds the first 2 elements of the place description
    // the second view holds the rest
    let firstViews: JSX.Element[] = [];
    let secondViews: JSX.Element[] = [];

    if (elements.length <= 2) {
      // just show the full place description if it does not exist of more then 2 elements
      firstViews.push(<span>{commaSeparatedString}</span>)
    } else {
      // join the first 2 elements with `, `
      const firstViewText = elements.slice(0,2).join(', ')

      // join all elements after the first 2 with `, `
      const secondViewText = elements.slice(2,elements.length).join(', ')

      // make a component view for it
      firstViews.push(<span>{firstViewText}</span>)
      secondViews.push(<span>{secondViewText}</span>)
    }

    return (
      <>
        <div>{firstViews}<br/>{secondViews}</div>
      </>
    )
  }

  // 2 different ways to display place info, 
  // based on if place info is available
  if (memorial.extend_place) {
    return memorialPlaceViewWithPlace(memorial);
  } else {
    return memorialPlaceViewWithoutPlace(memorial);
  }

}
