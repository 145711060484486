import 'bootstrap';
//import 'jquery';
//import 'bootstrap/dist/js/bootstrap';

import './assets/sass/light-bootstrap-dashboard.scss';
import './assets/sass/main.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '@fortawesome/fontawesome-pro/css/all.css';

// necessary for the menu collapse to work
import './assets/js/light-bootstrap-dashboard';

import { render } from 'react-dom';
import createHashHistory from 'history/createHashHistory';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import appReducers from './redux/Reducers';

import * as serviceWorker from './serviceWorker';
import API from './api';
import App from './App';
import { endContactMomentAction, startContactMomentAction } from './redux/Actions';
import PublicSearchApp from './PublicSearchApp';
import api from './api';

const history = createHashHistory();
const store = createStore(appReducers);

// synchronize active contact moment between windows/tabs
window.addEventListener('storage', event => {
  if (event.key === 'activeContactMoment') {
    if (event.newValue === null) {
      store.dispatch(endContactMomentAction());
    } else {
      store.dispatch(startContactMomentAction(JSON.parse(event.newValue)));
    }
  }
});

const app = window.location.pathname.includes('publicsearch') ? 'publicsearch' : 'main';
if (app === 'publicsearch')
  api.useGuestToken();

render(
  <Provider store={store}>
    {app === 'main' && <App history={history} />}
    {app === 'publicsearch' && <PublicSearchApp history={history} />}
  </Provider>,
  document.getElementById('app')
);

(window as any).api = API;


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
