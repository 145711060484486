import React from 'react';
import { BasePage, PageProps, PageState } from '../Page';
import { Icon } from '../../components/Icons';
import { T, plural, singular } from '../../Translate';
import api from '../../api';
import { MilitaryEntity, MilitaryEntityType, IMilitaryEntityChild, MilitaryEntityEvent } from '../../models/MilitaryEntity';
import { TopTabs, TopTab } from '../../components/TopTabs';
import { Person } from '../../models/Person';
import { IQueryResult } from '../../models/Generic';
import { ITableColumn, StringTableColumn } from '../../components/table/Fields';
import { generatePersonsColumns } from '../ListPersons';
import { Card } from 'react-bootstrap';
import { Table, SortDirection } from '../../components/table/Table';
import { MilitaryEntityEventView } from '../EditMilitaryEntity/MilitaryEntityEventView';
import { formatSortableDate } from '../../utils/Date';
import { ConfirmationModal } from '../../modals/Confirmation';
import { showNotification } from '../../utils/Notification';
import { EditLink } from '../../components/EditLink';
import { PageID, getPageLink, getPageVia } from '../../utils/PageLinking';
import ListPersonsTab from "./ListPersonsTab";
import ListDocumentsTab from "../ViewPlace/ListDocumentsTab";

interface ViewMilitaryEntityPageProps extends PageProps {
  id: string;
}
interface ViewMilitaryEntityPageState extends PageState {
  entity?: MilitaryEntity;
  tabs: TopTab[];

  personFilter: string;
  personTablePage: number;
  personSortDirection: SortDirection;
  personSortColumn: string;

  subEntitiesTablePage: number;
  subEntitiesSortDirection: SortDirection;
  subEntitiesSortColumn: string;

  persons?: IQueryResult<Person>;
  subEntities: IMilitaryEntityChild[];

  numberOfDocuments: number
}

const PageSize = 10;
export default class ViewMilitaryEntityPage extends BasePage<ViewMilitaryEntityPageProps, ViewMilitaryEntityPageState> {
  personFilterTimeout?: NodeJS.Timeout;
  personColumns: ITableColumn<Person>[];
  subEntityColumns: ITableColumn<IMilitaryEntityChild>[];

  constructor(props: ViewMilitaryEntityPageProps) {
    super(props);

    this.personColumns = generatePersonsColumns(
      this.handleClickedRemovePerson
    );
    this.subEntityColumns = [
      new StringTableColumn<IMilitaryEntityChild>(
        'regiment',
        T('page.militaryEntity.subEntityColumn.regiment'),
        item => item.military_entity.regiment || ''
      ),
      new StringTableColumn<IMilitaryEntityChild>(
        'unit',
        T('page.militaryEntity.subEntityColumn.unit'),
        item => (item.military_entity.unit_nr || '') + ' ' + item.military_entity.unit
      ),
      new StringTableColumn<IMilitaryEntityChild>(
        'from',
        T('page.militaryEntity.subEntityColumn.from'),
        item => formatSortableDate(item.from_date) || '*'
      ),
      new StringTableColumn<IMilitaryEntityChild>(
        'to',
        T('page.militaryEntity.subEntityColumn.to'),
        item => formatSortableDate(item.to_date) || '*'
      )
    ];

    this.state = {
      page: {
        id: PageID.ViewMilitaryEntity,
        entity: props.id,
        icon: Icon.Sitemap,
        title: '...'
      },
      tabs: [],
      personFilter: '',
      personTablePage: 0,
      personSortDirection: SortDirection.Up,
      personSortColumn: 'filter',
      subEntitiesTablePage: 0,
      subEntitiesSortDirection: SortDirection.Up,
      subEntitiesSortColumn: 'regiment',
      subEntities: [],
      numberOfDocuments:0
    };
  }

  async componentDidUpdate(oldProps: ViewMilitaryEntityPageProps, oldState: ViewMilitaryEntityPageState) {
    super.componentDidUpdate(oldProps, oldState);

    if (oldProps.id !== this.props.id) {
      this.loadContent();
    }

    if (oldState.entity?.id !== this.state.entity?.id) {
      this.loadNumberOfDocuments()
    }


  }

  loadContent() {
    const { id, via } = this.props;
    this.loading(api.getMilitaryEntity(id, ['history.entity', 'history.place']), singular('militaryEntity'))
      .then(entity => {
        const entityObject = new MilitaryEntity(entity);
        this.setState({
          page: {
            id: PageID.ViewMilitaryEntity,
            entity: id,
            icon: Icon.Sitemap,
            title: entityObject.name,
            via: via || 'military_entities'
          },
          entity: entityObject,
          tabs: this.getTabsForEntity(entityObject),
          persons: undefined
        }, () => {
          this.updatePersons();
          this.updateSubEntities();
        });
      });
  }

  loadNumberOfDocuments = async () => {
    if (!this.state.entity?.id)
      return

    const count = await api.countDocumentsRelatedTo('military_entity', this.state.entity.id);
    this.setState({...this.state, numberOfDocuments: count})
  }


  updatePersons = () => {
    const { personTablePage, entity } = this.state;
    if (!entity)
      return;

    if (this.personFilterTimeout) {
      clearTimeout(this.personFilterTimeout);
      this.personFilterTimeout = undefined;
    }
    this.loadPersons(personTablePage * PageSize, PageSize).then(data => {
      this.setState({
        persons: data,
        tabs: this.getTabsForEntity(entity)
      });
    });
  }

  updateSubEntities = () => {
    const { entity } = this.state;
    if (!entity)
      return;
    this.loading(api.getMilitaryEntitiesIn(entity.id), plural('militaryUnit')).then(subEntities => {
      this.setState({
        subEntities,
        tabs: this.getTabsForEntity(entity)
      });
    });
  }

  handleClickedRemovePerson = (person: Person) => {
    this.showModal<boolean>(props => (
      <ConfirmationModal
        title={T('modal.removePerson.title')}
        message={T('modal.removePerson.message', { name: person.name })}
        warning={T('generic.cannotUndo')}
        acceptText={T('generic.action.remove')}
        acceptStyle='danger'
        rejectText={T('generic.action.cancel')}
        {...props}
      />
    ))
      .then(confirmed => { confirmed && api.removePerson(person.id); })
      .then(() => {
        showNotification({ message: T('modal.removePerson.removed', { name: person.name }) });
        this.updatePersons();
      });
  }

  loadPersons = (skip: number, limit: number): Promise<IQueryResult<Person>|undefined> => {
    const { entity, personFilter, personSortColumn, personSortDirection } = this.state;
    if (entity && (entity.type === MilitaryEntityType.Unit || entity.type === MilitaryEntityType.Regiment)) {
      return this.loading(api.getMilitaryEntitySoldiers(
        entity.army,
        entity.regiment || '',
        entity.unit || null,
        entity.unitNr || null,
        personFilter,
        skip,
        limit,
        [[personSortColumn, personSortDirection]]
      ), plural('person')).then(persons => ({
        total: persons.total,
        data: persons.data.map(person => new Person(person))
      }));
    }

    return Promise.resolve(undefined);
  }

  getTabsForEntity(entity: MilitaryEntity): TopTab[] {
    const tabs: TopTab[] = [];
    if (entity.type === MilitaryEntityType.Unit || entity.type === MilitaryEntityType.Regiment) {
      tabs.push({
        id: 'persons',
        title: T('page.militaryEntity.tab.persons'),
        icon: Icon.Users,
        badgeContent: () => this.state.persons === undefined ? '...' : this.state.persons.total.toString(),
        content: this.renderPersonsTab
      });
    }
    tabs.push({
      id: 'events',
      title: T('page.militaryEntity.tab.events'),
      icon: Icon.ListUl,
      badgeContent: () => (entity.entity.history || []).length.toString(),
      content: this.renderEventsTab
    });
    tabs.push({
      id: 'documents',
      title: T('page.militaryEntity.tab.documents'),
      icon: Icon.ListUl,
      badgeContent: () => this.state.numberOfDocuments.toString(),
      content: this.renderDocumentsTab
    });
    if (entity.type !== MilitaryEntityType.Unit) {
      tabs.push({
        id: 'subentities',
        title: T('page.militaryEntity.tab.subEntities'),
        icon: Icon.Sitemap,
        badgeContent: () => this.state.subEntities.length.toString(),
        content: this.renderSubEntitiesTab
      });
    }
    return tabs;
  }

  handleSubEntitiesPageChanged = (page: number) => {
    this.setState({ subEntitiesTablePage: page }, this.updateSubEntities);
  }

  handleSubEntitiesSortChanged = (column: string, direction: SortDirection) => {
    this.setState({ subEntitiesSortColumn: column, subEntitiesSortDirection: direction });
  }

  handleClickedSubEntitiesRow = (entity: IMilitaryEntityChild) => {
    const { history } = this.props;
    history.push(getPageLink(PageID.ViewMilitaryEntity, entity.military_entity._id) + '?via=' + getPageVia(PageID.ViewMilitaryEntity, this.props.id));
  }

  renderPersonsTab = () => {
    return (
      <Card style={{ marginTop: '1em' }} className='table-with-links'>
        {api.user.canEdit() && <EditLink page={PageID.EditMilitaryEntity} param={this.props.id} />}
        <ListPersonsTab entity={this.state.entity}
                        history={this.props.history}
                        loading={this.props.loading}
                        modals={this.props.modals}
                        pending={this.props.pending} via={''}/>
      </Card>
    );
  }


  renderEventsTab = () => {
    const { entity } = this.state;
    if (!entity)
      return <div />;

    const history = entity.entity.history || [];
    const eventsRendered: JSX.Element[] = history.map(event => this.renderEvent(event));
    return (
    <Card style={{ marginTop: '1em', position: 'relative' }}>
      <Card.Header>
      {api.user.canEdit() && <EditLink page={PageID.EditMilitaryEntity} param={this.props.id} />}
      </Card.Header>
      <Card.Body>
      {eventsRendered}
      </Card.Body>
    </Card>);
  }

  renderDocumentsTab = () => {
    return (
      <div style={{position: 'relative'}}>
        {api.user.canEdit() && <EditLink page={PageID.EditMilitaryEntity} param={this.props.id} />}
        <ListDocumentsTab
          ref_id={this.state.entity?.id ?? ''}
          ref_name={this.state.entity?.name ?? ''}
          ref_type='military_entity'
          history={this.props.history}
          loading={this.props.loading}
          modals={this.props.modals}
          via={getPageVia(PageID.ViewMilitaryEntity, this.state.entity?.id)}
          readonly={true}
          pageStateKey='militaryDocumentation'
        />
      </div>

    );
  }

  renderEvent(event: MilitaryEntityEvent): JSX.Element {
    return (
      <MilitaryEntityEventView event={event} />
    );
  }

  subEntityRowKey = (entity: IMilitaryEntityChild) => entity._id;

  renderSubEntitiesTab = () => {
    const {
      subEntities,
      subEntitiesSortColumn,
      subEntitiesSortDirection,
      subEntitiesTablePage
    } = this.state;
    const pageEntities = subEntities.slice(
      subEntitiesTablePage * PageSize,
      (subEntitiesTablePage + 1) * PageSize
    );

    return (
      <Card style={{ marginTop: '1em' }} className='table-with-links'>
        <Card.Header>
          {api.user.canEdit() && <EditLink page={PageID.EditMilitaryEntity} param={this.props.id} />}
        </Card.Header>
        <Card.Body className='table-full-width'>
          <Table
            displayedItems={pageEntities}
            columns={this.subEntityColumns}
            rowKey={this.subEntityRowKey}
            onClickItem={this.handleClickedSubEntitiesRow}
            onPageChanged={this.handleSubEntitiesPageChanged}
            onSortChanged={this.handleSubEntitiesSortChanged}
            sortDirection={subEntitiesSortDirection}
            sortColumn={subEntitiesSortColumn}
            page={subEntitiesTablePage}
            pageSize={PageSize}
            totalItems={subEntities.length}
            noun='militaryEntity'
          />
        </Card.Body>
      </Card>
    );
  }

  renderContent() {
    return (
      <TopTabs
        id='view-military-entity'
        defaultActiveKey={this.state.tabs.some(x => x.id === 'persons') ? 'persons' : 'events'}
        tabs={this.state.tabs}
        storeKey='viewMilitary'
      />
    );
  }
}
