import { T, TranslationKey } from "../Translate";
import { IContactPerson } from "./ContactPerson";
import { IKeyLocation } from "./KeyLocation";
import { IPerson } from "./Person";

export enum StoryStatus {
  IN_PROGRESS = 'in progress',
  IN_REVIEW = 'in review',
  DONE = 'done'
}

export enum Template {
  TEMPLATE_1 = 1,
  TEMPLATE_2 = 2,
  TEMPLATE_3 = 3
}

export enum ImageType {
  PROFILE = 'profile',
  EXTRA = 'extra'
}

export enum Language {
  NL = 'nl',
  FR = 'fr',
  EN = 'en',
  DE = 'de',
}

export interface IPersonStoryText {
  story_part_1: string,
  story_part_2: string,
  story_status: StoryStatus,
  story_child_part_1: string,
  story_child_part_2: string,
  story_child_status: StoryStatus,
  language: Language
}

export interface IPersonStoryImage {
  source_image_url: string,
  processed_image_url: string,
  image_type: ImageType,
  x: number,
  y: number,
  width: number,
  height: number,
  grayscale: boolean,
  contrast: number,
  template: Template,
  upscaled: boolean,
  legend?: {[key: string]: string}|null
}

export interface IPersonStoryTextForLanguage {
  nl?: IPersonStoryText | null
  fr?: IPersonStoryText | null
  en?: IPersonStoryText | null
  de?: IPersonStoryText | null
}

export interface IPersonStory2 {
  _id: string;
  person_id_ref: string;
  person?: IPerson;
  first_name: string;
  family_name: string;
  born_year: string;
  died_year: string;
  community: string;
  area: string;
  country_code: string;
  place_source_event_ref?: string;
  story_status: StoryStatus;
  story_child_option: boolean;
  story_text?: IPersonStoryTextForLanguage | null;
  profile_image?: IPersonStoryImage | null;
  map_image?: string|null;
  extra_images?: IPersonStoryImage[] | null;
  coupling_use_events_with_places_refs?: string[] | null;
  coupling_use_died_date?: boolean | null;
  coupling_tags?: string[] | null;
  coupling_story_refs?: string[] | null;
  coupling_stories?: IPersonStory2[];
  published_date?: string;
  calculated_full_name?: string;
  calculated_filter?: string;
  key_location_id?: string | null;
  key_location?: IKeyLocation;
  key_date?: string;
  key_organisation_title?: string;
  linked_contact_persons?: ILinkedContactPerson[];
  targets: string[];
}

export interface ILinkedContactPerson {
  person: IContactPerson;
  title: string;
}

export enum PersonStoryTag {
  Art = 'art',
  Education = 'education',
  Science = 'science',
  Sport = 'sport',
  Politics = 'politics',
  Economy = 'economy',
  Religion = 'religion',
  Colonialism = 'colonialism',
  MedicalCare = 'medical_care',
  Aviation = 'aviation',
  PrisonerOfWar = 'prisoner_of_war',
  Dwangarbeid = 'forced_labor',
  Verzet = 'resistance',
  Desertie = 'desertion',
  Gas = 'gas',
  Battle1 = 'battle1',
  XMas1914 = 'xmas1914',
  Battle2 = 'battle2',
  MineBattle = 'mine_battle',
  Battle3 = 'battle3',
  SpringOffensive = 'spring_offensive',
  LiberationOffensive = 'liberation_offensive',
  MinorOperation = 'minor_operation',
  Reconstruction='reconstruction',
  Accident = 'accident',
}

export function translatePersonStoryTag(tag: PersonStoryTag) {
  return T('storytag.' + tag as TranslationKey);
}

export interface IPublishedPersonStory {
  _id: string;
  person_id_ref: string;
  first_name: string;
  family_name: string;
  born_year: string;
  died_year: string;
  community: string;
  area: string;
  country_code: string;
  story_child_option: boolean;
  story_text: IPersonStoryTextForLanguage;
  location: {
    type: 'Point',
    coordinates: [number, number]
  },
  profile_image_url: string;
  map_image_url: string;
  extra_images: {
    url: string;
    legend: {[key: string]: string};
  }[];
  event_type: string;
  name: string;
}

function removeWhitespaceLines(value: string): string {
  return value.split(/\n[ \t]+\n/g).join('\n\n');
}

export function fixWhitespaceLines(story: IPersonStory2): IPersonStory2 {
  if (story.story_text) {
    const keys = ['nl', 'en', 'fr', 'de'] as (keyof IPersonStoryTextForLanguage)[];
    for (var key of keys) {
      const text = story.story_text[key];
      if (!text)
        continue;
      
      text.story_part_1 = removeWhitespaceLines(text.story_part_1);
      text.story_part_2 = removeWhitespaceLines(text.story_part_2);
      text.story_child_part_1 = removeWhitespaceLines(text.story_child_part_1);
      text.story_child_part_2 = removeWhitespaceLines(text.story_child_part_2);
    }
  }
  return story;
}
