import api, { IAPIError } from "../api";
import { NotificationManager } from 'react-notifications';
import ValidationResult from "./ValidationError";


class GlobalErrorHandler {
  constructor() {
    this.registerAsApiErrorHandler()
  }

  registerAsApiErrorHandler() {
    api.onError = this.handleError;
  }

  handleError(error: IAPIError) {
    let message: string | undefined = error.message;
    if (error.code === 1013) {
      // validation error
      const validationResult = new ValidationResult(error.message)
      message = validationResult.notificationMessage()
    } else {
      const validationResult = new ValidationResult(error.message)
      if (!validationResult.name) {
        message = validationResult.notificationMessage()
      }
    }
    if (message) {
      NotificationManager.error(message,
        undefined,
        5000);
    }
  }
}


export default new GlobalErrorHandler();
