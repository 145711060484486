export enum PageID {
  None = 'none',

  ActivateAccount = 'aa',
  AdvancedSearch = 'as',
  CreateCollection = 'cc',
  CreateDocument = 'dc',
  CreateContactPerson = 'cpc',
  CreateMemorial = 'mc',
  CreateMilitaryEntity = 'mec',
  CreatePerson = 'pc',
  CreatePlace = 'plc',
  EditCollection = 'ce',
  EditDocument = 'de',
  EditContactMoment = 'cme',
  EditContactPerson = 'cpe',
  EditDocumentPage = 'dpe',
  EditKeyLocation = 'kle',
  EditMemorial = 'me',
  EditMilitaryEntity ='mee',
  EditPerson = 'pe',
  EditPersonStory = 'pse',
  EditPlace = 'ple',
  Home = 'h',
  ListCollections = 'cl',
  ListContactPersons = 'cpl',
  ListContactMoments = 'cml',
  ListDocumentation = 'dl',
  ListKeyLocations = 'kll',
  ListMemorials = 'ml',
  ListMilitaryEntities = 'mel',
  ListPersonFeedback = 'pfl',
  ListPersons = 'pl',
  ListPersonStories = 'psl',
  ListPlaces = 'pll',
  ListProjects = 'prl',
  ListUsers = 'ul',
  Login = 'l',
  PublicSearch = 'ps',
  PublicViewPerson = 'pvp',
  PublicViewPersonStory = 'pvps',
  PublicViewKeyLocation = 'pvkl',
  PublicViewPersonKeyStory = 'pvpk',
  PublicViewProject = 'pvpr',
  PublicViewStoryPair = 'pvsp',
  RecordContactMoment = 'cmr',
  RequestPasswordReset = 'prr',
  ResetPassword = 'rp',
  SpecialFunctions = 'sf',
  ViewCollection = 'cv',
  ViewMemorial = 'mv',
  ViewMilitaryEntity = 'mev',
  ViewPerson = 'pv',
  ViewPlace = 'plv',
  ViewProject = 'prv',
  ViewDocument='dv',
}

export class LinkToPage {
  page: PageID;
  param: string;

  constructor(page: PageID, param?: string) {
    this.page = page;
    this.param = param || '';
  }
}

export function getPageLinkWithVia(toPage: PageID, toParam: string, viaPage: PageID, viaParam?: string) {
  return [getPageLink(toPage, toParam), getPageVia(viaPage, viaParam)].join('?via=')
}

export function getPageVia(page: PageID, param?: string) {
  return param ? page + '~' + param : page;
}

export function getViaLink(via: string): string {
  if (via.includes('~')) {
    return getPageLink(via as PageID, '');
  } else {
    const parts = via.split('~', 2);
    return getPageLink(parts[0] as PageID, parts[1]);
  }
}

export function getPageLink(page: PageID, param: string) {
  switch (page) {
    case PageID.ActivateAccount:
      return '/activateAccount';
    case PageID.AdvancedSearch:
      return '/persons/search';
    case PageID.CreateCollection:
      return '/collections/create';
    case PageID.CreateDocument:
      return '/documents/create';
    case PageID.CreateContactPerson:
      return '/contact_persons/create';
    case PageID.CreateMemorial:
      return '/memorials/create'
    case PageID.CreateMilitaryEntity:
      return '/military_entities/create';
    case PageID.CreatePerson:
      return '/persons/create';
    case PageID.CreatePlace:
      return '/places/create';
    case PageID.EditCollection:
      return `/collections/${param}/edit`;
    case PageID.EditDocument:
      return `/documents/${param}`;
    case PageID.EditContactMoment:
      return `/contact_moments/${param}`;
    case PageID.EditContactPerson:
      return `/contact_persons/${param}`;
    case PageID.EditDocumentPage:
      const params = param.split('+');
      return `/documents/${params[0]}/${params[1]}`;
    case PageID.EditKeyLocation:
      return `/key_locations/${param}/edit`;
    case PageID.EditMemorial:
      return `/memorials/${param}/edit`;
    case PageID.EditMilitaryEntity:
      return `/military_entities/${param}/edit`;
    case PageID.EditPerson:
      return `/persons/${param}/edit`;
    case PageID.EditPersonStory:
      return `/personstories/${param}/edit`;
    case PageID.PublicViewKeyLocation:
      return `/public/key_locations/${param}`;
    case PageID.PublicViewPerson:
      return `/person/_id=${param}`;
    case PageID.PublicViewPersonStory:
      return `/public/personstories/${param}`;
    case PageID.PublicViewPersonKeyStory:
      return `/public/remembrancekey/${param}`;
    case PageID.PublicViewStoryPair:
      return `/public/storypair?${param}`;
    case PageID.EditPlace:
      return `/places/${param}/edit`;
    case PageID.Home:
      return '/';
    case PageID.ListCollections:
      return `/collections`;
    case PageID.ListContactPersons:
      return `/contact_persons`;
    case PageID.ListContactMoments:
      return `/contact_moments`;
    case PageID.ListDocumentation:
      return `/documentation`;
    case PageID.ListKeyLocations:
      return `/key_locations`;
    case PageID.ListMemorials:
      return `/memorials`;
    case PageID.ListMilitaryEntities:
      return `/military_entities`;
    case PageID.ListPersonFeedback:
      return `/persons/feedback`;
    case PageID.ListPersons:
      return `/persons`;
    case PageID.ListPersonStories:
      return `/personStories`;
    case PageID.ListPlaces:
      return `/places`;
    case PageID.ListProjects:
      return `/projects`;
    case PageID.ListUsers:
      return `/users`;
    case PageID.Login:
      return `/login`;
    case PageID.PublicSearch:
      return `/search`;
    case PageID.RecordContactMoment:
      return `/record_contact_moment`;
    case PageID.RequestPasswordReset:
      return `/requestPasswordReset`;
    case PageID.ResetPassword:
      return `/resetPassword`;
    case PageID.SpecialFunctions:
      return `/functions`;
    case PageID.ViewCollection:
      return `/collections/${param}`;
    case PageID.ViewMemorial:
      return `/memorials/${param}`;
    case PageID.ViewMilitaryEntity:
      return `/military_entities/${param}`;
    case PageID.ViewPerson:
      return `/persons/${param}`;
    case PageID.ViewPlace:
      return `/places/${param}`;
    case PageID.ViewProject:
      return `/projects/${param}`;
    case PageID.ViewDocument:
      return `/documents/${param}`;
    default:
      console.log('Unknown page ID:' + page);
      return ``; // fallback
    }
}
