import React, { useMemo, CSSProperties, useCallback } from 'react';
import { useElementID } from '../../utils/ElementID';
import { InputGroup } from './InputGroup';

interface SelectInputProps {
  options?: ISelectOption[];
  optionElements?: JSX.Element[];
  labelColumns?: number;
  inputColumns?: number;

  name: string;
  label: string;
  value: string;
  error?: string;
  readOnly?: boolean;
  validated?: boolean;
  controlStyle?: CSSProperties;
  style?: CSSProperties;

  onChange: (value: string) => void;
}

export interface ISelectOption {
  value: string;
  title: string;
}
export const SelectInput = (props: SelectInputProps) => {
  const {
    labelColumns = 4,
    inputColumns,
    name,
    label,
    value,
    error,
    readOnly,
    validated,
    controlStyle,
    style,
    options = [],
    optionElements,
    onChange,
  } = props;

  const id = useElementID('input');
  //const inputClass = readOnly ? 'form-control-plaintext' : (error ? 'form-control is-invalid' : (validated ? 'form-control is-valid' : 'form-control'));
  const inputClass = error ? 'form-control is-invalid' : (validated ? 'form-control is-valid' : 'form-control');
  const renderedOptions = useMemo(
    () => optionElements || options.map(option => (
      <option key={option.value} value={option.value}>{option.title}</option>
    )), [options, optionElements]);
  const handleChange = useCallback((e: React.SyntheticEvent<HTMLSelectElement>) => {
    onChange(e.currentTarget.value)
  }, [onChange]);

  return (
    <InputGroup
      id={id}
      labelColumns={labelColumns}
      inputColumns={inputColumns}
      label={label}
      error={error}
      style={style}
    >
      <select
        id={id}
        name={name}
        className={inputClass}
        value={value}
        disabled={readOnly}
        onChange={handleChange}
        style={controlStyle}
      >{renderedOptions}</select>
    </InputGroup>
  );
};

interface SelectInputOnlyProps {
  options?: ISelectOption[];
  optionElements?: JSX.Element[];

  value: string;
  readOnly?: boolean;
  validated?: boolean;

  onChange: (value: string) => void;

  style?: CSSProperties;
}

export const SelectInputOnly = (props: SelectInputOnlyProps) => {
  const {
    options = [],
    optionElements,
    value,
    readOnly,
    validated,
    onChange,
    style
  } = props;

  const id = useElementID('input');
  const inputClass = validated ? 'form-control is-valid' : 'form-control';
  const renderedOptions = useMemo(
    () => optionElements || options.map(option => (
      <option key={option.value} value={option.value}>{option.title}</option>
    )), [options, optionElements]);
  const handleChange = useCallback((e: React.SyntheticEvent<HTMLSelectElement>) => {
    onChange(e.currentTarget.value)
  }, [onChange]);

  return (
    <select
      id={id}
      className={inputClass}
      value={value}
      disabled={readOnly}
      onChange={handleChange}
      style={style}
    >{renderedOptions}</select>
  );
}
