import React, { useEffect, useState } from 'react';
import { ModalProps, useModal } from '../BaseModal';
import { T } from '../../Translate';
import { TextInput } from '../../components/inputs/TextInput';
import { Modal, Button } from 'react-bootstrap';
import { ModalHeader } from '../ModalHeader';
import { IDocument } from "../../models/IDocument";
import { ButtonLink } from "../../components/ButtonLink";
import { TextStyles } from "../../config/styles";
import MoveDocumentPreview from "./MoveDocumentPreview";
import api from "../../api";
import { CheckboxInput } from "../../components/inputs/CheckboxInput";



export interface IMoveToDocumentResult {
  document?: IDocument,
  root?: boolean
}

interface IMoveToDocumentModalProps extends ModalProps<IMoveToDocumentResult>{
  isSubDocument: boolean
}

const MoveToDocumentModal = (props: IMoveToDocumentModalProps) => {
  const [isOpen, resolve] = useModal(props);

  const [isLoading, setIsLoading] = useState(false);
  const [inputDocumentId, setInputDocumentId] = useState('');
  const [selectedDocumentId, setSelectedDocumentId] = useState<string|undefined>(undefined)
  const [documentForId, setDocumentForId] = useState<IDocument|undefined>(undefined)
  const [checkRoot, setCheckRoot] = useState(false)

  /*** api ***/

  const loadDocument = async (id: string | undefined) => {
    if (!id) {
      setDocumentForId(undefined)
      return
    }

    try {
      setIsLoading(true)
      const loadedDocument = await api.getCollectionDocument(id)
      setDocumentForId(loadedDocument)
    } catch (e) {
      setDocumentForId(undefined)
    } finally {
      setIsLoading(false)
    }
  }

  /*** effects ***/

  useEffect( () => {
    loadDocument(selectedDocumentId)
  },[selectedDocumentId])

  useEffect(() => setSelectedDocumentId(undefined), [inputDocumentId, checkRoot]);

  /*** Handlers ***/

  const handleSearchButtonPressed = async () => {
    if (inputDocumentId) {
      setSelectedDocumentId(inputDocumentId)
    }
  }

  const handleClose = () => resolve({document: undefined, root: false});

  const handleClickedExecute = () => resolve({document: documentForId, root: checkRoot})

  /*** Rendering ***/

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <ModalHeader>{T('modal.movedocument.title')}</ModalHeader>

      <Modal.Body>
        {/*Intro text */}
        <span style={{...TextStyles.text.large}}>
        {props.isSubDocument ? T('modal.movedocument.subdocument.message') : T('modal.movedocument.page.message')}
        </span>

        <form style={{ marginTop: '2em' }}>

          {/* Checkbox for root */}
          <CheckboxInput
            labelColumns={8}
            readOnly={false}
            label={T('model.movedocument.toRoot') + ':'}
            value={checkRoot}
            onChange={setCheckRoot}
          />

          {/*UUID input with error indication*/}
          <TextInput
            name='document-id'
            value={inputDocumentId}
            label={T('model.movedocument.documentId.label')}
            onChange={(input) => {
              setInputDocumentId(input)
            }}
            placeholder={T('model.movedocument.documentId.placeholder')}
            readOnly={checkRoot}
          />

          {/*Check button*/}
          <div style={{display: 'flex', flexDirection: 'row', justifyContent:'flex-end'}}>
            <ButtonLink onClick={handleSearchButtonPressed}>
              <span style={{ ...TextStyles.link.medium, paddingLeft: '0.5em' }}>{T('model.movedocument.documentId.search')}</span>
            </ButtonLink>
          </div>

          {/*Document Preview*/}
          <MoveDocumentPreview document={documentForId} isLoading={isLoading}/>

        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='primary' data-dismiss="modal">
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={handleClickedExecute} disabled={!documentForId && !checkRoot}>
          {T('generic.action.execute')}{' '}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MoveToDocumentModal;
