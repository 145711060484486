import React from 'react';
import { IDocumentElement, IDocumentSubdocument, IDocumentPage } from '../../models/Document';
import styles from './DocumentElementPreview.module.scss';
import { Icon } from '../../components/Icons';
import { classes } from '../../utils/Styles';
import { getLargeIcon, getFileTypeFromMimeType } from '../../utils/MimeTypes';
import { T } from '../../Translate';
import { Colors, TextStyles } from "../../config/styles";
import { Badge } from "react-bootstrap";
import { IndexedList } from "../../models/IndexedList";
import { DocumentTagDefinition } from "../../models/DocumentTagDefinition";
import { getTagName } from '../../models/DocumentTag';
import { DocumentElementPreviewErrorHeaderView } from "./DocumentElementPreviewErrorHeader";

function getPages(element: IDocumentSubdocument): IDocumentPage[] {
  if (element.document === undefined)
    return [];

  return element.document.elements.filter(e => e.type === 'page') as IDocumentPage[];
}

interface DocumentElementPreviewProps {
  index: number;
  element: IDocumentElement;
  selected: boolean;
  readOnly: boolean;
  onClick: () => void;
  onSelected: (selected: boolean) => void;
  onPreview?: (selected: IDocumentPage) => void;
  showTitle?: boolean
  tagDefinitions?: IndexedList<DocumentTagDefinition>
}

export const DocumentElementPreview = (props: DocumentElementPreviewProps) => {
  const {
    index,
    element,
    selected,
    readOnly,
    onClick,
    onSelected,
    onPreview,
    showTitle,
    tagDefinitions
  } = props;

  const badgeContent = (): string => {
    if (element.type === 'page') {
      return (index + 1).toString()
    } else {
      if (tagDefinitions && element.document && element.document.tags.length > 0) {
        return getTagName(element.document.tags[0], tagDefinitions)
      }
    }
    return T('document.no.tags')
  }

  return (
    <>
      <div className={styles.center}>
        {/*page background*/}
        <div className={styles.outer} onClick={onClick}>
          {/*content of the page */}
          {/*<div className={styles.elements}>*/}
            {element.type === 'page' && <PageElementPreview page={element} onPreview={onPreview} />}
            {element.type === 'subdocument' && <SubdocumentElementPreview subdocument={element} />}
            {!readOnly && (
              <span
                className={classes("fa-stack fa-2x", styles.checkbox)}
                onClick={e => {
                  e.stopPropagation(); // otherwise will act as a click on the page
                  onSelected(!selected);
                }}
              >
                <i className={classes(Icon.SolidSquare, 'fa-stack-2x')} style={{ color: 'white' }} />
                <i className={classes(selected ? Icon.CheckSquare : Icon.Square, 'fa-stack-2x')} />
              </span>
            )}
          {/*</div>*/}
        </div>
        { showTitle &&
          <div className={styles.center} style={{marginBottom:10}}>
            <Badge variant='secondary'>
              {badgeContent()}
            </Badge>
          </div> }
      </div>
    </>
  );
};

interface PagePreviewProps {
  page: IDocumentPage;
  onPreview?: (selected: IDocumentPage) => void;
}
const PageElementPreview = (props:  PagePreviewProps) => {
  const { page, onPreview } = props;


  const onClickedExpand = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    if (onPreview) {
      onPreview(page)
    }
  }

  const errorMessage = (): string | undefined => {
    if (!page.url_resized && !page.mimetype) {
      return `Error: No URL or Mimetype`
    } else {
      return undefined
    }
  }

  return <>
    <div className={styles.elements}>
      {errorMessage() &&
        <DocumentElementPreviewErrorHeaderView>
          Error: No URL or Mimetype
        </DocumentElementPreviewErrorHeaderView>
      }
    <img
      src={page.url_resized || getLargeIcon(getFileTypeFromMimeType(page.mimetype))}
      alt=''
      onDrag={e => { e.stopPropagation(); e.preventDefault(); }}
      onMouseDown={e => { e.stopPropagation(); e.preventDefault(); }}
      className={styles.nodrag}
      draggable={false}
    />
    <span className={styles.pageInfo}>
      {page.tags.length > 0 && (
        <span
          className='fa-stack'
          title={T('document.pageHasAnnotations', { count: page.tags.length.toString() })}>
          <i className={classes(Icon.SolidSquare, 'fa-stack-2x')} style={{ color: 'white' }} />
          <i className={classes(Icon.LightSquare, 'fa-stack-2x')} />
          <i className='fa-stack-1x'>{page.tags.length}</i>
        </span>
      )}
      {page.transcription && (
        <span className='fa-stack' title={T('document.pageHasTranscription')}>
          <i className={classes(Icon.SolidSquare, 'fa-stack-2x')} style={{ color: 'white' }} />
          <i className={classes(Icon.LightSquare, 'fa-stack-2x')} />
          <i className={classes(Icon.AlignJustify, 'fa-stack-1x')} />
        </span>
      )}

    </span>
    <div className={styles.expandIcon}>
      {page.mimetype && (
        <span className='fa-stack' title={''} onClick={onClickedExpand}>
          <i className={classes(Icon.ExpandArrowsAlt, 'fa-stack-2x')} style={{ color: Colors.darkGray }} />
        </span>
      )}
    </div>
    </div>
  </>;
}

interface SubdocumentPreviewProps {
  subdocument: IDocumentSubdocument;
}
const SubdocumentElementPreview = (props: SubdocumentPreviewProps) => {
  const { subdocument } = props;
  const pages = getPages(subdocument);
  const numberOfPages= pages.length
  if (numberOfPages === 0) {
    return (
      <>
        <div style={{height: '100%', display: "flex",
          flexDirection: "column",
          justifyContent: 'center'}}>
        <span style={{...TextStyles.text.large}}>{T('document.subdocument.empty.part1')}</span>
          <span style={{...TextStyles.text.large}}>{T('document.subdocument.empty.part2')}</span>
        </div>
      </>
    )
  } else {
    const showPages = pages.slice(0, 3)
    return (
      <>
        <div className={styles.elements}>
        {showPages.map((element, index) => {
          return (
            <div key={index} className={styles.subdocumentInner}>
              <img
                src={element.url_resized || getLargeIcon(getFileTypeFromMimeType(element.mimetype))}
                alt=''
                onDrag={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onMouseDown={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className={styles.nodrag}
                draggable={false}
              />
            </div>)
        })}
        {numberOfPages > 3 &&
          <div className={styles.subdocumentInner}>
            <span style={{...TextStyles.title.fatcapitals}}>+{numberOfPages - 3}</span>
          </div>
        }
        </div>
      </>
    );
  }
}
