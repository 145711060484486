import React, { useMemo } from 'react';
import { useElementID } from '../../utils/ElementID';
import { Button } from 'react-bootstrap';
import { Icon } from '../../components/Icons';
import { ISelectOption } from '../../components/inputs/SelectInput';

interface SelectInputProps {
  options: ISelectOption[];
  labelColumns?: number;
  inputColumns?: number;

  label: string;
  value: string;

  onChange: (e: React.SyntheticEvent<HTMLSelectElement>) => void;
  onClickedAdd: () => void;
}

export const SpecialFunctionTypeSelector = (props: SelectInputProps) => {
  const {
    labelColumns = 4,
    inputColumns,
    label,
    value,
    onClickedAdd,
    options,
    onChange,
  } = props;

  const id = useElementID('input');
  const renderedOptions = useMemo(
    () => options.map(option => (
      <option key={option.value} value={option.value}>{option.title}</option>
    )), [options]);
  return (
    <div className="form-group row">
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}</label>
      <div className={`col-sm-${inputColumns || (12 - labelColumns)}`}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <select
            id={id}
            name='special-function-type'
            className='form-control'
            value={value}
            onChange={onChange}
            style={{ width: 200 }}
          >{renderedOptions}</select>
          <Button size='sm' onClick={onClickedAdd} style={{ marginLeft: 10 }}>
            <i className={Icon.Plus} />
          </Button>
        </div>
      </div>
    </div>
  );
};
