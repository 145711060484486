import {Form, Image, Button} from "react-bootstrap";
import {FormCard} from "../../components/FormCard";
import {Icon} from "../../components/Icons";
import {T} from "../../Translate";
import {MapMarkerInput, MapMarkerInputValue} from "../../components/inputs/MapMarkerInput";
import {IAttachment} from "../../models/Attachment";
import {TextInput} from "../../components/inputs/TextInput";
import {TextareaInput} from "../../components/inputs/TextareaInput";
import {MapCoordinatesInput} from "../../components/inputs/MapCoordinatesInput";
import {IKeyLocation, KeyLocationNotes} from "../../models/KeyLocation";
import {AttachmentListView} from "../../components/AttachmentView";
import React, {useState} from "react";
import api from "../../api";
import {isDigit} from "../../utils/conversions";
import {LanguageSelector} from "../EditPersonStory/Stories/LanguageSelector";
import {Language} from "../../models/PersonStory2";
import { CheckboxInput } from "../../components/inputs/CheckboxInput";

interface KeyLocationTabProps {
  keyLocation?: IKeyLocation;
  name: string;
  nameError?: string;
  position: MapMarkerInputValue;
  positionError?: string;
  year: string;
  yearError?: string;
  notes: KeyLocationNotes;
  number: string;
  positionLocked: boolean;
  photos: IAttachment[];
  onNameChange: (name: string) => void;
  onPositionChange: (position: MapMarkerInputValue) => void;
  onYearChange: (year: string) => void;
  onNumberChange: (number: string) => void;
  onNotesChange: (notes: KeyLocationNotes) => void;
  onPositionLockedChange: (locked: boolean) => void;
  onPhotoCreated: (photo: IAttachment) => void;
  onPhotoDeleted: (photo: IAttachment) => void;
}

export function KeyLocationTab(props: KeyLocationTabProps) {
  const {
    keyLocation,
    name,
    nameError,
    position,
    positionError,
    year,
    yearError,
    notes,
    photos,
    number,
    positionLocked,
    onNameChange,
    onPositionChange,
    onYearChange,
    onNotesChange,
    onPhotoCreated,
    onPhotoDeleted,
    onNumberChange,
    onPositionLockedChange
  } = props;

  const [localLanguage, setLocalLanguage] = useState(Language.NL)

  const getQRUrl = (): string => {
    if (keyLocation === undefined) {
      return ''
    }
    return api.baseUrl + '/qr/keyLocation?token=' + api.token + '&id=' + keyLocation?._id + '&target=download'
  }

  const onNumberValueChangedChanged = (length: number, f: (v: string) => void) => {
    return (v: string) => {
      if (v.length > length)
        return;

      if (!isDigit(v))
        return;

      f(v);
    }
  }

  const onNotesChangedForLanguage = (notesForLanguage: string) => {
    let newNotes = {...notes};
    newNotes[localLanguage] = notesForLanguage;
    onNotesChange(newNotes);
  }

  const onUpdateLanguage = (newLanguage: Language) => {
    setLocalLanguage(newLanguage)
  }

  const leftForm = (
    <Form>
      <TextInput
        name='name'
        label={T('keyLocation.field.name')}
        value={name}
        error={nameError}
        placeholder={T('keyLocation.field.name')}
        onChange={onNameChange}
      />
      <TextInput
        name='number'
        label={T('keyLocation.field.number')}
        //{keyLocation ? keyLocation.number.toString() : T('page.keyLocation.number.tbd')}
        value={number.toString()}
        placeholder={T('keyLocation.field.number')}
        onChange={onNumberValueChangedChanged(3, onNumberChange)}
      />
      <TextInput
        name='year'
        label={T('keyLocation.field.year')}
        value={year}
        error={yearError}
        placeholder={T('keyLocation.field.year')}
        onChange={onNumberValueChangedChanged(4, onYearChange)}
      />
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col sm-4'}>
          </div>
          <div className={'col sm-8 text-right'}>
            <LanguageSelector
              onUpdateLanguage={onUpdateLanguage}
            />
          </div>
        </div>
      </div>

      <TextareaInput
        name='notes'
        label={T('keyLocation.field.notes')}
        value={notes[localLanguage]}
        onChange={onNotesChangedForLanguage}
        placeholder={T('keyLocation.field.notes')}
      />
    </Form>
  );

  const rightForm = (
    <Form>
      <MapMarkerInput
        label=''
        position={position}
        onChange={onPositionChange}
        defaultCenter={{lat: position.latAsNumber || 50.8512, lng: position.lonAsNumber || 2.8859}}
        defaultZoom={12}
        readOnly={positionLocked}
      />
      <CheckboxInput
        label={T('keyLocation.field.positionLocked')}
        value={positionLocked}
        onChange={onPositionLockedChange}
      />
      <MapCoordinatesInput
        name='coordinates'
        label={T('keyLocation.field.position')}
        position={position}
        onChange={onPositionChange}
        error={positionError}
      />
    </Form>
  );

  const QRCardEmptyContent = () => {
    return <>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12 text-center'}>
            {"No QRCode yet"}
          </div>
        </div>
      </div>
    </>
  }

  const QRCardFullContent = () => {
    return <>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12 text-center'}>
            <Image className={'img'} style={{'width': '104px', 'height': '104px'}}
                   src={api.baseUrl + '/qr/keyLocation?token=' + api.token + '&id=' + keyLocation?._id} alt={''}>
            </Image>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12 text-center'}>
            <Button variant={'link'} onClick={() => {
              window.open(getQRUrl(), '_blank')
            }}>
              <span><i className={'fas fa-download'}></i> {T('page.keyLocation.panel.qrcode.download')}</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  }

  const QRCardContent = () => {
    if (keyLocation && keyLocation._id) {
      return QRCardFullContent()
    } else {
      return QRCardEmptyContent()
    }
  }

  const QRCard = () => {
    return <>
      <FormCard icon={Icon.QR} title={T('page.keyLocation.panel.qrcode')}>
        {QRCardContent()}
      </FormCard>
    </>
  }


  return <>
    <div className='container'>
      <div className='row pt-3 align-items-stretch'>
        {/*<Row style={{alignItems: 'stretch', paddingTop: 15}}>*/}
        <div className={'col-md-6'}>
          <FormCard
            style={{height: '100%'}}
            icon={Icon.Info}
            title={T('page.keyLocation.panel.keyLocation')}
          >
            {leftForm}
          </FormCard>
        </div>
        <div className={'col-md-6'}>
          <FormCard
            style={{height: '100%'}}
            icon={Icon.MapMarker}
            title={T('page.keyLocation.panel.position')}
          >
            {rightForm}
          </FormCard>
        </div>
      </div>
      <div className="row pt-3">
        <div className={'col-xl-8'}>
          <FormCard icon={Icon.Images} title={T('page.keyLocation.panel.photos')}>
            {keyLocation ? (
              <AttachmentListView
                attachments={photos}
                collection='key_locations'
                collectionId={keyLocation._id}
                onClickedDelete={onPhotoDeleted}
                onCreated={onPhotoCreated}
                maxItems={2}
              />
            ) : <span>{T('page.keyLocation.photos.saveFirst')}</span>}
          </FormCard>
        </div>
        <div className={'col-xl-4'}>
          {QRCard()}
        </div>
      </div>
    </div>
  </>;
}
