import React from 'react';
import { Card } from 'react-bootstrap';
import { T } from '../../../Translate';
import { IPersonRelation, Gender} from '../../../models/Person';
import { LinkedPersonView } from "./LinkedPersonView";
import { NoDataView } from "../NoDataView";


interface LinkedPersonListViewProps {
  relations: IPersonRelation[];
  gender: Gender;
}

export default (props: LinkedPersonListViewProps) => {
  const { relations, gender } = props;

  if (relations.length === 0)
    return <NoDataView text={T("page.person.noRelations")}/>

  return (
    <Card>
      <Card.Body>
        {
          relations.map((relation, index) =>
            <LinkedPersonView key={index} relation={relation} gender={gender} index={index} />)
        }
      </Card.Body>
    </Card>
  )
}
