import { Icon } from "../../components/Icons";
import { PersonStoryStatusViewForLanguage, PersonStoryStatusViewForLanguages } from "../EditPersonStory/Stories/PersonStoryStatusForLanguagesView";
import { StoryIndex } from "../EditPersonStory/Stories/StoryTextView";
import { IPersonStory2, Language } from "../../models/PersonStory2";
import { Badge } from "react-bootstrap";
import { T } from "../../Translate";

interface PersonStoryStatusViewProps {
  personStory: IPersonStory2
}
export default function PersonStoryStatusView(props: PersonStoryStatusViewProps) {
  const {personStory} = props
  return (
    <div >
      <div style={{display: "flex", justifyContent: "left", alignItems: "center"}}>
        <i style={{textAlign: "center", width: 15}} className={Icon.Man} />
        <span >
          <PersonStoryStatusViewForLanguages personStory={personStory} storyIndex={StoryIndex.STORY_1}/>
        </span>
      </div>
      <div style={{display: "flex", justifyContent: "left", alignItems: "center"}}>
        <i style={{textAlign: "center", width: 15}} className={Icon.Baby} />
        { personStory.story_child_option &&
        <span >
          <PersonStoryStatusViewForLanguages personStory={personStory} storyIndex={StoryIndex.STORY_CHILD_1}/>
        </span> }
        { !personStory.story_child_option &&
        <span >
          <Badge variant='light'>
            {T('personstories.no.storychild')}
          </Badge>
        </span> }
      </div>
    </div>
  );
}

interface PersonStoryLanguageStatusViewProps {
  personStory: IPersonStory2,
  language: Language;
}
export function PersonStoryLanguageStatusView(props: PersonStoryLanguageStatusViewProps) {
  const {personStory, language} = props
  return (
    <div >
      <div style={{display: "flex", justifyContent: "left", alignItems: "center"}}>
        <i style={{textAlign: "center", width: 15}} className={Icon.Man} />
        <span >
          <PersonStoryStatusViewForLanguage personStory={personStory} storyIndex={StoryIndex.STORY_1} language={language as Language} />
        </span>
      </div>
    </div>
  );
}

export function PersonStoryChildLanguageStatusView(props: PersonStoryLanguageStatusViewProps) {
  const {personStory, language} = props
  return (
    <div >
      <div style={{display: "flex", justifyContent: "left", alignItems: "center"}}>
        <i style={{textAlign: "center", width: 15}} className={Icon.Baby} />
        { personStory.story_child_option &&
        <span >
          <PersonStoryStatusViewForLanguage personStory={personStory} storyIndex={StoryIndex.STORY_CHILD_1} language={language}/>
        </span> }
        { !personStory.story_child_option &&
        <span >
          <Badge variant='light'>
            {T('personstories.no.storychild.short')}
          </Badge>
        </span> }
      </div>
    </div>
  );
}
