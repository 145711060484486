import React from 'react';
import { Icon } from './Icons';

interface MergeIconProps {
  item: { id: string };
  selected: { id: string }[];
}
export const MergeIcon = (props: MergeIconProps) => {
  const { item, selected } = props;
  const active = selected.find(x => x.id === item.id);
  return active ? (
    <span className='fa-stack fa-1x' style={{ marginLeft: -9, marginRight: -8, marginTop: -8, marginBottom: -6 }}>
      <i className={`${Icon.SolidSquare} fa-stack-2x`} />
      <i className={`${Icon.Merge} fa-stack-1x fa-inverse`} />
    </span>
  ) : <i className={Icon.Merge} style={{ marginLeft: 4, marginRight: 4 }} />;
}
