import React, { useState } from 'react';
import { Page } from './Page';
import { IQueryResult } from '../models/Generic';
import { ITableFilter, SortDirection, Table } from '../components/table/Table';
import { ITableColumn } from '../components/table/Fields';
import { Noun, T } from '../Translate';
import { Search } from '../components/Search';
import { Button, Card } from 'react-bootstrap';
import { Icon } from '../components/Icons';
import { PageID } from '../utils/PageLinking';
import { useDelayedUpdateEffect } from "../utils/UpdateEffects";

export interface ListState {
  filter: string;
  tablePage: number;
  sortDirection: SortDirection;
  sortColumn: string;
  filters: ITableFilter[];
}

const defaultRowKey = <T,>(item: T, index: number): number|string => index;

interface ListPageProps<T, TS> {
  id: PageID;
  entity?: string;
  icon: Icon;
  title: string;
  via?: string;
  noun: Noun;
  columns: ITableColumn<T, TS>[];
  items: IQueryResult<T>|undefined;
  state: ListState;
  tableState?: TS;
  updateState: (updates: Partial<ListState>) => void;
  rowKey?: (item: T, index: number) => number|string;
  pageSize?: number;
  onClickedRow?: (item: T) => void;
  onClickedAdd?: () => void;
  header?: JSX.Element;
  additionalHeader?: JSX.Element;
}
export function ListPage<T, TS = {}>(props: ListPageProps<T, TS>) {
  const {
    id,
    entity,
    icon,
    title,
    via,
    noun,
    columns,
    items,
    state,
    tableState = {} as TS,
    updateState,
    rowKey = defaultRowKey,
    pageSize = 10,
    onClickedRow,
    onClickedAdd,
    header,
    additionalHeader
  } = props;


  const { filter, tablePage, sortDirection, sortColumn, filters } = state;
  const [filterInput, setFilterInput] = useState(filter);

  const handlePageChanged = (page: number) => {
    updateState({ tablePage: page });
  };

  const handleSortChanged = (column: string, direction: SortDirection) => {
    updateState({ sortColumn: column, sortDirection: direction });
  };

  const handleFilter = (column: string, value: string|null) => {
    const newFilters = filters.filter(x => x.column !== column);
    if (value !== null) {
      newFilters.push({ column, value });
    }
    updateState({ filters: newFilters });
  };

  useDelayedUpdateEffect(
    () => updateState({ filter: filterInput, tablePage: 0 }),
    750,
    [filterInput]
  );

  const actualHeader = header || (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Search name='search' value={filterInput} onChange={setFilterInput} />
      {additionalHeader}
      <div style={{ flexGrow: 1 }} />
      {onClickedAdd && (
        <Button variant='primary' onClick={onClickedAdd}>
          {T('generic.list.add')}
        </Button>
      )}
    </div>
  );

  return (
    <Page id={id} entity={entity} icon={icon} title={title} via={via}>
      <Card style={{ marginTop: '1em' }} className='table-with-links'>
        <Card.Header>
          {actualHeader}
        </Card.Header>
        <Card.Body className='table-full-width'>
          {items && (
            <Table
              displayedItems={items.data}
              columns={columns}
              rowKey={rowKey}
              state={tableState}
              onClickItem={onClickedRow}
              onPageChanged={handlePageChanged}
              onSortChanged={handleSortChanged}
              onFilter={handleFilter}
              sortDirection={sortDirection}
              sortColumn={sortColumn}
              filters={filters}
              page={tablePage}
              pageSize={pageSize}
              totalItems={items.total}
              noun={noun}
            />
          )}
        </Card.Body>
      </Card>
    </Page>
  );
}
