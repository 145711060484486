import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { ModalProps, useModal } from '../../../modals/BaseModal';
import { Modal } from 'react-bootstrap';
import { ModalHeader } from '../../../modals/ModalHeader';
import { T } from '../../../Translate';
import { AttachmentFileUploader, DocumentFileUploader } from '../../../components/FileUploader';
import styles from './ChoosePhotoModal.module.scss';

import { IDocument } from '../../../models/Document';
import { NotificationManager } from 'react-notifications';
import { IDocumentTag } from '../../../models/DocumentTag';
import { IDocumentReference, DocumentReferenceType, DocumentReferenceRelation } from '../../../models/DocumentReference';
import { Person } from '../../../models/Person';
import api from '../../../api';
import { ChosenDocumentPhoto, ChosenPhoto } from './ChosenPhoto';
import ChoosePhotoItem from './ChoosePhotoItem';
import { Colors, TextStyles } from "../../../config/styles";
import { Icon } from "../../../components/Icons";
import { IAttachment } from '../../../models/Attachment';

interface ChoosePhotoModalProps extends ModalProps<ChosenPhoto|undefined> {
  person: Person;
  storyId: string;
  createAsDocument: boolean;
}

export default (props: ChoosePhotoModalProps) => {
  const { person, storyId, createAsDocument } = props;
  const [isOpen, resolve] = useModal(props);

  const handleDocumentUploaded = (document: IDocument|'NOK') => {
    if (document === 'NOK') {
      NotificationManager.error(T('page.personstory.editPersonPhoto.uploadFailed'));
    } else {
      resolve({ source: 'document', document, page: 0 });
    }
  };

  const handleAttachmentUploaded = (attachment: IAttachment[]|'NOK') => {
    if (attachment === 'NOK') {
      NotificationManager.error(T('page.personstory.editPersonPhoto.uploadFailed'));
    } else {
      resolve({ source: 'temporary', attachment: attachment[0] });
    }
  }

  const tags: IDocumentTag[] = useMemo(() => [{ tag: 'unprocessedConverted', fields: {} }], []);
  const references: IDocumentReference[] = useMemo(() => [{
    _id: person.id,
    type: DocumentReferenceType.Person,
    name: person.name,
    relation: DocumentReferenceRelation.None,
    comment: ''
  }], [person]);

  const handleClickedPhoto = useCallback((item: ChosenPhoto) => {
    resolve(item);
  }, [resolve]);

  const [documents, setDocuments] = useState<IDocument[]>([]);
  useEffect(() => {
    api.getDocumentsRelatedTo('person', person.id, ['generateUrl']).then(setDocuments);
  }, [person.id]);
  const pages: ChosenDocumentPhoto[] = useMemo(() => {
    const result: ChosenDocumentPhoto[] = [];
    for (let document of documents) {
      document.elements.forEach((element, index) => {
        if (element.type === 'page' && element.url_resized !== null) {
          result.push({
            source: 'document',
            document,
            page: index
          });
        }
      });
    }
    return result;
  }, [documents]);

  const pageViews = useMemo(() => {
    return pages.map(page => (
    <ChoosePhotoItem
      key={page.document._id + '#' + page.page}
      item={page}
      onClicked={handleClickedPhoto}
    />
  ))}, [pages, handleClickedPhoto]);

  const documentsViews = useMemo(() => {
    if (pageViews.length === 0) {
      return (
        <div style={{height: 200, width: "100%"}}>
          <p style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
            <i style={{color: Colors.lightGray, fontSize: 52}} className={Icon.ProfileDocument}/>
            <span style={{textAlign: "center", width: "60%", ...TextStyles.text.medium}}>{T('personstories.images.noProfileDocumentsAvailable')}</span>
          </p>
        </div>
      )
    } else {
      return (
        <div className={styles.gallery}>
          {pageViews}
        </div>
      )
    }
  }, [pageViews])

  return (
    <Modal show={isOpen} size='lg' onHide={() => resolve(undefined)}>
      <ModalHeader>{T('page.personstory.editPersonPhoto.choosePhoto')}</ModalHeader>
      <Modal.Body>
        <h3 className={styles.header}>{T('page.personstory.editPersonPhoto.choosePhoto.fromDocumentation')}</h3>
        {documentsViews}
        <h3 className={styles.header}>{T('page.personstory.editPersonPhoto.choosePhoto.upload')}</h3>
        {createAsDocument ? (
          <DocumentFileUploader
            tags={tags}
            references={references}
            buttonStyle={{ width: 150, height: 200, borderRadius: 0 }}
            onSaved={handleDocumentUploaded}
          />
        ) : (
          <AttachmentFileUploader
            collection='temp_personstory'
            collectionId={storyId}
            buttonStyle={{ width: 150, height: 200, borderRadius: 0 }}
            onSaved={handleAttachmentUploaded}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
