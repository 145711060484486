import { T } from '../../../Translate';
import { ButtonLink } from "../../../components/ButtonLink";
import * as React from "react";


interface ResetToOriginalLinkProps {
    onClick: () => void
}

const buttonLinkStyle = {
  fontSize:'14px',
  textDecorationLine: 'underline'
}

export const ResetToOrginalLink = (props: ResetToOriginalLinkProps ) => (
    <ButtonLink onClick={props.onClick} style={buttonLinkStyle}>
      {T('page.personstory.reset.name')}
    </ButtonLink>)

