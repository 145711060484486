import React from 'react';
import styles from './AddPhotoControl.module.scss';

interface AddPhotoControlProps {
  onClick: () => void;
}
export default (props: AddPhotoControlProps) => {
  const { onClick } = props;
  return (
    <div className={styles.photo} onClick={onClick}>
      <div className={styles.wrapper}>
        <svg width="140" height="175" viewBox="0 0 156 205" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="0" width="150" height="200">
            <rect x="3.5" y="0.5" width="149" height="199" rx="3.5" fill="white" stroke="#D4D4D4"/>
          </mask>
          <g mask="url(#mask0)">
            <g transform="translate(62, 95)">
              <path d="M22.7461 14.3477H35.4375V23.5234H22.7461V37.8672H13.0781V23.5234H0.351562V14.3477H13.0781V0.601562H22.7461V14.3477Z" fill="#6B757D"/>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};
