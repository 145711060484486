import React, { useMemo } from 'react';
import { ChosenDocumentPhoto } from './ChosenPhoto';
import styles from './AddPhotoControl.module.scss';
import { IDocumentPage } from '../../../models/Document';

interface ChoosePhotoItemProps {
  item: ChosenDocumentPhoto;
  onClicked: (item: ChosenDocumentPhoto) => void;
}

export default (props: ChoosePhotoItemProps) => {
  const { item, onClicked } = props;
  const url = useMemo(
    () => (item.document.elements[item.page] as IDocumentPage).url_resized,
    [item]
  );

  return (
    <div className={styles.photoSelector}>
      <div className={styles.wrapper} onClick={() => onClicked(item)}>
        {url && <img src={url} alt='' />}
      </div>
    </div>
  );
}
