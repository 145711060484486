import React, { useState } from 'react';
import { T } from '../../Translate';
import { LoadingIcon, Icon } from '../../components/Icons';
import { Alert, Button, Card, Container, Form, Row } from 'react-bootstrap';
import { PageProps, usePageHeader } from '../Page';
import { VerticalInput } from '../../components/inputs/VerticalInput';
import api from '../../api';
import { NotificationManager } from 'react-notifications';
import { PageID } from '../../utils/PageLinking';

export default (props: PageProps) => {
  const { pending } = props;
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string|undefined>();
  usePageHeader(PageID.RequestPasswordReset, undefined, Icon.Page, '', '');

  const handleClickedSend = async () => {
    const result = await api.requestUserPasswordReset(email);
    if (result === 'NOT_FOUND') {
      setError(T('page.requestPasswordReset.notFound'));
    } else {
      setError(undefined);
      NotificationManager.success(T('page.requestPasswordReset.sent'));
    }
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Card style={{ marginTop: 100, width: 400 }}>
          <Card.Body style={{ padding: '2rem' }}>
            <h4 style={{ textAlign: 'center', fontSize: 17.5, fontWeight: 'bold' }}>{T('page.requestPasswordReset.title')}</h4>
            <hr />
            <p style={{ textAlign: 'justify' }}>{T('page.requestPasswordReset.message')}</p>
            <Form>
              <VerticalInput
                name='email'
                label={T('page.requestPasswordReset.email')}
                value={email}
                onChange={setEmail}
                placeholder={T('page.requestPasswordReset.email')}
              />
              {error && <Alert variant="danger">{error}</Alert>}
              <div style={{ textAlign: 'center', paddingTop: 20 }}>
                <Button variant='primary' onClick={handleClickedSend}>
                  {T('page.requestPasswordReset.send')}
                  {pending.length > 0 && <span>&nbsp;<LoadingIcon /></span>}
                </Button>
              </div>
            </Form>
            <hr />
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}
