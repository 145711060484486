import React, { useState, useEffect, useRef } from 'react';
import api from '../../api';
import { useElementID } from '../../utils/ElementID';
import { SortDirection } from '../table/Table';
import { InputGroup } from './InputGroup';
import { SimpleAutocomplete } from './SimpleAutocomplete';
import { IKeyLocation } from '../../models/KeyLocation';
import { ReferenceView } from '../../pages/EditCollectionDocument/ReferenceView';
import { DocumentReference, DocumentReferenceType } from '../../models/DocumentReference';

interface KeyLocationInputProps {
  labelColumns?: number;
  inputColumns?: number;

  label: string;
  error?: string;
  readOnly?: boolean;

  value: IKeyLocation|undefined;
  onChange: (keyLocation: IKeyLocation) => void;
  onClear: () => void;
}

export function KeyLocationInput(props: KeyLocationInputProps) {
  const {
    label,
    onChange,
    value,
    error,
    onClear,
    readOnly
  } = props;
  const id = useElementID('input');
  const [inputText, setInputText] = useState(value ? value.name : '');
  useEffect(() => setInputText(value ? value.name : ''), [value]);

  const handleSelected = (selected: IKeyLocation) => {
    setInputText(selected.name);
    onChange(selected);
  };

  return (
    <InputGroup id={id} {...props}>
      <KeyLocationInputComponent
        keyLocation={value}
        value={inputText}
        onSelected={handleSelected}
        onChange={setInputText}
        onClear={onClear}
        invalid={error !== undefined}
        placeholder={label}
        readOnly={readOnly}
      />
    </InputGroup>
  );
}

interface KeyLocationInputComponentProps {
  keyLocation: IKeyLocation|undefined;
  value: string
  onSelected: (value: IKeyLocation) => void;
  onChange: (value: string) => void;
  onClear: () => void;
  invalid?: boolean;
  placeholder?: string;
  readOnly?: boolean;
}

export function KeyLocationInputComponent(props: KeyLocationInputComponentProps) {
  const { keyLocation, value, onSelected, onChange, onClear, invalid, placeholder, readOnly = false } = props;
  const requestTimer = useRef<NodeJS.Timer>();

  const [options, setOptions] = useState<IKeyLocation[]>([]);

  const handleValueChanged = (value: string) => {
    // is this a valid uuid?
    let match = value.match(/.*([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/);
    if (match) {
      setOptions([]);
      api.getKeyLocation(match[1])
        .then(result => {
          if (match && result)
            handleSelected(match[1], result);
        });
    } else {
      scheduleSearch(value);
    }

    onChange(value);
  };

  const handleSelected = (value: string, item: IKeyLocation) => {
    onSelected(item);
    setOptions([]);
  };

  const scheduleSearch = (value: string) => {
    const searchKeyLocations = () => {
      requestTimer.current = undefined;
      api.findKeyLocations(value, 0, 10, [['filter', SortDirection.Up]])
        .then(entities => setOptions(entities.data));
    };
    
    if (requestTimer.current) {
      clearTimeout(requestTimer.current);
      requestTimer.current = undefined;
    }

    if (!value) {
      setOptions([]);
    } else {
      requestTimer.current = setTimeout(() => searchKeyLocations(), 500);
    }
  };

  return <>
    {keyLocation ? (
      <div style={{marginTop: 12}}>
        <ReferenceView
          item={new DocumentReference(keyLocation._id, DocumentReferenceType.KeyLocation, keyLocation.name)}
          readOnly={readOnly}
          onClickedRemove={onClear}
        />
      </div>

    ) : (
      <SimpleAutocomplete
        placeholder={placeholder}
        loading={requestTimer.current !== undefined}
        inputValue={value}
        onInputChange={handleValueChanged}
        onSelect={handleSelected}
        itemValue={item => item.number + ": " + item.name}
        itemKey={item => item._id}
        renderItem={item => item.name}
        options={options}
        invalid={invalid}
        readOnly={readOnly}
      />
    )}
  </>;
}
