import { KeyLocation } from "./KeyLocation";

export interface IAPIError {
  code: number | string;
  data?: string;
  message: string;
}

export type ActionType = 'create'|'update';

export function convertNonJSONRPCRequestError(error: any) {
  if ("readyState" in error && error.readyState === 0) {
    const noConnectionError: IAPIError = {
      code: "network",
      message: "No Network Response",
      data: undefined
    }
    return noConnectionError;
  }
  // handle http errors
  if ("status" in error && error.status >= 400) {
    const httpError: IAPIError = {
      code: 'http',
      message: error.statusText,
      data: undefined
    }
    return httpError
  }
  return error;
}

class KeyMapAPI {
  baseUrl: string;
  url: string;
  uploadAttachmentUrl: string;
  uploadPageUrl: string;
  uploadDocumentUrl: string;
  id: number;
  token: string | null;
  refreshingToken: Promise<any> | null;

  onError?: (error: IAPIError) => void;
  failHandler?: (error: IAPIError) => void;

  constructor(url: string) {
    this.baseUrl = url;
    this.url = url + "/v2";
    this.uploadAttachmentUrl = url + '/file/uploadImage';
    this.uploadPageUrl = url + '/uploadpage/uploadImage';
    this.uploadDocumentUrl = url  + '/uploaddocument/uploadImage';
    this.id = 1;
    this.token = localStorage.getItem('token') || null;

    this.refreshingToken = null;
  }

  setToken(token: string) {
    this.token = token;

    localStorage.setItem('token', token);
  }

  useGuestToken() {
    this.token = 'GUEST';
  }

  _requestTyped<T>(method: string, parameters: any, deserializer: (value: any) => T): Promise<T> {
    return this._request(method, parameters).then(result => deserializer(result));
  }

  async _request<T>(method: string, parameters: any): Promise<T> {
    const id = this.id++;

    let parametersWithToken = parameters;
    if (method !== 'authenticate2' && !parameters.token)
      parametersWithToken = {...parameters, token: this.token || 'GUEST'};

    const data = {
      id: id,
      jsonrpc: '2.0',
      method: method,
      params: parametersWithToken
    };
    try {
      const success = await this._doRequest(data);
      if (success.result !== undefined) {
        return success.result;
      } else if (success.error) {
        throw success.error;
      } else {
        throw Error('Unexpected response');
      }
    } catch (errorResponse) {
      const error = convertNonJSONRPCRequestError(errorResponse)

      if (this.onError) {
        this.onError(error);
      }
      if (this.failHandler)
        this.failHandler(error);

      throw error;
    }
  }

  _doRequest(request: any) {
    // actually equivalent (and doesn't need jquery) - but would need to be tested for error handling conditions
    return fetch(this.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(request),
    }).then(response => response.json());

    /*return Promise.resolve($.ajax({
      url: this.url,
      headers: {},
      type: 'POST',
      data: JSON.stringify(request),
      dataType: 'json',
      contentType: 'application/json; charset=utf-8'
    }));*/
  }

  getMapLocations(): Promise<KeyLocation[]> {
    return this._request(
      'keyLocationsForMap',
      {}
    ) as Promise<KeyLocation[]>;
  }
}

let url = "/api";
if (document.location.host.indexOf("localhost") === 0)
  url = "http://localhost:8080";

export default new KeyMapAPI(url);
