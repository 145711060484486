import React, { useMemo } from 'react';
import { IPlace, Place, getResolutionStatusColor } from '../../models/Place';
import styles from './PlaceInput.module.scss';
import { Icon } from '../Icons';
import PageLink from '../PageLink';
import { PageID } from '../../utils/PageLinking';
import { ButtonLink } from '../ButtonLink';

interface PlaceInfoProps {
  place: IPlace;
  target?: string;
  onClickedClear?: () => void;
}

export default (props: PlaceInfoProps) => {
  const { place, target, onClickedClear } = props;
  const name = useMemo(() => new Place(place).describe(), [place]);

  return (
    <div className={styles.placeLabel}>
      <PageLink page={PageID.ViewPlace} param={place._id} target={target}>
        <i className={Icon.MapMarker} style={{ color: getResolutionStatusColor(place.resolution_status)}} /> {name}
      </PageLink>
      {onClickedClear && (
        <ButtonLink style={{ marginLeft: 5 }} onClick={onClickedClear}>
          <i className={Icon.TimesCircle} />
        </ButtonLink>
      )}
    </div>
  );
}
