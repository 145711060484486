import React, { CSSProperties } from 'react';
import styles from './TextView.module.scss';

interface TextViewProps {
  text: String;
  style?: CSSProperties
}

export const TextView = (props: TextViewProps) => {
  const { text, style } = props;
  return (
    <span style={style} className={styles.text}>{text}</span>
  );
}
