import { useEffect, useState, useMemo } from 'react';
import { T } from '../Translate';
import { Person } from '../models/Person';
import { Document } from '../models/Document';
import { Place } from '../models/Place';
import api from '../api';
import { PageID } from '../utils/PageLinking';
import { MilitaryEntity } from '../models/MilitaryEntity';
import { PersonStoryHelper } from "../models/wrappers/PersonStoryHelper";

export enum PageLayout {
  Regular, // default
  NoFrames // used by login
}

function useBaseBreadcrumbs(via: string, viaTitle?: string): Breadcrumb[] {
  const [viaEntity, viaId] = via.split('~', 2);
  const [title, setTitle] = useState(viaTitle || '...');
  const [intermediateId, setIntermediateId] = useState('');

  useEffect(() => {
    if (viaTitle !== undefined) {
      setTitle(viaTitle);
      return;
    }

    setTitle('...');
    switch (viaEntity as PageID) {
      case PageID.ViewMilitaryEntity:
      case PageID.EditMilitaryEntity:
        if (viaId) {
          api.getMilitaryEntity(viaId)
            .then(entity => setTitle(new MilitaryEntity(entity).name));
        }
        break;
      case PageID.ViewCollection:
      case PageID.EditCollection:
        if (viaId) {
          api.getDocumentCollection(viaId)
            .then(collection => setTitle(collection.composedTitle()));
        }
        break;
      case PageID.EditDocument:
        if (viaId) {
          Promise.all([
            api.getCollectionDocument(viaId),
            api.getCollectionDocumentTagsIndexed()
          ]).then(([document, tags]) => {
            setTitle(Document.fromJSON(document, tags).title);
          });
        }
        break;
      case PageID.ViewPerson:
      case PageID.EditPerson:
      case PageID.PublicViewPerson:
          api.getPerson(viaId).then(person => setTitle(new Person(person).name));
        break;
      case PageID.EditPersonStory:
        if (viaId) {
          api.getPersonStory2(viaId)
            .then(personStory => setTitle(PersonStoryHelper.personName(personStory)));
        }
        break;
      case PageID.ViewPlace:
      case PageID.EditPlace:
        if (viaId) {
          api.getPlace(viaId).then(place => setTitle(new Place(place).name));
        }
        break;
      case PageID.ViewMemorial:
      case PageID.EditMemorial:
        if (viaId) {
          api.getMemorial(viaId).then(memorial => setTitle(memorial.name));
        }
        break;
      case PageID.EditContactPerson:
        if (viaId) {
          api.getContactPerson(viaId)
            .then(person => setTitle(person.firstName + ' ' + person.lastName));
        }
        break;
      case PageID.EditContactMoment:
        if (viaId) {
          api.getContactPersonEvent(viaId)
            .then(contactMoment => {
              setIntermediateId(contactMoment.contactPersonId);
              setTitle((contactMoment.personFirstName || '')
                + ' ' + (contactMoment.personLastName || ''));
            });
        }
        break;
      case PageID.ViewProject:
        if (viaId) {
          api.getProject(viaId)
            .then(project => setTitle(project.title));
        }
        break;
    }
  }, [viaEntity, viaId, viaTitle]);

  return useMemo(() => {
    switch (viaEntity as PageID) {
      case PageID.None:
        return [];
      case PageID.ListMilitaryEntities:
        return [new Breadcrumb(T('page.militaryEntities.title'), '/military_entities')];
      case PageID.ViewMilitaryEntity:
      case PageID.EditMilitaryEntity:
        return [
          new Breadcrumb(T('page.militaryEntities.title'), '/military_entities'),
          new Breadcrumb(title, `/military_entities/${viaId}`)
        ];
      case PageID.ListCollections:
        return [new Breadcrumb(T('page.collections.title'), '/collections')];
      case PageID.ViewCollection:
      case PageID.EditCollection:
        return [
          new Breadcrumb(T('page.collections.title'), '/collections'),
          new Breadcrumb(title, `/collections/${viaId}`)
        ];
      case PageID.ListDocumentation:
        return [new Breadcrumb(T('page.documentation.title'), '/documentation')];
      case PageID.EditDocument:
        return [
          new Breadcrumb(T('page.documentation.title'), '/documentation'),
          new Breadcrumb(title || T('page.document.untyped'), `/documents/${viaId}`)
        ];
      case PageID.ListPersonStories:
        return [new Breadcrumb(T('personstories.title'), '/personstories')];
      case PageID.ListPersons:
        return [new Breadcrumb(T('listPersons.title'), '/persons')];
      case PageID.ViewPerson:
      case PageID.EditPerson:
        return [
          new Breadcrumb(T('listPersons.title'), '/persons'),
          new Breadcrumb(title, `/persons/${viaId}`)
        ];
      case PageID.EditPersonStory:
        return [
          new Breadcrumb(T('personstories.title'), '/personstories'),
          new Breadcrumb(title, `/personstories/${viaId}`)
        ];
      case PageID.ListPlaces:
        return [new Breadcrumb(T('page.places.title'), '/places')]
      case PageID.ViewPlace:
      case PageID.EditPlace:
        return [
          new Breadcrumb(T('page.places.title'), '/places'),
          new Breadcrumb(title, `/places/${viaId}`)
        ];
      case PageID.ListMemorials:
        return [new Breadcrumb(T('memorials.title'), '/memorials')];
      case PageID.ViewMemorial:
      case PageID.EditMemorial:
        return [
          new Breadcrumb(T('memorials.title'), '/memorials'),
          new Breadcrumb(title, `/memorials/${viaId}`)
        ];
      case PageID.ListContactPersons:
        return [new Breadcrumb(T('page.listContactPersons.title'), '/contact_persons')];
      case PageID.ListContactMoments:
        return [new Breadcrumb(T('page.listContactMoments.title'), '/contact_moments')];
      case PageID.EditContactPerson:
        return [
          new Breadcrumb(T('page.listContactPersons.title'), '/contact_persons'),
          new Breadcrumb(title, `/contact_persons/${viaId}`)
        ];
      case PageID.EditContactMoment:
        console.log('intermediate:', intermediateId);
        return [
          new Breadcrumb(T('page.listContactPersons.title'), '/contact_persons'),
          new Breadcrumb(title, `/contact_persons/${intermediateId}`),
          new Breadcrumb(T('noun.contactMoment'), `/contact_moments/${viaId}`)
        ];
      case PageID.ViewProject:
        return [
          new Breadcrumb(T('page.projects.title'), '/projects'),
          new Breadcrumb(title, `/projects/${viaId}`)
        ];
      case PageID.ViewDocument:
        return [
          new Breadcrumb(T('page.document.title'), '/documents'),
          new Breadcrumb(title, `/documents/${viaId}`)
        ];
      case PageID.PublicSearch:
        return [
          new Breadcrumb(T('publicSearch.title'), '/search')          
        ];
    }

    console.log('Unknown page in via: ', viaEntity);
    return [];
  }, [viaEntity, viaId, title, intermediateId]);
}

export function useBreadcrumbs(title: string, via: string, viaTitle?: string) {
  const base = useBaseBreadcrumbs(via, viaTitle);
  return useMemo(
    () => via === 'none' ? [] : [...base, new Breadcrumb(title, '', true)],
    [base, title, via]
  );
}

export class Breadcrumb {
  title: string;
  to: string;
  active: boolean;

  constructor(title: string, to: string, active: boolean = false) {
    this.title = title;
    this.to = to;
    this.active = active;
  }
}
