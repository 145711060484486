import React, { useContext } from 'react';
import { History } from 'history';
import { Loading } from './Loading';

export interface IAppContext {
  history: History;
  loading: Loading;
}

// cheating here for the default value, as there should always be an AppContext
const Context = React.createContext<IAppContext>({} as IAppContext);

export function useAppContext(): IAppContext {
  return useContext(Context);
}

export const AppContextProvider = Context.Provider;
