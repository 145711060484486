import React, { CSSProperties } from 'react';
import { useElementID } from '../../utils/ElementID';

interface TextInputProps {
  name: string;
  labelColumns?: number;
  label: string;

  firstValue: string;
  firstPlaceholder?: string;
  firstColumns?: number;
  onChangeFirst: (value: string) => void;
  
  secondValue: string;
  secondPlaceholder?: string;
  secondColumns?: number;
  onChangeSecond: (value: string) => void;

  error?: string;
  readOnly?: boolean;
  validated?: boolean;
  style?: CSSProperties;
}

export const DualTextInput = (props: TextInputProps) => {
  const {
    name,
    labelColumns = 4,
    label,

    firstValue,
    firstPlaceholder,
    firstColumns = 4,
    onChangeFirst,

    secondValue,
    secondPlaceholder,
    secondColumns = 4,
    onChangeSecond,

    error,
    readOnly,
    validated,
    style
  } = props;

  const id1 = useElementID('input');
  const id2 = useElementID('input');
  const inputClass = error ? 'form-control is-invalid' : (validated ? 'form-control is-valid' : 'form-control');
  return (
    <div className="form-group row" style={style}>
      <label htmlFor={id1} className={`col-sm-${labelColumns} control-label`}>{label}</label>
      <div className={`col-sm-${firstColumns}`}>
        <input
          id={id1}
          name={name + '-a'}
          type='text'
          readOnly={readOnly}
          className={inputClass}
          value={firstValue}
          onChange={e => onChangeFirst(e.currentTarget.value)}
          placeholder={firstPlaceholder}
        />
      </div>
      <div className={`col-sm-${secondColumns}`}>
        <input
          id={id2}
          name={name + '-b'}
          type='text'
          readOnly={readOnly}
          className={inputClass}
          value={secondValue}
          onChange={e => onChangeSecond(e.currentTarget.value)}
          placeholder={secondPlaceholder}
        />
      </div>
    </div>
  );
};
