import { Icon } from "../../components/Icons";
import React from "react";
import { ContactMomentType, translateContactMomentType } from "../../models/ContactPerson";

interface ContactMomentTypeOutputProps {
  contactMomentType: ContactMomentType;
}
export const ContactMomentTypeOutput = (props: ContactMomentTypeOutputProps) => {
  const { contactMomentType } = props;
  const typeAsString = translateContactMomentType(contactMomentType)
  return (
    <span>{typeAsString}&nbsp;<i>({contactMomentType})</i></span>
  );
}
