import React from 'react';

import { DocumentReference, DocumentReferenceType } from '../../models/DocumentReference';
import { T, TranslationKey } from "../../Translate";
import { Badge } from "react-bootstrap";


interface ReferenceInputCellProps {
  document: DocumentReference,
  documentType: DocumentReferenceType,
  currentValue: string,
  highlighted?: boolean,    // when undefined, no highlight background colors are set
  renderName?: boolean      // when undefined or false, no name will be shown
}

function renderName(document: DocumentReference) {
  return <span>{document.name}</span>;
}

function renderDiedDate(document: DocumentReference) {
  let style = { marginLeft: 5 }
  const diedDate = document.info?.diedDate;
  if (diedDate && diedDate.year) {
    return (
      <Badge variant={'secondary'} style={style}>
        {' † ' + diedDate.day + '/' + diedDate.month + '/' + diedDate.year}
      </Badge>
    )
  } else {
    return <span></span>
  }
}

function renderServicerNumber(document: DocumentReference) {
  let style = { marginLeft: 5 }
  if (document.info?.serviceNumber) {
    return (
      <Badge variant={'danger'} style={style}>
        {document.info?.serviceNumber}
      </Badge>
    )
  } else {
    return <span></span>
  }
}

const ReferenceInputPersonCell = (props: ReferenceInputCellProps) => {
  const backgroundColor = props.highlighted === undefined ? undefined : props.highlighted === true ? 'lightgray' : 'white'
  return (
    <div key={props.document.id || 'create'} style={{
      fontSize: 14,
      background: backgroundColor,
      padding: '0.25em',
      cursor: 'default'
    }}>
    {(props.renderName === true || props.renderName === undefined) && renderName(props.document)}
    {renderServicerNumber(props.document)}
    {renderDiedDate(props.document)}
  </div>)
}

const ReferenceInputOtherCell = (props: ReferenceInputCellProps) => {
  const backgroundColor = props.highlighted === undefined ? undefined : props.highlighted === true ? 'lightgray' : 'white'
  return (<div key={props.document.id || 'create'} style={{
    fontSize: 14,
    background:backgroundColor,
    padding: '0.25em',
    cursor: 'default'
  }}>
    {props.document.toString()}
  </div>)
}

export const ReferenceInputCell = (props: ReferenceInputCellProps) => {
  const backgroundColor = props.highlighted === undefined ? undefined : props.highlighted === true ? 'lightgray' : 'white'
  if (props.document.id && props.documentType === DocumentReferenceType.Person) {
    return <ReferenceInputPersonCell {...props} />;
  } else if (props.document.id) {
    return <ReferenceInputOtherCell {...props} />;
  } else {
    return (
      <div key={props.document.id || 'create'} style={{
        fontSize: 14,
        background: backgroundColor,
        padding: '0.25em',
        cursor: 'default'
      }}>
        <i className='fa fa-plus' style={{marginRight: '0.5rem'}}/>
        {T(`reference.create.${props.documentType}` as TranslationKey) + ' ' + props.currentValue}
      </div>
    );
  }
}
