import React from 'react';
import { getActiveLanguage } from '../../Translate';
import { Card } from 'react-bootstrap';
import { DocumentTag, DocumentTagFieldValue } from '../../models/DocumentTag';
import styles from './TagView.module.scss';
import { Colors, TextStyles } from "../../config/styles";

/*function getTagFields(
  tag: DocumentTag,
  readOnly: boolean,
  onChangeField: (field: string, value: DocumentTagFieldValue) => void
): JSX.Element[] {
  return tag.allFields.map(field => (
    <TagField
      key={field.key}
      tag={tag.tag.id}
      field={field}
      value={tag.fields[field.key] || ''}
      readOnly={readOnly}
      onChange={(value: DocumentTagFieldValue) => onChangeField(field.key, value)}
    />
  ));
}*/

interface TagViewProps {
  tag: DocumentTag;
  removeTag: () => void;
  onChangeField: (field: string, value: DocumentTagFieldValue) => void;
  readOnly: boolean;
}
export const TagView = (props: TagViewProps) => {
  const { tag, removeTag, readOnly } = props;
  //const fields = getTagFields(tag, readOnly, onChangeField);

  return (
    <Card style={{backgroundColor: Colors.verylightGray}}>
      <Card.Body>
        <div className={styles.deleteButton}>
        {!readOnly && (
          <i
            className={"fas fa-times-circle"}
            onClick={removeTag}
          />
        )}
        </div>
        <span className={styles.tagtitle} style={{...TextStyles.subtitle.capitals}}>
          { (tag.tag.translations[getActiveLanguage()] || props.tag)}{' '}
        </span>
        <h6 className={styles.tagsubtitle}>{tag.fullName}</h6>
        {/* KURT: I putted the rendering of the tag fields. Dries wil dit, maar dit soort zaken knn wel eens veranderen */}
        {/*<Form.Group>*/}
        {/*    {fields}*/}
        {/*</Form.Group>*/}
      </Card.Body>
    </Card>
  );
};


