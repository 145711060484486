import { PagesState, ActivePageState, ISharedPageState, TabState, IExportModalState } from './State';
import { ActiveContactMoment } from '../models/ActiveContactMoment';
import { ContactMomentContribution } from '../models/ContactPerson';
import { useAppSelector, useAppDispatch } from '../utils/Functional';
import { useCallback } from 'react';

export enum AppActionType {
  DocumentEditorSetRatio = 'DOCUMENT_EDITOR_SET_RATIO',
  LoadDocumentTags = 'LOAD_DOCUMENT_TAGS',
  DocumentSetTagFieldValue = 'DOCUMENT_SET_TAG_FIELD_VALUE',
  LoadCollectionDocuments = 'LOAD_COLLECTION_DOCUMENTS',
  UpdatePagesState = 'UPDATE_PAGES_STATE',
  UpdateTabState = 'UPDATE_PAGES_STATE',
  StartContactMoment = 'START_CONTACT_MOMENT',
  UpdateContactMoment = 'UPDATE_CONTACT_MOMENT',
  RegisterContactMomentContribution = 'REGISTER_CONTACT_MOMENT_CONTRIBUTION',
  EndContactMoment = 'END_CONTACT_MOMENT',
  SetActivePage = 'SET_ACTIVE_PAGE',
  SetSharedPageState = 'SET_SHARED_PAGE_STATE',
  UpdateExportModalState = 'UPDATE_EXPORT_MODAL_STATE'
}

export interface SetDocumentEditorRatioAction {
  type: AppActionType.DocumentEditorSetRatio;
  payload: {
    ratio: number;
  }
}
export const setDocumentEditorRatio = (ratio: number): SetDocumentEditorRatioAction => {
  return {
    type: AppActionType.DocumentEditorSetRatio,
    payload: { ratio }
  };
}

export interface UpdatePagesStateAction {
  type: AppActionType.UpdatePagesState;
  payload: {
    page: keyof PagesState;
    value: any;
  }
}
export function updatePagesStateAction<K extends keyof PagesState>(page: K, value: Partial<PagesState[K]>): UpdatePagesStateAction {
  return {
    type: AppActionType.UpdatePagesState,
    payload: { page, value }
  };
}


export interface UpdateTabStateAction {
  type: AppActionType.UpdateTabState;
  payload: {
    page: string;
    value: string;
  }
}

export function updateTabStateAction(page: string, value: string): UpdateTabStateAction {
  return {
    type: AppActionType.UpdateTabState,
    payload: { page, value }
  };
}


export interface UpdateExportModalStateAction {
  type: AppActionType.UpdateExportModalState;
  payload: {
    updates: Partial<IExportModalState>
  }
}

export function updateExportModalStateAction(updates: Partial<IExportModalState>): UpdateExportModalStateAction {
  return {
    type: AppActionType.UpdateExportModalState,
    payload: {
      updates
    }
  };
}


export function usePageState<K extends keyof PagesState>(page: K): [PagesState[K], (updates: Partial<PagesState[K]>) => void] {
  const state = useAppSelector(state => state.pagesState[page]);
  const dispatch = useAppDispatch();
  const update = useCallback((updates: Partial<PagesState[K]>) => {
    dispatch(updatePagesStateAction(page, updates));
  }, [dispatch, page]);
  return [state, update];
}

export function useTabState(pageKey: string | undefined, initialValue: string | undefined): [string | undefined, (update: string) => void ] {
  const state = useAppSelector(state => {
    const index: keyof TabState = pageKey as keyof TabState;
    if (!pageKey || !index) {
      return initialValue
    } else {
      return state.tabsState[index]
    }
  })
  const dispatch = useAppDispatch()
  const update = (update: string) => {
    if (pageKey) {
      dispatch(updateTabStateAction(pageKey, update))
    }
  }
  return [state, update]
}

export interface StartContactMomentAction {
  type: AppActionType.StartContactMoment,
  payload: ActiveContactMoment
}
export function startContactMomentAction(contactMoment: ActiveContactMoment)
  : StartContactMomentAction {
  return {
    type: AppActionType.StartContactMoment,
    payload: contactMoment
  };
}

export interface UpdateContactMomentAction {
  type: AppActionType.UpdateContactMoment,
  payload: Partial<ActiveContactMoment>
}
export function updateContactMomentAction(
  updates: Partial<ActiveContactMoment>
) : UpdateContactMomentAction {
  return {
    type: AppActionType.UpdateContactMoment,
    payload: updates
  };
}

export interface RegisterContactMomentContributionAction {
  type: AppActionType.RegisterContactMomentContribution,
  payload: ContactMomentContribution
}
export function registerContactMomentContributionAction(
  contribution: ContactMomentContribution
): RegisterContactMomentContributionAction {
  return {
    type: AppActionType.RegisterContactMomentContribution,
    payload: contribution
  };
}

export interface EndContactMomentAction {
  type: AppActionType.EndContactMoment,
  payload: {}
}
export function endContactMomentAction(): EndContactMomentAction {
  return {
    type: AppActionType.EndContactMoment,
    payload: {}
  };
}

export interface SetActivePageAction {
  type: AppActionType.SetActivePage,
  payload: ActivePageState
}
export function setActivePage(state: ActivePageState): SetActivePageAction {
  return {
    type: AppActionType.SetActivePage,
    payload: state
  };
}

export interface SetSharedPageStateAction {
  type: AppActionType.SetSharedPageState,
  payload: ISharedPageState
}
export function setSharedPageState(state: ISharedPageState): SetSharedPageStateAction {
  return {
    type: AppActionType.SetSharedPageState,
    payload: state
  };
}

export type AnyAppAction
  = SetDocumentEditorRatioAction
  | UpdatePagesStateAction
  | UpdateTabStateAction
  | StartContactMomentAction
  | UpdateContactMomentAction
  | RegisterContactMomentContributionAction
  | EndContactMomentAction
  | SetActivePageAction
  | SetSharedPageStateAction
  | UpdateExportModalStateAction;
