import { IAttachment } from "./Attachment";

export interface IPersonFeedbackFields {
  person_id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  description: string;
  timestamp: string;
  language: string;
  status: PersonFeedbackStatus;
  documentIds?: string[];
  feedback_session?: string;
}

export interface IPersonFeedback extends IPersonFeedbackFields {
  _id: string;
  
  extend_person_name?: string;
  extend_attachments?: IAttachment[];
}

export enum PersonFeedbackStatus {
  Writing = 'writing',
  Submitted = 'submitted',
  Reviewed = 'reviewed'
}

export enum PersonFeedbackLoadOptions {
  PersonName = 'EXTEND_PERSON_NAME',
  Attachments = 'EXTEND_ATTACHMENTS'
}
