import { getErrorDescription, ValidationError } from "../models/ValidationResult";
import { T } from "../Translate";


class ValidationResult {

  error: string
  name: string | undefined
  code: string | undefined

  constructor(errorMessage: string) {
    this.error = errorMessage
    this.split()
  }

  getName(): string | undefined {
    return this.name
  }

  getErrorCode(): string | undefined {
    return this.code
  }

  isErrorWithName(): boolean {
    return this.name !== undefined
  }

  private split() {
    const splitted = this.error.split(',')
    if (splitted.length > 0) {
      const firstErrorString = splitted[0]
      const firstErrorStringSplitted = firstErrorString.split(':')
      if (firstErrorStringSplitted.length === 2) {
        this.name = firstErrorStringSplitted[0].trim()
        this.code = firstErrorStringSplitted[1].trim()
      } else if (firstErrorStringSplitted.length === 1) {
        this.name = undefined
        this.code = firstErrorStringSplitted[0].trim()
      } else {
        this.name = undefined
        this.code = undefined
      }
    }
  }

  notificationMessage(): string | undefined {
    let message = ''
    if (this.isErrorWithName()) {
      message = this.getName() + ': '
    }
    const codeErrorMessage = this.code?.toUpperCase()
    if (codeErrorMessage) {
      const validationErrorString = codeErrorMessage as ValidationError
      let errorDesription = getErrorDescription(validationErrorString) || this.code
      if (errorDesription === T('validationError.unknown')) {
        errorDesription = this.code
      }
      message += errorDesription
    }
    return message === '' ? undefined : message
  }
}

export default ValidationResult;
