import React, { ReactNode } from 'react';
import styles from './CenteredPane.module.scss';

interface CenteredPaneProps {
  children: ReactNode;
}
export const CenteredPane = (props: CenteredPaneProps) => {
  const { children } = props;
  return (
    <div className={styles.panel}>
      {children}
    </div>
  );
}
