import React, { useState } from 'react';
import { SimpleAutocomplete } from './SimpleAutocomplete';
import { useDelayedEffectWithLoading } from '../../utils/DelayedEffect';

interface StringAutocompleteProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  search: (value: string) => Promise<string[]>;
}

function identity(value: string): string {
  return value;
}

/**
 * This (simplified) autocomplete component is used to autocomplete a string
 * input with multiple suggestions. Similar interface as a regular input field,
 * but has a search function with is used to load a list of suggestions.
 */
export const StringAutocomplete = (props: StringAutocompleteProps) => {
  const { placeholder, value, onChange, search } = props;

  const [options, setOptions] = useState<string[]>([]);
  const [changed, setChanged] = useState(false);

  const handleChange = (value: string) => {
    setChanged(true);
    onChange(value);
  };

  const handleSelected = (value: string) => {
    setChanged(false);
    onChange(value);
  }

  const loading = useDelayedEffectWithLoading(() => {
    if (!changed)
      return Promise.resolve();
    
    return search(value).then(setOptions);
  }, [search, value], 500);

  return (
    <SimpleAutocomplete
      placeholder={placeholder}
      loading={loading}
      inputValue={value}
      onInputChange={handleChange}
      onSelect={handleSelected}
      options={options}
      itemKey={identity}
      renderItem={identity}
      itemValue={identity}
    />
  );
}
