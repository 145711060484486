import React, { useMemo } from 'react';
import { Breadcrumb as PageBreadcrumb } from '../../pages/PageBreadcrumb';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';

interface BreadcrumbItemProps {
  to: string;
  active: boolean;
  children: React.ReactNode;
}
const BreadcrumbItem = (props: BreadcrumbItemProps) => {
  const { to, active, children } = props;
  return (
    <li className={active ? 'breadcrumb-item active' : 'breadcrumb-item'}>
      {active ? children : <Link to={to}>{children}</Link>}
    </li>
  );
};

interface BreadcrumbsViewProps {
  breadcrumbs: PageBreadcrumb[];
}
export default (props: BreadcrumbsViewProps) => {
  const { breadcrumbs } = props;
  const views = useMemo(() => breadcrumbs.map((item, index) => {
    return <BreadcrumbItem key={index} active={item.active} to={item.to}>{item.title}</BreadcrumbItem>;
  }), [breadcrumbs]);
  return (
    <>
    { views && views.length > 1 && <Breadcrumb style={{ display: 'inline-block', fontSize: 14}}>
      {views}
    </Breadcrumb> }
    </>
  );
}
