import React, { useMemo } from 'react';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { T } from '../../Translate';
import { DocumentCategory, getDocumentCategoryName } from '../../models/Document';

const DocumentCategories: DocumentCategory[] = [
  DocumentCategory.Archive,
  DocumentCategory.Wise,
  DocumentCategory.Digital,
  DocumentCategory.Web,
  DocumentCategory.Adlib
];

interface AddDocumentationControlProps {
  onClickedAdd: (type: DocumentCategory) => void;
}
export default (props: AddDocumentationControlProps) => {
  const { onClickedAdd } = props;

  const items = useMemo(() => DocumentCategories.map(type => (
    <Dropdown.Item as='button' key={type} onClick={() => onClickedAdd(type)}>
      {getDocumentCategoryName(type)}
    </Dropdown.Item>
  )), [onClickedAdd]);
  return (
    <DropdownButton as={ButtonGroup} title={T('page.documentation.addDocument')} id='add-document-control'>
      {items}
    </DropdownButton>
  );
}
