import React, { CSSProperties } from 'react';
import { useElementID } from '../../utils/ElementID';
import { InvalidFeedback } from './InvalidFeedback';

interface TextInputProps {
  labelColumns?: number;
  inputColumns?: number;

  label: string;
  value: string;
  name: string;
  error?: string;
  readOnly?: boolean;
  plainText?: boolean;
  validated?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  info?: string;
  type?: 'text'|'number';

  onChange?: (value: string) => void;
  onPressedEnter?: () => void;
}

export const TextInputColumn = (props: TextInputProps) => {


  const {
    label,
    value,
    name,
    error,
    info,
    type = 'text',
    readOnly,
    plainText,
    validated,
    placeholder,
    style,
    onChange,
    onPressedEnter
  } = props;

  const handleKeyPress = onPressedEnter && ((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter')
      onPressedEnter();
  });
  const id = useElementID('input');
  const inputClass = plainText ? 'form-control-plaintext' : (error ? 'form-control is-invalid' : (validated ? 'form-control is-valid' : 'form-control'));
  return (
    <div className="form-group" style={style}>
      <label htmlFor={id} className={`control-label`}>{label}:</label>
      <div>
        <input
          id={id}
          type={type}
          name={name}
          readOnly={readOnly || plainText}
          className={inputClass}
          value={value}
          onChange={e => onChange && onChange(e.currentTarget.value)}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
        />
        {error && <InvalidFeedback error={error}/>}
        {info && <small className='form-text text-muted'>{info}</small>}
      </div>
    </div>
  );
};
