import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DocumentTagDefinition } from '../../models/DocumentTagDefinition';
import api from '../../api';
import { getActiveLanguage } from '../../Translate';
import { useElementID } from '../../utils/ElementID';
import { IndexedList } from '../../models/IndexedList';

export function getFullTagName(tags: IndexedList<DocumentTagDefinition>, tag: DocumentTagDefinition): string {
  const name = tag.translations[getActiveLanguage()];
  const parentTag = tag.parent && tags.indexed[tag.parent];
  if (parentTag) {
    const parentName = getFullTagName(tags, parentTag);
    return parentName + ' > ' + name;
  } else {
    return name;
  }
}

class FilterableDocumentTagType {
  id: string;
  tag: DocumentTagDefinition;
  name: string;

  constructor(tag: DocumentTagDefinition, tags: IndexedList<DocumentTagDefinition>) {
    this.id = tag.id;
    this.tag = tag;
    this.name = getFullTagName(tags, tag);
  }
}

interface DocumentTagTypeInputProps {
  selected?: DocumentTagDefinition;
  onSelected: (selected: DocumentTagDefinition|undefined) => void;
  disabledTags?: string[];
  placeholder?: string;
}
const NoDisabledTags: string[] = [];
export const DocumentTagTypeInput = (props: DocumentTagTypeInputProps) => {
  const { selected, onSelected, placeholder, disabledTags = NoDisabledTags } = props;
  const [availableTags, setAvailableTags] = useState<FilterableDocumentTagType[]>([]);
  useEffect(() => {
    api.getCollectionDocumentTagsIndexed().then(tags => {
      setAvailableTags(
        tags.list
          .filter(tag => tag.selectable && !disabledTags.includes(tag.id))
          .map(tag => new FilterableDocumentTagType(tag, tags))
      );
    });
  }, [disabledTags]);

  const id = useElementID('input');
  const selectedItem = selected && availableTags.find(x => x.id === selected.id);

  return (
    <Typeahead
      id={id}
      options={availableTags}
      labelKey='name'
      selected={selectedItem ? [selectedItem] : []}
      onChange={items => onSelected(items[0] ? items[0].tag : undefined)}
      placeholder={placeholder}
    />
  );
};
