import React, { CSSProperties } from 'react';
import styles from './PublicButton.module.scss';

interface PublicButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  style?: CSSProperties;
}

export const PublicButton = (props: PublicButtonProps) => {
  const { children, onClick, style } = props;

  const handleClicked = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick();
  }

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a className={styles.btn} onClick={handleClicked} style={style}>{children}</a>;
}


interface PublicLinkProps {
  children: React.ReactNode;
  url: string;
  style?: CSSProperties;
}

export const PublicLink = (props: PublicLinkProps) => {
  const { children, url, style } = props;
  return <a className={styles.btn} href={url} style={style} target='_blank' rel="noreferrer">{children}</a>;
}
