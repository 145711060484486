import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ModalProps, useModal } from './BaseModal';
import { T } from '../Translate';
import {
  IPlace,
  PlaceType,
  IPlaceFields,
  ResolutionStatus,
  toLatLon,
  createEmptyLanguages
} from '../models/Place';
import { ModalHeader } from './ModalHeader';
import { PlaceFormState, PlaceForm } from '../pages/EditPlace/PlaceForm';
import api from '../api';
import { NotificationManager } from 'react-notifications';
import { ButtonRowModalFooter } from '../components/ButtonRow';
import { MapMarkerInputValue } from '../components/inputs/MapMarkerInput';

interface CreatePlaceModalProps extends ModalProps<IPlace|undefined> {
  value: string;
}

export const CreatePlaceModal = (props: CreatePlaceModalProps) => {
  const [isOpen, resolve] = useModal(props);

  /*** States ***/

  const [formState, setFormState] = useState<PlaceFormState>({
    resolutionStatus: ResolutionStatus.Custom,
    alternativeNames:'',
    country: 'BE',
    type: PlaceType.Locality,
    languages: createEmptyLanguages(),
    suggestions: [],
    similar: [],
    location: MapMarkerInputValue.empty()
  });

  /*** Handlers ***/

  /**
   * Create Button handler
   */
  const handleClickedCreate = async () => {
    const {
      resolutionStatus,
      country,
      type,
      alternativeNames,
      location
    } = formState;

    const fields: IPlaceFields = {
      type,
      country_code: country,
      languages: formState.languages,
      alternative_names: alternativeNames.split(','),
      resolution_status: resolutionStatus,
      loc: toLatLon(location.lastValidPosition)
    };
    const result = await api.validatePlace(fields, 'create');
    if (result.status === 'NOK') {
      // errors... I don't see any validation requirements on server?
      return;
    }

    const place = await api.createPlace(fields);
    NotificationManager.success(T('page.editPlace.create.success'));
    resolve(place);
  };

  /**
   * Cancel Button handler
   */
  const handleClickedCancel = () => {
    resolve(undefined);
  };

  /**
   * Clicked a similar place
   * @param place
   */
  const handleClickedSimilar = (place: IPlace) => {
    resolve(place);
  };

  return (
    <Modal show={isOpen} onHide={handleClickedCancel} size='lg'>
      <ModalHeader>{T('modal.createplace.title')}</ModalHeader>
      <Modal.Body>
        <PlaceForm
          formState={formState}
          setFormState={setFormState}
          onClickedSimilar={handleClickedSimilar}
        />
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='secondary' onClick={handleClickedCancel}>
          {T('modal.createplace.cancel')}
        </Button>
        <Button variant='primary' onClick={handleClickedCreate}>
          {T('modal.createplace.create')}
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
