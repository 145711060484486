import { T } from "../Translate";
import { IPlace } from "./Place";

export interface IContactPersonFields {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  streetNumber: string;
  placeId: string;
  notes: string;
}

export interface IContactPerson extends IContactPersonFields {
  _id: string;
  place?: IPlace;
}

export interface BaseContactPersonEvent {
  _id: string,
  type: string,
  contactPersonId: string,
  start: string,  // ISO 6801 datetime string
  end: string,  // ISO 6801 datetime string

  personFirstName?: string;
  personLastName?: string;
}

export interface ContactPersonEventData extends  BaseContactPersonEvent {
  contactMethod?: string;
}

export enum ContactMomentType {
  Email = 'email',
  Phone = 'phone',
  Visit = 'visit',
  Feedback = 'feedback',
  Post = 'post'
}

export const ContactMomentTypes = [
  ContactMomentType.Email,
  ContactMomentType.Phone,
  ContactMomentType.Visit,
  ContactMomentType.Feedback,
  ContactMomentType.Post
];
export function translateContactMomentType(type: ContactMomentType): string {
  switch (type) {
    case ContactMomentType.Email:
      return T('contactMomentType.email');
    case ContactMomentType.Phone:
      return T('contactMomentType.phone');
    case ContactMomentType.Visit:
      return T('contactMomentType.visit');
    case ContactMomentType.Feedback:
      return T('contactMomentType.feedback');
    case ContactMomentType.Post:
      return T('contactMomentType.post');
    default:
      return type;
  }
}

export interface ContactMomentContactPersonEvent extends BaseContactPersonEvent {
  type: 'contact_moment',
  contactMethod: ContactMomentType,
  contributions: ContactMomentContribution[],
  transcript: string
}

export interface KeyStoryContactPersonEvent extends BaseContactPersonEvent {
  type: 'key_story',
  storyId: string
}

export interface ContactMomentContribution {
  type: 'person'|'document',
  targetId: string,
  name?: string
}

export type ContactPersonEvent = ContactMomentContactPersonEvent | KeyStoryContactPersonEvent;


export class ContactPersonEventHelper {
  static getContributionsForType(contactPersonEvent: ContactMomentContactPersonEvent, type: 'person'|'document'): ContactMomentContribution[] {
    return contactPersonEvent.contributions.filter(contribution => contribution.type === type)
  }
}
