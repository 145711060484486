import { IPerson } from "./Person";

export enum DocumentCollectionType {
  Box = 'box'
}

export enum DocumentCollectionStatus {
  Todo = 'todo',
  InProgress = 'in_progress',
  Done = 'done'
}

export interface IDocumentCollection {
  _id: string;
  type: DocumentCollectionType;
  title: string;
  description: string;
  status: DocumentCollectionStatus;
  adlibId: string;
  adlibCode: string;
  personId: string|null;
  person?: IPerson|null;
  deleted?: boolean;
}

export enum DocumentCollectionLoadOption {
  Person = 'EXTEND_PERSON'
}

export class DocumentCollection {
  id: string;
  type: DocumentCollectionType;
  title: string;
  description: string;
  status: DocumentCollectionStatus;
  adlibId: string;
  adlibCode: string;
  person?: IPerson|null;
  deleted: boolean;

  static fromJSON(json: IDocumentCollection) {
    return new DocumentCollection(
      json._id,
      json.type,
      json.title,
      json.description,
      json.status,
      json.adlibId,
      json.adlibCode,
      json.person,
      json.deleted
    );
  }

  static empty() {
    return new DocumentCollection(
      '',
      DocumentCollectionType.Box,
      '',
      '',
      DocumentCollectionStatus.Todo,
      '',
      '',
      undefined,
      false
    );
  }

  constructor(
    id: string,
    type?: DocumentCollectionType,
    title?: string,
    description?: string,
    status?: DocumentCollectionStatus,
    adlibId?: string,
    adlibCode?: string,
    person?: IPerson|null,
    deleted?: boolean
  ) {
    this.id = id;
    this.type = type || DocumentCollectionType.Box;
    this.title = title || '';
    this.description = description || '';
    this.status = status || DocumentCollectionStatus.Todo;
    this.adlibId = adlibId || '';
    this.adlibCode = adlibCode || '';
    this.person = person;
    this.deleted = deleted || false;
  }

  toJSON(): IDocumentCollection {
    return {
      _id: this.id,
      type: this.type,
      title: this.title,
      description: this.description,
      status: this.status,
      personId: this.person ? this.person._id : null,
      adlibId: this.adlibId,
      adlibCode: this.adlibCode
    };
  }

  composedTitle(): string {
    return this.adlibCode + ' - ' +  this.title;
  }
}
