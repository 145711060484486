import { T } from "../Translate";
import { ValidationError } from "./ValidationResult";

export interface ISpecialFunctionCategory {
  category: SpecialFunctionCategory;
  functions: ISpecialFunction[];
}

export enum SpecialFunctionCategory {
  General = 'GENERAL',
  Logs = 'LOGS',
  Memorials = 'MEMORIALS',
  Persons = 'PERSONS',
  Places = 'PLACES',
  PersonStories = 'PERSONSTORIES'
}

export function getSpecialFunctionCategoryName(category: SpecialFunctionCategory) {
  switch (category) {
    case SpecialFunctionCategory.General:
      return T('specialFunctionCategory.general');
    case SpecialFunctionCategory.Logs:
      return T('specialFunctionCategory.logs');
    case SpecialFunctionCategory.Memorials:
      return T('specialFunctionCategory.memorials');
    case SpecialFunctionCategory.Persons:
      return T('specialFunctionCategory.persons');
    case SpecialFunctionCategory.Places:
      return T("specialFunctionCategory.places");
    case SpecialFunctionCategory.PersonStories:
      return T('specialFunctionCategory.personStories');
    default:
      return category;
  }
}

export interface ISpecialFunction {
  name: string;
  displayName: string;
  description: string;
  columns: ISpecialFunctionColumn[],
  inputs: SpecialFunctionInput[]
}

export interface ISpecialFunctionColumn {
  field: string;
  title: string;
  sortable?: boolean;
  type?: 'date';
  width?: number;
}

export type SpecialFunctionInput = IBaseSpecialFunctionInput
  | IRepeatableSpecialFunctionInput
  | ISelectSpecialFunctionInput
  | ITextSpecialFunctionInput;

export interface IBaseSpecialFunctionInput {
  type: 'checkbox'|'country'|'date'|'datetime'|'location'|'repeatable'|'select'|'text';
  name: string;
  info: string;
  required: boolean;
  placeholder?: string;
}

export interface IBasicSpecialFunctionInput extends IBaseSpecialFunctionInput {
  type: 'checkbox'|'country'|'date'|'datetime'|'location';
}

export interface IRepeatableSpecialFunctionInput extends IBaseSpecialFunctionInput {
  type: 'repeatable';
  types: { name: string, inputs: SpecialFunctionInput[] }[]; // for repeatable
}

export interface ISelectSpecialFunctionInput extends IBaseSpecialFunctionInput {
  type: 'select';
  options: string[]; // for select
  titles?: {[key: string]: string};
}

export interface ITextSpecialFunctionInput extends IBaseSpecialFunctionInput {
  type: 'text';
  tailOption: boolean;
  autocomplete?: AutocompleteType;
}

export enum AutocompleteType {
  Place = 'place',
  Memorial = 'memorial'
}


export type SpecialFunctionArguments = {[key: string]: SpecialFunctionArgument};

export type SpecialFunctionArgument = 
    boolean
  | string
  | ISpecialFunctionDateArgument
  | ISpecialFunctionDateTimeArgument
  | ISpecialFunctionLocationArgument
  | ISpecialFunctionRepeatableArgument[]
  | ISpecialFunctionTextArgument;

export interface ISpecialFunctionDateArgument {
  day: string;
  month: string;
  year: string;
}
export interface ISpecialFunctionDateTimeArgument {
  day: string;
  month: string;
  year: string;
  hour: string;
  minute: string;
}
export interface ISpecialFunctionLocationArgument {
  lat: string;
  lon: string;
}
export interface ISpecialFunctionRepeatableArgument {
  type: string;
  inputs: SpecialFunctionArguments;
}
export interface ISpecialFunctionTextArgument {
  value: string;
  id?: string;
  mode: TextMode;
}

export enum TextMode {
  Complete = 'complete',
  Tail = 'tail',
  Head = 'head',
  Contains = 'contains',
  Selected = 'selected',
  NotEqual = 'not_equal',
  Empty = 'empty'
}

export type SpecialFunctionResult = ISpecialFunctionTextResult|ISpecialFunctionTableResult;

export interface ISpecialFunctionTextResult {
  type: 'text';
  content: string;
}
export interface ISpecialFunctionTableResult {
  type: 'table';
  elementType?: 'person'|'place'|'memorial'|'personStory';
  columns: ISpecialFunctionColumn[];
  data: SpecialFunctionTableItem[];
  total: number;
  actions?: string[];
}

export type SpecialFunctionTableItem = { [key: string]: SpecialFunctionTableElement };
export type SpecialFunctionTableElement = string|{}|{ day: number, month: number, year: number};

export interface ISpecialFunctionValidationResult {
  status: 'OK'|'NOK';
  data?: ValidationErrors;
  extra?: {}
}

export type ValidationErrors = {[key: string]: ValidationError|ValidationErrors[]};

export function getDefaultArguments(func?: ISpecialFunction): SpecialFunctionArguments {
  const result: SpecialFunctionArguments = {};
  if (!func)
    return result;
  
  func.inputs.forEach(input => {
    switch (input.type) {
      case 'checkbox': result[input.name] = false; break;
      case 'country': result[input.name] = '__'; break;
      case 'date': result[input.name] = { day: '', month: '', year: '' }; break;
      case 'datetime': result[input.name] = { day: '', month: '', year: '', hour: '', minute: '' }; break;
      case 'location': result[input.name] = { lat: '', lon: '' }; break;
      case 'repeatable': result[input.name] = []; break;
      case 'select': result[input.name] = (input as ISelectSpecialFunctionInput).options[0]; break;
      case 'text': result[input.name] = { value: '', mode: TextMode.Head }; break;
    }
  });
  return result;
}
