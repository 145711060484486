import React from 'react';
import { DocumentReferencesView } from "./DocumentReferencesView";
import { Colors, TextStyles } from "../../config/styles";
import { Document } from "../../models/Document";
import { T } from '../../Translate';


interface TitleCellProps {
  document: Document
}

export const TitleCell = (props: TitleCellProps) => {
  const { document } = props;

  return (
    <div>
      {document.title ? (
        <span style={{...TextStyles.text.large}}>{document.title}</span>
      ) : (
        <span style={{...TextStyles.text.large, color: Colors.lighterGray }}>{T('documents.noTitle')}</span>
      )}
      <br/>
      <DocumentReferencesView documentReferences={document.referenced_entities}/>
    </div>
  );
}
