import { ColumnAlign, ComponentTableColumn, ITableColumn, StringTableColumn } from "../../components/table/Fields";
import { T, TranslationKey } from "../../Translate";
import { Document, DocumentCategory } from "../../models/Document";
import React from "react";
import { TitleCell } from "./TitleCell";
import { CategoryCell } from "./CategoryCell";

const getDocumentIdPresentation = (document: Document) => {
  return (document.collection && document.filename) || document.filename || document.wiseLocation || document.inventoryId || '';
}

export function getColumns(): ITableColumn<Document>[] {
  return [
    new ComponentTableColumn('category', T('documents.column.category'), document => {
      return (<CategoryCell document={document}/>)
    }, {sortable: true, clickable: true}),
    new StringTableColumn('id', T('documents.column.documentid'), document => {
      if (document.category === DocumentCategory.Digital) {
        return Document.renderDocumentCounter(document.counter)
      } else {
        return getDocumentIdPresentation(document)
      }
    }, {sortable: false}),
    new ComponentTableColumn('title', T('documents.column.title'), document => {
      return (<TitleCell document={document}/>)
    }, { width: 400, sortable: true}),
    new StringTableColumn('status', T('documents.column.status'), document => T('document.status.' + document.status as TranslationKey)),
    new StringTableColumn('modified', T('documents.column.modified'), document => document.modified.format('YYYY-MM-DD HH:mm')),
    new StringTableColumn('pages', T('documents.column.pages'), document => document.elements.length.toString(), { align: ColumnAlign.Right, sortable: false, width: 100 })
  ];
}

export const rowKey = (document: Document) => document.id;
