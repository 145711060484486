import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { ContactMomentContribution } from '../../models/ContactPerson';
import { ContributionView } from './ContributionView';
import { ReferenceInputGroup } from '../../components/inputs/ReferenceTypeaheadInput';
import { DocumentReferenceType, DocumentReference } from '../../models/DocumentReference';
import { T } from '../../Translate';
import api from "../../api";
import { Person } from "../../models/Person";
import { Document } from "../../models/Document"
import { useDocumentTagDefinitions } from "../../FunctionalAPI";


interface ContributionListProps {
  contributions: ContactMomentContribution[];
  onDelete: (contribution: ContactMomentContribution) => void;
  onInsert: (contribution: ContactMomentContribution) => void;
}
export const ContributionList = (props: ContributionListProps): JSX.Element => {
  const { contributions, onDelete, onInsert } = props;

  /*** state ***/
  const  [updatedContributions, setUpdatedContributions] = useState<ContactMomentContribution[]> (contributions) ;
  const tags = useDocumentTagDefinitions()

  /*** effects ***/
  useEffect(() => {
    if (tags.list.length === 0)
      return;
    
    const updateContributionsWithReferencedData = (contributions: ContactMomentContribution[] ) => {
      setUpdatedContributions([]);
      contributions.forEach(contribution => {
        if (contribution.type === 'person') {
          updatePersonContributionWithReferencedData(contribution)
        } else if (contribution.type === 'document') {
          updateDocumentContributionWithReferencedData(contribution)
        }
      })
    };
    
    updateContributionsWithReferencedData(contributions)
  }, [contributions, tags])

  /*** utils ***/
  const updatePersonContributionWithReferencedData = (contribution: ContactMomentContribution) => {
    api.getPerson(contribution.targetId)
      .then((person) => {
        const personObject = new Person(person)
        setUpdatedContributions(updatedContributions => {
          let newContribution = {...contribution}
          newContribution.name = personObject.name
          let newList =  [...updatedContributions, newContribution]
          newList = newList.sort(sortContactMomentContribution)
          return newList
        })
      })
  }

  const updateDocumentContributionWithReferencedData = (contribution: ContactMomentContribution) => {
    Promise.all([
      api.getCollectionDocument(contribution.targetId)]
    )
      .then(([document]) => {
        setUpdatedContributions(updatedContributions => {
          let newContribution = {...contribution}
          newContribution.name = Document.renderDocumentCounter(document.counter) + ': ' + document.title
          let newList = [...updatedContributions, newContribution]
          newList =  newList.sort(sortContactMomentContribution)
          return newList
        })
      })
  }

  const sortContactMomentContribution = (a:ContactMomentContribution, b:ContactMomentContribution) => {
    if (a.type !== b.type)
      return a.type > b.type ? 1 : -1
    return (a.name ?? '').localeCompare(b.name ?? '')
  }


  /*** handlers ***/

  const handleSelectedPerson = (reference: DocumentReference) => {
    onInsert({
      type: 'person',
      targetId: reference.id!,
      name: reference.name
    });
  };
  const handleSelectedDocument = (reference: DocumentReference) => {
    onInsert({
      type: 'document',
      targetId: reference.id!,
      name: reference.name
    });
  };

  return <>
    {updatedContributions.map(contribution => (
      <ContributionView
        key={contribution.targetId}
        contribution={contribution}
        onClickedDelete={() => onDelete(contribution)}
      />
    ))}
    <h5 style={{ marginTop: '1em', color: '#888' }}>{T('page.recordContactMoment.contributions.register')}:</h5>
    <ReferenceInputGroup
      label={T('page.recordContactMoment.contributions.register.person') + ":"}
      type={DocumentReferenceType.Person}
      onSelected={handleSelectedPerson}
    />
    <ReferenceInputGroup
        label={T('page.recordContactMoment.contributions.register.document') + ":"}
      type={DocumentReferenceType.DibornDocument}
      onSelected={handleSelectedDocument}
    />
    <Alert variant={'secondary'} style={{color: '#888'}}>
      {T('page.recordContactMoment.contributions.register.subtitle')}
    </Alert>
  </>;
};
