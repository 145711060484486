import React from 'react';
import api from '../../api';
import {
  Document,
  DocumentCategory,
  DocumentKind,
  DocumentStatus,
  getDocumentCategoryName
} from '../../models/Document';
import { Col, Form, Row } from 'react-bootstrap';
import { singular, T } from '../../Translate';
import { DocumentTagDefinition } from '../../models/DocumentTagDefinition';
import { DocumentTag, DocumentTagFieldValue } from '../../models/DocumentTag';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { SelectInput } from '../../components/inputs/SelectInput';
import { FormCard } from '../../components/FormCard';
import { Icon } from '../../components/Icons';
import { CheckboxInput } from '../../components/inputs/CheckboxInput';
import { EditModeButton } from '../../components/EditModeButton';
import { PageID } from '../../utils/PageLinking';
import PageLink from '../../components/PageLink';
import { TextInput } from '../../components/inputs/TextInput';
import { InputGroup } from '../../components/inputs/InputGroup';
import { IDocumentFormState } from './DocumentFormState';
import { IndexedList } from '../../models/IndexedList';
import { DocumentReferences } from "./DocumentReferences";
import { TagsListView } from "./TagsListView";
import { zeroPad } from "../../utils/Formatting";
import { ContactPersonEventView } from "../EditContactPerson/ContactPersonEventView";
import { ContactPersonEvent } from "../../models/ContactPerson";

interface EditDocumentFormProps {
  tagDefinitions: IndexedList<DocumentTagDefinition>;
  document: Document;
  contactEvent?: ContactPersonEvent;
  readOnly: boolean;
  formState: IDocumentFormState;
  updateFormState: (updates: Partial<IDocumentFormState>) => void;
  contentHasChanged: boolean;
  onClickedEdit: () => void;
}

export default (props: EditDocumentFormProps) => {
  const {
    tagDefinitions,
    document,
    contactEvent,
    readOnly,
    formState,
    updateFormState,
    contentHasChanged,
    onClickedEdit
  } = props;

  const documentCategory = document.category;
  const adlibCode = document.adlibCode || (document.collection && document.collection.adlibCode);
  const titleReadonly = documentCategory === DocumentCategory.Adlib || documentCategory === DocumentCategory.AdlibCollectionDocument;

  const {
    kind,
    source,
    title,
    description,
    institution,
    inventoryId,
    url,
    wiseProfile,
    wiseBranch,
    wiseLocation,
    pages,
    status,
    disclosed,
    tags,
    references
  } = formState;

  const collectionObject = document && document.collection;

  const handleAddTag = (tag: DocumentTagDefinition|undefined) => {
    if (!tag)
      return;

    updateFormState({ tags: [...tags, DocumentTag.create(tag, tagDefinitions)] });
  };

  const editButton = !api.user.isReadOnly() && (
    <EditModeButton
      disabled={contentHasChanged}
      inverted={!readOnly}
      onClick={onClickedEdit}
    />
  );

  /*** handlers ***/

  const handleRemoveTag = (tag: DocumentTag) => {
    updateFormState({ tags: tags.filter(t => t !== tag) });
  };

  const handleFieldValueUpdated = (tag: DocumentTag, field: string, value: DocumentTagFieldValue) => {
    const newTag = tag.withFieldValue(field, value);
    const newTags = tags.map(t => t === tag ? newTag : t);
    updateFormState({ tags: newTags });
  }


  /*** rendering ***/

  const renderCategory = () => {
    return (
      <TextInput
        name='category'
        labelColumns={3}
        label={T('document.category')}
        value={getDocumentCategoryName(document.category)}
        readOnly
      />
    );
  }

  const renderCounter = () => {
    const renderDocumentCounter = (counter: number) => 'DIBO' + zeroPad(counter, 6);
    const counterValue: string =  document.counter && document.counter > 0
      ? renderDocumentCounter(document.counter)
      : T('document.counter.savefirst');
    
    return (
      <TextInput
        name='counter'
        labelColumns={3}
        label={T('document.counter')}
        value={counterValue}
        readOnly
      />
    );
  }

  const renderKind = () => {
    return (
      <SelectInput
        name='kind'
        label={T('document.kind')}
        labelColumns={3}
        value={kind}
        onChange={value => updateFormState({ kind: value as DocumentKind })}
        readOnly={readOnly || documentCategory === DocumentCategory.Digital}
        options={[
          { value: DocumentKind.Original, title: T('document.kind.original') },
          { value: DocumentKind.Copy, title: T('document.kind.copy') },
          { value: DocumentKind.Digital, title: T('document.kind.digital') },
          { value: DocumentKind.Reproduction, title: T('document.kind.reproduction') }
        ]}/>
    )
  }

  const renderCollectionTitle = () => {
    return (
      <TextInput
        name='collection-title'
        labelColumns={3}
        label={T('document.collection.title')}
        value={document.collection!.title}
        onChange={title => {}}
        readOnly={readOnly || titleReadonly}
      />
    )
  }

  const renderDocumentTitle = () => {
    return (
      <TextInput
        labelColumns={3}
        label={T('document.title')}
        name='title'
        value={title}
        onChange={title => updateFormState({ title })}
        readOnly={readOnly || titleReadonly}
        error={formState.titleError}
      />
    )
  }

  const renderDocumentDescription = () => {
    return (
      <TextareaInput
        labelColumns={3}
        label={T('document.description')}
        name='description'
        value={description}
        onChange={description => updateFormState({ description })}
        readOnly={readOnly || titleReadonly}
        rows={5}
      />
    )
  }

  const renderDocumentSource = () => {
    return (
      <TextInput
        labelColumns={3}
        label={T('document.source')}
        name='source'
        value={source}
        onChange={source => updateFormState({ source })}
        readOnly={readOnly}
      />
    )
  }

  const renderDocumentAdlibCode = () => {
    return (
      <TextInput
        labelColumns={3}
        label={T('document.adlibCode')}
        name='adlib-code'
        value={adlibCode || ''}
        readOnly
      />
    )
  }

  const renderDocumentFilename = () => {
    return (
      <TextInput
        labelColumns={3}
        label={T('document.filename')}
        name='filename'
        value={document.filename}
        readOnly
      />
    )
  }

  const renderCollectionObjectLink = () => {
    return (
      <Form.Group as={Row}>
        <Form.Label column sm={3} className='control-label'>{singular('collection')}:</Form.Label>
        <Col sm={9} className='form-control-plaintext'>
          <PageLink page={PageID.ViewCollection} param={document.collectionId || ''}>{collectionObject!.adlibCode}</PageLink>
        </Col>
      </Form.Group>
    )
  }

  const renderInstitution = () => {
    return (
      <TextInput
        labelColumns={3}
        label={T('document.institution')}
        name='institution'
        value={institution}
        onChange={institution => updateFormState({ institution })}
        readOnly={readOnly}
      />
    );
  }

  const renderInventory = () => {
    return (
      <TextInput
        labelColumns={3}
        label={T('document.inventoryId')}
        name='inventory-id'
        value={inventoryId}
        onChange={inventoryId => updateFormState({ inventoryId })}
        readOnly={readOnly}
      />
    );
  }

  const renderUrl = () => {
    return (
      readOnly ? (
          <InputGroup id='urlInput' labelColumns={3} label={T('document.url')}>
            <a
              href={url}
              target='_blank'
              rel='noopener noreferrer'
              style={{
                paddingTop: 8,
                paddingBottom: 4,
                display: 'inline-block'
              }}
            >{url}</a>
          </InputGroup>
        ) : (
          <TextInput
            labelColumns={3}
            label={T('document.url')}
            name='url'
            value={url}
            onChange={url => updateFormState({ url })}
            readOnly={readOnly}
            error={formState.urlError}
          />
        )
    )
  }

  const renderWiseProfile = () => {
    return (
      <SelectInput
        labelColumns={3}
        label={T('document.wiseProfile')}
        name='wise-profile'
        value={wiseProfile}
        onChange={wiseProfile => updateFormState({ wiseProfile })}
        readOnly={readOnly}
        options={[
          { value: 'ieper', title: 'Stad Ieper' }
        ]}
      />
    )
  }

  const renderWiseBranch = () => {
    return (
      <SelectInput
        labelColumns={3}
        label={T('document.wiseBranch')}
        name='wise-branch'
        value={wiseBranch}
        onChange={wiseBranch => updateFormState({ wiseBranch })}
        readOnly={readOnly}
        options={[
          { value: 'Ieper/Kenniscentrum In Flanders Fields Museum', title: 'Kenniscentrum' },
          { value: 'Ieper/Hoofdbibliotheek', title: 'Hoofdbibliotheek' },
          //{ value: 'Ieper/Elverdinge', title: 'Elverdinge' },
          //{ value: 'Ieper/Vlamertinge', title: 'Vlamertinge' },
          { value: 'Ieper/Stadsarchief', title: 'Stadsarchief' },
          { value: 'Ieper/Dé Academie', title: 'Dé Academie' }
        ]}
      />
    )
  }

  const renderWiseLocation = () => {
    return (
      <TextInput
        labelColumns={3}
        label={T('document.wiseLocation')}
        name='wise-location'
        value={wiseLocation}
        onChange={wiseLocation => updateFormState({ wiseLocation })}
        readOnly={readOnly}
      />
    )
  }

  const renderDocumentPages = () => {
    return (
      <TextInput
        labelColumns={3}
        label={T('document.pages')}
        name='pages'
        value={pages}
        onChange={pages => updateFormState({ pages })}
        readOnly={readOnly}
      />
    )
  }

  const renderStatus = () => {
    return (
      <SelectInput
        labelColumns={3}
        label={T('document.status')}
        name='status'
        value={status}
        onChange={status => updateFormState({ status: status as DocumentStatus })}
        readOnly={readOnly}
        options={[
          { value: 'todo', title: T('document.status.todo') },
          { value: 'in_progress', title: T('document.status.in_progress') },
          { value: 'done', title: T('document.status.done') }
        ]}
      />
    )
  }

  const renderDisclosed = () => {
    return (
      <CheckboxInput
        labelColumns={3}
        readOnly={readOnly}
        label={T('document.disclosed') + ':'}
        value={disclosed}
        onChange={disclosed => updateFormState({ disclosed })}
      />
    )
  }

  const renderReferences = () => {
    return (
      <FormCard
        title={T('document.references')}
        icon={Icon.Tags}
        style={{ marginTop: '1em'}}>
        <Form>
          <DocumentReferences
            references={references}
            setReferences={(newReferences) => updateFormState({references: newReferences})}
            readOnly={readOnly}
          />
        </Form>
      </FormCard>
    )
  }

  const renderContactEvent = () => {
    if (!contactEvent) {
      return <></>
    } else
    return (
      <ContactPersonEventView
        event={contactEvent}
        readonly={true}
        compact={true}
      />
    );
  }

  const renderTags = () => {
    return (
      <TagsListView
        document={document}
        tags={tags}
        handleAddTag={handleAddTag}
        handleRemoveTag={handleRemoveTag}
        handleFieldValueUpdated={handleFieldValueUpdated}
        readOnly={readOnly}
      />
    );
  }

  const renderWebDocument = () => {
    return (
      <>
        <FormCard
          title={T('document.title.content')}
          icon={Icon.Tags}
          style={{ marginTop: '1em'}}
          buttons={editButton}
        >
          <Form>
            {renderCategory()}
            {renderUrl()}
            {renderDocumentTitle()}
            {renderDocumentDescription()}
            {renderDisclosed()}
          </Form>
        </FormCard>
        {renderReferences()}
      </>
    )
  }

  const renderWiseDocument = () => (
    <>
      <FormCard
        title={T('document.title.content')}
        icon={Icon.Tags}
        style={{ marginTop: '1em'}}
        buttons={editButton} >
        <Form>
          {renderCategory()}
          {renderWiseProfile()}
          {renderWiseBranch()}
          {renderWiseLocation()}
          {/*renderWiseId() -- hidden until available */}
          {renderDocumentTitle()}
          {renderDocumentPages()}
          {renderDocumentDescription()}
          {renderDisclosed()}
        </Form>
      </FormCard>
      {renderReferences()}
    </>
  );

  const renderDibornDocument = () => (
    <>
      <FormCard
        title={T('document.title.content')}
        icon={Icon.Tags}
        style={{ marginTop: '1em'}}
        buttons={editButton} >
        <Form>
          {renderCategory()}
          {renderCounter()}
          { !contactEvent && renderDocumentSource()}
          {renderStatus()}
          {renderDisclosed()}
        </Form>
      </FormCard>
      {renderContactEvent()}
      {renderReferences()}
      {renderTags()}
    </>
  );

  const renderArchiveDocument = () => (
    <>
      <FormCard
        title={T('document.title.content')}
        icon={Icon.Tags}
        style={{ marginTop: '1em'}}
        buttons={editButton} >
        <Form>
          {renderCategory()}
          {renderInstitution()}
          {renderInventory()}
          {renderDocumentTitle()}
          {renderDocumentDescription()}
          {renderDisclosed()}
        </Form>
      </FormCard>
      {renderReferences()}
    </>
  );

  const renderAdlibDocument = () => (
    <>
    <FormCard
      title={T('document.title.content')}
      icon={Icon.Tags}
      style={{ marginTop: '1em'}}
      buttons={editButton} >
      <Form>
        {renderCategory()}
        {renderDocumentFilename()}
        {renderKind()}
        {collectionObject && renderCollectionObjectLink()}
        {document.adlibCode && renderDocumentAdlibCode()}
        {document.collection && renderCollectionTitle()}
        {renderStatus()}
        {renderDisclosed()}
      </Form>
    </FormCard>
    {renderReferences()}
    {renderTags()}
    </>
  );

  switch (documentCategory) {
    case DocumentCategory.Web:
      return renderWebDocument()
    case DocumentCategory.Wise:
      return renderWiseDocument()
    case DocumentCategory.Digital:
      return renderDibornDocument()
    case DocumentCategory.Archive:
      return renderArchiveDocument()
    case DocumentCategory.AdlibCollectionDocument:
    case DocumentCategory.Adlib:
      return renderAdlibDocument()
    default:
      return <></>
  }

}
