import React, { useEffect, useState } from 'react';
import {MilitaryEntity, MilitaryEntityType } from '../../models/MilitaryEntity';
import { IQueryResult } from '../../models/Generic';
import api from '../../api';
import { plural } from '../../Translate';
import { Card } from 'react-bootstrap';
import { usePageState } from '../../redux/Actions';
import { PageProps } from '../Page';
import { useAppSelector, useRefresh } from '../../utils/Functional';
import { getPageLinkWithVia, PageID } from '../../utils/PageLinking';
import { SortDirection, Table } from "../../components/table/Table";
import { generatePersonsColumns } from "../ListPersons";
import { Person, PersonQueryOptions } from "../../models/Person";
import { Search } from "../../components/Search";
import { useDelayedUpdateEffect } from "../../utils/UpdateEffects";


const PageSize = 10;
const rowKey = (item: Person) => item.id;

interface ListPersonsTabProps extends PageProps {
  entity?: MilitaryEntity
}

/**
 *
 */
export default (props: ListPersonsTabProps) => {

  /*** state ***/

  const [pageState, updatePageState] = usePageState('militaryEntityPersons');
  const [items, setItems] = useState<IQueryResult<Person>>();
  const currentPage = useAppSelector(state => state.activePageState);

  /*** variables ***/

  const { history, loading, entity } = props;
  const { filter, tablePage, sortColumn, sortDirection } = pageState;

  /*** api ***/

  const loadPersons = (skip: number, limit: number): Promise<IQueryResult<Person>|undefined> => {
    const { filter, sortColumn, sortDirection } = pageState;
    if (entity && (entity.type === MilitaryEntityType.Unit || entity.type === MilitaryEntityType.Regiment)) {
      return loading.loading(api.getMilitaryEntitySoldiers(
        entity.army,
        entity.regiment || '',
        entity.unit || null,
        entity.unitNr || null,
        filter,
        skip,
        limit,
        [[getSortColumn(sortColumn), sortDirection]],
        [PersonQueryOptions.Memorials, PersonQueryOptions.MilitaryEvents],
      ), plural('person')).then(persons => ({
        total: persons.total,
        data: persons.data.map(person => new Person(person))
      }));
    }
    return Promise.resolve(undefined);
  }

  /*** effects ***/

  const refresh = useRefresh(() => {
    loadPersons(tablePage * PageSize, PageSize).then(data => {
      setItems(data);
    })});
  useEffect(refresh, [tablePage, sortColumn, sortDirection]);

  useDelayedUpdateEffect(() => {
    refresh();
    updatePageState({tablePage: 0})},
    500,
    [filter])


  /*** table handlers ***/

  const handlePageChanged = (page: number) => {
    updatePageState({ tablePage: page });
  };

  const handleSortChanged = (column: string, direction: SortDirection) => {
    updatePageState({ sortColumn: column, sortDirection: direction });
  };

  const handleClickedRow = (item: Person) => {
    history.push(getPageLinkWithVia(PageID.ViewPerson, item.id, currentPage.id, currentPage.entity));
  }

  const handlePersonFilterChanged = (value: string) => {
    updatePageState({...pageState, filter: value})
  }

  /*** table helpers ***/

  const personColumns = generatePersonsColumns();

  const getSortColumn = (column: string) => {
    switch (column) {
      default:
        return 'filter';
      case 'birthDate':
        return 'sort_born_date';
      case 'diedDate':
        return 'sort_died_date';
    }
  }

  /*** rendering ***/

  const renderPersonsHeader = () => {
    return (
      <div style={{display: 'flex'}}>
        <Search name='search' value={filter} onChange={handlePersonFilterChanged} />
      </div>
    );
  }

  return (
    <>
      <Card.Header>
        {renderPersonsHeader()}
      </Card.Header>
      <Card.Body className='table-full-width'>
        <Table
          displayedItems={items?.data || []}
          columns={personColumns}
          rowKey={rowKey}
          onClickItem={handleClickedRow}
          onPageChanged={handlePageChanged}
          onSortChanged={handleSortChanged}
          sortDirection={sortDirection}
          sortColumn={sortColumn}
          page={tablePage}
          pageSize={PageSize}
          totalItems={items?.total ?? 0}
          noun='person'
        />
      </Card.Body>
    </>
  );
}
