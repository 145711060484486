import React, { CSSProperties } from 'react';

interface InvalidFeedbackProps {
  error: string;
  style?: CSSProperties
}

export const InvalidFeedback = (props: InvalidFeedbackProps) => {
  const { error, style } = props;
  return (
    <div className="invalid-feedback" style={{...style, display: 'inherit'}}>
      {error}
    </div>
  );
};

