import { useState } from "react";

let id = 0;

export function useElementID(prefix: string) {
  const [ elementID ] = useState(() => generateElementID(prefix));
  return elementID;
}

export function generateElementID(prefix: string) {
  return prefix + (++id);
}
