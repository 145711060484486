export function distinct<T>(values: T[]): T[] {
  return Array.from(new Set(values).values());
}

export function equals<T>(a: T[], b: T[]): boolean {
  if (a.length !== b.length)
    return false;

  for (let i = 0; i < a.length; i++)
    if (a[i] !== b[i])
      return false;
  
  return true;
}

export function isEmptyObject(value: any): value is {} {
  return Object.keys(value).length === 0 && value.constructor === Object;
}
