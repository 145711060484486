import React from 'react';
import { HashRouter, Prompt, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { setLanguage, Language, getActiveLanguage, TranslationKey, getLanguages } from './Translate';
import API, { IAuthenticationResponse } from './api';

import LoginModal from './modals/LoginModal';
import { PageProps, ContextPageProps, PageLayout } from './pages/Page';
import { Layout } from './components/layout/Layout';
import { History } from 'history';


import { ModalTarget } from './modals/ModalTarget';
import { ModalProps } from './modals/BaseModal';
import { LoadingItem, Loading } from './utils/Loading';
import { LoadingModal } from './components/LoadingModal';
import { ActivePageState } from './redux/State';
import { ModalContextProvider } from './modals/ModalContext';
import globalErrorHandler from "./utils/GlobalErrorHandler";
import { AppContextProvider, IAppContext } from './utils/AppContext';


export interface AppProps {
  history: History;
  page: ActivePageState;
}

export interface AppComponentState {
  modals: JSX.Element[];
  loading: LoadingItem[];
  language: Language;
}

export interface Renderers {
  renderPage: (
    cls: React.ComponentClass<PageProps>|React.FunctionComponent<PageProps>,
    layout?: PageLayout
  ) =>  (props: RouteComponentProps) => React.ReactNode;

  renderWithProps: <P,>(
    cls: React.ComponentClass<PageProps & P>|React.FunctionComponent<PageProps & P>,
    extractProps: (props: RouteComponentProps<any>, query: queryString.ParsedQuery<string>, params: {[key: string]: string}) => P,
    layout?: PageLayout
  ) =>  (props: RouteComponentProps) => React.ReactNode;
}

export class BaseApp extends React.Component<AppProps, AppComponentState> {
  loggingIn: Promise<IAuthenticationResponse|null>|null;
  modalCounter: number;
  modals: ModalTarget;
  loadingInfo: Loading;
  globalErrorHandler: Object;
  appContext: IAppContext;

  constructor(props: AppProps) {
    super(props);

    this.loggingIn = null;
    this.state = {
      modals: [],
      loading: [],
      language: getActiveLanguage(),
    };

    this.modalCounter = 0;
    this.modals = { show:  this.showModal.bind(this) };
    this.loadingInfo = {
      loading: this.loading.bind(this),
      saving: this.saving.bind(this),
      pending: this.pending.bind(this)
    };

    this.appContext = {
      loading: this.loadingInfo,
      history: props.history
    };

    API.onRequireLogin = this.handleRequireLogin;

    this.globalErrorHandler = globalErrorHandler;
  }

  handleLanguageChanged = (languageCode: Language) => {
    setLanguage(languageCode);
    this.setState({ language: languageCode });
  }

  handleRequireLogin = () => {
    if (this.loggingIn)
      return this.loggingIn;

    this.loggingIn = this.showModal(props => (
      <LoginModal
        resolve={props.resolve}
        reject={props.reject}
        onClosed={() => {
          this.loggingIn = null;
          props.onClosed();
        }}
      />
    ));
    return this.loggingIn;
  }

  showModal<T>(modal: (props: ModalProps<T>) => JSX.Element): Promise<T> {
    return new Promise((resolve, reject) => {
      const id = this.modalCounter++;
      const Modal = modal;
      const newModal = (
        <Modal
          key={id}
          resolve={resolve}
          reject={reject}
          onClosed={() => this.handleModalClosed(id)}
        />
      );
      this.setState(state => ({ modals: [...state.modals, newModal] }));
    });
  }

  handleModalClosed(id: number) {
    setTimeout(() => {
      this.setState(state => ({ modals: state.modals.filter(modal => (modal.key || '').toString() !== id.toString())}));
    }, 1000); // give the modal a little bit of time to perform its close animation
  }

  confirmNavigation = (message: string, callback: (result: boolean) => void) => {
    const { page } = this.props;
    if (page.confirmNavigation) {
      page.confirmNavigation().then(result => callback(result));
    } else {
      callback(true);
    }
  }

  loading<T>(promise: Promise<T>, displayName: string): Promise<T> {
    return this.pending(promise, 'pending.loading', displayName);
  }

  saving<T>(promise: Promise<T>, displayName: string): Promise<T> {
    return this.pending(promise, 'pending.saving', displayName);
  }

  async pending<T>(promise: Promise<T>, action: TranslationKey, displayName: string): Promise<T> {
    const item: LoadingItem = { action, displayName };
    this.setState(state => {
      const { loading } = state;
      const newLoading = [...loading, item];
      return { loading: newLoading };
    });
    try {
      const result = await promise;
      this._stopLoading(item);
      return result;
    }
    catch (err) {
      this._stopLoading(item);
      throw err;
    }
  }

  _stopLoading(item: LoadingItem) {
    this.setState(state => {
      const { loading } = state;
      const index = loading.indexOf(item);
      if (index < 0)
        return { loading };

      const newLoading = [...loading];
      newLoading.splice(index, 1);
      return { loading: newLoading };
    });
  }

  renderRoutes() {
    const { loading } = this.state;
    const { page, history } = this.props;
    const pageParams: ContextPageProps = {
      history,
      modals: this.modals,
      loading: this.loadingInfo,
      pending: loading
    };


    const renderPage = (
      cls: React.ComponentClass<PageProps>|React.FunctionComponent<PageProps>,
      layout?: PageLayout
    ) => {
      const PageClass = cls;
      return (props: RouteComponentProps<any>) => {
        const query = queryString.parse(props.location.search);
        const via = typeof(query.via) === 'string' ? query.via : undefined;
        if (props.match.params.params) {
          const params = parseParams(props.match.params.params);
          processParams(params);
        }
        return (
          <Layout
            history={history}
            page={page}
            onLanguageChanged={this.handleLanguageChanged}
            layout={layout}
          >
            { loading.length > 0 && <LoadingModal loading={loading} /> }
            <PageClass via={via} {...pageParams} />
          </Layout>
        );
      };
    };

    const renderWithProps = <P,>(
      cls: React.ComponentClass<PageProps & P>|React.FunctionComponent<PageProps & P>,
      extractProps: (props: RouteComponentProps<any>, query: queryString.ParsedQuery<string>, params: {[key: string]: string}) => P,
      layout?: PageLayout
    ) => {
      const PageClass = cls;
      return (props: RouteComponentProps<any>) => {
        const query = queryString.parse(props.location.search);
        const via = typeof(query.via) === 'string' ? query.via : undefined;
        const params = props.match.params.params ? parseParams(props.match.params.params) : {};
        processParams(params);
        const extraProps = extractProps(props, query, params);
        return (
          <Layout
            history={history}
            page={page}
            onLanguageChanged={this.handleLanguageChanged}
            layout={layout}
          >
            { loading.length > 0 && <LoadingModal loading={loading} /> }
            <PageClass via={via} {...pageParams} {...extraProps} />
          </Layout>
        );
      }
    };

    return this.renderRouteSwitch({ renderPage, renderWithProps });
  }

  renderRouteSwitch(renderers: Renderers) {
    throw Error("Not implemented");
  }

  render() {
    const { language, modals } = this.state;
    const { page } = this.props;

    return (
      <ModalContextProvider value={this.modals}>
        <AppContextProvider value={this.appContext}>
          <HashRouter key={language} getUserConfirmation={this.confirmNavigation}>
            <Prompt
              when={page.confirmNavigation !== undefined}
              message=''
            />
            {this.renderRoutes()}
            {modals}
          </HashRouter>
        </AppContextProvider>
      </ModalContextProvider>
    );
  }
}

function parseParams(params: String) {
  const parts = params.split('&');
  const result:{[key: string]: string} = {};
  parts.forEach(part => {
    const separator = part.indexOf('=');
    if (separator < 0) {
      result[part] = '';
    } else {
      let key = part.substring(0, separator);
      let value = part.substring(separator + 1);
      result[key] = value;
    }
  });
  console.log('Params:', result);
  return result;
}

function processParams(params: {[key: string]: string}) {
  if (params.lang || params.language) {
    const language = (params.lang || params.language).toLocaleLowerCase() as Language;
    if (getLanguages().includes(language)) {
      setLanguage(language);
    }
  }
}
