import React from 'react';
import { Place, getResolutionStatusColor, getResolutionStatusName } from '../../models/Place';
import { T } from '../../Translate';
import { Icon } from '../Icons';
import styles from './PlaceInput.module.scss';

interface PlaceInputOptionProps {
  value: string;
  item?: Place;
  searching: boolean;
}
// has to be a class because of the autocomplete input breaking on functional components
// (functional components don't support refs)
export default class PlaceInputOption extends React.PureComponent<PlaceInputOptionProps> {
  render() {
    const { value, item, searching } = this.props;
    return item === undefined ? (
      <div key='header' style={{ color: '#888', fontSize: 10 }}>{
        value.length === 0
          ? T('component.search.typeToSearch')
          : (searching ? T('component.search.searching') : T('component.search.noResults'))
      }</div>
    ) : (
      <div key={item.id} className={styles.option}>
        <div className={styles.optionLeft}>
          <i style={{ color: getResolutionStatusColor(item.status)}} className={Icon.MapMarker} />
        </div>
        <div className={styles.optionRight}>
          <div>{item.name} <span className={styles.placetype}>({getResolutionStatusName(item.status)})</span></div>
          <div>{item.describeHierarchy()}</div>
        </div>
      </div>
    );
  }
}
