import React, { useState, CSSProperties, Fragment, useEffect } from 'react';
import { IPlace, Place } from '../../models/Place';
import { Icon } from '../Icons';
import { InputGroup } from 'react-bootstrap';

import styles from './PlaceInput.module.scss';
import { useElementID } from '../../utils/ElementID';
import api from '../../api';
import { TextMode } from '../../models/SpecialFunction';
import { InvalidFeedback } from './InvalidFeedback';
import { SortDirection } from '../table/Table';
import PlaceInputOption from './PlaceInputOption';
import PlaceInfo from './PlaceInfo';
import { AsyncTypeahead, Highlighter, TypeaheadMenuProps } from "react-bootstrap-typeahead";
import { T } from "../../Translate";
import { useModalContext } from '../../modals/ModalContext';
import { CreatePlaceModal } from '../../modals/CreatePlace';

interface PlaceInputProps {
  labelColumns?: number;

  label: string;
  value: IPlace|undefined;
  name: string;
  placeholder?: string;
  error?: string;
  target?: string

  onChange: (place: IPlace|undefined) => void;
}

export const PlaceInput = (props: PlaceInputProps) => {
  const {
    labelColumns = 4,
    label,
    value,
    placeholder = label,
    onChange,
    error,
    target
  } = props;

  const modals = useModalContext();

  const handleClickedAdd = async () => {
    const place = await modals.show<IPlace|undefined>(props => (
      <CreatePlaceModal value='' {...props} />
    ));

    if (place) {
      onChange(place);
    }
  };

  const [inputText, setInputText] = useState(value ? new Place(value).name : '');

  useEffect(() => {
    setInputText(value ? new Place(value).name : '');
  }, [value])

  const handleSelected = (selected: Place) => onChange(selected.place);
  const handleClickedClear = () => onChange(undefined);

  const id = useElementID('input');
  return (
    <div className="form-group row">
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}</label>
      <div className={`col-sm-${12 - labelColumns}`}>
        <InputGroup>
          <PlaceInputComponent
            place={value && new Place(value)}
            value={inputText}
            placeholder={placeholder}
            onChange={(text) => {
              setInputText(text)
            }}
            onSelected={handleSelected}
          />
          <div className='input-group-append'>
            <div className={'input-group-text ' + styles.inputGroupButton} onClick={handleClickedAdd}>
              <i className={Icon.PlusSquare} />
            </div>
          </div>
        </InputGroup>
        {value && <PlaceInfo place={value} target={target} onClickedClear={handleClickedClear} />}
        {error && <InvalidFeedback error={error} />}
      </div>
    </div>
  );
};

interface PlaceInputComponentProps {
  place: Place|undefined;
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onSelected: (value: Place) => void;
  style?: CSSProperties;
  searchMode?: TextMode;
}
interface PlaceInputComponentState {
  options: Place[];
  inputValue?: string;
  modal: null|JSX.Element;
  isLoading: boolean;
}

const labelKey = (object: Object): string => {
  const place = object as Place
  if (place) {
    return place.nameWithAlternatives;
  } else {
    return '???'
  }
}

export class PlaceInputComponent extends React.PureComponent<PlaceInputComponentProps, PlaceInputComponentState> {
  requestTimer?: NodeJS.Timer;

  constructor(props: PlaceInputComponentProps) {
    super(props);

    this.state = {
      options: [],
      modal: null,
      inputValue: props.value,
      isLoading: false
    };
  }

  componentDidUpdate(prevProps: Readonly<PlaceInputComponentProps>, prevState: Readonly<PlaceInputComponentState>, snapshot?: any): void {
    this.setState({...this.state, inputValue: this.props.value})
  }

  handleValueChanged = (newValue: string) => {
    let value = newValue;
    // is this a valid uuid?
    let match = value.match(/.*([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/);
    if (match) {
      this.setState({ isLoading: true, options: [] });
      api.getPlace(match[1])
        .then(result => {
          if (match && result)
            this.handleSelected('', new Place(result));
        })
        .finally(
          () => {
            this.setState({isLoading: false})
          }
        );
    } else {
      this.scheduleSearch(value);
    }

    this.props.onChange(value);
  }

  handleSelected = (value: string, object: Object) => {
    const item = object as Place;
    if (item) {
      this.props.onSelected(item);
      this.setState({options: []});
    }
  }

  scheduleSearch(value: string) {
    if (this.requestTimer) {
      clearTimeout(this.requestTimer);
      this.requestTimer = undefined;
    }

    if (!value) {
      this.setState({ options: [] });
    } else {
      this.requestTimer = setTimeout(() => this.searchPlaces(), 500);
    }
  }

  searchPlaces() {
    this.requestTimer = undefined;
    const { searchMode } = this.props || TextMode.Head;
    api.findPlaces(this.props.value, 0, 100, [['name', SortDirection.Up]], undefined, searchMode)
      .then(places => {

        // only use the first 100 places
        let maxNumberOfPlaces = places.data.length > 100 ? 100 : places.data.length;

        this.setState({
        options: places.data.slice(0,maxNumberOfPlaces).map(place => new Place(place))
      })});
  }

  render() {
    const { value } = this.props;
    const { options } = this.state;

    let typeAheadProps = {
      renderMenuItemChildren : (reference: Place, props: TypeaheadMenuProps<Place>, index: number) => {
        const search = reference.nameWithAlternatives
        return (
          <Fragment>
            <Highlighter search={props.text}>
              {search}
            </Highlighter>
            <div key={reference.nameWithAlternatives}>
              <PlaceInputOption
                value={value}
                item={reference}
                searching={this.requestTimer !== undefined}
              />
            </div>
          </Fragment>
        )
      }
    };

    return (
      <AsyncTypeahead
        {...typeAheadProps}
        delay={150}
        useCache={true}
        id={'typeahead-id-places'}
        filterBy={()=>true}
        options={options}
        defaultInputValue={this.state.inputValue}
        labelKey={labelKey}
        selected= {this.state.inputValue === '' ? [] : undefined}
        placeholder={this.state.inputValue}
        emptyLabel={T('component.search.noResults')}
        onSearch={this.handleValueChanged}
        onChange={(selected => {this.handleSelected('', selected[0])})}
        isLoading={this.state.isLoading}
      />
    )

  }
}
