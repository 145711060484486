import React, { CSSProperties } from 'react';

interface FormCheckProps {
  label: string;
  checked: boolean;
  style?: CSSProperties;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}
export const FormCheck = (props: FormCheckProps) => {
  const { label, checked, style, onChange } = props;

  return (
    <div className='form-check' style={style}>
      <label className='form-check-label'>
        <input
          className='form-check-input'
          type='checkbox'
          value=''
          checked={checked}
          onChange={onChange}
        />
        <span className='form-check-sign' />
        {label}
      </label>
    </div>
  );
}
