import React, { CSSProperties } from 'react';
import { useElementID } from '../../utils/ElementID';
import { InputGroup } from './InputGroup';

interface TextInputProps {
  labelColumns?: number;
  inputColumns?: number;

  name: string;
  label: string;
  value: string;
  error?: string;
  readOnly?: boolean;
  plainText?: boolean;
  validated?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  info?: string;

  onChange?: (value: string) => void;
  onPressedEnter?: () => void;
  onBlur?: () => void;
}

export const TextInput = (props: TextInputProps) => {
  const {
    name,
    label,
    value,
    error,
    readOnly,
    plainText,
    validated,
    placeholder = label,
    onChange,
    onPressedEnter,
    onBlur
  } = props;

  const handleKeyPress = onPressedEnter && ((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter')
      onPressedEnter();
  });
  const id = useElementID('input');
  const inputClass = plainText ? 'form-control-plaintext' : (error ? 'form-control is-invalid' : (validated ? 'form-control is-valid' : 'form-control'));
  return (
    <InputGroup id={id} {...props}>
      <input
        id={id}
        name={name}
        type="text"
        readOnly={readOnly || plainText}
        className={inputClass}
        value={value}
        onChange={e => onChange && onChange(e.currentTarget.value)}
        onKeyPress={handleKeyPress}
        onBlur={onBlur}
        placeholder={placeholder}
      />
    </InputGroup>
  );
};
