import React, { CSSProperties, useCallback } from 'react';
import { useElementID } from '../../utils/ElementID';

interface CheckboxInputProps {
  label: string;
  value: boolean;
  readOnly?: boolean;
  onChange?: (checked: boolean) => void;
  labelStyle?: CSSProperties;
}

export default (props: CheckboxInputProps) => {
  const {
    label,
    value,
    readOnly,
    onChange,
    labelStyle
  } = props;

  const id = useElementID('input');
  return (
    <div className='form-check' style={{ paddingLeft: 0 }}>
      <label className='form-check-label' style={labelStyle}>
        <input
          id={id}
          className='form-check-input'
          type='checkbox'
          checked={value}
          disabled={readOnly}
          onChange={useCallback(e => onChange && onChange(e.currentTarget.checked), [onChange])}
        />
        <span className='form-check-sign' />
        {label}
      </label>
    </div>
  );
}
