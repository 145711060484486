import { getActiveLanguage, T, TranslationKey } from '../Translate';
import { DocumentTag, isReferenceFieldObject, getAllTagFields } from './DocumentTag';
import { IndexedList } from './IndexedList';

export interface IDocumentTagDefinition {
  id: string;
  translations: { [key: string]: string };
  fields: IDocumentTagField[];
  parent: string;
  selectable: boolean;
  validForDocument: boolean;
  validForContent: boolean;
}

export enum DocumentTagFieldType {
  String = 'string',
  Text = 'text',
  Person = 'person',
  Place = 'place',
  MilitaryEntity = 'military_entity'
}

export enum DocumentTagFieldPreposition {
  Colon = 'colon',
  From = 'from',
  To = 'to',
  Of = 'of',
  About = 'about'
}


export function translateTagFieldPreposition(preposition: DocumentTagFieldPreposition) {
  return T(('preposition.' + preposition) as TranslationKey);
}

export interface IDocumentTagField {
  key: string;
  type: DocumentTagFieldType;
  preposition: DocumentTagFieldPreposition;
  translations: { [language: string]: string };
}

export class DocumentTagDefinition {
  static fromJSON(json: IDocumentTagDefinition) {
    return new DocumentTagDefinition(
      json.id,
      json.translations,
      json.fields.map(field => DocumentTagField.fromJSON(field)),
      json.parent,
      json.selectable,
      json.validForDocument,
      json.validForContent
    );
  }

  id: string;
  translations: { [key: string]: string };
  fields: DocumentTagField[];
  parent: string|null;
  selectable: boolean;
  validForDocument: boolean;
  validForContent: boolean;
  allFields?: DocumentTagField[];

  constructor(
    id: string,
    translations: { [key: string]: string },
    fields: DocumentTagField[],
    parent: string|null,
    selectable: boolean,
    validForDocument: boolean,
    validForContent: boolean
  ) {
    this.id = id;
    this.translations = translations;
    this.fields = fields;
    this.parent = parent;
    this.selectable = selectable;
    this.validForDocument = validForDocument;
    this.validForContent = validForContent;
  }

  completeFields(definitions: IndexedList<DocumentTagDefinition>) {
    this.allFields = getAllTagFields(this, definitions);
  }

  generateDescription(tag: DocumentTag) {
    let description = this.translations[getActiveLanguage()];
    if (this.allFields === undefined)
      return description;

    for (let field of this.allFields) {
      let fieldValue = tag.fields[field.key];
      if (fieldValue) {
        let value = isReferenceFieldObject(fieldValue) ? fieldValue.name : fieldValue;
        description += ' ' + translateTagFieldPreposition(field.preposition) + ' ' + value;
      }
    }
    return description;
  }
}

export const DummyDocumentTagDefinition = new DocumentTagDefinition('_', {}, [], null, false, false, false);

export class DocumentTagField {
  static fromJSON(json: IDocumentTagField) {
    return new DocumentTagField(json.key, json.type, json.preposition, json.translations);
  }

  key: string;
  type: DocumentTagFieldType;
  preposition: DocumentTagFieldPreposition;
  translations: { [key: string]: string };

  constructor(
    key: string,
    type: DocumentTagFieldType,
    preposition: DocumentTagFieldPreposition,
    translations: { [key: string]: string }
  ) {
    this.key = key;
    this.type = type;
    this.preposition = preposition;
    this.translations = translations;
  }
}
