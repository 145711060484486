import React, { Fragment, useEffect, useState } from 'react';
import API from '../../api';
import { CreatePersonModal } from '../../modals/CreatePerson';
import { CreatePlaceModal } from '../../modals/CreatePlace';
import { DocumentReference, DocumentReferenceRelation, DocumentReferenceType } from '../../models/DocumentReference';
import { IPerson } from '../../models/Person';
import { IPlace } from '../../models/Place';
import { useElementID } from '../../utils/ElementID';
import { ReferenceInputCell } from './ReferenceInputCell';
import { useModalContext } from '../../modals/ModalContext';
import { IMilitaryEntity, MilitaryEntity } from '../../models/MilitaryEntity';
import { CreateMilitaryEntityModal } from '../../modals/CreateMilitaryEntity';
import { AsyncTypeahead, Highlighter, TypeaheadMenuProps } from "react-bootstrap-typeahead";
import { T, getActiveLanguage } from "../../Translate";

interface ReferenceInputProps {
  placeHolder?: string
  type: DocumentReferenceType;
  onSelected: (value: DocumentReference) => void;
}

export const ReferenceInput = (props: ReferenceInputProps) => {

  const { type, onSelected, placeHolder } = props;
  const modals = useModalContext();

  const [options, setOptions] = useState<DocumentReference[]>([]);
  const [value, setValue] = useState('');
  const [loadingCounter, setLoadingCounter] = useState(0)

  const isValidValue = value !== '' && !value.match(/^[0-9]+/);

  /** effects **/

  useEffect(() => {
    if (!isValidValue)
      setOptions([]);
  }, [isValidValue]);

  useEffect(() => {
    setOptions([]);
  }, [loadingCounter])


  const search = (query: string) => {
    if (query === '')
      return;

    // update state for loading indicator
    setLoadingCounter(loadingCounter => loadingCounter + 1)

    API.searchCollectionReferences(type, query)
      .then(references => {
        // When working with one of these DocumentReferenceType's, add the option
        // in front of the references to create a new entity
        if (type === DocumentReferenceType.Person
          || type === DocumentReferenceType.Place
          || type === DocumentReferenceType.MilityEntity) {
          references.splice(0, 0, DocumentReference.emptyWithName(''));
        }
        // fill the typeahead optoins
        setOptions(references);
      })
      .finally(() => {
        setLoadingCounter(loadingCounter => loadingCounter - 1)
      });
  }

  /** utils **/

  const showModalCreatePerson = () => {
    let creating = modals.show<IPerson|undefined>(props => (
      <CreatePersonModal value={value} {...props} />
    ));
    creating.then(person => {
      if (!person)
        return;

      onSelected(new DocumentReference(
        person._id,
        DocumentReferenceType.Person,
        person.surname + ' ' + person.familyname,
        DocumentReferenceRelation.None,
        ''
      ));
    });
  }

  const showModalCreateMilitaryEntity = () => {
    let creating = modals.show<IMilitaryEntity|undefined>(props => (
      <CreateMilitaryEntityModal value={value} {...props} />
    ));
    creating.then(entity => {
      if (!entity)
        return;

      onSelected(new DocumentReference(
        entity._id,
        DocumentReferenceType.MilityEntity,
        new MilitaryEntity(entity).name,
        DocumentReferenceRelation.None,
        ''
      ));
    });
  }

  const showModalCreatePlace = () => {
    let creating = modals.show<IPlace|undefined>(props => (
      <CreatePlaceModal value={value} {...props} />
    ));
    creating.then(place => {
      if (!place)
        return;

      onSelected(new DocumentReference(
        place._id,
        DocumentReferenceType.Place,
        place.languages[getActiveLanguage()].name,
        DocumentReferenceRelation.None,
        ''
      ));
    });
  }

  /**
   * Will show a modal to create an element. The onSelected callback
   * will be called when the modal closes with OK button
   */
  const showModalCreate = () => {
    switch (type) {
      case DocumentReferenceType.Person: {
        showModalCreatePerson()
        break;
      }
      case DocumentReferenceType.Place: {
        showModalCreatePlace()
        break;
      }
      case DocumentReferenceType.MilityEntity:
        showModalCreateMilitaryEntity()
        break;

      case DocumentReferenceType.Project:
      case DocumentReferenceType.Memorial:
        // Not supported
        break;
      default:
        break;
    }
  }

  /*** handlers ***/

  const handleSelected = (item: DocumentReference) => {
    if (!item) {
      return
    }

    // if the empty element was selected
    if (item.isEmpty()) {
      showModalCreate()
    } else {
      onSelected(item);
    }

    setValue('');
    setOptions(value ? [DocumentReference.empty()] : []);
  }


  let typeAheadProps = {
    renderMenuItemChildren : (reference: DocumentReference, props: TypeaheadMenuProps<DocumentReference>, index: number) => {
      const search = reference.name
      return (
        <Fragment>
          <Highlighter search={props.text}>
            {search}
          </Highlighter>
              <div key={reference.name}>
              <ReferenceInputCell
                document={reference}
                documentType={type}
                currentValue={value}
                renderName={false}
              />
            </div>
        </Fragment>
      )
    }
  };

  return (
    <AsyncTypeahead
      {...typeAheadProps}
      delay={300}
      useCache={true}
      defaultInputValue={value}
      filterBy={()=>true}
      id={'typeahead-id-xxkjkdl'}
      options={options}
      labelKey='name'
      selected= {value === '' ? [] : []}
      placeholder={placeHolder || T('component.search.typeToSearch')}
      onSearch={search}
      onChange={(selected => {handleSelected(selected[0])})}
      isLoading={loadingCounter > 0}
    />
  )
}


interface ReferenceInputGroupProps extends ReferenceInputProps {
  label: string;
  labelColumns?: number;
}
export const ReferenceInputGroup = (props: ReferenceInputGroupProps) => {
  const { label, labelColumns = 4, ...otherProps } = props;

  const id = useElementID('referenceInput');
  return (
    <div className="form-group row">
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}</label>
      <div className={`col-sm-${12 - labelColumns}`}>
        <ReferenceInput {...otherProps} />
      </div>
    </div>
  );
};
