import React, { useState } from 'react';
import { ModalProps, useModal } from '../../modals/BaseModal';
import { Modal, Button, Table } from 'react-bootstrap';
import { T } from '../../Translate';
import { Icon } from '../../components/Icons';
import styles from './index.module.scss';
import { Place, getResolutionStatusColor, getPlaceTypeName } from '../../models/Place';
import { classes } from '../../utils/Styles';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { ModalHeader } from '../../modals/ModalHeader';

export interface MergePlacesModalResult {
  confirmed: boolean;
  openResult: boolean;
  placeA: Place;
  placeB: Place;
}
interface MergePlacesModalProps extends ModalProps<MergePlacesModalResult> {
  placeA: Place;
  placeB: Place;
}

export const MergePlacesModal = (props: MergePlacesModalProps) => {
  const [isOpen, resolve] = useModal(props);
  const [placeA, setPlaceA] = useState(props.placeA);
  const [placeB, setPlaceB] = useState(props.placeB);

  const handleClickedSwitch = () => {
    setPlaceA(placeB);
    setPlaceB(placeA);
  };

  const handleClickedCancel = () => {
    resolve({
      placeA,
      placeB,
      confirmed: false,
      openResult: false
    });
  };

  const handleClickedConfirm = () => {
    resolve({
      placeA,
      placeB,
      confirmed: true,
      openResult: false
    });
  };

  const handleClickedConfirmAndOpen = () => {
    resolve({
      placeA,
      placeB,
      confirmed: true,
      openResult: true
    });
  };

  const nameA = placeA.name;
  const nameB = placeB.name;
  const translationA = placeA.getTranslation();
  const translationB = placeB.getTranslation();
  const sublocalityA = translationA.sublocality || '';
  const sublocalityB = translationB.sublocality || '';
  const localityA = translationA.locality || '';
  const localityB = translationB.locality || '';
  const area2A = translationA.administrative_area_level_2 || '';
  const area2B = translationB.administrative_area_level_2 || '';
  const area1A = translationA.administrative_area_level_1 || '';
  const area1B = translationB.administrative_area_level_1 || '';
  const willEraseName = nameA !== nameB;
  const willEraseStatus = placeA.status !== placeB.status;
  const willEraseType = placeA.type !== placeB.type;
  const willEraseSublocality = sublocalityA && sublocalityA !== sublocalityB;
  const willEraseLocality = localityA && localityA !== localityB;
  const willEraseArea2 = area2A && area2A !== area2B;
  const willEraseArea1 = area1A && area1A !== area1B;
  const willEraseCountry = placeA.countryCode !== placeB.countryCode;

  return (
    <Modal show={isOpen} onHide={handleClickedCancel} size='lg'>
      <ModalHeader>{T('modal.mergePlace.title')}</ModalHeader>
      <Modal.Body>
        <p>{T('modal.mergePlace.message')}</p>
        <Table>
          <tbody>
            <tr>
              <td>{T('place.name')}</td>
              <td>
                <i
                  className={Icon.MapMarker}
                  style={{ color: getResolutionStatusColor(placeA.status) }}
                />{' '}{nameA}</td>
              <td>
                <i
                  className={classes(Icon.MapMarker, willEraseStatus ? styles.erased : undefined)}
                  style={{ color: getResolutionStatusColor(placeB.status) }}
                />{' '}
                <span className={willEraseName ? styles.erased : ''}>{nameB}</span>
              </td>
            </tr>
            <tr>
              <td>{T('place.type')}</td>
              <td>{getPlaceTypeName(placeA.type)}</td>
              <td className={willEraseType ? styles.erased : ''}>{getPlaceTypeName(placeB.type)}</td>
            </tr>
            <tr>
              <td>{T('placeType.sublocality')}</td>
              <td>{sublocalityA}</td>
              <td className={willEraseSublocality ? styles.erased : ''}>{sublocalityB}</td>
            </tr>
            <tr>
              <td>{T('placeType.locality')}</td>
              <td>{localityA}</td>
              <td className={willEraseLocality ? styles.erased : ''}>{localityB}</td>
            </tr>
            <tr>
              <td>{T('place.area2')}</td>
              <td>{area2A}</td>
              <td className={willEraseArea2 ? styles.erased : ''}>{area2B}</td>
            </tr>
            <tr>
              <td>{T('place.area1')}</td>
              <td>{area1A}</td>
              <td className={willEraseArea1 ? styles.erased : ''}>{area1B}</td>
            </tr>
            <tr>
              <td>{T('place.countryCode')}</td>
              <td>{placeA.countryCode}</td>
              <td className={willEraseCountry ? styles.erased : ''}>{placeB.countryCode}</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='warning' onClick={handleClickedSwitch}>
          <i className={Icon.Switch} />
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button variant='secondary' onClick={handleClickedCancel}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={handleClickedConfirm}>
          <i className={Icon.Check} />
        </Button>
        <Button variant='primary' onClick={handleClickedConfirmAndOpen}>
          <i className={Icon.Check} />
          {' & '}
          <i className={Icon.Search} />
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
