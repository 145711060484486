import React, { useState } from 'react';
import { PageProps, usePageHeader } from '../Page';
import { Container, Row, Card, Form, Alert, Button } from 'react-bootstrap';
import { T } from '../../Translate';
import { LoadingIcon, Icon } from '../../components/Icons';
import { VerticalInput } from '../../components/inputs/VerticalInput';
import api from '../../api';
import { NotificationManager } from 'react-notifications';
import { PageID } from '../../utils/PageLinking';

interface ActivateAccountPageProps extends PageProps {
  activationKey: string;
  mode: 'activate'|'resetPassword';
}

export default (props: ActivateAccountPageProps) => {
  const { history, activationKey, mode, pending } = props;

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string|undefined>();
  const [validatePassword, setValidatePassword] = useState('');
  const [validatePasswordError, setValidatePasswordError] = useState<string|undefined>();
  const [error, setError] = useState<string|undefined>();
  const [confirmation] = useState<string|undefined>();

  usePageHeader(
    PageID.ActivateAccount,
    undefined,
    Icon.Page,
    'activate',
    'activate'
  );

  const handlePasswordChanged = (password: string) => {
    setPassword(password);
    setPasswordError(undefined);
  };

  const handleConfirmPasswordChanged = (validatePassword: string) => {
    setValidatePassword(validatePassword);
    setValidatePasswordError(undefined);
  }

  const validate = () => {
    let valid = true;
    if (password.length < 8) {
      setPasswordError(T('page.activateAccount.passwordTooShort'));
      valid = false;
    }
    if (password !== validatePassword) {
      setValidatePasswordError(T('page.activateAccount.passwordNotMatching'));
      valid = false;
    }
    return valid;
  };

  const handleClickedActivate = async () => {
    if (!validate())
      return;

    if (mode === 'resetPassword') {
      const result = await api.resetUserPassword(activationKey, password);
      if (result === 'INVALID_KEY') {
        setError(T('page.activateAccount.invalidKey'));
        return;
      }
      
      NotificationManager.success(T('page.resetPassword.done'));
    } else {
      const result = await api.activateUser(activationKey, password);
      if (result === 'INVALID_KEY') {
        setError(T('page.activateAccount.invalidKey'));
        return;
      }
      
      NotificationManager.success(T('page.activateAccount.done'));
    }
    history.push('/login');
  }

  const title = mode === 'activate'
    ? T('page.activateAccount.title')
    : T('page.resetPassword.title');
  const message = mode === 'activate'
    ? T('page.activateAccount.message')
    : T('page.resetPassword.message');
  const action = mode === 'activate'
    ? T('page.activateAccount.activate')
    : T('page.resetPassword.update');

  return (
    <Container>
      <Row className="justify-content-center">
        <Card style={{ marginTop: 100, width: 350 }}>
          <Card.Body style={{ padding: '2rem' }}>
            <h4 style={{ textAlign: 'center', fontSize: 17.5, fontWeight: 'bold' }}>
              {title}
            </h4>
            <hr />
            <p style={{ textAlign: 'center' }}>{message}</p>
            <Form>
              <VerticalInput
                type='password'
                name='password'
                label={T('page.activateAccount.password')}
                value={password}
                onChange={handlePasswordChanged}
                placeholder={T('page.activateAccount.password')}
                error={passwordError}
              />
              <VerticalInput
                type='password'
                name='confirm-password'
                label={T('page.activateAccount.confirmPassword')}
                value={validatePassword}
                onChange={handleConfirmPasswordChanged}
                placeholder={T('page.activateAccount.confirmPassword')}
                error={validatePasswordError}
              />
              {error && <Alert variant="danger">{error}</Alert>}
              {confirmation && <Alert variant='success'>{confirmation}</Alert>}
              <div style={{ textAlign: 'center', paddingTop: 20 }}>
                <Button variant='primary' onClick={handleClickedActivate}>
                  {action}
                  {pending.length > 0 && <span>&nbsp;<LoadingIcon /></span>}
                </Button>
              </div>
            </Form>
            <hr />
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}
