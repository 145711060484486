import React from 'react';
import { ModalProps, useModal } from '../../../modals/BaseModal';
import { Modal, Col, Row } from 'react-bootstrap';
import { T } from '../../../Translate';
import { ModalHeader } from '../../../modals/ModalHeader';

interface ChooseTemplateModalProps extends ModalProps<number|undefined> {
  options: { value: number, image: string }[];
}

export default (props: ChooseTemplateModalProps) => {
  const { options } = props;
  const [isOpen, resolve] = useModal(props);
  return (
    <Modal show={isOpen} onHide={() => resolve(undefined)} size='xl'>
      <ModalHeader>{T('page.personstory.editPersonPhoto.chooseTemplate')}</ModalHeader>
      <Modal.Body>
        <Row>
          {options.map(option => (
            <Col sm={4}>
              <img
                src={option.image}
                alt=''
                onClick={() => resolve(option.value)}
                style={{
                  width: '100%',
                  border: '2px solid #888',
                  cursor: 'pointer'
                }}
              />
            </Col>
          ))}
        </Row>
      </Modal.Body>
    </Modal>
  );
}
