import React, { useCallback, useMemo } from 'react';
import { useModalContext } from '../../../modals/ModalContext';
import AddPhotoControl from './AddPhotoControl';
import PersonPhotoModal from './PersonPhotoModal';
import { Person } from '../../../models/Person';
import { IPersonStory2 } from '../../../models/PersonStory2';
import PhotoPreview from './PhotoPreview';
import { StoryPhotoSource } from './PhotoSource';
import styles from './ChoosePhotoModal.module.scss';
import AddPassportPhotoControl from './AddPassportPhotoControl';
import { ConfirmationModal } from '../../../modals/Confirmation';
import { T } from '../../../Translate';
import api from '../../../api';
import SimpleImagePreview from './SimpleImagePreview';
import mapImagePlaceholder from '../../../assets/images/personstory_map_placeholder.png';
import { TextView } from '../../ViewPerson/TextView';
import { IImageErrors } from '../Stories/StoriesData';

interface PhotosTabProps {
  story?: IPersonStory2;
  person?: Person;
  readOnly: boolean;
  errors: IImageErrors;
  onChanged: (story: IPersonStory2) => void;
}
export default (props: PhotosTabProps) => {
  const { story, person, readOnly, errors, onChanged } = props;
  const modals = useModalContext();

  const handleClickedAdd = async () => {
    if (!story || !person || readOnly)
      return;

    const updated = await modals.show<IPersonStory2|undefined>(props => (
      <PersonPhotoModal
        person={person}
        personStoryId={story._id}
        target='new'
        {...props}
      />
    ));
    if (updated) {
      onChanged(updated);
    }
  };

  const handleClickedPassport = async () => {
    if (!story || !person || readOnly)
      return;

    const updated = await modals.show<IPersonStory2|undefined>(props => (
      <PersonPhotoModal
        person={person}
        personStoryId={story._id}
        target='profile'
        photo={story.profile_image ? new StoryPhotoSource(story, 'profile') : undefined}
        metadata={story.profile_image || undefined}
        {...props}
      />
    ));
    if (updated) {
      onChanged(updated);
    }
  }

  const onClickedDelete = useCallback(async (index: number|'profile') => {
    if (!story)
      return;

    const confirmed = await modals.show<boolean>(props => (
      <ConfirmationModal
        title={T('page.personstory.deletePersonPhoto.title')}
        message={T('page.personstory.deletePersonPhoto.title')}
        acceptStyle='danger'
        acceptText={T('generic.action.remove')}
        rejectText={T('generic.action.cancel')}
        {...props}
      />
    ));
    if (confirmed) {
      const updated = await api.deletePersonStoryPhoto(story._id, index);
      onChanged(updated);
    }
  }, [modals, story, onChanged]);

  const extraPhotos = useMemo(() => {
    if (!story || !person)
      return [];

    const onClickedPhoto = async (index: number) => {
      if (readOnly)
        return;
      
      const photo = story.extra_images![index];
      const updated = await modals.show<IPersonStory2|undefined>(props => (
        <PersonPhotoModal
          key={index}
          person={person}
          personStoryId={story._id}
          target={index}
          photo={new StoryPhotoSource(story, index)}
          metadata={photo}
          captions={photo.legend || {}}
          {...props}
        />
      ));
      if (updated) {
        onChanged(updated);
      }
    };

    return (story.extra_images || []).map((photo, index) => (
      <PhotoPreview
        key={index}
        item={photo}
        readOnly={readOnly}
        onClicked={() => onClickedPhoto(index)}
        onClickedDelete={() => onClickedDelete(index)}
      />
    ));
  }, [story, person, modals, readOnly, onChanged, onClickedDelete]);

  const isSaved = story && story._id !== '';
  return (
    <div className={styles.gallery}>
      {story && isSaved && (
        story.profile_image
          ? (
            <PhotoPreview
              item={story.profile_image}
              readOnly={readOnly}
              onClicked={handleClickedPassport}
              onClickedDelete={() => onClickedDelete('profile')}
            />
          ) : (
            <AddPassportPhotoControl
              error={errors.profileImage !== undefined}
              onClick={handleClickedPassport}
            />
          )
      )}
      {story && isSaved && <SimpleImagePreview url={story.map_image || mapImagePlaceholder} />}
      {extraPhotos}
      {story && isSaved && !readOnly && <AddPhotoControl onClick={handleClickedAdd} />}
      {!isSaved && <TextView text={T('page.personstory.editPersonPhoto.pleaseSaveFirst')} />}
    </div>
  );
}
