import {FormCard} from "../../../components/FormCard";
import {Icon} from "../../../components/Icons";
import {ContactPersonInput} from "../../../components/inputs/ContactPersonInput";
import FormGroup from "../../../components/inputs/FormGroup";
import {DocumentReference, DocumentReferenceType} from "../../../models/DocumentReference";
import {ILinkedContactPerson, IPersonStory2} from "../../../models/PersonStory2";
import {T} from "../../../Translate";
import {Loading} from "../../../utils/Loading";
import {ReferenceView} from "../../EditCollectionDocument/ReferenceView";
import {IKeyStoryErrors} from "../Stories/StoriesData";
import {KeyLocationInput} from "../../../components/inputs/KeyLocationInput";
import {DateInput, DateInputValue} from "../../../components/inputs/DateInput";
import React from "react";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import {ButtonLink} from "../../../components/ButtonLink";
import {IconButton} from "../../../components/IconButton";
import api from "../../../api";

const MAX_CONTACT_PERSONS = 3;

interface KeyStoryTabProps {
  loading: Loading;
  story?: IPersonStory2;
  onChanged: (story: IPersonStory2) => void;
  keyStoryDate: DateInputValue;
  keyStoryDateError?: string;
  onKeyStoryDateChanged: (date: DateInputValue) => void;
  keyStoryOrganisationTitle?: string,
  keyStoryOrganisationTitleError?: string,
  onkeyStoryOrganisationTitleChanged: (title: string) => void;
  errors: IKeyStoryErrors;
  readOnly: boolean;
}

export default function KeyStoryTab(props: KeyStoryTabProps) {
  const {
    loading,
    story,
    onChanged,
    keyStoryDate,
    keyStoryDateError,
    onKeyStoryDateChanged,
    keyStoryOrganisationTitle,
    keyStoryOrganisationTitleError,
    onkeyStoryOrganisationTitleChanged,
    errors,
    readOnly
  } = props;

  const getPdfUrl = (): string => {
    if (story === undefined || story === null) {
      return ''
    }
    return api.baseUrl + '/pdf/key?token=' + api.token + '&id=' + story._id
  }

  return <>
    <FormCard icon={Icon.Key} title={T('page.personstory.tab.keystory')}>
      {story && (
        <KeyLocationInput
          label={T("page.personstory.tab.keystory.keyLocation")}
          value={story.key_location}
          onChange={keyLocation => onChanged({...story, key_location: keyLocation, key_location_id: keyLocation._id})}
          onClear={() => onChanged({...story, key_location: undefined, key_location_id: null})}
          readOnly={readOnly}
        />
      )}
    </FormCard>
    <FormCard icon={Icon.Man} title={T('page.personstory.tab.keystory.contactperson')}>
      {story && (
        <>
          <DateInput
            name="key_story_date"
            label={T('page.personstory.tab.keystory.contactperson.date')}
            value={keyStoryDate}
            error={keyStoryDateError}
            onChange={onKeyStoryDateChanged}
            readOnly={readOnly}
          />

          <FormGroup label={T('page.personstory.tab.keystory.organisation.title')}>
            <input
              className="form-control"
              value={keyStoryOrganisationTitle}
              onChange={e => {
                onkeyStoryOrganisationTitleChanged(e.target.value)
              }}
              readOnly={readOnly}
              placeholder={T('page.personstory.tab.keystory.organisation.title.placeholder')}
            />
          </FormGroup>
        </>
      )}
      {story?.linked_contact_persons && story.linked_contact_persons.map((contactPerson, index) => (
        <>
          <FormGroup label={T('page.personstory.tab.keystory.contactperson')}>
            <div style={{marginTop: 12}}>
              <ReferenceView
                item={new DocumentReference(contactPerson.person._id, DocumentReferenceType.ContactPerson, contactPerson.person.firstName + ' ' + contactPerson.person.lastName)}
                readOnly={readOnly}
                onClickedRemove={() => {
                  const linked_contact_persons = (story.linked_contact_persons || []).filter(contact => contact.person._id !== contactPerson.person._id);
                  onChanged({...story, linked_contact_persons});
                }}
              />
            </div>
          </FormGroup>
          <FormGroup label={T('page.personstory.tab.keystory.contactperson.title')}>
            <input
              className="form-control"
              value={contactPerson.title}
              onChange={e => {
                const newContactPersons: ILinkedContactPerson[] = [...(story?.linked_contact_persons || [])];
                newContactPersons[index] = {...contactPerson, title: e.target.value};
                onChanged({...story, linked_contact_persons: newContactPersons});
              }}
              readOnly={readOnly}
            />
          </FormGroup>
          <br/>
        </>
      ))}

      {(story?.linked_contact_persons || []).length < MAX_CONTACT_PERSONS && !readOnly && (
        <ContactPersonInput
          label={T('page.personstory.tab.keystory.contactperson')}
          value={undefined}
          onSelected={person => {
            if (!story) return;

            const newContactPersons: ILinkedContactPerson[] = [...(story?.linked_contact_persons || []), {
              person,
              title: ''
            }];
            onChanged({...story, linked_contact_persons: newContactPersons});
          }}
          error={errors.linkedContactPerson}
        />
      )}
    </FormCard>
    {story && story.key_date && (story?.linked_contact_persons || []).length > 0 && getPdfUrl() !== '' && (
      <FormCard icon={Icon.Key} title={T('page.personstory.tab.keystory.certificate')}>
        <Container>
          <Row>
            <Col className="text-center">
              <Button variant="link" onClick={() => {
                window.open(getPdfUrl(), '_blank')
              }}>
                <i className="fa fa-download"/>
                {' ' + T('page.personstory.tab.keystory.certificate.download')}
              </Button>
            </Col>
          </Row>
        </Container>
      </FormCard>)}
  </>;
}
