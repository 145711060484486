import React, { useState } from 'react';
import { T } from '../../Translate';
import Autocomplete from 'react-autocomplete';

interface SimpleAutocompleteProps<T> {
  placeholder?: string;
  loading: boolean;
  inputValue: string;
  onInputChange: (value: string) => void;
  onSelect: (value: string, item: T) => void;
  onBlur?: () => void;

  itemValue: (value: T) => string;
  itemKey: (value: T) => string|number;
  renderItem: (value: T) => React.ReactNode;
  options: T[];

  invalid?: boolean;
  readOnly?: boolean;
}
/**
 * This autocomplete component accepts an input value, options list and item
 * renderer to build the autocomplete input component.
 * 
 * Easier to use (and appriopriately styled) in comparison to the
 * react-autocomplete component. Also shows the loading state and a message if
 * there are no results.
 */
export const SimpleAutocomplete = <T,>(props: SimpleAutocompleteProps<T>) => {
  const {
    placeholder,
    loading,
    inputValue,
    onInputChange,
    onSelect,
    onBlur,
    itemValue,
    itemKey,
    renderItem,
    options,
    invalid,
    readOnly
  } = props;
  const [changed, setChanged] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    onInputChange(e.currentTarget.value);
  };

  return (
    <Autocomplete
      wrapperStyle={{ width: '100%' }}
      inputProps={{
        className: invalid ? 'form-control is-invalid' : 'form-control',
        placeholder,
        readOnly,
        onBlur
      }}
      getItemValue={itemValue}
      items={options.length === 0 && changed ? [undefined] : options}
      menuStyle={{ padding: '0.25em', position: 'fixed', background: 'white', border: '1px solid #ccc', zIndex: 100 }}
      renderItem={(item: T|undefined, isHighlighted: boolean) =>
        item === undefined ? (
          <div style={{ color: '#888', fontSize: 10 }}>{
            inputValue.length === 0
              ? T('component.search.typeToSearch')
              : (loading ? T('component.search.searching') : T('component.search.noResults'))
          }</div>
        ) : (
          <div key={itemKey(item)} style={{ background: isHighlighted ? 'lightgray' : 'white', padding: '0.25em', cursor: 'default' }}>
            {renderItem(item)}
          </div>
        )
      }
      value={inputValue}
      onChange={handleChange}
      onSelect={onSelect}
    />
  );
}
