import React, { CSSProperties } from 'react';
import { IPersonStory2 } from "../../../models/PersonStory2";
import { T } from "../../../Translate";
import { Form } from "react-bootstrap";


export interface StoriesCheckboxProps {
  personStory?: IPersonStory2;
  readOnly?: boolean;
  onUpdateStories: (personStory: Partial<IPersonStory2>) => void;
}

export const StoriesCheckbox = (props: StoriesCheckboxProps) => {

  const checkStyle: CSSProperties = {
    fontSize: 14,
    color: '#9A9A9A',
    textTransform: "uppercase",
    paddingLeft: 0
  }

  const textStyle: CSSProperties = {
    color: '#9A9A9A',
  }

  const { personStory, readOnly, onUpdateStories } = props;

  /** event handling **/

  const onStatusChanged = (newStatus: boolean) => {
    onUpdateStories({story_child_option: newStatus})
  }

  const handleReferenceTypeChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const status = e.currentTarget.checked;
    onStatusChanged(status)
  };

  return (
    <Form.Group>
      <div className='form-check' style={checkStyle}>
        <label className='form-check-label'>
          <input
            className='form-check-input'
            type='checkbox'
            value=''
            checked={personStory?.story_child_option || false}
            onChange={handleReferenceTypeChanged}
            disabled={readOnly}
          />
          <span className='form-check-sign' />
          {T('personstories.check.childrenStories')}
        </label>
      </div>
      {personStory?.story_child_option === false &&
          <Form.Text style={textStyle}>
          {T('personstories.check.childrenStories.description')}
          </Form.Text>
      }
    </Form.Group>
  );
};
