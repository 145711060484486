import { Button, Container } from "react-bootstrap";
import { Icon } from "../../components/Icons";
import { Gender, Person } from "../../models/Person";
import { T } from "../../Translate";
import styles from './index.module.scss';
import publicStyles from '../PublicSearch/index.module.scss';
import { ModalTarget } from "../../modals/ModalTarget";
import { PersonFeedbackModal } from "../ViewPerson/PersonFeedbackModal";
import { PageID, getPageLink } from "../../utils/PageLinking";
import { Link } from "react-router-dom";

interface HeaderProps {
  modals: ModalTarget;
  person: Person;
}

export function Header(props: HeaderProps) {
  const { person, modals } = props;

  const handleClickedFeedback = () => {
    modals.show(props => <PersonFeedbackModal person={person} {...props} />);
  };

  return (
    <div className={styles.header}>
      <Container>
        <div className={publicStyles.actions}>
          <Button onClick={handleClickedFeedback}>{T('page.person.feedback')}</Button>
          <Button href='javascript:print()'><i className={Icon.Print} /></Button>
        </div>
        {person.familyInfo}
        <div className={styles.relations}>
          {person.relations.map(relation => <p>
            {Person.describePersonalRelationType(relation.relation, person.gender)}
            {' '}
            <Link to={getPageLink(PageID.PublicViewPerson, relation.person_id)}>{relation.person ? new Person(relation.person).name : '?'}</Link>
          </p>)}
      </div>
      </Container>
    </div>
  );
}
