import { IDocument, IDocumentPage } from "../../../models/Document";
import api from "../../../api";
import { IPersonStory2 } from "../../../models/PersonStory2";
import { IAttachment } from "../../../models/Attachment";

export enum PhotoDimension {
  Profile = 'profile', // 1080x1280
  Portrait = 'portrait', // 960x1280
  Landscape = 'landscape' // 1080x810
}

export interface IPhotoMetadata {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  grayscale?: boolean;
  contrast?: number;
  template?: number;
  upscaled?: boolean;
  legend?: {[key: string]: string}|null;
  dimension?: PhotoDimension;
}

export interface IPhotoSource {
  getUrl: () => string|undefined;

  save: (metadata?: IPhotoMetadata) => Promise<IPersonStory2>;
}

export class DocumentPhotoSource implements IPhotoSource {
  document: IDocument;
  page: number;

  storyId: string;
  target: number|'profile'|'new';

  constructor(document: IDocument, page: number, storyId: string, target: number|'profile'|'new') {
    this.document = document;
    this.page = page;

    this.storyId = storyId;
    this.target = target;
  }

  getUrl = () => {
    return (this.document.elements[this.page] as IDocumentPage).url_resized || undefined;
  }

  save = (metadata?: IPhotoMetadata) => {
    return api.importDocumentPhotoToPersonStory(this.storyId, this.target, this.document._id, this.page, metadata);
  }
}

export class TemporaryPhotoSource implements IPhotoSource {
  attachment: IAttachment;

  storyId: string;
  target: number|'profile'|'new';

  constructor(attachment: IAttachment, storyId: string, target: number|'profile'|'new') {
    this.attachment = attachment;
    this.storyId = storyId;
    this.target = target;
  }

  getUrl = () => {
    return this.attachment.urls.original;
  }

  save = (metadata?: IPhotoMetadata) => {
    return api.importAttachmentToPersonStory(this.storyId, this.target, this.attachment._id, metadata);
  }
}

export class StoryPhotoSource implements IPhotoSource {
  story: IPersonStory2;
  photo: 'profile'|number;

  constructor(story: IPersonStory2, photo: 'profile'|number) {
    this.story = story;
    this.photo = photo;
  }

  getUrl = () => {
    if (this.photo === 'profile') {
      return this.story.profile_image?.source_image_url;
    } else {
      const image = (this.story.extra_images || [])[this.photo];
      return image?.source_image_url;
    }
  }

  save = (metadata?: IPhotoMetadata) => {
    return api.updatePersonStoryPhoto(this.story._id, this.photo, metadata);
  }
}
