import React from 'react';
import { T } from '../../Translate';
import API from '../../api';
import { PageProps, usePageHeader } from '../Page';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import { Icon } from '../../components/Icons';
import { PageID } from '../../utils/PageLinking';

interface TileProps {
  icon: Icon;
  title: string;
  to: string;
}
const Tile = (props: TileProps) => {
  const { icon, title, to } = props;
  return (
    <Col sm={6} md={4} className={styles.tile}>
      <Link className={styles.tileInner} to={to}>
        <i className={icon}></i>
        <br />
        {title}
      </Link>
    </Col>
  );
}

const HomePage = (props: PageProps) => {
  const { history } = props;

  usePageHeader(PageID.Home, undefined, Icon.Home, T('app.title'), T('app.title'));

  if (!API.isLoggedIn()) {
    window.setTimeout(() => history.push('/login'));
    return <div />;
  }

  return (
    <div>
      <div className='container'>
        <Row style={{marginTop: 24}}>
          <Tile title={T('menu.namelist')} icon={Icon.Users} to='/persons?dataset=namenlijst' />
          <Tile title={T('menu.persons')} icon={Icon.Users} to='/persons' />
          <Tile title={T('menu.militaryEntities')} icon={Icon.Sitemap} to='/military_entities' />
          <Tile title={T('menu.places')} icon={Icon.MapMarker} to='/places' />
          <Tile title={T('menu.memorials')} icon={Icon.Monument} to='/memorials' />
        </Row>
      </div>
    </div>
  );
}
export default HomePage;

