import React, { useCallback } from 'react';
import api from '../../../api';
import { StringAutocomplete } from '../../../components/inputs/StringAutocomplete';
import { useElementID } from '../../../utils/ElementID';
import { InputGroup } from '../../../components/inputs/InputGroup';
import { Row, Col } from 'react-bootstrap';
import { T } from '../../../Translate';

interface UnitInputProps {
  army: string;
  regiment: string;
  value: string;
  onChange: (value: string) => void;
}
/**
 * Input field for a military unit name. Supports autocomplete, which suggests names
 * from existing units in the military entities collection with the
 * specified army and regiment.
 */
export const UnitInput = (props: UnitInputProps) => {
  const { army, regiment, value, onChange } = props;

  const search = useCallback(
    value => api.getUnitList({ army, regiment }, value, 0, 10),
    [army, regiment]
  );

  return (
    <StringAutocomplete
      placeholder={T('event.militaryUnit.placeholder')}
      value={value}
      onChange={onChange}
      search={search}
    />
  );
}

/**
 * Input field for a military unit number. Supports autocomplete, which suggests names
 * from existing units in the military entities collection with the
 * specified army, regiment and unit.
 */
interface UnitNrInputProps {
  army: string;
  regiment: string;
  unit: string;
  value: string;
  onChange: (value: string) => void;
}
export const UnitNrInput = (props: UnitNrInputProps) => {
  const { army, regiment, unit, value, onChange } = props;

  const search = useCallback(
    value => api.getUnitNumberList({ army, regiment, unit }, value, 0, 10),
    [army, regiment, unit]
  );

  return (
    <StringAutocomplete
      placeholder={T('event.militaryUnitNr.placeholder')}
      value={value}
      onChange={onChange}
      search={search}
    />
  );
}

interface UnitInputGroupProps {
  label: string;
  unitValue: string;
  unitNrValue: string;
  onChangeUnit: (value: string) => void;
  onChangeUnitNr: (value: string) => void;
  error?: string;
  army: string;
  regiment: string;
}
/**
 * Unit input group - consisting of a label, a unit number input, and a unit
 * name input. The unit number and unit name have autocomplete functionality
 * which is loaded from the list of existing units in the database. Only units
 * in the given army and regiment will be suggested.
 */
export const UnitInputGroup = (props: UnitInputGroupProps) => {
  const { unitValue, unitNrValue, onChangeUnit, onChangeUnitNr, army, regiment } = props;
  const id = useElementID('regimentInput');
  return (
    <InputGroup id={id} {...props}>
      <Row>
        <Col md={3}>
          <UnitNrInput value={unitNrValue} onChange={onChangeUnitNr} army={army} regiment={regiment} unit={unitValue} />
        </Col>
        <Col md={9}>
          <UnitInput value={unitValue} onChange={onChangeUnit} army={army} regiment={regiment} />
        </Col>
      </Row>
    </InputGroup>
  );
}
