import React, { useMemo, useEffect, useState } from 'react';
import { IQueryResult } from '../../models/Generic';
import api from '../../api';
import { T } from '../../Translate';
import { Card } from 'react-bootstrap';
import { usePageState } from '../../redux/Actions';
import { PageProps } from '../Page';
import { useRefresh } from '../../utils/Functional';
import { getPageLink, PageID } from '../../utils/PageLinking';
import { SortDirection, Table } from "../../components/table/Table";
import { generatePersonsColumns } from "../ListPersons";
import { Person, PersonQueryOptions } from "../../models/Person";
import { Search } from "../../components/Search";
import { useDelayedUpdateEffect } from "../../utils/UpdateEffects";
import { IMemorial } from "../../models/Memorial";
import { ITableColumn } from "../../components/table/Fields";
import { ConfirmationModal } from "../../modals/Confirmation";
import { NotificationManager } from "react-notifications";


const PageSize = 10;
const rowKey = (item: Person, index: number) => item.id;

interface ListPersonsTabProps extends PageProps {
  memorial?: IMemorial
  readonly: boolean
}

/**
 *
 */
const ListPersonsOnMemorial =  (props: ListPersonsTabProps) => {

  /*** state ***/

  const [pageState, updatePageState] = usePageState('memorialPersons');
  const [items, setItems] = useState<IQueryResult<Person>>();
  const [memorial, setMemorial] = useState<IMemorial | undefined>(props.memorial)

  /*** variables ***/

  const { history, loading, modals } = props;
  const { filter, tablePage, sortColumn, sortDirection } = pageState;

  /*** api ***/

  const loadData = (skip: number, limit: number): Promise<IQueryResult<Person>|undefined> => {
    const {filter, sortColumn, sortDirection} = pageState;
    return loading.loading(
      api.findPersons(
        {memorials: memorial?._id},
        filter,
        tablePage * PageSize,
        PageSize,
        [[getSortColumn(sortColumn), sortDirection]],
        [PersonQueryOptions.MilitaryEvents])
        .then((queryResult) => {
           return {
             data: queryResult.data.map(iPerson => new Person(iPerson)),
             total: queryResult.total
           }
          }),
      'persons'
    );
  }

  /*** effects ***/
  useEffect(() => {
    setMemorial(props.memorial)
  }, [props.memorial])

  const refresh = useRefresh(() => {
    if (!props.memorial?._id)
      return;
    loadData(tablePage * PageSize, PageSize).then(data => {
      setItems(data);
    })
  });

  useEffect(refresh, [tablePage, sortColumn, sortDirection]);

  useDelayedUpdateEffect(
    () => {
      refresh();
      updatePageState({tablePage: 0});
    },
    500,
    [filter]
  );

  useEffect(refresh, [memorial])

  /*** table handlers ***/

  const handlePageChanged = (page: number) => {
    updatePageState({ tablePage: page });
  };

  const handleSortChanged = (column: string, direction: SortDirection) => {
    updatePageState({ sortColumn: column, sortDirection: direction });
  };

  const handleClickedRow = (item: Person) => {
    history.push(getPageLink(PageID.ViewPerson, item.id));
  }

  const handlePersonFilterChanged = (value: string) => {
    updatePageState({...pageState, filter: value})
  }

  /*** table helpers ***/

  const columns: ITableColumn<Person>[] = useMemo(() => {

    const handleClickedRemovePerson = async (person: Person) => {
      if (!memorial)
        return

      const confirmed = await modals.show<boolean>(props => (
        <ConfirmationModal
          title={T('modal.removePersonFromMemorial.title')}
          message={T('modal.removePersonFromMemorial.message')}
          acceptText={T('generic.action.remove')}
          acceptStyle='danger'
          rejectText={T('generic.action.cancel')}
          {...props}
        />
      ));
      if (!confirmed)
        return;

      await api.removePersonFromMemorial(person.id, memorial?._id);
      NotificationManager.success(T('modal.removePersonFromMemorial.removed', {name: person.name}));
      refresh();
    }

    // copy the column definitions from the list persons page and filter out the 'memorials' column
    let columns = generatePersonsColumns(handleClickedRemovePerson);
    if (props.readonly === true) {
      columns = columns.filter(column => column.id !=='actions' &&  column.id !== 'memorials')
    }
    return columns
  }, [memorial, modals, props.readonly, refresh]);

  const getSortColumn = (column: string) => {
    switch (column) {
      default:
        return 'filter';
      case 'birthDate':
        return 'sort_born_date';
      case 'diedDate':
        return 'sort_died_date';
    }
  }

  /*** rendering ***/

  return (
    <>
      <Card.Header>
        <div style={{display: 'flex'}}>
          <Search name='search' value={filter} onChange={handlePersonFilterChanged} />
        </div>
      </Card.Header>
      <Card.Body className='table-full-width'>
        <Table
          displayedItems={items?.data || []}
          columns={columns}
          rowKey={rowKey}
          onClickItem={handleClickedRow}
          onPageChanged={handlePageChanged}
          onSortChanged={handleSortChanged}
          sortDirection={sortDirection}
          sortColumn={sortColumn}
          page={tablePage}
          pageSize={PageSize}
          totalItems={items?.total ?? 0}
          noun='person'
        />
      </Card.Body>
    </>
  );
}

export default ListPersonsOnMemorial;
