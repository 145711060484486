import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Icon} from '../../components/Icons';
import {getActiveLanguage, plural, T} from '../../Translate';
import {
  ActionsTableColumn,
  ComponentTableColumn,
  IntegerCalculatedTableColumn,
  IntegerFieldTableColumn,
  ITableColumn,
  tableRowActions,
  TableRowActionStyle
} from '../../components/table/Fields';
import api from '../../api';
import {IQueryResult} from '../../models/Generic';
import {ConfirmationModal} from '../../modals/Confirmation';
import {NotificationManager} from 'react-notifications';
import {ListPage, ListState} from '../ListPage';
import {PageProps} from '../Page';
import {usePageState} from '../../redux/Actions';
import {useModalContext} from '../../modals/ModalContext';
import {PageID} from '../../utils/PageLinking';
import {IKeyLocation} from '../../models/KeyLocation';
import {Button} from "react-bootstrap";

export type ListKeyLocationsPageState = ListState;

const rowKey = (keyLocation: IKeyLocation) => keyLocation._id;
const PageSize = 10;

export default function ListKeyLocations(props: PageProps) {
  const {history, loading, via} = props;

  const modals = useModalContext();
  const user = api.user;

  const [pageState, updatePageState] = usePageState('keyLocations');
  const {filter, tablePage, sortColumn, sortDirection} = pageState;
  const [items, setItems] = useState<IQueryResult<IKeyLocation>>();

  const refresh = useCallback(() => {
    loading.loading(api.findKeyLocations(
      filter,
      tablePage * PageSize,
      PageSize,
      [[sortColumn, sortDirection]],
      ['photos']
    ), plural('keyLocation'))
      .then(setItems);
  }, [loading, filter, tablePage, sortColumn, sortDirection]);
  useEffect(refresh, [refresh]);

  const columns = useMemo(() => {
    const handleClickedDelete = (keyLocation: IKeyLocation) => {
      modals.show<boolean>(props => (
        <ConfirmationModal
          title={T('modal.removeKeyLocation.title')}
          message={T('modal.removeKeyLocation.message', {name: keyLocation.name})}
          warning={T('generic.cannotUndo')}
          acceptText={T('generic.action.remove')}
          acceptStyle='danger'
          rejectText={T('generic.action.cancel')}
          {...props}
        />
      )).then(confirmed => {
        if (!confirmed)
          return;

        api.removeKeyLocation(keyLocation._id).then(() => {
          NotificationManager.success(T('modal.removeKeyLocation.removed'));
          refresh();
        });
      });
    }

    const columns: ITableColumn<IKeyLocation>[] = [
      new ComponentTableColumn('name', T('keyLocation.field.name'), keyLocation =>
        <strong>{keyLocation.name}</strong>, {ellipsize: true, sortable: true, clickable: true}),
      new IntegerFieldTableColumn('number', T('keyLocation.field.number')),
      new IntegerFieldTableColumn('year', T('keyLocation.field.year')),
      new IntegerCalculatedTableColumn('photos', T('keyLocation.field.photos'), keyLocation => keyLocation.photos?.length || 0),
    ];
    if (user.canEdit()) {
      columns.push(new ActionsTableColumn('actions', T('generic.list.actions'), keyLocation => tableRowActions(
        {icon: Icon.Eye, style: TableRowActionStyle.Success, page: PageID.PublicViewKeyLocation, pageParam: keyLocation._id},
        {icon: Icon.Edit, style: TableRowActionStyle.Success, page: PageID.EditKeyLocation, pageParam: keyLocation._id},
        {icon: Icon.TimesCircle, style: TableRowActionStyle.Danger, onClick: handleClickedDelete}
      ), {width: 100}));
    }
    return columns;
  }, [user, modals, refresh]);

  const handleClickedAdd = () => {
    history.push('/key_locations/create');
  };

  const handleClickedRow = (keyLocation: IKeyLocation) => {
    history.push(`/key_locations/${keyLocation._id}/edit`);
  };

  const getMapUrl = () => {
    return `#/public/key_locations_map/${getActiveLanguage()}`
  }


  return (
    <div className={'container'}>
      <div className={'row'}>
        <div className={'col-12 text-right'}>
          <Button variant={'link'} onClick={() => {
            window.open(getMapUrl(), '_blank')
          }}>
            <i className="fa fa-map"/>&nbsp;{T('listKeyLocations.map')}
          </Button>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-12'}>
          <ListPage
            id={PageID.ListKeyLocations}
            icon={Icon.Key}
            title={T('listKeyLocations.title')}
            via={via}
            noun='keyLocation'
            columns={columns}
            items={items}
            state={pageState}
            updateState={updatePageState}
            rowKey={rowKey}
            onClickedRow={handleClickedRow}
            onClickedAdd={handleClickedAdd}
          />
        </div>
      </div>
    </div>
);
}
