import { IPlace, PlaceData } from "./Place";
import { T } from "../Translate";
import { Icon } from "../components/Icons";

export interface IMilitaryEntityFields {
  type: MilitaryEntityType;
  army: string;
  division?: string;
  brigade?: string;
  corps?: string;
  regiment?: string;
  unit?: string;
  unit_nr?: string;
  history?: MilitaryEntityEvent[];
}
export interface IMilitaryEntity extends IMilitaryEntityFields {
  _id: string;
}

export type MilitaryEntityEvent = IMilitaryEntityStructureEvent|IMilitaryEntityTrackEvent;

export interface IMilitaryEntityQuery {
  type: MilitaryEntityType|{'$in': MilitaryEntityType[]};
}

export interface IMilitaryEntityStructureEvent {
  type: 'structure'|'attached';
  army: string;
  entity_id: string;
  entity?: IMilitaryEntity;
  from_date: string;
  to_date: string;
  description: string;
}

export interface IMilitaryEntityTrackEvent extends PlaceData {
  type: 'track';
  from_date: string;
  to_date: string;
  description: string;
  track_type: MilitaryTrackType;
  place?: IPlace;
}

export interface IMilitaryEntityChild {
  _id: string;
  from_date: string;
  to_date: string;
  military_entity: IMilitaryEntity;
}

export enum MilitaryEntityType {
  Corps = 'corps',
  Division = 'division',
  Brigade = 'brigade',
  Regiment = 'regiment',
  Unit = 'unit'
}

export const MilitaryEntityTypes: MilitaryEntityType[] = [
  MilitaryEntityType.Division,
  MilitaryEntityType.Brigade,
  MilitaryEntityType.Regiment,
  MilitaryEntityType.Unit
];

export function getMilitaryEntityTypeName(type: MilitaryEntityType): string {
  switch (type) {
    case MilitaryEntityType.Corps: return T('militaryEntityType.corps');
    case MilitaryEntityType.Division: return T('militaryEntityType.division');
    case MilitaryEntityType.Brigade: return T('militaryEntityType.brigade');
    case MilitaryEntityType.Regiment: return T('militaryEntityType.regiment');
    case MilitaryEntityType.Unit: return T('militaryEntityType.unit');
    default: return type;
  }
}

export function getMilitaryEntityEventIcon(type: 'structure'|'track'|'attached'): Icon {
  switch (type) {
    case 'structure': return Icon.Sitemap;
    case 'track': return Icon.Route;
    case 'attached': return Icon.Sitemap;
    default: return Icon.Info;
  }
}

export function getMilitaryEntityEventTypeName(type: 'structure'|'track'|'attached'): string {
  switch (type) {
    case 'structure':
    case 'attached':
      return T('militaryEntityEventType.main.structure');
    case 'track':
      return T('militaryEntityEventType.main.track');
    default:
      return '';
  }
}


export enum MilitaryTrackType {
  FrontierService = 'frontier_service',
  Support = 'support',
  HalfRest = 'half_rest',
  Rest = 'rest'
}

export function getMilitaryTrackTypeName(type: MilitaryTrackType) {
  switch (type) {
    case MilitaryTrackType.FrontierService:
      return T('militaryEntityTrackType.frontierService');
    case MilitaryTrackType.Support:
      return T('militaryEntityTrackType.support');
    case MilitaryTrackType.HalfRest:
      return T('militaryEntityTrackType.halfRest');
    case MilitaryTrackType.Rest:
      return T('militaryEntityTrackType.rest');
    default:
      return '?';
  }
}

export const Armies: string[] = [
  'A.F.',
  'A.B.L.',
  'B.E.F.',
  'N.Z.E.F.',
  'A.I.F.',
  'Indian Army',
  'S.Afr.',
  'C.E.F.',
  'ANZAC',
  'D.K.H.',
  'U.S.A.',
  'C.E.P.',
  'E.I.',
  'R.I.A.'
];

export const ArmyNames: {[key: string]: string} = {
  'A.F.': 'Armée Française',
  'A.B.L.': 'Armée Belge / Belgisch Leger',
  'D.K.H.': 'Deutsches Kaiserliches Heer',
  'B.E.F.': 'British Expeditionary Force',
  'C.E.F.': 'Canadian Expeditionary Force',
  'A.I.F.': 'Australian Imperial Force',
  'N.Z.E.F.': 'New Zealand Expeditionary Force',
  'S.Afr.': 'South African Force',
  'U.S.A.': 'United States Army',
  'C.E.P.': 'Corpo Expedicionário Português',
  'E.I.': 'Esercito Italiano',
  'R.I.A.': 'Ruska Imperatorska Armija'
};

export class MilitaryEntity {
  entity: IMilitaryEntity;

  constructor(entity: IMilitaryEntity) {
    this.entity = entity;
  }

  get id() {
    return this.entity._id;
  }

  get name() {
    switch (this.entity.type) {
      case MilitaryEntityType.Division:
        return (this.entity.division || '') + ', ' + this.entity.army;
      case MilitaryEntityType.Brigade:
        return (this.entity.brigade || '') + ', ' + this.entity.army;
      case MilitaryEntityType.Regiment:
        return (this.entity.regiment || '') + ', ' + this.entity.army;
      case MilitaryEntityType.Unit:
        return (this.entity.unit_nr || '')+ ' ' + (this.entity.unit || '')
          + ', ' + (this.entity.regiment || '')
          + ', ' + this.entity.army;
      default:
        console.log('Getting default name of ', this, this.entity);
        return '?, ' + this.entity.army;
    }
  }

  get type() {
    return this.entity.type;
  }

  get army() {
    return this.entity.army;
  }

  get regiment() {
    return this.entity.regiment;
  }

  get unit() {
    return this.entity.unit;
  }

  get unitNr() {
    return this.entity.unit_nr;
  }
}
