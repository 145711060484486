import React from 'react';
import { Icon } from './Icons';
import styles from './IconButton.module.scss';
import { classes } from '../utils/Styles';

interface IconButtonProps {
  icon: Icon;
  onClick: (e: React.MouseEvent) => void;
}
export const IconButton = (props: IconButtonProps) => {
  const { icon, onClick } = props;
  return (
    <i
      className={classes(icon, styles.iconbutton)}
      onClick={onClick}
    />
  );
}

interface IconLinkProps {
  icon: Icon;
  href: string;
}
export const IconLink = (props: IconLinkProps) => {
  const { icon, href } = props;
  return (
    <a href={href} target='_blank' rel="noopener noreferrer">
      <i className={classes(icon, styles.iconbutton)} />
    </a>
  );
}
