import React from 'react';
import { Icon } from './Icons';
import { PageID } from '../utils/PageLinking';
import PageLink from './PageLink';

interface EditLinkProps {
  page: PageID;
  param?: string;
}
export const EditLink = (props: EditLinkProps) => {
  const { page, param } = props;
  return (
    <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 100 }}>
      <PageLink page={page} param={param}>
        <i className={Icon.Edit} />
      </PageLink>
    </div>
  );
}
