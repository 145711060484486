import React, { useRef, useMemo, useEffect } from 'react';
import { useRefresh } from '../../../utils/Functional';

interface PhotoPreviewProps {
  x: number;
  y: number;
  width: number;
  height: number;

  targetWidth: number;
  targetHeight: number;

  setPosition: (x: number, y: number) => void;
  setSize: (width: number, height: number) => void;

  image: string|undefined;
  overlay: string|undefined;
  grayscale: boolean;

  onImageLoaded: (image: HTMLImageElement) => void;
}

interface IDragInitState {
  startX: number;
  startY: number;
  startMouseX: number;
  startMouseY: number;
}

const drawScale = 0.3;

export default (props: PhotoPreviewProps) => {
  const {
    x, y, width, height,
    targetWidth,
    targetHeight,
    image,
    overlay,
    grayscale,
    setPosition,
    setSize,
    onImageLoaded
  } = props;

  const ref = useRef<HTMLCanvasElement|null>(null);
  const dragInitState = useRef<IDragInitState>();

  const photoImage = useMemo(() => new Image(), []);
  const overlayImage = useMemo(() => new Image(), []);

  const renderCanvas = useRefresh(() => {
    const canvas = ref.current;
    if (canvas === null)
      return;

    const context = canvas.getContext('2d');
    if (context === null)
      return;

    // resulting size is 1080 x 1280, but the canvas is rendered at a fixed
    // size of 324 x 384, which is 30% of that size
    context.fillStyle = 'white';
    context.globalCompositeOperation = grayscale ? 'luminosity' : 'source-over';

    context.fillRect(0, 0, canvas.width, canvas.height);

    // Drawing an empty image does not work on Safari.
    if (photoImage.width !== 0) {
      context.drawImage(
        photoImage,
        x * drawScale,
        y * drawScale,
        width * drawScale,
        height * drawScale
      );
    }

    if (overlay && overlayImage.width !== 0) {
      context.drawImage(overlayImage, 0, 0, canvas.width, canvas.height);
    }
  });

  useEffect(() => {
    if (overlay) {
      overlayImage.src = overlay;
      overlayImage.addEventListener('load', renderCanvas);
    }
  }, [renderCanvas, overlay, overlayImage]);

  useEffect(renderCanvas, [x, y, width, height, grayscale]);

  useEffect(() => {
    if (image === undefined)
      return;

    if (image.length === 0)
      return;

    photoImage.src = image;
    photoImage.addEventListener('load', () => {
      onImageLoaded(photoImage);
      renderCanvas();
    });
    return () => photoImage.removeEventListener('load', renderCanvas);
  }, [renderCanvas, image, photoImage, onImageLoaded]);

  const setRef = (canvas: HTMLCanvasElement|null) => {
    ref.current = canvas;
    renderCanvas();
  };

  const handleCanvasMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    dragInitState.current = {
      startX: x,
      startY: y,
      startMouseX: e.clientX,
      startMouseY: e.clientY
    };
  };

  const handleCanvasMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const initState = dragInitState.current;
    if (initState === undefined)
      return;

    const x = initState.startX + Math.round((e.clientX - initState.startMouseX) / drawScale);
    const y = initState.startY + Math.round((e.clientY - initState.startMouseY) / drawScale);
    setPosition(x, y);
  };

  const handleCanvasMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
    dragInitState.current = undefined;
  };

  const handleCanvasScroll = (e: React.WheelEvent<HTMLCanvasElement>) => {
    const canvas = ref.current;
    if (canvas === null)
      return;

    const scale = e.deltaY > 0 ? 0.9 : 1 / 0.9;

    // similar rescale calculation as found in ImageBrowser.tsx
    // only difference being that the canvas is drawn at a different scale,
    // so relativeX and relativeY must be scaled
    let rect = canvas.getBoundingClientRect();
    let relativeX = (e.clientX - rect.left) / drawScale;
    let relativeY = (e.clientY - rect.top) / drawScale;
    const newX = Math.round(relativeX - (relativeX - x) * scale);
    const newY = Math.round(relativeY - (relativeY - y) * scale);

    setPosition(newX, newY);
    setSize(Math.round(width * scale), Math.round(height * scale));
  };

  return (
    <canvas
      ref={setRef}
      width={targetWidth * drawScale}
      height={targetHeight * drawScale}
      onMouseDown={handleCanvasMouseDown}
      onMouseMove={handleCanvasMouseMove}
      onMouseUp={handleCanvasMouseUp}
      onWheel={handleCanvasScroll}
      style={{ border: '1px solid #888' }}
    />
  );
}
