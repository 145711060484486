import React, { useRef, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import api from '../../api';
import { useElementID } from '../../utils/ElementID';
import { T } from '../../Translate';
import { IContactPerson } from '../../models/ContactPerson';
import FormGroup from './FormGroup';

interface ContactPersonInputProps extends ContactPersonInputComponentProps {
  labelColumns?: number;
  inputColumns?: number;

  label: string;
  error?: string;
}

export function ContactPersonInput(props: ContactPersonInputProps) {
  const {
    onSelected,
    value,

    labelColumns = 4,
    inputColumns,
    label,
    error
  } = props;
  const id = useElementID('cpinput');

  return (
    <FormGroup
      id={id}
      labelColumns={labelColumns}
      inputColumns={inputColumns}
      label={label}
      error={error}
    >
      <ContactPersonInputComponent
        onSelected={onSelected}
        value={value}
        invalid={error !== undefined}
      />
    </FormGroup>
  );
}

interface ContactPersonInputComponentProps {
  value: IContactPerson|undefined;
  onSelected: (value: IContactPerson) => void;
  invalid?: boolean;
  readOnly?: boolean;
}

export function ContactPersonInputComponent(props: ContactPersonInputComponentProps) {
  const { value, onSelected, invalid = false } = props;

  const [inputValue, setInputValue] = useState(value ? value.firstName + ' ' + value.lastName : '');
  const [options, setOptions] = useState<IContactPerson[]>([]);

  const requestTimer = useRef<NodeJS.Timeout>();

  const handleSelected = (value: string, item: IContactPerson) => {
    onSelected(item);

    setInputValue('');
    setOptions([]);
  };

  const searchReferences = (value: string) => {
    requestTimer.current = undefined;
    api.findContactPersons(value, 0, 10)
      .then(entities => setOptions(entities.data));
  };

  const scheduleSearch = (value: string) => {
    if (requestTimer.current) {
      clearTimeout(requestTimer.current);
      requestTimer.current = undefined;
    }

    if (!value) {
      setOptions([]);
    } else {
      requestTimer.current = setTimeout(() => searchReferences(value), 500);
    }
  }

  const handleValueChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    // is this a valid uuid?
    const match = value.match(/.*([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/);
    if (match) {
      setOptions([]);
      api.getContactPerson(match[1])
        .then(result => {
          if (match && result)
            handleSelected(match[1], result);
        });
    } else {
      scheduleSearch(value);
    }

    setInputValue(value);
  };

  return <>
    <Autocomplete
      inputProps={{ className: invalid ? 'form-control is-invalid' : 'form-control' }}
      getItemValue={item => item.firstName + ' ' + item.lastName}
      items={options.length === 0 ? [undefined] : options}
      menuStyle={{ padding: '0.25em', position: 'fixed', background: 'white', border: '1px solid #ccc', zIndex: 100 }}
      renderItem={(item: IContactPerson|undefined, isHighlighted: boolean) =>
        item === undefined ? (
          <div style={{ color: '#888', fontSize: 10 }}>{
            inputValue.length === 0
              ? T('component.search.typeToSearch')
              : (requestTimer.current ? T('component.search.searching') : T('component.search.noResults'))
          }</div>
        ) : (
          <div key={item._id} style={{ background: isHighlighted ? 'lightgray' : 'white', padding: '0.25em', cursor: 'default' }}>
            {item.firstName} {item.lastName}
          </div>
        )
      }
      value={inputValue}
      onChange={handleValueChanged}
      onSelect={handleSelected}
    />
  </>;
}
