import React from 'react';
import { Page, PageProps } from '../Page';
import { Icon } from '../../components/Icons';
import { Card } from 'react-bootstrap';
import api from '../../api';
import { PlaceLink } from '../../components/PlaceLink';
import { T } from '../../Translate';
import { GetMemorialOption, describeMemorialKind } from '../../models/Memorial';
import { useMemorial, useNumberOfRelatedDocuments } from '../../FunctionalAPI';
import { PageID, getPageVia } from '../../utils/PageLinking';
import PageLink from '../../components/PageLink';
import { TextStyles } from "../../config/styles";
import { TopTab, TopTabs } from "../../components/TopTabs";
import ListDocumentsTab from "../ViewPlace/ListDocumentsTab";
import ListPersonsOnMemorial from "./ListPersonsOnMemorial";

interface ViewMemorialPageProps extends PageProps {
  id: string;
}

const LoadOptions = [GetMemorialOption.Place];

const ViewMemorial = (props: ViewMemorialPageProps) => {

  const { loading, id, history, modals, via } = props;

  const memorial = useMemorial(loading, id, LoadOptions);
  const numberOfDocuments = useNumberOfRelatedDocuments('memorial', memorial?._id )

  const user = api.user;

  /*** rendering ***/
  const generateTabs = (): TopTab[] => {
    return [
      {
        icon: Icon.Monument,
        title: T('field.memorated.memorial.name'),
        id: 'memorial',
        content: () => renderMemorialTab(),
        badgeContent: () => '',
      },
      {
        icon: Icon.Document,
        title: T('page.place.tab.documents'),
        id: 'documents',
        content: () => renderDocumentsTab(),
        badgeContent: () => numberOfDocuments.toString()      }
    ]
  }

  const renderHeader = () => {
    return (
      <Card.Header>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{flexGrow: 1, paddingLeft: 15}}>
            {memorial && <p style={{...TextStyles.title.capitals, marginBottom: '0.25rem'}}><i
              className={Icon.Monument}/> {memorial?.name}</p>}
            {memorial &&
            <p style={{...TextStyles.text.small, marginBottom: '0.5rem'}}>{describeMemorialKind(memorial)}</p>}
            {memorial && memorial.extend_place &&
            <p><PlaceLink style={TextStyles.link.medium} place={memorial.extend_place}/></p>}
          </div>
          {user.canEdit() && (
            <div>
              <PageLink page={PageID.EditMemorial} param={id}>
                <i className={Icon.Edit}/>
              </PageLink>
            </div>
          )}
        </div>
      </Card.Header>
    )
  }

  const renderMemorialTab = () => {
    return (<>
      {renderHeader()}
      {renderPersons()}
    </>);
  };

  const renderPersons = () => {
    return (
      <Card style={{ marginTop: '1em' }} className='table-with-links'>
        <ListPersonsOnMemorial memorial={memorial}
                        history={history}
                        loading={loading}
                        modals={modals}
                        pending={[]}
                        readonly={true}
                        via={''}/>
      </Card>
    );
  }

  const renderDocumentsTab = () => {
    if (!memorial) {
      return ''
    } else {
      return (
        <>
          {renderHeader()}
          <ListDocumentsTab
            ref_id={memorial._id}
            ref_name={memorial.name ?? ''}
            ref_type='memorial'
            history={history}
            loading={loading}
            modals={modals}
            via={getPageVia(PageID.ViewMemorial, memorial._id)}
            readonly={true}
            pageStateKey='memorialDocumentation'
          />
        </>
      );
    }
  }

  if (!memorial) {
    return (<></>)
  } else {
    return (
      <Page
        id={PageID.ViewMemorial}
        entity={id}
        icon={Icon.Monument}
        title={memorial.name}
        via={via || getPageVia(PageID.ViewMemorial, id)}>
        <TopTabs
          id='view-memorials-tabs'
          defaultActiveKey='memorial'
          storeKey='viewMemorial'
          tabs={generateTabs()}/>
      </Page>
    )
  }
}

export default ViewMemorial
