// Format of trench coordinates:
// - First digits (and optionally letter) indicate the map number -> see https://library.mcmaster.ca/maps/ww1/ndx5to40.htm
//   (12 = Oostende, 19 = Dunkerque, 20 = Roulers, 27 = Poperinge, 28 = Ypres, 29 = Kortrijk, 51b = Arras ...)
// - The maps are divided in  areas indicated with a capital letter
// - Each of these areas is subdivided in up to 36 squares indicated with a number 1-36. Each of these squares is always 1000x1000 yards
// - These are then further subdivided in 4 500x500 yard sections indicated by lowercase letters a-d
// Thus each of these 500x500 yard locations is indicated with the map number followed by the subdivision eg. 57c.B14d
// Convention seems that these parts can be separated by a space or a dot.
// In this regex, both the 1000x1000 and 500x500 yard notations are accepted. So 57c.T1 and 57c.T1b are both fine.
// 
// If more accuracy is desired, these grids can be further divided into 10x10 areas indicated with two digits separated by a dot.
// The coordinates are in format x.y so that 0.0 is the bottomleft square and 9.0 is the bottomright square.
//
// If even more accuracy is needed, a 100x100 subgrid can be used instead. In this cases the areas are indicated using digits 00.00 - 99.99
//
// Thus the following are all valid coordinates:
// 57c S10
// 57c S.10
// 57c S.10.c
// 57c.S10c
// 57c.S10c.0.0
// 57c.S10c.01.10
// 
// Source: https://www.longlongtrail.co.uk/battlefields/how-to-read-a-british-trench-map/

const TRENCH_COORDINATE_REGEX = /^([0-9]{1,2}[a-z]?)[ .]([A-Z])\.?([0-9]{1,2})(\.?([a-d])(([ .]([0-9])\.([0-9]))|([ .]([0-9]{2})\.([0-9]{2})))?)?$/;

export function validateTrenchCoordinate(value: string) {
  return TRENCH_COORDINATE_REGEX.test(value);
}

export class BritishTrenchCoordinates {
  private map: string; // map name
  private level1grid: string; // A - Z (area in map)
  private level2grid: number; // 1 - 36 (1000x1000 yard grid)
  private level3grid?: string; // a - d (500x500 yard subgrid)
  private subgrid10?: { x: number, y: number }; // 10x10 subgrid coordinate
  private subgrid100?: { x: number, y: number }; // 100x100 subgrid coordinate

  static parse(coordinate: string): BritishTrenchCoordinates|null {
    const match = coordinate.match(TRENCH_COORDINATE_REGEX);
    if (!match)
      return null;
    
    const map = match[1];
    const level1grid = match[2];
    const level2grid = parseInt(match[3]);
    const level3grid = match[5];
    const subgrid10x = match[8] ? parseInt(match[8]) : undefined;
    const subgrid10y = match[9] ? parseInt(match[9]) : undefined;
    const subgrid100x = match[11] ? parseInt(match[11]) : undefined;
    const subgrid100y = match[12] ? parseInt(match[12]) : undefined;
    const subgrid10 = subgrid10x !== undefined && subgrid10y !== undefined ? { x: subgrid10x, y: subgrid10y } : undefined;
    const subgrid100 = subgrid100x !== undefined && subgrid100y !== undefined ? { x: subgrid100x, y: subgrid100y } : undefined;

    return new BritishTrenchCoordinates(map, level1grid, level2grid, level3grid, subgrid10, subgrid100);
  }

  constructor(map: string, level1grid: string, level2grid: number, level3grid?: string, subgrid10?: { x: number, y: number }, subgrid100?: { x: number, y: number }) {
    this.map = map;
    this.level1grid = level1grid;
    this.level2grid = level2grid;
    this.level3grid = level3grid;
    this.subgrid10 = subgrid10;
    this.subgrid100 = subgrid100;
  }

  toString(): string {
    let result = this.map + ' ' + this.level1grid + this.level2grid;
    if (this.level3grid)
      result += this.level3grid;
    
    if (this.subgrid10)
      result += ' ' + this.subgrid10.x + '.' + this.subgrid10.y;
    else if (this.subgrid100)
      result += ' ' + this.subgrid100.x.toString().padStart(2, '0')
        + '.' + this.subgrid100.y.toString().padStart(2, '0');

    return result;
  }
}
