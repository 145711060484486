import React, { CSSProperties } from 'react';
import { Form } from 'react-bootstrap';
import { useElementID } from '../../utils/ElementID';
import { InvalidFeedback } from './InvalidFeedback';

interface VerticalInputProps {
  name: string;
  label: string;
  value: string;
  error?: string;
  plainText?: boolean;
  validated?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  info?: string;
  type?: 'text'|'password';

  onChange: (value: string) => void;
  onPressedEnter?: () => void;
}
export const VerticalInput = (props: VerticalInputProps) => {
  const {
    name,
    label,
    value,
    error,
    plainText,
    validated,
    placeholder,
    info,
    type = 'text',
    
    onChange,
    onPressedEnter
  } = props;

  const handleKeyPress = onPressedEnter && ((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter')
      onPressedEnter();
  });
  const id = useElementID('input');
  const inputClass = plainText ? 'form-control-plaintext' : (error ? 'form-control is-invalid' : (validated ? 'form-control is-valid' : 'form-control'));
  return (
    <Form.Group controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        name={name}
        className={inputClass}
        type={type}
        value={value}
        onChange={e => onChange(e.currentTarget.value)}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
      />
      {error && <InvalidFeedback error={error} />}
      {info && <small className='form-text text-muted'>{info}</small>}
    </Form.Group>
  );             
}
