import React, { CSSProperties, useCallback } from 'react';
import { useElementID } from '../../utils/ElementID';
import styles from './CheckboxInput.module.scss';
import { InvalidFeedback } from './InvalidFeedback';

interface CheckboxInputProps {
  labelColumns?: number;
  inputColumns?: number;

  label: string;
  value: boolean;
  error?: string;
  readOnly?: boolean;
  validated?: boolean;
  placeholder?: string;
  style?: CSSProperties;

  onChange?: (checked: boolean) => void;
}

export const CheckboxInput = (props: CheckboxInputProps) => {
  const {
    labelColumns = 4,
    inputColumns,
    label,
    value,
    error,
    readOnly,
    validated,
    style,
    onChange,
  } = props;

  const id = useElementID('input');
  const inputClass = error ? 'form-check-input is-invalid' : (validated ? 'form-check-input is-valid' : 'form-check-input');
  return (
    <div className="form-group row" style={style}>
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}</label>
      <div className={`col-sm-${inputColumns || (12 - labelColumns)} ${styles.checkboxWrapper}`}>
        <Checkbox
          id={id}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          inputClass={inputClass}
        />
        {error && <InvalidFeedback error={error} />}
      </div>
    </div>
  );
};

interface CheckboxProps {
  id: string;
  readOnly?: boolean;
  value: boolean;
  onChange?: (checked: boolean) => void;
  inputClass?: string;
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    id,
    readOnly,
    value,
    onChange,
    inputClass = 'form-check-input'
  } = props;

  return (
    <div className={`form-check ${styles.checkboxCheck}`}>
      <label className='form-check-label'>
        <input
          id={id}
          type="checkbox"
          disabled={readOnly}
          className={inputClass}
          checked={value}
          onChange={useCallback(e => onChange && onChange(e.currentTarget.checked), [onChange])}
        />
        <span className='form-check-sign' />
        &nbsp;
      </label>
    </div>
  );
}
