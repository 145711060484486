import React, { ReactNode } from 'react';
import styles from './Backdrop.module.scss';
import { classes } from '../utils/Styles';

interface BackdropProps extends React.DOMAttributes<HTMLDivElement> {
  dark?: boolean;
  children: ReactNode;
}
export const Backdrop = (props: BackdropProps) => {
  const { children, dark, ...other } = props;
  return (
    <div className={classes(styles.backdrop, dark ? styles.dark : undefined)} {...other}>
      {children}
    </div>
  );
}
