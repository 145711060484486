import React, { useState, useEffect } from 'react';
import { PageProps, Page } from '../Page';
import { FormCard } from '../../components/FormCard';
import { Icon } from '../../components/Icons';
import { Button, Col, Row } from 'react-bootstrap';
import {
  Place,
  PlaceType,
  ResolutionStatus,
  toLatLng,
  IPlaceFields,
  toLatLon, createEmptyLanguages
} from '../../models/Place';
import { getActiveLanguage, T } from '../../Translate';
import { MapMarkerInputMap, ILatLon, MapMarkerInputValue } from '../../components/inputs/MapMarkerInput';
import api from '../../api';
import { BottomButtonRow } from '../../components/ButtonRow';
import { useNumberOfRelatedDocuments, usePlace } from '../../FunctionalAPI';
import { PlaceFormState, PlaceForm } from './PlaceForm';
import { PageID, getPageVia } from '../../utils/PageLinking';
import { TopTab, TopTabs } from "../../components/TopTabs";
import ListDocumentsTab from "../ViewPlace/ListDocumentsTab";
import { MapCoordinatesInput } from '../../components/inputs/MapCoordinatesInput';
import { NotificationManager } from "react-notifications";

interface EditPlacePageProps extends PageProps {
  id?: string;
}

export default function EditPlacePage(props: EditPlacePageProps) {
  const { history, loading, id, via } = props;

  /*** state ***/

  const [formState, setFormState] = useState<PlaceFormState>({
    resolutionStatus: ResolutionStatus.Custom,
    country: 'BE',
    type: PlaceType.Locality,
    alternativeNames: '',
    languages: createEmptyLanguages(),
    suggestions: [],
    similar: [],
    location: MapMarkerInputValue.empty()
  });

  const {
    resolutionStatus,
    country,
    type,
    alternativeNames,
    location
  } = formState;

  const place = usePlace(loading, id);

  /// The number of document related to this place.
  const numberOfDocuments = useNumberOfRelatedDocuments('place', place?._id )

  /*** effects ***/

  useEffect(() => {
    if (place) {
      setFormState({
        resolutionStatus: place.resolution_status,
        country: place.country_code,
        type: place.type,
        alternativeNames: place.alternative_names.join(','),
        location: MapMarkerInputValue.fromValue(toLatLng(place.loc)),
        languages: place.languages || createEmptyLanguages(),

        suggestions: [],
        similar: []
      });
    } else {
      clearForm();
    }
  }, [place]);

  /*** handlers ***/

  const handlePositionChanged = (position: MapMarkerInputValue) => {
    updatePlace({ location: position });
  }

  const handleClickedSave = () => {
    save(false);
  }

  /*** helpers ***/

  const updatePlace = (update: Partial<PlaceFormState>) => {
    if (resolutionStatus !== ResolutionStatus.Custom) {
      update.resolutionStatus = ResolutionStatus.Custom;
    }

    setFormState(Object.assign({}, formState, update));
  };

  const setLocation = (location: MapMarkerInputValue) => {
    updatePlace({ location });
  };

  const clearForm = () => {
    setFormState({
      resolutionStatus: ResolutionStatus.Custom,
      country: 'BE',
      type: PlaceType.Locality,
      alternativeNames: '',
      location: MapMarkerInputValue.empty(),
      languages: createEmptyLanguages(),

      suggestions: [],
      similar: []
    });
  }

  const save = async (createNew: boolean) => {
    const primaryLanguage = Place.getPrimaryLanguage(country);

    const fields: IPlaceFields & { _id?: string } = {
      _id: id,
      type,
      country_code: country,
      languages: formState.languages,
      alternative_names: alternativeNames.split(','),
      resolution_status: resolutionStatus,
      loc: toLatLon(location.lastValidPosition)
    };

    const result = await api.validatePlace(fields, id ? 'update' : 'create');
    if (result.status === 'NOK') {
      NotificationManager.success(T('page.editPlace.notificatons.saved.error',{name: fields.languages[primaryLanguage].name}));
      return;
    }

    if (id) {
      const placeWithId = Object.assign({ _id: id }, fields);
      await api.updatePlace(placeWithId);
      if (createNew)
        openNew();
    } else {
      const place = await api.createPlace(fields);
      if (createNew) {
        openNew();
      } else {
        history.push(`/places/${place._id}`);
      }
    }
    NotificationManager.success(T('page.editPlace.notificatons.saved',{name: fields.languages[primaryLanguage].name}));
  }

  const openNew = () => {
    if (id) {
      history.push('/places/create');
    } else {
      clearForm();
    }
  }

  /*** rendering ***/

  const title = place ? T('page.editPlace.title.edit') : T('page.editPlace.title.create');

  const generateTabs = (): TopTab[] => {
    return [
      {
        icon: Icon.ListUl,
        title: T('page.place.tab.map'),
        id: 'map',
        content: () => renderEditTab(),
        badgeContent: () => '',
      },
      {
        icon: Icon.ListUl,
        title: T('page.place.tab.documents'),
        id: 'documents',
        content: () => renderPlaceDocumentsTab(),
        badgeContent: () => numberOfDocuments.toString(),
      }
    ];
  }

  const renderEditTab = () => {
    return (
      <>
        <Row style={{marginBottom: 70}}>
          <Col>
            <FormCard
              icon={Icon.Info}
              title={T('modal.editPlace.info')}
              style={{marginTop: 15}}>
              <PlaceForm formState={formState} setFormState={setFormState} placeId={id}/>
            </FormCard>
          </Col>
          <Col>
            <FormCard
              icon={Icon.MapMarker}
              title={T('modal.editPlace.location')}
              style={{marginTop: 15}}
            >
              <MapMarkerInputMap
                containerElement={<div style={{height: 400}}/>}
                mapElement={<div style={{height: `100%`}}/>}
                position={location}
                defaultCenter={location.lastValidPosition || {lat: 52, lng: 3}}
                onChange={handlePositionChanged}
                label=''
              />
              <div style={{ height: '1rem'}} />
              <MapCoordinatesInput
                name='coordinates'
                label={T('page.place.coordinates')}
                position={location}
                onChange={setLocation}
              />
            </FormCard>
          </Col>
        </Row>
        <BottomButtonRow>
          <Button variant='primary' onClick={handleClickedSave}>
            {T('generic.action.save')}
          </Button>
        </BottomButtonRow>
      </>
    );
  }

  const renderPlaceDocumentsTab = () => {
    if (!place) {
      return (<></>)
    } else {
      return (
        <ListDocumentsTab
          ref_id={new Place(place).id}
          ref_name={new Place(place).describe(getActiveLanguage())}
          ref_type='place'
          history={props.history}
          loading={props.loading}
          modals={props.modals}
          via={getPageVia(PageID.EditPlace, place._id)}
          readonly={false}
          pageStateKey='editPlaceDocumentation'
        />
      );
    }
  }

  return (
    <Page
      id={id ? PageID.EditPlace : PageID.CreatePlace}
      entity={id}
      icon={Icon.MapMarker}
      title={title}
      shortTitle={T('generic.breadcrumb.edit')}
      via={via || getPageVia(PageID.ViewPlace, id)}
      viaTitle={via ? title : undefined}
    >
      <TopTabs
        id='view-place-tabs'
        defaultActiveKey= 'map'
        tabs={generateTabs()}
        storeKey='editPlace'
      />
    </Page>
  )
}
