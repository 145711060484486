import React from 'react';
import { IPersonStory2 } from "../../../models/PersonStory2";
import { Button, Col, Row } from "react-bootstrap";
import { T } from "../../../Translate";
import { SummaryCheckbox } from "./SummaryCheckbox";
import { getPageLink, PageID } from "../../../utils/PageLinking";
import { TextStyles } from "../../../config/styles";
import { Link } from "react-router-dom";


export interface SummaryTabProps {
  personStory?: IPersonStory2
  onUnpublishedButtonClicked: () => void
}


export const SummaryTab = (props: SummaryTabProps) => {
  const { personStory, onUnpublishedButtonClicked  } = props;

  const publishTargets = personStory?.targets || [];
  const published = personStory?.published_date !== null && personStory?.published_date !== undefined;
  const publishedToMuseum = published && publishTargets.includes('museum');
  const publishedToKey = published && publishTargets.includes('key');  

  return <>
    {/*The published status*/}
    <Row>
      <Col sm={8}>
        <h6>STATUS</h6>
        <SummaryCheckbox personStory={personStory}/>
      </Col>
    </Row>

    {/* Only visible when published */}
    {personStory?.published_date !== null && personStory?.published_date !== undefined && (
      <>
        {/* Links to pubic stories */}
        <Row>
          <Col sm={12}>
            <h6>LINKS</h6>
            <div style={{marginBottom: '2em'}}>
              {publishedToMuseum && (
                <div>
                  <Link style={{...TextStyles.link.medium}}
                        to={`${getPageLink(PageID.PublicViewPersonStory,personStory?._id)}`}>
                    {T('page.personstory.publicmuseum.link')}
                  </Link>
                </div>
              )}
              {publishedToMuseum && personStory?.story_child_option &&
                <div>
                  <Link style={{...TextStyles.link.medium}}
                        to={`${getPageLink(PageID.PublicViewPersonStory,personStory?._id)}?child`}>
                    {T('page.personstory.publicmuseum.child.link')}
                  </Link>
                </div>
              }
              {publishedToKey && (
                <div>
                  <Link style={{...TextStyles.link.medium}}
                        to={`${getPageLink(PageID.PublicViewPersonKeyStory,personStory?._id)}`}>
                    {T('page.personstory.publickey.link')}
                  </Link>
                </div>
              )}
              {/*-- not (yet) necessary -- publishedToKey && personStory?.story_child_option &&
                <div>
                  <Link style={{...TextStyles.link.medium}}
                        to={`${getPageLink(PageID.PublicViewPersonKeyStory,personStory?._id)}?child`}>
                    {T('page.personstory.publickey.child.link')}
                  </Link>
                </div>
              */}
            </div>
          </Col>
        </Row>
        {/* Unpublish button */}
        <Row style={{marginTop: 32}}>
          <Col sm={12} className={'d-flex justify-content-left'}>
            <Button variant="danger" onClick={() => { onUnpublishedButtonClicked() }}>
              {T("personstories.summary.unpublish")}
            </Button>
          </Col>
        </Row>
      </>
    )}
  </>;
};
