import React, { useMemo, useEffect, useState } from 'react';
import { T, plural } from '../../Translate';
import {
  BaseContactPersonEvent,
  IContactPerson,
  ContactMomentType,
  ContactPersonEventData
} from '../../models/ContactPerson';
import {
  ITableColumn,
  StringTableColumn,
  ActionsTableColumn,
  TableRowActionStyle,
  StringFieldTableColumn,
  ComponentTableColumn, ColumnAlign
} from '../../components/table/Fields';
import { Icon } from '../../components/Icons';
import { ConfirmationModal } from '../../modals/Confirmation';
import api from '../../api';
import { NotificationManager } from 'react-notifications';
import { IQueryResult } from '../../models/Generic';
import { ListState, ListPage } from '../ListPage';
import { PageProps } from '../Page';
import { useModalContext } from '../../modals/ModalContext';
import { usePageState } from '../../redux/Actions';
import { useRefresh } from '../../utils/Functional';
import { getPageLinkWithVia, PageID } from '../../utils/PageLinking';
import { DateTimeOutput } from "../../components/DateTimeOutput";
import { DateTimeFromToOutput } from "../../components/DateTimeFromToOutput";
import { ContactMomentTypeOutput } from "./ContactMomentTypeOutput";
import { Place } from "../../models/Place";

export interface ListContactPersonEventsStateProps extends ListState {}

function getSortColumn(name: string) {
  if (name === 'name')
    return 'filter';
  else if (name == 'duration')
    return 'end';
  else if (name == 'method')
    return 'contact_method';
  else
    return name;
}

const rowKey = (item: ContactPersonEventData) => item._id;
const PageSize = 10;

export default (props: PageProps) => {
  const { loading, history, via } = props;
  const modals = useModalContext();
  const [pageState, updatePageState] = usePageState('contactPersonEvents');
  const { filter, tablePage, sortColumn, sortDirection } = pageState;

  const [items, setItems] = useState<IQueryResult<ContactPersonEventData>>();
  const refresh = useRefresh(() => {
    loading.loading(
      api.findContactPersonEvents(
        filter,
        tablePage * PageSize,
        PageSize,
        [[getSortColumn(sortColumn), sortDirection]]
      ),
      plural('contactPerson')
    ).then(setItems);
  });
  useEffect(refresh, [loading, filter, tablePage, sortColumn, sortDirection]);

  const columns: ITableColumn<ContactPersonEventData>[] = useMemo(() => {

    return [
      new ComponentTableColumn(
        'name',
        T('contactPerson.name'),
        (contactPersonEvent) => { return (
          <strong>{contactPersonEvent.personLastName + ' ' + contactPersonEvent.personFirstName}</strong>
        )},
        { sortable: true, align: ColumnAlign.Left, clickable: true }),
      new ComponentTableColumn(
        'duration',
        T('contactMoment.duration'),
        (contactPersonEvent) => { return (
          <DateTimeFromToOutput from={contactPersonEvent.start} to={contactPersonEvent.end}/>)
        },
        { sortable: true, align: ColumnAlign.Left, clickable: true }),
      new ComponentTableColumn(
        'method',
        T('contactMoment.contactMethod'),
        (contactPersonEvent) => { return (
          <span>
            <ContactMomentTypeOutput contactMomentType={contactPersonEvent.contactMethod as ContactMomentType}/>
        </span>)
        },
        { sortable: true, align: ColumnAlign.Right, clickable: true }),
      new ActionsTableColumn(
        'actions',
        T('generic.list.actions'),
        contactPersonEvent => [
          {
            icon: Icon.Eye,
            style: TableRowActionStyle.Success,
            tooltip: T('generic.action.view'),
            page: PageID.EditContactPerson,
            pageParam: contactPersonEvent.contactPersonId
          }
        ],
        { width: 80 }
      )

    ];
  }, [modals, refresh]);

  const user = api.user;

  const handleClickedRow = (contactPersonEventData: ContactPersonEventData) => {
    history.push(getPageLinkWithVia(PageID.EditContactPerson,
      contactPersonEventData.contactPersonId,
      PageID.ListContactMoments,
      undefined))
  };

  return (
    <ListPage
      id={PageID.ListContactMoments}
      icon={Icon.AddressBook}
      title={T('page.listContactMoments.title')}
      via={via}
      noun='contactPerson'
      columns={columns}
      items={items}
      state={pageState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedRow={handleClickedRow}
    />
  );
}
