import React, { useMemo, useState, useEffect } from 'react';
import {
  getPlaceTypeName,
  getResolutionStatusColor,
  getResolutionStatusName, IPlace, IPlaceLanguage,
  Place,
  ResolutionStatus,
} from '../../models/Place';
import { Icon } from '../../components/Icons';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import {
  ActionsTableColumn,
  ComponentTableColumn,
  DefaultColumnOptions,
  ICellProps,
  IColumnOptions,
  ITableColumn,
  StringTableColumn,
  TableRowActionStyle
} from '../../components/table/Fields';
import api, {GetPlaceOptions} from '../../api';
import { showNotification } from '../../utils/Notification';
import { T, plural } from '../../Translate';
import { ConfirmationModal } from '../../modals/Confirmation';
import { PersonEventType } from '../../models/PersonEvent';
import { MergeIcon } from '../../components/MergeIcon';
import { MergePlacesModal, MergePlacesModalResult } from './MergePlacesModal';
import { PlaceEventListModal } from './PlaceEventListModal';
import { TextMode } from "../../models/SpecialFunction";
import { NotificationManager } from 'react-notifications';
import { ListState, ListPage } from '../ListPage';
import { PageProps } from '../Page';
import { IQueryResult } from '../../models/Generic';
import { useModalContext } from '../../modals/ModalContext';
import { usePageState } from '../../redux/Actions';
import { User } from '../../models/User';
import { useRefresh } from '../../utils/Functional';
import { PageID } from '../../utils/PageLinking';
import { getName, registerLocale } from 'i18n-iso-countries';
import { getActiveLanguage } from '../../Translate';
import { PlaceEventsView } from "./PlaceEventsView";
import { inspect } from "util";
import styles from './index.module.scss';


interface PlacesListState {
  selectedForMerge: Place[];
}
export interface ListPlacesStateProps extends ListState {
  filterStatus: ResolutionStatus;
  mergingFirst?: Place;
  mergingSecond?: Place;
}



// !! You need to register the libraries before you can call the `getName()` function
// from the i18n-iso-countries package !!
registerLocale(require("i18n-iso-countries/langs/de.json"));
registerLocale(require("i18n-iso-countries/langs/nl.json"));
registerLocale(require("i18n-iso-countries/langs/en.json"));
registerLocale(require("i18n-iso-countries/langs/fr.json"));

export class PlaceNameTableColumn implements ITableColumn<Place> {
  id: string;
  title: string;
  options: IColumnOptions;

  constructor(id: string, title: string, options?: Partial<IColumnOptions>) {
    this.id = 'name';
    this.title = 'Naam';
    this.options = Object.assign({}, DefaultColumnOptions, { sortable: false, clickable: false }, options);
  }

  communityNames = (place: Place): string => {
    let resultArray: string[] = []
    const language: string  = getActiveLanguage()
    const placefields: IPlaceLanguage | undefined = place.place.languages[language]
    if (placefields.locality)
      resultArray.push(placefields.locality)
    return resultArray.join(', ')
  }

  render(item: Place, props: ICellProps): JSX.Element {

    const communityTextStyle = {
      fontWeight: 500,
      fontSize: 12
    }

    return (
    <>
      <td key={this.id} {...props}>
          <Row>
            <Col>
            <i
              className={Icon.MapMarker}
              style={{ color: getResolutionStatusColor(item.place.resolution_status)}}
              title={getResolutionStatusName(item.place.resolution_status)}
            />
            {' '}
              <strong>{item.name}</strong>
            </Col>
          </Row>
          <Row>
            <Col>
              {' '}
              <span style={communityTextStyle}>{this.communityNames(item)}</span>
            </Col>
          </Row>
      </td>
    </>
    );
  }

  sort = (a: Place, b: Place): number => {
    return a.name.localeCompare(b.name);
  }
}

function getSortColumn(column: string) {
  switch (column) {
    case 'countryCode':
      return 'country_code';
    case 'name':
      return 'filter';
    default:
      return column;
  }
}



function getColumns(
  user: User,
  handleClickedPlaceEvents: (place: IPlace, eventType: PersonEventType, count: number) => void,
  handleClickedRemove: (place: Place) => void,
  handleClickedMerge: (place: Place, state: PlacesListState) => void
) {
  const columns: ITableColumn<Place, PlacesListState>[] = [
    new PlaceNameTableColumn('name', T('person.name'), {clickable: true, sortable: true, width: 200}),
    new StringTableColumn('type', T('place.type'), place => getPlaceTypeName(place.place.type), {width: 100}),
    new StringTableColumn('area2', T('place.area2'), place => place.getTranslation().administrative_area_level_2 || '', { sortable: false }),
    new StringTableColumn('area1', T('place.area1'), place => place.getTranslation().administrative_area_level_1 || '', { sortable: false }),
    new ComponentTableColumn('countryCode', T('place.countryCode'), place => {
      return <div>
          <span>{place.countryCode}</span><br/>
          <span className={styles.textEllipsis} style={{width: 120, fontSize: 12}}>{getName(place.countryCode, getActiveLanguage())}</span>
        </div>
    }, { clickable: true, sortable: false, width: 128 }),
    new ComponentTableColumn('events', T('place.events'), place => {
      return <PlaceEventsView place={place.place} onClickedPlaceEvent={handleClickedPlaceEvents}/>
    }, { clickable: false, sortable: false, width: 128 })
  ];
  if (user.canEdit()) {
    columns.push(
      new ActionsTableColumn('actions', T('generic.list.actions'), place => [
        { icon: Icon.Edit, page: PageID.EditPlace, pageParam: place.id },
        {
          icon: state => <MergeIcon selected={state.selectedForMerge} item={place} />,
          style: TableRowActionStyle.Warning,
          tooltip: T('generic.action.merge'),
          onClick: handleClickedMerge
        },
        {
          icon: Icon.TimesCircle,
          onClick: handleClickedRemove,
          style: TableRowActionStyle.Danger
        }
      ])
    );
  }
  return columns;
}

const StatusOptions = [
  ResolutionStatus.All,
  ResolutionStatus.Found,
  ResolutionStatus.NotFound,
  ResolutionStatus.Unresolved,
  ResolutionStatus.Custom,
  ResolutionStatus.Ambiguous
];

const PageSize = 10;
const rowKey = (item: Place) => item.id;

export default (props: PageProps) => {
  const { history, loading, via } = props;

  const modals = useModalContext();
  const user = api.user;

  const statusOptions = useMemo(() => StatusOptions.map(status => (
    <option key={status} value={status}>{getResolutionStatusName(status)}</option>
  )), []);

  const [pageState, updatePageState] = usePageState('places');
  const { filter, tablePage, sortColumn, sortDirection, filterStatus, mergingFirst, mergingSecond } = pageState;
  const [items, setItems] = useState<IQueryResult<Place>>();
  const refresh = useRefresh(() => {
    loading.loading(api.findPlaces(
      filter,
      tablePage * PageSize,
      PageSize,
      [[getSortColumn(sortColumn), sortDirection]],
      [GetPlaceOptions.EventsByType],
      TextMode.Contains,
      { resolution_status: filterStatus === ResolutionStatus.All ? undefined : filterStatus }
    ), plural('place'))
      .then(result => {
        const places = result.data.map(place => new Place(place));
        return setItems({ data: places, total: result.total });
      });
  });
  useEffect(refresh, [filter, tablePage, sortColumn, sortDirection, filterStatus]);

  const handleClickedAdd = () => {
    history.push('/places/create');
  };

  const handleClickedRow = (place: Place) => {
    history.push(`/places/${place.id}`);
  };

  const columns = useMemo(
    () => {
      const handleClickedPlaceEvents = (place: IPlace, eventType: PersonEventType, count: number) => {
        modals.show(props => (
          <PlaceEventListModal
            history={history}
            place={place}
            type={eventType}
            count={count}
            {...props}
          />
        ));
      }

      const handleClickedRemove = async (place: Place) => {
        const result = await api.canRemovePlace(place.id);
        if (result === 0) {
          showNotification({
            message: T('modal.removePlace.impossible'),
            style: 'warning'
          });
          return;
        }
        const confirmed = await modals.show<boolean>(props => (
          <ConfirmationModal
            title={T('modal.removePlace.title')}
            message={T('modal.removePlace.message', { name: place.name })}
            acceptText={T('generic.action.remove')}
            acceptStyle='danger'
            rejectText={T('generic.action.cancel')}
            {...props}
          />
        ));
        if (!confirmed)
          return;

        await api.removePlace(place.id);
        NotificationManager.success(T('modal.removePlace.removed', { name: place.name }));
        refresh();
      };

      const handleClickedMerge = async (place: Place, state: PlacesListState) => {
        const mergingFirst = state.selectedForMerge[0];
        if (!mergingFirst) {
          updatePageState({ mergingFirst: place });
          return;
        }
        if (place === mergingFirst) {
          updatePageState({ mergingFirst: undefined });
          return;
        }

        updatePageState({ mergingSecond: place });
        const result = await modals.show<MergePlacesModalResult>(props => (
          <MergePlacesModal
            placeA={mergingFirst}
            placeB={place}
            {...props}
          />
        ));
        if (!result.confirmed) {
          // If the cancel button was pressed, then deselect the second or 'to' place to merge
          updatePageState({
            mergingSecond: undefined,
          });
          return;
        } else {

          // unselect the places
          updatePageState({
            mergingFirst: undefined,
            mergingSecond: undefined,
          });

          // start the merging
          await loading.loading(api.mergePlaces(result.placeA.id, result.placeB.id), 'Merging');
          const message = T('modal.mergePlace.merged', {a: mergingFirst.name, b: place.name});
          NotificationManager.success(message);
          if (result.openResult) {
            history.push(`/places/${result.placeA.id}`);
          } else {
            refresh();
          }
        }
      };

      return getColumns(
        user,
        handleClickedPlaceEvents,
        handleClickedRemove,
        handleClickedMerge
      );
    },
    [user, updatePageState, history, modals, refresh, loading]
  );

  const handleClickedCancelMerge = () => {
    updatePageState({
      mergingFirst: undefined,
      mergingSecond: undefined,
    });
  };

  const handleFilterStatusChanged = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    updatePageState({
      filterStatus: e.currentTarget.value as ResolutionStatus,
      tablePage: 0
    });
  };

  const additionalHeader = <>
    <select
      className='form-control'
      style={{ width: 200 }}
      value={filterStatus}
      onChange={handleFilterStatusChanged}
    >
      {statusOptions}
    </select>
    {filterStatus !== ResolutionStatus.All && (
      <i className={Icon.MapMarker} style={{
        color: getResolutionStatusColor(filterStatus),
        paddingLeft: 10
      }} />
    )}
    {mergingFirst && (
      <ButtonGroup style={{ marginLeft: 10, marginRight: 10 }}>
        <Button>
          <i className={Icon.Merge} />
          {' '}
          {mergingFirst.name}
        </Button>
        <Button onClick={handleClickedCancelMerge}>
          <i className={Icon.Times} />
        </Button>
      </ButtonGroup>
    )}
  </>;

  const tableState = useMemo(() => {
    const merging: Place[] = [];
    if (mergingFirst)
      merging.push(mergingFirst);
    if (mergingSecond)
      merging.push(mergingSecond);
    return { selectedForMerge: merging };
  }, [mergingFirst, mergingSecond]);

  return (
    <ListPage
      id={PageID.ListPlaces}
      icon={Icon.MapMarker}
      title={T('page.places.title')}
      via={via}
      noun='place'
      columns={columns}
      items={items}
      state={pageState}
      tableState={tableState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedRow={handleClickedRow}
      onClickedAdd={handleClickedAdd}
      additionalHeader={additionalHeader}
    />
  );
}
