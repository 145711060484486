import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../Icons';

interface NavGroupProps {
  id: string;
  icon: Icon;
  title: string;
  children: JSX.Element|JSX.Element[];
}
export const NavGroup = (props: NavGroupProps) => {
  const { id, icon, title, children } = props;
  return (
    <li className='nav-item'>
      <a className='nav-link main-nav-link collapsed' data-toggle='collapse' href={`#${id}`}>
        <i className={icon} />
        <p>
          {title}
          <b className='caret' />
        </p>
      </a>
      <div id={id} className='collapse'>
        <ul className='nav'>
          {children}
        </ul>
      </div>
    </li>
  );
}

interface NavGroupItemProps {
  icon: Icon;
  title: string;
  to: string;
}
export const NavGroupItem = (props: NavGroupItemProps) => {
  const { icon, title, to } = props;
  return (
    <li className='nav-item'>
      <Link className='nav-link' to={to}>
        <span className='sidebar-mini'><i className={icon} /></span>
        <span className='sidebar-normal'>{title}</span>
      </Link>
    </li>
  );
}
