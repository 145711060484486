import { Form } from 'react-bootstrap';

import { MapMarkerInputValue } from './MapMarkerInput';
import { useElementID } from '../../utils/ElementID';
import FormGroup from './FormGroup';

interface MapCoordinatesInputProps {
  labelColumns?: number;

  name: string;
  label: string;
  position: MapMarkerInputValue;
  placeholderLat?: string;
  placeholderLon?: string;
  error?: string;
  disabled?: boolean;

  /**
   * callback when lat & lon changes
   * @param place is undefined if lat and lon are empty
   */
  onChange: (position: MapMarkerInputValue) => void;
}

export const MapCoordinatesInput = (props: MapCoordinatesInputProps) => {
  const {
    labelColumns = 4,
    name,
    label,
    position,
    placeholderLat,
    placeholderLon,
    onChange,
    error,
    disabled
  } = props;

  const id = useElementID('input');
  return (
    <FormGroup id={id} labelColumns={labelColumns} label={label} error={error}>
      <div className='row'>
        <div className={`col-sm-6`}>
          <Form.Control
            id={id + '-lat'}
            name={name + '-lat'}
            type="text"
            className='form-control'
            placeholder={placeholderLat}
            value={position.latAsString}
            onChange={e => onChange(position.withLatValue(e.currentTarget.value))}
            isInvalid={error !== undefined}
            disabled={disabled}
          />
        </div>
        <div className={`col-sm-6`}>
          <Form.Control
            id={id + '-lng'}
            name={name + '-lng'}
            type="text"
            className='form-control'
            placeholder={placeholderLon}
            value={position.lonAsString}
            onChange={e => onChange(position.withLonValue(e.currentTarget.value))}
            isInvalid={error !== undefined}
            disabled={disabled}
          />
        </div>
      </div>
    </FormGroup>
  );
};
