import React from 'react';
import { InvalidFeedback } from './InvalidFeedback';

interface ColumnInputGroupProps {
  id: string;
  label: string;
  children: React.ReactNode;

  style?: React.CSSProperties;
  error?: string;
  info?: string;
}

export function ColumnInputGroup(props: ColumnInputGroupProps) {
  const { id, label, children, style, error, info } = props;

  return (
    <div className="form-group" style={style}>
      <label htmlFor={id} className={`control-label`}>{label}:</label>
      <div>
        {children}
        {error && <InvalidFeedback error={error}/>}
        {info && <small className='form-text text-muted'>{info}</small>}
      </div>
    </div>
  );
}
