import { TranslationKey, T } from "../Translate";

export enum UserType {
  Anonymous = 'anonymous',
  Admin = 'admin',
  Revisor = 'revisor',
  Normal = 'normal',
  NormalCivilian = 'normal-civilian',
  ApiRead = 'api-read',
  ApiWrite = 'api-write',
  ReadOnly = 'readonly',
  ReadBESoldiers = 'read-be-soldiers'
}

export const UserTypeNames: [UserType, TranslationKey][] = [
  [UserType.Admin, 'usertype.admin'],
  [UserType.Revisor, 'usertype.revisor'],
  [UserType.Normal, 'usertype.normal'],
  [UserType.NormalCivilian, 'usertype.normalCivilian'],
  [UserType.ApiRead, 'usertype.apiRead'],
  [UserType.ApiWrite, 'usertype.apiWrite'],
  [UserType.ReadOnly, 'usertype.readOnly'],
  [UserType.ReadBESoldiers, 'usertype.readBeSoldiers']
];

const AllUserTypes: [UserType, TranslationKey][] = [
  [UserType.Anonymous, 'usertype.anonymous'],
  ...UserTypeNames
];

export function getUsertypeName(type: UserType) {
  return T((AllUserTypes.find(t => t[0] === type) || AllUserTypes[0])[1]);
}

export enum Dataset {
  Any = 'any',
  Deaths = 'deaths',
  Namelist = 'namenlijst',
  IMR = 'irish_memorial_records',
  Survivors = 'survivors',
  Stories = 'stories',
  Stories1 = 'stories_1',
  Stories2 = 'stories_2',
  Stories3 = 'stories_3',
  Stories4 = 'stories_4',
  WithDocumentation = 'with_documentation',
  Contacts = 'contacts',
  Military = 'military'
}

export const AccessibleDatasets: Dataset[] = [
  Dataset.Any,
  Dataset.Namelist,
  Dataset.Deaths,
  Dataset.Survivors,
  Dataset.WithDocumentation,
  Dataset.Stories,
  Dataset.Contacts,
  Dataset.IMR,
  Dataset.Military
];

export const DatasetNames : [Dataset, TranslationKey][] = [
  [Dataset.Any, 'dataset.any'],
  [Dataset.Namelist, 'dataset.namelist'],
  [Dataset.Deaths, 'dataset.deaths'],
  [Dataset.Survivors, 'dataset.survivors'],
  [Dataset.IMR, 'dataset.imr'],
  [Dataset.Stories, 'dataset.stories'],
  [Dataset.Stories1, 'dataset.stories1'],
  [Dataset.Stories2, 'dataset.stories2'],
  [Dataset.Stories3, 'dataset.stories3'],
  [Dataset.Stories4, 'dataset.stories4'],
  [Dataset.WithDocumentation, 'dataset.withDocumentation'],
  [Dataset.Contacts, 'dataset.contacts'],
  [Dataset.Military, 'dataset.military']
];

export function translateDataset(dataset: Dataset) {
  const translation = DatasetNames.find(x => x[0] === dataset);
  return translation ? T(translation[1]) : dataset;
}

export interface IUserFields {
  type: UserType;
  dataset: Dataset;
  account: string;
  name: string;
  email: string;
  phone: string;
  description: string;
  language: string;
}


export interface IUser extends IUserFields {
  _id: string;

  deleted: boolean;
  confirmed: boolean;
}

export class User {
  static fromJSON(json: IUser) {
      return new User(
          json._id,
          json.type,
          json.account,
          json.name,
          json.email,
          json.phone,
          json.description,
          json.language);
  }

  id: string|null;
  type: UserType;
  username: string;
  name: string;
  email: string;
  phone: string;
  description: string;
  language: string;
  army: string;
  dataset: string;
  deleted: boolean;

  constructor(
    id: string|null,
    type: UserType,
    username: string,
    name: string,
    email: string,
    phone: string,
    description: string,
    language: string,
    army?: string,
    dataset?: string,
    deleted?: boolean)
  {
      this.id = id;
      this.type = type;
      this.username = username;
      this.name = name || username;
      this.email = email || '';
      this.phone = phone || '';
      this.description = description || '';
      this.language = language || 'en';
      this.army = army || '';
      this.dataset = dataset || 'any';
      this.deleted = deleted || false;
  }

  isAdmin() {
    return this.type === UserType.Admin;
  }

  isRevisor() {
    return this.type === UserType.Revisor;
  }

  isAtLeastRevisor() {
    return this.type === UserType.Admin || this.type === UserType.Revisor;
  }

  isAtLeastNormal() {
    return this.type === UserType.Normal || this.isAtLeastRevisor();
  }

  isReadOnly() {
    return this.type !== UserType.Admin
      && this.type !== UserType.Normal
      && this.type !== UserType.NormalCivilian
      && this.type !== UserType.Revisor;
  }

  isGuest() {
    return this.type === UserType.Anonymous;
  }

  canEdit() {
    return !this.isReadOnly();
  }

  toJSON() {
      return {
          _id: this.id,
          type: this.type,
          account: this.username,
          name: this.name,
          email: this.email,
          phone: this.phone,
          description: this.description,
          language: this.language
      };
  }
}

export const AnonymousUser = new User(null, UserType.Anonymous, '', '', '', '', '', 'nl');
