import React from "react";
import { IPersonStory2, Language, StoryStatus } from "../../../models/PersonStory2";
import { Badge } from "react-bootstrap";
import { StoryIndex } from "./StoryTextView";
import { PersonStoryWrapper } from "../../../models/wrappers/PersonStoryWrapper";

const badgeVariantForStatus = (status: StoryStatus) => {
  switch(status) {
    case StoryStatus.IN_PROGRESS:
      return 'secondary'
    case StoryStatus.IN_REVIEW:
      return 'warning';
    case StoryStatus.DONE:
      return 'success'
  }
}

interface PersonStoryLanguageStatusBadgeProps {
  language: Language
  status: StoryStatus
}

const PersonStoryLanguageStatusBadge = (props: PersonStoryLanguageStatusBadgeProps) => {
  const badgeText = props.language as string
  const badgeVariant = badgeVariantForStatus(props.status)
  return (<Badge style={{marginLeft: 4}} variant={badgeVariant}>{badgeText}</Badge>)
}

export interface PersonStoryStatusViewForLanguagesProps {
  personStory: IPersonStory2
  storyIndex: StoryIndex
}

export function PersonStoryStatusViewForLanguages(props: PersonStoryStatusViewForLanguagesProps) {
  const personStoryWrapper = new PersonStoryWrapper(props.personStory)
  return (
    <div>
      {Object.values(Language).map(language => (
        <PersonStoryLanguageStatusBadge
          key={language}
          language={language}
          status={personStoryWrapper.getStoryStatus(props.storyIndex, language)}
        />
      ))}
    </div>
  );
}


export interface PersonStoryStatusViewForLanguageProps {
  personStory: IPersonStory2,
  storyIndex: StoryIndex,
  language: Language
}

export function PersonStoryStatusViewForLanguage(props: PersonStoryStatusViewForLanguageProps) {
  const {language, personStory} = props;
  const personStoryWrapper = new PersonStoryWrapper(personStory)
  return (
    <PersonStoryLanguageStatusBadge
      key={language}
      language={language}
      status={personStoryWrapper.getStoryStatus(props.storyIndex, language)}
    />
  );
}
