import React from 'react';
import { Card } from 'react-bootstrap';

interface NoDataViewProps {
  text: String;
}

export const NoDataView = (props: NoDataViewProps) => {
  const { text } = props;
  return (
    <Card bg="light" className="text-center">
      <Card.Body >
        <Card.Text className="text-muted">
          {text}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
