import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ModalProps, useModal } from './BaseModal';
import { T } from '../Translate';
import { ModalHeader } from './ModalHeader';
import api from '../api';
import { IMilitaryEntity, MilitaryEntityType, IMilitaryEntityFields, Armies } from '../models/MilitaryEntity';
import { MilitaryEntityFormState, MilitaryEntityForm } from '../pages/EditMilitaryEntity/MilitaryEntityForm';
import { NotificationManager } from 'react-notifications';
import { getErrorDescription } from '../models/ValidationResult';
import { ButtonRowModalFooter } from '../components/ButtonRow';

interface CreateMilitaryEntityModalProps extends ModalProps<IMilitaryEntity|undefined> {
  value: string;
}

export const CreateMilitaryEntityModal = (props: CreateMilitaryEntityModalProps) => {
  const { value } = props;
  const [isOpen, resolve] = useModal(props);

  const [formState, setFormState] = useState<MilitaryEntityFormState>({
    type: MilitaryEntityType.Regiment,
    regiment: value,
    regimentError: undefined,
    unitNr: '',
    unitNrError: undefined,
    unit: '',
    unitError: undefined,
    brigade: '',
    brigadeError: undefined,
    division: '',
    divisionError: undefined,
    army: Armies[0],
    armyError: undefined
  });

  const handleClickedSave = async () => {
    const {
      type,
      regiment,
      unitNr,
      unit,
      brigade,
      division,
      army
    } = formState;

    const fields: IMilitaryEntityFields = {
      type,
      army
    };
    switch (type) {
      case MilitaryEntityType.Brigade:
        fields.brigade = brigade;
        break;
      case MilitaryEntityType.Division:
        fields.division = division;
        break;
      case MilitaryEntityType.Unit:
        fields.unit_nr = unitNr;
        fields.unit = unit;
        fields.regiment = regiment;
        break;
      case MilitaryEntityType.Regiment:
        fields.regiment = regiment;
        break;
    }
    const result = await api.validateMilitaryEntity(fields, 'create');
    if (result.status === 'OK') {
      const entity = await api.createMilitaryEntity(fields);
      NotificationManager.success(T('page.militaryEntity.created'));
      resolve(entity);
      return;
    }

    const errors = result.data || {};
    setFormState(Object.assign({}, formState, {
      regimentError: getErrorDescription(errors.regiment),
      unitError: getErrorDescription(errors.unit),
      unitNrError: getErrorDescription(errors.unit_nr),
      brigadeError: getErrorDescription(errors.brigade),
      divisionError: getErrorDescription(errors.division),
      armyError: getErrorDescription(errors.army)
    }));
  };

  const handleClickedCancel = () => resolve(undefined);

  return (
    <Modal show={isOpen} onHide={handleClickedCancel} size='lg'>
      <ModalHeader>{T('modal.createplace.title')}</ModalHeader>
      <Modal.Body>
        <MilitaryEntityForm formState={formState} setFormState={setFormState} />
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='secondary' onClick={handleClickedCancel}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={handleClickedSave}>
          {T('generic.action.create')}
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
