import React, { useState } from 'react';
import { Alert, Modal, ModalBody } from 'react-bootstrap';
import { ModalProps, useModal } from '../../../modals/BaseModal';
import { ModalHeader } from '../../../modals/ModalHeader';
import { IPersonStory2 } from '../../../models/PersonStory2';
import { T } from '../../../Translate';
import { TextView } from '../../ViewPerson/TextView';
import SearchStoryInput from './SearchStoryInput';

interface SearchStoryModalProps extends ModalProps<IPersonStory2|undefined> {
  withChildStoryOnly: boolean;
  excludePersonStoryIdList?: string[];
}

export default (props: SearchStoryModalProps) => {
  const { withChildStoryOnly } = props;
  const [isOpen, resolve] = useModal(props);
  const [childStoryError, setChildStoryError] = useState(false);

  const handleSelected = (story: IPersonStory2) => {
    if (withChildStoryOnly && !story.story_child_option) {
      setChildStoryError(true);
      return;
    }
    resolve(story);
  };

  return (
    <Modal show={isOpen} onHide={() => resolve(undefined)}>
      <ModalHeader>{T('page.personstory.linkTable.add.title')}</ModalHeader>
      <ModalBody>
        <TextView text={T('page.personstory.linkTable.add.message')} />
        <SearchStoryInput
          onSelected={handleSelected}
          excludePersonStoryIdList={props.excludePersonStoryIdList}
        />
        {childStoryError && (
          <Alert variant='danger' style={{ marginTop: '1em' }}>
            {T('page.personstory.linkedStoryMustHaveChildStory')}
          </Alert>
        )}
      </ModalBody>
    </Modal>
  );
}
