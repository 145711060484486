import React, { ReactNode } from 'react';
import { BasePage, PageProps, PageState } from '../Page';
import { IPlace, Place, getPlaceTypeName, PlaceType, ResolutionStatus, getResolutionStatusColor, getResolutionStatusName } from '../../models/Place';
import api from '../../api';
import { getActiveLanguage, T } from '../../Translate';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { Icon } from '../../components/Icons';
import { EditLink } from '../../components/EditLink';
import { MapMarkerInputMap, MapMarkerInputValue } from '../../components/inputs/MapMarkerInput';
import { PageID, getPageVia } from '../../utils/PageLinking';
import { TextStyles } from "../../config/styles";
import { TopTab, TopTabs } from "../../components/TopTabs";
import { Language } from "../../models/PersonStory2";
import ListDocumentsTab from "./ListDocumentsTab";


interface StatusViewProps {
  status: ResolutionStatus;
}
const StatusView = (props: StatusViewProps) => {
  const { status } = props;
  return (
    <div style={{ marginBottom: 0 }}>
      <div>
        <div style={{ ...TextStyles.text.medium }}>
          <i className={Icon.MapMarker} style={{ color: getResolutionStatusColor(status) }} />{' '}
          {getResolutionStatusName(status)}
        </div>
      </div>
    </div>
  );
}

interface InfoLineViewProps {
  label: string
  text?: string
  children?: ReactNode
}
const InfoLineView = (props: InfoLineViewProps) => {
  const { label, text, children } = props;
  return (
    <div style={{display:"flex", flexDirection: 'row', alignItems: 'baseline', marginBottom: '0.5rem'}} >
      <div style={{flex: 1, textAlign:'right', marginRight: 4, ...TextStyles.label.medium}}>{label} :</div>
      { text && <div style={{flex: 2, ...TextStyles.text.medium}}>{text}</div>}
      { children && <div style={{flex: 2, ...TextStyles.text.medium}}>{children}</div>}
    </div>
  );
}


interface ViewPlacePageProps extends PageProps {
  id: string;
}
interface ViewPlacePageState extends PageState {
  place?: IPlace;
  numberOfDocuments: number
}
export default class ViewPlacePage extends BasePage<ViewPlacePageProps, ViewPlacePageState> {
  constructor(props: ViewPlacePageProps) {
    super(props);

    this.state = {
      page: {
        id: PageID.ViewPlace,
        entity: props.id,
        icon: Icon.MapMarker,
        title: '...',
        via: props.via || PageID.ListPlaces
      },
      numberOfDocuments: 0
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    await this.fetchData();

    if (!this.state.place?._id)
      return;

    const count = await api.countDocumentsRelatedTo('place', this.state.place?._id);
    this.setState({...this.state, numberOfDocuments: count})
  }

  async componentDidUpdate(oldProps: ViewPlacePageProps, oldState: ViewPlacePageState) {
    super.componentDidUpdate(oldProps, oldState);

    if (this.props.id !== oldProps.id) {
      await this.fetchData();
    }

    const placeId = this.state.place?._id;
    if (placeId && placeId !== oldState.place?._id) {
      const count = await api.countDocumentsRelatedTo('place', placeId);
      this.setState({...this.state, numberOfDocuments: count})
    }
  }

  async fetchData() {
    const place = await this.loading(api.getPlace(this.props.id, []), T('noun.place'));
    return this.setState({
      place,
      page: {
        id: PageID.ViewPlace,
        entity: this.props.id,
        icon: Icon.MapMarker,
        title: T('page.place.title'),
        via: this.props.via || PageID.ListPlaces
      }
    });
  }

  /** Tabs **/

  renderPlaceMapTab = (placeObject: Place) => {
    return <>
      {placeObject && placeObject.hasLocation() && (
        <MapMarkerInputMap
          containerElement={<div style={{ height: 400 }} />}
          mapElement={<div style={{ height: `100%` }} />}
          position={MapMarkerInputValue.fromValue(placeObject.place && placeObject.place.loc && { lat: placeObject.place.loc.lat, lng: placeObject.place.loc.lon })}
          onChange={() => {}}
          label=''
        />
      )}
    </>;
  }

  renderPlaceDocumentsTab = (placeObject: Place) => {
    return (
      <ListDocumentsTab
        ref_id={placeObject.id}
        ref_name={placeObject.describe(getActiveLanguage())}
        ref_type='place'
        history={this.props.history}
        loading={this.props.loading}
        modals={this.props.modals}
        via={getPageVia(PageID.ViewPlace, placeObject.id)}
        readonly={true}
        pageStateKey='placeDocumentation'
      />
    );
  }

  generateTabs(placeObject: Place | undefined): TopTab[] {
    if (!this.state.place?._id)
      return []

    if (!placeObject)
      return []

    return [
      {
        icon: Icon.ListUl,
        title: T('page.place.tab.map'),
        id: 'map',
        content: () => this.renderPlaceMapTab(placeObject),
        badgeContent: () => '',
      },
      {
        icon: Icon.ListUl,
        title: T('page.place.tab.documents'),
        id: 'documents',
        content: () => this.renderPlaceDocumentsTab(placeObject),
        badgeContent: () => this.state.numberOfDocuments.toString()
      }
    ]
  }


  renderContent() {
    const { place } = this.state;
    const placeObject = place && new Place(place);
    const user = api.user;

    return (
      <>
        <Card style={{ marginTop: 15 }}>
          <Card.Header>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flexGrow: 1, paddingLeft: 15 }}>
                {place && <p style={{...TextStyles.title.capitals, marginBottom: '0.25rem'}}><i className={Icon.MapMarker} style={{marginRight: 8}}/> {placeObject?.describe(getActiveLanguage())}</p>}
              </div>
              <div>
                {place && user.canEdit() && <EditLink page={PageID.EditPlace} param={place._id} />}
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div style={{ marginBottom: '1em' }}>
              <Row>
                <Col sm={4}>
                  <InfoLineView label={T('generic.status')}>
                    <StatusView status={place ? place.resolution_status : ResolutionStatus.Unresolved} />
                  </InfoLineView>
                  <InfoLineView label={T('place.type')} text={getPlaceTypeName(place ? place.type : PlaceType.Unknown)}/>
                  <InfoLineView label={T('place.alternativeNames')} text={place && place.alternative_names.length > 0 ? place.alternative_names.join(', ') : '...'}/>
                </Col>
                <Col sm={8}>
                  <InfoLineView label={T('place.translations')}>
                    <div>
                      {Object.values(Language).map(language =>
                        placeObject && place?.languages[language] && <p style={{...TextStyles.text.medium, marginBottom: '0.5rem'}}><Badge variant={'secondary'} style={{marginRight: 4}}>{`${language}:`}</Badge>{placeObject.describe(language)}</p>
                      )}

                    </div>
                  </InfoLineView>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
        <TopTabs
          id='view-place-tabs'
          defaultActiveKey='map'
          storeKey='viewPlace'
          tabs={this.generateTabs(placeObject)}
        />
      </>
    );
  }
}
