import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  ContactMomentContactPersonEvent,
  ContactMomentContribution,
  ContactPersonEvent,
  ContactPersonEventHelper,
  KeyStoryContactPersonEvent,
  translateContactMomentType
} from '../../models/ContactPerson';
import { Card } from 'react-bootstrap';
import { T } from '../../Translate';
import { ContributionView } from '../RecordContactMoment/ContributionView';
import { Icon } from '../../components/Icons';
import { markdown2html } from '../../utils/Markdown';
import { ButtonLink } from '../../components/ButtonLink';
import { PageID } from '../../utils/PageLinking';
import PageLink from '../../components/PageLink';
import { TextStyles } from "../../config/styles";
import styles from './ContactPersonEventView.module.scss';
import { usePersonStory } from '../../FunctionalAPI';
import { Loading } from '../../utils/Loading';
import { IPersonStory } from '../../models/PersonStory';
import api from '../../api';
import { IPersonStory2 } from '../../models/PersonStory2';

interface ContactPersonEventViewProps {
  event: ContactPersonEvent;
  onClickedDelete?: () => void;
  readonly?: boolean
  compact?: boolean
}


const ContactPersonActionButtonsView = (props: ContactPersonEventViewProps) => {
  const {event, onClickedDelete} = props;

  const handleClickedDelete = () => {
    if (onClickedDelete) {
      onClickedDelete();
    }
  }

  const actionsDiv = () => {
    return (
      <div style={{float: 'right'}}>
        <PageLink page={PageID.EditContactMoment} param={event._id}><i className={Icon.Edit}/></PageLink>
        <ButtonLink onClick={handleClickedDelete} style={{paddingLeft: 5, color: 'rgb(250, 27, 40)'}}>
          <i className={Icon.TimesCircle}/>
        </ButtonLink>
      </div>)
  }

  return (
    props.readonly === false ? actionsDiv() : <></>
  )
}


const ContactPersonTitleView = (props: ContactPersonEventViewProps) => {
  const { event } = props;

  const readonlyTitle = () => {
    const contactPersonName = `${event.personFirstName || ''} ${event.personLastName || ''}`;

    return (
      <h4 className='card-title'>
        <PageLink page={PageID.EditContactPerson} param={event.contactPersonId}>{contactPersonName}</PageLink>
        {event.type === 'contact_moment' && (': ' + T('page.person.contactAt', {
          type: translateContactMomentType(event.contactMethod),
          moment: moment(event.start).format('DD/MM/YYYY')
        }))}
      </h4>)
  }

  const readwriteTitle = () => {
    return (
      <h4 className='card-title'>
        {moment(event.start).format('DD/MM/YYYY')} &middot; {event.type === 'contact_moment' ? translateContactMomentType(event.contactMethod) : T('page.contactPerson.event.keyStory')}
      </h4>)
  }

  return (
    props.readonly === false ? readwriteTitle() : readonlyTitle()
  )
}

interface ContributionListViewProps {
  labelText: string
  contributionsList: ContactMomentContribution[]
}

const ContributionListView = (props: ContributionListViewProps) => {

  const {contributionsList, labelText} = props;

  return (
    <>
      {contributionsList.length > 0 && (
        <div>
          <p style={{...TextStyles.label.medium, marginBottom: '0.5em'}}>{labelText}:</p>
          <div style={{paddingLeft: '1em'}}>
            {contributionsList.map(contribution => (
              <ContributionView
                key={contribution.targetId}
                contribution={contribution}
              />
            ))}
          </div>
        </div>
      )}
  </>)
}

export const ContactPersonEventView = (props: ContactPersonEventViewProps) => {
  const { event, onClickedDelete, readonly, compact } = props;
  const showCompact = compact && compact === true

  return (
    <Card>
      <Card.Header>
        <ContactPersonActionButtonsView event={event} onClickedDelete={onClickedDelete} readonly={readonly || event.type === 'key_story'}/>
        <ContactPersonTitleView event={event} readonly={readonly}></ContactPersonTitleView>
      </Card.Header>
      {!showCompact && (
        <Card.Body>
          {event.type === 'contact_moment' && <ContactMomentCardBody event={event} />}
          {event.type === 'key_story' && <KeyStoryCardBody event={event} />}
        </Card.Body>
      )}
    </Card>
  );
}

interface ContactMomentCardBodyProps {
  event: ContactMomentContactPersonEvent;
}

function ContactMomentCardBody(props: ContactMomentCardBodyProps) {
  const { event } = props;

  const transcription = useMemo(
    () => event.transcript
      ? <p style={{...TextStyles.block.medium}} dangerouslySetInnerHTML={markdown2html(event.transcript)}/>
      : <p style={{color: '#888'}}>{T('page.contactPerson.event.noTranscription')}</p>,
    [event.transcript]
  );

  const personContributions = ContactPersonEventHelper.getContributionsForType(event, 'person')
  const documentContributions = ContactPersonEventHelper.getContributionsForType(event, 'document')

  return <>
    <ContributionListView
      labelText={T('contactPerson.event.contributions.persons')}
      contributionsList={personContributions}
    />
    <div style={{marginBottom: '1rem'}}></div>
    <p style={{
      ...TextStyles.label.medium,
      marginTop: '0.5em',
      marginBottom: '0.5em'
    }}>{T('contactPerson.event.contributions.transcription')}:</p>
    <div className={styles.markdowncontent} style={{ paddingLeft: 14, ...TextStyles.block.medium }}>
      {transcription}
    </div>
    <div style={{marginBottom: '1rem'}}></div>
    <ContributionListView
      labelText={T('contactPerson.event.contributions.documents')}
      contributionsList={documentContributions}
    />
  </>;
}

interface KeyStoryCardBodyProps {
  event: KeyStoryContactPersonEvent;
}

function KeyStoryCardBody(props: KeyStoryCardBodyProps) {
  const { event } = props;

  const [story, setStory] = useState<IPersonStory2>();
  useEffect(() => {
    api.getPersonStory2(event.storyId).then(setStory);
  }, [event.storyId]);

  return <>
    {story && (
      <PageLink page={PageID.EditPersonStory} param={event.storyId}>{story.first_name + ' ' + story.family_name}</PageLink>
    )}
  </>;
}
