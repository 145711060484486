import React, { useMemo, useEffect, useState } from 'react';
import { IQueryResult } from '../../../models/Generic';
import api from '../../../api';
import { plural } from '../../../Translate';
import { Card } from 'react-bootstrap';
import { usePageState } from '../../../redux/Actions';
import { PageProps } from '../../Page';
import { useRefresh } from '../../../utils/Functional';
import { getPageLinkWithVia, PageID } from '../../../utils/PageLinking';
import { SortDirection, Table } from "../../../components/table/Table";
import { useDelayedUpdateEffect } from "../../../utils/UpdateEffects";
import { IPersonStory2 } from "../../../models/PersonStory2";
import { getPersonStoryColumns } from "./Columns";


const PageSize = 10;
const rowKey = (item: IPersonStory2, index: number) => item._id;

interface ListMatchingPersonStoriesProps extends PageProps {
  personStory?: IPersonStory2
  onMatchingItemsChanged?: (numberOfItems: number) => void
}

/**
 *
 */
const ListMatchingPersonStories =  (props: ListMatchingPersonStoriesProps) => {

  /*** state ***/

  const [pageState, updatePageState] = usePageState('personStoryMatches');
  const [items, setItems] = useState<IQueryResult<IPersonStory2>>();
  const [personStory, setPersonStory] = useState<IPersonStory2 | undefined>(props.personStory);

  /*** variables ***/

  const { history, loading } = props;
  const { filter, tablePage, sortColumn, sortDirection } = pageState;

  /*** effects ***/
  useEffect(() => setPersonStory(props.personStory), [props.personStory])

  const refresh = useRefresh(() => {
    if (personStory === undefined || !personStory._id) {
      setItems({ data: [], total: 0 });
      return;
    }
    
    const { filter, sortColumn, sortDirection } = pageState;
    
    loading.loading(
      api.findMatchingPersonStories(
        personStory._id,
        tablePage * PageSize,
        PageSize,
        [[getSortColumn(sortColumn), sortDirection]],
        filter
      ), plural('memorial')
    ).then(data => {
      if (props.onMatchingItemsChanged) {
        props.onMatchingItemsChanged(data.total)
      }
      setItems(data);
    })});

  useEffect(refresh, [tablePage, sortColumn, sortDirection, personStory?._id]);

  useDelayedUpdateEffect(() => {
    refresh();
    updatePageState({tablePage: 0})},
    500,
    [filter])

  // Dit lijkt me niet nodig en vernieuwt onnodig de pagina. Storend!
  //  useEffect(refresh, [personStory]);


  /*** table handlers ***/

  const handlePageChanged = (page: number) => {
    updatePageState({ tablePage: page });
  };

  const handleSortChanged = (column: string, direction: SortDirection) => {
    updatePageState({ sortColumn: column, sortDirection: direction });
  };

  const handleClickedRow = (item: IPersonStory2) => {
    history.push(getPageLinkWithVia(PageID.EditPersonStory, item._id, PageID.EditPersonStory, props.personStory?._id));
  }

  /*** table helpers ***/

  const columns = useMemo(() => getPersonStoryColumns(false, () => {}), []);

  const getSortColumn = (column: string) => {
    switch (column) {
      default:
        return 'filter';
      case 'birthDate':
        return 'sort_born_date';
      case 'diedDate':
        return 'sort_died_date';
    }
  }

  /*** rendering ***/


  return (
    <Card.Body className='table-full-width'>
      <Table
        displayedItems={items?.data || []}
        columns={columns}
        rowKey={rowKey}
        onClickItem={handleClickedRow}
        onPageChanged={handlePageChanged}
        onSortChanged={handleSortChanged}
        sortDirection={sortDirection}
        sortColumn={sortColumn}
        page={tablePage}
        pageSize={PageSize}
        totalItems={items?.total ?? 0}
        noun='person'
      />
    </Card.Body>
  );
}

export default ListMatchingPersonStories;
