import React from 'react';
import styles from './ResizableSplitPane.module.scss';
import { connect } from 'react-redux';
import { setDocumentEditorRatio, AnyAppAction } from '../../redux/Actions';
import { Dispatch } from 'redux';
import { AppState } from '../../redux/State';

interface ResizableLeftProps {
  setRef: (element: HTMLDivElement) => void;
  width: number|string;
  children: JSX.Element;
}
const ResizableLeft = (props: ResizableLeftProps) => (
  <div ref={props.setRef} style={{width: props.width}}>
    {props.children}
  </div>
);

interface ResizableDividerProps {
  setRef: (element: HTMLDivElement) => void;
  onPointerDown: (e: React.PointerEvent<HTMLElement>) => void;
  onPointerUp: (e: React.PointerEvent<HTMLElement>) => void;
  onPointerMove: (e: React.PointerEvent<HTMLElement>) => void;
}
const ResizableDivider = (props: ResizableDividerProps) => (
  <div
    className={styles.dividerWrapper}
    ref={props.setRef}
    onPointerDown={props.onPointerDown}
    onPointerUp={props.onPointerUp}
    onPointerMove={props.onPointerMove}
  >
    <div className={styles.divider} />
  </div>
);

interface ResizableRightProps {
  children: JSX.Element;
}
const ResizableRight = (props: ResizableRightProps) => (
  <div style={{ flexGrow: 1, flexShrink: 1, overflow: 'auto' }}>
    {props.children}
  </div>
);

interface ResizableComponentProps {
  ratio: number;
  left: JSX.Element;
  right: JSX.Element;
  setRatio: (ratio: number) => void;
}
interface ResizableComponentState {
  dragging: boolean;
  draggingStartWidth: number;
  draggingStartX: number;
  draggingWidth: number;
}
class ResizableComponent extends React.Component<ResizableComponentProps, ResizableComponentState> {
  parentRef: HTMLElement|null;
  dividerRef: HTMLElement|null;
  leftRef: HTMLElement|null;

  constructor(props: ResizableComponentProps) {
    super(props);

    this.state = {
      dragging: false,
      draggingStartWidth: 0,
      draggingStartX: 0,
      draggingWidth: 0
    };

    this.parentRef = null;
    this.dividerRef = null;
    this.leftRef = null;
  }

  setParentRef = (element: HTMLElement|null) => {
    this.parentRef = element;
  }

  setDividerRef = (element: HTMLElement|null) => {
    this.dividerRef = element;
  }

  setLeftRef = (element: HTMLElement|null) => {
    this.leftRef = element;
  }

  handlePointerDown = (e: React.PointerEvent<HTMLElement>) => {
    if (this.dividerRef === null || this.leftRef === null)
      return;
    
    this.dividerRef.setPointerCapture(e.pointerId);

    this.setState({
      dragging: true,
      draggingStartWidth: this.leftRef.clientWidth,
      draggingStartX: e.clientX,
      draggingWidth: this.leftRef.clientWidth
    });
  }

  handlePointerMove = (e: React.PointerEvent<HTMLElement>) => {
    if (!this.state.dragging)
      return;

    this.setState({
      draggingWidth: this.state.draggingStartWidth + e.clientX - this.state.draggingStartX
    });
  }

  handlePointerUp = (e: React.PointerEvent<HTMLElement>) => {
    if (this.dividerRef === null || this.parentRef === null)
      return;
    
    this.dividerRef.releasePointerCapture(e.pointerId);
    let ratio = this.state.draggingWidth / this.parentRef.clientWidth;
    this.props.setRatio(ratio);
    localStorage.setItem('documentEditorRatio', ratio.toString());

    this.setState({
      dragging: false
    });
  }

  render() {
    let width = this.props.ratio * 100 + '%';
    return (
      <div className={styles.splitpane} ref={this.setParentRef}>
        <ResizableLeft
          setRef={this.setLeftRef}
          width={this.state.dragging ? this.state.draggingWidth : width}
        >
          {this.props.left}
        </ResizableLeft>
        <ResizableDivider
          setRef={this.setDividerRef}
          onPointerDown={this.handlePointerDown}
          onPointerMove={this.handlePointerMove}
          onPointerUp={this.handlePointerUp}
        />
        <ResizableRight>{this.props.right}</ResizableRight>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  ratio: state.documentEditorSettings.ratio
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAppAction>) => ({
  setRatio: (ratio: number) => dispatch(setDocumentEditorRatio(ratio))
});

export const ResizableSplitPane = connect(mapStateToProps, mapDispatchToProps)(ResizableComponent);
