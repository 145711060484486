import React, { ReactNode, CSSProperties } from 'react';

interface ButtonLinkProps {
  className?: string;
  onClick?: () => void;
  title?: string;
  style?: CSSProperties;
  children: ReactNode;
}
export const ButtonLink = (props: ButtonLinkProps) => {
  const { className, onClick, title, style, children } = props;
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick && onClick();
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href='#' className={className} onClick={handleClick} title={title} style={style}>
      {children}
    </a>
  );
}
