import React, { useMemo } from 'react';
import { PersonEventType, getEventIcon, getEventTypeName } from '../../../models/PersonEvent';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { T } from '../../../Translate';

const PersonEventTypes: PersonEventType[] = [
  PersonEventType.School,
  PersonEventType.Work,
  PersonEventType.Where,
  PersonEventType.Military,
  PersonEventType.Jailed,
  PersonEventType.Injured,
  PersonEventType.Hospitalized,
  PersonEventType.Memorated,
  PersonEventType.Decorated,
  PersonEventType.Extra
];

interface AddEventControlProps {
  onClickedAdd: (type: PersonEventType) => void;
}

const AddEventControl = (props: AddEventControlProps) => {
  const { onClickedAdd } = props;

  const items = useMemo(() => PersonEventTypes.map(type => (
    <Dropdown.Item as='button' key={type} onClick={() => onClickedAdd(type)}>
      <i className={getEventIcon(type)} />
      {' '}
      {getEventTypeName(type)}
    </Dropdown.Item>
  )), [onClickedAdd]);
  return (
    <DropdownButton as={ButtonGroup} title={T('page.person.addEvent')} id='add-event-control'>
      {items}
    </DropdownButton>
  );
}

export default AddEventControl;
