import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../Icons';

interface NavItemProps {
  icon: Icon;
  title: string;
  to: string;
}
export const NavItem = (props: NavItemProps) => {
  const { icon, title, to } = props;
  return (
    <li className='nav-item'>
      <Link to={to} className='nav-link main-nav-link'>
        <i className={icon} />
        <p>{title}</p>
      </Link>
    </li>
  );
}
