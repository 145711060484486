import {T, TranslationKey} from "../Translate";

export interface IValidationResult {
  status: 'OK'|'NOK';
  data?: {[key: string]: ValidationError};
  extra?: {}
}

export function isValidationResult(obj: any): obj is IValidationResult {
  return typeof obj.status === "string" && typeof obj.extra === "object" && typeof obj.data === "object";
}


export enum ValidationError {
  Ok = 'OK',
  InvalidFormat = 'INVALID_FORMAT',
  InvalidLength = 'INVALID_FORMAT_LENGTH',
  SpacesNotAllowed = "INVALID_FORMAT_SPACE_NOT_ALLOWED",
  TooMany = "INVALID_TOO_MANY",

  DataAlreadyExists = "INVALID_DATA_ALREADY_EXIST",
  ConnectionAlreadyExists = "INVALID_CONNECTION_ALREADY_EXISTS",
  DataRequired = "INVALID_DATA_REQUIRED",
  GoogleNotFound = "INVALID_DATA_GOOGLE_NOT_FOUND",
  GoogleTooManyResults = "INVALID_DATA_GOOGLE_TOO_MUCH",

  UsernameAlreadyExists = "INVALID_DATA_USERNAME_ALREADY_EXISTS",
  EmailAlreadyExists = "INVALID_DATA_EMAIL_ALREADY_EXISTS",

  AtLeastOne = "INVALID_AT_LEAST_ONE",

  InvalidOption = 'INVALID_OPTION',
  InvalidDateOrder = 'INVALID_DATE_ORDER',
  EventsOverlap = 'EVENT_OVERLAP',
  Required = 'REQUIRED',

  DoesNotExist = 'DOES_NOT_EXIST',
  InvalidReference = 'INVALID_REFERENCE',
  InvalidData = 'INVALID_DATA',
  AlreadyExists = 'ALREADY_EXISTS',

  ShouldBeEmpty = 'SHOULD_BE_EMPTY',
  ChildrenShouldBeEmpty = 'CHILDREN_SHOULD_BE_EMPTY',
  DocumentReferenceExist = 'DOCUMENT_REFERENCES_EXIST',
  EventReferenceExist = 'EVENT_REFERENCES_EXIST',
  ContactReferencesExist = 'CONTACT_REFERENCES_EXIST',
  ContactPersonReferencesExist = 'CONTACT_PERSON_REFERENCES_EXIST',
  MemorialReferencesExist = 'MEMORIAL_REFERENCES_EXIST',
  RelationsExist = 'RELATIONS_EXIST',
  PersonStoriesExist = 'PERSONSTORY_REFERENCES_EXIST',

  InvalidStatusOption = 'INVALID_STATUS_OPTION',
  InvalidPublishStatusOption = 'INVALID_PUBLISH_STATUS_OPTION',
  PersonAlreadyHasPersonStory = 'PERSON_ALREADY_HAS_STORY',
  PersonStoryCannotBeDeletedIsPublished = 'PERSONSTORY_CANNOT_BE_DELETED_IS_PUBLISHED',
  PersonStoryCannotBeDeletedIsReferenced = 'PERSONSTORY_CANNOT_BE_DELETED_IS_REFERENCED',
  KeyLocationCannotBeDeletedIsReferenced = 'KEY_LOCATION_CANNOT_BE_DELETED_IS_REFERENCED',

  InvalidBritishTrenchCoordinates = 'INVALID_BRITISH_TRENCH_COORDINATES',

  InvalidYear = 'INVALID_YEAR',
  InvalidYear2000 = 'INVALID_YEAR_2000',
  InvalidPosition = 'INVALID_POSITION'
}

const ErrorNames: [ValidationError, TranslationKey][] = [
  [ValidationError.Ok, 'generic.ok'],
  [ValidationError.InvalidFormat, 'validationError.invalidFormat'],
  [ValidationError.InvalidData, 'validationError.invalidData'],
  [ValidationError.InvalidLength, 'validationError.invalidLength'],
  [ValidationError.SpacesNotAllowed, 'validationError.spacesNotAllowed'],
  [ValidationError.TooMany, 'validationError.tooMany'],
  [ValidationError.DataAlreadyExists, 'validationError.dataAlreadyExists'],
  [ValidationError.ConnectionAlreadyExists, 'validationError.ConnectionAlreadyExists'],
  [ValidationError.DataRequired, 'validationError.dataRequired'],
  [ValidationError.GoogleNotFound, 'validationError.googleNotFound'],
  [ValidationError.GoogleTooManyResults, 'validationError.googleTooManyResults'],
  [ValidationError.InvalidOption, 'validationError.invalidOption'],
  [ValidationError.InvalidDateOrder, 'validationError.invalidDateOrder'],
  [ValidationError.EventsOverlap, 'validationError.eventsOverlap'],
  [ValidationError.Required, 'validationError.required'],
  [ValidationError.DoesNotExist, 'validationError.doesNotExist'],
  [ValidationError.InvalidReference, 'validationError.invalidReference'],
  [ValidationError.UsernameAlreadyExists, 'validationError.usernameAlreadyExists'],
  [ValidationError.EmailAlreadyExists, 'validationError.emailAlreadyExists'],
  [ValidationError.AtLeastOne, 'validationError.atLeastOne'],
  [ValidationError.ShouldBeEmpty, 'validationError.shouldBeEmpty'],
  [ValidationError.ChildrenShouldBeEmpty, 'validationError.ChildrenShouldBeEmpty'],
  [ValidationError.DocumentReferenceExist, 'validationError.DocumentReferenceExist'],
  [ValidationError.EventReferenceExist, 'validationError.EventReferenceExist'],
  [ValidationError.ContactReferencesExist, 'validationError.ContactReferencesExist'],
  [ValidationError.ContactPersonReferencesExist, 'validationError.ContactPersonReferencesExist'],
  [ValidationError.MemorialReferencesExist, 'validationError.MemorialReferencesExist'],
  [ValidationError.RelationsExist, 'validationError.RelationsExist'],
  [ValidationError.PersonStoriesExist, 'validationError.PersonStoriesExist'],
  [ValidationError.AlreadyExists, 'validationError.militaryEntityAlreadyExists'],
  [ValidationError.InvalidStatusOption, 'validationError.InvalidStatusOption'],
  [ValidationError.InvalidPublishStatusOption, 'validationError.InvalidPublishStatusOption'],
  [ValidationError.PersonAlreadyHasPersonStory, 'validationError.PersonAlreadyHasPersonStory'],
  [ValidationError.PersonStoryCannotBeDeletedIsPublished, 'validationError.PersonStoryCannotBeDeletedIsPublished'],
  [ValidationError.PersonStoryCannotBeDeletedIsReferenced, 'validationError.PersonStoryCannotBeDeletedIsReferenced'],
  [ValidationError.KeyLocationCannotBeDeletedIsReferenced, 'validationError.KeyLocationCannotBeDeletedIsReferenced'],
  [ValidationError.InvalidBritishTrenchCoordinates, 'memorial.britishTrenchCoordinates.invalid'],
  [ValidationError.InvalidYear, 'validationError.invalidYear'],
  [ValidationError.InvalidYear2000, 'validationError.invalidYear2000']
]

export function getErrorName(error: ValidationError) {
  return T((
    ErrorNames.find(x => x[0] === error)
    || [ValidationError.Ok, "validationError.unknown"]
  )[1]);
}

export function getErrorDescription(error?: ValidationError, overrides?: [ValidationError, string][]): string|undefined {
  if (error === undefined || error === ValidationError.Ok)
    return undefined;

  const override = (overrides || []).find(x => x[0] === error);
  return override ? override[1] : getErrorName(error)
}
