import React from 'react';
import styles from './StoryView.module.scss';
import poppy from '../../assets/images/poppy.png';
import { Col, Row } from 'react-bootstrap';
import { getActiveLanguage, T } from '../../Translate';
import { IPublishedPersonStory } from '../../models/PersonStory2';
import ImageCarrousel from './ImageCarrousel';

interface StoryViewProps {
  story: IPublishedPersonStory;
  child: boolean;
}

export const StoryView = (props: StoryViewProps) => {
  const { story, child } = props;

  const language = getActiveLanguage();
  const storyText = (story.story_text || {})[language];
  const storyPart1 = child ? storyText?.story_child_part_1 : storyText?.story_part_1;
  const storyPart2 = child ? storyText?.story_child_part_2 : storyText?.story_part_2;

  return (
    <div className={styles.story}>
      <div className='container'>
        <div className={styles.storyWrapper}>
          <div className={styles.storyLine} />
          <div className={styles.storyIconWrapper}>
            <div className={styles.storyIcon}>
              <img src={poppy} alt='' />
            </div>
          </div>
          <div className={styles.storyContent}>
            {story.profile_image_url && (
              <div className={styles.storyPersonPhoto}>
                <img src={story.profile_image_url} alt='' />
                <div className={styles.fadeLeft} />
                <div className={styles.fadeRight} />
                <div className={styles.fadeTop} />
                <div className={styles.fadeBottom} />
              </div>
            )}
            <Row style={{padding: "1em", marginTop: story.profile_image_url ? "-2em" : undefined }}>
              <Col sm={2} style={{ fontWeight: 'bold' }}>{T('page.personstory.theStory')}</Col>
              <Col sm={6}>
                <p>{storyPart1}</p>
                <p>{storyPart2}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={2}/>
              <Col sm={6}>
                {story.extra_images.length > 0 && (
                  <ImageCarrousel images={story.extra_images} />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}
