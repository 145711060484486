import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { PageProps, Page } from '../Page';
import { Icon } from '../../components/Icons';
import { FormCard } from '../../components/FormCard';
import { Button } from 'react-bootstrap';
import {
  MilitaryEntityType,
  getMilitaryEntityTypeName,
  Armies,
  MilitaryEntity,
  IMilitaryEntityFields,
  IMilitaryEntityStructureEvent,
  MilitaryEntityEvent,
  IMilitaryEntityTrackEvent,
  IMilitaryEntity
} from '../../models/MilitaryEntity';
import api from '../../api';
import { TopTab, TopTabs } from '../../components/TopTabs';
import { T } from '../../Translate';
import { getErrorDescription } from '../../models/ValidationResult';
import styles from './index.module.scss';
import { EditStructureEventModal } from './EditStructureEventModal';
import { EditTrackEventModal } from './EditTrackEventModal';
import { BottomButtonRow } from '../../components/ButtonRow';
import { NotificationManager } from 'react-notifications';
import { useModalContext } from '../../modals/ModalContext';
import { useMilitaryEntity, useNumberOfRelatedDocuments } from '../../FunctionalAPI';
import { MilitaryEntityFormState, MilitaryEntityForm } from './MilitaryEntityForm';
import { MilitaryEventEditor } from './MilitaryEventEditor';
import { PageID, getPageVia, getPageLink } from '../../utils/PageLinking';
import ListDocumentsTab from "../ViewPlace/ListDocumentsTab";

interface EditMilitaryEntityPageProps extends PageProps {
  id?: string;
}

const EmptyFormState: MilitaryEntityFormState = {
  type: MilitaryEntityType.Unit,
  regiment: '',
  regimentError: undefined,
  unitNr: '',
  unitNrError: undefined,
  unit: '',
  unitError: undefined,
  brigade: '',
  brigadeError: undefined,
  division: '',
  divisionError: undefined,
  army: Armies[0],
  armyError: undefined
};

function getFormStateFromMilitaryEntity(entity: IMilitaryEntity) {
  return {
    type: entity.type,
    regiment: entity.regiment || '',
    regimentError: undefined,
    unitNr: entity.unit_nr || '',
    unitNrError: undefined,
    unit: entity.unit || '',
    unitError: undefined,
    brigade: entity.brigade || '',
    brigadeError: undefined,
    division: entity.division || '',
    divisionError: undefined,
    army: entity.army || Armies[0],
    armyError: undefined
  };
}

const LoadOptions: ('history.place'|'history.entity')[] = ['history.place', 'history.entity'];

export default (props: EditMilitaryEntityPageProps) => {
  const { id, loading, via, history } = props;

  const modals = useModalContext();

  const entity = useMilitaryEntity(loading, id, LoadOptions);
  const [formState, setFormState] = useState<MilitaryEntityFormState>(EmptyFormState);
  const [events, setEvents] = useState<MilitaryEntityEvent[]>([]);

  const numberOfDocuments= useNumberOfRelatedDocuments('military_entity', entity?._id )


  const eventViews = useMemo(() => {
    return events.map((_, index) => (
      <MilitaryEventEditor
        key={index}
        militaryEntityId={id}
        index={index}
        events={events}
        setEvents={setEvents}
      />
    ));
  }, [events, id]);

  const updateFormState = useCallback((updates: Partial<MilitaryEntityFormState>) => {
    setFormState(formState => Object.assign({}, formState, updates));
  }, []);

  const clear = useCallback(() => {
    updateFormState(EmptyFormState);
  }, [updateFormState]); // satisfying linter here

  useEffect(() => {
    if (entity) {
      updateFormState(getFormStateFromMilitaryEntity(entity));
      setEvents(entity.history || []);
    } else {
      clear();
    }
  }, [entity, clear, updateFormState]);

  const addEvent = async (event: MilitaryEntityEvent) => {
    const newEvents = [...events, event];
    if (id) {
      const entity = await api.updateMilitaryEntity({
        _id: id,
        history: newEvents
      });
      NotificationManager.success(T('page.militaryEntity.event.created'));
      setEvents(entity.history || []);
    } else {
      setEvents(newEvents);
    }
  };

  const renderInfoTab = () => {
    return <>
      <FormCard icon={Icon.User} title={T('page.militaryEntity.tab.identity')} style={{ marginTop: '1em' }}>
        <MilitaryEntityForm formState={formState} setFormState={setFormState} />
      </FormCard>
      <BottomButtonRow>
        <Button variant='primary' onClick={handleClickedSave}>
          {T('generic.action.save')}
        </Button>
        <Button variant='primary' onClick={handleClickedSaveAndClear}>
          {T('generic.action.saveAndNew')}
        </Button>
      </BottomButtonRow>
    </>;
  };

  const renderEventsTab = () => {
    return (
      <div style={{ paddingTop: 15 }}>
        <div style={{ marginBottom: 15 }}>
          <span className={styles.addEventTitle}>
            {T('page.militaryEntity.event.add')}:
          </span>
          <Button variant='outline-light' style={{ marginRight: 10 }} onClick={handleClickedAddStructureEvent}>
            <i className={Icon.Sitemap} /> {T('militaryEntityEventType.main.structure')}
          </Button>
          <Button variant='outline-light'  style={{ marginRight: 10 }} onClick={handleClickedAddRouteEvent}>
            <i className={Icon.Route} /> {T('militaryEntityEventType.main.track')}
          </Button>
        </div>
        {eventViews}
      </div>
    );
  };

  const renderDocumentsTab = () => {
    if (!entity)
      return (<></>)
    else {
      return (
        <ListDocumentsTab
          ref_id={new MilitaryEntity(entity).id || ''}
          ref_name={new MilitaryEntity(entity).name || ''}
          ref_type='military_entity'
          history={props.history}
          loading={loading}
          modals={modals}
          via={getPageVia(PageID.EditMilitaryEntity, entity._id)}
          readonly={false}
          pageStateKey='editMilitaryDocumentation'
        />
      );
    }
  };

  const mytabs: TopTab[] = [
    {
      id: 'info',
      icon: Icon.Info,
      title: T('page.militaryEntity.event.entity'),
      badgeContent: () => '',
      content: renderInfoTab
    },
    {
      id: 'events',
      icon: Icon.ListUl,
      title: T('page.militaryEntity.tab.events'),
      badgeContent: () => events.length.toString(),
      content: renderEventsTab
    },
    {
      id: 'documents',
      icon: Icon.ListUl,
      title: T('page.place.tab.documents'),
      badgeContent: () => numberOfDocuments.toString(),
      content: renderDocumentsTab
    }
  ];

  const handleClickedAddStructureEvent = async () => {
    const event = await modals.show<IMilitaryEntityStructureEvent|null>(props => (
      <EditStructureEventModal militaryEntityId={id} {...props} />
    ));
    if (event)
      addEvent(event);
  };

  const handleClickedAddRouteEvent = async () => {
    if (!id)
      return;

    const event = await modals.show<IMilitaryEntityTrackEvent|null>(props => (
      <EditTrackEventModal militaryEntityId={id} {...props} />
    ));
    if (event)
      addEvent(event);
  };

  const handleClickedSave = async (): Promise<boolean> => {
    const {
      type,
      regiment,
      unitNr,
      unit,
      brigade,
      division,
      army
    } = formState;

    const fields: IMilitaryEntityFields & { _id?: string } = {
      _id: id,
      type,
      army
    };
    switch (type) {
      case MilitaryEntityType.Brigade:
        fields.brigade = brigade;
        break;
      case MilitaryEntityType.Division:
        fields.division = division;
        break;
      case MilitaryEntityType.Unit:
        fields.unit_nr = unitNr;
        fields.unit = unit;
        fields.regiment = regiment;
        break;
      case MilitaryEntityType.Regiment:
        fields.regiment = regiment;
        break;
    }
    const result = await api.validateMilitaryEntity(fields, id ? 'update' : 'create');
    const errors = result.data || {};
    updateFormState({
      regimentError: getErrorDescription(errors.regiment),
      unitError: getErrorDescription(errors.unit),
      unitNrError: getErrorDescription(errors.unit_nr),
      brigadeError: getErrorDescription(errors.brigade),
      divisionError: getErrorDescription(errors.division),
      armyError: getErrorDescription(errors.army)
    });

    if (result.status === 'OK') {
      if (id) {
        await api.updateMilitaryEntity(Object.assign({ _id: id }, fields));
        NotificationManager.success(T('page.militaryEntity.updated'));
      } else {
        fields.history = events;
        const entity = await api.createMilitaryEntity(fields);
        NotificationManager.success(T('page.militaryEntity.created'));
        history.push(getPageLink(PageID.EditMilitaryEntity, entity._id));
      }
      return true;
    }

    return false;
  };

  const handleClickedSaveAndClear = () => {
    handleClickedSave().then(success => {
      if (!success)
        return;

      if (id === undefined) {
        clear();
      } else {
        history.push('/military_entities/create');
      }
    });
  };

  const entityTitle = entity
    ? getMilitaryEntityTypeName(entity.type) + ': ' + new MilitaryEntity(entity).name
    : '...';
  const title = id ? entityTitle : T('page.militaryEntity.title.create');

  return (
    <Page
      id={id ? PageID.EditMilitaryEntity : PageID.CreateMilitaryEntity}
      entity={id}
      icon={Icon.Sitemap}
      title={title}
      shortTitle={T('generic.breadcrumb.edit')}
      via={via || getPageVia(PageID.ViewMilitaryEntity, id)}
      viaTitle={via ? undefined : entityTitle}
    >
      <TopTabs
        id='edit-militaryentity-tabs'
        defaultActiveKey='info'
        tabs={mytabs}
        storeKey='editMilitary'
      />
    </Page>
  );
}
