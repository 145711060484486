import { T } from "../../Translate";
import { IPersonStory2 } from "../PersonStory2";

export class PersonStoryHelper {

  static personName = (personStory: IPersonStory2 | undefined): string => {
    if (!personStory) {
      return '...'
    }
    let result: string = ''
    if (personStory.first_name.length > 0)
      result = result + personStory.first_name + ' '
    if (personStory.family_name.length > 0)
      result = result + personStory.family_name
    return result.length > 0 ? result : T('personstories.messages.noNamesSet')
  }
}
