import React, { useMemo } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { T, singular } from '../../Translate';
import API from '../../api';
import { DocumentCollection } from '../../models/Collection';
import { PageProps, Page } from '../Page';
import { CollectionEditor } from '../EditCollection/CollectionEditor';
import { Icon } from '../../components/Icons';
import { PageID } from '../../utils/PageLinking';

export default (props: PageProps) => {
  const { history, loading, via } = props;

  const collection = useMemo(() => DocumentCollection.empty(), []);

  const handleSave = async (collection: DocumentCollection) => {
    const saved = await loading.saving(API.createCollection(collection), singular('collection'));
    history.push('/collections/' + saved.id);
  };
  const handleCancel = () => history.push('/collections');

  return (
    <Page
      id={PageID.CreateCollection}
      icon={Icon.Box}
      title={T('page.collection.create.title')}
      via={via || PageID.ListCollections}
    >
      <Row className="justify-content-md-center">
        <Col sm={8}>
          <Card style={{ marginTop: '1em' }}>
            <Card.Body>
              <Card.Title>{T('page.collection.create.title')}</Card.Title>
              <Card.Text as="div">
                <CollectionEditor
                  collection={collection}
                  onSave={handleSave}
                  onCancel={handleCancel}
                  history={history}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
}
