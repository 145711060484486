import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { getActiveLanguage } from '../../Translate';
import { DocumentReference, DocumentReferenceType } from '../../models/DocumentReference';
import { ReferenceInput } from '../../components/inputs/ReferenceTypeaheadInput';
import { DocumentTagFieldType, DocumentTagField } from '../../models/DocumentTagDefinition';
import { DocumentTagFieldValue } from '../../models/DocumentTag';
import { Icon } from '../../components/Icons';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { TextInput } from '../../components/inputs/TextInput';
import { ButtonLink } from '../../components/ButtonLink';
import PageLink from '../../components/PageLink';

interface ReferenceInputProps {
  name: string;
  label: string;
  type: DocumentReferenceType;
  value: DocumentReference|null;
  onChange: (value: DocumentReference|null) => void;
  readOnly?: boolean;
  labelColumns?: number;
}
export const ReferenceInputField = (props: ReferenceInputProps) => {
  const { label, type, value, onChange, readOnly, labelColumns = 3 } = props;
  const handleSelected = (item: DocumentReference) =>  onChange(item);
  const handleClickedRemove = () => onChange(null);

  const item = value || DocumentReference.empty();
  const link = item.getLink();
  return (
    <Form.Group as={Row}>
      <label className={`control-label col-sm-${labelColumns}`}>
        {label}:
      </label>
      <Col sm={12 - labelColumns}>
        {!item.isEmpty() ? (
          <span style={{ padding: '0.5em', display: 'inline-block' }}>
            <i className={item.getIcon()} style={{ paddingRight: '0.5em' }} />
            <PageLink page={link.page} param={link.param} className='link' target='_blank'>{item.name}</PageLink>
            {!readOnly && (
              <ButtonLink onClick={handleClickedRemove}>
                <i className={Icon.TimesCircle} style={{ paddingLeft: '0.5em' }} />
              </ButtonLink>
            )}
          </span>
        ) : (
          !readOnly && <ReferenceInput type={type} onSelected={handleSelected} />
        )}
      </Col>
    </Form.Group>
  );
}

interface TagFieldProps {
  tag: string;
  field: DocumentTagField;
  value: DocumentTagFieldValue|null;
  readOnly: boolean;
  onChange: (value: DocumentTagFieldValue) => void;
}
export const TagField = (props: TagFieldProps) => {
  const { field, value, onChange, readOnly } = props;
  const label = field.translations[getActiveLanguage()];

  switch (field.type) {
    case DocumentTagFieldType.String:
      return (
        <TextInput
          name={field.key}
          label={label}
          labelColumns={3}
          value={value as string}
          onChange={onChange}
          readOnly={readOnly}
        />
      );

    case DocumentTagFieldType.Text:
      return (
        <TextareaInput
          name={field.key}
          label={label}
          labelColumns={3}
          value={value as string}
          onChange={onChange}
          readOnly={readOnly}
        />
      );

    case DocumentTagFieldType.Person:
      return (
        <ReferenceInputField
          name={field.key}
          value={value as DocumentReference}
          label={label}
          type={DocumentReferenceType.Person}
          onChange={onChange}
          readOnly={readOnly}
        />
      );

    case DocumentTagFieldType.Place:
      return (
        <ReferenceInputField
          name={field.key}
          value={value as DocumentReference}
          label={label}
          type={DocumentReferenceType.Place}
          onChange={onChange}
          readOnly={readOnly}
        />
      );

    case DocumentTagFieldType.MilitaryEntity:
      return (
        <ReferenceInputField
          name={field.key}
          value={value as DocumentReference}
          label={label}
          type={DocumentReferenceType.MilityEntity}
          onChange={onChange}
          readOnly={readOnly}
        />
      );

    default:
      return <div />;
  }
};
