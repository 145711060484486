import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { T } from '../../Translate';
import API from '../../api';
import { DocumentCollection, DocumentCollectionType, DocumentCollectionStatus } from '../../models/Collection';
import { ConfirmationModal } from '../../modals/Confirmation';
import { History } from 'history';
import { SelectInput } from '../../components/inputs/SelectInput';
import { TextInput } from '../../components/inputs/TextInput';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { BottomButtonRow } from '../../components/ButtonRow';
import { DocumentReferenceType, DocumentReference, DocumentReferenceRelation } from '../../models/DocumentReference';
import { IPerson } from '../../models/Person';
import api from '../../api';
import { ReferenceInputField } from '../EditCollectionDocument/TagField';
import { useModalContext } from '../../modals/ModalContext';

function getPersonReference(person: IPerson): DocumentReference {
  return new DocumentReference(
    person._id,
    DocumentReferenceType.Person,
    person.surname + ' ' + person.familyname,
    DocumentReferenceRelation.None,
    ''
  );
}

interface CollectionEditorProps {
  collection: DocumentCollection;
  history: History;
  onSave: (collection: DocumentCollection) => void;
  onCancel: () => void;
}
export const CollectionEditor = (props: CollectionEditorProps) => {
  const { collection, history, onSave, onCancel } = props;
  const modals = useModalContext();

  const [type, setType] = useState(DocumentCollectionType.Box);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState(DocumentCollectionStatus.Todo);
  const [adlibId, setAdlibId] = useState('');
  const [adlibCode, setAdlibCode] = useState('');
  const [person, setPerson] = useState<DocumentReference|null>(null);

  useEffect(() => {
    setType(collection.type);
    setTitle(collection.title);
    setDescription(collection.description);
    setStatus(collection.status);
    setAdlibId(collection.adlibId);
    setAdlibCode(collection.adlibCode);
    setPerson(collection.person ? getPersonReference(collection.person) : null);
  }, [collection]);

  const handleTypeChanged = (v: string) => setType(v as DocumentCollectionType);
  const handleStatusChanged = (v: string) => setStatus(v as DocumentCollectionStatus);

  const handleClickedSave = async () => {
    const personObject = person && person.id ? await api.getPerson(person.id) : undefined;
    collection.type = type;
    collection.title = title;
    collection.description = description;
    collection.status = status;
    collection.adlibId = adlibId;
    collection.adlibCode = adlibCode;
    collection.person = personObject || null;
    onSave(collection);
  };

  const handleClickedRemove = async () => {
    const result = await modals.show(props => (
      <ConfirmationModal
        title={T('collection.remove.title')}
        message={T('collection.remove.message')}
        acceptStyle='danger'
        acceptText={T('generic.action.remove')}
        rejectText={T('generic.action.cancel')}
        {...props}
      />
    ));
    if (!result)
      return;

    await API.removeCollection(collection.id || '');
    history.push('/collections');
  };

  const handlePersonSelected = (value: DocumentReference|null) => {
    setPerson(value);
  };

  const handleClickedCancel = () => onCancel();

  const hasChanged =
    collection.type !== type
      || collection.title !== title
      || collection.description !== description
      || collection.status !== status
      || collection.adlibId !== adlibId
      || collection.adlibCode !== adlibCode
      || (collection.person && collection.person._id) !== (person ? person.id : undefined);

  return (
    <Form>
      <SelectInput
        name='type'
        label={T('collection.type')}
        labelColumns={2}
        value={type}
        onChange={handleTypeChanged}
        options={[ { value: 'box', title: T('collection.type.box') } ]}
      />
      <TextInput
        name='title'
        label={T('collection.title')}
        labelColumns={2}
        value={title}
        onChange={setTitle}
      />
      <TextareaInput
        name='description'
        label={T('collection.description')}
        labelColumns={2}
        value={description}
        onChange={setDescription}
        rows={3}
      />
      <SelectInput
        name='status'
        label={T('collection.status')}
        labelColumns={2}
        value={status}
        onChange={handleStatusChanged}
        options={[
          { value: DocumentCollectionStatus.Todo, title: T('collection.status.todo') },
          { value: DocumentCollectionStatus.InProgress, title: T('collection.status.in_progress') },
          { value: DocumentCollectionStatus.Done, title: T('collection.status.done') }
        ]}
      />
      <ReferenceInputField
        name='person'
        label={T('collection.person')}
        labelColumns={2}
        type={DocumentReferenceType.Person}
        onChange={handlePersonSelected}
        value={person}
      />
      <TextInput
        name='adlib-id'
        label={T('collection.adlibId')}
        labelColumns={2}
        value={adlibId}
        onChange={setAdlibId}
      />
      <TextInput
        name='adlib-code'
        label={T('collection.adlibCode')}
        labelColumns={2}
        value={adlibCode}
        onChange={setAdlibCode}
      />
      <Form.Group as={Row}>
        <Col sm={{ span: 10, offset: 2 }}>
          <BottomButtonRow>
            <Button variant="danger" onClick={handleClickedRemove}>
              {T('generic.action.remove')}
            </Button>
            <Button variant="secondary" onClick={handleClickedCancel}>
              {T('generic.action.cancel')}
            </Button>
            <Button
              variant="primary"
              disabled={!hasChanged}
              onClick={handleClickedSave}
            >
              {T('collection.save')}
            </Button>
          </BottomButtonRow>
        </Col>
      </Form.Group>
    </Form>
  );
}
