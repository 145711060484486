import React, { useState, useEffect } from 'react';
import api from '../../api';
import { useElementID } from '../../utils/ElementID';
import { IMemorial } from '../../models/Memorial';
import { TextMode } from '../../models/SpecialFunction';
import { SortDirection } from '../table/Table';
import { InputGroup } from './InputGroup';
import { SimpleAutocomplete } from './SimpleAutocomplete';
import { IPlace } from '../../models/Place';
import { PlaceLink } from "../PlaceLink";

interface MemorialInputProps {
  labelColumns?: number;
  inputColumns?: number;

  label: string;
  error?: string;

  value: IMemorial|undefined;
  onChange: (memorial: IMemorial) => void;
}

export const MemorialInput = (props: MemorialInputProps) => {
  const {
    label,
    onChange,
    value,
    error
  } = props;
  const id = useElementID('input');
  const [inputText, setInputText] = useState(value ? value.name : '');
  useEffect(() => setInputText(value ? value.name : ''), [value]);

  const handleSelected = (selected: IMemorial) => {
    setInputText(selected.name);
    onChange(selected);
  };

  const [place, setPlace] = useState<IPlace>();
  const placeId = value && value.place_id;
  useEffect(() => {
    if (!placeId) {
      setPlace(undefined);
      return;
    }

    api.getPlace(placeId).then(setPlace);
  }, [placeId]);

  return (
    <InputGroup id={id} {...props}>
      <MemorialInputComponent
        memorial={value}
        value={inputText}
        onSelected={handleSelected}
        onChange={setInputText}
        invalid={error !== undefined}
        placeholder={label}
      />
      {place && <PlaceLink place={place} />}
    </InputGroup>
  );
}

interface MemorialInputComponentProps {
  memorial: IMemorial|undefined;
  value: string
  onSelected: (value: IMemorial) => void;
  onChange: (value: string) => void;
  searchMode?: TextMode;
  invalid?: boolean;
  placeholder?: string;
}
interface MemorialInputComponentState {
  options: IMemorial[],
  modal: null | JSX.Element;
}

export class MemorialInputComponent extends React.PureComponent<MemorialInputComponentProps, MemorialInputComponentState> {
  requestTimer?: NodeJS.Timer;

  constructor(props: MemorialInputComponentProps) {
    super(props);

    this.state = {
      options: [],
      modal: null
    };
  }

  handleValueChanged = (value: string) => {
    // is this a valid uuid?
    let match = value.match(/.*([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/);
    if (match) {
      this.setState({ options: [] });
      api.getMemorial(match[1])
        .then(result => {
          if (match && result)
            this.handleSelected(match[1], result);
        });
    } else {
      this.scheduleSearch(value);
    }

    this.props.onChange(value);
  }

  handleSelected = (value: string, item: IMemorial) => {
    this.props.onSelected(item);

    this.setState({
      options: []
    });
  }

  scheduleSearch(value: string) {
    if (this.requestTimer) {
      clearTimeout(this.requestTimer);
      this.requestTimer = undefined;
    }

    if (!value) {
      this.setState({ options: [] });
    } else {
      this.requestTimer = setTimeout(() => this.searchMemorials(), 500);
    }
  }

  searchMemorials() {
    const { value, searchMode } = this.props;
    this.requestTimer = undefined;
    api.findMemorials(value, 0, 10, [['filter', SortDirection.Up]], [], searchMode)
      .then(entities => this.setState({
        options: entities.data
      }));
  }

  render() {
    const { value, placeholder, invalid = false } = this.props;
    const { options } = this.state;
    return <>
      <SimpleAutocomplete
        placeholder={placeholder}
        loading={this.requestTimer !== undefined}
        inputValue={value}
        onInputChange={this.handleValueChanged}
        onSelect={this.handleSelected}
        itemValue={item => item.name}
        itemKey={item => item._id}
        renderItem={item => item.name}
        options={options}
        invalid={invalid}
      />
      {this.state.modal}
    </>;
  }
}
