import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { PageProps, Page } from '../Page';
import api from '../../api';
import { Document, IDocumentPage } from '../../models/Document';
import { Button, Form, Card } from 'react-bootstrap';
import { T } from '../../Translate';
import { DocumentTag } from '../../models/DocumentTag';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { FormCard } from '../../components/FormCard';
import { Icon } from '../../components/Icons';
import { BottomButtonRow } from '../../components/ButtonRow';
import { UnsavedChangesModal } from '../../modals/UnsavedChangesModal';
import styles from './ImageBrowser.module.scss';
import { ImageViewer } from './ImageBrowser';
import { ResizableSplitPane } from './ResizableSplitPane';
import { TextInput } from '../../components/inputs/TextInput';
import { useDocument, useDocumentTagDefinitions } from '../../FunctionalAPI';
import { ISharedPageState } from '../../redux/State';
import { useAppSelector, useAppDispatch } from '../../utils/Functional';
import { setSharedPageState } from '../../redux/Actions';
import { NotificationManager } from 'react-notifications';
import { EditModeButton } from '../../components/EditModeButton';
import { guessFileType, getFileTypeFromMimeType, FileType } from '../../utils/MimeTypes';
import { DocumentReference } from '../../models/DocumentReference';
import { DocumentReferences } from '../EditCollectionDocument/DocumentReferences';
import { PageID, getPageVia } from '../../utils/PageLinking';

interface DocumentAnnotatorPageProps extends PageProps {
  id: string;
  page: number;
}

function getNextPage(document: Document|undefined, pageNumber: number) {
  if (!document)
    return undefined;

  let nextPage = pageNumber + 1;
  while (nextPage < document.elements.length && document.elements[nextPage].type !== 'page')
    nextPage++;
  return nextPage >= document.elements.length ? undefined : nextPage;
}

function getPreviousPage(document: Document|undefined, pageNumber: number) {
  if (!document)
    return undefined;

  let nextPage = pageNumber - 1;
  while (nextPage >= 0 && document.elements[nextPage].type !== 'page')
    nextPage--;
  return nextPage < 0 ? undefined : nextPage;
}

export function getFiletype(page: IDocumentPage): FileType {
  if (page.original_filename)
    return guessFileType(page.original_filename);
  else
    return getFileTypeFromMimeType(page.mimetype);
}

export default (props: DocumentAnnotatorPageProps) => {
  const { id, page: elementIndex, history, loading, modals, via } = props;

  const readOnlyFromState = useAppSelector(state => state.sharedPageState.editingDocumentId) !== id;
  const readOnly = api.user.isReadOnly() || readOnlyFromState;
  const dispatch = useAppDispatch();

  const [documentJson,,setDocumentJson] = useDocument(loading, id);
  const tagDefinitions = useDocumentTagDefinitions(loading);

  const [pageNumberInput, setPageNumberInput] = useState('');
  const [pageNumberError, setPageNumberError] = useState<string|undefined>();
  const [transcription, setTranscription] = useState('');
  const [tags, setTags] = useState<DocumentTag[]>([]);
  const [references, setReferences] = useState<DocumentReference[]>([]);

  const saved = useRef(false);
  useEffect(() => { saved.current = true; }, [id, elementIndex]);

  const document = useMemo(
    () => documentJson ? Document.fromJSON(documentJson, tagDefinitions) : undefined,
    [documentJson, tagDefinitions]
  );
  const page = useMemo(
    () => {
      const element = document && document.elements[elementIndex];
      if (element && element.type === 'page') {
        return element;
      } else {
        return undefined;
      }
    },
    [document, elementIndex]
  );
  const originalPageTags = useMemo(
    () => page ? page.tags.map(tag => DocumentTag.fromJSON(tag, tagDefinitions)) : [],
    [page, tagDefinitions]
  );

  useEffect(() => {
    setTags(page ? page.tags.map(tag => DocumentTag.fromJSON(tag, tagDefinitions)) : []);
    setReferences(page ? page.references.map(reference => DocumentReference.fromJSON(reference)): []);
    setTranscription(page ? page.transcription : '');
  }, [document, tagDefinitions, page]);

  useEffect(
    () => setPageNumberInput((elementIndex + 1).toString()),
    [elementIndex]
  );

  const nextPage = getNextPage(document, elementIndex);
  const previousPage = getPreviousPage(document, elementIndex);

  const handleClickedNext = () => {
    if (nextPage === undefined)
      return;

    history.push(`/documents/${id}/${nextPage}`);
  };

  const handleClickedPrevious = () => {
    if (previousPage === undefined)
      return;

    history.push(`/documents/${id}/${previousPage}`);
  };

  const handlePageNumberChanged = (pageNumber: string) => {
    let error: string|undefined = undefined;
    if (isNaN(parseInt(pageNumber))) {
      error = T('validationError.invalidFormat');
    }
    setPageNumberInput(pageNumber);
    setPageNumberError(error);
  };

  const referencesHaveChanged = useMemo(() => {
    if (!page)
      return false;

    if (page.references.length !== references.length)
      return true;

    for (let i = 0; i < references.length; i++) {
      if (page.references[i]._id !== references[i].id)
        return true;
    }

    return false;
  }, [page, references]);

  const hasChanged = () => {
    if (!page)
      return true;
    if (referencesHaveChanged)
      return true;

    if (pageNumberInput !== (elementIndex + 1).toString())
      return true;
    if (transcription !== page.transcription)
      return true;
    if (tags.length !== originalPageTags.length)
      return true;

    for (let i = 0; i < tags.length; i++) {
      const oldAnnotation = originalPageTags[i];
      const newAnnotation = tags[i];

      if (!oldAnnotation.equals(newAnnotation))
        return true;
    }

    return false;
  };
  const changed = hasChanged();

  const confirmNavigation = useCallback(() => {
    if (!changed || saved.current)
      return Promise.resolve(true);

    modals.show(UnsavedChangesModal);
    return Promise.resolve(false);
  }, [changed, modals]);

  const documentTitle = document ? (document.title || T('page.document.untyped')) : '';
  const title = T('documentPage.title', {
    title: documentTitle,
    page: (elementIndex + 1).toString()
  });
  const shortTitle = T('documentPage.shortTitle', { page: (elementIndex + 1).toString() });

  const parsedPageNumberInput = parseInt(pageNumberInput);
  const isValid = document !== undefined
    && /^[0-9]+$/.test(pageNumberInput)
    && parsedPageNumberInput > 0
    && parsedPageNumberInput <= document.elements.length;

  const handleClickedSave = async () => {
    if (!document)
      return;

    const updates: Partial<IDocumentPage> = {
      tags: tags.map(tag => tag.toJSON()),
      references: references.map(reference => reference.toJSON()),
      transcription
    };
    const newElementIndex = parsedPageNumberInput - 1;
    const newDocument = await api.updateCollectionDocumentElement(
      document.id,
      elementIndex,
      updates,
      newElementIndex
    );
    NotificationManager.success(T('page.document.pageSaved'));
    setDocumentJson(newDocument);
    if (elementIndex !== newElementIndex) {
      saved.current = true;
      history.replace(`/documents/${id}/${newElementIndex}`);
    }
  };

  const handleClickedCancel = () => {
    const tags = page ? page.tags.map(tag => DocumentTag.fromJSON(tag, tagDefinitions)) : [];
    setTags(tags);
    setTranscription(page ? page.transcription : '');
    setPageNumberInput((elementIndex + 1).toString());
    setReferences(page ? page.references.map(reference => DocumentReference.fromJSON(reference)): []);
  };

  const handleClickedEdit = () => {
    const newReadOnly = !readOnly;
    dispatch(setSharedPageState(newReadOnly ? {} : { editingDocumentId: id }));
  }

  const editButton = !api.user.isReadOnly() && (
    <EditModeButton
      disabled={changed}
      inverted={!readOnly}
      onClick={handleClickedEdit}
    />
  );

  if (!page)
    return <div />; // still loading

  const pageViewer = <>
    <ImageViewer
      filetype={getFiletype(page)}
      url={page.url_resized}
      originalUrl={page.url_original}
    />
    {previousPage !== undefined && (
      <Button variant='link' onClick={handleClickedPrevious} className={styles.previous}>
        <i className={Icon.AngleLeft} />
      </Button>
    )}
    {nextPage !== undefined && (
      <Button variant='link' onClick={handleClickedNext} className={styles.next}>
        <i className={Icon.AngleRight} />
      </Button>
    )}
    <div className={styles.filename}>
      <div>{page.original_filename}</div>
    </div>
  </>;

  const leftPanel = (
    <Card style={{ marginTop: '1em', height: 'calc(100% - 2em)' }}>
      <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
        <Card.Title>
          <i className={Icon.Images} />{' '}
          {T('document.title.scans')}
        </Card.Title>
        <Card.Text as='div' style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          {pageViewer}
        </Card.Text>
      </Card.Body>
    </Card>
  );
  const rightPanel = <>
    <FormCard
      title={T('document.title.content')}
      icon={Icon.Tags}
      style={{ marginTop: '1em'}}
      buttons={editButton}
    >
      <Form>
        {!readOnly && (
          <TextInput
            label={T('documentPage.pageNumber')}
            name='page-number'
            value={pageNumberInput}
            onChange={handlePageNumberChanged}
            error={pageNumberError}
            info={T('documentPage.pageNumber.info')}
          />
        )}
        <DocumentReferences
          references={references}
          setReferences={setReferences}
          readOnly={readOnly}
        />
        <TextareaInput
          vertical
          label={T('document.transcription')}
          value={transcription}
          name='transcription'
          onChange={setTranscription}
          rows={8}
          readOnly={readOnly}
        />
      </Form>
    </FormCard>
  </>;

  const sharedPageState: ISharedPageState = readOnly ? {} : { editingDocumentId: id };
  return (
    <Page
      id={PageID.EditDocumentPage}
      entity={`${id}+${elementIndex}`}
      icon={Icon.Page}
      title={title}
      shortTitle={shortTitle}
      via={via || getPageVia(PageID.EditDocument, id)}
      viaTitle={via ? undefined : documentTitle}
      sharedPageState={sharedPageState}
      confirmNavigation={confirmNavigation}
      containerStyle={{
        paddingBottom: readOnly ? '0px' : '3.5em ',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flexGrow: 1
      }}
    >
      <ResizableSplitPane left={leftPanel} right={rightPanel} />
      {!readOnly && (
        <BottomButtonRow>
          <Button
            variant='secondary'
            disabled={!changed}
            onClick={handleClickedCancel}
          >
            {T('generic.action.cancel')}
          </Button>
          <Button
            variant='primary'
            type='submit'
            disabled={!changed || !isValid}
            onClick={handleClickedSave}
          >
            {T('document.save')}
          </Button>
        </BottomButtonRow>
      )}
    </Page>
  );
}
