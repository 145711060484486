import React, { useState } from 'react';
import { Person, translateVictimType } from '../../models/Person';
import { ModalProps, useModal } from '../../modals/BaseModal';
import { Modal, Button, Table } from 'react-bootstrap';
import { T } from '../../Translate';
import { Icon } from '../../components/Icons';
import styles from './index.module.scss';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { ModalHeader } from '../../modals/ModalHeader';

export interface MergePersonsModalResult {
  confirmed: boolean;
  openResult: boolean;
  personA: Person;
  personB: Person;
}
interface MergePersonsModalProps extends ModalProps<MergePersonsModalResult> {
  personA: Person;
  personB: Person;
}
export const MergePersonsModal = (props: MergePersonsModalProps)  => {
  const [isOpen, resolve] = useModal(props);
  const [personA, setPersonA] = useState(props.personA);
  const [personB, setPersonB] = useState(props.personB);

  const handleClickedSwitch = () => {
    setPersonA(personB);
    setPersonB(personA);
  };

  const handleClickedCancel = () => {
    resolve({
      personA,
      personB,
      confirmed: false,
      openResult: false
    });
  };

  const handleClickedConfirm = () => {
    resolve({
      personA,
      personB,
      confirmed: true,
      openResult: false
    });
  };

  const handleClickedConfirmAndOpen = () => {
    resolve({
      personA,
      personB,
      confirmed: true,
      openResult: true
    });
  };

  const nameA = personA.name;
  const nameB = personB.name;
  const bornA = personA.birthDateFormatted;
  const bornB = personB.birthDateFormatted;
  const diedA = personA.diedDateFormatted;
  const diedB = personB.diedDateFormatted;
  const willEraseName = nameA !== nameB;
  const willEraseBorn = bornA !== '' && (bornA !== bornB);
  const willEraseDied = diedA !== '' && (diedA !== diedB);
  const willEraseType = personA.victimType !== personB.victimType;

  return (
    <Modal show={isOpen} onHide={handleClickedCancel} size='lg'>
      <ModalHeader>{T('modal.mergePerson.title')}</ModalHeader>
      <Modal.Body>
        <p>{T('modal.mergePerson.message')}</p>
        <Table>
          <tbody>
            <tr>
              <td>{T('person.name')}</td>
              <td>{nameA}</td>
              <td className={willEraseName ? styles.erased : ''}>{nameB}</td>
            </tr>
            <tr>
              <td>{T('person.born')}</td>
              <td>{bornA}</td>
              <td className={willEraseBorn ? styles.erased : ''}>{bornB}</td>
            </tr>
            <tr>
              <td>{T('person.died')}</td>
              <td>{diedA}</td>
              <td className={willEraseDied ? styles.erased : ''}>{diedB}</td>
            </tr>
            <tr>
              <td>{T('person.type')}</td>
              <td>{translateVictimType(personA.victimType)}</td>
              <td className={willEraseType ? styles.erased : ''}>{translateVictimType(personB.victimType)}</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='warning' onClick={handleClickedSwitch}>
          <i className={Icon.Switch} />
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button variant='secondary' onClick={handleClickedCancel}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={handleClickedConfirm}>
          <i className={Icon.Check} />
        </Button>
        <Button variant='primary' onClick={handleClickedConfirmAndOpen}>
          <i className={Icon.Check} />
          {' & '}
          <i className={Icon.Search} />
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
