import { CSSProperties as ReactCSSProperties } from "react";
import { Properties } from 'csstype';

// because of incompatibilities between different declarations of CSSProperties
type CSSProperties = ReactCSSProperties & Properties;

export const Colors =  {
  lightGray: '#9A9A9A',
  lighterGray: '#CCCCCC',
  verylightGray: '#FAFAFA',
  darkGray: '#565656',
  linkBlue: '#447DF7',
  errorRed: '#FB404B',
  white: '#FFFFFF',
  black: '#2b2d42',
}


const labelTextSmallStyle : CSSProperties= {
  fontSize:'0.75rem',
  textTransform: 'uppercase',
  color: Colors.lightGray,
}

const labelTextMediumStyle: CSSProperties = {
  fontSize:'0.75rem',
  textTransform: 'uppercase',
  color: Colors.lightGray,
}

const labelTextLargeStyle: CSSProperties = {
  fontSize:'1rem',
  textTransform: 'uppercase',
  color: Colors.lightGray,
}

const textSmallStyle : CSSProperties= {
  fontSize:'0.75rem',
  color: Colors.lightGray,
}

const textMediumStyle: CSSProperties = {
  fontSize:'0.875rem',
  color: Colors.lightGray,
}

const textLargeStyle: CSSProperties = {
  fontSize:'1rem',
  color: Colors.lightGray,
}

const textMediumBoldStyle: CSSProperties = {
  fontSize:'0.875rem',
  fontWeight: 750,
  color: Colors.lightGray,
}

const blockTextMediumStyle : CSSProperties= {
  fontSize:'0.875rem',
  color: Colors.darkGray,
}

const titleTextCapitalsStyle : CSSProperties= {
  fontSize:'1.2rem',
  color: Colors.darkGray,
  textTransform: 'uppercase'
}

const titleBoldTextCapitalsStyle: CSSProperties = {...titleTextCapitalsStyle, fontWeight: 'bold'}

const subtitleTextCapitalsStyle : CSSProperties= {
  fontSize:'1.0rem',
  color: Colors.darkGray,
  textTransform: 'uppercase'
}
const flatlinkTextSmallStyle : CSSProperties= {
  fontSize:'0.75rem',
  color: Colors.linkBlue,
}

const flatlinkTextMediumStyle : CSSProperties= {
  fontSize:'0.875rem',
  color: Colors.linkBlue,
}

const linkTextSmallStyle =  {...flatlinkTextMediumStyle, textDecorationLine: "underline"}
const linkTextMediumStyle =  {...flatlinkTextMediumStyle, textDecorationLine: "underline"}



export const TextStyles = {
  label: {
    small: labelTextSmallStyle,
    medium: labelTextMediumStyle,
    large: labelTextLargeStyle
  },
  text: {
    small: textSmallStyle,
    medium: textMediumStyle,
    mediumBold: textMediumBoldStyle,
    large: textLargeStyle
  },
  block: {
    medium: blockTextMediumStyle
  },
  title: {
    capitals: titleTextCapitalsStyle,
    fatcapitals: titleBoldTextCapitalsStyle
  },
  subtitle: {
    capitals: subtitleTextCapitalsStyle
  },
  link: {
    small: linkTextSmallStyle,
    medium: linkTextMediumStyle
  },
  flatlink: {
    small: flatlinkTextSmallStyle,
    medium: flatlinkTextMediumStyle
  }
}
