import React from 'react';
import { Pagination } from 'react-bootstrap';
import { Icon } from './Icons';
import { Noun, T, pluralize } from '../Translate';

interface PaginatorProps {
  page: number;
  pageSize: number;
  shownItems: number;
  totalItems: number;
  maxItems?: number;
  onPageSelected: (page: number) => void;
  noun: Noun;
}
export class Paginator extends React.PureComponent<PaginatorProps> {
  handleClickedFirst = () => {
    const { onPageSelected } = this.props;
    onPageSelected(0);
  }

  handleClickedPrevious = () => {
    const { page, onPageSelected } = this.props;
    onPageSelected(page - 1);
  }

  handleClickedPage = (e: React.SyntheticEvent<HTMLElement>) => {
    const { onPageSelected } = this.props;
    onPageSelected(parseInt(e.currentTarget.dataset.page || '0'));
  }

  handleClickedNext = () => {
    const { page, onPageSelected } = this.props;
    onPageSelected(page + 1);
  }

  handleClickedLast = () => {
    const { totalItems, pageSize, onPageSelected } = this.props;
    const numPages = Math.ceil(totalItems / pageSize);
    onPageSelected(numPages - 1);
  }

  render() {
    const { page, shownItems, totalItems, pageSize, maxItems = 9, noun } = this.props;
    const numPages = Math.ceil(totalItems / pageSize);
    if (numPages === 0)
      return <div />;

    const items: JSX.Element[] = [];
    items.push(
      <Pagination.Item
        key='first'
        disabled={page === 0}
        onClick={this.handleClickedFirst}
      >
        <i className={Icon.ChevronDoubleLeft} />
      </Pagination.Item>
    );
    items.push(
      <Pagination.Item
        key='previous'
        disabled={page === 0}
        onClick={this.handleClickedPrevious}
      >
        <i className={Icon.ChevronLeft} />
      </Pagination.Item>
    );

    let start: number;
    let end: number;
    if (numPages <= maxItems) {
      start = 0;
      end = numPages;
    } else if (page <= maxItems / 2) {
      start = 0;
      end = maxItems - 2;
    } else if (page >= numPages - (maxItems / 2)) {
      end = numPages;
      start = end - (maxItems - 2);
    } else {
      start = page - (maxItems - 5) / 2;
      end = start + maxItems - 4;
    }
    
    if (start > 0) {
      //items.push(<Pagination.Item key={1} onClick={this.handleClickedFirst}>{1}</Pagination.Item>);
      items.push(<Pagination.Ellipsis key='ellipsis-prev' />);
    }
    for (let i = start; i < end; i++) {
      if (i < 99)
        items.push(<Pagination.Item key={i} data-page={i} active={i === page} onClick={this.handleClickedPage}>{(i + 1)}</Pagination.Item>);
    }
    if (end < numPages) {
      items.push(<Pagination.Ellipsis key='ellipsis-next' />);
      //if (numPages < 99)
      //  items.push(<Pagination.Item key={numPages} onClick={this.handleClickedLast}>{(numPages + 1)}</Pagination.Item>);
    }

    items.push(
      <Pagination.Item
        key='next'
        disabled={page === numPages - 1}
        onClick={this.handleClickedNext}
      >
        <i className={Icon.ChevronRight} />
      </Pagination.Item>
    );
    items.push(
      <Pagination.Item
        key='last'
        disabled={page === numPages - 1}
        onClick={this.handleClickedLast}
      >
        <i className={Icon.ChevronDoubleRight} />
      </Pagination.Item>
    );

    const fromItem = page * pageSize;
    const toItem = fromItem + shownItems;
    const label = T('table.showingXofY', {
      from: (fromItem + 1).toString(),
      to: toItem.toString(),
      noun: pluralize(noun, shownItems),
      total: totalItems.toString()
    });
    return (
      <div className='pull-right pagination' style={{marginLeft: '1em', marginRight: '1em'}}>
        <span style={{ color: '#888' }}>{label}</span>
        <div style={{ flexGrow: 1}} />
        <Pagination>
          {items}
        </Pagination>
      </div>
    );
  }
}
