import React, { CSSProperties, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { ITableColumn, ColumnAlign, IFilterOption } from './Fields';
import { Icon } from '../Icons';
import { classes } from '../../utils/Styles';
import styles from './Table.module.scss';
import { Paginator } from '../Paginator';
import { Noun, T } from '../../Translate';
import { usePopper } from 'react-popper';
import { Form } from 'react-bootstrap';

export enum SortDirection {
  Up = 'up',
  Down = 'down'
}

export interface ITableFilter {
  column: string,
  value: string
}

interface TableProps<T, S = {}> {
  displayedItems: T[];
  state?: S;
  columns: ITableColumn<T>[];
  className?: string;
  rowKey?: (item: T, index: number) => string|number;
  onClickItem?: (item: T) => void;
  onPageChanged: (page: number) => void;
  onSortChanged: (column: string, direction: SortDirection) => void;
  onFilter?: (column: string, value: string|null) => void;
  noun: Noun;

  sortColumn: string;
  sortDirection: SortDirection;
  filters?: ITableFilter[];
  page: number;
  pageSize: number;
  totalItems: number;
}

function renderTH<T>(
  column: ITableColumn<T>,
  sortColumn: string,
  sortDirection: SortDirection,
  filter: string|undefined,
  onClickedSort: (column: ITableColumn<T>) => void,
  onClickedFilter?: (column: string, option: string|null) => void
) {
  let style: CSSProperties|undefined = undefined;
  if (column.options.align === ColumnAlign.Right)
    style = { textAlign: 'right' };
  if (column.options.width) {
    style = style || {};
    style.width = column.options.width;
  }
  if (column.options.maxWidth) {
    style = style || {};
    style.maxWidth = column.options.maxWidth;
  }

  if (column.options.sortable || column.options.filter) {
    const icon = sortColumn === column.id ? (sortDirection === SortDirection.Up ? Icon.SortUp : Icon.SortDown) : Icon.Sort;

    return (
      <th key={column.id} className={styles.th} style={style}>
        <span className={styles.sortableHeader} onClick={() => onClickedSort(column)}>{column.options.icon ? <i className={column.options.icon} /> : column.title} {column.options.sortable && <i className={icon} />}</span>
        {column.options.filter && onClickedFilter && (
          <FilterIcon
            column={column.id}
            selected={filter === undefined ? null : filter}
            filterOptions={column.options.filter}
            onFilter={option => onClickedFilter(column.id, option)}
          />
        )}
      </th>
    );
  } else {
    return <th key={column.id} style={style}>{column.options.icon ? <i className={column.options.icon} title={column.title} /> : column.title}</th>;
  }
}

interface FilterIconProps {
  column: string,
  selected: string|null,
  filterOptions: IFilterOption[],
  onFilter: (value: string|null) => void
}
function FilterIcon(props: FilterIconProps) {
  const { column, selected, filterOptions, onFilter } = props;

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement|null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement|null>(null);
  const [filterOpen, setFilterOpen] = useState(false);
  
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {});

  const windowClickedHandler = useRef<((e: MouseEvent) => void)|null>(null);
  useEffect(() => {
    if (filterOpen) {
      windowClickedHandler.current = (e: MouseEvent) => {
        console.log(e);
        if (popperElement && !popperElement.contains(e.target as Node) && referenceElement && !referenceElement.contains(e.target as Node)) {
          setFilterOpen(false);
        }
      };
      window.addEventListener('click', windowClickedHandler.current);

      return () => {
        if (windowClickedHandler.current) {
          window.removeEventListener('click', windowClickedHandler.current);
          windowClickedHandler.current = null;
        }
      }
    }
  }, [filterOpen, popperElement, referenceElement]);

  const handleClickedFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleCheckedFilter = (checked: boolean, value: string|null) => {
    if (checked) {
      setFilterOpen(false);
      onFilter(value);
    }
  };

  return <>
    <div className={selected === null ? styles.filterButton : styles.filterButtonActive} ref={setReferenceElement} onClick={handleClickedFilter}>
      <i className="far fa-search" />
    </div>
    {filterOpen && (
      <div ref={setPopperElement} className={styles.filterPopout} style={popperStyles.popper} {...attributes.popper}>
        <Form.Check
          type='radio'
          id={`${column}-filter-all`}
          label={T('generic.filter.noFilter')}
          checked={selected === null}
          onChange={e => handleCheckedFilter(e.currentTarget.checked, null)}
        />
        {filterOptions.map(option => (
          <Form.Check
            key={option.value}
            type='radio'
            id={`${column}-filter-${option.value}`}
            label={option.label}
            checked={option.value === selected}
            onChange={e => handleCheckedFilter(e.currentTarget.checked, option.value)}
          />
        ))}
      </div>
    )}
  </>;
}

function renderRow<T, S>(
  columns: ITableColumn<T>[],
  rowKey: (item: T, index: number) => string|number,
  state: S,
  item: T,
  index: number,
  onClickItem?: ((item: T) => void)
): JSX.Element {

  return (
    <tr key={rowKey(item, index)} className={onClickItem ? styles.clickableRow : undefined}>
      {columns.map(column => {
        const style: CSSProperties = { maxWidth: column.options.maxWidth, width: column.options.width };
        if (column.options.align === ColumnAlign.Right)
          style.textAlign = 'right';

        return column.render(
          item,
          {
            onClick: onClickItem && column.options.clickable ? () => onClickItem(item) : undefined,
            className: classes(
              onClickItem && column.options.clickable ? styles.clickableCell : undefined,
              column.options.ellipsize ? styles.ellipsize : undefined,
              column.options.className
            ),
            style
          },
          state
        );
      })}
    </tr>
  );
}

function renderHeader<T>(
  columns: ITableColumn<T>[],
  sortColumn: string,
  sortDirection: SortDirection,
  filters: ITableFilter[],
  onClickedSort: (column: ITableColumn<T>) => void,
  onClickedFilter?: (column: string, option: string|null) => void
) {
  return <tr>{columns.map(column => renderTH(
    column,
    sortColumn,
    sortDirection,
    filters.find(filter => filter.column === column.id)?.value,
    onClickedSort,
    onClickedFilter
  ))}</tr>;
}

const NO_FILTERS: ITableFilter[] = [];

export function Table<T, S = {}>(props: TableProps<T, S>) {
  const {
    displayedItems,
    columns,
    className,
    rowKey = (_: T, index: number) => index,
    state = {} as S,
    onClickItem,
    onPageChanged,
    onSortChanged,
    onFilter,
    sortColumn,
    sortDirection,
    filters,
    page,
    pageSize,
    totalItems,
    noun
  } = props;


  const handleClickedSort = useCallback((column: ITableColumn<T>) => {
    if (column.id === sortColumn) {
      const newDirection = sortDirection === SortDirection.Up ? SortDirection.Down : SortDirection.Up;
      onSortChanged(column.id, newDirection);
    } else {
      onSortChanged(column.id, SortDirection.Up);
    }
  }, [sortColumn, sortDirection, onSortChanged]);

  const renderedHeader = useMemo(() => renderHeader<T>(columns, sortColumn, sortDirection, filters || NO_FILTERS, handleClickedSort, onFilter), [columns, sortColumn, sortDirection, handleClickedSort]);
  const renderedRows = useMemo(() => displayedItems.map((item, index) => renderRow(columns, rowKey, state, item, index, onClickItem)), [columns, displayedItems, rowKey, onClickItem, state]);

  return (
    <>
      <table className={classes('table', 'table-striped', styles.table, className)}>
        <thead>
          {renderedHeader}
        </thead>
        <tbody>
          {renderedRows}
        </tbody>
      </table>
      <div className='fixed-table-pagination'>
        <Paginator page={page} pageSize={pageSize} shownItems={displayedItems.length} totalItems={totalItems} onPageSelected={onPageChanged} noun={noun} />
      </div>
    </>
  );
}

export function TableCardBody<T>(props: TableProps<T> & { tableClassName?: string }) {
  return (
    <div className='card-block table-responsive table-full-width' style={{ width: 'auto' }}>
      <Table className={props.tableClassName} {...props} />
    </div>
  );
};

export function sortItems<T>(
  columns: ITableColumn<T>[],
  items: T[],
  sortColumn: string,
  sortDirection: SortDirection
) {
  const column = columns.find(c => c.id === sortColumn);
  if (!column)
    return items;

  const result = [...items];
  result.sort(sortDirection === SortDirection.Up ? column.sort : (a, b) => column.sort(b, a));
  return result;
}
