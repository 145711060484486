import React, { useContext } from 'react';
import { ModalTarget } from './ModalTarget';

const ModalContext = React.createContext<ModalTarget>({ show: <T,>() => Promise.reject() });

export function useModalContext(): ModalTarget {
  return useContext(ModalContext);
}

export const ModalContextProvider = ModalContext.Provider;
