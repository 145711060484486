import React, { useMemo, useState } from 'react';
import { ModalProps, useModal } from '../../modals/BaseModal';
import { MilitaryTrackType, IMilitaryEntityTrackEvent, getMilitaryTrackTypeName } from '../../models/MilitaryEntity';
import { emptyDate, DateInput, inputDateToSortable, parseInputDateFromSortable } from '../../components/inputs/DateInput';
import { Modal, Form, Button } from 'react-bootstrap';
import { SelectInput, ISelectOption } from '../../components/inputs/SelectInput';
import { T } from '../../Translate';
import api from '../../api';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { PlaceInput } from '../../components/inputs/PlaceInput';
import { TextInput } from '../../components/inputs/TextInput';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { ModalHeader } from '../../modals/ModalHeader';
import { useStateWithError } from '../../utils/Functional';

interface EditTrackEventModalProps extends ModalProps<IMilitaryEntityTrackEvent|null> {
  index?: number;
  militaryEntityId?: string;
  event?: IMilitaryEntityTrackEvent;
}
const trackTypes = [
  MilitaryTrackType.FrontierService,
  MilitaryTrackType.Support,
  MilitaryTrackType.HalfRest,
  MilitaryTrackType.Rest
];

export const EditTrackEventModal = (props: EditTrackEventModalProps) => {
  const { index, militaryEntityId, event } = props;
  const [isOpen, resolve] = useModal(props);
  const handleClose = () => resolve(null);

  const typeOptions: ISelectOption[] = useMemo(() =>
    trackTypes.map(t => ({ value: t, title: getMilitaryTrackTypeName(t) })),
    []
  );

  const [type, setType] = useState(event ? event.track_type : MilitaryTrackType.FrontierService);
  const [fromDate, setFromDate, fromDateError, setFromDateError]
    = useStateWithError(event ? parseInputDateFromSortable(event.from_date) : emptyDate());
  const [toDate, setToDate, toDateError, setToDateError]
    = useStateWithError(event ? parseInputDateFromSortable(event.to_date) : emptyDate());
  const [place, setPlace, placeError, setPlaceError]
    = useStateWithError(event ? event.place : undefined);
  const [topo, setTopo, topoError, setTopoError]
    = useStateWithError(event ? event.topo || '' : '');
  const [description, setDescription] = useState(event ? event.description : '');

  const handleTypeChanged = (value: string) => setType(value as MilitaryTrackType);

  const handleClickedSave = async () => {
    const document: IMilitaryEntityTrackEvent = {
      type: 'track',
      track_type: type,
      from_date: inputDateToSortable(fromDate),
      to_date: inputDateToSortable(toDate),
      place_id: place ? place._id : undefined,
      place: place,
      topo,
      description
    };
    const result = await api.validateMilitaryEntityHistory(
      document,
      index ? 'update' : 'create',
      militaryEntityId,
      index
    );
    if (result.status === 'OK') {
      resolve(document);
      return;
    }

    const errors = result.data || {};
    setFromDateError(errors.from_date);
    setToDateError(errors.to_date);
    setPlaceError(errors.place_id);
    setTopoError(errors.topo);
  };

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <ModalHeader>{T('page.militaryEntity.editTrackEvent.title')}</ModalHeader>
      <Modal.Body>
        <Form>
          <SelectInput
            label={T('page.militaryEntity.event.trackType')}
            name='track-type'
            value={type}
            onChange={handleTypeChanged}
            options={typeOptions}
          />
          <DateInput
            label={T('page.militaryEntity.event.from')}
            name='from'
            value={fromDate}
            onChange={setFromDate}
            error={fromDateError}
          />
          <DateInput
            label={T('page.militaryEntity.event.to')}
            name='to'
            value={toDate}
            onChange={setToDate}
            error={toDateError}
          />
          <PlaceInput
            label={T('page.militaryEntity.event.place')}
            name='place'
            value={place}
            onChange={setPlace}
            error={placeError}
            target={'window'}
          />
          <TextInput
            label={T('page.militaryEntity.event.topo')}
            name='topo'
            value={topo}
            onChange={setTopo}
            error={topoError}
          />
          <TextareaInput
            vertical
            label={T('page.militaryEntity.event.description')}
            name='description'
            value={description}
            onChange={setDescription}
          />
        </Form>
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='primary' onClick={handleClickedSave}>
          {T('generic.action.save')}
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
