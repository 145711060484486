import React from 'react';

import { IPlace, isPlace, Place } from "./Place";
import { IPerson } from "./Person";
import { IMemorial, MemorialType } from "./Memorial";
import { Icon } from "../components/Icons";
import { T, TranslationKey } from "../Translate";
import PageLink from '../components/PageLink';
import { PageID } from '../utils/PageLinking';
import { markdown2html } from '../utils/Markdown';

export enum PersonEventLoadOption {
  Place = 'EXTEND_PLACE',
  PlaceName = 'EXTEND_PLACE_NAME',
  Person = 'EXTEND_PERSON'
}

export enum PersonEventType {
  Where = 'where',
  School = 'school',
  Work = 'work',
  Extra = 'extra',
  Marriage = 'marriage',
  Injured = 'injured',
  Decorated = 'decorated',
  Military = 'enlisted',
  Died = 'died',
  Born = 'born',
  Memorated = 'memorated',
  Jailed = 'jailed',
  Hospitalized = 'hospitalized',
  CWXRM = 'cwxrm'
}

export interface IBaseEvent {
  _id: string;
  person_id: string;
  type: PersonEventType;
  start_day: number | null;
  start_month: number | null;
  start_year: number | null;
  sort_start_date: string;
  end_day: number | null;
  end_month: number | null;
  end_year: number | null;
  sort_end_date?: string;

  place_id?: string;
  topo?: string;
  description: string;
  place_name_original?: string;
  original_region?: string;
  original_country?: string;

  extend_place?: {} | IPlace;
  extend_place_name?: string;
  extend_person?: IPerson;
}

export interface IBornEvent extends IBaseEvent {
  type: PersonEventType.Born;
}

export interface IDiedEvent extends IBaseEvent {
  type: PersonEventType.Died;
  death_reason: string;
}

export interface IExtraEvent extends IBaseEvent {
  type: PersonEventType.Extra;
}

export interface IHospitalizedEvent extends IBaseEvent {
  type: PersonEventType.Hospitalized;
}

export interface IInjuredEvent extends IBaseEvent {
  type: PersonEventType.Injured;
  injured_wound: string;
  injured_cause: string;
}


export enum JailedType {
  Jailed = 'jailed',
  Captive = 'captive'
}
export function translateJailedEventType(type: JailedType) {
  return T(type === JailedType.Captive ? 'jailedType.captive' : 'jailedType.jailed');
}

export interface IJailedEvent extends IBaseEvent {
  type: PersonEventType.Jailed;
  jailed_type: JailedType;
}

export interface IMarriageEvent extends IBaseEvent {
  type: PersonEventType.Marriage;
  marriage_partner: string;
}

export enum MemoratedKind {
  Original = 'original',
  Current = 'current',
  None = 'none'
}
const MemoratedKindTranslations: { [key: string]: TranslationKey } = {
  [MemoratedKind.Original]: 'memoratedKind.original',
  [MemoratedKind.Current]: 'memoratedKind.current',
  [MemoratedKind.None]: 'memoratedKind.none'
}
export function translateMemoratedKind(type: MemoratedKind) {
  return T(MemoratedKindTranslations[type]) || ('!' + type);
}

export interface IMemoratedEvent extends IBaseEvent {
  type: PersonEventType.Memorated;
  memorial_id: string | null;
  memorial_kind: MemoratedKind;
  memorial_ref: string;
  memorial_document?: string;

  extend_memorial?: IMemorial & { loc: { lat: number, lon: number }, place_name: string };
}

export enum MilitaryEventType {
  Enlisted = 'enlisted',
  ActiveService = 'active service',
  PostedTo = 'posted to',
  AttachedTo = 'attached to',
  Renumbered = 'renumbered',
  TransferredTo = 'transferred to',
  Promoted = 'promoted'
}
const MilitaryEventTypeTranslations: { [key: string]: TranslationKey } = {
  [MilitaryEventType.Enlisted]: 'militaryEventType.enlisted',
  [MilitaryEventType.ActiveService]: 'militaryEventType.activeService',
  [MilitaryEventType.PostedTo]: 'militaryEventType.postedTo',
  [MilitaryEventType.AttachedTo]: 'militaryEventType.attachedTo',
  [MilitaryEventType.Renumbered]: 'militaryEventType.renumbered',
  [MilitaryEventType.TransferredTo]: 'militaryEventType.transferredTo',
  [MilitaryEventType.Promoted]: 'militaryEventType.promoted'
};

export function translateMilitaryEventType(type: MilitaryEventType) {
  return T(MilitaryEventTypeTranslations[type]) || ('!' + type);
}

export function describeMilitaryEventUnit(event: IMilitaryEvent): string {
  let entity = event.enlisted_army;
  if (event.enlisted_regt)
    entity += ' ' + event.enlisted_regt;
  if (event.enlisted_nr)
    entity += ', ' + event.enlisted_nr;
  if (event.enlisted_unit)
    entity += ' ' + event.enlisted_unit;
  return entity;
}

export interface IMilitaryEvent extends IBaseEvent {
  type: PersonEventType.Military;

  enlisted_reason: MilitaryEventType;
  enlisted_army: string;
  enlisted_regt: string;
  enlisted_unit: string;
  enlisted_nr: string; // unit nr
  enlisted_number: string; // service number
  enlisted_sub: string;
  enlisted_rank: string;
  military_entity_id: string | null;
  military_regiment_id: string | null;
}

export interface ISchoolEvent extends IBaseEvent {
  type: PersonEventType.School;

  school_name: string;
  school_direction: string;
}

export enum WhereEventType {
  HomeAddress = 'homeaddress',
  Fugitive = 'fugitive',
  Evacuated = 'evacuated',
  Deportated = 'deportated',
  Moved = 'moved'
}

const WhereEventTypeTranslations: { [key: string]: TranslationKey } = {
  [WhereEventType.HomeAddress]: 'whereEventType.homeAddress',
  [WhereEventType.Fugitive]: 'whereEventType.fugitive',
  [WhereEventType.Deportated]: 'whereEventType.deportated',
  [WhereEventType.Evacuated]: 'whereEventType.evacuated',
  [WhereEventType.Moved]: 'whereEventType.moved'
};
export function translateWhereEventType(type: WhereEventType) {
  return T(WhereEventTypeTranslations[type]) || ('!' + type);
}

export interface IWhereEvent extends IBaseEvent {
  type: PersonEventType.Where;

  where_reason: WhereEventType;
}

export interface IWorkEvent extends IBaseEvent {
  type: PersonEventType.Work;

  work_company_name: string;
  work_profession: string;
}

export interface IDecoratedEvent extends IBaseEvent {
  type: PersonEventType.Decorated;

  decoration: string;
  citation: string;
}

export interface ICWXRMEvent extends IBaseEvent {
  type: PersonEventType.CWXRM;

  batch?: string;
  csv_id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  group?: string;
}

export type PersonEvent =
  IBornEvent
  | IDiedEvent
  | IExtraEvent
  | IHospitalizedEvent
  | IInjuredEvent
  | IJailedEvent
  | IMarriageEvent
  | IMemoratedEvent
  | IMilitaryEvent
  | ISchoolEvent
  | IWhereEvent
  | IWorkEvent
  | IDecoratedEvent
  | ICWXRMEvent;

export function isRemovablePersonEvent(event: PersonEvent) {
  return event.type !== PersonEventType.Born && event.type !== PersonEventType.Died;
}

interface PersonEventTypeInfo {
  type: PersonEventType;
  icon: Icon;
  name: TranslationKey;
  nameIn: TranslationKey;
  plural: TranslationKey;
}
const EVENT_TYPES: PersonEventTypeInfo[] = [
  {
    type: PersonEventType.Born,
    icon: Icon.Circle,
    name: 'event.born',
    nameIn: 'event.born.in',
    plural: 'events.born'
  },
  {
    type: PersonEventType.School,
    icon: Icon.GraduationCap,
    name: 'event.school',
    nameIn: 'event.school.in',
    plural: 'events.school'
  },
  {
    type: PersonEventType.Work,
    icon: Icon.Tools,
    name: 'event.work',
    nameIn: 'event.work.in',
    plural: 'events.work'
  },
  {
    type: PersonEventType.Where,
    icon: Icon.House,
    name: 'event.where',
    nameIn: 'event.where.in',
    plural: 'events.where'
  },
  {
    type: PersonEventType.Marriage,
    icon: Icon.RingsWedding,
    name: 'event.marriage',
    nameIn: 'event.marriage.in',
    plural: 'events.marriage'
  },
  {
    type: PersonEventType.Military,
    icon: Icon.Shield,
    name: 'event.military',
    nameIn: 'event.military.in',
    plural: 'events.military'
  },
  {
    type: PersonEventType.Injured,
    icon: Icon.Medkit,
    name: 'event.injured',
    nameIn: 'event.injured.in',
    plural: 'events.injured'
  },
  {
    type: PersonEventType.Hospitalized,
    icon: Icon.Ambulance,
    name: 'event.hospitalized',
    nameIn: 'event.hospitalized.in',
    plural: 'events.hospitalized'
  },
  {
    type: PersonEventType.Died,
    icon: Icon.Cross,
    name: 'event.died',
    nameIn: 'event.died.in',
    plural: 'events.died'
  },
  {
    type: PersonEventType.Memorated,
    icon: Icon.Monument,
    name: 'event.memorated',
    nameIn: 'event.memorated.in',
    plural: 'events.memorated'
  },
  {
    type: PersonEventType.Decorated,
    icon: Icon.Medal,
    name: 'event.decorated',
    nameIn: 'event.decorated.in',
    plural: 'events.decorated'
  },
  {
    type: PersonEventType.Extra,
    icon: Icon.Comment,
    name: 'event.extra',
    nameIn: 'event.extra.in',
    plural: 'events.extra'
  },
  {
    type: PersonEventType.Jailed,
    icon: Icon.Lock,
    name: 'event.jailed',
    nameIn: 'event.jailed.in',
    plural: 'events.jailed'
  },
  {
    type: PersonEventType.CWXRM,
    icon: Icon.Tag,
    name: 'event.cwxrm',
    nameIn: 'event.cwxrm.in',
    plural: 'events.cwxrm'
  }
];

const DEFAULT_EVENT_FIELDS = {
  _id: '',
  start_day: null,
  start_month: null,
  start_year: null,
  sort_start_date: '????????',
  end_day: null,
  end_month: null,
  end_year: null,
  sort_end_date: '????????',
  description: ''
};

export function createNewEvent(personId: string, eventType: PersonEventType): PersonEvent {
  switch (eventType) {
    case PersonEventType.Born:
      return Object.assign({
        type: PersonEventType.Born as PersonEventType.Born,
        person_id: personId
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.School:
      return Object.assign({
        type: PersonEventType.School as PersonEventType.School,
        person_id: personId,
        school_name: '',
        school_direction: ''
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Work:
      return Object.assign({
        type: PersonEventType.Work as PersonEventType.Work,
        person_id: personId,
        work_company_name: '',
        work_profession: ''
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Where:
      return Object.assign({
        type: PersonEventType.Where as PersonEventType.Where,
        person_id: personId,
        where_reason: WhereEventType.HomeAddress,
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Marriage:
      return Object.assign({
        type: PersonEventType.Marriage as PersonEventType.Marriage,
        person_id: personId,
        marriage_partner: ''
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Military:
      return Object.assign({
        type: PersonEventType.Military as PersonEventType.Military,
        person_id: personId,
        enlisted_reason: MilitaryEventType.ActiveService,
        enlisted_army: '',
        enlisted_regt: '',
        enlisted_unit: '',
        enlisted_nr: '',
        enlisted_number: '',
        enlisted_sub: '',
        enlisted_rank: '',
        military_entity_id: null,
        military_regiment_id: null
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Jailed:
      return Object.assign({
        type: PersonEventType.Jailed as PersonEventType.Jailed,
        person_id: personId,
        jailed_type: JailedType.Jailed
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Injured:
      return Object.assign({
        type: PersonEventType.Injured as PersonEventType.Injured,
        person_id: personId,
        injured_wound: '',
        injured_cause: ''
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Hospitalized:
      return Object.assign({
        type: PersonEventType.Hospitalized as PersonEventType.Hospitalized,
        person_id: personId,
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Died:
      return Object.assign({
        type: PersonEventType.Died as PersonEventType.Died,
        person_id: personId,
        death_reason: ''
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Memorated:
      return Object.assign({
        type: PersonEventType.Memorated as PersonEventType.Memorated,
        person_id: personId,
        memorial_id: null,
        memorial_kind: MemoratedKind.Current,
        memorial_ref: ''
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Decorated:
      return Object.assign({
        type: PersonEventType.Decorated as PersonEventType.Decorated,
        person_id: personId,
        decoration: '',
        citation: ''
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.Extra:
      return Object.assign({
        type: PersonEventType.Extra as PersonEventType.Extra,
        person_id: personId,
      }, DEFAULT_EVENT_FIELDS);
    case PersonEventType.CWXRM:
  }
  throw Error("Unknown event type: " + eventType);
}

export function getEventTypeInfo(eventType: PersonEventType): PersonEventTypeInfo {
  return EVENT_TYPES.find(x => x.type === eventType)
    || {
      type: eventType,
      icon: Icon.Comment,
      name: 'event.unknown',
      nameIn: 'event.unknown.in',
      plural: 'events.unknown'
    };
}

export function getEventIcon(eventType: PersonEventType): Icon {
  return getEventTypeInfo(eventType).icon;
}

export function getEventTypeName(eventType: PersonEventType): string {
  return T(getEventTypeInfo(eventType).name);
}

export function getEventTypePlural(eventType: PersonEventType): string {
  return T(getEventTypeInfo(eventType).plural);
}

export function getEventNameForPlace(eventType: PersonEventType, place: string): string {
  return T(getEventTypeInfo(eventType).nameIn, { place });
}

export function getEventSubType(event: PersonEvent): string | undefined {
  switch (event.type) {
    case PersonEventType.Where:
      return translateWhereEventType(event.where_reason);
    case PersonEventType.Military:
      return translateMilitaryEventType(event.enlisted_reason);
    case PersonEventType.Jailed:
      return translateJailedEventType(event.jailed_type);
    case PersonEventType.Memorated:
      if (event.memorial_kind === 'none' || (event.extend_memorial && event.extend_memorial.type !== 'cemetery'))
        return undefined;
      else
        return translateMemoratedKind(event.memorial_kind);
    default:
      return undefined;
  }
}

export interface EventInfoLine {
  key: string;
  title: string;
  value: string | JSX.Element;
}
export function getEventInfo(event: PersonEvent, links: boolean = true): EventInfoLine[] {
  const result: EventInfoLine[] = [];
  const { type } = event;
  if (type === PersonEventType.Decorated
    || type === PersonEventType.CWXRM
    || type === PersonEventType.Military
    || type === PersonEventType.Born
    || type === PersonEventType.Died) {
    if (event.start_day || event.start_month || event.start_year) {
      result.push({
        key: 'date',
        title: T('event.date'),
        value: (event.start_day || '?') + '/' + (event.start_month || '?') + '/' + (event.start_year || '?')
      });
    }
  } else {
    if (event.start_day || event.start_month || event.start_year) {
      result.push({
        key: 'dateFrom',
        title: T('event.startDate'),
        value: (event.start_day || '?') + '/' + (event.start_month || '?') + '/' + (event.start_year || '?')
      });
    }
    if (event.end_day || event.end_month || event.end_year) {
      result.push({
        key: 'dateTo',
        title: T('event.endDate'),
        value: (event.end_day || '?') + '/' + (event.end_month || '?') + '/' + (event.end_year || '?')
      });
    }
  }

  if (isPlace(event.extend_place)) {
    result.push({
      key: 'place',
      title: T('event.place'),
      value: links ? (
        <PageLink className='link' page={PageID.ViewPlace} param={event.place_id}>
          {new Place(event.extend_place).describe()}
        </PageLink>
      ) : new Place(event.extend_place).describe()
    });
  }
  if (event.topo) {
    result.push({
      key: 'topo',
      title: T('event.address'),
      value: event.topo
    });
  }
  if (event.type === PersonEventType.School) {
    if (event.school_name) {
      result.push({
        key: 'schoolName',
        title: T('event.schoolName'),
        value: event.school_name
      });
    }
    if (event.school_direction) {
      result.push({
        key: 'schoolDirection',
        title: T('event.schoolDirection'),
        value: event.school_direction
      });
    }
  }
  if (event.type === PersonEventType.Work) {
    if (event.work_company_name) {
      result.push({
        key: 'workCompanyName',
        title: T('event.workCompanyName'),
        value: event.work_company_name
      });
    }
    if (event.work_profession) {
      result.push({
        key: 'workProfession',
        title: T('event.workProfession'),
        value: event.work_profession
      });
    }
  }
  if (event.type === PersonEventType.Military) {
    if (event.enlisted_army) {
      result.push({
        key: 'militaryArmy',
        title: T('event.militaryArmy'),
        value: event.enlisted_army
      });
    }
    if (event.enlisted_rank) {
      result.push({
        key: 'militaryRank',
        title: T('event.militaryRank'),
        value: event.enlisted_rank
      });
    }
    if (event.enlisted_number) {
      result.push({
        key: 'militaryNumber',
        title: T('event.militaryServiceNumber'),
        value: event.enlisted_number
      });
    }
    if (event.enlisted_regt) {
      result.push({
        key: 'militaryRegt',
        title: T('event.militaryRegiment'),
        value: (links && event.military_regiment_id)
          ? <PageLink className='link' page={PageID.ViewMilitaryEntity} param={event.military_regiment_id}>{event.enlisted_regt}</PageLink>
          : event.enlisted_regt
      });
    }
    if (event.enlisted_unit || event.enlisted_nr) {
      const unit = event.enlisted_nr + ' ' + event.enlisted_unit;
      result.push({
        key: 'militaryUnit',
        title: T('event.militaryUnitAndNr'),
        value: (links && event.military_entity_id)
          ? <PageLink className='link' page={PageID.ViewMilitaryEntity} param={event.military_entity_id}>{unit}</PageLink>
          : unit
      });
    }
    if (event.enlisted_sub) {
      result.push({
        key: 'militarySub',
        title: T('event.militaryUnitSub'),
        value: event.enlisted_sub
      });
    }
  }
  if (event.type === PersonEventType.Injured) {
    if (event.injured_wound) {
      result.push({
        key: 'injuredWound',
        title: T('event.injuredWound'),
        value: event.injured_wound
      });
    }
    if (event.injured_cause) {
      result.push({
        key: 'injuredCause',
        title: T('event.injuredCause'),
        value: event.injured_cause
      });
    }
  }
  if (event.type === PersonEventType.Died) {
    if (event.death_reason) {
      result.push({
        key: 'reason',
        title: T('event.diedReason'),
        value: event.death_reason
      });
    }
  }
  if (event.type === PersonEventType.Memorated) {
    if (event.memorial_id) {
      const memorialName = event.extend_memorial ? event.extend_memorial.name : '#' + event.memorial_id;
      result.push({
        key: 'memorial',
        title: T('event.memoratedMemorial'),
        value: links ? (
          <PageLink className='link' page={PageID.ViewMemorial} param={event.memorial_id}>
            {memorialName}
          </PageLink>
        ) : memorialName
      });
    }
    if (event.memorial_ref) {
      result.push({
        key: 'memorialRef',
        title: T('event.memoratedRef'),
        value: event.memorial_ref
      });
    }
  }
  if (event.type === PersonEventType.Decorated) {
    if (event.decoration) {
      result.push({
        key: 'decoratedDecoration',
        title: T('event.decoratedDecoration'),
        value: event.decoration
      });
    }
    if (event.citation) {
      result.push({
        key: 'decoratedCitation',
        title: T('event.decoratedCitation'),
        value: event.citation
      });
    }
  }

  if (event.type === PersonEventType.CWXRM) {
    if (event.first_name) {
      result.push({
        key: 'cwxrmFirstName',
        title: T('event.cwxrmFirstName'),
        value: event.first_name
      });
    }
    if (event.last_name) {
      result.push({
        key: 'cwxrmFirstName',
        title: T('event.cwxrmLastName'),
        value: event.last_name
      });
    }
    if (event.email) {
      result.push({
        key: 'cwxrmFirstName',
        title: T('event.cwxrmMail'),
        value: event.email
      });
    }
    if (event.group) {
      result.push({
        key: 'cwxrmFirstName',
        title: T('event.cwxrmGroup'),
        value: event.group
      });
    }
  }

  if (event.description) {
    result.push({
      key: 'description',
      title: T('event.description'),
      value: <div dangerouslySetInnerHTML={markdown2html(event.description)} />
    });
  }
  return result;
}

export const personEventSorter = (a: PersonEvent, b: PersonEvent) => {
  const ta = getEventTypeMajorPriority(a);
  const tb = getEventTypeMajorPriority(b);

  if (ta !== tb)
    return ta - tb;

  if (!(a.start_year === b.start_year || !a.start_year || !b.start_year))
    return a.start_year - b.start_year;

  if (!(a.start_month === b.start_month || !a.start_month || !b.start_month))
    return a.start_month - b.start_month;

  if (!(a.start_day === b.start_day || !a.start_day || !b.start_day))
    return a.start_day - b.start_day;

  const ma = getEventTypeMinorPriority(a);
  const mb = getEventTypeMinorPriority(b);
  if (ma !== mb)
    return ma - mb;

  if (a.type === PersonEventType.Military && b.type === PersonEventType.Military) {
    const xa = getEnlistedPriority(a);
    const xb = getEnlistedPriority(b);
    return xa - xb;
  } else if (a.type === PersonEventType.Where && b.type === PersonEventType.Where) {
    const xa = getWherePriority(a);
    const xb = getWherePriority(b);
    return xa - xb;
  } else if (a.type === PersonEventType.Memorated && b.type === PersonEventType.Memorated) {
    const xa = getMemoratedPriority(a);
    const xb = getMemoratedPriority(b);
    return xa - xb;
  }

  return 0;
};

// Major priority is main sorting criterion - events will always be sorted with this priority
export function getEventTypeMajorPriority(event: PersonEvent) {
  return getMajorPriorityForEventType(event.type)
};

export function getMajorPriorityForEventType(eventType: PersonEventType) {
  switch (eventType) {
    case PersonEventType.Born:
      return 0;
    case PersonEventType.School:
      return 1;
    case PersonEventType.Work:
      return 1;
    case PersonEventType.Where:
      return 1;
    case PersonEventType.Marriage:
      return 1;
    case PersonEventType.Military:
      return 2;
    case PersonEventType.Jailed:
      return 2;
    case PersonEventType.Injured:
      return 2;
    case PersonEventType.Hospitalized:
      return 2;
    case PersonEventType.Died:
      return 5;
    case PersonEventType.Memorated:
      return 6;
    case PersonEventType.Decorated:
      return 7;
    case PersonEventType.CWXRM:
      return 8;
    case PersonEventType.Extra:
      return 9;
    default:
      return -1;
  }
};


// Minor priority is sorting used when dates are equal or unknown
export function getEventTypeMinorPriority(event: PersonEvent) {
  switch (event.type) {
    case PersonEventType.Born:
      return 0;
    case PersonEventType.School:
      return 1;
    case PersonEventType.Work:
      return 2;
    case PersonEventType.Where:
      return 3;
    case PersonEventType.Marriage:
      return 4;
    case PersonEventType.Military:
      return 5;
    case PersonEventType.Jailed:
      return 6;
    case PersonEventType.Injured:
      return 7;
    case PersonEventType.Hospitalized:
      return 8;
    case PersonEventType.Died:
      return 9;
    case PersonEventType.Memorated:
      return 10;
    case PersonEventType.Decorated:
      return 11;
    case PersonEventType.CWXRM:
      return 12;
    case PersonEventType.Extra:
      return 13;
    default:
      return -1;
  }
};


function getWherePriority(event: IWhereEvent) {
  switch (event.where_reason) {
    case 'homeaddress':
      return 1;
    default:
      return 2;
  }
};


function getEnlistedPriority(event: IMilitaryEvent) {
  switch (event.enlisted_reason) {
    case 'enlisted':
      return 1;
    case 'posted to':
      return 2;
    case 'active service':
      return 3;
    case 'attached to':
      return 4;
    default:
      return 5;
  }
};


function getMemoratedPriority(event: IMemoratedEvent) {
  if (event.extend_memorial && event.extend_memorial.type === MemorialType.Cemetery) {
    switch (event.memorial_kind) {
      case 'original':
        return 1;
      case 'current':
        return 2;
      default:
        return 3;
    }
  } else {
    return 4;
  }
};
