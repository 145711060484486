import React, { useMemo, useState, useEffect } from 'react';
import { PageProps } from '../Page';
import { ListPage, ListState } from '../ListPage';
import { Icon } from '../../components/Icons';
import { T, plural } from '../../Translate';
import { getColumns, rowKey } from '../ViewCollection/Columns';
import { IQueryResult } from '../../models/Generic';
import { Document, DocumentCategory } from '../../models/Document';
import { usePageState } from '../../redux/Actions';
import api from '../../api';
import { useDocumentTagDefinitions } from '../../FunctionalAPI';
import { DocumentTagTypeInput, getFullTagName } from '../../components/inputs/DocumentTagTypeInput';
import { DocumentTagDefinition } from '../../models/DocumentTagDefinition';
import { classes } from '../../utils/Styles';
import { PageID } from '../../utils/PageLinking';
import AddDocumentationControl from './AddDocumentationControl';
import AddAdlibDocumentModal from './AddAdlibDocumentModal';
import { CheckAdlibCodeResult } from '../../models/CheckAdlibCodeResult';
import { TextStyles } from "../../config/styles";

export interface ListDocumentationPageState extends ListState {
  tagFilter: string|null;
}

const PageSize = 10;
export default (props: PageProps) => {

  const { loading, history, via, modals } = props;

  /*** state ***/
  const [pageState, updatePageState] = usePageState('documentation');
  const tagDefinitions = useDocumentTagDefinitions(loading);
  const [items, setItems] = useState<IQueryResult<Document>>();

  /*** variables ***/
  const { filter, tablePage, sortColumn, sortDirection, tagFilter } = pageState;
  const columns = useMemo(() => getColumns(), []);
  const tag = tagFilter === null ? undefined : tagDefinitions.indexed[tagFilter];

  /*** effects ***/

  useEffect(() => {
    if (tagDefinitions.list.length === 0)
      return;

    loading.loading(api.findCollectionDocuments(
      tagFilter ? { 'tags.tag': tagFilter } : {},
      filter,
      tablePage * PageSize,
      PageSize,
      [[getSortColumn(sortColumn), sortDirection], ['_id', sortDirection]],
      ['collection']
    ), plural('document'))
    .then(result => {
      setItems({
        total: result.total,
        data: result.data.map(document => {
          const newDocument = Document.fromJSON(document, tagDefinitions);
          return newDocument;
        })
      });
    });
  }, [loading, filter, tablePage, sortColumn, sortDirection, tagFilter, tagDefinitions]);

  /*** table handlers ***/

  const handleClickedRow = (row: Document) => {
    history.push(`/documents/${row.id}`);
  };

  const handleTagSelected = (tag: DocumentTagDefinition|undefined) => {
    updatePageState({ tagFilter: tag ? tag.id : null, tablePage: 0 });
  };

  const handleClickedClearTag = () => {
    updatePageState({ tagFilter: null, tablePage: 0 });
  };

  const handleClickedAdd = async (category: DocumentCategory) => {
    if (category === DocumentCategory.Adlib) {
      const check = await modals.show<CheckAdlibCodeResult|undefined>(
        props => <AddAdlibDocumentModal {...props} />
      );
      if (check === undefined)
        return;

      if (check.type === 'document') {
        history.push(`/documents/create?category=${DocumentCategory.Adlib}&code=${check.code}`);
      } else if (check.type === 'collection') {
        history.push(`/documents/create?category=${DocumentCategory.AdlibCollectionDocument}&code=${check.code}`);
      }
    } else {
      history.push(`/documents/create?category=${category}`);
    }
  };

  const getSortColumn = (column: string) => {
    switch (column) {
      case 'title':
        return 'sort_title'
      case 'category':
        return 'category'
      default:
        return column;
    }
  }


  /*** rendering ***/

  const additionalHeader = <>
    <div style={{ width: 500, display: 'flex', flexDirection: 'row', justifyItems: 'flex-start', alignItems: 'baseline' }} >
      {tag ? (
        <div style={{flexGrow: 1}}>
          <span style={{...TextStyles.label.medium, marginRight: 6}}>{T('generic.filter') + ':'}</span>
          <span style={{...TextStyles.text.medium}}>{getFullTagName(tagDefinitions, tag)}</span>
          {' '}
          <i onClick={handleClickedClearTag} className={classes(Icon.TimesCircle, 'clickable-icon')} />
        </div>
      ) : (
        <div style={{flexGrow: 1}}>
        <DocumentTagTypeInput
          onSelected={handleTagSelected}
          placeholder={T('document.tags.filter')}
        />
        </div>
      )}
    </div>
    <div style={{ flexGrow: 1 }} />
    <AddDocumentationControl onClickedAdd={handleClickedAdd} />
  </>;

  return (
    <ListPage
      id={PageID.ListDocumentation}
      icon={Icon.FilesMedical}
      title={T('page.documentation.title')}
      via={via}
      noun='document'
      rowKey={rowKey}
      columns={columns}
      items={items}
      state={pageState}
      updateState={updatePageState}
      onClickedRow={handleClickedRow}
      additionalHeader={additionalHeader}
    />
  );
}
