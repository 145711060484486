import React, { CSSProperties, useState } from 'react';
import $ from 'jquery';
import 'blueimp-file-upload';
import { T } from '../Translate';
import { IAttachment } from '../models/Attachment';
import api from '../api';
import { ProgressBar } from 'react-bootstrap';

import styles from './FileUploader.module.scss';
import { Icon } from './Icons';
import { classes } from '../utils/Styles';
import { IDocument } from '../models/Document';
import { IDocumentTag } from '../models/DocumentTag';
import { IDocumentReference } from '../models/DocumentReference';

/**
 * Initializes the upload component.
 *
 * The upload component should be a piece of HTML with this structure: (for example)
 *      <span id="personUploadDiv" class="pull-left btn btn-success btn-orange fileinput-button">
 *          Add Files
 *          <input type="file" name="files[]" multiple="">
 *      </span>
 *
 *  params: {
 *      formData: { data to be sent with the upload },
 *      start: function(data) to be called upon upload start,
 *      progress: function(data) to be called upon upload progress,
 *      success: function(data) to be called upon successful completion,
 *      fail: function(data) to be called upon upload failure,
 *      finish: function(data) to be called upon finish no matter the status
 *  }
 *
 * @param target target element
 * @param params upload parameters
 */

($ as any).widget('blueimp.fileupload', ($ as any).blueimp.fileupload, {
  processActions: {
    validate: function (data: any, options: any) {
      if (options.disabled)
        return data;

      var dfd = $.Deferred();
      var file = data.files[data.index];

      if (!options.acceptFileTypes.test(file.type)) {
        file.error = 'Invalid file type.';
        dfd.rejectWith(this, [data]);
      }
      else {
        dfd.resolveWith(this, [data]);
      }
      return dfd.promise();
    }
  }
});


export interface FileUploadParams {
  token: string;
  _id?: string;
  collection?: string;
  position?: string;
}
interface FileUploaderComponentProps {
  url: string;
  data: any;

  error?: string;
  prompt?: string;

  style?: CSSProperties;
  onStart?: (e: unknown) => void;
  onProgress?: (e: unknown) => void;
  onSuccess?: (e: unknown) => void;
  onFail?: (e: unknown) => void;
  onFinish?: (e: unknown) => void;
  disabled?: boolean;
}
export class FileUploaderComponent extends React.PureComponent<FileUploaderComponentProps> {
  ref: React.RefObject<HTMLInputElement>;

  constructor(props: FileUploaderComponentProps) {
    super(props);

    this.ref = React.createRef<HTMLInputElement>();
  }

  componentDidMount() {
    const target = this.ref.current;
    if (target)
      this.initUpload(target);
  }

  componentDidUpdate(oldProps: FileUploaderComponentProps) {
    if (oldProps.disabled !== this.props.disabled) {
      this.ref.current && ($(this.ref.current) as any).fileupload(this.props.disabled ? 'disable' : 'enabled');
    }
  }

  initUpload(target: HTMLInputElement) {
    const {
      url,
      data,

      onStart,
      onProgress,
      onSuccess,
      onFail,
      onFinish
    } = this.props;

    ($(target) as any).fileupload({
      url,
      dataType: 'json',
      paramName: 'file',
      formData: data,
      submit: function (e: any, data: any) {
        var $this = $(this) as any;
        api.getAuthenticatedUser().then(() => {
          data.jqXHR = $this.fileupload('send', data);
        });
        return false;
      },    
      progress: (e: any, data: any) => {
        onProgress && onProgress(data);
      }
    });

    //Bind upload event callbacks
    $(target)
      .bind('fileuploaddone', function (e, data) {
        onSuccess && onSuccess(data);
      })
      .bind('fileuploadsend', function (e, data) {
        onStart && onStart(data);
      })
      .bind('fileuploadalways', function (e, data) {
        onFinish && onFinish(data);
      })
      .bind('fileuploadfail', function (e, data) {
        onFail && onFail(data);
      });
  }

  render() {
    const { style, prompt } = this.props;
    return (
      <div ref={this.ref} className={classes(styles.fileinputButton, styles.uploadGroup)} style={style}>
        <i className={Icon.PlusSquare} style={{ fontSize: 20 }} />
        {prompt || T('component.fileUploader.addFiles')}
        <input type="file" name="files[]" multiple />
      </div>
    );
  }
}

interface FileUploaderProps {
  url: string;
  data: any;
  style?: CSSProperties;
  prompt?: string;
  buttonStyle?: CSSProperties;
  onSaved: (result: any) => void;
}
export const FileUploader = (props: FileUploaderProps) => {
  const {
    url,
    data,
    style,
    buttonStyle,
    prompt,
    onSaved
  } = props;
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [failed, setFailed] = useState(false);
  const handleStart = () => {
    setUploading(true);
    setFailed(false);
  };
  const handleProgress = (e: any) => {
    setProgress(e.loaded / e.total * 100);
  };
  const handleSuccess = (e: any) => {
    setUploading(false);
    onSaved(e.result);
  };
  const handleFail = () => {
    setUploading(false);
    setFailed(true);
  };

  return (
    <div style={style}>
      <FileUploaderComponent
        url={url}
        data={data}
        style={uploading ? Object.assign({ display: 'none' }, buttonStyle) : buttonStyle}
        error={failed ? T('component.fileUploader.failed') : undefined}
        prompt={prompt}
        onStart={handleStart}
        onProgress={handleProgress}
        onSuccess={handleSuccess}
        onFail={handleFail}
      />
      {uploading && <div className={styles.uploadGroup} style={buttonStyle}>
        <ProgressBar max={100} now={progress} style={{ width: 100 }} />
        <span>
          {T('component.fileUploader.uploading') + ': ' + progress.toFixed(0) + '%'}
        </span>
      </div>}
    </div>
  );
}

interface AttachmentFileUploaderProps {
  collectionId?: string;
  collection?: string;
  position?: string;
  style?: CSSProperties;
  prompt?: string;
  buttonStyle?: CSSProperties;
  onSaved: (attachment: IAttachment[]|'NOK') => void;
}
export const AttachmentFileUploader = (props: AttachmentFileUploaderProps) => {
  const {
    collectionId,
    collection,
    position,
    style,
    prompt,
    buttonStyle,
    onSaved
  } = props;

  const data = {
    _id: collectionId,
    collection,
    position
  } as any;
  if (api.token)
    data.token = api.token;
  
  return (
    <FileUploader
      url={api.uploadAttachmentUrl}
      data={data}
      style={style}
      prompt={prompt}
      buttonStyle={buttonStyle}
      onSaved={onSaved}
    />
  );
}

interface PageFileUploaderProps {
  documentId: string;
  documentElement: number;
  feedbackSession?: string;
  style?: CSSProperties;
  prompt?: string;
  buttonStyle?: CSSProperties;
  onSaved: (document: IDocument|'NOK') => void;
}
export const PageFileUploader = (props: PageFileUploaderProps) => {
  const {
    documentId,
    documentElement,
    feedbackSession,
    style,
    prompt,
    buttonStyle,
    onSaved
  } = props;

  const data = {
    document_id: documentId,
    document_element: documentElement,
    feedback_session: feedbackSession
  } as any;
  if (api.token)
    data.token = api.token;

  return (
    <FileUploader
      url={api.uploadPageUrl}
      data={data}
      style={style}
      prompt={prompt}
      buttonStyle={buttonStyle}
      onSaved={onSaved}
    />
  );
}

interface DocumentFileUploaderProps {
  tags: IDocumentTag[];
  references?: IDocumentReference[];
  feedbackSession?: string;
  style?: CSSProperties;
  prompt?: string;
  buttonStyle?: CSSProperties;
  onSaved: (document: IDocument|'NOK') => void;
}
export const DocumentFileUploader = (props: DocumentFileUploaderProps) => {
  const {
    tags,
    references,
    feedbackSession,
    style,
    prompt,
    buttonStyle,
    onSaved
  } = props;

  const data = {
    token: api.token,
    tags: JSON.stringify(tags),
    references: references && JSON.stringify(references),
    feedback_session: feedbackSession
  };

  return (
    <FileUploader
      url={api.uploadDocumentUrl}
      data={data}
      style={style}
      prompt={prompt}
      buttonStyle={buttonStyle}
      onSaved={onSaved}
    />
  );
}
