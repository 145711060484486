import {useState, useEffect, useCallback} from 'react';
import {PageProps, Page} from '../Page';
import {Icon} from '../../components/Icons';
import {Button} from 'react-bootstrap';
import {MapMarkerInputValue} from '../../components/inputs/MapMarkerInput';
import api from '../../api';
import {showNotification} from '../../utils/Notification';
import {BottomButtonRow} from '../../components/ButtonRow';
import {useKeyLocation} from '../../FunctionalAPI';
import {useStateWithError} from '../../utils/Functional';
import {PageID, getPageVia} from '../../utils/PageLinking';
import {IKeyLocation} from '../../models/KeyLocation';
import {ValidationError} from '../../models/ValidationResult';
import {IAttachment} from '../../models/Attachment';
import {KeyLocationTab} from './KeyLocationTab';
import {TopTab, TopTabs} from '../../components/TopTabs';
import {LocationStoriesTab} from './LocationStoriesTab';
import {Language} from "../../models/PersonStory2";
import {T} from "../../Translate";

interface EditKeyLocationProps extends PageProps {
  id?: string;
}

const LoadOptions: ('photos' | 'stories')[] = ['photos', 'stories'];

export default function EditKeyLocation(props: EditKeyLocationProps) {
  const {loading, id, history, via} = props;

  const user = api.user;
  const keyLocation = useKeyLocation(loading, id, LoadOptions);
  const title = id ? T('page.keyLocation.title.edit') : T('page.keyLocation.title.create');
  const icon = Icon.Key;

  const [name, setName, nameError, setNameError] = useStateWithError('');
  const [position, setPosition] = useState<MapMarkerInputValue>(MapMarkerInputValue.empty());
  const [positionError, setPositionError] = useState<string>();
  const [year, setYear, yearError, setYearError] = useStateWithError('');
  const [number, setNumber, numberError, setNumberError] = useStateWithError('0');
  const [positionLocked, setPositionLocked] = useState(false);


  const emptyLanguages: Record<Language, string> = {
    [Language.NL]: '',
    [Language.EN]: '',
    [Language.FR]: '',
    [Language.DE]: ''
  };


  const [notes, setNotes] = useState<Record<Language, string>>(emptyLanguages);
  const [photos, setPhotos] = useState<IAttachment[]>([]);

  const clearForm = () => {
    setName('');
    setPosition(MapMarkerInputValue.empty());
    setYear('');
    setNotes(emptyLanguages);
    setPhotos([]);
    setNumber('0');
    setPositionLocked(false);
  };

  useEffect(() => {
    if (keyLocation) {
      setName(keyLocation.name);
      setPosition(MapMarkerInputValue.fromValue({
        lat: keyLocation.position.lat,
        lng: keyLocation.position.lon
      }));
      setPositionError(undefined);
      setYear(keyLocation.year.toString());
      setNotes(keyLocation.notes);
      setPhotos(keyLocation.photos || []);
      setNumber(keyLocation.number.toString());
      setPositionLocked(keyLocation.positionLocked);
    } else {
      clearForm();
    }
  }, [keyLocation]); // eslint-disable-line react-hooks/exhaustive-deps
  // dependencies verified - and not necessary here:
  // - setters never change
  // - clearForm only uses setters, too, thus all instances of clearForm are equivalent

  const createNewKeyLocation = () => {
    if (id) {
      history.push('/key_locations/create');
    } else {
      clearForm();
    }
  };

  const handleClickedRemoveAttachment = useCallback((attachment: IAttachment) => {
    api.removeAttachment(attachment._id).then(() => {
      setPhotos(photos => photos.filter(x => x._id !== attachment._id));
    });
  }, []);
  const handleCreatedAttachment = useCallback((attachment: IAttachment) => {
    setPhotos(photos => [...photos, attachment]);
  }, []);

  const save = (createNew: boolean) => {

    let hasErrors = false;
    if (!/^[0-9]+$/.exec(year)) {
      setYearError(ValidationError.InvalidYear);
      hasErrors = true;
    }
    console.log(position);
    if (!position.valid) {
      setPositionError(T('page.keyLocation.error.positionInvalid'));
      hasErrors = true;
    } else if (!position.lastValidPosition) {
      setPositionError(T('page.keyLocation.error.positionNotSet'));
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }

    const yearAsInt = parseInt(year);
    const numberAsInt = parseInt(number);
    const data: Omit<IKeyLocation, '_id'> & { _id?: string } = {
      _id: id,
      name,
      number: numberAsInt,
      position: {lat: position.lastValidPosition!.lat, lon: position.lastValidPosition!.lng},
      year: yearAsInt,
      notes,
      positionLocked
    };

    api.validateKeyLocation(data, id ? 'update' : 'create').then(result => {
      if (result.status === 'NOK') {
        const errors = result.data || {};
        setNameError(errors.name);
        setYearError(errors.year);
        if (errors.position === ValidationError.InvalidPosition) {
          setPositionError(T('page.keyLocation.error.positionInvalid'));
        }
        return;
      }

      if (id) {
        const dataWithId = Object.assign(data, {_id: id});
        api.updateKeyLocation(dataWithId)
          .then(() => {
            showNotification({message: T('page.keyLocation.updated')});
            if (createNew)
              createNewKeyLocation();
          });
      } else {
        api.createKeyLocation(data)
          .then(keyLocation => {
            showNotification({message: T('page.keyLocation.created')});
            if (createNew) {
              createNewKeyLocation();
            } else {
              history.push(`/key_locations/${keyLocation._id}/edit`);
            }
          });
      }
    });
  }

  const handleClickedSave = () => {
    save(false);
  };

  const tabs: TopTab[] = [
    {
      icon: Icon.Key,
      title: T('page.keyLocation.tab.keyLocation'),
      id: 'location',
      content: () => (
        <KeyLocationTab
          keyLocation={keyLocation}
          name={name}
          nameError={nameError}
          position={position}
          positionError={positionError}
          year={year}
          yearError={yearError}
          notes={notes}
          photos={photos}
          number={number}
          positionLocked={positionLocked}
          onNameChange={setName}
          onPositionChange={position => {
            setPosition(position);
            setPositionError(undefined);
          }}
          onYearChange={setYear}
          onNotesChange={setNotes}
          onNumberChange={setNumber}
          onPositionLockedChange={setPositionLocked}
          onPhotoCreated={handleCreatedAttachment}
          onPhotoDeleted={handleClickedRemoveAttachment}
        />
      )
    }, {
      icon: Icon.Link,
      title: T('page.keyLocation.tab.stories'),
      id: 'linkedStories',
      content: () => (
        <LocationStoriesTab
          history={history}
          keyLocation={keyLocation}
          refresh={() => {/* TODO */
          }}
        />
      )
    }
  ];

  return (
    <Page
      id={id ? PageID.EditMemorial : PageID.CreateMemorial}
      entity={id}
      icon={icon}
      title={title}
      shortTitle={T('generic.breadcrumb.edit')}
      via={via || getPageVia(PageID.ListKeyLocations, id)}
      viaTitle={via ? undefined : (keyLocation ? keyLocation.name : '')}
    >
      <TopTabs
        id='edit-keylocation-tabs'
        defaultActiveKey='location'
        tabs={tabs}
      />
      <BottomButtonRow>
        <Button variant='primary' onClick={handleClickedSave}>
          {T('generic.action.save')}
        </Button>
      </BottomButtonRow>
    </Page>
  );
}
