import React from "react";
import { ButtonLink } from "../../../components/ButtonLink";

interface StringsListProps {
  data: string[]
  onClick: (selected: number) => void;
}

export const StringsListView = (props: StringsListProps ) => {
  const buttonLinkStyle = {
    fontSize:'14px',
    textDecorationLine: 'underline',
    marginLeft: 3,
    marginRight: 3
  }

  return <>
    {props.data.map((el, index) => (
      <ButtonLink key={index} onClick={() => {props.onClick(index)}} style={buttonLinkStyle}>
        {el}
      </ButtonLink>)
    )}
  </>;
}
