import React, { CSSProperties } from 'react';
import { StoryStatus } from "../../../models/PersonStory2";
import { T } from "../../../Translate";
import { Form } from "react-bootstrap";
import { InvalidFeedback } from "../../../components/inputs/InvalidFeedback";
import { PersonStoryStatusView } from "./PersonStoryStatusView";


export interface StoriesStatusProps {
  storyStatus?: StoryStatus
  labelText?: string
  infoText?: string
  error?: string
  readOnly: boolean;
  onUpdateStatus: (newStatus: StoryStatus) => void
}

const labelStyle: CSSProperties = {
  fontSize: 14,
  color: '#9A9A9A',
  textTransform: "uppercase",
}

const controlStyle: CSSProperties = {
  marginRight: 50,
  color: '#9A9A9A',
}

const textStyle: CSSProperties = {
  color: '#9A9A9A',
}

export const StoriesStatus = (props: StoriesStatusProps) => {
  const { storyStatus, error, readOnly, onUpdateStatus } = props;

  /** render **/

  const formControlClassName =  error ? 'form-control is-invalid' : 'form-control';
  const labelText = props.labelText ?? T('generic.status')
  const infoText = props.infoText
  const currentStoryStatus = storyStatus || StoryStatus.IN_PROGRESS

  return (
    <Form>
      <Form.Group controlId="status">
        <Form.Label style={labelStyle}>{labelText}<PersonStoryStatusView storyStatus={currentStoryStatus}/></Form.Label>
        {infoText && (
          <Form.Text style={textStyle}>
            {infoText}
          </Form.Text>
        )}
        <Form.Control
          value={storyStatus || StoryStatus.IN_PROGRESS}
          style={controlStyle}
          as="select"
          className={formControlClassName}
          onChange={e => onUpdateStatus(e.currentTarget.value as StoryStatus)}
          disabled={readOnly}
        >
          <option value={StoryStatus.IN_PROGRESS}>{T("personstories.status.inprogress")}</option>
          <option value={StoryStatus.IN_REVIEW}>{T("personstories.status.inreview")}</option>
          <option value={StoryStatus.DONE}>{T("personstories.status.done")}</option>
        </Form.Control>
        {error && <InvalidFeedback error={error}/>}
      </Form.Group>
    </Form>
  )
};
