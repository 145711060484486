import React from 'react';
import { Icon } from '../../components/Icons';
import { ResolutionStatus, getResolutionStatusColor } from '../../models/Place';
import { useElementID } from '../../utils/ElementID';
import { SecondaryButton } from '../../components/SecondaryButton';
import { T } from "../../Translate";

interface PlaceSearchInputProps {
  resolutionStatus: ResolutionStatus;
  name: string;
  value: string;
  onChange: (value: string) => void;
  onClickedGoogle: () => void;
  onClickedGeonames: () => void;
  error?: string;
}
export const PlaceSearchInput = (props: PlaceSearchInputProps) => {
  const {
    resolutionStatus,
    name,
    value,
    onChange,
    onClickedGoogle,
    error,
  } = props;

  const id = useElementID('input');
  const resolutionStatusColor = getResolutionStatusColor(resolutionStatus);

  return (
    <div className='form-group row'>
      <label htmlFor={id} className='col-sm-3 control-label'>
        <i className={Icon.MapMarker} style={{ color: resolutionStatusColor }} />
        {' '}
        {T('place.name')}:
      </label>
      <div className={`col-sm-5`}>
        <input
          id={id}
          name={name}
          className='form-control'
          type="text"
          value={value}
          onChange={e => onChange(e.currentTarget.value)}
          placeholder='Naam'
        />
        {error && (
          <div className="invalid-feedback" style={{display: 'inherit'}}>
            {error}
          </div>
        )}
      </div>
      <div className='col-sm-4'>
        <SecondaryButton onClick={onClickedGoogle}>
          Google!
        </SecondaryButton>
      </div>
    </div>
  );
}
