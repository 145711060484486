import React from 'react';
import { ISelectOption } from './SelectInput';
import { Col, FormLabel } from 'react-bootstrap';
import { useElementID } from '../../utils/ElementID';

interface RadioInputProps {
  label?: string;
  value: string;
  options: ISelectOption[];
  onChange: (value: string) => void;
  horizontal?: boolean;
  error?: string;
  labelCols?: number;
  valueCols?: number;
}
export const RadioInput = (props: RadioInputProps) => {
  const {
    label,
    labelCols = 4,
    value,
    valueCols = 4,
    options,
    onChange,
    horizontal = false
  } = props;

  const handleChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked)
      onChange(e.currentTarget.value);
  };

  const name = useElementID('options');
  const optionsRendered = options.map(option => {
    const content = (
      <label className='form-check-label'>
        <input
          className='form-check-input'
          type='radio'
          name={name}
          value={option.value}
          checked={value === option.value}
          onChange={handleChanged}
        />
        <span className='form-check-sign' />
        {option.title}
      </label>
    );
    if (horizontal) {
      return (
        <Col sm={valueCols} key={option.value}  className='form-check form-check-radio'>
          {content}
        </Col>
      )
    } else {
      return (
        <div key={option.value} className='form-check form-check-radio'>
          {content}
        </div>
      );
    }
  });

  if (horizontal) {
    return (
      <div className='row-group row'>
        {label && <FormLabel className={`col-sm-${labelCols}`} style={{ paddingTop: 12 }}>{label}</FormLabel>}
        {optionsRendered}
      </div>
    );
  } else {
    return (
      <div className='row-group row'>
        <div className='offset-sm-4 col-sm-8'>
          {optionsRendered}
        </div>
      </div>
    );
  }
}
