import React, { CSSProperties } from 'react';
import { IPersonStory2, Language } from "../../../models/PersonStory2";
import { Form } from "react-bootstrap";
import { PersonStoryWrapper } from "../../../models/wrappers/PersonStoryWrapper";
import { InvalidFeedback } from "../../../components/inputs/InvalidFeedback";


export enum StoryIndex {
  STORY_1,
  STORY_2,
  STORY_CHILD_1,
  STORY_CHILD_2
}

export interface StoryTextViewProps {
  name: string;
  personStory?: IPersonStory2;
  labelText: string;
  error?: string;
  language: Language;
  storyIndex: StoryIndex;
  readOnly: boolean;
  onUpdateStory: (personStory: Partial<IPersonStory2>) => void;
}


export const StoryTextView = (props: StoryTextViewProps) => {

  const labelStyle: CSSProperties = {
    fontSize: 14,
    color: '#9A9A9A',
    textTransform: "uppercase",
  }

  const textStyle: CSSProperties = {
    color: '#9A9A9A',
  }

  const { personStory, error, readOnly, onUpdateStory, labelText, language } = props;

  /** event handling **/

  const onTextChanged = (newText: string) => {
    const maxLength = getMaximumNumberOfChars(props.storyIndex)
    if (newText.length > maxLength)
      return

    updateStoryWithText(newText, props.storyIndex)
    if (personStory !== undefined) {
      onUpdateStory(personStory)
    }
  }

  /** Helper functions **/

  const updateStoryWithText = (newText: string, index: StoryIndex) => {
    if (personStory === undefined)
      return;
    const personStoryWrapper = new PersonStoryWrapper(personStory)
    personStoryWrapper.updateStoryWithText(newText, index, language)
  }

  const getCounter = (index: StoryIndex) => {
    if (!personStory)
      return '???'
    const textLength = getStory(index).length
    const maxLength = getMaximumNumberOfChars(index)
    return `${textLength}/${maxLength}`
  }

  const getStory = (index: StoryIndex) => {
    const personStoryWrapper = new PersonStoryWrapper(personStory!)
    return personStoryWrapper.getStory(index,language)
  }

  const getMaximumNumberOfChars = (index: StoryIndex) => {
    if (index === StoryIndex.STORY_1 || index === StoryIndex.STORY_2) {
      return 1000
    }
    return 750
  }

  const formControlClassName = error ? 'form-control is-invalid' : 'form-control';

  return (
    <Form>
    <Form.Group controlId="exampleForm.ControlTextarea1">
      <Form.Label style={labelStyle}>{labelText}</Form.Label>
      <Form.Control
        name={props.name}
        value={getStory(props.storyIndex)}
        className={formControlClassName}
        as="textarea"
        rows={10}
        disabled={readOnly}
        onChange={e => onTextChanged(e.currentTarget.value)}
      />
      <Form.Text style={textStyle}>
        {getCounter(props.storyIndex)}
      </Form.Text>
      {error && <InvalidFeedback error={error}/>}
    </Form.Group>
    </Form>
  )
};
