import React, { useMemo, useEffect, useState } from 'react';
import { IMilitaryEntity, IMilitaryEntityQuery, MilitaryEntityType } from '../../models/MilitaryEntity';
import { IQueryResult } from '../../models/Generic';
import api from '../../api';
import { Icon } from '../../components/Icons';
import { T, plural } from '../../Translate';
import {
  ITableColumn,
  StringTableColumn,
  StringFieldTableColumn,
  ActionsTableColumn,
  TableRowActionStyle, ComponentTableColumn
} from '../../components/table/Fields';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ConfirmationModal } from '../../modals/Confirmation';
import { ListState, ListPage } from '../ListPage';
import { usePageState } from '../../redux/Actions';
import { PageProps } from '../Page';
import { useModalContext } from '../../modals/ModalContext';
import { useRefresh } from '../../utils/Functional';
import { PageID } from '../../utils/PageLinking';

export enum ListMilitaryEntityKind {
  RegimentOrUnit = 'minor',
  Division = 'division',
  Brigade = 'brigade'
}

export interface ListMilitaryEntitiesStateProps extends ListState {
  kind: ListMilitaryEntityKind;
}

function getQuery(kind: ListMilitaryEntityKind): IMilitaryEntityQuery {
  switch (kind) {
    default:
    case ListMilitaryEntityKind.RegimentOrUnit:
      return { type: { '$in': [MilitaryEntityType.Regiment, MilitaryEntityType.Unit] } };
    case ListMilitaryEntityKind.Brigade:
      return { type: MilitaryEntityType.Brigade };
    case ListMilitaryEntityKind.Division:
      return { type: MilitaryEntityType.Division };
  }
}

function getSortColumn(column: string, kind: ListMilitaryEntityKind) {
  if (column === 'name') {
    switch (kind) {
      default:
      case ListMilitaryEntityKind.RegimentOrUnit:
        return 'regiment';
      case ListMilitaryEntityKind.Brigade:
        return 'brigade';
      case ListMilitaryEntityKind.Division:
        return 'division';
    }
  }

  return column;
}

const PageSize = 10;
const rowKey = (item: IMilitaryEntity) => item._id;

export default (props: PageProps) => {
  const { history, loading, via } = props;
  const modals = useModalContext();
  const [pageState, updatePageState] = usePageState('militaryEntities');
  const { filter, tablePage, sortColumn, sortDirection, kind } = pageState;

  const [items, setItems] = useState<IQueryResult<IMilitaryEntity>>();

  const user = api.user;
  const refresh = useRefresh(() => {
    loading.loading(api.findMilitaryEntities(
      getQuery(kind),
      filter,
      tablePage * PageSize,
      PageSize,
      [[getSortColumn(sortColumn, kind), sortDirection]]
    ), plural('militaryEntity'))
      .then(setItems);
  });
  useEffect(refresh, [kind, filter, tablePage, sortColumn, sortDirection]);

  const handleClickedAdd = () => {
    history.push('/military_entities/create');
  }

  const columns = useMemo(() => {
    const handleClickedRemove = async (item: IMilitaryEntity) => {
      const confirmed = await modals.show<boolean>(props => (
        <ConfirmationModal
          title={T('page.militaryEntities.remove.title')}
          message={T('page.militaryEntities.remove.message')}
          warning={T('generic.cannotUndo')}
          acceptStyle='danger'
          acceptText={T('generic.action.remove')}
          rejectText={T('generic.action.cancel')}
          {...props}
        />
      ));
      if (!confirmed)
        return;

      await api.removeMilitaryEntity(item._id);
      refresh();
    };

    let columns: ITableColumn<IMilitaryEntity>[] = [];
    switch (kind) {
      case ListMilitaryEntityKind.RegimentOrUnit:
        columns = [
          new ComponentTableColumn('name', 'Regiment/Korps', item => <strong>{item.regiment || item.corps || ''}</strong>, {clickable: true, sortable: true}),
          new ComponentTableColumn('unit', 'Eenheid', item => <span style={{fontWeight: 500}}>{(item.unit_nr || '') + ' ' + (item.unit || '')}</span>, {clickable: true, sortable: true}),
          new StringFieldTableColumn('army', 'Strijdmacht', { width: 120 })
        ];
        break;
      case ListMilitaryEntityKind.Brigade:
        columns = [
          new ComponentTableColumn('name', 'Brigade/Groepering', item => <strong>{item.brigade || item.corps || ''}</strong>, {clickable: true}),
          new StringFieldTableColumn('army', 'Strijdmacht', { width: 120 })
        ];
        break;
      case ListMilitaryEntityKind.Division:
        columns = [
          new ComponentTableColumn('name', 'Divisie', item => <strong>{item.division || ''}</strong>, {clickable: true}),
          new StringFieldTableColumn('army', 'Strijdmacht', { width: 120 })
        ];
        break;
    }

    if (user.canEdit()) {
      columns.push(
        new ActionsTableColumn('actions', 'Acties', item => [
          { icon: Icon.Edit, page: PageID.EditMilitaryEntity, pageParam: item._id },
          { icon: Icon.TimesCircle, onClick: handleClickedRemove, style: TableRowActionStyle.Danger }
        ], { width: 100 })
      );
    }
    return columns;
  }, [modals, refresh, kind, user])


  const handleClickedRow = (item: IMilitaryEntity) => {
    history.push(`/military_entities/${item._id}`);
  };

  const handleClickedRegimentsAndUnits = () => {
    updatePageState({ kind: ListMilitaryEntityKind.RegimentOrUnit });
  };

  const handleClickedBrigades = () => {
    updatePageState({ kind: ListMilitaryEntityKind.Brigade });
  };

  const handleClickedDivisions = () => {
    updatePageState({ kind: ListMilitaryEntityKind.Division });
  };

  const additionalHeader = (
    <ButtonGroup>
      <Button
        variant='outline-primary'
        active={kind === ListMilitaryEntityKind.RegimentOrUnit}
        onClick={handleClickedRegimentsAndUnits}
      >
        {T('page.militaryEntities.kind.regimentOrUnit')}
      </Button>
      <Button
        variant='outline-primary'
        active={kind === ListMilitaryEntityKind.Brigade}
        onClick={handleClickedBrigades}
      >
        {T('page.militaryEntities.kind.brigades')}
      </Button>
      <Button
        variant='outline-primary'
        active={kind === ListMilitaryEntityKind.Division}
        onClick={handleClickedDivisions}
      >
        {T('page.militaryEntities.kind.divisions')}
      </Button>
    </ButtonGroup>
  );

  return (
    <ListPage
      id={PageID.ListMilitaryEntities}
      icon={Icon.Sitemap}
      title={T('page.militaryEntities.title')}
      via={via}
      noun='militaryEntity'
      columns={columns}
      items={items}
      state={pageState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedRow={handleClickedRow}
      onClickedAdd={handleClickedAdd}
      additionalHeader={additionalHeader}
    />
  );
}
