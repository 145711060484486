import React from 'react';
import BaseModal, { ModalProps, BaseModalState } from '../../modals/BaseModal';
import { IUserFields, UserType, Dataset, IUser, UserTypeNames, DatasetNames } from '../../models/User';
import { TextInput } from '../../components/inputs/TextInput';
import { T } from '../../Translate';
import { Form, Modal, Button } from 'react-bootstrap';
import { SelectInput, ISelectOption } from '../../components/inputs/SelectInput';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import api from '../../api';
import { getErrorDescription } from '../../models/ValidationResult';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { ModalHeader } from '../../modals/ModalHeader';

interface EditUserModalProps extends ModalProps<IUserFields> {
  user: IUser|undefined;
}
interface EditUserModalState extends BaseModalState {
  username: string;
  usernameError?: string;
  type: UserType;
  typeError?: string;
  language: string;
  languageError?: string;
  dataset: Dataset;
  datasetError?: string;
  name: string;
  nameError?: string;
  email: string;
  emailError?: string;
  phone: string;
  phoneError?: string;
  description: string;
  descriptionError?: string;
}

export class EditUserModal extends BaseModal<IUserFields, EditUserModalProps, EditUserModalState> {
  typeOptions: ISelectOption[];
  languageOptions: ISelectOption[];
  datasetOptions: ISelectOption[];

  constructor(props: EditUserModalProps) {
    super(props);

    this.typeOptions = UserTypeNames.map(t => ({ value: t[0], title: T(t[1]) }));

    this.languageOptions = [
      { value: 'nl', title: T('language.nl') },
      { value: 'fr', title: T('language.fr') },
      { value: 'en', title: T('language.en') },
      { value: 'de', title: T('language.de') }
    ];

    this.datasetOptions = DatasetNames.map(x => ({ value: x[0], title: T(x[1]) }));

    var user: IUserFields = props.user || {
      type: UserType.Normal,
      dataset: Dataset.Any,
      account: '',
      name: '',
      email: '',
      phone: '',
      description: '',
      language: 'nl',
    };
    this.state = {
      visible: true,

      username: user.account,
      type: user.type,
      language: user.language,
      dataset: user.dataset,
      name: user.name,
      email: user.email,
      phone: user.phone,
      description: user.description
    };
  }

  handleUsernameChanged = (username: string) => {
    this.setState({
      username,
      usernameError: undefined
    });
  }

  handleTypeChanged = (value: string) => {
    this.setState({
      type: value as UserType,
      typeError: undefined
    });
  }

  handleLanguageChanged = (value: string) => {
    this.setState({
      language: value,
      languageError: undefined
    });
  }

  handleDatasetChanged = (value: string) => {
    this.setState({
      dataset: value as Dataset,
      datasetError: undefined
    });
  }

  handleNameChanged = (name: string) => {
    this.setState({
      name,
      nameError: undefined
    });
  }

  handleEmailChanged = (email: string) => {
    this.setState({
      email,
      emailError: undefined
    });
  }

  handlePhoneChanged = (phone: string) => {
    this.setState({
      phone,
      phoneError: undefined
    });
  }

  handleDescriptionChanged = (description: string) => {
    this.setState({
      description,
      descriptionError: undefined
    });
  }

  handleClickedSave = () => {
    const { user } = this.props;
    const {
      type,
      dataset,
      username,
      name,
      email,
      phone,
      description,
      language
    } = this.state;

    const data: IUserFields & { _id?: string } = {
      _id: user ? user._id : undefined,
      type,
      dataset,
      account: username,
      name,
      email,
      phone,
      description,
      language
    };

    api.validateUser(data, user ? 'update' : 'create').then(result => {
      if (result.status === 'OK') {
        this.resolve(data);
        return;
      }

      const errors = result.data || {};
      this.setState({
        typeError: getErrorDescription(errors.type,),
        datasetError: getErrorDescription(errors.dataset),
        usernameError: getErrorDescription(errors.account),
        nameError: getErrorDescription(errors.name),
        emailError: getErrorDescription(errors.email),
        phoneError: getErrorDescription(errors.phone),
        descriptionError: getErrorDescription(errors.description),
        languageError: getErrorDescription(errors.language)
      });
    });
  }

  renderModal() {
    const { user } = this.props;
    const {
      username,
      usernameError,
      type,
      typeError,
      language,
      languageError,
      dataset,
      datasetError,
      name,
      nameError,
      email,
      emailError,
      phone,
      phoneError,
      description,
      descriptionError
    } = this.state;

    return <>
      <ModalHeader>
        {user ? T('createUser.editTitle') : T('createUser.createTitle')}
      </ModalHeader>
      <Modal.Body>
        <Form>
          <TextInput
            name='username'
            label={T('createUser.username')}
            value={username}
            onChange={this.handleUsernameChanged}
            error={usernameError}
          />
          <SelectInput
            name='type'
            label={T('createUser.type')}
            value={type}
            onChange={this.handleTypeChanged}
            options={this.typeOptions}
            error={typeError}
          />
          <SelectInput
            name='language'
            label={T('createUser.language')}
            value={language}
            onChange={this.handleLanguageChanged}
            options={this.languageOptions}
            error={languageError}
          />
          <SelectInput
            name='dataset'
            label={T('createUser.dataset')}
            value={dataset}
            onChange={this.handleDatasetChanged}
            options={this.datasetOptions}
            error={datasetError}
          />
          <TextInput
            name='name'
            label={T('createUser.name')}
            value={name}
            onChange={this.handleNameChanged}
            error={nameError}
          />
          <TextInput
            name='email'
            label={T('createUser.email')}
            value={email}
            onChange={this.handleEmailChanged}
            error={emailError}
          />
          <TextInput
            name='phone'
            label={T('createUser.phone')}
            value={phone}
            onChange={this.handlePhoneChanged}
            error={phoneError}
          />
          <TextareaInput
            name='description'
            label={T('createUser.description')}
            value={description}
            onChange={this.handleDescriptionChanged}
            error={descriptionError}
          />
        </Form>
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='secondary' onClick={this.handleHide}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={this.handleClickedSave}>
          {T('generic.action.save')}
        </Button>
      </ButtonRowModalFooter>
    </>;
  }
}
