import { ButtonLink } from "../../../components/ButtonLink";
import * as React from "react";
import { Icon, IconElement } from "../../../components/Icons";
import { Row } from "react-bootstrap";


interface EventLinkProps {
  selected: boolean
  text: string
  onClick?: () => void
}

export const EventLinkView = (props: EventLinkProps ) => {
  const { selected, text, onClick } = props;

  const buttonLinkStyle = {
    fontSize:'14px',
    textDecorationLine: 'underline'
  }

  const renderCheckIcon = ()  => {
    if (selected) {
      return <>
        <span style={{marginLeft: '5px'}}></span>
        <IconElement icon={Icon.CheckCircle} style={{color: '#9A9A9A', fontSize:'14px'}}/>
        </>
    } else {
      return
    }
  }

  return <>
    <Row className="align-items-center">
      <ButtonLink className="text-decoration-" onClick={onClick} style={buttonLinkStyle}>
        {text}
      </ButtonLink>
      {renderCheckIcon()}
    </Row>
  </>
}
