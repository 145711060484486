import React, { useState } from 'react';
import API, { IAuthenticationResponse } from '../../api';
import { T } from '../../Translate';
import { LoadingIcon, Icon } from '../../components/Icons';
import { Alert, Button, Card, Container, Form, Row } from 'react-bootstrap';
import { PageProps, usePageHeader } from '../Page';
import { VerticalInput } from '../../components/inputs/VerticalInput';
import { Link } from 'react-router-dom';
import { PageID } from '../../utils/PageLinking';

export default (props: PageProps) => {
  const { loading, pending, history } = props;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  usePageHeader(PageID.Login, undefined, Icon.Page, 'login', 'login');

  const handleClickedLogin = async () => {
    try {
      const loginData = await loading.pending<IAuthenticationResponse>(
        API.login(username, password),
        'pending.loggingIn',
        ''
      );
      API.loginWith(loginData);
      setError('');
      history.push('/');
    } catch (error: any) {
      if (error.code === 2)
        setError(T('page.login.invalidLogin'));
      else if (error.code === 8)
        setError(T('page.login.accountNotActivated'));
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Card style={{ marginTop: 100, width: 350 }}>
          <Card.Body style={{ padding: '2rem' }}>
            <h4 style={{ textAlign: 'center', fontSize: 17.5, fontWeight: 'bold' }}>{T('page.login.title')}</h4>
            <hr />
            <p style={{ textAlign: 'center' }}>{T('page.login.message')}</p>
            <Form>
              <VerticalInput
                name='username'
                label={T('page.login.username')}
                value={username}
                onChange={setUsername}
                placeholder={T('page.login.username')}
              />
              <VerticalInput
                name='password'
                label={T('page.login.password')}
                value={password}
                onChange={setPassword}
                placeholder={T('page.login.password')}
                onPressedEnter={handleClickedLogin}
                type='password'
              />
              <p style={{ textAlign: 'center' }}><Link to='requestPasswordReset'>{T('page.login.forgotPassword')}</Link></p>
              {error && <Alert variant="danger">{error}</Alert>}
              <div style={{ textAlign: 'center', paddingTop: 20 }}>
                <Button variant='primary' onClick={handleClickedLogin}>
                  {T('generic.action.login')}
                  {pending.length > 0 && <span>&nbsp;<LoadingIcon /></span>}
                </Button>
              </div>
            </Form>
            <hr />
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}
