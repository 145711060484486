import { getActiveLanguage, T, TranslationKey } from "../Translate";
import { IPlace, Place } from "./Place";
import { IAttachment } from "./Attachment";

export enum MemorialType {
  FieldGrave = 'fieldgrave',
  Plate = 'plate',
  Cemetery = 'cemetery',
  Document = 'document',
}
export const MemorialTypes = [
  MemorialType.Plate,
  MemorialType.Cemetery,
  MemorialType.FieldGrave,
  MemorialType.Document
];
const MemorialTypeTranslations: { [key: string]: TranslationKey } = {
  [MemorialType.FieldGrave]: 'memorial.type.fieldgrave',
  [MemorialType.Plate]: 'memorial.type.plate',
  [MemorialType.Cemetery]: 'memorial.type.cemetery',
  [MemorialType.Document]: 'memorial.type.document'
}
export function translateMemorialType(type: MemorialType): string {
  return T(MemorialTypeTranslations[type]) || type;
}

export enum CemeteryKind {
  Military = 'military',
  Civilian = 'civilian'
}
export function translateCemeteryKind(kind: CemeteryKind): string {
  return kind === CemeteryKind.Military ? T('memorial.cemeteryKind.military') : T('memorial.cemeteryKind.civilian');
}

export enum PlateDetails {
  Private = 'private',
  Organisation = 'organisation',
  Community = 'community',
  Military = 'military'
}
const PlateDetailTranslations: { [key: string]: TranslationKey } = {
  [PlateDetails.Private]: 'memorial.plateKind.private',
  [PlateDetails.Organisation]: 'memorial.plateKind.organisation',
  [PlateDetails.Community]: 'memorial.plateKind.community',
  [PlateDetails.Military]: 'memorial.plateKind.military'
}
export function translatePlateDetails(plate: PlateDetails): string {
  return T(PlateDetailTranslations[plate]) || plate;
}

const PlateDetailTranslationsShort: { [key: string]: TranslationKey } = {
  [PlateDetails.Private]: 'memorial.plateKind.private',
  [PlateDetails.Organisation]: 'memorial.plateKind.organisationShort',
  [PlateDetails.Community]: 'memorial.plateKind.community',
  [PlateDetails.Military]: 'memorial.plateKind.military'
}
function translatePlateDetailsShort(plate: PlateDetails): string {
  return T(PlateDetailTranslationsShort[plate]) || plate;
}

export function describeMemorialKind(memorial: IMemorial): string {
  switch (memorial.type) {
    case MemorialType.Plate:
      return (memorial.type_plate_details ? translatePlateDetailsShort(memorial.type_plate_details) + ' ' : '') + T('memorial.type.plate');
    case MemorialType.Cemetery:
      // TODO: FORMER?
      return (memorial.type_cemetery_details ? translateCemeteryKind(memorial.type_cemetery_details.kind) + ' ' : '') + T('memorial.type.cemetery');
    default:
      return translateMemorialType(memorial.type);
  }
}

export function desribeMemorialPlace(memorial: IMemorial): string {
  if (!memorial.extend_place)
    return ''
  return new Place(memorial.extend_place).describe(getActiveLanguage())
}

export enum GetMemorialOption {
  NumberOfPersons = 'EXTEND_NBR_OF_PERSONS',
  PlaceNames = 'EXTEND_PLACE_NAMES',
  Place = 'EXTEND_PLACE',
  Attachments = 'EXTEND_ATTACHMENTS'
}

export interface IMemorialFields {
  type: MemorialType;
  type_cemetery_details: {
    kind: CemeteryKind,
    exists: boolean;
    nationality: string;
  }
  type_plate_details?: PlateDetails;
  name: string;
  alternative_names: string[];
  address: string;
  address_loc: { lat: number, lon: number };
  position_locked: boolean;
  description: string;
  start_day: string|null;
  start_month: string|null;
  start_year: string|null;
  start_date: string|null;
  calc_place_name: string;
  calc_place_loc: { lat: number, lon: number };
  calc_place_country: string;
  address_positioned: boolean;
  place_id?: string;
  british_trench_coordinates?: string;
}

export interface IMemorial extends IMemorialFields {
  _id: string;

  filter: string;

  extend_nbr_of_persons?: number;
  extend_place_names?: { [key: string]: string };
  extend_place?: IPlace;
  attachments?: IAttachment[];
}
