import React from 'react';
import { useElementID } from '../../utils/ElementID';

interface TextareaInputProps {
  labelColumns?: number;
  inputColumns?: number;

  name: string;
  label: string;
  value: string;
  error?: string;
  readOnly?: boolean;
  plainText?: boolean;
  validated?: boolean;
  placeholder?: string;
  rows?: number;
  vertical?: boolean;

  onChange?: (value: string) => void;
}

export const TextareaInput = (props: TextareaInputProps) => {
  const {
    labelColumns = 4,
    inputColumns,
    name,
    label,
    value,
    error,
    readOnly,
    plainText,
    validated,
    placeholder = label,
    vertical,
    rows,
    onChange,
  } = props;

  const id = useElementID('input');
  const inputClass = plainText
    ? 'form-control-plaintext'
    : (error ? 'form-control is-invalid' : (validated ? 'form-control is-valid' : 'form-control'));

  if (vertical) {
    return (
      <div className='form-group'>
        <label htmlFor={id}>{label}:</label>
        <textarea
          id={id}
          name={name}
          readOnly={readOnly || plainText}
          className={inputClass}
          value={value}
          onChange={e => onChange && onChange(e.currentTarget.value)}
          placeholder={placeholder}
          rows={rows}
        />
        {error && (
          <div
            className="invalid-feedback"
            style={{display: 'inherit'}}
          >
            {error}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="form-group row">
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}</label>
      <div className={`col-sm-${inputColumns || (12 - labelColumns)}`}>
        <textarea
          id={id}
          name={name}
          readOnly={readOnly || plainText}
          className={inputClass}
          value={value}
          onChange={e => onChange && onChange(e.currentTarget.value)}
          placeholder={placeholder}
          rows={rows}
        />
        {error && <div className="invalid-feedback" style={{display: 'inherit'}}>{error}</div>}
      </div>
    </div>
  );
};
