import { getActiveLanguage, T } from "../Translate";

export enum PlaceType {
  Unknown = 'unknown',
  Topo = 'topo',
  Sublocality = 'sublocality',
  Locality = 'locality',
  Neighborhood = 'neighborhood',
  ColloquialArea = 'colloquial_area',
  AdministrativeAreaLevel2 = 'administrative_area_level_2',
  AdministrativeAreaLevel1 = 'administrative_area_level_1',
  Country = 'country'
}

export const PlaceTypes = [
  PlaceType.Unknown,
  PlaceType.Topo,
  PlaceType.Sublocality,
  PlaceType.Locality,
  PlaceType.Neighborhood,
  PlaceType.ColloquialArea,
  PlaceType.AdministrativeAreaLevel2,
  PlaceType.AdministrativeAreaLevel1,
  PlaceType.Country
];

export enum ResolutionStatus {
  Ambiguous = 'ambiguous',
  Found = 'found',
  NotFound = 'not_found',
  Custom = 'not_resolved',
  Unresolved = 'unresolved',

  All = '__all__'
}

export function getResolutionStatusName(status: ResolutionStatus): string {
  switch (status) {
    case ResolutionStatus.Ambiguous: return T('resolutionStatus.ambiguous');
    case ResolutionStatus.Found: return T('resolutionStatus.found');
    case ResolutionStatus.Custom: return T('resolutionStatus.notResolved');
    case ResolutionStatus.NotFound: return T('resolutionStatus.notFound');
    case ResolutionStatus.All: return T('resolutionStatus.all');
    default:
      return T('resolutionStatus.unresolved');
  }
}

export function getResolutionStatusColor(status: ResolutionStatus): string {
  switch (status) {
    case ResolutionStatus.Ambiguous: return '#fd7e14';
    case ResolutionStatus.Found: return '#28a745';
    case ResolutionStatus.NotFound: return '#dc3545';
    case ResolutionStatus.Custom: return '#007bff';
    default: return '#cccccc';
  }
}

export interface IPlaceLanguage {
  name: string;
  sublocality: string|null;
  locality: string|null;
  administrative_area_level_2: string|null;
  administrative_area_level_1: string|null;
  country: string;
}

export const createEmptyPlaceLanguage = (): IPlaceLanguage => {
  return {
    name: '',
    sublocality: '',
    locality: '',
    administrative_area_level_1: '',
    administrative_area_level_2: '',
    country: ''
  }
}

export const createEmptyLanguages = (): {[language: string]: IPlaceLanguage} => {
  return {
    'nl': createEmptyPlaceLanguage(),
    'en': createEmptyPlaceLanguage(),
    'de': createEmptyPlaceLanguage(),
    'fr': createEmptyPlaceLanguage(),
  }
}

export interface IPlaceFields {
  type: PlaceType;
  country_code: string;
  languages: {[language: string]: IPlaceLanguage};
  alternative_names: string[];
  resolution_status: ResolutionStatus;
  loc?: { lat: number, lon: number };
}

export function toLatLng(value?: { lat: number, lon: number }) {
  return value && (value.lat || value.lon) ? { lat: value.lat, lng: value.lon } : undefined;
}
export function toLatLon(value?: { lat: number, lng: number }) {
  return value && { lat: value.lat, lon: value.lng };
}

export interface IPlace extends IPlaceFields {
  _id: string;

  memorials?: {_id: string, name: string}[];
  extend_events_by_type?: {[key: string]: number}; // keys are PersonEventType
}

export interface IPlaceSearchResult {
  match: IPlaceSearchResultEntry[];
  suggestions: IPlaceSearchResultEntry[];
}

export interface IPlaceSearchResultEntry {
  name: string;
  address: {
    sublocality?: string;
    locality?: string;
    administrative_area_level_2?: string;
    administrative_area_level_1?: string;
    country: string;
    country_code: string;
  },
  location: [number, number],
  type: PlaceType
}

export function isPlace(place: IPlace|{}|undefined): place is IPlace {
  return place && (place as any).type;
}

export class Place {
  place: IPlace;

  constructor(place: IPlace) {
    this.place = place;
  }

  get id() {
    return this.place._id;
  }

  get name() {
    return this.getTranslation().name;
  }

  get nameWithAlternatives() {
    const firstPart = this.name
    if (this.place.alternative_names.some(x => x.trim().length > 0)) {
      return firstPart + ` ( ${
        this.place.alternative_names.join(', ')
      } )`
    } else {
      return firstPart
    }
  }

  get status() {
    return this.place.resolution_status;
  }

  get type() {
    return this.place.type;
  }

  get countryCode() {
    return this.place.country_code;
  }

  describe(language?: string) {
    const translation = language ? this.place.languages[language] : this.getTranslation();
    let result = translation.name;
    if (translation.locality && translation.locality !== translation.name)
      result += ', ' + translation.locality;
    if (translation.administrative_area_level_2)
      result += ', ' + translation.administrative_area_level_2;
    if (translation.administrative_area_level_1)
      result += ', ' + translation.administrative_area_level_1;
    result += ', ' + this.place.country_code;
    return result;
  }

  describeHierarchy(language?: string) {
    const translation = language ? this.place.languages[language] : this.getTranslation();
    const components: string[] = [];
    if (translation.locality && translation.locality !== translation.name)
      components.push(translation.locality);
    if (translation.administrative_area_level_2)
      components.push(translation.administrative_area_level_2);
    if (translation.administrative_area_level_1)
      components.push(translation.administrative_area_level_1);
    components.push(this.place.country_code);
    return components.join(', ');
  }

  getTranslation() {
    return this.place.languages[getActiveLanguage()]
      || this.place.languages[Place.getPrimaryLanguage(this.place.country_code)];
  }

  hasLocation() {
    const { loc } = this.place;
    return loc && (loc.lat !== 0 || loc.lon !== 0);
  }

  static getPrimaryLanguage(countryCode: string) {
    switch (countryCode) {
      case 'BE':
      case 'NL':
        return 'nl';
      case 'FR':
        return 'fr';
      case 'DE':
        return 'de';
      default:
        return 'en';
    }
  }
}

export function getPlaceTypeName(placeType: PlaceType) {
  switch (placeType) {
    case PlaceType.Topo: return T('placeType.topo');
    case PlaceType.Sublocality: return T('placeType.sublocality');
    case PlaceType.Locality: return T('placeType.locality');
    case PlaceType.Neighborhood: return T('placeType.neighborhood');
    case PlaceType.ColloquialArea: return T('placeType.colloquialArea');
    case PlaceType.AdministrativeAreaLevel2: return T('placeType.area2');
    case PlaceType.AdministrativeAreaLevel1: return T('placeType.area1');
    case PlaceType.Country: return T('placeType.country');
    case PlaceType.Unknown:
    default:
      return T('placeType.unknown');
  }
}

export interface PlaceData {
  place_id?: string;
  topo?: string;
  original_place_name?: string;
  original_region?: string;
  original_country?: string;
  place_country_code?: string;
}

export const EXAMPLE_PLACE: IPlace = {
  "languages":{
     "fr":{
        "name":"Ypres",
        "locality":"Ypres",
        "country":"",
        "administrative_area_level_2":"Flandre Occidentale",
        "administrative_area_level_1":"R\u00e9gion Flamande",
        "sublocality":""
     },
     "en":{
        "name":"Ypres",
        "locality":"Ypres",
        "country":"",
        "administrative_area_level_2":"West Flanders",
        "administrative_area_level_1":"Flanders",
        "sublocality":""
     },
     "nl":{
        "name":"Ieper",
        "locality":"Ieper",
        "country":"",
        "administrative_area_level_2":"West-Vlaanderen",
        "administrative_area_level_1":"Vlaanderen",
        "sublocality":"Ieper"
     },
     "de":{
        "name":"Ypern",
        "locality":"Ypern",
        "country":"",
        "administrative_area_level_2":"Westflandern",
        "administrative_area_level_1":"Flanders",
        "sublocality":""
     }
  },
  "loc":{
     "lat":50.8492265,
     "lon":2.8779465
  },
  "country_code":"BE",
  "resolution_status": ResolutionStatus.Found,
  "_id":"1ba98a3a-a5ca-4e5e-bc5a-981bac76437a",
  "type": PlaceType.Sublocality,
  "alternative_names":[

  ]
};
