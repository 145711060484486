import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { SelectInput } from '../../components/inputs/SelectInput';
import {
  MilitaryEntityType,
  getMilitaryEntityTypeName,
  MilitaryEntityTypes,
  Armies
} from '../../models/MilitaryEntity';
import { TextInput } from '../../components/inputs/TextInput';
import { T } from "../../Translate";

export interface MilitaryEntityFormState {
  type: MilitaryEntityType;
  regiment: string;
  regimentError?: string;
  unitNr: string;
  unitNrError?: string;
  unit: string;
  unitError?: string;
  brigade: string;
  brigadeError?: string;
  division: string;
  divisionError?: string;
  army: string;
  armyError?: string;
}

interface MilitaryEntityFormProps {
  formState: MilitaryEntityFormState;
  setFormState: (state: MilitaryEntityFormState) => void;
}
export const MilitaryEntityForm = (props: MilitaryEntityFormProps) => {
  const { formState, setFormState } = props;

  const {
    type,
    regiment,
    regimentError,
    unitNr,
    unitNrError,
    unit,
    unitError,
    brigade,
    brigadeError,
    division,
    divisionError,
    army,
    armyError,
  } = formState;

  const typeOptions = useMemo(() => MilitaryEntityTypes
    .map(type => ({ value: type, title: getMilitaryEntityTypeName(type) })), []);
  const armyOptions = useMemo(() => Armies.map(army => ({ value: army, title: army })), []);

  const updateFormState = (updates: Partial<MilitaryEntityFormState>) => {
    setFormState(Object.assign({}, formState, updates));
  };

  const handleTypeChanged = (value: string) => {
    updateFormState({ type: value as MilitaryEntityType });
  };

  const handleRegimentChanged = (regiment: string) => {
    updateFormState({ regiment });
  };

  const handleUnitNrChanged = (unitNr: string) => {
    updateFormState({ unitNr });
  };

  const handleUnitChanged = (unit: string) => {
    updateFormState({ unit });
  };

  const handleBrigadeChanged = (brigade: string) => {
    updateFormState({ brigade });
  };

  const handleDivisionChanged = (division: string) => {
    updateFormState({ division });
  };

  const handleArmyChanged = (army: string) => {
    updateFormState({ army });
  };

  return (
    <Form>
      <SelectInput
        label={T('page.militaryEntity.type')}
        name='type'
        value={type}
        onChange={handleTypeChanged}
        options={typeOptions}
      />
      {type === MilitaryEntityType.Division && (
        <TextInput
          label={T('page.militaryEntity.division')}
          name='division'
          value={division}
          onChange={handleDivisionChanged}
          error={divisionError}
        />
      )}
      {type === MilitaryEntityType.Brigade && (
        <TextInput
          label={T('page.militaryEntity.brigade')}
          name='brigade'
          value={brigade}
          onChange={handleBrigadeChanged}
          error={brigadeError}
        />
      )}
      {(type === MilitaryEntityType.Unit || type === MilitaryEntityType.Regiment) && (
        <TextInput
          label={T('specialFunctions.params.regiment')}
          name='regiment'
          value={regiment}
          onChange={handleRegimentChanged}
          error={regimentError}
        />
      )}
      {type === MilitaryEntityType.Unit && (
        <TextInput
          label={T('specialFunctions.params.unitNr')}
          name='unit-nr'
          value={unitNr}
          onChange={handleUnitNrChanged}
          error={unitError}
        />
      )}
      {type === MilitaryEntityType.Unit && (
        <TextInput
          label={T('specialFunctions.params.unit')}
          name='unit'
          value={unit}
          onChange={handleUnitChanged}
          error={unitNrError}
        />
      )}
      <SelectInput
        label={T('specialFunctions.params.army')}
        name='army'
        value={army}
        onChange={handleArmyChanged}
        options={armyOptions}
        error={armyError}
      />
    </Form>
  );
}
