import React, { useMemo, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { T, plural } from '../../Translate';
import { DocumentCollection } from '../../models/Collection';
import { FormCheck } from '../../components/theme/FormCheck';
import { StringFieldTableColumn, ITableColumn, ComponentTableColumn } from '../../components/table/Fields';
import api from '../../api';
import { Icon } from '../../components/Icons';
import { ListState, ListPage } from '../ListPage';
import { PageProps } from '../Page';
import { usePageState } from '../../redux/Actions';
import { IQueryResult } from '../../models/Generic';
import { PageID } from '../../utils/PageLinking';

export interface ListCollectionsStateProps extends ListState {
  showRemoved: boolean;
}

const rowKey = (collection: DocumentCollection) => collection.id;
const PageSize = 10;

export default (props: PageProps) => {
  const { loading, history } = props;

  const columns: ITableColumn<DocumentCollection>[] = useMemo(() => [
    new StringFieldTableColumn('adlibCode', T('collections.column.adlibcode'), {width: 120}),
    new ComponentTableColumn('title', T('collections.column.title'), (documentCollection) => {
      return <strong>{documentCollection.title}</strong>
    })
  ], []);

  const [pageState, updatePageState] = usePageState('collections');
  const { filter, tablePage, sortColumn, sortDirection, showRemoved } = pageState;
  const [items, setItems] = useState<IQueryResult<DocumentCollection>>();

  useEffect(() => {
    loading.loading(api.findDocumentCollections(
      {},
      filter,
      tablePage * PageSize,
      PageSize,
      [[sortColumn, sortDirection]],
      showRemoved
    ), plural('collection'))
    .then(result => setItems({
      data: result.data.map(DocumentCollection.fromJSON),
      total: result.total
    }));
  }, [loading, filter, tablePage, sortColumn, sortDirection, showRemoved]);

  const handleClickedCreate = () => {
    history.push('/collections/create');
  };

  const handleCheckedRemoved = (e: React.SyntheticEvent<HTMLInputElement>) => {
    let checked = e.currentTarget.checked;
    updatePageState({ showRemoved: checked });
  };

  const handleClickedRow = (item: DocumentCollection) => {
    history.push(`/collections/${item.id}`);
  };

  const user = api.user;
  const header = <>
    {user.canEdit() && (
      <Button style={{ float: 'right' }} onClick={handleClickedCreate}>
        {T('generic.action.create')}
      </Button>
    )}
    <Form className='form-inline'>
      <FormCheck
        label={T('collection.showRemoved')}
        checked={showRemoved}
        onChange={handleCheckedRemoved}
        style={{ paddingTop: '0.6em', paddingRight: '1em' }}
      />
    </Form>
  </>;

  return (
    <ListPage
      id={PageID.ListCollections}
      icon={Icon.Box}
      title={T('page.collections.title')}
      noun='collection'
      columns={columns}
      items={items}
      state={pageState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedRow={handleClickedRow}
      header={header}
    />
  );
}
