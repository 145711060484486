import React, { DependencyList, EffectCallback, useEffect, useState } from "react";

export function useDelayedEffect(
  callback: React.EffectCallback,
  dependencies: React.DependencyList,
  delay: number) {
  return useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useDelayedEffectWithLoading(
  callback: () => Promise<unknown>,
  dependencies: React.DependencyList,
  delay: number
): boolean {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      callback().then(() => setLoading(false));
    }, delay);
    return () => clearTimeout(timeout);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
  return loading;
}
