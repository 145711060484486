import { ReactNode } from "react";

export function isChildrenEmpty(node: ReactNode) {
  if (node === null || node === false)
    return true;
  if (Array.isArray(node))
    return !node.some(x => x !== null && x !== false);
  
  return false;
}
