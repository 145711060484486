import React, { CSSProperties } from 'react';
import { IPlace, Place } from '../models/Place';
import { Icon } from './Icons';
import { T } from '../Translate';
import PageLink from './PageLink';
import { PageID } from '../utils/PageLinking';

interface PlaceLinkProps {
  place: IPlace;
  style?: CSSProperties
}
export const PlaceLink = (props: PlaceLinkProps) => {
  const { place, style } = props;
  return (
    <span>
      <i className={Icon.MapMarker} />{' '}
      <PageLink className='link' page={PageID.ViewPlace} style={style} param={place._id}>
        {new Place(place).describe()}
      </PageLink>
      {place.loc && <>
        {' '}
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${place.loc.lat},${place.loc.lon}`}
          title={T('linkToPositionOnGoogleMaps')}
          style={{fontSize: '0.75rem', marginLeft: '0.25rem', marginRight: '0.25rem'}}
          target={'window'}
        >
          ( {T('external.links.google.maps')} <i className={Icon.ExternalLink}/> )
        </a>
      </>}
    </span>
  );
}
