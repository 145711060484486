import React, { useState, useMemo } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { ModalProps, useModal } from '../../modals/BaseModal';
import { T, getActiveLanguage } from '../../Translate';
import { TextInput } from '../../components/inputs/TextInput';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { Icon } from '../../components/Icons';
import api from '../../api';
import { IPersonFeedbackFields, PersonFeedbackStatus } from '../../models/PersonFeedback';
import moment from 'moment';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { NotificationManager } from 'react-notifications';
import { useStateWithError } from '../../utils/Functional';
import { ModalHeader } from '../../modals/ModalHeader';
import { IDocument } from '../../models/Document';
import { IDocumentTag } from '../../models/DocumentTag';
import { DocumentUploadListView } from '../../components/DocumentUploadView';
import { DocumentReferenceType, DocumentReferenceRelation } from '../../models/DocumentReference';
import { Person } from '../../models/Person';

interface PersonFeedbackModalProps extends ModalProps<void> {
  person: Person;
}

export const PersonFeedbackModal = (props: PersonFeedbackModalProps) => {
  const { person } = props;
  const [isOpen, resolve] = useModal(props);
  const handleClose = () => resolve();

  const feedbackSession = useMemo(() => uuidv4(), []);
  const [firstName, setFirstName, firstNameError, setFirstNameError] = useStateWithError('');
  const [lastName, setLastName, lastNameError, setLastNameError] = useStateWithError('');
  const [email, setEmail, emailError, setEmailError] = useStateWithError('');
  const [message, setMessage, messageError, setMessageError] = useStateWithError('');
  const [documents, setDocuments] = useState<IDocument[]>([]);

  const tags: IDocumentTag[] = [
    {
      tag: 'unprocessedFeedback',
      fields: {
        person: {
          type: DocumentReferenceType.Person,
          _id: person.id,
          name: person.name,
          relation: DocumentReferenceRelation.None,
          comment: ''
        }
      }
    }
  ];

  const handleDeleteDocument = (document: IDocument) => {
    const newDocuments = documents.filter(x => x._id !== document._id);
    setDocuments(newDocuments);
  };

  const handleDocumentCreated = (document: IDocument) => {
    setDocuments([...documents, document]);
  };

  const handleDocumentUpdated = (document: IDocument) => {
    setDocuments(documents => documents.map(doc => doc._id === document._id ? document : doc));
  };

  const handleClickedSave = async () => {
    const data: IPersonFeedbackFields = {
      person_id: person.id,
      firstName,
      lastName,
      email,
      description: message,
      timestamp: moment().format('YYYY-MM-DDTHH:mm'),
      language: getActiveLanguage(),
      status: PersonFeedbackStatus.Submitted,
      documentIds: documents.map(document => document._id),
      feedback_session: feedbackSession
    };

    const result = await api.validatePersonFeedback(data, 'create');

    if (result.status === 'NOK') {
      const errors = result.data || {};
      setFirstNameError(errors.firstName);
      setLastNameError(errors.lastName);
      setEmailError(errors.email);
      setMessageError(errors.description);
    } else {
      await api.createPersonFeedback(data);
      NotificationManager.success(T('modal.personFeedback.submitted'));
      resolve();
    }
  };

  return (
    <Modal size='lg' show={isOpen} onHide={handleClose}>
      <ModalHeader>{T('modal.personFeedback.title')}</ModalHeader>
      <Modal.Body>
        <p>{T('modal.personFeedback.identity.intro')}</p>
        <Form>
          <TextInput
            name='first-name'
            label={T('modal.personFeedback.firstName')}
            placeholder={T('modal.personFeedback.firstName')}
            value={firstName}
            error={firstNameError}
            onChange={setFirstName}
          />
          <TextInput
            name='last-name'
            label={T('modal.personFeedback.lastName')}
            placeholder={T('modal.personFeedback.lastName')}
            value={lastName}
            error={lastNameError}
            onChange={setLastName}
          />
          <TextInput
            name='email'
            label={T('modal.personFeedback.email')}
            placeholder={T('modal.personFeedback.email')}
            value={email}
            error={emailError}
            onChange={setEmail}
          />
          <TextareaInput
            name='message'
            label={T('modal.personFeedback.message')}
            placeholder={T('modal.personFeedback.message.placeholder')}
            value={message}
            error={messageError}
            onChange={setMessage}
          />
        </Form>
        <p>{T('modal.personFeedback.files.intro')}</p>
        <DocumentUploadListView
          tags={tags}
          documents={documents}
          feedbackSession={feedbackSession}
          onClickedDelete={handleDeleteDocument}
          onCreated={handleDocumentCreated}
          onUpdated={handleDocumentUpdated}
        />
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='secondary' onClick={handleClose}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={handleClickedSave}>
          <i className={Icon.Check}/>
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
