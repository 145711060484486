import React from 'react';
import moment from 'moment';
import { BasePage, PageProps, PageState } from '../Page';
import { Col, Row, Button } from 'react-bootstrap';
import { FormCard } from '../../components/FormCard';
import { Icon } from '../../components/Icons';
import { T } from '../../Translate';
import { DateTimeInput, DateTimeInputValue, inputDateTimeToISO } from '../../components/inputs/DateTimeInput';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import { connect } from 'react-redux';
import { AppState } from '../../redux/State';
import { ActiveContactMoment } from '../../models/ActiveContactMoment';
import { Dispatch } from 'redux';
import {
  AnyAppAction,
  startContactMomentAction,
  endContactMomentAction,
  updateContactMomentAction
} from '../../redux/Actions';
import { BottomButtonRow } from '../../components/ButtonRow';
import { ConfirmationModal } from '../../modals/Confirmation';
import { NotificationManager } from 'react-notifications';
import api from '../../api';
import { ContactPersonEvent, ContactMomentContribution } from '../../models/ContactPerson';
import { ContributionList } from './ContributionList';
import { PageID } from '../../utils/PageLinking';

interface RecordContactMomentOwnProps extends PageProps {
}
interface RecordContactMomentStateProps {
  current?: ActiveContactMoment;
}
interface RecordContactMomentDispatchProps {
  start: (data: ActiveContactMoment) => void;
  update: (updates: Partial<ActiveContactMoment>) => void;
  stop: () => void;
}
interface RecordContactMomentState extends PageState {

}
type RecordContactMomentProps = RecordContactMomentOwnProps
  & RecordContactMomentStateProps
  & RecordContactMomentDispatchProps;

class RecordContactMomentPage extends BasePage<RecordContactMomentProps, RecordContactMomentState> {
  constructor(props: RecordContactMomentProps) {
    super(props);

    const person = props.current && props.current.person;
    const name = person ? (person.firstName + ' ' + person.lastName) : '...';
    this.state = {
      page: {
        id: PageID.RecordContactMoment,
        icon: Icon.User,
        title: T('page.recordContactMoment.title', { name }),
        via: person && ('contact_person:' + person._id),
        viaTitle: name
      }
    };
  }

  handleStartsAtChanged = (value: DateTimeInputValue) => {
    const { update } = this.props;
    update({ startsAt: value });
  }

  handleEndsAtChanged = (value: DateTimeInputValue) => {
    const { update } = this.props;
    update({ endsAt: value });
  }

  handleTranscriptChanged = (value: string) => {
    const { update } = this.props;
    update({ transcript: value });
  }

  handleClickedCancel = () => {
    this.showModal<boolean>(props => (
      <ConfirmationModal
        title={T('contactMoment.cancel.title')}
        message={T('contactMoment.cancel.message')}
        {...props}
      />
    )).then(confirmed => {
      if (confirmed) {
        const { history, stop, current } = this.props;
        if (!current)
          return;

        stop();
        history.push(`/contact_persons/${current.person._id}`)
      }
    });
  }

  handleDeleteContribution = (contribution: ContactMomentContribution) => {
    const { update, current } = this.props;
    if (!current)
      return;

    const newContributions = current.contributions.filter(c => c.targetId !== contribution.targetId);
    update({ contributions: newContributions });
  }

  handleInsertContribution = (contribution: ContactMomentContribution) => {
    const { update, current } = this.props;
    if (!current)
      return;

    let already_added_contribution = current.contributions.find(
      i => i.targetId === contribution.targetId
    )

    if (already_added_contribution === undefined) {
      update({contributions: [...current.contributions, contribution]});
    } else {
      NotificationManager.info(T('validationError.dataAlreadyExists'));
    }
  }

  handleClickedSave = () => {
    const { stop, current: contactMoment, history } = this.props;
    if (!contactMoment)
      return;

    const startDateTime = inputDateTimeToISO(contactMoment.startsAt);
    if (!startDateTime) {
      NotificationManager.error(T('contactMoment.invalidStart'))
      return;
    }
    api.createContactPersonEvent({
      type: 'contact_moment',
      contactPersonId: contactMoment.person._id,
      contactMethod: contactMoment.type,
      start: startDateTime,
      end: moment().format('YYYY-MM-DDTHH:mm'),
      contributions: contactMoment.contributions,
      transcript: contactMoment.transcript
    } as ContactPersonEvent).then(() => {
      stop();
      NotificationManager.success(T('contactMoment.stoppedSuccessfully'))
      history.push(`/contact_persons/${contactMoment.person._id}`)
    });
  }

  renderContent() {
    const { current } = this.props;
    if (!current)
      return <div />;

    const { startsAt, transcript, contributions } = current;

    return <>
      <Row style={{ marginTop: '1em' }}>
        <Col md={6}>
          <FormCard icon={Icon.Info} title={T('page.recordContactMoment.info')}>
            <DateTimeInput
              name='start'
              label={T('contactMoment.start')}
              labelColumns={3}
              value={startsAt}
              onChange={this.handleStartsAtChanged}
            />
            <TextareaInput
              name='transcript'
              label={T('contactMoment.transcript')}
              labelColumns={3}
              value={transcript}
              onChange={this.handleTranscriptChanged}
              rows={10}
            />
          </FormCard>
        </Col>
        <Col md={6}>
          <FormCard icon={Icon.ListUl} title={T('page.recordContactMoment.contributions')}>
            <ContributionList
              contributions={contributions}
              onDelete={this.handleDeleteContribution}
              onInsert={this.handleInsertContribution}
            />
          </FormCard>
        </Col>
      </Row>
      <BottomButtonRow>
        <Button variant='primary' onClick={this.handleClickedCancel}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={this.handleClickedSave}>
          {T('generic.action.save')}
        </Button>
      </BottomButtonRow>
    </>;
  }
}

const mapStateToProps = (state: AppState): RecordContactMomentStateProps => {
  return {
    current: state.activeContactMoment.current
  };
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAppAction>): RecordContactMomentDispatchProps => {
  return {
    start: data => dispatch(startContactMomentAction(data)),
    update: updates => dispatch(updateContactMomentAction(updates)),
    stop: () => dispatch(endContactMomentAction())
  };
}
const ConnectedPage = connect<
  RecordContactMomentStateProps,
  RecordContactMomentDispatchProps,
  RecordContactMomentOwnProps,
  AppState
>(mapStateToProps, mapDispatchToProps)(RecordContactMomentPage);
export default ConnectedPage;
