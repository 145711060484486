import React, { CSSProperties } from 'react';
import { T } from '../Translate';
import { classes } from '../utils/Styles';
import styles from './Search.module.scss';
import { Icon } from './Icons';
import { Button } from 'react-bootstrap';
import { TextStyles } from "../config/styles";

interface SearchProps {
  className?: string;
  name: string;
  margin?: number|string;
  placeholder?: string;
  style?: CSSProperties;
  iconTop?: string; // TODO: why don't things just stay in place?

  value: string;
  onChange: (value: string) => void;
}

export const Search = (props: SearchProps) => {
  const {
    className,
    name,
    iconTop,
    placeholder,
    style,
    value,
    onChange
  } = props;
  const handleClear = React.useCallback(() => onChange(""), [onChange]);

  return (
    <section className={classes(styles.section, className)} style={style}>
      <label htmlFor={name + '-input'} className={styles.label} style={{ top: iconTop }}>
        <i className="fa fa-search" aria-hidden="true" />
        <span className="sr-only">{T('component.search.action')}</span>
      </label>
      <input
        id={name + '-input'}
        name={name}
        className={classes('form-control', styles.input)}
        placeholder={placeholder || 'Search'}
        autoComplete="off"
        spellCheck="false"
        autoCorrect="off"
        tabIndex={1}
        value={value}
        onChange={e => onChange(e.currentTarget.value)}
      />
      {value && (
        <Button
          variant={'link'}
          onClick={handleClear}
          className={classes('hide', Icon.TimesCircle, styles.clear)}
          style={TextStyles.text.large}
          aria-hidden="true"
        >
          <span className="sr-only">{T('component.search.clear')}</span>
        </Button>
      )}
    </section>
  );
}
