
import React from 'react';
import styles from './ImageBrowser.module.scss';
import Measure, { BoundingRect, ContentRect } from 'react-measure';
import { Icon } from '../../components/Icons';
import { FullscreenImageView } from '../../components/FullscreenImageView';
import { IconButton, IconLink } from '../../components/IconButton';
import { getLargeIcon, FileType, isImageType } from '../../utils/MimeTypes';
import { T } from '../../Translate';

interface ImageViewerProps {
  filetype: FileType;
  url: string|null;
  originalUrl: string;
}
interface ImageViewerState {
  dimensions?: BoundingRect
  fullscreenOpen: boolean;
}
export class ImageViewer extends React.Component<ImageViewerProps, ImageViewerState> {
  canvas: HTMLCanvasElement|null;
  image: HTMLImageElement;

  dragging: boolean;
  offsetX: number;
  offsetY: number;
  scale: number;
  dragStartX: number;
  dragStartY: number;
  dragStartMouseX: number;
  dragStartMouseY: number;

  constructor(props: ImageViewerProps) {
    super(props);

    this.state = {
      fullscreenOpen: false
    };

    this.canvas = null;

    this.image = new Image();
    this.image.width = 200;
    this.image.height = 200;
    this.image.addEventListener('load', () => this.paint());
    if (this.props.url) {
      this.image.src = this.props.url;
    }

    this.dragging = false;
    this.offsetX = 0;
    this.offsetY = 0;
    this.scale = 1;

    this.dragStartX = 0;
    this.dragStartY = 0;
    this.dragStartMouseX = 0;
    this.dragStartMouseY = 0;
  }

  componentDidUpdate(prevProps: ImageViewerProps) {
    if (this.props.url && this.props.url !== prevProps.url) {
      this.image.src = this.props.url;
    }
  }

  setCanvas = (canvas: HTMLCanvasElement|null) => {
    this.canvas = canvas;
    if (!this.canvas)
      return;

    this.canvas.addEventListener('wheel', ((e: React.WheelEvent<HTMLCanvasElement>) => {
      e.preventDefault(); // prevent scrolling the page

      let rect = e.currentTarget.getBoundingClientRect();
      let relativeX = e.clientX - rect.left;
      let relativeY = e.clientY - rect.top;

      let newScale = e.deltaY > 0 ? this.scale * 0.9 : this.scale / 0.9;
      this.zoom(relativeX, relativeY, newScale);

      // if we're dragging while zooming, make sure things don't jump around
      // (this will "reset" drag variables as if a new drag operation has been started)
      this.dragStartX = this.offsetX;
      this.dragStartY = this.offsetY;
      this.dragStartMouseX = e.pageX;
      this.dragStartMouseY = e.pageY;
    }) as any); // here, swallow!
    this.paint();
  }

  zoom(relativeX: number, relativeY: number, scale: number) {
    // Calculation to zoom in at the cursor position:
    // the pixel at the given pointer position must point to the same before and after scaling
    // relativeX = offsetX + scale * documentX
    // relativeX = newOffsetX + newScale * documentX
    // we can substitute documentX:
    // documentX = (relativeX - offsetX) / scale = (relativeX - newOffsetX) / newScale
    // <=> (relativeX - offsetX) * newScale / scale = relativeX - newOffsetX
    // <=> newOffsetX = relativeX - (relativeX - offsetX) * newScale / scale
    // -- same calculation is valid for Y

    this.offsetX = relativeX - (relativeX - this.offsetX) * scale / this.scale;
    this.offsetY = relativeY - (relativeY - this.offsetY) * scale / this.scale;
    this.scale = scale;

    this.paint();
  }

  paint() {
    if (this.canvas === null)
      return;

    let context = this.canvas.getContext('2d');
    if (context === null)
      return;

    context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let width = this.canvas.width * this.scale;
    let height = width * this.image.naturalHeight / this.image.naturalWidth;
    context.drawImage(this.image, this.offsetX, this.offsetY, width, height);
  }

  handleClickedZoomIn = () => {
    if (this.canvas === null)
      return;

    this.zoom(this.canvas.clientWidth / 2, this.canvas.clientHeight / 2, this.scale / 0.9);
  }

  handleClickedZoomOut = () => {
    if (this.canvas === null)
      return;

    this.zoom(this.canvas.clientWidth / 2, this.canvas.clientHeight / 2, this.scale * 0.9);
  }

  handleClickedFullscreen = () => {
    this.setState({ fullscreenOpen: true });
  }

  handleClickedFullscreenClose = () => {
    this.setState({ fullscreenOpen: false });
  }

  handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    this.dragging = true;
    this.dragStartX = this.offsetX;
    this.dragStartY = this.offsetY;
    this.dragStartMouseX = e.pageX;
    this.dragStartMouseY = e.pageY;
  }

  handleMouseUp = (e: React.MouseEvent<HTMLElement>) => {
    this.dragging = false;
    this.paint();
  }

  handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (this.dragging) {
      this.offsetX = e.pageX - this.dragStartMouseX + this.dragStartX;
      this.offsetY = e.pageY - this.dragStartMouseY + this.dragStartY;
      this.paint();
    }
  }

  handleMouseOut = (e: React.MouseEvent<HTMLElement>) => {
    this.dragging = false;
  }

  handleDimensionsChanged = (contentRect: ContentRect) => {
    this.setState({ dimensions: contentRect.bounds });
    this.paint();
  }

  render() {
    const { filetype, url, originalUrl } = this.props;
    const { fullscreenOpen, dimensions } = this.state;
    const { width, height } = dimensions || { width: -1, height: -1 };
    const isImage = isImageType(filetype);
    return <>
      <Measure bounds onResize={this.handleDimensionsChanged}>
        {({ measureRef }) => (
          <div
            ref={measureRef}
            className={styles.viewerWrapper}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onMouseMove={this.handleMouseMove}
            onMouseOut={this.handleMouseOut}>
            <div className={styles.zoomout}>
              <IconButton
                icon={Icon.SearchMinus}
                onClick={this.handleClickedZoomOut}
              />
            </div>
            <div className={styles.zoomin}>
              <IconButton
                icon={Icon.SearchPlus}
                onClick={this.handleClickedZoomIn}
              />
            </div>
            <div className={styles.fullscreen}>
              <IconButton
                icon={Icon.ExpandArrowsAlt}
                onClick={this.handleClickedFullscreen}
              />
            </div>
            <div className={styles.open}>
              <IconLink
                href={originalUrl}
                icon={Icon.ExternalLink}
              />
            </div>
            {isImage && <canvas width={width} height={height - 25} ref={this.setCanvas} />}
            {!isImage && (
              <div className={styles.centered}>
                <img src={getLargeIcon(filetype)} alt='' />
                <p style={{ width: '50%' }}>{T('imageView.notPreviewable')}</p>
                <p><a href={originalUrl}>{T('imageView.download')}</a></p>
              </div>
            )}
          </div>
        )}
      </Measure>
      {fullscreenOpen && url && <FullscreenImageView src={url} onClick={this.handleClickedFullscreenClose} />}
    </>;
  }
}
