import React from 'react';
import {
  PersonEvent,
  getEventIcon,
  getEventTypeName,
  getEventInfo,
  getEventSubType,
  isRemovablePersonEvent
} from '../../models/PersonEvent';

import styles from './index.module.scss';
import { Card, Button } from 'react-bootstrap';
import { Icon } from '../../components/Icons';
import { classes } from '../../utils/Styles';

interface EventViewProps {
  event: PersonEvent;
  onClickedEdit?: (event: PersonEvent) => void;
  onClickedRemove?: (event: PersonEvent) => void;
}
export const EventView = (props: EventViewProps) => {

  const { event, onClickedEdit, onClickedRemove } = props;
  const info: JSX.Element[] = [];

  getEventInfo(event).forEach(entry => {
    info.push(<dt key={entry.key + '-term'} className='col-sm-3'>{entry.title}:</dt>);
    info.push(<dd key={entry.key + '-defn'} className='col-sm-9'>{entry.value}</dd>);
  });
  const subType = getEventSubType(event);

  const handleClickedEdit = () => onClickedEdit && onClickedEdit(event);
  const handleClickedRemove = () => onClickedRemove && onClickedRemove(event);

  return (
    <div className={styles.event}>
      <div className={styles.eventIconWrapper}>
        <div className={classes('event-icon', styles.eventIcon)}>
          <i className={getEventIcon(event.type)} />
        </div>
      </div>
      <div className={styles.eventInfo}>
        <Card style={{ marginBottom: 0 }}>
          <Card.Header>
            <div style={{ float: 'right' }}>
              { onClickedEdit && (
                <Button variant='link' onClick={handleClickedEdit}>
                  <i className={Icon.Edit} />
                </Button>
              )}
              { onClickedRemove && isRemovablePersonEvent(event) && (
                <Button variant='link' onClick={handleClickedRemove}>
                  <i className={Icon.TimesCircle} />
                </Button>
              )}
            </div>
            <h4>{getEventTypeName(event.type)}{subType && <i>{' '}&middot;{' '}{subType}</i>}</h4>
          </Card.Header>
          <Card.Body style={{ paddingTop: 0 }}>
            <dl className='row'>
              {info}
            </dl>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
