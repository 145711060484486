import React from 'react';
import { IPersonStory2 } from "../../../models/PersonStory2";
import { PersonForm, PersonFormData } from "./PersonForm";
import { PlaceForm, PlaceFormData } from "./PlaceForm";
import { IPerson } from "../../../models/Person";
import { IPersonPlaceErrors } from "./PersonPlaceData";


export interface PersonPlaceTabProps {
  personStory?: IPersonStory2;
  person?: IPerson;
  errors: IPersonPlaceErrors;
  readOnly: boolean;
  onUpdatePerson: (data: PersonFormData) => void;
  onUpdatePlace: (data: PlaceFormData) => void;
}

export const PersonPlaceTab = (props: PersonPlaceTabProps) => {
  const { personStory, person, errors, readOnly, onUpdatePerson, onUpdatePlace } = props;

  return <>
    <PersonForm
      personStory={personStory}
      person={person}
      errors={errors}
      readOnly={readOnly}
      onUpdate={onUpdatePerson}
    />
    <PlaceForm
      personStory={personStory}
      person={person}
      errors={errors}
      readOnly={readOnly}
      onUpdate={onUpdatePlace}
    />
  </>;
};
