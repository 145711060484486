import { IPlace } from "./Place";
import { IMilitaryEvent, PersonEvent, MemoratedKind } from "./PersonEvent";
import { T, TranslationKey } from "../Translate";
import { IPersonDocument } from "./PersonDocument";
import { IPersonStory } from "./PersonStory";
import { IMemorial } from "./Memorial";

export enum Gender {
  Undefined = 'UNDEFINED',
  Male = 'MALE',
  Female = 'FEMALE'
}

export enum VictimType {
  Civilian = 'CIVILIAN',
  Military = 'MILITARY',
  None = 'NONE'
}

export function translateVictimType(type: VictimType) {
  switch (type) {
    case VictimType.Civilian:
      return T('personType.civilian');
    case VictimType.Military:
      return T('personType.military');
    default:
      return T('personType.unknown');
  }
}

export function translateWarCasualty(isWarCasualty: Boolean) {
  if (isWarCasualty) {
    return T('person.victim');
  } else {
    return T('person.survivor');
  }
}

export enum VictimSubType {
  None = 'NONE',
  Unknown = 'UNKNOWN',
  Deportated = 'DEPORTATED',
  Executed = 'EXECUTED'
}

export function translateVictimSubType(victimSubType: VictimSubType) {
  switch (victimSubType) {
    case VictimSubType.Deportated:
      return T('victimSubType.deportated');
    case VictimSubType.Executed:
      return T('victimSubType.executed');
    case VictimSubType.Unknown:
      return T('victimSubType.unknown');
    default:
      return T('victimSubType.none');
  }
}

export function translateGender(gender: Gender) {
  switch (gender) {
    case Gender.Undefined:
      return T('gender.undefined');
    case Gender.Female:
      return T('gender.female');
    case Gender.Male:
      return T('gender.male');
    default:
      return T('gender.undefined');
  }
}

export enum PersonRelationType {
  __Select__ = '__',
  Married = 'married',
  Parent = 'parent',
  Child = 'child',
  Sibling = 'sibling',
  Friend = 'friend',
  Fieldmate = 'fieldmate',
  Cousin = 'cousin',
  Other = 'other',
  Grandchild = 'grandchild',
  Grandparent = 'grandparent',
  Family = 'family'
}

export interface IPersonRelation {
  person_id: string;
  relation: PersonRelationType;
  person?: IPerson;
  comments: string;
}

export interface IPersonProjectMembership {
  project: string; // project ID
  notes: string;
}

export enum PersonQueryOptions {
  MemorialIdsAndNames = 'EXTEND_MEMORIAL_IDS_NAMES',
  Memorials = 'EXTEND_MEMORIALS',
  Memorial = 'EXTEND_MEMORIAL',
  BornDate = 'EXTEND_BORN_DATE',
  BornPlaceId = 'EXTEND_BORN_PLACE_ID',
  DiedPlaceId = 'EXTEND_DIED_PLACE_ID',
  BornPlace = 'EXTEND_BORN_PLACE',
  DiedPlace = 'EXTEND_DIED_PLACE',
  DiedDate = 'EXTEND_DIED_DATE',
  MilitaryEvents = 'EXTEND_EVENTS_ENLISTED',
  Documents = 'EXTEND_DOCUMENTS',
  Storied = 'EXTEND_STORIES',
  EventsWithPlaces = 'EXTEND_EVENTS_WITH_PLACES',
  PhotoUrls = 'EXTEND_PHOTO_URLS',

  RelationPersons = 'EXTEND_RELATIONS'
}

export interface IPersonFields {
  surname: string; // misnomer; actually first name
  familyname: string;
  gender: Gender;
  victim_type: VictimType;
  victim_type_details: string;

  born_day: string|null;
  born_month: string|null;
  born_year: string|null;
  sort_born_date: string;

  died_day: string|null;
  died_month: string|null;
  died_year: string|null;
  sort_died_date: string;
  died_age: number;

  alternative_surnames: string[];
  alternative_familynames: string[];
  nationality: string;
  initials: string;

  description: string;
  relations: IPersonRelation[];
  project_memberships: IPersonProjectMembership[];

  memorials: string[]; // memorial IDs
  extend_memorials?: IMemorial[];

  war_casualty: boolean;
  in_namelist: boolean;
  cwxrm_remembered: boolean;
  import_info?: unknown;
}

export interface IPerson extends IPersonFields {
  _id: string;

  // extended information, available upon request
  extend_memorial_ids_names?: [string, string][];
  extend_born_date?: string;
  extend_born_place_id?: string;
  extend_died_place_id?: string;
  extend_born_place?: IPlace;
  extend_died_place?: IPlace;
  extend_died_date?: string;
  extend_events_enlisted?: IMilitaryEvent[];
  documents?: IPersonDocument[];
  stories?: IPersonStory[];
  events?: PersonEvent[];
  photo_urls?: { original: string, xs: string, l: string, xl: string }[];
}

export interface IPersonWithEnlistedInfo extends IPerson {
  enlisted_day: number;
  enlisted_month: number;
  enlisted_year: number;
  enlisted_rank: string;
  enlisted_number: string;
}

export class Person {
  id: string;
  firstName: string;
  lastName: string;
  alternativeFirstNames: string[];
  alternativeLastNames: string[];
  gender: Gender;
  bornDay: string|null;
  bornMonth: string|null;
  bornYear: string|null;
  bornDateSortable: string|null;
  diedDay: string|null;
  diedMonth: string|null;
  diedYear: string|null;
  diedDateSortable: string|null;
  diedAge: string|null;
  victimType: VictimType;
  victimTypeDetails: string;
  warCasualty: boolean;
  inNamelist: boolean;
  familyInfo: string;
  events: PersonEvent[];
  relations: IPersonRelation[];

  projects?: IPersonProjectMembership[];
  militaryEvents?: IMilitaryEvent[];
  memorialIdsAndNames?: { id: string, name: string }[];
  memorials?: IMemorial[];

  constructor(person: IPerson) {
    this.id = person._id || '';
    this.firstName = person.surname;
    this.lastName = person.familyname;
    this.alternativeFirstNames = person.alternative_surnames;
    this.alternativeLastNames = person.alternative_familynames;
    this.gender = person.gender;
    this.events = person.events || [];
    this.familyInfo = person.description;

    this.bornDay = person.born_day;
    this.bornMonth = person.born_month;
    this.bornYear = person.born_year;
    this.bornDateSortable = person.sort_born_date;

    this.diedDay = person.died_day;
    this.diedMonth = person.died_month;
    this.diedYear = person.died_year;
    this.diedAge = person.died_age.toString();
    this.diedDateSortable = person.sort_died_date;

    this.victimType = person.victim_type;
    this.victimTypeDetails = person.victim_type_details;
    this.warCasualty = person.war_casualty;
    this.inNamelist = person.in_namelist;

    this.projects = person.project_memberships;
    this.relations = person.relations;
    this.militaryEvents = person.extend_events_enlisted;
    this.memorials = person.extend_memorials || [];

    if (person.extend_memorial_ids_names) {
      this.memorialIdsAndNames = person.extend_memorial_ids_names
        .map(entry => ({ id: entry[0], name: entry[1] }));
    }
  }

  get name() {
    return this.firstName + ' ' + this.lastName;
  }

  get nameSortable() {
    return this.lastName + ' ' + this.firstName;
  }

  get birthDateFormatted(): string|undefined {
    if (this.bornDay || this.bornMonth || this.bornYear)
      return (this.bornDay || '?') + '/' + (this.bornMonth || '?') + '/' + (this.bornYear || '?');
    else
      return '';
  }

  get diedDateFormatted(): string|undefined {
    if (this.diedDay || this.diedMonth || this.diedYear)
      return (this.diedDay || '?') + '/' + (this.diedMonth || '?') + '/' + (this.diedYear || '?');
    else
      return '';
  }

  get hasBornDate(): boolean {
    return (this.bornDay || this.bornMonth || this.bornYear) != null;
  }

  get hasDiedDate(): boolean {
    return (this.diedDay || this.diedMonth || this.diedYear) != null;
  }

  describeType(): string {
    return translateVictimType(this.victimType) + ' ' + translateWarCasualty(this.warCasualty);
  }

  describeNameLister(): string {
    return this.inNamelist ? T('person.isNamelist') : '';
  }

  describeVictimSubtype(): string {
    return translateVictimSubType(this.victimTypeDetails as VictimSubType)
  }

  describeGender(): string {
    return translateGender(this.gender);
  }

  describeAge(): string {
    if (this.diedAge !== null) {
      let ageAsNumber = parseInt(this.diedAge)
      if (isNaN(ageAsNumber)) {
        return this.diedAge
      } else {
        if (ageAsNumber > 0) {
          return this.diedAge
        }
      }
    }
    return ""
  }

  static describePersonalRelationType(relationType: PersonRelationType, gender: Gender) : string {
    return T(gender === Gender.Undefined
      ? `relation.${relationType}` as TranslationKey
      : `relation.${relationType}.${gender.toLocaleLowerCase()}` as TranslationKey);
  }
}



export interface IPersonMergeSuggestion {
  first: IPerson;
  second: IPerson;
}

export interface IPersonMilitaryInfo {
  army?: string;
  rank?: string;
  number?: string;
  regiment?: string;
  unit?: string;
  unit_nr?: string;
  sub?: string;
}

export interface IPersonMemoratedInfo {
  memorialId: string;
  memorialName: string;
  memorialReference: string;
  memorialKind: MemoratedKind;
}
