import React, { useCallback, useMemo } from 'react';
import { ModalProps, useModal } from '../../modals/BaseModal';
import { Modal, Button } from 'react-bootstrap';
import { T, TranslationKey } from '../../Translate';
import { ModalHeader } from '../../modals/ModalHeader';
import { CheckboxInput } from '../../components/inputs/CheckboxInput';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { Icon } from '../../components/Icons';
import { useAppDispatch, useAppSelector } from '../../utils/Functional';
import { updateExportModalStateAction } from '../../redux/Actions';

interface ExportFieldModalProps extends ModalProps<string[]|undefined> {

}

interface FieldGroup {
  key: TranslationKey;
  fields: string[];
}

const fields: FieldGroup[] = [
  {
    key: 'exportgroup.general',
    fields: [
      'id',
      'name',
      'alternativeNames',
      'gender',
      'familyInfo'
    ]
  },
  {
    key: 'exportgroup.born',
    fields: [
      'born.date',
      'born.date.split',
      'born.place.name',
      'born.place.region',
      'born.place.country',
      'born.place.position',
      'born.topo',
      'born.notes'
    ]
  },
  {
    key: 'exportgroup.school',
    fields: [
      'school.date',
      'school.date.split',
      'school.place.name',
      'where.place.region',
      'school.place.country',
      'school.place.position',
      'school.topo',
      'school.notes',
      'school.name',
      'school.direction'
    ]
  },
  {
    key: 'exportgroup.work',
    fields: [
      'work.date',
      'work.date.split',
      'work.place.name',
      'work.place.region',
      'work.place.country',
      'work.place.position',
      'work.topo',
      'work.notes',
      'work.companyName',
      'work.profession'
    ]
  },
  {
    key: 'exportgroup.where',
    fields: [
      'where.date',
      'where.date.split',
      'where.place.name',
      'where.place.region',
      'where.place.country',
      'where.place.position',
      'where.topo',
      'where.notes',
      'where.placeType'
    ]
  },
  {
    key: 'exportgroup.where.homeaddress',
    fields: [
      'where.homeaddress.date',
      'where.homeaddress.date.split',
      'where.homeaddress.place.name',
      'where.homeaddress.place.region',
      'where.homeaddress.place.country',
      'where.homeaddress.place.position',
      'where.homeaddress.topo',
      'where.homeaddress.notes',
      'where.homeaddress.placeType'
    ]
  },
  {
    key: 'exportgroup.where.evacuate',
    fields: [
      'where.evacuate.date',
      'where.evacuate.date.split',
      'where.evacuate.place.name',
      'where.evacuate.place.region',
      'where.evacuate.place.country',
      'where.evacuate.place.position',
      'where.evacuate.topo',
      'where.evacuate.notes',
      'where.evacuate.placeType'
    ]
  },
  {
    key: 'exportgroup.military.enlisted',
    fields: [
      'enlisted.date',
      'enlisted.date.split',
      'enlisted.place.name',
      'enlisted.place.region',
      'enlisted.place.country',
      'enlisted.place.position',
      'enlisted.topo',
      'enlisted.army',
      'enlisted.regiment',
      'enlisted.unit',
      'enlisted.unitType',
      'enlisted.unitNr',
      'enlisted.sub',
      'enlisted.serviceNumber',
      'enlisted.rank',
      'enlisted.notes'
    ]
  },
  {
    key: 'exportgroup.military.service',
    fields: [
      'activeService.date',
      'activeService.date.split',
      'activeService.place.name',
      'activeService.place.region',
      'activeService.place.country',
      'activeService.place.position',
      'activeService.topo',
      'activeService.army',
      'activeService.regiment',
      'activeService.unit',
      'activeService.unitType',
      'activeService.unitNr',
      'activeService.sub',
      'activeService.serviceNumber',
      'activeService.rank',
      'activeService.notes'
    ]
  },
  {
    key: 'exportgroup.military.other',
    fields: [
      'militaryOther.date',
      'militaryOther.date.split',
      'militaryOther.type',
      'militaryOther.place.name',
      'militaryOther.place.region',
      'militaryOther.place.country',
      'militaryOther.place.position',
      'militaryOther.topo',
      'militaryOther.army',
      'militaryOther.regiment',
      'militaryOther.unit',
      'militaryOther.unitType',
      'militaryOther.unitNr',
      'militaryOther.sub',
      'militaryOther.serviceNumber',
      'militaryOther.rank',
      'militaryOther.notes'
    ]
  },
  {
    key: 'exportgroup.injured',
    fields: [
      'injured.date',
      'injured.date.split',
      'injured.place.name',
      'injured.place.region',
      'injured.place.country',
      'injured.place.position',
      'injured.topo',
      'injured.wound',
      'injured.cause',
      'injured.notes'
    ]
  },
  {
    key: 'exportgroup.hospitalized',
    fields: [
      'hospitalized.date',
      'hospitalized.date.split',
      'hospitalized.place.name',
      'hospitalized.place.region',
      'hospitalized.place.country',
      'hospitalized.place.position',
      'hospitalized.topo',
      'hospitalized.notes'
    ]
  },
  {
    key: 'exportgroup.died',
    fields: [
      'died.date',
      'died.date.split',
      'died.place.name',
      'died.place.region',
      'died.place.country',
      'died.place.position',
      'died.topo',
      'died.notes',
      'died.age'
    ]
  },
  {
    key: 'exportgroup.buriedOriginal',
    fields: [
      'buriedOriginal.date',
      'buriedOriginal.date.split',
      'buriedOriginal.place.name',
      'buriedOriginal.place.region',
      'buriedOriginal.place.country',
      'buriedOriginal.memorial.name',
      'buriedOriginal.memorial.address',
      'buriedOriginal.memorial.position',
      'buriedOriginal.reference',
      'buriedOriginal.kind',
      'buriedOriginal.notes'
    ]
  },
  {
    key: 'exportgroup.buriedCurrent',
    fields: [
      'buriedCurrent.date',
      'buriedCurrent.date.split',
      'buriedCurrent.place.name',
      'buriedCurrent.place.region',
      'buriedCurrent.place.country',
      'buriedCurrent.memorial.name',
      'buriedCurrent.memorial.address',
      'buriedCurrent.memorial.position',
      'buriedCurrent.reference',
      'buriedCurrent.kind',
      'buriedCurrent.notes'
    ]
  },
  {
    key: 'exportgroup.memoratedOther',
    fields: [
      'memoratedOther.date',
      'memoratedOther.date.split',
      'memoratedOther.place.name',
      'memoratedOther.place.region',
      'memoratedOther.place.country',
      'memoratedOther.memorial.name',
      'memoratedOther.memorial.address',
      'memoratedOther.memorial.position',
      'memoratedOther.reference',
      'memoratedOther.kind',
      'memoratedOther.notes'
    ]
  }
];


export default (props: ExportFieldModalProps) => {
  const [isOpen, resolve] = useModal(props);

  const selected = useAppSelector(state => state.exportModalState.fields) || [];
  const dispatch = useAppDispatch();

  const setSelected = useCallback((fields: string[]) => {
    dispatch(updateExportModalStateAction({ fields }));
  }, [dispatch]);

  const handleClose = () => resolve(undefined);
  const handleAccept = () => resolve(selected);

  const groups = useMemo(() => fields.map(group => {
    const handleChecked = (field: string, value: boolean) => {
      if (value) {
        setSelected([...selected, field]);
      } else {
        setSelected(selected.filter(x => x !== field));
      }
    };

    return (
      <div key={group.key}>
        <h4>{T(group.key)}</h4>
        {group.fields.map(field => (
          <CheckboxInput
            style={{ marginBottom: 0 }}
            label={T(('field.' + field) as TranslationKey)}
            labelColumns={6}
            value={selected.includes(field)}
            onChange={value => handleChecked(field, value)}
          />
        ))}
      </div>
    );
  }), [selected, setSelected]);

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <ModalHeader>{T('page.advancedSearch.exportFields')}</ModalHeader>
      <Modal.Body style={{ overflowY: 'auto', maxHeight: '70vh' }}>
        {groups}
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant="primary" onClick={handleClose}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant="primary" onClick={handleAccept}>
          <i className={Icon.Check} />
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
