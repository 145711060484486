import { getErrorDescription, IValidationResult } from "../../../models/ValidationResult";
import { Language } from "../../../models/PersonStory2";
import { StoryIndex } from "./StoryTextView";

export interface IStoryErrors {
  story1Error?: string
  story2Error?: string
  storyStatusError?: string
  childStory1Error?: string
  childStory2Error?: string
  childStoryStatusError?: string
}

export interface IStoriesErrors {
  nl?: IStoryErrors
  fr?: IStoryErrors
  de?: IStoryErrors
  en?: IStoryErrors
}

export interface IImageErrors {
  profileImage?: string;
  mapImage?: string;
}

export interface IKeyStoryErrors {
  keyPosition?: string;
  linkedContactPerson?: string;
}

export function hasImageErrors(errors: IImageErrors) {
  return errors.profileImage !== undefined || errors.mapImage !== undefined;
}

export class StoriesErrorsWrapper {
  storiesErrors?: IStoriesErrors;

  constructor(storiesErrors?: IStoriesErrors) {
    this.storiesErrors = storiesErrors
  }

  errorsCount(): number {
    if (this.storiesErrors === undefined)
      return 0;

    let count = 0;
    for (const key in this.storiesErrors) {
      if (this.storiesErrors[key as keyof IStoriesErrors] !== undefined) {
        count++;
      }
    }
    return count
  }

  getStoryStatusErrorMessage(language: Language, index: StoryIndex) {
    if (!this.storiesErrors) {
      return undefined
    }
    if (this.storiesErrors[language]) {
      if (index === StoryIndex.STORY_1) {
        return this.storiesErrors[language]?.storyStatusError
      } else {
        return this.storiesErrors[language]?.childStoryStatusError
      }
    }
  }

  getStoryTextErrorMessage(language: Language, index: StoryIndex) {
    if (!this.storiesErrors) {
      return undefined
    }
    if (this.storiesErrors[language]) {
      switch (index) {
        case StoryIndex.STORY_1:
          return this.storiesErrors[language]?.story1Error;
        case StoryIndex.STORY_2:
          return this.storiesErrors[language]?.story2Error;
        case StoryIndex.STORY_CHILD_1:
          return this.storiesErrors[language]?.childStory1Error;
        case StoryIndex.STORY_CHILD_2:
          return this.storiesErrors[language]?.childStory2Error;
        default:
          return undefined
      }
    }
    return undefined
  }

  languagesWithErrors(): Language[] {
    if (!this.storiesErrors) {
      return []
    }
    return Object.keys(this.storiesErrors).map((key) => key as Language);
  }
}


export const storiesErrorsCount =(storiesErrors?: IStoriesErrors): number => {
  if (!storiesErrors)
    return 0;
  return new StoriesErrorsWrapper(storiesErrors).errorsCount()
}


export const storyErrorsFromValidationResult = (result: IValidationResult): IStoriesErrors => {
  if (!result.data)
    return {}

  const languageStrings = ['nl', 'fr', 'de', 'en']
  let storiesErrors: IStoriesErrors = {}

  for (var index in languageStrings) {

    let errorFound = false
    let storyError: IStoryErrors = {}
    const languageString = languageStrings[index]

    const storyStatusErrorName = `storyText.${languageString}.storyStatus`
    if (result.data[storyStatusErrorName]) {
      storyError.storyStatusError = getErrorDescription(result.data[storyStatusErrorName])
      errorFound = true
    }

    const storyPart1ErrorName = `storyText.${languageString}.storyPart1`;
    if (result.data[storyPart1ErrorName]) {
      storyError.story1Error = getErrorDescription(result.data[storyPart1ErrorName])
      errorFound = true
    }
    const storyPart2ErrorName = `storyText.${languageString}.storyPart2`;
    if (result.data[storyPart2ErrorName]) {
      storyError.story2Error = getErrorDescription(result.data[storyPart2ErrorName])
      errorFound = true
    }

    const storyChildStatusErrorName = `storyText.${languageString}.storyChildStatus`
    if (result.data[storyChildStatusErrorName]) {
      storyError.childStoryStatusError = getErrorDescription(result.data[storyChildStatusErrorName])
      errorFound = true
    }

    const storyChildPart1ErrorName = `storyText.${languageString}.storyChildPart1`
    if (result.data[storyChildPart1ErrorName]) {
      storyError.childStory1Error = getErrorDescription(result.data[storyChildPart1ErrorName])
      errorFound = true
    }
    const storyChildPart2ErrorName = `storyText.${languageString}.storyChildPart2`
    if (result.data[storyChildPart2ErrorName]) {
      storyError.childStory2Error = getErrorDescription(result.data[storyChildPart2ErrorName])
      errorFound = true
    }

    if (errorFound) {
      storiesErrors[languageString as keyof IStoriesErrors] = storyError
    }
  }

  return Object.keys(storiesErrors).length === 0 ? {} : storiesErrors
}
