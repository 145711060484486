export function formatSortableDate(date: string) {
  if (date === '')
    return '';

  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);
  return `${day === '??' ? '?' : day}/${month === '??' ? '?' : month}/${year === '????' ? '?' : year}`;
}



export function formatDayMonthYear(day: String | null, month: String | null, year: String | null) {
  let dayString = day ?? "??";
  let monthString = month ?? "??"
  let yearString = year ?? "??"

  return `${dayString}/${monthString}/${yearString}`
}
