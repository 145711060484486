import { IPerson } from "../../../models/Person";
import { IPlace } from "../../../models/Place";
import { PersonEvent, PersonEventType } from "../../../models/PersonEvent";
import { Language, T, TranslationKey } from "../../../Translate";
import { PlaceFormData } from "./PlaceForm";

export interface EventLinksData {
  text: string,
  eventId?: string,
  event?: PersonEvent
}

export interface EventLinksDataWithSelected extends EventLinksData {
  selected: boolean
}

/**
 * Describe place for language
 * @param place
 * @param language
 */
const describePlace = (place: IPlace, language: Language): string | undefined => {
   return `${place.languages[language].name},  ${place.languages[language].administrative_area_level_2}, ${place.languages[language].administrative_area_level_1}, ${place.country_code}`
}

/**
 * Get Place information based on an event and the language
 */
export const infoFromPlace = (personEvent: PersonEvent, language: Language): PlaceFormData => {
  const place = personEvent.extend_place as IPlace;
  const placeInfo = place.languages[language]


  // Community is the lowest available area, sublocality > locality > admin_area_level_2
  // Area will the the first available area above the community
  return {
    community: placeInfo.sublocality || placeInfo.locality || placeInfo.administrative_area_level_2 || '',
    area: (placeInfo.sublocality ? placeInfo.locality : undefined) || placeInfo.administrative_area_level_2 || placeInfo.administrative_area_level_1 || '',
    countryCode: place.country_code,
    eventRef: personEvent._id
  }
}

/**
 * Return the different possible area values from a place
 */
export const areasFromPlace = (personEvent: PersonEvent, language: Language): string[] => {
  let results: string[] = [];
  const place = personEvent.extend_place as IPlace;
  const placeInfoForLanguage = place.languages[language];
  if (placeInfoForLanguage.locality && placeInfoForLanguage.sublocality) {
    results.push(placeInfoForLanguage.locality)
  }
  if (placeInfoForLanguage.administrative_area_level_2) {
    results.push(placeInfoForLanguage.administrative_area_level_2)
  }
  if (placeInfoForLanguage.administrative_area_level_1) {
    results.push(placeInfoForLanguage.administrative_area_level_1)
  }
  return results;
}

export const areasFromPersonWithEventId = (person: IPerson, id: string): string[] => {
  const personEvent = eventWithIdFromPerson(person, id);
  if (personEvent !== undefined) {
    return areasFromPlace(personEvent, 'nl')
  } else {
    return []
  }
}


/**
 * Describe person event
 * @param event
 */
const describePersonEvent = (event: PersonEvent): string | undefined => {

  if (!event.extend_place)
    return undefined

  if (Object.keys(event.extend_place).length === 0)
    return undefined

  const translationKey = ('event.' + event.type) as TranslationKey
  const typeAsString: string = T(translationKey)

  return typeAsString + ' ' + T('event.at') + ' ' + describePlace(event.extend_place as IPlace, 'nl')
}


/**
 * get the event with id from the person events
 */
export const eventWithIdFromPerson = (person: IPerson, id: string): PersonEvent | undefined => {
  return person.events?.find((el) => el._id === id)
}

/**
 * Generate event links form person data
 * @param person
 */
export const eventLinksFromPersonEvents = (person?: IPerson) : EventLinksData[] => {
  if (person === undefined) {
    return []
  }

  if (person.events === undefined) {
    return []
  }

  return person.events
    // filter only the valid event types
    .filter((el, index) => {
      return (el.extend_place !== undefined) &&
            (el.type === PersonEventType.Born || el.type ===  PersonEventType.School || el.type ===  PersonEventType.Work || el.type ===  PersonEventType.Where)
    })
    // convert to event descriptions
    .map((el, index) => {
    if (el.extend_place !== undefined) {
      return {
        text: describePersonEvent(el) || '',
        eventId: el._id,
        event: el
      }
    } else {
      // will never happen because of the preceding filter
      return {
        text: '',
      }
    }
  });
}
