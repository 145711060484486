import React from 'react';
import { EventInfoLine } from '../../models/PersonEvent';

import eventStyles from '../ViewPerson/index.module.scss';
import { Card, Button } from 'react-bootstrap';
import { Icon } from '../../components/Icons';
import {
  MilitaryEntityEvent,
  getMilitaryEntityEventTypeName,
  getMilitaryEntityEventIcon,
  getMilitaryEntityTypeName,
  getMilitaryTrackTypeName,
  MilitaryEntity,
  IMilitaryEntityStructureEvent
} from '../../models/MilitaryEntity';
import { formatSortableDate } from '../../utils/Date';
import { T } from '../../Translate';
import { PageID } from '../../utils/PageLinking';
import PageLink from '../../components/PageLink';
import PlaceInfo from '../../components/inputs/PlaceInfo';

export function getMilitaryEntityEventInfo(event: MilitaryEntityEvent): EventInfoLine[] {
  const result: EventInfoLine[] = [];
  if (event.from_date && event.from_date !== '????????') {
    result.push({
      key: 'from',
      title: T('page.militaryEntity.event.from'),
      value: formatSortableDate(event.from_date)
    });
  }
  if (event.to_date && event.to_date !== '????????') {
    result.push({
      key: 'to',
      title: T('page.militaryEntity.event.to'),
      value: formatSortableDate(event.to_date)
    });
  }
  if (event.type === 'attached' || event.type === 'structure') {
    const entity = event.entity;
    if (entity) {
      result.push({
        key: 'entity',
        title: getMilitaryEntityTypeName(entity.type) + ':',
        value: (
          <PageLink page={PageID.ViewMilitaryEntity} param={entity._id}>
            {new MilitaryEntity(entity).name}
          </PageLink>
        )
      });
    }
  }
  if (event.type === 'track') {
    const place = event.place;
    if (place) {
      result.push({
        key: 'place',
        title: T('page.militaryEntity.event.place'),
        value: <PlaceInfo place={place} />
      });
    }
    if (event.topo)
      result.push({ key: 'topo', title: T('page.militaryEntity.event.topo'), value: event.topo });
  }
  if (event.description)
    result.push({ key: 'description', title: T('page.militaryEntity.event.description'), value: event.description });
  
  return result;
}

export function getMilitaryEntitySubTypeName(event: MilitaryEntityEvent): string|undefined {
  switch (event.type) {
    case 'structure':
      return T('militaryEntityEventType.sub.structure');
    case 'attached':
      return T('militaryEntityEventType.sub.attached');
    case 'track':
      return getMilitaryTrackTypeName(event.track_type);
    default:
      return '';
  }
}

interface MilitaryEntityEventViewProps {
  event: MilitaryEntityEvent;
  onClickedEdit?: (event: MilitaryEntityEvent) => void;
  onClickedRemove?: (event: MilitaryEntityEvent) => void;
}
export const MilitaryEntityEventView = (props: MilitaryEntityEventViewProps) => {
  const { event, onClickedEdit, onClickedRemove } = props;
  const info: JSX.Element[] = [];
  getMilitaryEntityEventInfo(event).forEach(entry => {
    info.push(<dt key={entry.key + '-term'} className='col-sm-3'>{entry.title}</dt>);
    info.push(<dd key={entry.key + '-defn'} className='col-sm-9'>{entry.value}</dd>);
  });

  const handleClickedEdit = () => onClickedEdit && onClickedEdit(event);
  const handleClickedRemove = () => onClickedRemove && onClickedRemove(event);
  const entity = (event as IMilitaryEntityStructureEvent).entity;
  const subHistory = entity
    && entity.history
    && entity.history.map((event, index) => (
      <MilitaryEntityEventView
        key={index}
        event={event}
        onClickedEdit={onClickedEdit && (() => onClickedEdit(event))}
        onClickedRemove={onClickedRemove && (() => onClickedRemove(event))}
      />
    ));

  return <>
    <div className={eventStyles.event}>
      <div className={eventStyles.eventIconWrapper}>
        <div className={eventStyles.eventIcon}>
          <i className={getMilitaryEntityEventIcon(event.type)} />
        </div>
      </div>
      <div className={eventStyles.eventInfo}>
        <Card style={{ marginBottom: 0 }}>
          <Card.Body>
            <div style={{ float: 'right' }}>
              { onClickedEdit && (
                <Button variant='link' onClick={handleClickedEdit}>
                  <i className={Icon.Edit} />
                </Button>
              )}
              { onClickedRemove && (
                <Button variant='link' onClick={handleClickedRemove}>
                  <i className={Icon.TimesCircle} />
                </Button>
              )}
            </div>
            <h4>{getMilitaryEntityEventTypeName(event.type)} &middot; <i>{getMilitaryEntitySubTypeName(event)}</i></h4>
            <dl className='row'>
              {info}
            </dl>
          </Card.Body>
        </Card>
      </div>
    </div>
    <div style={{ marginLeft: '2em'}}>{subHistory}</div>
  </>;
}
