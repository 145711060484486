import React, { ReactNode, useMemo } from 'react';
import styles from './TopTabs.module.scss';
import { Icon } from './Icons';
import { Badge, Nav, Tab } from 'react-bootstrap';
import { useTabState } from "../redux/Actions";

export type BadgeVariant =
| 'primary'
| 'secondary'
| 'success'
| 'danger'
| 'warning'
| 'info'
| 'light'
| 'dark';

interface TopTabsProps {
  id: string;
  defaultActiveKey?: string;
  activeKey?: string;
  onSelect?: (tab: string) => void;
  storeKey?: string
  tabs: TopTab[];
}
export interface TopTab {
  id: string;
  title: string;
  icon: Icon;
  badgeContent?: () => string|undefined;
  badgeVariant?: BadgeVariant;
  content: () => ReactNode;
}

export const TopTabs = (props: TopTabsProps) => {
  const { id, defaultActiveKey, tabs, activeKey, onSelect } = props;

  const [tabKey, setTabKey] = useTabState(props.storeKey, defaultActiveKey)

  const handleSelect = (tab: string|null) => {
    if (tab === null)
      return;
    
    setTabKey(tab)
    if (onSelect) {
      onSelect(tab)
    }
  }

  const renderedTabs = useMemo(() => tabs.map(tab => {
    const badgeVariant = tab.badgeVariant || 'secondary'
    return (
      <Nav.Item key={tab.id}>
        <Nav.Link eventKey={tab.id}>
          <i className={tab.icon} />
          {' '}
          {tab.title}
          {tab.badgeContent !== undefined && <>
            {' '}
            <Badge pill variant={badgeVariant}>{tab.badgeContent()}</Badge>
          </>}
        </Nav.Link>
      </Nav.Item>
  )}), [tabs]);

  const renderedContent = tabs.map(tab => (
    <Tab.Pane key={tab.id} eventKey={tab.id}>
      {tab.content()}
    </Tab.Pane>
  ));
  
  return (
    <Tab.Container
      id={id}
      defaultActiveKey={tabKey || defaultActiveKey}
      activeKey={activeKey}
      onSelect={handleSelect}
    >
      <div className={`${styles.wrapper} content-full-width`}>
        <Nav className='nav-tabs'>
          {renderedTabs}
        </Nav>
      </div>
      <Tab.Content>
        {renderedContent}
      </Tab.Content>
    </Tab.Container>
  );
}
