import React, { ReactNode, CSSProperties } from 'react';

interface SecondaryButtonProps {
  variant?: 'primary'|'secondary'|'success'|'danger'|'warning'|'info';
  onClick: () => void;
  style?: CSSProperties;
  children: ReactNode;
}
export const SecondaryButton = (props: SecondaryButtonProps) => {
  const { variant = 'primary', onClick, style, children } = props;
  const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick();
  };
  return (
    <button
      onClick={handleClick}
      style={style}
      className={`btn btn-link btn-${variant}`}
    >
      {children}
    </button>
  );
}
