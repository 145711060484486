import React from "react";
import { InvalidFeedback } from './InvalidFeedback';

interface FormGroupProps {
  id?: string;
  labelColumns?: number;
  inputColumns?: number;

  label: string;
  error?: string;

  children: React.ReactNode;
}

export default function FormGroup(props: FormGroupProps) {
  const { id, labelColumns = 4, inputColumns, label, error, children } = props;
  return (
    <div className="form-group row">
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}</label>
      <div className={`col-sm-${inputColumns || (12 - labelColumns)}`}>
        {children}
        {error && <InvalidFeedback error={error} />}
      </div>
    </div>
  );
}
