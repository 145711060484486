import React from 'react';
import {
  PersonEvent,
  getEventIcon,
  getEventTypeName,
  getEventInfo,
  getEventSubType
} from '../../models/PersonEvent';

import styles from './index.module.scss';
import { Card } from 'react-bootstrap';

interface TimelineEventViewProps {
  event: PersonEvent;
}
export const TimelineEventView = (props: TimelineEventViewProps) => {
  const { event } = props;

  const info: JSX.Element[] = [];

  getEventInfo(event, false).forEach(entry => {
    info.push(<dt key={entry.key + '-term'} className='col-sm-3'>{entry.title}:</dt>);
    info.push(<dd key={entry.key + '-defn'} className='col-sm-9'>{entry.value}</dd>);
  });
  const subType = getEventSubType(event);

  return (
    <div className={styles.event}>
      <div className={styles.eventLineTop} />
      <div className={styles.eventLineBottom} />
      <div className={styles.eventIconWrapper}>
        <div className={styles.eventIcon}>
          <i className={getEventIcon(event.type)} />
        </div>
      </div>
      <div className={styles.eventInfo}>
        <Card style={{ marginBottom: 0 }}>
          <Card.Header>
            <h4>{getEventTypeName(event.type)}{subType && <i>{' '}&middot;{' '}{subType}</i>}</h4>
          </Card.Header>
          <Card.Body style={{ paddingTop: 0 }}>
            <dl className='row'>
              {info}
            </dl>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
