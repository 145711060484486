import React from 'react';
import { BasePage, PageProps, PageState } from '../Page';
import { TopTab, TopTabs } from '../../components/TopTabs';
import { Icon } from '../../components/Icons';
import { singular, T } from '../../Translate';
import api from '../../api';
import { fixWhitespaceLines, IPersonStory2, StoryStatus } from "../../models/PersonStory2";
import { PersonPlaceTab } from "./PersonPlace/PersonPlaceTab";
import { PersonFormData } from "./PersonPlace/PersonForm";
import { PlaceFormData } from "./PersonPlace/PlaceForm";
import { IPerson, PersonQueryOptions, Person } from "../../models/Person";
import { BottomButtonRow } from "../../components/ButtonRow";
import { Alert, Button } from "react-bootstrap";
import { getErrorDescription, IValidationResult } from "../../models/ValidationResult";
import { IImageErrors, IKeyStoryErrors, IStoriesErrors, storiesErrorsCount, storyErrorsFromValidationResult } from "./Stories/StoriesData";
import { StoriesTab } from "./Stories/StoriesTab";
import { IPersonPlaceErrors, personPlaceErrorsCount } from "./PersonPlace/PersonPlaceData";
import { SummaryTab } from "./Summary/SummaryTab";
import { NotificationManager } from "react-notifications";
import { getPageVia, PageID } from "../../utils/PageLinking";
import { PersonStoryHelper } from "../../models/wrappers/PersonStoryHelper";
import PhotosTab from './Photos/PhotosTab';
import QualificationsTab from './Qualifications/QualificationsTab';
import LinkedStoriesTab from './LinkedStories/LinkedStoriesTab';
import { ConfirmationModal } from '../../modals/Confirmation';
import KeyStoryTab from './KeyStory/KeyStoryTab';
import { DateInputValue, inputDateFromISO, inputDateToISO } from '../../components/inputs/DateInput';

export interface EditPersonStoryProps extends PageProps {
  id?: string;
  personIdRef?: string
}


interface EditPersonStoryState extends PageState {
  personStory?: IPersonStory2;
  person?: IPerson;
  selectedPlaceEvent?: string;
  personPlaceErrors: IPersonPlaceErrors;
  imageErrors: IImageErrors;
  storiesErrors: IStoriesErrors;
  keyStoryErrors: IKeyStoryErrors;
  numberOfMatchingItems: number;
  saving: boolean;
  keyStoryDate: DateInputValue;
  keyStoryDateError?: string;
  keyStoryOrganisationTitle?: string;
  keyStoryOrganisationError?: string;
}

export default class EditPersonStoryPage extends BasePage<EditPersonStoryProps, EditPersonStoryState> {

  originalPersonStory?: IPersonStory2 = undefined;

  constructor(props: EditPersonStoryProps) {
    super(props);


    // Make an initial state
    this.state = {
      page: {
        id: PageID.EditPersonStory,
        via: props.via || getPageVia(PageID.EditPersonStory),
        icon: Icon.PersonStory,
        title: '...'
      },
      numberOfMatchingItems: 0,
      saving: false,
      personPlaceErrors: {},
      imageErrors: {},
      storiesErrors: {},
      keyStoryErrors: {},
      keyStoryDate: { day: '', month: '', year: '' }
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.props.id !== undefined) {
      this.fetchPersonStoryData(this.props.id);
    } else {
      if (this.props.personIdRef !== undefined) {
        this.createNewPersonStoryFromPersonId(this.props.personIdRef)
      }
    }
  }

  componentDidUpdate(oldProps: EditPersonStoryProps, oldState: EditPersonStoryState) {
    super.componentDidUpdate(oldProps, oldState);

    if (this.props.id !== oldProps.id) {
      if (this.props.id)
        this.fetchPersonStoryData(this.props.id);
    } else if (this.props.personIdRef !== oldProps.personIdRef) {
      if (this.props.personIdRef)
        this.createNewPersonStoryFromPersonId(this.props.personIdRef);
    }
  }

  /** event handlers **/

  onUpdatePersonData = (data: PersonFormData) => {
    const { personStory } = this.state;

    if (personStory !== undefined) {
      personStory.first_name = data.firstName;
      personStory.family_name = data.familyName;
    }

    this.setState( {
      personStory: personStory,
      page: {
        id: PageID.EditPersonStory,
        via: this.props.via || getPageVia(PageID.ListPersonStories),
        icon: Icon.PersonStory,
        title: PersonStoryHelper.personName(personStory)
      }
    });
  }

  onUpdateStories = (data: Partial<IPersonStory2>) => {
    const { personStory } = this.state;

    if (personStory !== undefined) {
      personStory.story_child_option = data.story_child_option === undefined ? personStory.story_child_option: data.story_child_option;
      personStory.story_status = data.story_status || personStory.story_status;
      personStory.story_text = data.story_text || personStory.story_text;
    }

    this.setState( {
      personStory: personStory,
      page: {
        id: PageID.EditPersonStory,
        via: this.props.via || getPageVia(PageID.ListPersonStories),
        icon: Icon.PersonStory,
        title: PersonStoryHelper.personName(personStory)
      }
    });
  }

  onUpdatePlaceData = (data: PlaceFormData) => {
    const { personStory } = this.state;

    if (personStory !== undefined) {
      personStory.community = data.community;
      personStory.area = data.area;
      personStory.country_code = data.countryCode;
      personStory.place_source_event_ref = data.eventRef
    }

    this.setState( {
      personStory: personStory,
      page: {
        id: PageID.EditPersonStory,
        via: this.props.via || getPageVia(PageID.ListPersonStories),
        icon: Icon.PersonStory,
        title: PersonStoryHelper.personName(personStory)
      }
    });
  }

  handleClickedSave = async () => {
    if (!this.state.personStory)
      return

    const saveResult = await this.save()
    const validationResult = saveResult as IValidationResult
    if (validationResult?.status !== undefined) {
      this.setValidationErrors(validationResult)
      // If a validation error is not specific to a certain form field, we pass
      // `none` as form field name. In that case the error is communicated
      // by a notification.
      const notification = getErrorDescription(validationResult?.data?.none) ?? T('personStories.messages.saveFailed')
      NotificationManager.error(notification)
    } else {
      this.resetValidationErrors()
      const savedPersonStory = saveResult as IPersonStory2
      this.setState({ personStory: savedPersonStory });
      NotificationManager.success(T('personStories.messages.savedSuccesfully'))
    }
  }

  handleClickedPublishMuseum = async () => {
    const story = this.state.personStory;
    if (!story)
      return;

    const autoLinkedStories = await api.findMatchingPersonStories(story._id);
    const manualLinkedStories = story.coupling_stories || [];
    const hasPublishedLinkedStories = [...autoLinkedStories.data, ...manualLinkedStories].some(story => story.published_date !== undefined);
    if (!hasPublishedLinkedStories) {
      const confirmed = await this.props.modals.show<boolean>(props => (
        <ConfirmationModal 
          title={T('personStories.publishingLoneStory.title')}
          message={T('personStories.publishingLoneStory.message')}
          {...props}
        />
      ));
      if (!confirmed)
        return;
    }

    await this.publish('museum');
  };

  handleClickedPublishKey = async () => {
    const story = this.state.personStory;
    if (!story)
      return;

    await this.publish('key');
  };

  async publish(target: 'museum'|'key') {
    const saveResult = await this.save()
    if ((saveResult as IValidationResult).status !== undefined) {
      NotificationManager.error(T('personStories.messages.publishingFailed'))
    } else {
      const savedPersonStory = saveResult as IPersonStory2
      const publishResult = await api.publishPersonStory2(savedPersonStory._id, target)
      if ((publishResult as IValidationResult).status === undefined) {
        NotificationManager.success(T('personStories.messages.publishedSuccesfully'))
        await this.fetchPersonStoryData(savedPersonStory._id)
        this.resetValidationErrors()
        return;
      } else {
        const validationResult = publishResult as IValidationResult;
        this.setValidationErrors(validationResult);
        NotificationManager.error(T('personStories.messages.publishingFailed'))
      }
    }
  }

  handleClickedUnpublish = async () => {
    if (this.state.personStory) {
      const result = await api.unpublishPersonStory2(this.state.personStory._id)
      if (result === "OK") {
        this.setState({personStory: {...this.state.personStory, published_date: undefined}})
      }
    }
  }

  handleKeyStoryDateChanged = (date: DateInputValue) => {
    this.setState({ keyStoryDate: date });
    if (this.state.personStory) {
      const iso = inputDateToISO(date);
      if (iso) {
        this.setState({ personStory: { ...this.state.personStory, key_date: iso } });
      }
    }
  }

  handleKeyStoryOrganisationTitleChanged = (title: string) => {
    this.setState({ keyStoryOrganisationTitle: title });
    if (this.state.personStory) {
      this.setState({personStory: {...this.state.personStory, key_organisation_title: title}});
    }
  }


  /** render Tab Helper functions **/

  renderPersonPlaceTabContent() {
    return (
      <PersonPlaceTab
        personStory={this.state.personStory}
        person={this.state.person}
        errors={this.state.personPlaceErrors}
        readOnly={this.isReadOnly()}
        onUpdatePerson={this.onUpdatePersonData}
        onUpdatePlace={this.onUpdatePlaceData}
      />
    );
  }

  renderStoriesTabContent() {
    return (
      <StoriesTab
        personStory={this.state.personStory}
        errors={this.state.storiesErrors}
        readOnly={this.isReadOnly()}
        onUpdateStories={this.onUpdateStories}
      />
    );
  }

  renderPhotosTabContent() {
    return (
      <PhotosTab
        story={this.state.personStory}
        person={this.state.person && new Person(this.state.person)}
        errors={this.state.imageErrors}
        readOnly={this.isReadOnly()}
        onChanged={story => this.setState({ personStory: story })}
      />
    );
  }

  renderQualificationsTab() {
    return (
      <QualificationsTab
        story={this.state.personStory}
        person={this.state.person && new Person(this.state.person)}
        readOnly={this.isReadOnly()}
        onChanged={story => this.setState({ personStory: story })}
      />
    );
  }

  renderLinkedStoriesTab() {
    return (
      <LinkedStoriesTab
        history={this.props.history}
        loading={this.props.loading}
        story={this.state.personStory}
        readOnly={this.isReadOnly()}
        onMatchingItemsChanged={numberOfItems => this.setState({ numberOfMatchingItems: numberOfItems })}
        onChanged={story => this.setState({ personStory: story })}
      />
    );
  }

  renderKeyStoryTab() {
    return (
      <KeyStoryTab
        loading={this.props.loading}
        story={this.state.personStory}
        onChanged={story => this.setState({ personStory: story })}
        errors={this.state.keyStoryErrors}
        readOnly={this.isReadOnly()}
        keyStoryDate={this.state.keyStoryDate}
        onKeyStoryDateChanged={this.handleKeyStoryDateChanged}
        keyStoryOrganisationTitle={this.state.keyStoryOrganisationTitle}
        keyStoryOrganisationTitleError={this.state.keyStoryOrganisationTitle}
        onkeyStoryOrganisationTitleChanged={this.handleKeyStoryOrganisationTitleChanged}

      />
    );
  }

  renderSummaryTabContent() {
    return (
      <SummaryTab
        personStory={this.state.personStory}
        onUnpublishedButtonClicked={this.handleClickedUnpublish}
      />
    );
  }

  isReadOnly() {
    return !!(this.state.personStory && this.state.personStory.published_date);
  }

  hasKeyStoryErrors() {
    const keyStoryErrors = this.state.keyStoryErrors;
    return keyStoryErrors.keyPosition !== undefined
      || keyStoryErrors.linkedContactPerson !== undefined;
  }

  generateTabs(): TopTab[] {
    const hasKeyStoryErrors = this.hasKeyStoryErrors();

    return [
      {
        icon: Icon.ListUl,
        title: T('page.personstory.tab.person'),
        id: 'person',
        content: () => this.renderPersonPlaceTabContent(),
        badgeContent: () => personPlaceErrorsCount(this.state.personPlaceErrors) > 0 ? '!': '',
        badgeVariant: personPlaceErrorsCount(this.state.personPlaceErrors) > 0 ? 'danger': 'secondary'
      },
      {
        icon: Icon.Link,
        title: T('page.personstory.tab.stories'),
        id: 'stories',
        content: () => this.renderStoriesTabContent(),
        badgeContent: () => storiesErrorsCount(this.state.storiesErrors) > 0 ? '!': '',
        badgeVariant: storiesErrorsCount(this.state.storiesErrors) > 0 ? 'danger': 'secondary'
      },
      {
        icon: Icon.Images,
        title: T('page.personstory.tab.photos'),
        id: 'pictures',
        content: () => this.renderPhotosTabContent(),
        badgeContent: () => this.state.imageErrors.profileImage ? '!' : '',
        badgeVariant: 'danger'
      },
      {
        icon: Icon.Tags,
        title: T('page.personstory.tab.qualifications'),
        id: 'qualifications',
        content: () => this.renderQualificationsTab(),
        badgeContent: () => this.countQualifications()
      },
      {
        icon: Icon.Key,
        title: T('page.personstory.tab.keystory'),
        id: 'keystory',
        content: () => this.renderKeyStoryTab(),
        badgeContent: () => hasKeyStoryErrors ? '!': '',
        badgeVariant: hasKeyStoryErrors ? 'danger': 'secondary'
      },
      {
        icon: Icon.Link,
        title: T('page.personstory.tab.links'),
        id: 'links',
        content: () => this.renderLinkedStoriesTab(),
        badgeContent: () => this.countLinkedStories()
      },
      {
        icon: Icon.Page,
        title: T('page.personstory.tab.summary'),
        id: 'summary',
        content: () => this.renderSummaryTabContent(),
        badgeContent: () => '',
      }
    ]
  }

  countQualifications() {
    const { personStory: story } = this.state;
    if (!story)
      return undefined;

    const count =
      (story.coupling_use_events_with_places_refs || []).length
      + (story.coupling_tags || []).length
      + (story.coupling_use_died_date ? 1 : 0);
    return count.toString();
  }

  countLinkedStories() {
    const { personStory: story } = this.state;
    if (!story)
      return undefined;

    const total = (story.coupling_story_refs || []).length + this.state.numberOfMatchingItems;

    return total.toString();
  }

  /** API handling **/

  createNewPersonStoryFromPerson(person: IPerson): IPersonStory2 {
    return {
      _id: '',
      person_id_ref: person._id,
      first_name: person.surname,
      family_name: person.familyname,
      born_year: person.born_year || '',
      died_year: person.died_year || '',
      community: '',
      area: '',
      country_code: '',
      story_status: StoryStatus.IN_PROGRESS,
      story_child_option: false,
      story_text: {},
      targets: [],
      linked_contact_persons: []
    };
  }

  createNewPersonStoryFromPersonId(personIdRef: string ) {
    const options: PersonQueryOptions[] = [PersonQueryOptions.EventsWithPlaces]
    this.loading(api.getPerson(personIdRef, options), singular('person'))
      .then(person => {
        const personStory = this.createNewPersonStoryFromPerson(person)

        this.setState({
          personStory: personStory,
          page: {
            id: PageID.EditPersonStory,
            via: this.props.via || getPageVia(PageID.ListPersonStories),
            icon: Icon.PersonStory,
            title: PersonStoryHelper.personName(personStory)
          },
          person: person
        })
      })
  }

  fetchPersonStoryData(id: string) {
    return this.loading(api.getPersonStory2(id, ['linked_stories', 'key_location', 'contact_persons']), T('noun.place'))
      .then(personStory => {
        this.originalPersonStory = { ...personStory };
        this.setState({
          personStory: personStory,
          page: {
            id: PageID.EditPersonStory,
            via: this.props.via || getPageVia(PageID.ListPersonStories),
            icon: Icon.PersonStory,
            title: personStory.first_name as string
          }
        })
        return personStory;
      })
      .then(personStory => {
        const options: PersonQueryOptions[] = [PersonQueryOptions.EventsWithPlaces]
        const personIdRef = personStory.person_id_ref;
        this.loading(api.getPerson(personIdRef, options), singular('person'))
          .then(person => this.setState({
            personStory: personStory,
            page: {
              id: PageID.EditPersonStory,
              via: this.props.via || getPageVia(PageID.ListPersonStories),
              icon: Icon.PersonStory,
              title: PersonStoryHelper.personName(personStory)
            },
            person: person,
            keyStoryOrganisationTitle: personStory.key_organisation_title ?? '',
            keyStoryDate: personStory.key_date ? inputDateFromISO(personStory.key_date) : { day: '', month: '', year: '' }
          }))
      });
  }

  // page: {
  //   id: this.props.id ? PageID.EditPerson : PageID.CreatePerson,
  //   entity: this.props.id,
  //   icon: Icon.User,
  //   title: T('page.person.title.create'),
  //   via: this.props.via || 'persons'
  // },

  async fetchPersonStoryValidation(): Promise<IValidationResult> {
    const validationResult = await this.loading(
      api.validatePersonStory2((this.state.personStory as IPersonStory2), 'publish'),
      T('noun.person')
    );
    this.setValidationErrors(validationResult);
    return validationResult;
  }

  async save() : Promise<IPersonStory2|IValidationResult> {
    this.setState({ saving: true });

    try {
      let story = this.state.personStory;
      if (!story)
        throw new Error('Invalid state');

      let keyDate = this.state.keyStoryDate;
      if (keyDate.day !== '' && keyDate.month !== '' && keyDate.year !== '') {
        const iso = inputDateToISO(keyDate);
        if (!iso) {
          this.setState({keyStoryDateError: T('personStories.errors.invalidKeyDate')});
        } else {
          this.setState({keyStoryDateError: undefined});
        }
      } else {
        this.setState({keyStoryDateError: undefined});
      }
      
      story = fixWhitespaceLines(story);

      const action = story._id === '' ? 'create' : 'update'
      const validationResult = await api.validatePersonStory2(story, action)
      if (validationResult.status !== 'OK') {
        this.setValidationErrors(validationResult);
        return validationResult
      } else {
        if (action === 'create') {
          const result = await api.createPersonStory2(story)
          this.props.history.push(`/personstories/${result._id}/edit`);
          return result;
        } else {
          return await api.updatePersonStory2(story, ['linked_stories', 'key_location', 'contact_persons'])
        }
      }
    } finally {
      this.setState({ saving: false });
    }
  }


  setValidationErrors(result: IValidationResult) {
    const errors = result.data || {};
    const personPlaceErrors: IPersonPlaceErrors = {
      firstNameError: getErrorDescription(errors.firstName),
      familyNameError: getErrorDescription(errors.familyName),
      communityError: getErrorDescription(errors.community),
      areaError: getErrorDescription(errors.area),
      countryCodeError: getErrorDescription(errors.countryCode),
      placeEventError: getErrorDescription(errors.place_source_event_ref)
    }
    const storyErrors = storyErrorsFromValidationResult(result);

    const imageErrors: IImageErrors = {
      profileImage: getErrorDescription(errors.profile_image),
      mapImage: getErrorDescription(errors.map_image)
    };

    const keyStoryErrors: IKeyStoryErrors = {
      keyPosition: getErrorDescription(errors.key_position),
      linkedContactPerson: getErrorDescription(errors.linked_contact_person)
    };

    this.setState({
      personPlaceErrors: personPlaceErrors,
      storiesErrors: storyErrors,
      imageErrors: imageErrors,
      keyStoryErrors
    });
  }

  resetValidationErrors() {
    this.setState({
      personPlaceErrors: {},
      storiesErrors: {},
      imageErrors: {},
      keyStoryErrors: {}
    });
  }

  renderSaveButtons() {
    const readOnly = this.isReadOnly();
    const personStory = this.state.personStory;

    const publishTargets = personStory?.targets || [];
    const published = personStory?.published_date !== null && personStory?.published_date !== undefined;
    const publishedToMuseum = published && publishTargets.includes('museum');
    const publishedToKey = published && publishTargets.includes('key');  
  
    return (
      <BottomButtonRow>
        {!publishedToMuseum && (
          <Button variant='primary' onClick={this.handleClickedPublishMuseum}>
            {T('personstories.publish.museum')}
          </Button>
        )}
        {!publishedToKey && (
          <Button variant='primary' onClick={this.handleClickedPublishKey}>
          {T('personstories.publish.key')}
        </Button>
      )}
        {!readOnly && (
          <Button variant='primary' onClick={this.handleClickedSave} disabled={this.state.saving}>
            {T('generic.action.save')}{' '}{this.state.saving && <i className={`${Icon.Loading} fa-lg`} />}
          </Button>
        )}
      </BottomButtonRow>
    );
  }

  renderContent() {
    const published = !!(this.state.personStory && this.state.personStory.published_date);
    return <>
      {published && <Alert variant='warning'>{T('page.personstory.publishedReadOnly')}</Alert>}
      <TopTabs
        id='edit-personstory-tabs'
        defaultActiveKey='person'
        storeKey='editPersonStory'
        tabs={this.generateTabs()}
      />
      <div style={{ marginBottom:80 }} />
      {this.renderSaveButtons()}
    </>;
  }
}
