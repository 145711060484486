import React from 'react';
import { PersonEvent, PersonEventType } from '../../models/PersonEvent';
import { EventView } from '../ViewPerson/EventView';
import AddEventControl from './components/AddEventControl';
import { Col, Row } from "react-bootstrap";

interface EventsTabProps {
  events: PersonEvent[];
  onClickedEdit: (event: PersonEvent) => void;
  onClickedRemove: (event: PersonEvent) => void;
  onClickedAdd: (type: PersonEventType) => void;
}
export const PersonEventsTab = (props: EventsTabProps) => {
  const { onClickedEdit, onClickedRemove, onClickedAdd, events } = props;
  const eventsRendered = events.map(event => (
    <EventView
      key={event._id}
      event={event}
      onClickedEdit={onClickedEdit}
      onClickedRemove={onClickedRemove}
    />
  ));

  return (
    <div style={{ paddingTop: 8 }}>
      <div style={{ marginBottom: 8 }}>
        <Row className="justify-content-md-right">
          <Col sm={8}/>
          <Col sm={4}>
            <div className="float-right">
             <AddEventControl onClickedAdd={onClickedAdd} />
            </div>
          </Col>
        </Row>
      </div>
      {eventsRendered}
    </div>
  );
}
