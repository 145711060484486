export class IndexedList<T> {
  static empty<T>(): IndexedList<T> {
    return new IndexedList<T>([], (item => ''));
  }

  list: T[];
  indexed: {[key: string]: T};

  constructor(items: T[], indexer: (item: T) => string) {
    this.list = items;
    this.indexed = {};
    items.forEach(item => this.indexed[indexer(item)] = item);
  }
}
