import React, { CSSProperties, useRef } from 'react';
import moment from 'moment';
import { InvalidFeedback } from './InvalidFeedback';
import { useElementID } from '../../utils/ElementID';

export const emptyDateTime = () => ({ day: '', month: '', year: '', hour: '', minute: '' });

export const currentDateTime = (): DateTimeInputValue => {
  const now = moment();

  const result =  {
    day: now.date().toString(),
    month: (now.month()+1).toString(),   // month is in 0..11
    year: now.year().toString(),
    hour: now.hour().toString(),
    minute: now.minute().toString()
  };

  return result
};

export function inputDateTimeToISO(value: DateTimeInputValue): string|undefined {
  const { day, month, year, hour, minute } = value;
  if (!/^[0-9]{1,2}$/.test(day))
    return undefined;
  if (!/^[0-9]{1,2}$/.test(month))
    return undefined;
  if (!/^[0-9]{4}$/.test(year))
    return undefined;
  if (!/^[0-9]{1,2}$/.test(hour))
    return undefined;
  if (!/^[0-9]{1,2}$/.test(minute))
    return undefined;

  const isoString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`;

  // make sure the iso string is valid
  const momentISOString = moment(isoString);
  if (!momentISOString.isValid()) {
    return undefined;
  }

  return isoString
}

export function inputDateTimeFromISO(value: string): DateTimeInputValue {
  const date = moment(value);
  return {
    year: date.year().toString(),
    month: (date.month() +1).toString(),  // month is in 0..11
    day: date.date().toString(),
    hour: date.hour().toString(),
    minute: date.minute().toString()
  };
}

export interface DateTimeInputValue {
  day: string;
  month: string;
  year: string;
  hour: string;
  minute: string;
}

interface DateTimeInputProps {
  name: string;
  label: string;
  value: DateTimeInputValue;
  onChange: (date: DateTimeInputValue) => void;
  style?: CSSProperties;

  labelColumns?: number;
  error?: string;
  readOnly?: boolean;
  validated?: boolean;
}
export const DateTimeInput = (props: DateTimeInputProps) => {
  const {
    name,
    labelColumns = 4,
    label,
    value,
    error,
    readOnly,
    validated,
    onChange,
    style
  } = props;

  const smallWidthInputStyle = {
    width: 52,
    marginRight: 4
  }

  const largeWidthInputStyle = {
    width: 68,
    marginRight: 10
  }

  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);
  const hourRef = useRef<HTMLInputElement>(null);
  const minuteRef = useRef<HTMLInputElement>(null);

  const handleDayChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    if (v.length > 2)
      return;

    onChange(Object.assign({}, value, { day: v }));

    const monthElement = monthRef.current;
    if (v.length === 2 && monthElement)
      monthElement.focus();
  };

  const handleMonthChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    if (v.length > 2)
      return;

    onChange(Object.assign({}, value, { month: v }));

    const yearElement = yearRef.current;
    if (v.length === 2 && yearElement)
      yearElement.focus();
  };

  const handleYearChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    if (v.length > 4)
      return;

    onChange(Object.assign({}, value, { year: v }));

    const hourElement = hourRef.current;
    if (v.length === 4 && hourElement)
      hourElement.focus();
  };

  const handleHourChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    if (v.length > 2)
      return;

    onChange(Object.assign({}, value, { hour: v }));

    const minuteElement = minuteRef.current;
    if (v.length === 2 && minuteElement)
      minuteElement.focus();
  }

  const handleMinuteChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    if (v.length > 2)
      return;

    onChange(Object.assign({}, value, { minute: v }));
  }

  const id = useElementID('dateInput');
  const inputClass = readOnly ? 'form-control-plaintext' : (error ? 'form-control is-invalid' : (validated ? 'form-control is-valid' : 'form-control'));
  return (
    <div className="form-group row" style={style}>
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}</label>
      <div className={`col-sm-${12 - labelColumns}`}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            name={name + '-d'}
            className={inputClass}
            style={smallWidthInputStyle}
            value={value.day}
            onChange={handleDayChanged}
            placeholder='DD'
          />
          <input
            name={name + '-m'}
            className={inputClass}
            style={smallWidthInputStyle}
            value={value.month}
            onChange={handleMonthChanged}
            placeholder='MM'
            ref={monthRef}
          />
          <input
            name={name + '-y'}
            className={inputClass}
            style={largeWidthInputStyle}
            value={value.year}
            onChange={handleYearChanged}
            placeholder='YYYY'
            ref={yearRef}
          />
          <input
            name={name + '-h'}
            className={inputClass}
            style={smallWidthInputStyle}
            value={value.hour}
            onChange={handleHourChanged}
            placeholder='HH'
            ref={hourRef}
          />
          <input
            name={name + '-min'}
            className={inputClass}
            style={{ width: 52 }}
            value={value.minute}
            onChange={handleMinuteChanged}
            placeholder='MM'
            ref={minuteRef}
          />
        </div>
        {error && <InvalidFeedback error={error} />}
      </div>
    </div>
  );
};
