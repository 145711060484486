import React from "react";
import { Icon } from "../../../components/Icons";
import { ActionsTableColumn, ComponentTableColumn, ITableColumn, StringTableColumn, tableRowActions, TableRowActionStyle } from "../../../components/table/Fields";
import { IPersonStory2 } from "../../../models/PersonStory2";
import { T } from "../../../Translate";
import PersonStoryStatusView from "../../ListPersonStories/PersonStoryStatusView";

export function getPersonStoryColumns(canEdit: boolean, onClickedDelete: (story: IPersonStory2) => void) {
  const columns: ITableColumn<IPersonStory2>[] = [
    new ComponentTableColumn('calculated_full_name', T('personstories.name'), (personStory) => { return (
        <span>
          <strong>{personStory.family_name}</strong>
          <br />
          <span>{personStory.first_name}</span>
        </span>)
    }),
    new ComponentTableColumn('details', T('personstories.storyStatus'), personStory => (
      <PersonStoryStatusView personStory={personStory} />
    ), { sortable: true }),
    new StringTableColumn('published_date', T('personstories.published.date'), personStory => (
      personStory.published_date ? new Date(personStory.published_date).toLocaleDateString() : T('personstories.notPublished')
    ))
  ];

  if (canEdit) {
    columns.push(new ActionsTableColumn('actions', T('generic.list.actions'), () => tableRowActions(
      { icon: Icon.TimesCircle, style: TableRowActionStyle.Danger, onClick: onClickedDelete }
    ), { width: 80 }));
  }
  return columns;
}
