import React, { CSSProperties } from 'react';
import { IDocument } from "../../models/IDocument";
import { Badge, Card } from "react-bootstrap";
import { Colors, TextStyles } from "../../config/styles";
import EmptyMessage from "../../pages/EditPerson/components/EmptyMessage";
import { T } from "../../Translate";
import { LoadingIcon } from "../../components/Icons";



const styleColumn: CSSProperties = {width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}
const styleRow: CSSProperties = {width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}



interface MoveDocumentPreviewProps {
  document?: IDocument
  isLoading: boolean
}

const MoveDocumentPreview = (props: MoveDocumentPreviewProps) => {

  const { document, isLoading } = props

  if (!document)
    return (
      <Card style={{backgroundColor: Colors.verylightGray}}>
        <Card.Body>
          <EmptyMessage height={150} message={T('model.movedocument.noDocument')}>
            {isLoading && <span>&nbsp;<LoadingIcon /></span>}
          </EmptyMessage>
        </Card.Body>
      </Card>
    )

  return (
    <Card style={{backgroundColor: Colors.verylightGray}}>
      <Card.Body>
        <div>
          <div style={styleColumn}>
            <div style={styleRow}>
              <div style={{flex: 1}}>{document.title}</div>
            </div>
            <div style={styleRow}>
              <Badge variant={"secondary"}>
                <div style={{flex: 1}}>{document._id}</div>
              </Badge>
            </div>
            <div style={{...styleRow, marginTop: '0.75rem'}}>
              <p style={TextStyles.text.medium}>
                {document?.description ?? '...'}
              </p>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default MoveDocumentPreview
