import { useState, useCallback, useRef } from "react";
import { ValidationError, getErrorDescription } from "../models/ValidationResult";
import { AppState } from "../redux/State";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AnyAppAction } from "../redux/Actions";

export function useStateWithError<T>(initialValue: T): [
  T,
  (value: T) => void,
  string|undefined,
  (error: ValidationError|undefined) => void
] {
  const [value, setValue] = useState<T>(initialValue);
  const [error, setError] = useState<string|undefined>();

  const wrappedSetValue = (value: T) => {
    setValue(value);
    setError(undefined);
  };
  const setValidationError = (error: ValidationError|undefined) => {
    setError(getErrorDescription(error));
  };

  return [value, wrappedSetValue, error, setValidationError];
}

export function useAppDispatch() {
  return useDispatch<Dispatch<AnyAppAction>>();
}

export function useAppSelector<T>(projection: (state: AppState) => T) {
  return useSelector<AppState, T>(projection);
}

/**
 * This hook will always return the same function object, even if the callback
 * object changes. The call to the returned function object will be forwarded
 * to the callback that was last provided to this hook.
 * 
 * This solves the problem where a function dependency is needed in a hook
 * (eg. useEffect) and where the function should be able to change without
 * re-running the effect.
 * 
 * @param callback the target callback
 */
export function useRefresh(callback: () => void): () => void {
  const memo = useRef(callback);
  memo.current = callback;
  return useCallback(() => memo.current(), [memo]);
}
