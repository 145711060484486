import React from 'react';
import { NotificationManager } from 'react-notifications';
import { IPersonStory2 } from "../../models/PersonStory2";
import { ConfirmationModal } from "../../modals/Confirmation";
import { ModalTarget } from "../../modals/ModalTarget";
import api from '../../api';
import { T } from "../../Translate";


export const deletePersonStory = (personStory: IPersonStory2, modals: ModalTarget, completed: () => void) => {
  modals.show<boolean>(props => (
    <ConfirmationModal
      title={T('modal.removePersonStory.title')}
      message={T('modal.removePersonStory.message', { name: `${personStory.first_name + ' ' + personStory.family_name}`})}
      warning={T('generic.cannotUndo')}
      acceptText={T('generic.action.remove')}
      acceptStyle='danger'
      rejectText={T('generic.action.cancel')}
      {...props}
    />
  )).then(confirmed => {
    if (!confirmed)
      return;

    api.removePersonStory(personStory._id).then(() => {
      NotificationManager.success(T('modal.removePersonStory.removed'));
      completed();
    });
  });
}
