import React from 'react';
import { IAttachment, getAttachmentPreviewUrl } from '../models/Attachment';
import { Icon } from './Icons';
import { Button } from 'react-bootstrap';
import { AttachmentFileUploader } from './FileUploader';
import { NotificationManager } from 'react-notifications';
import { T } from '../Translate';

interface AttachmentViewProps {
  attachment: IAttachment;
  onClickedDelete: (attachment: IAttachment) => void;
}
export const AttachmentView = (props: AttachmentViewProps) => {
  const { attachment, onClickedDelete } = props;
  return (
    <div
      key={attachment._id}
      style={{ cursor: 'pointer', marginRight: 15, position: 'relative' }}
    >
      <Button
        variant='link'
        onClick={() => onClickedDelete(attachment)}
        style={{ position: 'absolute', right: 0, bottom: 0 }}
      >
        <i className={Icon.TimesCircle} />
      </Button>
      <img
        src={getAttachmentPreviewUrl(attachment)}
        alt=''
        style={{ height: 150, borderRadius: 5, border: '1px solid #888' }}
      />
    </div>
  );
}

interface AttachmentListViewProps {
  attachments: IAttachment[];
  collection?: string;
  collectionId?: string;
  maxItems?: number;

  onClickedDelete: (attachment: IAttachment) => void;
  onCreated: (attachment: IAttachment) => void;
}
export const AttachmentListView = (props: AttachmentListViewProps) => {
  const { attachments, collection, collectionId, onClickedDelete, onCreated, maxItems } = props;

  const handleCreated = (attachments: IAttachment[]|'NOK') => {
    if (attachments === 'NOK') {
      NotificationManager.error(T('generic.errors.fileUploadFailed'));
    } else {
      onCreated(attachments[0]);
    }
  };

  const attachmentViews = attachments.map(attachment => (
    <AttachmentView
      key={attachment._id}
      attachment={attachment}
      onClickedDelete={onClickedDelete}
    />
  ));
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {attachmentViews}
      {(collection === undefined || collectionId !== undefined) && (maxItems === undefined || attachments.length < maxItems) && (
        <AttachmentFileUploader
          buttonStyle={{ width: 150, height: 200, borderRadius: 0 }}
          collectionId={collectionId}
          collection={collection}
          onSaved={handleCreated}
        />
      )}
    </div>
  );
}
