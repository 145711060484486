import { Icon } from "./Icons";
import React from "react";
import moment from "moment";

interface DateTimeOutputProps {
  from?: string;
  to?: string;
}
export const DateTimeFromToOutput = (props: DateTimeOutputProps) => {
  const { from, to } = props;
  let fromDateTimeString = '???'
  let toDateTimeString = '???'
  if (from) {
    const datetime = moment(from);
    fromDateTimeString = datetime.format('YYYY-MM-DD HH:mm')
  }
  if (to) {
    const datetime = moment(to);
    toDateTimeString = datetime.format('YYYY-MM-DD HH:mm')
  }

  return (
    <span><i style={{marginRight: 6}} className={Icon.Clock}/>{fromDateTimeString} - {toDateTimeString}</span>
  );
}
