import React, { useMemo, useEffect, useState } from 'react';
import { IQueryResult } from '../../models/Generic';
import api from '../../api';
import { T, plural } from '../../Translate';
import { Card } from 'react-bootstrap';
import { updatePagesStateAction } from '../../redux/Actions';
import { PagePropsNoPending } from '../Page';
import { useAppDispatch, useAppSelector, useRefresh } from '../../utils/Functional';
import { SortDirection, Table } from "../../components/table/Table";
import { Search } from "../../components/Search";
import { useDelayedUpdateEffect } from "../../utils/UpdateEffects";
import { Document, DocumentCategory } from "../../models/Document";
import { useDocumentTagDefinitions } from "../../FunctionalAPI";
import { TextStyles } from "../../config/styles";
import { DocumentTagTypeInput, getFullTagName } from "../../components/inputs/DocumentTagTypeInput";
import { classes } from "../../utils/Styles";
import { Icon } from "../../components/Icons";
import AddDocumentationControl from "../ListDocumentation/AddDocumentationControl";
import { DocumentTagDefinition } from "../../models/DocumentTagDefinition";
import { CheckAdlibCodeResult } from "../../models/CheckAdlibCodeResult";
import AddAdlibDocumentModal from "../ListDocumentation/AddAdlibDocumentModal";
import { getColumns } from "../ViewCollection/Columns";
import { PagesState } from "../../redux/State";
import { ListState } from "../ListPage";
import { ListDocumentationPageState } from "../ListDocumentation";
import { getPageLinkWithVia, PageID } from "../../utils/PageLinking";


const PageSize = 10;
const rowKey = (item: Document) => item.id;

interface ListTabProps<K extends keyof PagesState> extends PagePropsNoPending {
  ref_id: string;
  ref_name: string;
  readonly: boolean;
  ref_type: 'person'|'place'|'memorial'|'military_entity';
  pageStateKey: K;
}

function useListDocumentationPageState<K extends keyof PagesState>(page: K): [ListDocumentationPageState, (updates: Partial<ListDocumentationPageState>) => void] {
  const state = useAppSelector(state => state.pagesState[page]);
  const dispatch = useAppDispatch();
  const update = (updates: Partial<ListDocumentationPageState>) => {
    dispatch(updatePagesStateAction(page, updates as Partial<PagesState[K]>));
  };
  return [state as ListDocumentationPageState, update];
}


/**
 *
 */
const ListDocumentsTab = <K extends keyof PagesState, T> (props: ListTabProps<K>) => {


  const { history, loading, via, modals, readonly, ref_type, ref_name, ref_id, pageStateKey } = props;

  /*** state ***/

  const [pageState, updatePageState] = useListDocumentationPageState(pageStateKey) ;
  const [items, setItems] = useState<IQueryResult<Document> | undefined>(undefined);
  const tagDefinitions = useDocumentTagDefinitions(loading);
  const [filterInput, setFilterInput] = useState(pageState.filter);
  const currentPage = useAppSelector(state => state.activePageState);

  /*** variables ***/

  const { filter, tablePage, sortColumn, sortDirection, tagFilter } = pageState as ListDocumentationPageState;
  const tag = tagFilter === null ? undefined : tagDefinitions.indexed[tagFilter];

  const columns = useMemo(() => getColumns(), []);


  /*** api ***/

  const loadDocuments = (skip: number, limit: number): Promise<IQueryResult<Document>|undefined> => {
    const { filter, sortColumn, sortDirection } = pageState as ListState;

      loading.loading(api.findCollectionDocuments(
        tagFilter ? { 'tags.tag': tagFilter } : {},
        filter,
        tablePage * PageSize,
        PageSize,
        [[getSortColumn(sortColumn), sortDirection]],
        ['collection'],
        props.ref_type,
        props.ref_id,
      ), plural('document'))
        .then(result => {
          setItems({
            total: result.total,
            data: result.data.map(document => {
              const newDocument = Document.fromJSON(document, tagDefinitions);
              return newDocument;
            })
          });
        });
    return Promise.resolve(undefined);
  }

  /*** effects ***/

  const refresh = useRefresh(() => {
    loadDocuments(tablePage * PageSize, PageSize).then(data => {
      setItems(data);
    })});

  useEffect(refresh, [tablePage, sortColumn, sortDirection, filter, tagFilter, ref_id]);

  useDelayedUpdateEffect(() => {
    refresh();
    updatePageState({filter: filterInput, tablePage: 0})},
    500,
    [filterInput])


  /*** table handlers ***/

  const handlePageChanged = (page: number) => {
    updatePageState({ tablePage: page });
  };

  const handleSortChanged = (column: string, direction: SortDirection) => {
    updatePageState({ sortColumn: column, sortDirection: direction });
  };

  const handleClickedRow = (row: Document) => {
    history.push(getPageLinkWithVia(PageID.ViewDocument, row.id, currentPage.id, currentPage.entity));
  };

  const handleTagSelected = (tag: DocumentTagDefinition|undefined) => {
    updatePageState({ tagFilter: tag ? tag.id : null });
  };

  const handleClickedClearTag = () => {
    updatePageState({ tagFilter: null });
  };

  const handleClickedAdd = async (category: DocumentCategory) => {
    if (category === DocumentCategory.Adlib) {
      const check = await modals.show<CheckAdlibCodeResult|undefined>(
        props => <AddAdlibDocumentModal {...props} />
      );
      if (check === undefined)
        return;

      if (check.type === 'document') {
        history.push(`/documents/create?category=${DocumentCategory.Adlib}&code=${check.code}&via=${via}`);
      } else if (check.type === 'collection') {
        history.push(`/documents/create?category=${DocumentCategory.AdlibCollectionDocument}&code=${check.code}&via=${via}`);
      }
    } else {
      history.push(`/documents/create?category=${category}&ref_id=${ref_id}&ref_type=${ref_type}&ref_name=${ref_name}&via=${via}`);
    }
  };

  /*** table helpers ***/

  const getSortColumn = (column: string) => {
    switch (column) {
      case 'title':
        return 'filter'
      default:
        return column;
    }
  }

  /*** rendering ***/

  const renderPersonsHeader = () => {
    return (
      <>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyItems: 'space-between', alignItems: 'baseline' }} >
          <div style={{flexGrow: 2}}>
          <Search name='search' value={filterInput} onChange={setFilterInput} />
          </div>
          {tag ? (
            <div style={{flexGrow: 2}}>
              <span style={{...TextStyles.label.medium, marginRight: 6}}>{T('generic.filter') + ':'}</span>
              <span style={{...TextStyles.text.medium}}>{getFullTagName(tagDefinitions, tag)}</span>
              {' '}
              <i onClick={handleClickedClearTag} className={classes(Icon.TimesCircle, 'clickable-icon')} />
            </div>
          ) : (
            <div style={{flexGrow: 2}}>
              <DocumentTagTypeInput
                onSelected={handleTagSelected}
                placeholder={T('document.tags.filter')}
              />
            </div>
          )}
          <div style={{ flexGrow: 1 }}>
          </div>
          {!readonly &&
            <div style={{flexGrow: 2}}>
              <AddDocumentationControl onClickedAdd={handleClickedAdd}/>
            </div>
          }
        </div>

      </>
    );
  }


  return (
    <Card style={{ marginTop: '1em' }} className='table-with-links'>
      <Card.Header>
        {renderPersonsHeader()}
      </Card.Header>
      <Card.Body className='table-full-width'>
        <Table
          displayedItems={items?.data || []}
          columns={columns}
          rowKey={rowKey}
          onClickItem={handleClickedRow}
          onPageChanged={handlePageChanged}
          onSortChanged={handleSortChanged}
          sortDirection={sortDirection}
          sortColumn={sortColumn}
          page={tablePage}
          pageSize={PageSize}
          totalItems={items?.total ?? 0}
          noun='person'
        />
      </Card.Body>
    </Card>
  );
}


export default ListDocumentsTab;
