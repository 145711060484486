import { T } from "../../../Translate";
import React from "react";
import { StoryStatus } from "../../../models/PersonStory2";
import { Badge } from "react-bootstrap";

export interface PersonStoryStatusViewProps {
  storyStatus: StoryStatus
}

export const PersonStoryStatusView = (props: PersonStoryStatusViewProps) => {

  let title = T("personstories.status.inprogress");
  let variant: 'secondary' | 'warning' | 'success' = 'secondary';
  switch(props.storyStatus) {
    case StoryStatus.IN_PROGRESS:
      break;
    case StoryStatus.IN_REVIEW:
      title = T("personstories.status.inreview");
      variant = 'warning';
      break;
    case StoryStatus.DONE:
      title = T("personstories.status.done");
      variant='success'
  }
  return (<Badge style={{marginLeft: 4, marginRight: 4}}variant={variant}>{title}</Badge>)
}
