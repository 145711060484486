import { IAPIError } from "../api";


export function convertNonJSONRPCRequestError(error: any) {
  if ("readyState" in error && error.readyState === 0) {
    const noConnectionError: IAPIError = {
      code: "network",
      message: "No Network Response",
      data: undefined
    }
    return noConnectionError;
  }
  // handle http errors
  if ("status" in error && error.status >= 400) {
    const httpError: IAPIError = {
      code: 'http',
      message: error.statusText,
      data: undefined
    }
    return httpError
  }
  return error;
}
