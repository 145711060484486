import React, { useMemo, useState } from 'react';
import { History } from 'history';
import { ISpecialFunctionTableResult, SpecialFunctionTableItem } from '../../models/SpecialFunction';
import { Table, sortItems, SortDirection } from '../../components/table/Table';
import { StringTableColumn, ActionsTableColumn, ITableColumn, IColumnOptions, ICellProps, DefaultColumnOptions, ComponentTableColumn } from '../../components/table/Fields';
import { T, Noun, TranslationKey } from '../../Translate';
import { Icon } from '../../components/Icons';
import { TimestampTableColumn } from './TimestampTableColumn';
import { Checkbox } from '../../components/inputs/CheckboxInput';
import { PageID } from '../../utils/PageLinking';
import styles from '../../components/table/Table.module.scss';
import { classes } from '../../utils/Styles';
import { translateVictimType, translateWarCasualty, VictimType } from '../../models/Person';

const ColumnTitles: {[key: string]: TranslationKey} = {
  FIRST_NAME: 'person.firstName',
  FAMILY_NAME: 'person.lastName',
  BORN: 'person.born',
  DIED: 'person.died',
  VICTIM_TYPE: 'person.type',
  TITLE: 'document.title',
  NAME: 'memorial.name',
  TYPE: 'memorial.type'
};
function getColumnTitle(title: string): string {
  const translationKey = ColumnTitles[title];
  return translationKey ? T(translationKey) : title;
}

interface SpecialFunctionResultTableProps {
  result: ISpecialFunctionTableResult;
  history: History;
  selection: string[];
  onSelected: (id: string, selected: boolean) => void;
}

function getNoun(result: ISpecialFunctionTableResult): Noun {
  switch (result.elementType) {
    case 'person':
      return 'person';
    case 'place':
      return 'place';
    case 'memorial':
      return 'memorial';
    case 'personStory':
      return 'personStory';
    default:
      return 'result';
  }
}

class SelectColumn implements ITableColumn<SpecialFunctionTableItem, string[]> {
  id: string;
  title: string;
  options: IColumnOptions;
  onSelected: (id: string, selected: boolean) => void;

  public constructor(onSelected: (id: string, selected: boolean) => void) {
    this.id = 'selected';
    this.title = 'X';
    this.options = DefaultColumnOptions;
    this.onSelected = onSelected;
  }

  render(item: SpecialFunctionTableItem, props: ICellProps, state: string[]): JSX.Element {
    const id = item._id as string;
    const checked = state.includes(id);
    return <td {...props}>
      <Checkbox id={'select-' + id} value={checked} onChange={checked => this.onSelected(id, checked)} />
    </td>
  }

  sort = (a: SpecialFunctionTableItem, b: SpecialFunctionTableItem) => 0;
}

export class DateColumn implements ITableColumn<SpecialFunctionTableItem> {
    id: string;
    title: string;
    options: IColumnOptions;
  
    constructor(
      id: string,
      title: string,
      options?: Partial<IColumnOptions>
    ) {
      this.id = id;
      this.title = title;
      this.options = Object.assign({}, DefaultColumnOptions, options);
    }

    getValue(item: SpecialFunctionTableItem) {
      const value = item[this.id] as { day: number, month: number, year: number};
      if (value.day === 0 && value.month === 0 && value.year === 0)
        return '';
      
      const day = value.day === 0 ? '?' : value.day;
      const month = value.month === 0 ? '?' : value.month;
      const year = value.year === 0 ? '?' : value.year;
      return `${day}/${month}/${year}`;
    }
  
    render(item: SpecialFunctionTableItem, props: ICellProps): JSX.Element {
      const value = this.getValue(item);
      if (value === undefined)
        props.className = classes(styles.noValue, props.className);
      
      return <td key={this.id} {...props}>{value === undefined ? 'N/A' : value}</td>;
    }
  
    sort = (a: SpecialFunctionTableItem, b: SpecialFunctionTableItem): number => {
      const valueA = a[this.id] as { day: number, month: number, year: number};
      const valueB = b[this.id] as { day: number, month: number, year: number};
      if (valueA.year !== valueB.year)
        return valueA.year - valueB.year;
      else if (valueA.month !== valueB.month)
        return valueA.month - valueB.month;
      else
        return valueA.day - valueB.day;
    }
  }
  

export const SpecialFunctionResultTable = (props: SpecialFunctionResultTableProps) => {
  const { result, history, selection, onSelected } = props;

  const noun = getNoun(result);
  const columns = useMemo(() => {
    const cols: ITableColumn<SpecialFunctionTableItem>[] = result.columns.map(column => {
      const title = getColumnTitle(column.title);
      if (column.type === 'date') {
        return new DateColumn(column.field, title);
      } else if (column.type === 'timestamp') {
        return new TimestampTableColumn<SpecialFunctionTableItem>(
          column.field,
          title,
          item => item[column.field] as string
        );
      } else if (column.type === 'name') {
        return new ComponentTableColumn('name', T('listPersons.column.name'), person => {
          const altFirstNames = person.firstNameAlt ? ' (' + person.firstNameAlt + ')' : '';
          const altLastNames = person.lastNameAlt ? ' (' + person.lastNameAlt + ')' : '';
          return (
            <span>
              <strong className={styles.ellipsized}>{person.lastName}{altLastNames}</strong>
              <br />
              <span className={styles.ellipsized}>{person.firstName}{altFirstNames}</span>
            </span>
          );
        }, { sortable: true, clickable: true }, (a, b) => (a.name as string).localeCompare(b.name as string));
      } else if (column.type === 'victimType') {
        return new ComponentTableColumn(column.field, title, person => {
          const army = (person.armies as any)[0];
          return <>
            {translateVictimType(person.victimType as VictimType) + ' ' + translateWarCasualty(person.warCasualty as boolean)}
            <br />
            {army && `(${army}) `}{person.inNamelist ? T('person.isNamelist') : ''}
          </>;
        });
      } else {
        return new StringTableColumn<SpecialFunctionTableItem>(
          column.field,
          title,
          item => item[column.field] as string
        );
      }
    });
    if (result.actions && result.actions.length > 0) {
      cols.push(new SelectColumn(onSelected));
    }
    switch (result.elementType) {
      case 'person':
        cols.push(new ActionsTableColumn('actions', T('generic.list.actions'), person => [
          { icon: Icon.Edit, page: PageID.EditPerson, pageParam: person._id as string }
        ]));
        break;
      case 'place':
        cols.push(new ActionsTableColumn('actions', T('generic.list.actions'), place => [
          { icon: Icon.Edit, page: PageID.EditPlace, pageParam: place._id as string }
        ]));
        break;
      case 'memorial':
        cols.push(new ActionsTableColumn('actions', T('generic.list.actions'), memorial => [
          { icon: Icon.Edit, page: PageID.EditMemorial, pageParam: memorial._id as string }
        ]));
        break;
      case 'personStory':
        cols.push(new ActionsTableColumn('actions', T('generic.list.actions'), story => [
          { icon: Icon.Edit, page: PageID.EditPersonStory, pageParam: story._id as string }
        ]));
        break;
      }
    return cols;
  }, [result]);

  const [page, setPage] = useState(0);
  const [sortColumn, setSortColumn] = useState(columns[0].id);
  const [sortDirection, setSortDirection] = useState(SortDirection.Up);
  const handleSortUpdated = (column: string, direction: SortDirection) => {
    setSortColumn(column);
    setSortDirection(direction);
  };
  const sortedData = useMemo(
    () => sortItems(columns, result.data, sortColumn, sortDirection),
    [result, columns, sortColumn, sortDirection]
  );
  const onClickItem = useMemo(() => {
    let itemClickHandler: ((item: SpecialFunctionTableItem) => void)|undefined = undefined;
    switch (result.elementType) {
      case 'person':
        return (item: SpecialFunctionTableItem) => history.push('/persons/' + item._id);
      case 'place':
        return (item: SpecialFunctionTableItem) => history.push('/places/' + item._id);
      case 'memorial':
        return (item: SpecialFunctionTableItem) => history.push('/memorials/' + item._id);
    }
    return itemClickHandler;
  }, [result, history, onSelected]);

  return (
    <Table
      displayedItems={sortedData.slice(page * 10, page * 10 + 10)}
      columns={columns}
      onPageChanged={setPage}
      onSortChanged={handleSortUpdated}
      onClickItem={onClickItem}
      
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      page={page}
      pageSize={10}
      totalItems={result.data.length}
      noun={noun}
      state={selection}
    />
  );
}
