import React, { useEffect, useState, useMemo } from 'react';
import $ from 'jquery';
import { BasePage, PageProps, PageState } from '../Page';
import {
  ISpecialFunctionCategory,
  SpecialFunctionResult,
  getSpecialFunctionCategoryName,
  ISpecialFunction,
  SpecialFunctionArguments,
  ValidationErrors,
  getDefaultArguments
} from '../../models/SpecialFunction';
import { Icon } from '../../components/Icons';
import { T } from '../../Translate';
import { FormCard } from '../../components/FormCard';
import { Row, Col, Button } from 'react-bootstrap';
import api from '../../api';
import { MarkdownView } from '../../components/MarkdownView';
import { SpecialFunctionForm } from './SpecialFunctionForm';
import { SpecialFunctionResultTable } from './SpecialFunctionResultTable';
import { ButtonRow } from '../../components/ButtonRow';
import { PageID } from '../../utils/PageLinking';

interface ActionButtonProps {
  selection: string[];
  actions: string[];
  onExecute: (action: string) => void;
}

const ActionButton = (props: ActionButtonProps) => {
  const { selection, actions, onExecute } = props;
  const [action, setAction] = useState('');
  useEffect(() => {
    if (actions.length > 0 && !actions.includes(action))
      setAction(actions[0]);
  }, [actions, action]);

  const handleExecute = () => onExecute(action);

  const renderedActions = useMemo(() => actions.map(action => (
    <option key={action} value={action}>{action}</option>
  )), [actions]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <h4 className='card-title' style={{ whiteSpace: 'nowrap', marginRight: 10 }}>{selection.length === 0 ? 'None' : selection.length} Selected</h4>
      <select
        className='form-control'
        value={action}
        onChange={e => setAction(e.currentTarget.value)}
      >
        {renderedActions}
      </select>
      <Button onClick={handleExecute} style={{ marginBottom: 0, marginLeft: 10 }}>Run</Button>
    </div>
  );
}

interface SpecialFunctionsPageState extends PageState {
  categories: ISpecialFunctionCategory[];
  result?: SpecialFunctionResult;
  selectedCategory: string;
  selectedFunction?: ISpecialFunction;

  categoryOptions: JSX.Element[];
  functionOptions: JSX.Element[];
  args: SpecialFunctionArguments;
  errors: ValidationErrors;

  selection: string[];
}
export default class SpecialFunctionsPage extends BasePage<PageProps, SpecialFunctionsPageState> {
  constructor(props: PageProps) {
    super(props);
    
    this.state = {
      page: {
        id: PageID.SpecialFunctions,
        icon: Icon.Database,
        title: T('page.specialFunctions.title')
      },

      categories: [],
      categoryOptions: [],
      functionOptions: [],
      selectedCategory: '',
      args: {},
      errors: {},

      selection: []
    };
  }

  loadContent() {
    this.loading(api.getFunctions(), T('page.specialFunctions.functions'))
      .then(categories => {
        this.setState({
          categories,
          categoryOptions: categories.map(category => (
            <option
              key={category.category}
              value={category.category}
            >
              {getSpecialFunctionCategoryName(category.category)}
            </option>
          ))
        }, () => this.setCategory(categories[0].category));
      });
  }

  getCategory(): ISpecialFunctionCategory|undefined {
    const { categories, selectedCategory } = this.state;
    return categories.find(category => category.category === selectedCategory);
  }

  setCategory(categoryName: string) {
    const { categories } = this.state;
    const category = categories.find(c => c.category === categoryName) || categories[0];
    if (!category) // can happen if categories aren't loaded yet
      return;
    
    this.setState({
      selectedCategory: category.category,
      functionOptions: category.functions.map(fn => (
        <option key={fn.name} value={fn.name}>{fn.displayName}</option>
      ))
    });
    this.setFunction(category, category.functions[0].name);
  }

  setFunction(category: ISpecialFunctionCategory, functionName: string) {
    const fn = category.functions.find(f => f.name === functionName);
    this.setState({
      selectedFunction: fn,
      args: getDefaultArguments(fn),
      errors: {}
    });
  }

  handleCategoryChanged = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    this.setCategory(e.currentTarget.value);
  }

  handleFunctionChanged = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    const category = this.getCategory();
    if (!category)
      return;
    
    this.setFunction(category, e.currentTarget.value);
  }

  handleArgumentsChanged = (args: SpecialFunctionArguments) => {
    this.setState({ args });
  }

  handleClickedExecute = () => {
    const { selectedFunction, args } = this.state;
    if (!selectedFunction)
      return;
    
    api.validateFunction(selectedFunction.name, args).then(result => {
      if (result.status !== 'OK') {
        this.setState({ errors: result.data || {} });
        return;
      }

      this.loading(api.callFunction(selectedFunction.name, args), T('page.specialFunctions.results'))
        .then(result => this.setState({ result, selection: [] }));
    });
  }

  handleClickedExecuteToCSV = () => {
    const { selectedFunction, args } = this.state;
    if (!selectedFunction)
      return;
    
    api.validateFunction(selectedFunction.name, args).then(result => {
      if (result.status !== 'OK') {
        this.setState({ errors: result.data || {} });
        return;
      }
      
      var urlParams = {
          format: 'csvfile',
          method: 'callFunctionCsv',
          params: JSON.stringify({
            token: api.token,
            name: selectedFunction.name,
            arguments: args
          })
      };
      var url = api.url + '?' + $.param(urlParams);
      window.open(url, '_blank');
    });
  }

  handleSelected = (id: string, selected: boolean) => {
    const newSelection = selected
      ? [...this.state.selection, id]
      : this.state.selection.filter(x => x !== id);
    this.setState({ selection: newSelection });
  };

  handleExecuteAction = async (action: string) => {
    const { selectedFunction, selection } = this.state;
    if (!selectedFunction)
      return;
    
    await api.applyAction(selectedFunction.name, action, selection);
    this.setState({ selection: [] });
  };

  renderContent() {
    const { history } = this.props;
    const {
      categoryOptions,
      functionOptions,
      selectedCategory,
      selectedFunction,
      args,
      errors,
      result,
      selection
    } = this.state;

    return <>
      <FormCard
        icon={Icon.Gear}
        title={T('page.specialFunctions.functions')}
        style={{ marginTop: '1em' }}
      >
        <Row>
          <Col md={3}>
            <h5><i className={Icon.Sitemap} /> {T('page.specialFunctions.category')}</h5>
            <select
              className='form-control'
              value={selectedCategory}
              onChange={this.handleCategoryChanged}
              size={6}
            >
              {categoryOptions}
            </select>
          </Col>
          <Col md={3}>
            <h5><i className={Icon.Gear} /> {T('page.specialFunctions.function')}</h5>
            <select
              className='form-control'
              value={selectedFunction ? selectedFunction.name : ''}
              onChange={this.handleFunctionChanged}
              size={6}
            >
              {functionOptions}
            </select>
          </Col>
          {selectedFunction && <Col md={6}>
            <MarkdownView content={selectedFunction.description} />
          </Col>}
        </Row>
      </FormCard>
      <FormCard
        icon={Icon.Gears}
        title={T('page.specialFunctions.parameters')}
      >
        {selectedFunction && (
          <SpecialFunctionForm
            args={args}
            func={selectedFunction}
            errors={errors}
            onArgumentsChange={this.handleArgumentsChanged}
          />
        )}
        <ButtonRow>
          <Button onClick={this.handleClickedExecuteToCSV}>
            {T('page.specialFunctions.executeToCSV')}
          </Button>
          <Button onClick={this.handleClickedExecute}>
            {T('page.specialFunctions.execute')}
          </Button>
        </ButtonRow>
      </FormCard>
      {result && (
        <FormCard
          icon={Icon.ListUl}
          title={T('page.specialFunctions.results')}
          buttons={result.type === 'table' && result.actions !== undefined && (
            <ActionButton selection={selection} actions={result.actions || []} onExecute={this.handleExecuteAction} />
          )
        }>
          {result.type === 'text' && <p>{result.content}</p>}
          {result.type === 'table' && (
            <SpecialFunctionResultTable
              result={result}
              history={history}
              selection={selection}
              onSelected={this.handleSelected}
            />
          )}
        </FormCard>
      )}
    </>;
  }
}
