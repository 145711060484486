import React from 'react';
import { DocumentReference } from "../../models/DocumentReference";
import PageLink from "../../components/PageLink";
import { Colors, TextStyles } from "../../config/styles";


interface DocumentReferencesViewProps {
  documentReferences: DocumentReference[]
}

export const DocumentReferencesView = (props: DocumentReferencesViewProps) => {
  const { documentReferences } = props;

  return (
    <>
      {documentReferences.map((value, index) => (
        <span>
          <i className={value.getIcon()} style={{ color: Colors.linkBlue, paddingRight:2, ...TextStyles.flatlink.small }} />
          <PageLink style={{...TextStyles.link.small}} key={value.id} page={value.getLink().page} param={value.getLink().param}>{value.toString()}</PageLink>
          <span style={{marginRight: 8}}/>
        </span>
      ))}
    </>
  );
}
