import React, { useState, CSSProperties } from 'react';
import { Backdrop } from './Backdrop';
import styles from './FullscreenImageView.module.scss';
import { Icon } from './Icons';
import { IconButton } from './IconButton';

interface FullscreenImageViewProps {
  src: string;
  caption?: string;
  onClick: () => void;
}

type DragState = { dragging: false } | {
  dragging: true;
  dragStartX: number;
  dragStartY: number;
  dragStartMouseX: number;
  dragStartMouseY: number;
}
interface Offsets {
  offsetX: number;
  offsetY: number;
  scale: number;
}

export const FullscreenImageView = (props: FullscreenImageViewProps) => {
  const { src, caption, onClick } = props;

  const [dragState, setDragState] = useState<DragState>({ dragging: false });
  const [offsets, setOffsets] = useState<Offsets>({
    offsetX: 0,
    offsetY: 0,
    scale: 1
  });

  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setDragState({
      dragging: true,
      dragStartX: offsets.offsetX,
      dragStartY: offsets.offsetY,
      dragStartMouseX: e.pageX,
      dragStartMouseY: e.pageY
    });
  }

  const handleMouseUp = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (dragState.dragging) {
      setDragState({ dragging: false });
    }
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (dragState.dragging) {
      const offsetX = e.pageX - dragState.dragStartMouseX + dragState.dragStartX;
      const offsetY = e.pageY - dragState.dragStartMouseY + dragState.dragStartY;
      setOffsets({ offsetX, offsetY, scale: offsets.scale });
    }
  }

  const handleMouseOut = (e: React.MouseEvent<HTMLElement>) => {
    if (dragState.dragging) {
      setDragState({ dragging: false });
    }
  }

  const zoom = (relativeX: number, relativeY: number, scale: number) => {
    // Calculation to zoom in at the cursor position:
    // the pixel at the given pointer position must point to the same before and after scaling
    // relativeX = offsetX + scale * documentX
    // relativeX = newOffsetX + newScale * documentX
    // we can substitute documentX:
    // documentX = (relativeX - offsetX) / scale = (relativeX - newOffsetX) / newScale
    // <=> (relativeX - offsetX) * newScale / scale = relativeX - newOffsetX
    // <=> newOffsetX = relativeX - (relativeX - offsetX) * newScale / scale
    // -- same calculation is valid for Y

    const offsetX = relativeX - (relativeX - offsets.offsetX) * scale / offsets.scale;
    const offsetY = relativeY - (relativeY - offsets.offsetY) * scale / offsets.scale;
    setOffsets({ offsetX, offsetY, scale });
  };

  const handleScroll = (e: React.WheelEvent<HTMLImageElement>) => {
    e.preventDefault(); // prevent scrolling the page

    let rect = e.currentTarget.getBoundingClientRect();
    let relativeX = e.clientX - (rect.left + rect.right) / 2;
    let relativeY = e.clientY - (rect.top + rect.bottom) / 2;

    let newScale = e.deltaY > 0 ? offsets.scale * 0.9 : offsets.scale / 0.9;
    zoom(relativeX, relativeY, newScale);

    // if we're dragging while zooming, make sure things don't jump around
    // (this will "reset" drag variables as if a new drag operation has been started)
    if (dragState.dragging) {
      setDragState({
        dragging: true,
        dragStartX: offsets.offsetX,
        dragStartY: offsets.offsetY,
        dragStartMouseX: e.pageX,
        dragStartMouseY: e.pageY
      });
    }
  };

  const zoomAtCenter = (scale: number) => {
    setOffsets({ offsetX: offsets.offsetX, offsetY: offsets.offsetY, scale });
  };

  const handleClickedZoomIn = (e: React.MouseEvent) => {
    e.stopPropagation();
    zoomAtCenter(offsets.scale / 0.8);
  };

  const handleClickedZoomOut = (e: React.MouseEvent) => {
    e.stopPropagation();
    zoomAtCenter(offsets.scale * 0.8);
  };

  const style: CSSProperties = {
    maxWidth: '90%',
    maxHeight: '90%',
    position: 'relative',
    left: offsets.offsetX,
    top: offsets.offsetY,
    transform: `scale(${offsets.scale})`
  };

  return (
    <Backdrop onClick={onClick} dark={true}>
      <img
        src={src}
        onClick={e => e.stopPropagation()}
        onDrag={e => e.preventDefault()}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
        onWheel={handleScroll}
        className={styles.nodrag}
        style={style}
        draggable={false}
        alt=''
      />
      <div className={styles.zoomButtons}>
        <IconButton
          icon={Icon.SearchPlus}
          onClick={handleClickedZoomIn}
        />
        <IconButton
          icon={Icon.SearchMinus}
          onClick={handleClickedZoomOut}
        />
      </div>
      {caption && <div className={styles.caption}>{caption}</div>}
      <div className={styles.closebutton}>
        <i className={Icon.TimesCircle} />
      </div>
    </Backdrop>
  );
};
