import React, { useState } from 'react';
import { ModalProps, useModal } from '../../modals/BaseModal';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { ModalHeader } from '../../modals/ModalHeader';
import { T } from '../../Translate';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { TextInput } from '../../components/inputs/TextInput';
import api from '../../api';
import { CheckAdlibCodeResult } from '../../models/CheckAdlibCodeResult';

interface AddAdlibDocumentModalProps extends ModalProps<CheckAdlibCodeResult|undefined> {
  
}

export default (props: AddAdlibDocumentModalProps) => {
  const [isOpen, resolve] = useModal(props);
  const handleClose = () => resolve(undefined);
  const [adlibCode, setAdlibCode] = useState('');
  const [error, setError] = useState<string>();

  const handleClickedCreate = async () => {
    setError(undefined);
    try {
      const result = await api.checkAdlibCode(adlibCode);
      if (result.type === 'invalid') {
        setError(T('page.documentation.addAdlibDocument.invalidCode'));
      } else {
        resolve(result);
      }
    }
    catch (err) {
      if (err.code === '404.10') {
        setError(T('page.documentation.addAdlibDocument.codeNotFound'));
      } else {
        setError(err.message);
      }
    }
  };

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <ModalHeader>{T('page.documentation.addAdlibDocument.title')}</ModalHeader>
      <Modal.Body>
        <Form>
          <TextInput
            name='add-document-code'
            label={T('page.documentation.addAdlibDocument.code')}
            value={adlibCode}
            onChange={setAdlibCode}
          />
        </Form>
        {error && <Alert variant='danger'>{error}</Alert>}
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant="secondary" onClick={handleClose}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={handleClickedCreate}>
          {T('generic.action.create')}
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
