import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from '../../components/Icons';
import { plural, T } from '../../Translate';
import {
  ActionsTableColumn,
  ColumnAlign, ComponentTableColumn,
  ITableColumn,
  StringFieldTableColumn,
  StringTableColumn,
  tableRowActions,
  TableRowActionStyle
} from '../../components/table/Fields';
import { describeMemorialKind, desribeMemorialPlace, GetMemorialOption, IMemorial } from '../../models/Memorial';
import api from '../../api';
import { IQueryResult } from '../../models/Generic';
import { ConfirmationModal } from '../../modals/Confirmation';
import { NotificationManager } from 'react-notifications';
import { ListPage, ListState } from '../ListPage';
import { PageProps } from '../Page';
import { usePageState } from '../../redux/Actions';
import { useModalContext } from '../../modals/ModalContext';
import { PageID } from '../../utils/PageLinking';
import { MemorialPlaceView } from "./MemorialPlaceView";

export type ListMemorialsStateProps = ListState;

function getSortColumn(column: string) {
  return column === 'place' ? 'calc_place_name' : column;
}

const rowKey = (memorial: IMemorial) => memorial._id;
const PageSize = 10;

export default (props: PageProps) => {
  const { history, loading, via } = props;

  const modals = useModalContext();
  const user = api.user;

  const [pageState, updatePageState] = usePageState('memorials');
  const { filter, tablePage, sortColumn, sortDirection } = pageState;
  const [items, setItems] = useState<IQueryResult<IMemorial>>();

  const refresh = useCallback(() => {
    loading.loading(api.findMemorials(
      filter,
      tablePage * PageSize,
      PageSize,
      [[getSortColumn(sortColumn), sortDirection]],
      [GetMemorialOption.NumberOfPersons, GetMemorialOption.PlaceNames, GetMemorialOption.Place]
    ), plural('memorial'))
      .then(setItems);
  }, [loading, filter, tablePage, sortColumn, sortDirection]);
  useEffect(refresh, [refresh]);

  const columns = useMemo(() => {
    const handleClickedDelete = (memorial: IMemorial) => {
      modals.show<boolean>(props => (
        <ConfirmationModal
          title={T('modal.removeMemorial.title')}
          message={T('modal.removeMemorial.message', { name: memorial.name })}
          warning={T('generic.cannotUndo')}
          acceptText={T('generic.action.remove')}
          acceptStyle='danger'
          rejectText={T('generic.action.cancel')}
          {...props}
        />
      )).then(confirmed => {
        if (!confirmed)
          return;

        api.removeMemorial(memorial._id).then(() => {
          NotificationManager.success(T('modal.removeMemorial.removed'));
          refresh();
        });
      });
    }

    const columns: ITableColumn<IMemorial>[] = [
      new ComponentTableColumn('name', T('memorial.name'),memorial => <strong>{memorial.name}</strong> , { ellipsize: true, sortable: true, clickable: true }),
      new StringTableColumn('type', T('memorial.type'), memorial => describeMemorialKind(memorial), { sortable: false }),
      new ComponentTableColumn('place', T('memorial.place'), memorial => <MemorialPlaceView memorial={memorial}/> , {sortable: true, clickable: true}),
      new StringTableColumn('numPersons', '#', memorial => (memorial.extend_nbr_of_persons || 0).toString(), { sortable: false, width: 120, align: ColumnAlign.Right })
    ];
    if (user.canEdit()) {
      columns.push(new ActionsTableColumn('actions', T('generic.list.actions'), memorial => tableRowActions(
        { icon: Icon.Edit, style: TableRowActionStyle.Success, page: PageID.EditMemorial, pageParam: memorial._id },
        { icon: Icon.TimesCircle, style: TableRowActionStyle.Danger, onClick: handleClickedDelete }
      ), { width: 100 }));
    }
    return columns;
  }, [user, modals, refresh]);

  const handleClickedAdd = () => {
    history.push('/memorials/create');
  };

  const handleClickedRow = (memorial: IMemorial) => {
    history.push(`/memorials/${memorial._id}`);
  };

  return (
    <ListPage
      id={PageID.ListMemorials}
      icon={Icon.Monument}
      title={T('memorials.title')}
      via={via}
      noun='memorial'
      columns={columns}
      items={items}
      state={pageState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedRow={handleClickedRow}
      onClickedAdd={handleClickedAdd}
    />
  );
}
