import React from 'react';
import { IPersonRelation, Person, Gender } from '../../../models/Person';
import { Row, Col } from 'react-bootstrap';
import { TextView } from "../TextView";
import { LabelView } from "../LabelView";
import { PageID } from '../../../utils/PageLinking';
import PageLink from '../../../components/PageLink';


interface LinkedPersonViewProps {
  relation: IPersonRelation;
  gender: Gender;
  index: number;
}

export const LinkedPersonView = (props: LinkedPersonViewProps) => {
  const { relation, gender } = props;

  return <>
    <Row>
      <Col sm={2}>
        <LabelView text={Person.describePersonalRelationType(relation.relation, gender)}/>
      </Col>
      <Col sm={4}>
        <PageLink page={PageID.ViewPerson} param={relation.person_id}>
          {relation.person && (relation.person.surname + ' ' + relation.person.familyname)}
        </PageLink>
      </Col>
      <Col sm={6}>
        <TextView text={relation.comments}/>
      </Col>
    </Row>
  </>;
}
