import React, { useMemo, useState, useEffect } from 'react';
import api from '../../api';
import { IUser, getUsertypeName, IUserFields } from '../../models/User';
import { IQueryResult } from '../../models/Generic';
import { EditUserModal } from './EditUserModal';
import { ITableColumn, StringFieldTableColumn, StringTableColumn, ActionsTableColumn, TableRowActionStyle, ITableRowAction } from '../../components/table/Fields';
import { Icon } from '../../components/Icons';
import { ConfirmationModal } from '../../modals/Confirmation';
import { T, plural } from '../../Translate';
import { NotificationManager } from 'react-notifications';
import { ListState, ListPage } from '../ListPage';
import { PageProps } from '../Page';
import { usePageState } from '../../redux/Actions';
import { useModalContext } from '../../modals/ModalContext';
import { useRefresh } from '../../utils/Functional';
import { PageID } from '../../utils/PageLinking';

function createColumns(
  onClickedEdit: (user: IUser) => void,
  onClickedRemove: (user: IUser) => void,
  onClickedResend: (user: IUser) => void
): ITableColumn<IUser>[] {
  return [
    new StringFieldTableColumn('account', T('user.username'), { width: 150 }),
    new StringTableColumn('type', T('user.type'), user => getUsertypeName(user.type)),
    new StringFieldTableColumn('name', T('user.name')),
    new StringFieldTableColumn('email', T('user.email')),
    new StringFieldTableColumn('phone', T('user.phone'), { width: 120 }),
    new StringTableColumn('status', T('user.status'), user => user.confirmed ? T('user.status.checked') : T('user.status.pending')),
    new ActionsTableColumn('actions', T('generic.list.actions'), user => {
      const result: ITableRowAction<IUser, unknown>[] = [
        { icon: Icon.Edit, onClick: onClickedEdit },
        { icon: Icon.TimesCircle, onClick: onClickedRemove, style: TableRowActionStyle.Danger }
      ];
      if (!user.confirmed) {
        result.splice(0, 0, {
          icon: Icon.Refresh,
          onClick: onClickedResend,
          tooltip: T('page.users.resendInvitation')
        });
      }
      return result;
    }, { width: 100 })
  ];
}

export type ListUsersStateProps = ListState;
export const rowKey = (item: IUser) => item._id;

const PageSize = 10;

export default (props: PageProps) => {
  const { loading, via } = props;

  const modals = useModalContext();
  
  const [pageState, updatePageState] = usePageState('users');
  const { filter, tablePage, sortColumn, sortDirection } = pageState;

  const [items, setItems] = useState<IQueryResult<IUser>>();
  const refresh = useRefresh(() => {
    loading.loading(api.findUser(
      filter,
      tablePage * PageSize,
      PageSize,
      [[sortColumn, sortDirection]]
    ), plural('user'))
      .then(setItems);
  });
  useEffect(refresh, [loading, filter, tablePage, sortColumn, sortDirection]);

  const handleClickedAdd = async () => {
    const user = await modals.show<IUserFields>(
      props => <EditUserModal user={undefined} {...props} />
    );
    await api.createUser(user);
    refresh();
  }

  const columns: ITableColumn<IUser>[] = useMemo(() => {
    const handleClickedResendInvitation = (user: IUser) => {
      api.resendUserInvitationMail(user._id).then(() => {
        NotificationManager.success(T('page.users.resendSent'));
      });
    }
    
    const handleClickedEdit = (user: IUser) => {
      modals.show<IUserFields>(
        props => <EditUserModal user={user} {...props} />
      ).then(editedUser => {
        var user2 = Object.assign(editedUser, { _id: user._id });
        api.updateUser(user2).then(refresh);
      });
    }
  
    const handleClickedRemove = (user: IUser) => {
      modals.show<boolean>(props => (
        <ConfirmationModal
          title={T('modal.removeUser.title')}
          message={T('modal.removeUser.message', { name: user.name })}
          warning={T('generic.cannotUndo')}
          rejectText={T('generic.action.cancel')}
          acceptText={T('generic.action.remove')}
          acceptStyle='danger'
          {...props}
        />
      )).then(confirmed => {
        if (!confirmed)
          return;
  
        api.removeUser(user._id).then(refresh);
      });
    }

    return createColumns(
      handleClickedEdit,
      handleClickedRemove,
      handleClickedResendInvitation
    );
  }, [modals, refresh]);

  return (
    <ListPage
      id={PageID.ListUsers}
      icon={Icon.Users}
      title={T('page.users.title')}
      via={via}
      noun='user'
      columns={columns}
      items={items}
      state={pageState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedAdd={handleClickedAdd}
    />
  );
}
