import React from 'react';
import styles from './AddPhotoControl.module.scss';
import { IPersonStoryImage } from '../../../models/PersonStory2';
import { getActiveLanguage } from '../../../Translate';
import { Icon } from '../../../components/Icons';

interface PhotoPreviewProps {
  item: IPersonStoryImage;
  readOnly: boolean;
  onClicked: () => void;
  onClickedDelete: () => void;
}

export default (props: PhotoPreviewProps) => {
  const { item, readOnly, onClicked, onClickedDelete } = props;

  return (
    <div className={styles.photo}>
      <div className={styles.wrapper} onClick={() => onClicked()}>
        <img src={item.processed_image_url} alt='' />
        <span>{(item.legend && item.legend[getActiveLanguage()]) || <>&nbsp;</>}</span>
      </div>
      {!readOnly && (
        <div className={styles.delete} onClick={onClickedDelete}>
          <i className={Icon.TimesCircle} />
        </div>
      )}
    </div>
  );
}
