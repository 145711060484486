import React, { useCallback } from 'react';
import { DocumentReference } from '../../models/DocumentReference';
import { Icon } from '../../components/Icons';
import { ButtonLink } from '../../components/ButtonLink';
import PageLink from '../../components/PageLink';
import { TextStyles } from "../../config/styles";

interface ReferenceViewProps {
  item: DocumentReference;
  onClickedRemove: (item: DocumentReference) => void;
  readOnly: boolean;
}
export const ReferenceView = (props: ReferenceViewProps) => {
  const { item, onClickedRemove, readOnly } = props;
  const handleClickedRemove = useCallback(
    () => onClickedRemove(item),
    [onClickedRemove, item]
  );
  const link = item.getLink();

  return (
    <div>
      <i className={item.getIcon()} style={{ paddingRight: '0.5em' }} />
      <PageLink page={link.page} param={link.param} style={{ ...TextStyles.link.medium, textDecorationLine: 'underline' }}>{item.name}</PageLink>
      {!readOnly && (
        <ButtonLink onClick={handleClickedRemove}>
          <i className={Icon.TimesCircle} style={{ ...TextStyles.flatlink.medium, paddingLeft: '0.5em' }} />
        </ButtonLink>
      )}
    </div>
  );
}
