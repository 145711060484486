import React, { useMemo } from 'react';
import { Alert, Card, Button } from 'react-bootstrap';
import { T, singular } from '../../Translate';
import API from '../../api';
import { Document } from '../../models/Document';
import { PageProps, Page } from '../Page';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAppAction, updatePagesStateAction } from '../../redux/Actions';
import { AppState } from '../../redux/State';
import { Dispatch } from 'redux';
import { TableCardBody, SortDirection } from '../../components/table/Table';
import api from '../../api';
import { Icon } from '../../components/Icons';
import { useDocumentCollection, useDocumentTagDefinitions, useCollectionDocuments } from '../../FunctionalAPI';
import { rowKey, getColumns } from './Columns';
import { ButtonLink } from '../../components/ButtonLink';
import { PageID } from '../../utils/PageLinking';


export interface ViewCollectionStateProps {
  search: string;
  showRemoved: boolean;

  tablePage: number;
  sortColumn: string;
  sortDirection: SortDirection;
}
export const ViewCollectionDefaultState: ViewCollectionStateProps = {
  search: '',
  showRemoved: false,

  tablePage: 0,
  sortColumn: 'title',
  sortDirection: SortDirection.Up
};

interface ViewCollectionPageOwnProps extends PageProps {
  id: string;
}

const PageSize = 10;

export default (props: ViewCollectionPageOwnProps) => {
  const { id, history, loading, via } = props;
  const pageState = useSelector<AppState, ViewCollectionStateProps>(state => state.pagesState.collection);

  const columns = useMemo(getColumns, []);

  const [collection, refreshCollection] = useDocumentCollection(loading, id);
  const tags = useDocumentTagDefinitions(loading);
  const [documentsJson] = useCollectionDocuments(loading, id, collection.deleted);
  const documents = useMemo(
    () => documentsJson.map(document => Document.fromJSON(document, tags)),
    [documentsJson, tags]
  );

  const handleClickedDocument = (document: Document) => {
    history.push(`/documents/${document.id}?via=c`);
  };

  const handleClickedEdit = () => {
    history.push(`/collections/${id}/edit`);
  };

  const dispatch = useDispatch<Dispatch<AnyAppAction>>();
  const updateState = (state: Partial<ViewCollectionStateProps>) => {
    dispatch(updatePagesStateAction('collection', state));
  };

  const handleClickedRestore = async () => {
    await loading.saving(API.restoreCollection(id), singular('collection'));
    refreshCollection();
  };

  const handlePageChanged = (page: number) => {
    updateState({ tablePage: page });
  };

  const handleSortChanged = (column: string, direction: SortDirection) => {
    updateState({ sortColumn: column, sortDirection: direction });
  };

  const handleClickedCreate = () => {
    history.push(`/documents/create?code=${collection.adlibCode}`);
  };

  const user = api.user;
  const header = <>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
    <p style={{ flex: 4, marginLeft: '1em', marginRight: '1em', marginTop: '1.5em'}}>
      {collection.description}
    </p>
    {user.canEdit() && (
      <div style={{ flex: 1}}>
        <i
          onClick={handleClickedEdit}
          className='fas fa-pencil-alt'
          style={{ cursor: 'pointer', paddingRight: 15 }}
        />
        <Button onClick={handleClickedCreate}>{T('collection.addDocument')}</Button>
      </div>
    )}

    {user.canEdit() && collection.deleted && (
      <Alert variant='danger'>
        {T('collection.showingRemoved')} <ButtonLink onClick={handleClickedRestore}>{T('collection.restore')}</ButtonLink>
      </Alert>
    )}
    </div>
  </>;

  const { search, tablePage, sortColumn, sortDirection } = pageState;
  const filteredDocuments = documents
    .filter(document => document.matchesFilterLower(search));
  const pageDocuments = filteredDocuments
    .slice(tablePage * PageSize, (tablePage + 1) * PageSize);

  return (
    <Page
      id={PageID.ViewCollection}
      entity={id}
      icon={Icon.Box}
      title={collection.composedTitle()}
      via={via || PageID.ListCollections}
    >
      <div style={{ marginTop: '1em' }}>
        <Card>
          <Card.Header>
            {header}
          </Card.Header>
          <TableCardBody
            displayedItems={pageDocuments}
            columns={columns}
            rowKey={rowKey}
            tableClassName='table-hover'
            onClickItem={handleClickedDocument}
            onPageChanged={handlePageChanged}
            onSortChanged={handleSortChanged}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            page={tablePage}
            pageSize={PageSize}
            totalItems={filteredDocuments.length}
            noun='document'
          />
        </Card>
      </div>
    </Page>
  );
}
