import { User, IUser, AnonymousUser, IUserFields, Dataset } from './models/User';
import { DocumentCollection, IDocumentCollection, DocumentCollectionLoadOption } from './models/Collection';
import { IDocument, IDocumentElement, IDocumentFields } from './models/Document';
import { DocumentTagDefinition } from './models/DocumentTagDefinition';
import { DocumentReference, DocumentReferenceType } from './models/DocumentReference';
import { IPerson, IPersonWithEnlistedInfo, IPersonFields, IPersonMergeSuggestion, IPersonMilitaryInfo, IPersonMemoratedInfo, PersonQueryOptions } from './models/Person';
import { IPlace, IPlaceSearchResult, IPlaceFields } from './models/Place';
import $ from 'jquery';
import { IMemorial, GetMemorialOption, IMemorialFields } from './models/Memorial';
import { IPersonFeedback, IPersonFeedbackFields, PersonFeedbackLoadOptions, PersonFeedbackStatus } from './models/PersonFeedback';
import { IAttachment } from './models/Attachment';
import { IValidationResult } from './models/ValidationResult';
import { PersonEvent, PersonEventType, PersonEventLoadOption } from './models/PersonEvent';
import { ISpecialFunction, SpecialFunctionArguments, ISpecialFunctionCategory, SpecialFunctionResult, ISpecialFunctionValidationResult, TextMode } from './models/SpecialFunction';
import { IMilitaryEntity, IMilitaryEntityChild, IMilitaryEntityFields, IMilitaryEntityQuery, MilitaryEntityEvent } from './models/MilitaryEntity';
import { IQueryResult } from './models/Generic';
import { IPersonDocument, IPersonDocumentFields } from './models/PersonDocument';
import { IProject, IProjectFields } from './models/Project';
import { IPersonStoryFields, IPersonStory, PersonStoryLoadOption } from './models/PersonStory';
import { IPersonStory2, IPublishedPersonStory } from './models/PersonStory2';

import {
  IContactPersonFields,
  IContactPerson,
  ContactPersonEvent,
  BaseContactPersonEvent
} from './models/ContactPerson';
import { SortDirection } from './components/table/Table';
import { IndexedList } from './models/IndexedList';
import { convertNonJSONRPCRequestError } from './utils/ErrorConverter'
import { CheckAdlibCodeResult } from './models/CheckAdlibCodeResult';
import { IPhotoMetadata } from './pages/EditPersonStory/Photos/PhotoSource';
import { ICountry } from './models/ICountry';
import { IKeyLocation } from './models/KeyLocation';

export interface IAPIError {
  code: number | string;
  data?: string;
  message: string;
}

export interface IAuthenticationResponse {
  token: string;
  user: IUser;
}

interface DocumentQuery {

}

export enum GetPlaceOptions {
  Memorials = 'EMBED_MEMORIALS',
  EventsByType = 'EXTEND_EVENTS_BY_TYPE'
}

export type ActionType = 'create'|'update';

class KnowledgeCenterAPI {
  baseUrl: string;
  url: string;
  uploadAttachmentUrl: string;
  uploadPageUrl: string;
  uploadDocumentUrl: string;
  id: number;
  token: string | null;
  user: User;
  refreshingToken: Promise<any> | null;

  onRequireLogin?: () => Promise<IAuthenticationResponse|null>;
  onError?: (error: IAPIError) => void;
  failHandler?: (error: IAPIError) => void;

  documentTagDefinitions?: DocumentTagDefinition[];
  documentTagsIndexed?: IndexedList<DocumentTagDefinition>;

  constructor(url: string) {
    this.baseUrl = url;
    this.url = url + "/v2";
    this.uploadAttachmentUrl = url + '/file/uploadImage';
    this.uploadPageUrl = url + '/uploadpage/uploadImage';
    this.uploadDocumentUrl = url  + '/uploaddocument/uploadImage';
    this.id = 1;
    this.token = localStorage.getItem('token') || null;
    this.user = this._loadUserFromLocalStorage();

    this.refreshingToken = null;
  }

  _loadUserFromLocalStorage() {
    let userJson = localStorage.getItem('user');
    if (userJson) {
      return User.fromJSON(JSON.parse(userJson));
    } else {
      return AnonymousUser;
    }
  }

  setToken(token: string) {
    this.token = token;

    localStorage.setItem('token', token);
  }

  useGuestToken() {
    this.token = 'GUEST';
  }

  logout() {
    this.token = null;
    this.user = AnonymousUser;

    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  loginWith(authResponse: IAuthenticationResponse) {
    this.setToken(authResponse.token);
    this.user = User.fromJSON(authResponse.user);

    localStorage.setItem('user', JSON.stringify(authResponse.user));
  }

  _requestTyped<T>(method: string, parameters: any, deserializer: (value: any) => T): Promise<T> {
    return this._request(method, parameters).then(result => deserializer(result));
  }

  async _request<T>(method: string, parameters: any): Promise<T> {
    const id = this.id++;

    let parametersWithToken = parameters;
    if (method !== 'authenticate2' && !parameters.token)
      parametersWithToken = {...parameters, token: this.token || 'GUEST'};

    const data = {
      id: id,
      jsonrpc: '2.0',
      method: method,
      params: parametersWithToken
    };
    try {
      const success = await this._doRequest(data);
      if (success.result !== undefined) {
        return success.result;
      } else if (success.error) {
        throw success.error;
      } else {
        throw Error('Unexpected response');
      }
    } catch (errorResponse) {
      const error = convertNonJSONRPCRequestError(errorResponse)

      if ((error.code === 1009 || error.code === 1000) && this.onRequireLogin) {
        // 1009 = token to old
        // 1000 = invalid token
        // ask for login and try again
        const loginInfo = await this.onRequireLogin()
        if (loginInfo)
          return this._request(method, parameters);
      } else if (this.onError) {
        this.onError(error);
      }
      if (this.failHandler)
        this.failHandler(error);

      throw error;
    }
  }

  _doRequest(request: any) {
    // actually equivalent (and doesn't need jquery) - but would need to be tested for error handling conditions
    /*return fetch(this.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(request),
    }).then(response => response.json());*/

    return Promise.resolve($.ajax({
      url: this.url,
      headers: {},
      type: 'POST',
      data: JSON.stringify(request),
      dataType: 'json',
      contentType: 'application/json; charset=utf-8'
    }));
  }

  _translateSort(sort: [string, SortDirection][]) {
    return sort.map(entry => [entry[0], entry[1] === SortDirection.Up ? 1 : -1]);
  }

  isLoggedIn() {
    return this.user.type !== 'anonymous';
  }

  isAdmin() {
    return this.user.type === 'admin';
  }

  /* === Global search === */

  search(query: string): Promise<unknown[]> {
    return new Promise((resolve, reject) => resolve([]));
  }

  /* === Attachments === */

  updateAttachment(attachment: Partial<IAttachment> & { _id: string }): Promise<IAttachment> {
    return this._request(
      'updateAttachment',
      { document: attachment }
    ) as Promise<IAttachment>;
  }

  getAttachment(id: string): Promise<IAttachment> {
    return this._request(
      'getAttachment',
      { _id: id }
    ) as Promise<IAttachment>;
  }

  validateAttachment(attachment: IAttachment, action: 'create'|'update'): Promise<IValidationResult> {
    return this._request(
      'validateAttachment',
      { document: attachment, action }
    ) as Promise<IValidationResult>;
  }

  removeAttachment(id: string): Promise<void> {
    return this._request('removeAttachment', { _id: id }) as Promise<void>;
  }

  findAttachment(
    collection: string,
    collectionId: string,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = []
  ): Promise<IQueryResult<IAttachment>> {
    return this._request(
      'findAttachment',
      {
        document: { collection, collection_id: collectionId },
        skip,
        limit,
        sort: this._translateSort(sort),
        total: 1
      }
    ) as Promise<IQueryResult<IAttachment>>;
  }

  /* === Authentication === */

  login(username: string, password: string): Promise<IAuthenticationResponse> {
    return this._request('authenticate2', {
      account: username,
      password: password
    }) as Promise<IAuthenticationResponse>;
  }

  activateUser(key: string, password: string): Promise<'OK'|'INVALID_KEY'> {
    return this._request('activateUser', {
      key,
      password
    }) as Promise<'OK'>;
  }

  resendUserInvitationMail(id: string) {
    return this._request('resendUserInvitationMail', { id });
  }

  requestUserPasswordReset(email: string): Promise<'OK'|'NOT_FOUND'> {
    return this._request('requestUserPasswordReset', { email }) as Promise<'OK'|'NOT_FOUND'>;
  }

  resetUserPassword(key: string, password: string): Promise<'OK'|'INVALID_KEY'> {
    return this._request('resetUserPassword', { key, password }) as Promise<'OK'|'INVALID_KEY'>;
  }

  getAuthenticatedUser(): Promise<IUser> {
    return this._request('getAuthenticatedUser', {}) as Promise<IUser>;
  }

  /* === Collection documents === */

  createCollectionDocument(document: IDocumentFields): Promise<IDocument> {
    return this._request('createCollectionDocument', { document }) as Promise<IDocument>;
  }

  updateCollectionDocument(document: Partial<IDocumentFields> & { _id: string }): Promise<IDocument> {
    return this._request('updateCollectionDocument', { document: document }) as Promise<IDocument>;
  }

  updateCollectionDocumentElement(
    documentId: string,
    elementIndex: number,
    element: Partial<IDocumentElement>,
    newElementIndex?: number
  ): Promise<IDocument> {
    return this._request('updateCollectionDocumentElement', {
      documentId,
      elementIndex,
      element,
      newElementIndex
    }) as Promise<IDocument>;
  }

  addCollectionDocumentSubdocument(documentId: string, index?: number): Promise<{main: IDocument, subdocument: IDocument}> {
    return this._request('addCollectionDocumentSubdocument', {
      documentId,
      index
    }) as Promise<{main: IDocument, subdocument: IDocument}>;
  }

  moveCollectionDocumentElements(
    fromDocumentId: string, toDocumentId: string | null, elements: number[]
  ): Promise<{from: IDocument, to: IDocument}> {
    return this._request('moveCollectionDocumentElements', {
      fromDocumentId,
      toDocumentId,
      elements
    }) as Promise<{from: IDocument, to: IDocument}>;
  }

  removeCollectionDocumentElements(documentId: string, elements: number[]): Promise<IDocument> {
    return this._request('removeCollectionDocumentElements', {
      documentId,
      elements
    }) as Promise<IDocument>;
  }

  getCollectionDocument(id: string) {
    return this._request('getCollectionDocument', { _id: id }) as Promise<IDocument>;
  }

  validateCollectionDocument(
    document: IDocument,
    action: 'create'|'update' | 'delete'
  ): Promise<IValidationResult> {

    return this._request(
      'validateCollectionDocument',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  removeCollectionDocument(id: string) {
    return this._request('removeCollectionDocument', { _id: id });
  }

  restoreCollectionDocument(id: string) {
    return this._request('restoreCollectionDocument', { _id: id });
  }

  findCollectionDocuments(
    query: any,
    filter: string,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = [],
    options: string[] | undefined = undefined,
    ref_type: 'person'|'place'|'memorial'|'military_entity'| undefined = undefined,
    ref_id: string | undefined = undefined
  ): Promise<IQueryResult<IDocument>> {
    return this._request('findCollectionDocument', {
      document: query || {},
      filterValue: filter,
      skip,
      limit,
      sort: this._translateSort(sort),
      total: 1,
      ref_id: ref_id,
      ref_type: ref_type,
      options: options
    }) as Promise<IQueryResult<IDocument>>;
  }

  getCollectionDocuments(collectionId: string, includeRemoved: boolean = false) {
    return this._request(
      'getCollectionDocuments',
      { collection: collectionId, includeDeleted: includeRemoved }
    ) as Promise<IDocument[]>;
  }

  getDocumentsRelatedTo(type: 'person'|'place'|'memorial'|'military_entity', id: string, options?: 'generateUrl'[]) {
    return this._request(
      'getDocumentsRelatedTo',
      { type, id, options }
    ) as Promise<IDocument[]>;
  }

  countDocumentsRelatedTo(type: 'person'|'place'|'memorial'|'military_entity', id: string) {
    return this._request(
      'countDocumentsRelatedTo',
      { type, id }
    ) as Promise<number>;
  }

  getCollectionDocumentTags(): Promise<DocumentTagDefinition[]> {
    if (this.documentTagDefinitions)
      return Promise.resolve(this.documentTagDefinitions);

    return this._requestTyped(
      'getCollectionDocumentTags',
      {},
      tags => {
        const result = tags.map(DocumentTagDefinition.fromJSON);
        this.documentTagDefinitions = result;
        return result;
      });
  }

  getCollectionDocumentTagsIndexed(): Promise<IndexedList<DocumentTagDefinition>> {
    if (this.documentTagsIndexed)
      return Promise.resolve(this.documentTagsIndexed);

    return this.getCollectionDocumentTags().then(tags => {
      const result = this.documentTagsIndexed = new IndexedList(tags, tag => tag.id);
      tags.forEach(tag => tag.completeFields(result));
      this.documentTagsIndexed = result;
      return result;
    });
  }

  getCollectionReference(type: DocumentReferenceType, id: string) {
    return this._requestTyped('getCollectionReference', {
      type: type,
      id: id
    }, DocumentReference.fromJSON);
  }

  searchCollectionReferences(type: DocumentReferenceType, query: string): Promise<DocumentReference[]> {
    return this._requestTyped('searchCollectionReferences', {
      type: type,
      query: query,
      limit:100
    }, references => references.map(DocumentReference.fromJSON));
  }

  /* === Document collections === */

  importDocumentCollection(
    adlibCode: string,
    type: 'box' = 'box'
  ): Promise<IDocumentCollection> {
    return this._request(
      'importDocumentCollection',
      { adlibCode, type }
    ) as Promise<IDocumentCollection>;
  }

  createCollection(collection: DocumentCollection) {
    return this._requestTyped('createDocumentCollection', {
      document: collection.toJSON()
    }, DocumentCollection.fromJSON);
  }

  updateCollection(collection: DocumentCollection) {
    return this._requestTyped('updateDocumentCollection', {
      document: collection.toJSON()
    }, DocumentCollection.fromJSON);
  }

  getDocumentCollection(id: string, options: DocumentCollectionLoadOption[] = []) {
    return this._requestTyped('getDocumentCollection', {
      _id: id,
      options
    }, DocumentCollection.fromJSON);
  }

  getDocumentCollectionsForPerson(id: string) {
    return this._request(
      'getDocumentCollectionsForPerson',
      { personId: id }
    ) as Promise<IDocumentCollection[]>;
  }

  validateDocumentCollection(
    collection: IDocumentCollection,
    action: 'create'|'update'
  ): Promise<IValidationResult> {
    return this._request('validateDocumentCollection', {
      document: collection,
      action
    }) as Promise<IValidationResult>;
  }

  removeCollection(id: string) {
    return this._request('removeDocumentCollection', { _id: id });
  }

  restoreCollection(id: string) {
    return this._request('restoreDocumentCollection', { _id: id });
  }

  findDocumentCollections(
    query: DocumentQuery,
    filter: string,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = [],
    includeRemoved: boolean = false
  ) {
    return this._request(
      'findDocumentCollection',
      {
        document: query,
        filterValue: filter,
        skip,
        limit,
        sort: this._translateSort(sort),
        total: 1,
        includeRemoved
      }) as Promise<IQueryResult<IDocumentCollection>>;
  }

  /* === Person events === */

  createEvent(event: PersonEvent): Promise<PersonEvent> {
    return this._request(
      'createEvent',
      { document: event }
    ) as Promise<PersonEvent>;
  }

  updateEvent(event: Partial<PersonEvent> & { _id: string }): Promise<PersonEvent> {
    return this._request(
      'updateEvent',
      { document: event }
    ) as Promise<PersonEvent>;
  }

  getEvent(id: String, options: (PersonEventLoadOption|PersonQueryOptions)[] = []): Promise<PersonEvent> {
    return this._request(
      'getEvent',
      { _id: id, options: options }
    ) as Promise<PersonEvent>;
  }

  validateEvent(
    document: PersonEvent,
    action: 'create'|'update'
  ): Promise<IValidationResult> {
    return this._request(
      'validateEvent',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  removeEvent(id: string): Promise<PersonEvent> {
    return this._request('removeEvent', { _id: id }) as Promise<PersonEvent>;
  }

  removeEventsForPerson(personId: string): Promise<void> {
    return this._request(
      'removeEvents',
      { document: { 'person_id': personId } }
    ) as Promise<void>;
  }

  findEvents(
    document: { person_id?: string, type?: PersonEventType, place_id?: string },
    filter: string|undefined = undefined,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = [],
    options: (PersonEventLoadOption|PersonQueryOptions)[] = []
  ) {
    return this._request(
      'findEvent',
      {
        document,
        filter,
        skip,
        limit,
        sort: this._translateSort(sort),
        total: 1,
        options
      }
    ) as Promise<IQueryResult<PersonEvent>>;
  }

  /* === Functions === */

  getFunctions(): Promise<ISpecialFunctionCategory[]> {
    return this._request('getFunctions', {}) as Promise<ISpecialFunctionCategory[]>;
  }

  getFunction(name: string): Promise<ISpecialFunction> {
    return this._request('getFunction', { name }) as Promise<ISpecialFunction>;
  }

  validateFunction(
    name: string,
    args: SpecialFunctionArguments
  ): Promise<ISpecialFunctionValidationResult> {
    return this._request(
      'validateFunction',
      { name, arguments: args }
    ) as Promise<ISpecialFunctionValidationResult>;
  }

  callFunction(name: string, args: SpecialFunctionArguments): Promise<SpecialFunctionResult> {
    return this._request(
      'callFunction',
      { name, arguments: args }
    ) as Promise<SpecialFunctionResult>;
  }

  applyAction(name: string, action: string, ids: string[]): Promise<void> {
    return this._request(
      'applyAction',
      { name, action, ids }
    ) as Promise<void>;
  }

  callFunctionCsv(name: string, args: SpecialFunctionArguments): Promise<string[][]> {
    return this._request(
      'callFunctionCsv',
      { name, arguments: args }
    ) as Promise<string[][]>;
  }

  /* === Memorials === */

  createMemorial(memorial: IMemorialFields): Promise<IMemorial> {
    return this._request('createMemorial', { document: memorial }) as Promise<IMemorial>;
  }

  updateMemorial(memorial: Partial<IMemorialFields> & { _id: string }): Promise<IMemorial> {
    return this._request('updateMemorial', { document: memorial }) as Promise<IMemorial>;
  }

  getMemorial(id: string, options: GetMemorialOption[] = []): Promise<IMemorial> {
    return this._request('getMemorial', { _id: id, options }) as Promise<IMemorial>;
  }

  getMemorials(ids: string[]): Promise<IMemorial[]> {
    return this._request('getMemorials', { ids }) as Promise<IMemorial[]>;
  }

  validateMemorial(memorial: IMemorialFields & { _id?: string }, action: 'create'|'update') {
    return this._request(
      'validateMemorial',
      { document: memorial, action }
    ) as Promise<IValidationResult>;
  }

  removeMemorial(id: string): Promise<void> {
    return this._request(
      'removeMemorial',
      { _id: id }
    ) as Promise<void>;
  }

  findMemorials(
    filter: string = '',
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = [],
    options: GetMemorialOption[] = [],
    filterMode?: TextMode
  ) {
    return this._request('findMemorial', {
      document: {},
      skip,
      limit,
      sort: this._translateSort(sort),
      filterValue: filter,
      total: 1,
      options,
      filterMode
    }) as Promise<IQueryResult<IMemorial>>;
  }

  addPersonToMemorial(personId: string, memorialId: string) {
    return this._request('addPersonToMemorial', {
      person_id: personId,
      memorial_id: memorialId
    });
  }

  findPersonOnMemorial(
    memorialId: string,
    filter: string,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = [],
    options: string[] = []
  ) {
    return this._request('findPersonOnMemorial', {
      document: {},
      memorial_id: memorialId,
      skip,
      limit,
      sort: this._translateSort(sort),
      total: 1,
      options,
      filterValue: filter
    }) as Promise<IQueryResult<IPerson>>;
  }

  addNewPersonToMemorial(person: IPerson, memorialId: string) {
    return this._request(
      'addNewPersonToMemorial',
      { document, memorial_id: memorialId }
    ) as Promise<IPerson>;
  }

  removePersonFromMemorial(personId: string, memorialId: string) {
    return this._request(
      'removePersonFromMemorial',
      { person_id: personId, memorial_id: memorialId }
    ) as Promise<IMemorial>;
  }

  /* === Military entities === */

  createMilitaryEntity(document: IMilitaryEntityFields) {
    return this._request(
      'createMilitaryEntity',
      { document }
    ) as Promise<IMilitaryEntity>;
  }

  updateMilitaryEntity(document: Partial<IMilitaryEntity> & { _id: string }) {
    return this._request(
      'updateMilitaryEntity',
      { document }
    ) as Promise<IMilitaryEntity>;
  }

  getMilitaryEntity(id: string, options: ('history.entity'|'history.place')[] = []) {
    return this._request(
      'getMilitaryEntity',
      { _id: id, fields: options }
    ) as Promise<IMilitaryEntity>;
  }

  validateMilitaryEntity(document: IMilitaryEntityFields, action: 'create'|'update') {
    return this._request(
      'validateMilitaryEntity',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  validateMilitaryEntityHistory(
    document: MilitaryEntityEvent,
    action: 'create'|'update',
    militaryEntityId?: string,
    index?: number
  ) {
    return this._request(
      'validateMilitaryEntityHistory',
      { document, action, _id: militaryEntityId, index }
    ) as Promise<IValidationResult>;
  }

  getMilitaryEntitiesIn(militaryEntityId: string) {
    return this._request(
      'getMilitaryEntitiesIn',
      { entity_id: militaryEntityId }
    ) as Promise<IMilitaryEntityChild[]>;
  }

  mergeMilitaryEntities(id1: string, id2: string) {
    return this._request(
      'mergeMilitaryEntities',
      { _id1: id1, _id2: id2 }
    ) as Promise<void>;
  }

  removeMilitaryEntity(id: string) {
    return this._request(
      'removeMilitaryEntity',
      { _id: id }
    ) as Promise<void>;
  }

  findMilitaryEntities(
    query: IMilitaryEntityQuery,
    filter: string,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = []
  ) {
    return this._request(
      'findMilitaryEntity',
      {
        document: query,
        filterValue: filter,
        skip,
        limit,
        sort: this._translateSort(sort),
        total: 1
      }
    ) as Promise<IQueryResult<IMilitaryEntity>>;
  }

  hasMilitaryEntity(query: IMilitaryEntityFields) {
    return this._request(
      'hasMilitaryEntity',
      { document: query }
    ) as Promise<boolean>;
  }

  getArmyList(
    query: Partial<IMilitaryEntityFields>|undefined,
    filter: string|undefined,
    skip: number = 0,
    limit: number = 0
  ) {
    return this._request(
      'getArmyList',
      { document: query, skip, limit, filterValue: filter }
    ) as Promise<string[]>;
  }

  getRegimentList(
    query: Partial<IMilitaryEntityFields>|undefined,
    filter: string|undefined,
    skip: number = 0,
    limit: number = 0
  ) {
    return this._request(
      'getRegimentList',
      { document: query, skip, limit, filterValue: filter }
    ) as Promise<string[]>;
  }

  getUnitList(
    query: Partial<IMilitaryEntityFields>|undefined,
    filter: string|undefined,
    skip: number = 0,
    limit: number = 0
  ) {
    return this._request(
      'getUnitList',
      { document: query, skip, limit, filterValue: filter }
    ) as Promise<string[]>;
  }

  getUnitNumberList(
    query: Partial<IMilitaryEntityFields>|undefined,
    filter: string|undefined,
    skip: number = 0,
    limit: number = 0
  ) {
    return this._request(
      'getUnitNumberList',
      { document: query, skip, limit, filterValue: filter }
    ) as Promise<string[]>;
  }

  getMilitaryEntitySoldiers(
    army: string,
    regiment: string,
    unit: string|null,
    unitNr: string|null,
    filter: string,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = [],
    options: PersonQueryOptions[] = []
  ) {
    return this._request(
      'getMilitaryEntitySoldiers',
      {
        army,
        regiment,
        unit,
        unit_nr: unitNr,
        filterValue: filter,
        skip,
        limit,
        total: 1,
        sort: this._translateSort(sort),
        options: options
      }
    ) as Promise<IQueryResult<IPersonWithEnlistedInfo>>;
  }

  /* === Persons === */

  createPerson(person: IPersonFields) {
    return this._request('createPerson', {
      document: person
    }) as Promise<IPerson>;
  }

  updatePerson(person: Partial<IPerson> & { _id: string }) {
    return this._request('updatePerson', {
      document: person
    }) as Promise<IPerson>;
  }

  getPerson(id: string, options: PersonQueryOptions[] = [], token?: string): Promise<IPerson> {
    return this._request('getPerson', { _id: id, options, token }) as Promise<IPerson>;
  }

  getPersons(ids: string[], options: string[] = []): Promise<IPerson[]> {
    return this._request('getPersons', { _ids: ids, options }) as Promise<IPerson[]>;
  }

  validatePerson(document: IPersonFields, action: 'create'|'update') {
    return this._request(
      'validatePerson',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  getMergeSuggestions(fromDate?: string, toDate?: string, limit?: number, offset?: number) {
    return this._request(
      'getMergeSuggestions',
      { fromDate, toDate, limit, offset }
    ) as Promise<IQueryResult<IPersonMergeSuggestion>>;
  }

  clearMergeSuggestion(personId: string) {
    return this._request(
      'clearMergeSuggestion',
      { _id: personId }
    ) as Promise<void>;
  }

  mergePersons(id1: string, id2: string) {
    return this._request(
      'mergePersons',
      { _id1: id1, _id2: id2 }
    ) as Promise<void>;
  }

  removePerson(id: string) {
    return this._request(
      'removePerson',
      { _id: id }
    ) as Promise<void>;
  }

  findPersons(
    queryDocument: { memorials?: string, 'project_memberships.project'?: string },
    filter: string = '',
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = [],
    options: PersonQueryOptions[] = [],
    dataset?: Dataset
  ): Promise<IQueryResult<IPerson>> {
    return this._request('findPerson', {
      document: queryDocument,
      filterValue: filter,
      skip,
      limit,
      sort: this._translateSort(sort),
      options,
      total: 1,
      dataset
    }) as Promise<IQueryResult<IPerson>>;
  }

  findPersonAdvanced(query: SpecialFunctionArguments) {
    return this._request(
      'findPersonAdvanced',
      { query }
    ) as Promise<SpecialFunctionResult>;
  }

  getPersonsDetails(ids: string[]) {
    return this._request(
      'getPersonsDetails',
      { ids }
    ) as Promise<{[key: string]: IPersonMilitaryInfo}>;
  }

  getPersonsMemorated(ids: string[]) {
    return this._request(
      'getPersonsMemorated',
      { ids }
    ) as Promise<{[key: string]: IPersonMemoratedInfo[]}>;
  }

  /* === Person documents === */

  createPersonDocument(document: IPersonDocumentFields) {
    return this._request(
      'createDocument',
      { document }
    ) as Promise<IPersonDocument>;
  }

  updatePersonDocument(document: Partial<IPersonDocument> & { _id: string }) {
    return this._request(
      'updateDocument',
      { document }
    ) as Promise<IPersonDocument>;
  }


  getPersonDocument(id: string) {
    return this._request('getDocument', { _id: id }) as Promise<IPersonDocument>;
  }

  getDocumentsForPerson(personId: string, options: string[] = []) {
    return this._request(
      'getDocumentsForPerson',
      { personId, options }
    ) as Promise<IPersonDocument[]>;
  }

  validatePersonDocument(document: IPersonDocumentFields & { _id?: string }, action: ActionType) {
    return this._request(
      'validateDocument',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  removePersonDocument(id: string) {
    return this._request(
      'removeDocument',
      { _id: id }
    ) as Promise<void>;
  }

  removeDocumentsForPerson(personId: string) {
    return this._request(
      'removeDocumentsForPerson',
      { personId }
    ) as Promise<void>;
  }

  findPersonDocuments(
    query: { person_id: string },
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][]
  ) {
    return this._request(
      'findDocument',
      { document: query, skip, limit, sort, total: 1}
    ) as Promise<IQueryResult<IPersonDocument>>;
  }

  findAttachmentsForPersonDocument(id: string) {
    return this._request(
      'findAttachmentsForDocument',
      { _id: id }
    ) as Promise<IPersonDocument[]>;
  }

  /* === Places === */

  createPlace(place: IPlaceFields) {
    return this._request('createPlace', {
      document: place
    }) as Promise<IPlace>;
  }

  updatePlace(place: Partial<IPlaceFields> & { _id: string }): Promise<IPlace> {
    return this._request('updatePlace', {
      document: place
    }) as Promise<IPlace>;
  }

  getPlace(id: string, options: GetPlaceOptions[] = []): Promise<IPlace> {
    return this._request('getPlace', {
      _id: id,
      options
    }) as Promise<IPlace>;
  }

  getCountries() {
    return this._request('getCountries', {}) as Promise<ICountry[]>;
  }

  validatePlace(
    place: IPlaceFields & { _id?: string },
    action: 'create'|'update'
  ): Promise<IValidationResult> {
    return this._request('validatePlace', {
      document: place,
      action
    }) as Promise<IValidationResult>;
  }

  canRemovePlace(id: string) {
    return this._request('canRemovePlace', { _id: id }) as Promise<0|1>;
  }

  mergePlaces(id1: string, id2: string) {
    return this._request(
      'mergePlaces',
      { _id1: id1, _id2: id2 }
    ) as Promise<boolean>;
  }

  removePlace(id: string) {
    return this._request(
      'removePlace',
      { _id: id }
    ) as Promise<boolean>;
  }

  findPlaces(
    filter: string,
    skip: number = 0,
    take: number = 100,
    sort: [string, SortDirection][] = [],
    options: GetPlaceOptions[] = [],
    filterMode?: TextMode|TextMode[],
    query?: Partial<IPlaceFields>
  ) {
    return this._request('findPlace', {
      document: query || {},
      skip,
      limit: take,
      total: 1,
      filterValue: filter,
      sort: this._translateSort(sort),
      options: options,
      filterMode: filterMode || [TextMode.Head, TextMode.Contains],
    }) as Promise<IQueryResult<IPlace>>;
  }

  findPlaceByNameAndCountryCode(name: string, language: string, countryCode: string) {
    return this._request(
      'findPlaceByNameAndCountryCode',
      { name, lang: language, country_code: countryCode }
    ) as Promise<IPlace[]>;
  }

  findPlaceByRegion(
    name: string,
    region: string,
    countryCode: string,
    language: string = 'en'
  ) {
    return this._request(
      'findPlaceByRegion',
      { name, region, country_code: countryCode, lang: language }
    ) as Promise<IPlace[]>;
  }

  geonamesAPI(name: string, countryCode: string, language: string) {
    return this._request('geonamesAPI', {
      name: name,
      country_code: countryCode,
      language: language
    }) as Promise<{ match: IPlace[], suggestions: IPlace[] }>;
  }

  googleMapsAPI(name: string, countryCode: string, language: string) {
    return this._request('googleMapsAPI', {
      name: name,
      country_code: countryCode,
      language: language
    }) as Promise<IPlaceSearchResult>;
  }

  googleMapsAPIMulti(name: string, countryCode: string, languages: string[]) {
    return this._request('googleMapsAPIMulti', {
      name,
      country_code: countryCode,
      languages
    }) as Promise<{[key: string]: IPlaceSearchResult}>;
  }

  /* === Projects === */

  createProject(document: IProjectFields) {
    return this._request('createProject', { document }) as Promise<IProject>;
  }

  updateProject(document: Partial<IProject> & { _id: string }) {
    return this._request('updateProject', { document }) as Promise<IProject>;
  }

  getProject(id: string) {
    return this._request('getProject', { _id: id }) as Promise<IProject>;
  }

  validateProject(document: IProjectFields, action: 'create'|'update') {
    return this._request(
      'validateProject',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  removeProject(id: string) {
    return this._request('removeProject', { _id: id });
  }

  findProjects(
    filter: string = '',
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][],
    document?: {}
  ) {
    return this._request('findProject', {
      document: document || {},
      skip,
      limit,
      sort: this._translateSort(sort),
      total: 1,
      filterValue: filter
    }) as Promise<IQueryResult<IProject>>;
  }

  /* == Stories === */

  createPersonStory(document: IPersonStoryFields) {
    return this._request('createStory', { document }) as Promise<IPersonStory>;
  }

  updatePersonStory(document: Partial<IPersonStoryFields> & { _id: string }) {
    return this._request('updateStory', { document }) as Promise<IPersonStory>;
  }

  getPersonStory(id: string) {
    return this._request('getStory', { _id: id }) as Promise<IPersonStory>;
  }

  getStoriesForPerson(personId: string, options: PersonStoryLoadOption[]) {
    return this._request(
      'getStoriesForPerson',
      { personId, options }
    ) as Promise<IPersonStory[]>;
  }

  validatePersonStory(document: IPersonStoryFields, action: 'create'|'update') {
    return this._request(
      'validateStory',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  removePersonStory(id: string) {
    return this._request('removePersonStory', { _id: id }) as Promise<'OK'>;
  }

  removeStoriesForPerson(personId: string) {
    return this._request('removeStoriesForPerson', { personId }) as Promise<'OK'>;
  }

  findPersonStories(
    document: Partial<{personId: string}> = {},
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = []
  ) {
    return this._request('findPersonStories', {
      document,
      skip,
      limit,
      sort: this._translateSort(sort),
      total: 1
    }) as Promise<IQueryResult<IPersonStory>>;
  }

  /* === Person Stories version 2 === */

  findPersonStories2(
    document: Partial<{_id: string}> = {},
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = [],
    filter: string | undefined = undefined,
    options?: ('linked_stories'|'person')[]
  ) {
    return this._request('findPersonStories', {
      document,
      skip,
      limit,
      sort: this._translateSort(sort),
      filterValue: filter,
      total: 1,
      options
    }) as Promise<IQueryResult<IPersonStory2>>;
  }


  findMatchingPersonStories(
    personStoryId: string,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = [],
    filter: string | undefined = undefined,
    options?: ('linked_stories'|'person')[]
  ) {
    return this._request('findMatchingPersonStories', {
      personStoryId,
      skip,
      limit,
      sort: this._translateSort(sort),
      filterValue: filter,
      total: 1,
      options
    }) as Promise<IQueryResult<IPersonStory2>>;
  }

  findPersonStoriesForPerson(
    personId: string,
  ): Promise<IQueryResult<IPersonStory2>> {
    let document = {person_id_ref: personId}
    return this._request('findPersonStories', {
      document,
      total: 1
    }) as Promise<IQueryResult<IPersonStory2>>;
  }


  getPersonStory2(id: string, options?: ('linked_stories'|'person'|'key_location'|'contact_persons')[]) {
    return this._request('getPersonStory', { _id: id, options }) as Promise<IPersonStory2>;
  }

  validatePersonStory2(document: IPersonStory2, action: 'create'|'update'|'publish') {
    return this._request(
      'validatePersonStory',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  publishPersonStory2(id: string, target: string) {
    return this._request('publishPersonStory', { _id: id, target }) as Promise<string | IValidationResult>;
  }

  unpublishPersonStory2(id: string) {
    return this._request('unpublishPersonStory', { _id: id }) as Promise<string | IValidationResult>;
  }

  updatePersonStory2(document: Partial<IPersonStory2>, options?: ('linked_stories'|'person'|'key_location'|'contact_persons')[]) {
    return this._request('updatePersonStory', { document: document, options: options }) as Promise<IPersonStory2>;
  }

  createPersonStory2(document: Partial<IPersonStory2>) {
    return this._request('createPersonStory', { document }) as Promise<IPersonStory2>;
  }

  getPublishedPersonStory(id: string) {
    return this._request('getPublishedStoryFromMuseum', { id: id, token: 'GUEST' }) as Promise<IPublishedPersonStory>;
  }

  importDocumentPhotoToPersonStory(
    personStoryId: string,
    target: number|'profile'|'new',
    documentId: string,
    page: number,
    metadata?: IPhotoMetadata
  ) {
    return this._request('importDocumentPhotoToPersonStory', {
      personStoryId,
      target,
      documentId,
      page,
      metadata
    }) as Promise<IPersonStory2>;
  }

  importAttachmentToPersonStory(
    personStoryId: string,
    target: number|'profile'|'new',
    attachmentId: string,
    metadata?: IPhotoMetadata
  ) {
    return this._request('importAttachmentToPersonStory', {
      personStoryId,
      target,
      attachmentId,
      metadata
    }) as Promise<IPersonStory2>;
  }

  updatePersonStoryPhoto(
    personStoryId: string,
    target: number|'profile',
    metadata?: IPhotoMetadata
  ) {
    return this._request('updatePersonStoryPhoto', {
      personStoryId,
      target,
      metadata
    }) as Promise<IPersonStory2>;
  }

  deletePersonStoryPhoto(personStoryId: string, target: number|'profile') {
    return this._request(
      'deletePersonStoryPhoto',
      { personStoryId, target }
    ) as Promise<IPersonStory2>;
  }

  /* === Person feedback === */

  createPersonFeedback(document: IPersonFeedbackFields) {
    return this._request(
      'createSubmittedData',
      { document }
    ) as Promise<IPersonFeedback>;
  }

  updatePersonFeedback(document: Partial<IPersonFeedbackFields> & { _id: string }) {
    return this._request(
      'updateSubmittedData',
      { document }
    ) as Promise<IPersonFeedback>;
  }

  getPersonFeedback(id: string) {
    return this._request(
      'getSubmittedData',
      { _id: id }
    ) as Promise<IPersonFeedback>;
  }

  validatePersonFeedback(document: IPersonFeedbackFields, action: 'create'|'update') {
    return this._request(
      'validateSubmittedData',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  removePersonFeedback(id: string) {
    return this._request('removeSubmittedData', { _id: id }) as Promise<'OK'>;
  }

  findPersonFeedback(
    filter: string,
    skip: number = 0,
    take: number = 0,
    sort: [string, SortDirection][] = [],
    options: PersonFeedbackLoadOptions[] = []
  ) {
    return this._request('findSubmittedData', {
      filterValue: filter,
      document: {},
      skip,
      limit: take,
      sort: this._translateSort(sort),
      total: 1,
      options
    }) as Promise<IQueryResult<IPersonFeedback>>;
  }

  markPersonFeedback(id: string, status: PersonFeedbackStatus) {
    return this._request('markPersonFeedback', { _id: id, status }) as Promise<true>;
  }

  /* === Users === */

  createUser(document: IUserFields) {
    return this._request('createUser', { document });
  }

  updateUser(document: Partial<IUserFields> & { _id: string }) {
    return this._request('updateUser', { document });
  }

  getUser(id: string) {
    return this._request('getUser', { _id: id });
  }

  validateUser(document: IUserFields & { _id?: string }, action: 'create'|'update') {
    return this._request(
      'validateUser',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  removeUser(id: string) {
    return this._request('removeUser', { _id: id });
  }

  findUser(
    filter: string,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][]
  ) {
    return this._request('findUser', {
      document: {},
      filterValue: filter,
      skip,
      limit,
      sort: this._translateSort(sort),
      total: 1
    }) as Promise<IQueryResult<IUser>>;
  }

  /* === Contact persons === */

  createContactPerson(document: IContactPersonFields): Promise<IContactPerson> {
    return this._request(
      'createContactPerson',
      { document }
    ) as Promise<IContactPerson>;
  }

  updateContactPerson(document: { _id: string } & Partial<IContactPersonFields>) {
    return this._request(
      'updateContactPerson',
      { document }
    ) as Promise<IContactPerson>;
  }

  deleteContactPerson(_id: string) {
    return this._request(
      'removeContactPerson',
      { _id }
    ) as Promise<'OK'>;
  }

  getContactPerson(_id: string, options?: string[]): Promise<IContactPerson> {
    return this._request(
      'getContactPerson',
      { _id, options }
    ) as Promise<IContactPerson>;
  }

  validateContactPerson(
    document: { id?: string } & Partial<IContactPersonFields>,
    action: ActionType
  ) {
    return this._request(
      'validateContactPerson',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  findContactPersons(
    filter: string,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = []
  ) {
    return this._request('findContactPerson', {
      document: {},
      filterValue: filter,
      skip,
      limit,
      sort: this._translateSort(sort),
      total: 1
    }) as Promise<IQueryResult<IContactPerson>>;
  }

  findContactPersonsByName(firstName: string, lastName: string) {
    return this._request(
      'findContactPersonByName',
      { firstName, lastName }
    ) as Promise<IContactPerson[]>;
  }

  findContactPersonEvents(
    filter: string,
    skip: number = 0,
    limit: number = 0,
    sort: [string, SortDirection][] = []
  ) {
    return this._request('findContactPersonEvents', {
      document: {},
      filterValue: filter,
      skip,
      limit,
      sort: this._translateSort(sort),
      total: 1
    }) as Promise<IQueryResult<BaseContactPersonEvent>>;
  }

  createContactPersonEvent(document: ContactPersonEvent) {
    return this._request(
      'createContactPersonEvent',
      { document }
    ) as Promise<ContactPersonEvent>;
  }

  updateContactPersonEvent(document: { _id: string } & Partial<ContactPersonEvent>) {
    return this._request(
      'updateContactPersonEvent',
      { document }
    ) as Promise<ContactPersonEvent>;
  }

  getContactPersonEvent(id: string) {
    return this._request(
      'getContactPersonEvent',
      { _id: id }
    ) as Promise<ContactPersonEvent>;
  }

  deleteContactPersonEvent(id: string) {
    return this._request(
      'removeContactPersonEvent',
      { _id: id }
    ) as Promise<'OK'>;
  }

  getContactPersonEventsForPerson(contactPersonId: string) {
    return this._request(
      'getContactPersonEventsForPerson',
      { contact_person_id: contactPersonId }
    ) as Promise<ContactPersonEvent[]>;
  }

  findContactMomentsForPerson(personId: string) {
    return this._request(
      'findContactMomentsForPerson',
      { person_id: personId }
    ) as Promise<ContactPersonEvent[]>;
  }

  findContactMomentsForDocument(documentId: string) {
    return this._request(
      'findContactMomentsForDocument',
      { document_id: documentId }
    ) as Promise<ContactPersonEvent[]>;
  }

  checkAdlibCode(code: string) {
    return this._request(
      'checkAdlibCode',
      { code }
    ) as Promise<CheckAdlibCodeResult>;
  }

  /* === Key locations === */

  createKeyLocation(document: Omit<IKeyLocation, '_id'>) {
    return this._request(
      'createKeyLocation',
      { document }
    ) as Promise<IKeyLocation>;
  }

  updateKeyLocation(document: Partial<IKeyLocation> & { _id: string }) {
    return this._request(
      'updateKeyLocation',
      { document }
    ) as Promise<IKeyLocation>;
  }

  getKeyLocation(id: string, options: string[] = []) {
    return this._request(
      'getKeyLocation',
      { _id: id, options }
    ) as Promise<IKeyLocation>;
  }

  removeKeyLocation(id: string) {
    return this._request(
      'removeKeyLocation',
      { _id: id }
    ) as Promise<void>;
  }

  validateKeyLocation(document: Partial<IKeyLocation>, action: ActionType) {
    return this._request(
      'validateKeyLocation',
      { document, action }
    ) as Promise<IValidationResult>;
  }

  findKeyLocations(filter: string, skip: number, limit: number, sort: [string, SortDirection][] = [], options: string[] = []) {
    return this._request(
      'findKeyLocations',
      { document: {}, filterValue: filter, skip, limit, sort: this._translateSort(sort), total: 1, options }
    ) as Promise<IQueryResult<IKeyLocation>>;
  }
}

let url = "/api";
if (document.location.host.indexOf("localhost") === 0)
  url = "http://localhost:8080";

export default new KnowledgeCenterAPI(url);
