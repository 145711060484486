/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { T, getUILanguages, Language } from '../../Translate';
import api from '../../api';
import { History } from 'history';
import { PageInfo } from '../../pages/Page';
import { Icon } from '../Icons';
import { Link } from 'react-router-dom';
import styles from './AppBar.module.scss';
import BreadcrumbsView from './BreadcrumbsView';
import { Container } from 'react-bootstrap';

const CollapseIcon = () => (
  <button
    className='navbar-toggler navbar-toggler-right toggled'
    type='button'
    aria-controls='navigation-index'
    aria-label='Toggle navigation'
  >
    <span className='navbar-toggler-bar burger-lines' />
    <span className='navbar-toggler-bar burger-lines' />
    <span className='navbar-toggler-bar burger-lines' />
  </button>
);

interface SmallDropdownMenuProps {
  children: React.ReactNode;
}
const SmallDropdownMenu = (props: SmallDropdownMenuProps) => (
  <li className='nav-item dropdown'>
    <a
      id='navbarDropdownMenuLink'
      className='nav-link dropdown-toggle'
      href='#'
      data-toggle='dropdown'
    >
      <i className='fal fa-list-ul' style={{ fontSize: '1rem' }} />
    </a>
    <div
      className='dropdown-menu dropdown-menu-right'
      aria-labelledby='navbarDropdownMenuLink'
    >
      {props.children}
    </div>
  </li>
);

enum DropdownMenuItemStyle {
  Normal = 'dropdown-item',
  Danger = 'dropdown-item text-danger'
}
interface DropdownMenuItemProps {
  icon: Icon;
  title: string;
  style?: DropdownMenuItemStyle;
  onClick?: () => void;
  to?: string;
}
const DropdownMenuItem = (props: DropdownMenuItemProps) => {
  const { icon, title, style = DropdownMenuItemStyle.Normal, to, onClick } = props;
  if (to) {
    return (
      <Link className={style} to={to}>
        <i className={icon} />
        &nbsp;
        {title}
      </Link>
    );
  } else {
    return (
      <a href='#' className={style} onClick={onClick}>
        <i className={icon} />
        &nbsp;
        {title}
      </a>
    );
  }
}

interface AppBarProps {
  history: History;
  loggedIn: boolean;
  page: PageInfo;
  onLanguageChanged: (language: Language) => void;
}
export default class AppBar extends React.Component<AppBarProps> {
  getLanguageListener(language: Language) {
    return (e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      this.props.onLanguageChanged(language);
    }
  }

  handleClickedLogout = () => {
    api.logout();
    window.setTimeout(() => this.props.history.push('/login'));
  }

  handleClickedLogin = () => {
    window.setTimeout(() => this.props.history.push('/login'));
  }

  handleClickedUserSettings = () => {

  }

  isLoggedIn() {
    return api.isLoggedIn();
  }

  render() {
    const { page } = this.props;

    // use either 'container' or 'container-fluid'; container-fluid will make
    // the navbar fill the whole page width
    return <>
      <nav className='navbar navbar-expand-lg'>
        <div className='navbar-minimize' style={{ marginLeft: '-0.5em' }}>
          <button
            id='minimizeSidebar'
            className='btn btn-danger btn-fill btn-round btn-icon d-none d-lg-block'
            style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, width: 30, height: 30 }}
          >
            <i className={Icon.AngleLeft + ' visible-on-sidebar-regular'} />
            <i className={Icon.AngleRight + ' visible-on-sidebar-mini'} />
          </button>
        </div>
        <div className='container'>
          <span className='navbar-wrapper'>
            <a className='navbar-brand' href='#'>
              <span className={styles.page_title}>
                {page.icon && <i className={page.icon} />} {page.title}
              </span>
            </a>
          </span>
          <CollapseIcon />
          <div className='collapse navbar-collapse justify-content-end'>
            {api.isLoggedIn() && (
              <span className={styles.user}>
                <i className={Icon.User} style={{ paddingRight: 5 }} />
                {api.user.username}
              </span>
            )}
            {api.isLoggedIn() && <ul className='navbar-nav'>
              <SmallDropdownMenu>
                <DropdownMenuItem
                  icon={Icon.SignOut}
                  title={T('appbar.logout')}
                  style={DropdownMenuItemStyle.Danger}
                  onClick={this.handleClickedLogout}
                />
              </SmallDropdownMenu>
            </ul>}
            {!api.isLoggedIn() && <ul className='navbar-nav'>
              <SmallDropdownMenu>
                <DropdownMenuItem
                  icon={Icon.SignOut}
                  title={T('appbar.login')}
                  style={DropdownMenuItemStyle.Normal}
                  onClick={this.handleClickedLogin}
                />
              </SmallDropdownMenu>
            </ul>}
          </div>
          <div className={styles.languageSelector} style={{ float: 'right' }}>
            {getUILanguages().map((langcode, i) => (
              <span key={langcode}>
                {i > 0 && <span>&nbsp;|&nbsp;</span>}
                <a href="#" onClick={this.getLanguageListener(langcode)}>
                  {langcode.toUpperCase()}
                </a>
              </span>
            ))}
          </div>
        </div>
      </nav>
      {page.breadcrumbs.length > 0 && (
        <Container>
          <BreadcrumbsView breadcrumbs={page.breadcrumbs} />
        </Container>
      )}
    </>;
  }
}
