import React from 'react';
import { Card, Button } from 'react-bootstrap';

import api from '../../api';
import { EditLink } from '../../components/EditLink';
import { T } from '../../Translate';

import { User } from '../../models/User';
import { Person, Gender } from '../../models/Person';

import { PersonInfoView } from './PersonInfoView';
import { Icon } from '../../components/Icons';
import { PageID } from '../../utils/PageLinking';


interface HeaderViewProps {
  user: User;
  person: Person;

  handleClickedFeedback: () => void;
}
export const HeaderView = (props: HeaderViewProps) => {
  const { user, person, handleClickedFeedback } = props;

  return (
    <Card>
      <Card.Body>
        {api.isLoggedIn() && user.canEdit() && <EditLink page={PageID.EditPerson} param={person.id} />}
        {!api.isLoggedIn() && (
          <Button  onClick={handleClickedFeedback} style={{ float: 'right' }}>
            {T('page.person.feedback')}
          </Button>
        )}
        <h2 style={{ marginBottom: 0 }}>
          {person.firstName}
          {' '}
          {person.gender !== Gender.Undefined && <i className={person.gender === Gender.Male ? Icon.Male : Icon.Female} />}
        </h2>
        <h1 style={{ marginTop: 0, marginBottom: 0 }}>
          <span style={{ textTransform: 'uppercase' }}>{person.lastName}</span>
        </h1>
        <PersonInfoView person={person}/>
      </Card.Body>
    </Card>
  );
}
