import React, { useMemo, useEffect, useState } from 'react';
import { T, plural } from '../../Translate';
import { IContactPerson } from '../../models/ContactPerson';
import {
  ITableColumn,
  StringTableColumn,
  ActionsTableColumn,
  TableRowActionStyle,
  StringFieldTableColumn,
  ComponentTableColumn
} from '../../components/table/Fields';
import { Icon } from '../../components/Icons';
import { ConfirmationModal } from '../../modals/Confirmation';
import api from '../../api';
import { NotificationManager } from 'react-notifications';
import { IQueryResult } from '../../models/Generic';
import { ListState, ListPage } from '../ListPage';
import { PageProps } from '../Page';
import { useModalContext } from '../../modals/ModalContext';
import { usePageState } from '../../redux/Actions';
import { useRefresh } from '../../utils/Functional';
import { PageID } from '../../utils/PageLinking';

export interface ListContactPersonsStateProps extends ListState {}

function getSortColumn(name: string) {
  if (name === 'name')
    return 'filter';
  else
    return name;
}

const rowKey = (item: IContactPerson) => item._id;
const PageSize = 10;
export default (props: PageProps) => {
  const { loading, history, via } = props;
  const modals = useModalContext();
  const [pageState, updatePageState] = usePageState('contactPersons');
  const { filter, tablePage, sortColumn, sortDirection } = pageState;

  const [items, setItems] = useState<IQueryResult<IContactPerson>>();
  const refresh = useRefresh(() => {
    loading.loading(
      api.findContactPersons(
        filter,
        tablePage * PageSize,
        PageSize,
        [[getSortColumn(sortColumn), sortDirection]]
      ),
      plural('contactPerson')
    ).then(setItems);
  });
  useEffect(refresh, [loading, filter, tablePage, sortColumn, sortDirection]);

  const columns: ITableColumn<IContactPerson>[] = useMemo(() => {
    const handleClickedDelete = async (contact: IContactPerson) => {
      const name = contact.firstName + ' ' + contact.lastName;
      const confirmed = await modals.show(props => (
        <ConfirmationModal
          title={T('page.listContactPersons.remove.title')}
          message={T('page.listContactPersons.remove.message', { name })}
          acceptText={T('generic.action.remove')}
          acceptStyle='danger'
          rejectText={T('generic.action.cancel')}
          {...props}
        />
      ));
      if (!confirmed)
        return;

      try {
        await api.deleteContactPerson(contact._id);
        NotificationManager.success(T('page.listContactPersons.remove.success', { name }));
        refresh();
      }
      catch {
        NotificationManager.error(T('page.listContactPersons.remove.failure'));
      }
    };

    return [
      new ComponentTableColumn(
        'name',
        T('contactPerson.name'),
        contact => <strong>{contact.firstName + ' ' + contact.lastName}</strong>
      ),
      new StringFieldTableColumn(
        'email',
        T('contactPerson.email')
      ),
      new StringFieldTableColumn(
        'phone',
        T('contactPerson.phone')
      ),
      new ActionsTableColumn(
        'actions',
        T('generic.list.actions'),
        contact => [
          {
            icon: Icon.Edit,
            style: TableRowActionStyle.Success,
            tooltip: T('generic.action.edit'),
            page: PageID.EditContactPerson,
            pageParam: contact._id
          },
          {
            icon: Icon.TimesCircle,
            style: TableRowActionStyle.Danger,
            tooltip: T('generic.action.remove'),
            onClick: handleClickedDelete
          }
        ],
        { width: 80 }
      )
    ];
  }, [modals, refresh]);

  const handleClickedAdd = () => {
    history.push('/contact_persons/create');
  }
  const user = api.user;

  return (
    <ListPage
      id={PageID.ListContactPersons}
      icon={Icon.AddressBook}
      title={T('page.listContactPersons.title')}
      via={via}
      noun='contactPerson'
      columns={columns}
      items={items}
      state={pageState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedAdd={user.canEdit() ? handleClickedAdd : undefined}
    />
  );
}
