import React from 'react';
import { ModalProps, useModal } from './BaseModal';
import { T } from '../Translate';
import { Button, Modal } from 'react-bootstrap';
import { ButtonRowModalFooter } from '../components/ButtonRow';
import { ModalHeader } from './ModalHeader';

type ButtonStyle = 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light'
  | 'link'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-success'
  | 'outline-danger'
  | 'outline-warning'
  | 'outline-info'
  | 'outline-dark'
  | 'outline-light';

interface ConfirmationModalProps extends ModalProps<boolean> {
  title: string;
  message: string;
  warning?: string;

  acceptStyle?: ButtonStyle;
  acceptText?: string;
  rejectText?: string;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { title, message, warning, acceptStyle, acceptText, rejectText } = props;

  const [isOpen, resolve] = useModal(props);
  const handleClickedNo = () => resolve(false);
  const handleClickedYes = () => resolve(true);

  return (
    <Modal show={isOpen} onHide={handleClickedNo}>
      <ModalHeader>{title}</ModalHeader>
      <Modal.Body>
        <p>{message}</p>
        {warning && <p style={{ color: 'rgb(250, 27, 40)' }}>{warning}</p>}
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant="primary" onClick={handleClickedNo}>
          {rejectText || T('modal.confirmation.no')}
        </Button>
        <Button variant={acceptStyle || 'primary'} onClick={handleClickedYes}>
          {acceptText || T('modal.confirmation.yes')}
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
