import React, { useState } from 'react';
import { IPersonStory2, Language, StoryStatus } from "../../../models/PersonStory2";
import { IStoriesErrors, StoriesErrorsWrapper } from "./StoriesData";
import { StoriesStatus } from "./StoriesStatus";
import { Col, Row } from "react-bootstrap";
import { LanguageSelector } from "./LanguageSelector";
import { StoryIndex, StoryTextView } from "./StoryTextView";
import { StoriesCheckbox } from "./StoriesCheckbox";
import { T } from "../../../Translate";
import { PersonStoryWrapper } from "../../../models/wrappers/PersonStoryWrapper";
import { PersonStoryStatusViewForLanguages } from "./PersonStoryStatusForLanguagesView";


export interface StoriesTabProps {
  personStory?: IPersonStory2
  errors?: IStoriesErrors
  readOnly: boolean;
  onUpdateStories: (personStory: Partial<IPersonStory2>) => void
}


export const StoriesTab = (props: StoriesTabProps) => {

  const { personStory, errors, readOnly, onUpdateStories } = props;

  /** State **/
  const [language, setLanguage] = useState(Language.NL)

  /** Event Handlers **/

  const handleOnUpdateStory = (personStory: Partial<IPersonStory2>) => {
    onUpdateStories(personStory);
  }

  const handleOnUpdateLanguage = (newLanguage: Language) => {
    setLanguage(newLanguage);
  }

  const onUpdateStatus = (newStatus: StoryStatus, storyIndex: StoryIndex) => {
    updateStoryWithStatus(newStatus, storyIndex)
    if (personStory !== undefined) {
      handleOnUpdateStory(personStory)
    }
  }

  const updateStoryWithStatus = (newStatus: StoryStatus, index: StoryIndex) => {
    if (personStory === undefined)
      return;
    const personStoryWrapper = new PersonStoryWrapper(personStory)
    personStoryWrapper.updateStoryWithStatus(newStatus, index, language)
  }

  /** Helpers **/

  const getStatus = (index: StoryIndex) => {
    const personStoryWrapper = new PersonStoryWrapper(personStory!)
    return personStoryWrapper.getStoryStatus(index,language)
  }

  const storiesErrors = new StoriesErrorsWrapper(errors);

  return <>
    <Row>
      <Col sm={4}>
      </Col>
      <Col style={{textAlign: "right"}} sm={4}>
        <LanguageSelector
          onUpdateLanguage={handleOnUpdateLanguage}
          errors={storiesErrors.languagesWithErrors()}
        />
      </Col>
    </Row>
    <Row>
      <Col sm={8}>
        <StoryTextView
          name='story1'
          personStory={personStory}
          labelText={T("personstories.story1")}
          language={language}
          storyIndex={StoryIndex.STORY_1}
          error={storiesErrors.getStoryTextErrorMessage(language, StoryIndex.STORY_1)}
          readOnly={readOnly}
          onUpdateStory={handleOnUpdateStory}
        />
      </Col>
      <Col sm={4}>
        <StoriesStatus
          storyStatus={getStatus(StoryIndex.STORY_1)}
          labelText={T('personstories.story.status')}
          error={storiesErrors.getStoryStatusErrorMessage(language, StoryIndex.STORY_1)}
          readOnly={readOnly}
          onUpdateStatus={newStatus => onUpdateStatus(newStatus, StoryIndex.STORY_1)}
        />
        <PersonStoryStatusViewForLanguages
          personStory={personStory!}
          storyIndex={StoryIndex.STORY_1}
        />
      </Col>
    </Row>
    <Row>
      <Col sm={8}>
        <StoryTextView
          name='story2'
          personStory={personStory}
          labelText={T("personstories.story2")}
          language={language}
          storyIndex={StoryIndex.STORY_2}
          error={storiesErrors.getStoryTextErrorMessage(language, StoryIndex.STORY_2)}
          readOnly={readOnly}
          onUpdateStory={handleOnUpdateStory}
        />
      </Col>
    </Row>
    <Row>
      <Col sm={8}>
        <StoriesCheckbox personStory={personStory} readOnly={readOnly} onUpdateStories={handleOnUpdateStory} />
      </Col>
    </Row>
    <div style={{marginBottom:10}}/>
    {personStory?.story_child_option === true && (
      <Row>
        <Col sm={8}>
          <StoryTextView
            name='story-child1'
            personStory={personStory}
            labelText={T("personstories.storychild1")}
            language={language}
            storyIndex={StoryIndex.STORY_CHILD_1}
            error={storiesErrors.getStoryTextErrorMessage(language, StoryIndex.STORY_CHILD_1)}
            readOnly={readOnly}
            onUpdateStory={handleOnUpdateStory}
          />
        </Col>
        <Col sm={4}>
          <StoriesStatus
            storyStatus={getStatus(StoryIndex.STORY_CHILD_1)}
            labelText={T('personstories.storyChild.status')}
            error={storiesErrors.getStoryStatusErrorMessage(language, StoryIndex.STORY_CHILD_1)}
            readOnly={readOnly}
            onUpdateStatus={newStatus => onUpdateStatus(newStatus, StoryIndex.STORY_CHILD_1)}
          />
          <PersonStoryStatusViewForLanguages
            personStory={personStory!}
            storyIndex={StoryIndex.STORY_CHILD_1}
          />
        </Col>
      </Row>
    )}
    {personStory?.story_child_option === true &&
      <Row>
        <Col sm={8}>
          <StoryTextView
            name='story-child2'
            personStory={personStory}
            labelText={T("personstories.storychild2")}
            language={language}
            storyIndex={StoryIndex.STORY_CHILD_2}
            error={storiesErrors.getStoryTextErrorMessage(language, StoryIndex.STORY_CHILD_2)}
            readOnly={readOnly}
            onUpdateStory={handleOnUpdateStory}
          />
        </Col>
      </Row>
    }
  </>;
};
