import { IPlace, toLatLng } from "../../models/Place";
import { T } from "../../Translate";

const DefaultLocation = { lat: 50.8486, lng: 4.3742 };

export interface Position {
  lat: number,
  lng: number
}

export enum ActualPositionSource {
  NONE = 0,
  CUSTOM = 1,
  ADDRESS = 2,
  PLACE = 3
}

/**
 * Which location is the actual location. custom > address > place > DefaultLocation
 *
 * @param customPosition
 * @param addressPosition
 * @param place
 */
export const actualPosition = (customPosition: Position | undefined,
                               addressPosition: Position | undefined,
                               place: IPlace | undefined) : Position => {
  return customPosition
    || addressPosition
    || (place && toLatLng(place.loc))
    || DefaultLocation;
}

export const actualPositionSource = (customPosition: Position | undefined,
                                     addressPosition: Position | undefined,
                                     place: IPlace | undefined): ActualPositionSource => {
  if (customPosition)
    return ActualPositionSource.CUSTOM
  if (addressPosition)
    return ActualPositionSource.ADDRESS
  if (place && toLatLng(place.loc))
    return ActualPositionSource.PLACE
  return ActualPositionSource.NONE
}

export const actualPositionSourceText = (source: ActualPositionSource) : string => {
  switch (source) {
    case ActualPositionSource.NONE:
      return T('memorial.position.source.none')
    case ActualPositionSource.ADDRESS:
      return T('memorial.position.source.address')
    case ActualPositionSource.PLACE:
      return T('memorial.position.source.place')
    case ActualPositionSource.CUSTOM:
      return T('memorial.position.source.custom')
  }
}


export const isAddressPositioned = (source: ActualPositionSource): boolean => {
  return source === ActualPositionSource.CUSTOM;
}

