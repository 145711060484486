import React from 'react';
import PageLink from '../../components/PageLink';
import { Person } from '../../models/Person';
import { IPublishedPersonStory } from '../../models/PersonStory2';
import { PageID } from '../../utils/PageLinking';
import styles from './StoryView.module.scss';

interface StoryViewProps {
  story: IPublishedPersonStory;
  person: Person;
  child: boolean;
}

export default function StoryView(props: StoryViewProps) {
  const { story, person } = props;

  const firstName = person ? person.firstName : '';
  const lastName = person ? person.lastName : '';
  return (
    <PageLink page={PageID.PublicViewPersonStory} param={story._id}>
      <div className={styles.wrapper}>
        <img className={styles.image} src={story.profile_image_url} />
        <div className={styles.name}>
          <p className={styles.firstName}>{firstName}</p>
          <p className={styles.lastName}>{lastName}</p>
        </div>
      </div>
    </PageLink>
  );
}
