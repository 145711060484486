import React from 'react';
import { IContactPerson } from '../../models/ContactPerson';
import { ModalProps, useModal } from '../../modals/BaseModal';
import { Modal, Button } from 'react-bootstrap';
import { T } from '../../Translate';
import { ModalHeader } from '../../modals/ModalHeader';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { Place } from '../../models/Place';

interface ViewContactPersonModalProps extends ModalProps<boolean> {
  contactPerson: IContactPerson;
}

export const ViewContactPersonModal = (props: ViewContactPersonModalProps) => {
  const { contactPerson } = props;
  const [isOpen, resolve] = useModal(props);

  const handleClose = () => resolve(false);
  const handleSelect = () => resolve(true);

  const name = contactPerson.firstName + ' ' + contactPerson.lastName;
  const title = T('page.contactPerson.selectExisting.title', { name });
  const placeName = contactPerson.place ? new Place(contactPerson.place).describe() : '';

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <ModalHeader>{title}</ModalHeader>
      <Modal.Body>
        <dl className='row'>
          <dt className='col-sm-3'>{T('contactPerson.name')}</dt>
          <dd className='col-sm-9'>{name}</dd>
          <dt className='col-sm-3'>{T('contactPerson.email')}</dt>
          <dd className='col-sm-9'>{contactPerson.email}</dd>
          <dt className='col-sm-3'>{T('contactPerson.phone')}</dt>
          <dd className='col-sm-9'>{contactPerson.phone}</dd>
          <dt className='col-sm-3'>{T('contactPerson.streetNr')}</dt>
          <dd className='col-sm-9'>{contactPerson.street} {contactPerson.streetNumber}</dd>
          <dt className='col-sm-3'>{T('contactPerson.place')}</dt>
          <dd className='col-sm-9'>{placeName}</dd>
        </dl>
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='primary' onClick={handleSelect}>
          {T('page.contactPerson.selectExisting.select')}
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
};
