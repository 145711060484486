import React, { useEffect, useMemo, useState } from 'react';
import api from '../../api';
import { ICountry } from '../../models/ICountry';
import { ColumnInputGroup } from './ColumnInputGroup';
import { SimpleAutocomplete } from './SimpleAutocomplete';

interface CountryInputProps {
  value: string;
  onChange: (value: string) => void;

  placeholder?: string;
  readOnly?: boolean;
}

export function CountryInput(props: CountryInputProps) {
  const { value, onChange, placeholder, readOnly } = props;

  const [inputValue, setInputValue] = useState(value);

  const [countries, setCountries] = useState<ICountry[]>([]);
  const country = useMemo(() => countries.find(c => c.code === value), [countries, value]);

  useEffect(() => {
    api.getCountries().then(countries => {
      setCountries(countries);
    });
  }, []);

  useEffect(() => country && setInputValue(country.name), [country]);

  const options = useMemo(() => {
    const inputUpper = inputValue.toLocaleUpperCase();
    const filtered = countries.filter(country => country.name.toLocaleUpperCase().includes(inputUpper) || country.code === inputUpper);
    return filtered.slice(0, 10);
  }, [countries, inputValue]);

  return (
    <SimpleAutocomplete
      placeholder={placeholder}
      inputValue={inputValue}
      options={options}
      itemKey={country => country.code}
      itemValue={country => country.code}
      loading={countries.length === 0}
      renderItem={country => country.name}
      onSelect={(code, country) => {
        onChange(country.code);
        setInputValue(country.name);
      }}
      onInputChange={setInputValue}
      onBlur={() => country && setInputValue(country.name)}
      readOnly={readOnly}
    />
  );
}

interface CountryInputGroupProps {
  id: string;
  label: string;

  value: string;
  onChange: (value: string) => void;

  placeholder?: string;
  readOnly?: boolean;
  error?: string;
}

export function CountryInputGroup(props: CountryInputGroupProps) {
  const { id, label, value, onChange, placeholder, readOnly, error } = props;

  return (
    <ColumnInputGroup id={id} label={label} error={error}>
      <CountryInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </ColumnInputGroup>
  );
}
