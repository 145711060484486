import React from 'react';
import { Modal } from 'react-bootstrap';

interface ModalHeaderProps {
  children: string|JSX.Element;
}
export const ModalHeader = (props: ModalHeaderProps) => {
  const { children } = props;

  return (
    <Modal.Header closeButton>
      <Modal.Title style={{ marginTop: 0 }}>{children}</Modal.Title>
    </Modal.Header>
  );
}
