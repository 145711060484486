import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Page, PageProps } from '../Page';
import api from '../../api';
import {
  Document,
  DocumentStatus,
  IDocument,
  IDocumentSubdocument,
  DocumentCategory,
  DocumentKind,
  IDocumentPage
} from '../../models/Document';
import { Button, Col, Row, Card, Dropdown, DropdownButton, ButtonGroup, Badge } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { T } from '../../Translate';
import { AnyAppAction, registerContactMomentContributionAction, setSharedPageState } from '../../redux/Actions';
import { ConfirmationModal } from '../../modals/Confirmation';
import { DocumentReference, DocumentReferenceType } from '../../models/DocumentReference';
import { AppState, ISharedPageState } from '../../redux/State';
import { Icon } from '../../components/Icons';
import { ActiveContactMoment } from '../../models/ActiveContactMoment';
import { BottomButtonRow, ButtonRowTop } from '../../components/ButtonRow';
import { DocumentElementPreview } from './DocumentElementPreview';
import { UnsavedChangesModal } from '../../modals/UnsavedChangesModal';
import styles from './index.module.scss';
import { PageFileUploader } from '../../components/FileUploader';
import { classes } from '../../utils/Styles';
import { CenteredPane } from '../../components/CenteredPane';
import { useContactPersonEventsForDocument, useDocument, useDocumentTagDefinitions } from '../../FunctionalAPI';
import { DocumentCollection } from '../../models/Collection';
import { useModalContext } from '../../modals/ModalContext';
import { NotificationManager } from 'react-notifications';
import { useAppSelector } from '../../utils/Functional';
import { PageID, getPageVia } from '../../utils/PageLinking';
import { CheckAdlibCodeResult } from '../../models/CheckAdlibCodeResult';
import { getFormStateForDocument, didFormStateChange, IDocumentFormState } from './DocumentFormState';
import EditDocumentForm from './EditDocumentForm';
import { ImageViewer } from "../EditDocumentPage/ImageBrowser";
import { ButtonLink } from "../../components/ButtonLink";
import { Colors, TextStyles } from "../../config/styles";
import { getFiletype } from "../EditDocumentPage";
import { getErrorDescription, getErrorName, IValidationResult } from "../../models/ValidationResult";
import MoveToDocumentModal, { IMoveToDocumentResult } from "../../modals/MoveDocument/MoveToDocumentModal";
import PageLink from "../../components/PageLink";

function createEmptyDocument(
  category: DocumentCategory,
  adlibInfo: CheckAdlibCodeResult = { type: 'invalid' },
  references: DocumentReference[] = []
) {
  const title = adlibInfo.type === 'document' ? adlibInfo.title : undefined;
  const description = adlibInfo.type === 'document' ? adlibInfo.description : undefined;
  const adlibId = adlibInfo.type !== 'invalid' ? adlibInfo.id : undefined;
  const adlibCode = adlibInfo.type !== 'invalid' ? adlibInfo.code : undefined;

  const collection = adlibInfo.type === 'collection'
    ? DocumentCollection.fromJSON(adlibInfo.collection)
    : undefined;

  return new Document(
    '',
    category,
    DocumentKind.Digital,
    undefined,
    undefined,
    [],
    DocumentStatus.Todo,
    false,
    [],
    references,
    [],
    title,
    description,
    moment(),
    moment(),
    false,
    collection && collection.id,
    collection,
    undefined,
    adlibId,
    adlibCode
  );
}

interface DocumentAnnotatorPageProps extends PageProps {
  id?: string;
  collection?: string;
  category?: DocumentCategory;
  code?: string;
  ref_type?: 'person'|'place'|'memorial'|'military_entity'
  ref_id?: string
  ref_name?: string
}


export default (props: DocumentAnnotatorPageProps) => {

  const {
    id,
    history,
    category = DocumentCategory.Adlib,
    code,
    loading,
    via,
    ref_id,
    ref_type,
    ref_name
  } = props;

  /*** provider ***/

  const modals = useModalContext();

  /*** state & variables ***/

  const readOnlyFromState = useAppSelector(state => state.sharedPageState.editingDocumentId) !== id;
  const readOnly = api.user.isReadOnly() || readOnlyFromState;

  const [adlibInfo, setAdlibInfo] = useState<CheckAdlibCodeResult>();
  const [fullscreenPage, setFullScreenPage] = useState<IDocumentPage | undefined >(undefined)


  const [ignoreNavigationCheck, setIgnoreNavigationCheck] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const [references, setReferences] = useState<DocumentReference[]>([]);
  const contactMoment = useSelector<AppState, ActiveContactMoment|undefined>(
    state => state.activeContactMoment.current
  );
  const tagDefinitions = useDocumentTagDefinitions(loading);
  const [loadedDocument,, setLoadedDocument] = useDocument(loading, id);
  const [contactEvent] = useContactPersonEventsForDocument(loading, id);

  const document = useMemo(
    () => (loadedDocument && Document.fromJSON(loadedDocument, tagDefinitions))
       || createEmptyDocument(category, adlibInfo, references),
    [loadedDocument, tagDefinitions, category, adlibInfo/*, references*/]
  );

  const originalFormState = useMemo(() => {
    return getFormStateForDocument(document);
  }, [document]);
  const [formState, setFormState] = useState(getFormStateForDocument(document));

  const documentCategory = document.category;
  const hasFiles = documentCategory === DocumentCategory.AdlibCollectionDocument || documentCategory === DocumentCategory.Digital;

  const dispatch = useDispatch<Dispatch<AnyAppAction>>();

  /*** effects **/

  useEffect(() => {
    if (!code)
      return;

    api.checkAdlibCode(code).then((newAdlibInfo) => {
      setAdlibInfo(newAdlibInfo)
    });
  }, [code]);


  useEffect(() => {
    setFormState(originalFormState)
  }, [originalFormState]);

  useEffect(() => {
    setReferences(document.references);
    setSelectedIndexes([]);
  }, [document]);

  const updateFormState = useCallback((updates: Partial<IDocumentFormState>) => {
    setFormState(state => Object.assign({}, state, updates));
  }, []);

  useEffect(() => {
    if (ref_id && ref_name && ref_type) {
      const newReferences = [new DocumentReference(ref_id, ref_type as DocumentReferenceType, ref_name)]
      updateFormState({references: newReferences })
    }
  }, [ref_id, ref_name, ref_type, updateFormState]);

  useEffect(() => {
    setReferences(formState.references)
  }, [formState])

  const {
    kind,
    source,
    title,
    description,
    institution,
    inventoryId,
    url,
    wiseProfile,
    wiseBranch,
    wiseLocation,
    wiseId,
    pages,
    status,
    disclosed,
    tags,
  } = formState;

  const tagsHaveChanged = useMemo(() => {
    if (document.tags.length !== tags.length)
      return true;

    for (let i = 0; i < tags.length; i++) {
      if (!document.tags[i].equals(tags[i]))
        return true;
    }

    return false;
  }, [document, tags]);

  const referencesHaveChanged = useMemo(() => {
    if (document.references.length !== references.length)
      return true;

    for (let i = 0; i < references.length; i++) {
      if (document.references[i].id !== references[i].id)
        return true;
    }

    return false;
  }, [document, references]);

  const contentHasChanged = (
    didFormStateChange(originalFormState, formState)
    || tagsHaveChanged
    || referencesHaveChanged
  );

  const confirmNavigation = useCallback(() => {
    // Do not warn the user to save the content before navigating to another page
    // when content has not changed or when we just saved a new document and
    // navigate to it.
    if (!contentHasChanged || ignoreNavigationCheck)
    {
      setIgnoreNavigationCheck(false)
      return Promise.resolve(true);
    }

    modals.show(UnsavedChangesModal);
    setIgnoreNavigationCheck(false)
    return Promise.resolve(false);
  }, [modals, contentHasChanged, ignoreNavigationCheck]);

  const handleDocumentUpdated = (newDocumentJson: IDocument) => {
    setLoadedDocument(newDocumentJson);
  };

  const handleClickedSaveNew = async () => {
    const validationResult = await api.validateCollectionDocument(stateToDocument() as IDocument, 'create')
    if (validationResult.status !== 'OK') {
      setValidationErrors(validationResult)
      NotificationManager.error(T('documents.messages.saveFailed'))
      return
    }
    const created = await api.createCollectionDocument({
      category: category || DocumentCategory.Adlib,
      collection_id: document.collectionId || undefined,
      kind,
      status,
      disclosed,
      tags: tags.map(tag => tag.toJSON()),
      references: references.map(reference => reference.toJSON()),
      title: title || null,
      description: description || null,
      source,

      adlibId: document.adlibId,
      adlibCode: document.adlibCode,

      institution,
      inventoryId,

      url,

      wiseProfile,
      wiseBranch,
      wiseLocation,
      wiseId,
      pages
    });
    setIgnoreNavigationCheck(true);
    NotificationManager.success(T('page.document.saved'));
    history.push(`/documents/${created._id}`);
  };

  /**
   * Make an IDocument instance from this component state
   */
  const stateToDocument = (): IDocument => {
    const newDocument: Document =  Document.fromProps({
      id: document.id,
      kind,
      category: document.category,
      status,
      disclosed,
      tags,
      references,
      title: title || '',
      description: description || null,
      institution,
      inventoryId,
      source,
      url,
      referenced_entities: document.referenced_entities,
      elements: document.elements,
      wiseProfile,
      wiseBranch,
      wiseLocation,
      wiseId,
      pages})
    const jsonDocument: IDocument = newDocument.toJSON()
    return jsonDocument
  }

  const setValidationErrors = (result: IValidationResult | undefined): boolean => {
    if (result && result.status !== 'OK') {
      // reset the error state variables
      const errors = result.data || {}
      setFormState(Object.assign({}, formState, {
        titleError: getErrorDescription(errors.title),
        urlError: getErrorDescription(errors.url)
      }));
    }
    return true;
  };

  const handleClickedSaveExisting = async () => {
    const validationResult = await api.validateCollectionDocument(stateToDocument() as IDocument, 'update')
    if (validationResult.status !== 'OK') {
      setValidationErrors(validationResult)
      NotificationManager.error(T('documents.messages.saveFailed'))
      return
    }

    const savedDocument = await api.updateCollectionDocument(stateToDocument());

    setLoadedDocument(savedDocument);
    NotificationManager.success(T('page.document.saved'));

    if (contactMoment) {
      const confirm = await modals.show<boolean>(props => (
        <ConfirmationModal
          title={T('document.registerContribution.title')}
          message={T('document.registerContribution.message')}
          {...props}
        />
      ));
      if (confirm) {
        dispatch(registerContactMomentContributionAction({
          type: 'document',
          targetId: savedDocument._id,
          name: Document.generateTitle(tags)
        }));
      }
    }
  };

  const handleClickedSave = () => {
    if (id === undefined)
      handleClickedSaveNew();
    else
      handleClickedSaveExisting();
  };

  const handleClickedCancel = () => {
    if (!id) {
      setIgnoreNavigationCheck(true);
      history.goBack();
    } else {
      setFormState(originalFormState);
      setReferences(document.references);
    }
  };

  const handleClickedRemove = async () => {
    if (!id)
      return;

    const validationResult = await api.validateCollectionDocument(stateToDocument() as IDocument, 'delete')
    if (validationResult.status !== 'OK') {
      for (let key in validationResult.data) {
        NotificationManager.error(getErrorName(validationResult.data[key]),
          T('documents.messages.saveFailed'),
          5000)
      }
      return
    }

    const result = await modals.show(props => (
      <ConfirmationModal
        title={T('document.remove.confirmTitle')}
        message={T('document.remove.confirmMessage')}
        warning={T('generic.cannotUndo')}
        acceptStyle='danger'
        acceptText={T('generic.action.remove')}
        rejectText={T('generic.action.cancel')}
      {...props}
      />
    ));
    if (!result)
      return;

    await api.removeCollectionDocument(id);

    history.push(`/documentation`);
  }

  const handleClickedRestore = async () => {
    await api.restoreCollectionDocument(document.id);
    history.push(`/collections/${document.collectionId}`);
  };

  const handleClickedMoveToNewSubdocument = async () => {
    if (!id)
      return;

    // Add the new subdocument to the start of the elements
    const { subdocument } = await api.addCollectionDocumentSubdocument(id, selectedIndexes[0]);

    // Because we added the new index to the start of the elements, all existing elements shift to the right
    // and we have to increase the selected indeces by 1.
    const newIndexes = selectedIndexes.map(index => index + 1);
    const { from } = await api.moveCollectionDocumentElements(id, subdocument._id, newIndexes)
    handleDocumentUpdated(from);
    setSelectedIndexes([]);
  }

  const handleClickedMoveToSubdocument = async (subdocument: IDocumentSubdocument) => {
    if (!id)
      return;

    const { from } = await api.moveCollectionDocumentElements(
      id,
      subdocument.documentId,
      selectedIndexes
    );
    handleDocumentUpdated(from);
    setSelectedIndexes([]);
  }

  const handleClickedMoveToMainDocument = async () => {
    if (!id)
      return;

    if (!document.parentId)
      return;

    const { from } = await api.moveCollectionDocumentElements(
      id,
      document.parentId,
      selectedIndexes
    );
    setSelectedIndexes([]);
    handleDocumentUpdated(from);
  };

  const handleClickedMoveDocument = async () => {
    if (!id)
      return;

    modals.show<IMoveToDocumentResult>(props =>
      (
        // isSubDocument will enable or disable the root checkbox.
        // This should only be enabled if the selection only contains subdocuments.
        <MoveToDocumentModal isSubDocument={hasOnlySelectedSubdocuments} {...props}/>
      ))
      .then(result => {
        if (result.root) {
          return api.moveCollectionDocumentElements(id, null, selectedIndexes)
        }
        if (result.document) {
          return api.moveCollectionDocumentElements(id, result.document._id, selectedIndexes)
        }
      })
      .then(documents => {
        if (documents) {
          handleDocumentUpdated(documents.from);
        }
        setSelectedIndexes([]);
      })
      .catch(e => {

      })
  }


  const handleClickedElement = (index: number) => {
    const element = document.elements[index];
    if (element.type === 'page') {
      history.push(`/documents/${id}/${index}`);
    } else if (element.type === 'subdocument') {
      history.push(`/documents/${element.documentId}`);
    }
  };

  const handlePageUpdated = (document: IDocument|'NOK') => {
    if (document === 'NOK') {
      NotificationManager.error(T('page.document.pageUploadFailed'));
    } else {
      handleDocumentUpdated(document);
      NotificationManager.success(T('page.document.pageUploaded'));
    }
  };

  const handleClickedAddSubdocument = async () => {
    if (!id)
      return;

    const { main } = await api.addCollectionDocumentSubdocument(id);
    handleDocumentUpdated(main);
  };

  const handleSelectedIndex = (index: number, selected: boolean) => {
    if (selected) {
      setSelectedIndexes([...selectedIndexes, index]);
    } else {
      setSelectedIndexes(selectedIndexes.filter(i => i !== index));
    }
  };

  const handleClickedRemovePages = async () => {
    if (!id)
      return;

    const result = await modals.show<boolean>(props => (
      <ConfirmationModal
        title={T('document.removePages.confirmTitle')}
        message={T('document.removePages.confirmMessage')}
        warning={T('generic.cannotUndo')}
        acceptStyle='danger'
        acceptText={T('generic.action.remove')}
        rejectText={T('generic.action.cancel')}
      {...props}
      />
    ));
    if (!result)
      return;

    const document = await api.removeCollectionDocumentElements(id, selectedIndexes);
    setSelectedIndexes([]);
    handleDocumentUpdated(document);
  }

  const handleClickedEdit = () => {
    const newReadOnly = !readOnly;
    dispatch(setSharedPageState(newReadOnly ? {} : { editingDocumentId: id }));
  }

  const hasSelectedSubdocuments = selectedIndexes.some(
    index => index < document.elements.length && document.elements[index].type === 'subdocument'
  );

  const hasOnlySelectedSubdocuments = selectedIndexes.every(
    index => index < document.elements.length && document.elements[index].type === 'subdocument'
  );

  /*** rendering ***/
  const pageTitle = formState.title //loadedDocument ? document.title || T('page.document.untyped') : T('page.document.create.title');


  const rightPanelFullScreenPreview = () => {
    if (!fullscreenPage)
      return <></>

    return (
      <Card style={{ marginTop: '1em', height: 'calc(100% - 3em)' }}>
        <Card.Header>
          <ButtonLink title={'Close'} style={TextStyles.link.medium} onClick={() => setFullScreenPage(undefined)}>
            <i className={"fas fa-angle-left"} style={{marginRight: 4}}/>{T('document.title.scans')}
          </ButtonLink>
        </Card.Header>
        <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <ImageViewer
            filetype={getFiletype(fullscreenPage)}
            url={fullscreenPage.url_resized}
            originalUrl={fullscreenPage?.url_original}
          />
        </Card.Body>
      </Card>
    );
  }

  const rightPanelElementsCollectionView = () => {
    return (
      <Card style={{ marginTop: '1em', height: 'calc(100% - 3em)' }}>
        <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <Card.Title style={{marginBottom: '1em'}}>
            <i className={Icon.Images} />{' '}
            {T('document.title.scans')}
          </Card.Title>
          {selectedIndexes.length > 0 && (
            <Card.Header style={{backgroundColor: Colors.verylightGray, paddingBottom: '1em'}}>
              <p style={{...TextStyles.text.medium, marginBottom: '0.5rem'}}>
                Kies een actie op jouw selectie(s):
              </p>
              <ButtonRowTop>
                {!hasSelectedSubdocuments && document.parentId === null && (
                  <DropdownButton size='sm' variant='outline-secondary' as={ButtonGroup} id='add-to-subdocument' title={T('document.addToSubdocument')} drop='down'>
                    <Dropdown.Item eventKey="new" onClick={handleClickedMoveToNewSubdocument}>
                      {T('document.newSubdocument')}
                    </Dropdown.Item>
                    {document.elements.filter(element => element.type === 'subdocument').map(element => {
                      const subdocument = element as IDocumentSubdocument;
                      const key = subdocument.documentId;
                      const title = (subdocument.document && Document.fromJSON(subdocument.document, tagDefinitions).title) || T('document.noTitle');
                      return (
                        <Dropdown.Item key={key} eventKey={key} onClick={() => handleClickedMoveToSubdocument(subdocument)}>{title}</Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                )}
                {document.parentId !== null && (
                  <Button size='sm' variant='outline-secondary' onClick={handleClickedMoveToMainDocument}>
                    {T('document.moveToParent')}
                  </Button>
                )}
                {(
                  <Button size='sm' variant='outline-secondary' onClick={handleClickedMoveDocument}>
                    {T('document.move')}
                  </Button>
                )}
                <Button className='btn-danger' size='sm' variant='outline-danger' onClick={handleClickedRemovePages}>
                  {T('document.removePages')}
                </Button>
              </ButtonRowTop>
            </Card.Header>
          )}
          <Card.Text as='div' className={styles.elementList}>
            {document.elements.map((element, index) => (
              <DocumentElementPreview
                key={index}
                index={index}
                element={element}
                selected={selectedIndexes.includes(index)}
                readOnly={readOnly}
                onClick={() => handleClickedElement(index)}
                onSelected={selected => handleSelectedIndex(index, selected)}
                onPreview={selected => setFullScreenPage(selected)}
                tagDefinitions={tagDefinitions}
                showTitle={true}
              />
            ))}
            {!hasFiles && (
              <CenteredPane>
                <small className='form-text text-muted'>{T('page.document.uploadsNotSupported')}</small>
              </CenteredPane>
            )}
            {hasFiles && id === undefined && (
              <CenteredPane>
                <small className='form-text text-muted'>{T('document.saveBeforeAddingPages')}</small>
              </CenteredPane>
            )}
            {hasFiles && !readOnly && id && <>
              <AddPageControl
                documentId={id}
                documentElement={document.elements.length + 1}
                onPageUploaded={handlePageUpdated}
              />
              {document.parentId === null && (
                <AddSubdocumentControl
                  onClickedAddSubdocument={handleClickedAddSubdocument}
                />
              )}
            </>}
          </Card.Text>
        </Card.Body>

      </Card>
    )
  }

  const leftPanel = (
    <EditDocumentForm
      tagDefinitions={tagDefinitions}
      document={document}
      contactEvent={contactEvent ? contactEvent[0] : undefined}
      readOnly={readOnly}
      formState={formState}
      updateFormState={updateFormState}
      contentHasChanged={contentHasChanged}
      onClickedEdit={handleClickedEdit}
    />
  );

  const rightPanel = fullscreenPage ? rightPanelFullScreenPreview() : rightPanelElementsCollectionView()

  const sharedPageState: ISharedPageState = readOnly ? {} : { editingDocumentId: id };
  let translatedVia = via;
  let viaTitle = undefined;
  if (via === 'c') {
    if (loadedDocument && loadedDocument.collection) {
      translatedVia = `${PageID.ViewCollection}+${loadedDocument.collection._id}`;
      viaTitle = DocumentCollection.fromJSON(loadedDocument.collection).composedTitle();
    } else {
      translatedVia = PageID.None;
      viaTitle = '...';
    }
  }

  return (
    <Page
      id={id ? PageID.EditDocument : PageID.CreateDocument}
      entity={id}
      icon={Icon.FileAlt}
      title={pageTitle}
      confirmNavigation={confirmNavigation}
      sharedPageState={sharedPageState}
      via={translatedVia || (document.parent ? getPageVia(PageID.EditDocument, document.parentId || '') : PageID.ListDocumentation)}
      viaTitle={viaTitle}
    >
      {document.parentId && (
        <div style={{paddingLeft: '1rem'}}>
          <Badge variant={"secondary"}>SUB</Badge><PageLink page={PageID.EditDocument} param={document.parentId} style={{...TextStyles.link.small,marginLeft:8}} >{T('document.subdocument.open.parent')}</PageLink>
        </div>
      )}
      <Row style={{ marginBottom: '2.5em' }}>
        <Col md={6}>{leftPanel}</Col>
        <Col md={6}>{rightPanel}</Col>
      </Row>

      {!readOnly && (
        <BottomButtonRow>
          {id && (document.deleted
            ? (
              <Button variant='warning' onClick={handleClickedRestore}>
                {T('document.restore')}
              </Button>
            ) : (
              <Button variant='danger' onClick={handleClickedRemove}>
                {T('generic.action.remove')}
              </Button>
            )
          )}
          <Button
            variant='secondary'
            disabled={!contentHasChanged}
            onClick={handleClickedCancel}
          >
            {T('generic.action.cancel')}
          </Button>
          <Button
            variant='primary'
            disabled={!contentHasChanged}
            onClick={handleClickedSave}
          >
            {T('document.save')}
          </Button>
        </BottomButtonRow>
      )}
    </Page>
  );
}

interface AddPageControlProps {
  documentId: string;
  documentElement: number;
  onPageUploaded: (document: IDocument|'NOK') => void;
}
const AddPageControl = (props: AddPageControlProps) => {
  const { documentId, documentElement, onPageUploaded } = props;
  return (
    <div className={styles.addPage}>
      <PageFileUploader
        documentId={documentId}
        documentElement={documentElement}
        buttonStyle={{ width: 150, height: 200, borderRadius: 0 }}
        onSaved={onPageUploaded}
        prompt={T('document.addPage')}
      />
    </div>
  );
};

interface AddSubdocumentControlProps {
  onClickedAddSubdocument: () => void;
}
const AddSubdocumentControl = (props: AddSubdocumentControlProps) => {
  const { onClickedAddSubdocument } = props;
  return (
    <div className={styles.addSubdocument} onClick={onClickedAddSubdocument}>
      <div className={classes(styles.addSubdocumentInner)}>
        <i className={Icon.PlusSquare} />
        <span>{T('document.addSubdocument')}</span>
      </div>
    </div>
  );
}
