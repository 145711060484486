import React from 'react';
import { FormCard } from '../../../components/FormCard';
import { Icon } from '../../../components/Icons';
import { T } from '../../../Translate';
import { IPersonStory2 } from "../../../models/PersonStory2";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import { TextInputColumn } from "../../../components/inputs/TextInputColumn";
import { TextView } from "../../ViewPerson/TextView";
import { EventLinksView } from "./EventLinksView";
import { IPerson } from "../../../models/Person";
import {
  areasFromPersonWithEventId,
  EventLinksDataWithSelected,
  eventLinksFromPersonEvents,
  infoFromPlace
} from "./EventLinks";
import { StringsListView } from "./StringsListView";
import { IPersonPlaceErrors } from "./PersonPlaceData";
import { CountryInputGroup } from '../../../components/inputs/CountryInput';

export interface PlaceFormData {
  community: string
  area: string
  countryCode: string
  eventRef?: string
}

export interface PlaceFormProps {
  personStory?: IPersonStory2
  person?: IPerson
  onUpdate: (data: PlaceFormData) => void
  readOnly: boolean;
  errors: IPersonPlaceErrors
}


export const PlaceForm = (props: PlaceFormProps) => {
  const {
    personStory,
    person,
    onUpdate,
    readOnly,
    errors
  } = props;

  if (personStory === undefined)
    return <></>;

  const { community, area, country_code } = personStory as IPersonStory2;

  /**
   * Community TextInput changed event handler
   * @param newName
   */
  function communityUpdate(newName:string) {
    onUpdate({community: newName, area: personStory?.area ?? '', countryCode: personStory?.country_code ?? ''})
  }

  /**
   * Area TextInput changed event handler
   * @param newName
   */
  function areaUpdate(newName:string) {
    onUpdate({community: personStory?.community ?? '', area: newName, countryCode: personStory?.country_code ?? ''})
  }

  /**
   * Country Code TextInput changed event handler
   * @param newName
   */
  function countryCodeUpdate(newName:string) {
    onUpdate({community: personStory?.community ?? '', area: personStory?.area ?? '', countryCode: newName})
  }

  /**
   * EventLinksView event handler
   * @param index - the index of the event link clicked
   */
  const handleEventClick = (index: number) => {
    const data = eventLinksFromPersonEvents(person);
    if (index >= data.length) {
      // in this case the user pressed the 'none of these' option
      props.onUpdate({
        community: '',
        area: '',
        countryCode: '',
        eventRef: undefined
      })
    } else {

      const selectedElement = data[index]
      const selectedEvent = selectedElement.event

      if (selectedEvent === undefined) {
        props.onUpdate({
          community: '',
          area: '',
          countryCode: '',
          eventRef: undefined
        })
      } else {
        props.onUpdate(infoFromPlace(selectedEvent, 'nl'))
      }
    }
  }

  const handleAreaLinkClick = (index: number) => {
    const areas = generateAreaLinks();
    const area = areas[index];
    props.onUpdate({
      community: personStory.community,
      area: area,
      countryCode: personStory.country_code,
      eventRef: personStory.place_source_event_ref
    })

  }

  /**
   * Helper method to generate the EventLinksDataWithSelected[] requested by the EventLinksView component
   * @param person
   */
  const generateEventLinksData = (person?: IPerson): EventLinksDataWithSelected[] => {
    if (person === undefined) {
      return []
    }

    // add the selected property to the eventlinksdata
    let data = eventLinksFromPersonEvents(person)
    let newData: EventLinksDataWithSelected[] = data.map((el, index) => {
      return {...el, selected: props.person ? el.eventId === props.personStory?.place_source_event_ref : false}
    });

    // add the 'none of these' option to the event links
    /*newData.push({
      text: T('personstories.noneOfThese'),
      eventId: undefined,
      event: undefined,
      selected: props.personStory?.place_source_event_ref === undefined
    });*/

    return newData
  }

  /**
   * Get the list of area names to be shown underneath the area input field.
   */
  const generateAreaLinks = (): string[] => {
    if (person === undefined) {
      return []
    }
    if (personStory.place_source_event_ref === undefined) {
      return []
    }
    return areasFromPersonWithEventId(person, personStory.place_source_event_ref)
  }

  /**
   * Render function for the place identity section
   */
  function renderIdentityForm() {
    return (
      <FormCard icon={Icon.Home} title={T('personstories.formcard.title.place')}>

      <Container>
        <Row>
          <Col>
            <Form>
              <div className="form-row align-items-center">
                <div className="col-6">
                  <TextInputColumn
                    name='community'
                    value={community}
                    label={T('personstories.community')}
                    placeholder={T('personstories.community')}
                    onChange={communityUpdate}
                    readOnly={readOnly}
                    error={errors.communityError}
                  />
                </div>
              </div>

              <div className="form-row align-items-center">
                <div className="col-6">
                  <TextInputColumn
                    name='area'
                    value={area}
                    label={T('personstories.area')}
                    placeholder={T('personstories.area')}
                    onChange={areaUpdate}
                    readOnly={readOnly}
                    error={errors.areaError}
                  />
                </div>
              </div>
              <Row className="form-row align-items-center" style={{marginTop:-24}}>
                <Col sm={10}>
                  {!readOnly && <StringsListView data={generateAreaLinks()} onClick={handleAreaLinkClick}/>}
                </Col>
              </Row>
              <Row className="form-row align-items-center">
                <div className="col-6">
                  <CountryInputGroup
                    id='countryCode'
                    value={country_code}
                    label={T('personstories.countryCode')}
                    placeholder={T('personstories.countryCode')}
                    onChange={countryCodeUpdate}
                    readOnly={readOnly}
                    error={errors.countryCodeError}
                  />
                </div>
              </Row>

            </Form>
          </Col>
          {!readOnly && (<Col>
              <Row style={{marginBottom: 10}}>
                  <TextView text={T("personstories.chooseEvent")}/>
                  {errors.placeEventError && (
                    <Alert variant='danger'>{T('page.personstory.eventIsRequired')}</Alert>
                  )}
              </Row>
              <EventLinksView
                data={generateEventLinksData(props.person)}
                onClick={handleEventClick}
              />
          </Col>)}
        </Row>
      </Container>
      </FormCard>
    )
  }

  return renderIdentityForm();
};
