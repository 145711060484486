import { IDocumentCollection } from './Collection';
import { IDocumentReference, IDocumentRefReference } from './DocumentReference';
import { IDocumentTag } from './DocumentTag';
import { T, TranslationKey } from '../Translate';

export enum DocumentStatus {
  Todo = 'todo',
  InProgress = 'in_progress',
  Done = 'done'
}

export enum DocumentCategory {
  Archive = 'ARCHIVE',
  Web = 'WEB',
  Wise = 'WISE',
  Digital = 'DIBORN',
  Adlib = 'ADLIBD',
  AdlibCollectionDocument = 'ADLIBC'
}

export enum DocumentKind {
  Original = 'original',
  Copy = 'copy',
  Digital = 'digital',
  Reproduction = 'reproduction'
}

export function getDocumentCategoryName(category: DocumentCategory) {
  return T(('document.category.' + category.toLowerCase()) as TranslationKey);
}

export interface IBaseDocumentFields {
  category: DocumentCategory;
  kind: DocumentKind;
  parentId?: string;
  status: DocumentStatus;
  disclosed: boolean;

  title: string|null;
  description: string|null;

  // AdlibCollectionDocument
  filename?: string;
  collection_id?: string;

  // Adlib
  adlibId?: string;
  adlibCode?: string;

  // Archive
  institution?: string;
  inventoryId?: string;

  // Diborn
  source?: string;
  counter?: number

  // Web
  url?: string;

  // Wise
  wiseProfile?: string;
  wiseBranch?: string;
  wiseLocation?: string;
  wiseId?: string;
  pages?: string;
}

/**
 * Adds reference fields
 */
export interface IDocumentFields extends IBaseDocumentFields {
  tags: IDocumentTag[];
  references: IDocumentReference[];
}

export interface IDocument extends IDocumentFields {
  _id: string;
  created?: number;
  modified?: number;
  elements: IDocumentElement[];
  referenced_entities: IDocumentRefReference[];

  collection?: IDocumentCollection;
  parent?: IDocument;
  deleted?: boolean;
}


export interface IDocumentPage {
  type: 'page';
  tags: IDocumentTag[];
  references: IDocumentReference[];
  transcription: string;
  url_original: string;
  url_resized: string|null;
  original_filename?: string;
  mimetype: string;
}


export interface IDocumentSubdocument {
  type: 'subdocument';
  documentId: string;
  document?: IDocument;
}

export type IDocumentElement = IDocumentPage|IDocumentSubdocument;
