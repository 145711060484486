import React from 'react';
import { ModalProps, useModal } from './BaseModal';
import { T } from '../Translate';
import { Button, Modal } from 'react-bootstrap';
import { ButtonRowModalFooter } from '../components/ButtonRow';
import { ModalHeader } from './ModalHeader';

interface NotificationModalProps extends ModalProps<void> {
  title: string;
  message: string;
  okText?: string;
}

export const NotificationModal = (props: NotificationModalProps) => {
  const { title, message, okText } = props;
  const [isOpen, resolve] = useModal(props);
  const handleClose = () => resolve();

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <ModalHeader>{title}</ModalHeader>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <ButtonRowModalFooter>
        <Button variant='primary' onClick={handleClose}>
          {okText || T('generic.ok')}
        </Button>
      </ButtonRowModalFooter>
    </Modal>
  );
}
