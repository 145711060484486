import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { PageProps, Page } from '../Page';
import { T } from '../../Translate';
import {
  IContactPerson,
  ContactPersonEvent,
  IContactPersonFields,
  ContactMomentTypes,
  translateContactMomentType,
  ContactMomentType
} from '../../models/ContactPerson';
import { Icon } from '../../components/Icons';
import api, {ActionType} from '../../api';
import { FormCard } from '../../components/FormCard';
import { Row, Col, Button } from 'react-bootstrap';
import { TextInput } from '../../components/inputs/TextInput';
import { DualTextInput } from '../../components/inputs/DualTextInput';
import { PlaceInput } from '../../components/inputs/PlaceInput';
import { IPlace } from '../../models/Place';
import { TextareaInput } from '../../components/inputs/TextareaInput';
import {IValidationResult, getErrorDescription, isValidationResult} from '../../models/ValidationResult';
import { emptyDateTime, currentDateTime } from '../../components/inputs/DateTimeInput';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ActiveContactMoment } from '../../models/ActiveContactMoment';
import { startContactMomentAction, AnyAppAction } from '../../redux/Actions';
import { ContactPersonEventView } from './ContactPersonEventView';
import { BottomButtonRow } from '../../components/ButtonRow';
import { ConfirmationModal } from '../../modals/Confirmation';
import { NotificationManager } from 'react-notifications';
import { AppState } from '../../redux/State';
import { ViewContactPersonModal } from './ViewContactPersonModal';
import { useContactPerson, useContactPersonEvents } from '../../FunctionalAPI';
import { useDelayedEffect } from '../../utils/DelayedEffect';
import { useRefresh } from '../../utils/Functional';
import { PageID } from '../../utils/PageLinking';


interface EditContactPersonProps extends PageProps {
  id?: string;
}

export default (props: EditContactPersonProps) => {
  const { id, history, modals, loading, via } = props;

  const activeContactMoment = useSelector<AppState, ActiveContactMoment|undefined>
    (state => state.activeContactMoment.current);
  const dispatch = useDispatch<Dispatch<AnyAppAction>>();
  const start = (contactMoment: ActiveContactMoment) =>
    dispatch(startContactMomentAction(contactMoment));

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState<string|undefined>();
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState<string|undefined>();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string|undefined>();
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState<string|undefined>();
  const [street, setStreet] = useState('');
  const [streetError, setStreetError] = useState<string|undefined>();
  const [streetNumber, setStreetNumber] = useState('');
  const [streetNumberError, setStreetNumberError] = useState<string|undefined>();
  const [place, setPlace] = useState<IPlace|undefined>();
  const [placeError, setPlaceError] = useState<string|undefined>();
  const [notes, setNotes] = useState('');
  const [notesError, setNotesError] = useState<string|undefined>();

  const [suggestions, setSuggestions] = useState<IContactPerson[]>([]);
  const [selectedContactMomentType, setSelectedContactMomentType] = useState<ContactMomentType>(ContactMomentType.Email);

  const contactPerson = useContactPerson(loading, id);
  const [events,,setEvents] = useContactPersonEvents(loading, id);

  const contactMomentTypeOptions = useMemo(() => ContactMomentTypes.map(type => (
    <option key={type} value={type}>
      {translateContactMomentType(type)}
    </option>
  )), []);

  const eventViews = useMemo(
    () => {
      const handleClickedDeleteEvent = async (event: ContactPersonEvent) => {
        const confirmed = await modals.show(props => (
          <ConfirmationModal
            title={T('page.contactPerson.removeEvent.title')}
            message={T('page.contactPerson.removeEvent.message')}
            acceptText={T('generic.action.remove')}
            acceptStyle='danger'
            rejectText={T('generic.action.cancel')}
            {...props}
          />
        ));

        if (!confirmed)
          return;

        try {
          await api.deleteContactPersonEvent(event._id);
          setEvents(events.filter(e => e._id !== event._id));
          NotificationManager.success(T('page.contactPerson.removeEvent.success'));
        } catch {
          NotificationManager.error(T('page.contactPerson.removeEvent.failure'));
        }
      };

      return events.map(event => (
        <ContactPersonEventView
          key={event._id}
          event={event}
          onClickedDelete={() => handleClickedDeleteEvent(event)}
          readonly={false}
        />
      ));
    },
    [events, modals, setEvents]
  );

  const clearErrors = useRefresh(() => {
    setFirstNameError(undefined);
    setLastNameError(undefined);
    setEmailError(undefined);
    setPhoneError(undefined);
    setStreetError(undefined);
    setStreetNumberError(undefined);
    setPlaceError(undefined);
    setNotesError(undefined);
  });

  const clearForm = useRefresh(() => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setStreet('');
    setStreetNumber('');
    setPlace(undefined);
    setNotes('');
    setSuggestions([]);
    setEvents([]);
    clearErrors();
  });

  useEffect(() => {
    if (contactPerson) {
      setFirstName(contactPerson.firstName);
      setLastName(contactPerson.lastName);
      setEmail(contactPerson.email);
      setPhone(contactPerson.phone);
      setStreet(contactPerson.street);
      setStreetNumber(contactPerson.streetNumber);
      setPlace(contactPerson.place);
      setNotes(contactPerson.notes);
      clearErrors();
    } else{
      clearForm();
    }
  }, [contactPerson, clearErrors, clearForm]);

  useDelayedEffect(() => {
    if (id !== undefined)
      return;

    if (firstName === '' || lastName === '') {
      setSuggestions([]);
      return;
    }

    api.findContactPersonsByName(firstName, lastName)
      .then(setSuggestions);
  }, [firstName, lastName], 500);

  const title = contactPerson ? T(
    'page.contactPerson.title.edit',
    { name: contactPerson.firstName + ' ' + contactPerson.lastName }
  ) : T('page.contactPerson.title.create');

  const handleFirstNameChanged = (value: string) => {
    setFirstName(value);
    setFirstNameError(undefined);
  };

  const handleLastNameChanged = (value: string) => {
    setLastName(value);
    setLastNameError(undefined);
  };

  const handleEmailChanged = (value: string) => {
    setEmail(value);
    setEmailError(undefined);
  };

  const handlePhoneChanged = (value: string) => {
    setPhone(value);
    setPhoneError(undefined);
  };

  const handleStreetChanged = (value: string) => {
    setStreet(value);
    setStreetError(undefined);
  };

  const handleStreetNumberChanged = (value: string) => {
    setStreetNumber(value);
    setStreetNumberError(undefined);
  };

  const handlePlaceChanged = (value: IPlace|undefined) => {
    setPlace(value);
    setPlaceError(undefined);
  };

  const handleNotesChanged = (value: string) => {
    setNotes(value);
    setNotesError(undefined);
  };

  const copyFields = (): IContactPersonFields => {
    return {
      firstName,
      lastName,
      email,
      phone,
      street,
      streetNumber,
      placeId: place ? place._id : '',
      notes
    };
  };

  const copyFieldsForValidation = (): { id?: string } & Partial<IContactPersonFields> => {
    return Object.assign({ _id: id }, copyFields())
  };

  const copyFieldsForUpdate = (): { _id: string } & Partial<IContactPersonFields> => {
    if (id) {
      return Object.assign({_id: id }, copyFields())
    } else {
      throw new Error('Invalid _id for updating contact person');
    }
  }

  const handleValidationErrors = (result: IValidationResult) => {
    if (result.status === 'OK')
      return false;

    const errors = result.data || {};
    setFirstNameError(getErrorDescription(errors.firstName));
    setLastNameError(getErrorDescription(errors.lastName));
    setEmailError(getErrorDescription(errors.email));
    setPhoneError(getErrorDescription(errors.phone));
    setStreetError(getErrorDescription(errors.street));
    setStreetNumberError(getErrorDescription(errors.streetNumber));
    setPlaceError(getErrorDescription(errors.placeId));
    setNotesError(getErrorDescription(errors.notes));
    return true;
  }

  const save = async (): Promise<IContactPerson|undefined> => {
    const fields: IContactPersonFields = copyFields()

    let action: ActionType = id !== undefined ? 'update' : 'create';
    const fieldsForValidation = copyFieldsForValidation();

    try {
      const result = await api.validateContactPerson(fieldsForValidation, action)
      if (result === undefined)
        return undefined;
      if (result.status !== 'OK')
        throw result;

      let person: IContactPerson;
      if (action === 'update') {
        const fieldsForUpdate = copyFieldsForUpdate();
        person = await api.updateContactPerson(fieldsForUpdate)
      } else {
        person = await api.createContactPerson(fields)
      }
      if (person === undefined) {
        NotificationManager.error(T('page.contactPerson.save.failure'));
      }
      return person;
    }
    catch (result) {
      if (isValidationResult(result)) {
        handleValidationErrors(result);
        return undefined;
      } else {
        NotificationManager.error(T('page.contactPerson.save.failure'));
      }
    }
  }

  const handleClickedStartContactMoment = () => {
    save().then(person => {
      if (person === undefined)
        return;

      start({
        person,
        startsAt: currentDateTime(),
        endsAt: emptyDateTime(),
        transcript: '',
        type: selectedContactMomentType,
        contributions: []
      });
      history.push('/record_contact_moment');
    });
  }

  const handleClickedSave = async () => {
    const person = await save();
    if (person === undefined)
      return;

    NotificationManager.success(T('page.contactPerson.save.success'));

    if (id === undefined)
      history.push(`/contact_persons/${person._id}`);
  };

  const handleClickedSaveAndNew = async () => {
    const person = await save();
    if (person === undefined)
      return;

    NotificationManager.success(T('page.contactPerson.save.success'));

    clearForm();
    history.push(`/contact_persons/create`);
  }

  const handleSelectedContactMomentTypeChanged = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    setSelectedContactMomentType(value as ContactMomentType);
  };

  const handleClickedSuggestion = useCallback(async (person: IContactPerson) => {
    const selected = await modals.show<boolean>(props => (
      <ViewContactPersonModal contactPerson={person} {...props} />
    ));
    if (!selected)
      return;

    history.replace('/contact_persons/' + person._id);
  }, [modals, history]);

  const saveButtons = (
    <BottomButtonRow>
      <Button variant='primary' onClick={handleClickedSave}>
        {T('generic.action.save')}
      </Button>
      <Button variant='primary' onClick={handleClickedSaveAndNew}>
        {T('generic.action.saveAndNew')}
      </Button>
    </BottomButtonRow>
  );

  return (
    <Page
      id={id ? PageID.EditContactPerson : PageID.CreateContactPerson}
      entity={id}
      icon={Icon.AddressBook}
      title={title}
      via={via || PageID.ListContactPersons}
    >
      <Row style={{ marginTop: '1em', marginBottom: '3.5em' }}>
        <Col md={6}>
          <FormCard icon={Icon.Info} title={T('page.contactPerson.info')}>
            <TextInput
              label={T('person.firstName')}
              name='first-name'
              value={firstName}
              error={firstNameError}
              onChange={handleFirstNameChanged}
            />
            <TextInput
              label={T('person.lastName')}
              name='last-name'
              value={lastName}
              error={lastNameError}
              onChange={handleLastNameChanged}
            />
            {suggestions.length > 0 && (
              <Suggestions
                suggestions={suggestions}
                label={T('contactPerson.knownSuggestions')}
                onClicked={handleClickedSuggestion}
              />
            )}
            <TextInput
              label={T('contactPerson.email')}
              name='email'
              value={email}
              error={emailError}
              onChange={handleEmailChanged}
            />
            <TextInput
              label={T('contactPerson.phone')}
              name='phone'
              value={phone}
              error={phoneError}
              onChange={handlePhoneChanged}
            />
            <DualTextInput
              label={T('contactPerson.streetNr')}
              name='street'
              firstValue={street}
              secondValue={streetNumber}
              error={streetError || streetNumberError}
              onChangeFirst={handleStreetChanged}
              onChangeSecond={handleStreetNumberChanged}
            />
            <PlaceInput
              label={T('contactPerson.place')}
              name='place'
              value={place}
              onChange={handlePlaceChanged}
              error={placeError}
              target={'window'}
            />
            <TextareaInput
              label={T('contactPerson.notes')}
              name='notes'
              value={notes}
              onChange={handleNotesChanged}
              error={notesError}
            />
          </FormCard>
        </Col>
        <Col md={6}>
          <FormCard icon={Icon.Comments} title={T('page.contactPerson.contactMoments')}>
            {events.length === 0 && <p>{T('page.contactPerson.contactMoments.none')}</p>}
          </FormCard>
          {eventViews}
          {activeContactMoment ? (
            <p style={{ fontSize: 14, color: '#888' }}>
              <i className={Icon.Info} /> {T('page.contactPerson.alreadyHaveActiveContactMoment')}
            </p>
          ) : <>
            <div className="form-group row">
              <div className='col-sm-4'>
                <select
                  className='form-control'
                  name='contact-moment-type'
                  value={selectedContactMomentType}
                  onChange={handleSelectedContactMomentTypeChanged}
                >
                  {contactMomentTypeOptions}
                </select>
              </div>
              <div className='col-sm-4' >
              <Button variant='link' onClick={handleClickedStartContactMoment}>
                {T('page.contactPerson.startContactMoment')}
              </Button>
              </div>
            </div>
            <p style={{ fontSize: 14, color: '#888' }}>
              <i className={Icon.Info} /> {T('page.contactPerson.willSaveOnStartingContact')}
            </p>
            <p style={{ height: 44}}>

            </p>
          </>}
        </Col>
      </Row>
      {saveButtons}
    </Page>
  );
}

interface ISuggestionsProps {
  label: string;
  suggestions: IContactPerson[];
  onClicked: (contactPerson: IContactPerson) => void;
}
const Suggestions = (props: ISuggestionsProps) => {
  const { label, suggestions, onClicked } = props;
  const suggestionElements = suggestions.map(suggestion => (
    <div key={suggestion._id}>
      <Button onClick={() => onClicked(suggestion)} variant='link'>
        {suggestion.firstName} {suggestion.lastName} {suggestion.email && `(${suggestion.email})`}
      </Button>
    </div>
  ));

  return (
    <div className='row form-group'>
      <label className='col-sm-4 control-label'>{label}:</label>
      <div className='col-sm-8' style={{ marginTop: '0.5em' }}>
        {suggestionElements}
      </div>
    </div>
  );
}
