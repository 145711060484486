import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { plural, T } from '../../Translate';
import api from '../../api';
import { IQueryResult } from '../../models/Generic';
import { ListPage, ListState } from '../ListPage';
import { PageProps } from '../Page';
import { usePageState } from '../../redux/Actions';
import { useModalContext } from '../../modals/ModalContext';
import { IPersonStory2 } from "../../models/PersonStory2";
import { deletePersonStory } from "./actions";
import { Icon } from "../../components/Icons";
import { PageID } from "../../utils/PageLinking";
import { getPersonStoryColumns } from './Columns';

export type ListPersonStoriesStateProps = ListState;

function getSortColumn(column: string) {
  return column;
}

const rowKey = (personStory: IPersonStory2) => personStory._id;
const PageSize = 10;

export default function ListPersonStories(props: PageProps) {
  const { history, loading, via } = props;

  const modals = useModalContext();
  const user = api.user;

  const [pageState, updatePageState] = usePageState('personStories');
  const { filter, tablePage, sortColumn, sortDirection, filters } = pageState;
  const [items, setItems] = useState<IQueryResult<IPersonStory2>>();

  const refresh = useCallback(() => {
    const document = {} as any;
    for (const filter of filters) {
      if (filter.value) {
        document[filter.column] = filter.value;
      }
    }
    console.log('document', document);
    loading.loading(api.findPersonStories2(
      document,
      tablePage * PageSize,
      PageSize,
      [[getSortColumn(sortColumn), sortDirection], ['calculated_full_name', sortDirection]],
      filter,
      ['linked_stories']
    ), plural('personStory'))
      .then( (items) => {
        setItems(items);
      });
  }, [loading, filter, tablePage, sortColumn, sortDirection, filters]);
  useEffect(refresh, [refresh]);

  const columns = useMemo(() => {
    const handleClickedDelete = (personStory: IPersonStory2) => {
      deletePersonStory(personStory, modals, () => {refresh();})
    };

    return getPersonStoryColumns(user.canEdit(), handleClickedDelete);
  }, [user, modals, refresh]);

  const handleClickedRow = (personStory: IPersonStory2) => {
    const via = PageID.ListPersonStories
    history.push(`/personstories/${personStory._id}/edit?via=${via}`);
  };

  return (
    <ListPage
      id={PageID.ListPersonStories}
      via={via}
      icon={Icon.PersonStory}
      title={T('personstories.title')}
      noun='personStory'
      columns={columns}
      items={items}
      state={pageState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedRow={handleClickedRow}
    />
  );
}
