import React, { useMemo, useEffect, useState } from 'react';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';
import { Icon } from '../../components/Icons';
import { T, plural } from '../../Translate';
import {
  ITableColumn,
  StringTableColumn,
  ActionsTableColumn,
  TableRowActionStyle,
  ComponentTableColumn,
  ITableRowAction
} from '../../components/table/Fields';
import { Person, VictimType, PersonQueryOptions } from '../../models/Person';
import { distinct } from '../../utils/Arrays';
import { ConfirmationModal } from '../../modals/Confirmation';
import api from '../../api';
import { IQueryResult } from '../../models/Generic';
import { Dataset, AccessibleDatasets, translateDataset } from '../../models/User';
import { MergePersonsModal, MergePersonsModalResult } from './MergePersonsModal';
import { showNotification } from '../../utils/Notification';
import { MergeIcon } from '../../components/MergeIcon';
import styles from './index.module.scss';
import { ListState, ListPage } from '../ListPage';
import { PageProps } from '../Page';
import { usePageState } from '../../redux/Actions';
import { useRefresh } from '../../utils/Functional';
import { NotificationManager } from 'react-notifications';
import { IMemorial, MemorialType } from '../../models/Memorial';
import { MilitaryEventType, IMilitaryEvent } from '../../models/PersonEvent';
import { PageID } from '../../utils/PageLinking';
import PageLink from '../../components/PageLink';

function selectDisplayedMemorials(memorials: IMemorial[]) {
  const memorial = memorials.find(memorial => memorial.type === MemorialType.Cemetery && memorial.type_cemetery_details.exists)
    || memorials.find(memorial => memorial.type === MemorialType.Plate && memorial.type_cemetery_details.exists);
  return memorial ? [memorial] : [];
}

function getMilitaryEntityName(event: IMilitaryEvent) {
  const elements: string[] = [];
  if (event.enlisted_nr)
    elements.push(event.enlisted_nr);
  if (event.enlisted_unit)
    elements.push(event.enlisted_unit);

  if (elements.length === 0) {
    return event.enlisted_regt;
  } else {
    return elements.join(' ') + ', ' + event.enlisted_regt;
  }
}

export interface PersonListState {
  selectedForMerge: Person[];
}

export function generatePersonsColumns(
  onClickedRemove?: (person: Person) => void,
  onClickedMerge?: (person: Person, state: PersonListState) => void
): ITableColumn<Person, PersonListState>[] {

  const user = api.user;
  const result: ITableColumn<Person, PersonListState>[] = [
    new ComponentTableColumn('name', T('listPersons.column.name'), person => {
      const altFirstNames = person.alternativeFirstNames.length > 0 ? ' (' + person.alternativeFirstNames.join(', ') + ')' : '';
      const altLastNames = person.alternativeLastNames.length > 0 ? ' (' + person.alternativeLastNames.join(', ') + ')' : '';
      return (
        <span>
          <strong className={styles.ellipsized}>{person.lastName}{altLastNames}</strong>
          <br />
          <span className={styles.ellipsized}>{person.firstName}{altFirstNames}</span>
        </span>
      );
    }, { sortable: true, clickable: true }, (a, b) => a.name.localeCompare(b.name)),
    new StringTableColumn('birthDate', T('listPersons.column.birthDate'), person => person.birthDateFormatted, { width: 110 }),
    new StringTableColumn('diedDate', T('listPersons.column.diedDate'), person => person.diedDateFormatted, { width: 110 }),
    new ComponentTableColumn('type', T('listPersons.column.type'), person => {
      const militaryEvents = person.militaryEvents || [];
      const army = militaryEvents.length > 0 ? militaryEvents[0].enlisted_army : undefined;
      return <>
        {person.describeType()}
        <br />
        {army && `(${army}) `}{person.inNamelist ? T('person.isNamelist') : ''}
      </>;
    }, { sortable: false, width: 140, className: styles.nowrap, clickable: true }),
    new ComponentTableColumn('details', T('listPersons.column.details'), person => {
      if (person.victimType === VictimType.Military) {
        const militaryEvents = person.militaryEvents || [];
        const activeService = militaryEvents.find(e => e.enlisted_reason === MilitaryEventType.ActiveService);
        const serviceNumbers = distinct(
          militaryEvents
            .map(ev => ev.enlisted_number)
            .filter(n => n)
        );
        const ranks = distinct(
          militaryEvents
            .map(ev => ev.enlisted_rank)
            .filter(rank => rank)
        );
        const name = activeService && getMilitaryEntityName(activeService);
        return (
          <>
            <Badge pill variant='secondary'>{serviceNumbers.join(' / ')}</Badge>
            {' '}
            {ranks.join(', ')}
            <br />
            {name && <span title={name}>{name}</span>}
          </>
        );
      } else {
        return <span />;
      }
    }, { clickable: true, sortable: false, className: styles.militaryInfoColumn }),
    new ComponentTableColumn('memorials', T('listPersons.column.memorials'), person => {
      const memorials = selectDisplayedMemorials(person.memorials || []);
      const links = memorials.map(memorial => (
        <PageLink key={memorial._id} page={PageID.ViewMemorial} param={memorial._id} className={styles.memorialTag}>
          <i className={Icon.Monument} />
          {' '}
          <span className='link'>{memorial.name}</span>
        </PageLink>
      ));
      return (
        <span>
          {links}
        </span>
      )
    }, { sortable: false })
  ];
  if (user.canEdit()) {
    result.push(
      new ActionsTableColumn('actions', T('listPersons.column.actions'), person => {
        const result: ITableRowAction<Person, PersonListState>[] = [];
        result.push({
          icon: Icon.Edit,
          style: TableRowActionStyle.Success,
          tooltip: T('generic.action.edit'),
          page: PageID.EditPerson,
          pageParam: person.id
        });
        if (onClickedMerge) {
          result.push({
            icon: state => <MergeIcon selected={state.selectedForMerge} item={person} />,
            style: TableRowActionStyle.Warning,
            tooltip: T('generic.action.merge'),
            onClick: (person, state) => onClickedMerge(person, state)
          });
        }
        if (onClickedRemove) {
          result.push({
            icon: Icon.TimesCircle,
            style: TableRowActionStyle.Danger,
            tooltip: T('generic.action.remove'),
            onClick: person => onClickedRemove(person)
          });
        }
        return result;
      }, { width: 120 })
    );
  }
  return result;
}

export function getSortColumn(column: string) {
  switch (column) {
    default:
      return 'filter';
    case 'birthDate':
      return 'sort_born_date';
    case 'diedDate':
      return 'sort_died_date';
  }
}

export interface ListPersonsStateProps extends ListState {
  dataset: Dataset;
  mergingFirst?: Person;
  mergingSecond?: Person;
}

interface ListPersonsPageProps extends PageProps {
  dataset?: Dataset
}

export const rowKey = (item: Person) => item.id;
const PageSize = 10;

export default (props: ListPersonsPageProps) => {
  const { history, loading, modals, via } = props;

  const [pageState, updatePageState] = usePageState('persons');
  const { filter, tablePage, sortColumn, sortDirection, dataset, mergingFirst, mergingSecond } = pageState;
  const datasetOptions = useMemo(
    () => AccessibleDatasets.map(dataset => (
      <option key={dataset} value={dataset}>{translateDataset(dataset)}</option>
    )),
    []
  );

  useEffect(
    () => updatePageState({ dataset: props.dataset || Dataset.Any }),
    [updatePageState, props.dataset]
  );

  const [items, setItems] = useState<IQueryResult<Person>>();
  const refresh = useRefresh(() => {
    loading.loading(
      api.findPersons(
        {},
        filter,
        tablePage * PageSize,
        PageSize,
        [[getSortColumn(sortColumn), sortDirection]],
        [PersonQueryOptions.Memorials, PersonQueryOptions.MilitaryEvents],
        dataset
      ), plural('person'))
    .then(results => setItems({
      total: results.total,
      data: results.data.map(x => new Person(x))
    }));
  });
  useEffect(refresh, [filter, tablePage, sortColumn, sortDirection, dataset]);

  const handleDatasetChanged = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    updatePageState({ dataset: e.currentTarget.value as Dataset });
  };

  const handleClickedAdd = () => {
    history.push('/persons/create');
  };

  const handleClickedRow = (person: Person) => {
    history.push(`/persons/${person.id}`);
  };

  const handleClickedCancelMerge = () => {
    updatePageState({
      mergingFirst: undefined,
      mergingSecond: undefined,
    });
  }

  const columns = useMemo(
    () => {
      const handleClickedRemove = async (person: Person) => {
        const confirmed = await modals.show<boolean>(props => (
          <ConfirmationModal
            title={T('modal.removePerson.title')}
            message={T('modal.removePerson.message', { name: person.name })}
            warning={T('generic.cannotUndo')}
            acceptText={T('generic.action.remove')}
            acceptStyle='danger'
            rejectText={T('generic.action.cancel')}
            {...props}
          />
        ));
        if (!confirmed)
          return;

        await api.removePerson(person.id)
        NotificationManager.success(T('modal.removePerson.removed', { name: person.name }));
        refresh();
      };

      const handleClickedMerge = async (person: Person, state: PersonListState) => {
        const mergingFirst = state.selectedForMerge[0];
        if (!mergingFirst) {
          updatePageState({ mergingFirst: person });
          return;
        }
        if (mergingFirst.id === person.id) {
          updatePageState({ mergingFirst: undefined });
          return;
        }

        updatePageState({ mergingSecond: person });
        const result = await modals.show<MergePersonsModalResult>(props => (
          <MergePersonsModal
            personA={mergingFirst}
            personB={person}
            {...props}
          />
        ));
        if (!result.confirmed) {
          updatePageState({
            mergingSecond: undefined
          });
          return;
        }

        updatePageState({
          mergingFirst: undefined,
          mergingSecond: undefined
        });

        await api.mergePersons(result.personA.id, result.personB.id);
        showNotification({
          message: T('modal.mergePerson.merged', { a: mergingFirst.name, b: person.name })
        });
        if (result.openResult) {
          history.push(`/persons/${result.personA.id}`);
        } else {
          refresh();
        }
      };

      return generatePersonsColumns(handleClickedRemove, handleClickedMerge);
    },
    [history, modals, refresh, updatePageState]
  );

  const tableState = useMemo(() => {
    const merging: Person[] = [];
    if (mergingFirst)
      merging.push(mergingFirst);
    if (mergingSecond)
      merging.push(mergingSecond);
    return { selectedForMerge: merging };
  }, [mergingFirst, mergingSecond]);

  const additionalHeader = <>
    <select
      className='form-control'
      value={dataset}
      onChange={handleDatasetChanged}
      style={{ width: 200 }}
    >
      {datasetOptions}
    </select>
    {mergingFirst && (
      <ButtonGroup style={{ marginLeft: 10, marginRight: 10 }}>
        <Button>
          <i className={Icon.Merge} />
          {' '}
          {mergingFirst.name}
        </Button>
        <Button onClick={handleClickedCancelMerge}>
          <i className={Icon.Times} />
        </Button>
      </ButtonGroup>
    )}
  </>;

  return (
    <ListPage
      id={PageID.ListPersons}
      icon={Icon.Users}
      title={T('listPersons.title')}
      via={via}
      noun='person'
      columns={columns}
      items={items}
      state={pageState}
      tableState={tableState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedRow={handleClickedRow}
      onClickedAdd={handleClickedAdd}
      additionalHeader={additionalHeader}
    />
  );
}
