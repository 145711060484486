import React, { useMemo } from 'react';
import { T } from '../../Translate';
import { Form } from 'react-bootstrap';
import { DocumentTag, DocumentTagFieldValue } from '../../models/DocumentTag';
import { FormCard } from "../../components/FormCard";
import { Icon } from "../../components/Icons";
import { DocumentTagTypeInput } from "../../components/inputs/DocumentTagTypeInput";
import { Document, DocumentCategory } from "../../models/Document";
import { DocumentTagDefinition } from "../../models/DocumentTagDefinition";
import styles from './index.module.scss';
import { TagView } from "./TagView";


interface TagsListViewProps {
  document: Document;
  tags: DocumentTag[];
  handleAddTag: (tag: DocumentTagDefinition|undefined) => void
  handleRemoveTag: (tag: DocumentTag) => void
  handleFieldValueUpdated: (tag: DocumentTag, field: string, value: DocumentTagFieldValue) => void
  readOnly: boolean;
}
export const TagsListView = (props: TagsListViewProps) => {
  const { document, tags, handleAddTag, handleRemoveTag, handleFieldValueUpdated, readOnly } = props;
  
  const documentCategory = document.category;
  const hasTypes = documentCategory === DocumentCategory.AdlibCollectionDocument || documentCategory === DocumentCategory.Digital;

  const tagViews = useMemo(() => {

    return tags.map((tag, index) => (
      <TagView
        key={index}
        tag={tag}
        removeTag={() => handleRemoveTag(tag)}
        readOnly={readOnly}
        onChangeField={(field, value) => handleFieldValueUpdated(tag, field, value)}
      />
    ));
  }, [tags, readOnly]);


  return (
    <FormCard
      title={T('document.tags')}
      icon={Icon.Tags}
      style={{ marginTop: '1em'}}>
      <Form>
        {!readOnly && hasTypes && <>
          <h5 className={styles.tagtypeselection}>{T('document.addTags').toUpperCase()}</h5>
          <div style={{ marginBottom: '1em' }}>
            <DocumentTagTypeInput
              onSelected={handleAddTag}
              placeholder={T('document.tags.select')}
              disabledTags={tags.map(tag => tag.tag.id)}
            />
          </div>
        </>}
        {tagViews}
      </Form>
    </FormCard>
  );
};


