import React, { useMemo, useState, useEffect } from 'react';
import { History } from 'history';
import { ModalProps, useModal } from '../../modals/BaseModal';
import { IQueryResult } from '../../models/Generic';
import { PersonEventType, PersonEventLoadOption, getEventTypePlural, MilitaryEventType, describeMilitaryEventUnit } from '../../models/PersonEvent';
import { Modal, Badge } from 'react-bootstrap';
import { Table, SortDirection } from '../../components/table/Table';
import api from '../../api';
import { ITableColumn, StringTableColumn, StringFieldTableColumn, ComponentTableColumn } from '../../components/table/Fields';
import { T } from '../../Translate';
import { IPlace, Place } from '../../models/Place';
import { ModalHeader } from '../../modals/ModalHeader';
import { PersonQueryOptions, Person, translateVictimType } from '../../models/Person';
import styles from './index.module.scss';
import { PageID } from '../../utils/PageLinking';
import PageLink from '../../components/PageLink';

interface EventListModalProps extends ModalProps<void> {
  history: History;
  place: IPlace;
  type: PersonEventType;
  count: number;
}

const PageSize = 10;
const rowKey = (item: Person) => item.id;
const MaxEvents = 1000;

export const PlaceEventListModal = (props: EventListModalProps) => {
  const { history, type, count } = props;
  const place = new Place(props.place)
  const [isOpen, resolve] = useModal(props);
  const handleClose = () => resolve();

  const columns: ITableColumn<Person>[] = useMemo(() => [
    new StringFieldTableColumn('nameSortable', T('person.name'), { width: 250 }),
    new StringTableColumn(
      'bornDate',
      T('person.born'),
      person => person.birthDateFormatted,
      { width: 110 }
    ),
    new StringTableColumn(
      'diedDate',
      T('person.died'),
      person => person.diedDateFormatted,
      { width: 110 }
    ),
    new ComponentTableColumn(
      'details',
      T('listPersons.column.details'),
      person => {
        if ((person.militaryEvents || []).length > 0) {
          const militaryEvents = person.militaryEvents || [];
          const military = militaryEvents.find(e => e.enlisted_reason === MilitaryEventType.ActiveService)
            || militaryEvents.find(e => e.enlisted_reason === MilitaryEventType.Enlisted);
          if (military) {
            const entity = describeMilitaryEventUnit(military);
            return <>
              {military.enlisted_rank}
              {military.enlisted_number && <>{' - '}<Badge variant='danger' className={styles.personNumberBadge}>{military.enlisted_number}</Badge>{' - '}</>}
              {entity}
            </>;
          }
        }
        return translateVictimType(person.victimType);
      }
    ),
  ], []);

  const [page, setPage] = useState(0);
  const [allPersons, setAllPersons] = useState<IQueryResult<Person>>({ data: [], total: 0 });
  const [sortColumn, setSortColumn] = useState('nameSortable');
  const [sortDirection, setSortDirection] = useState(SortDirection.Up);

  useEffect(() => {
    if (count > MaxEvents)
      return;

    api.findEvents(
      { type, place_id: place.id },
      undefined,
      0,
      MaxEvents,
      [],
      [PersonEventLoadOption.Person, PersonQueryOptions.MilitaryEvents]
    ).then(result => {
      const persons: Person[] = [];
      for (const event of result.data) {
        if (event.extend_person === undefined)
          continue;

        persons.push(new Person(event.extend_person));
      }
      setAllPersons({ data: persons, total: result.total });
    });
  }, [place.id, type, count]);
  const sortedPersons = useMemo(() => {
    const result = [...allPersons.data];
    if (sortColumn === 'nameSortable') {
      result.sort((a, b) => a.nameSortable.localeCompare(b.nameSortable));
    } else if (sortColumn === 'bornDate') {
      result.sort((a, b) => (a.bornDateSortable || '????????').localeCompare(b.bornDateSortable || '????????'));
    } else if (sortColumn === 'diedDate') {
      result.sort((a, b) => (a.diedDateSortable || '????????').localeCompare(b.diedDateSortable || '????????'));
    }
    if (sortDirection === SortDirection.Down)
      result.reverse();
    return result;
  }, [allPersons, sortColumn, sortDirection]);
  const pagePersons = useMemo(() => {
    return sortedPersons.slice(PageSize * page, PageSize * page + PageSize);
  }, [sortedPersons, page]);

  const handleSortChanged = (sortColumn: string, sortDirection: SortDirection) => {
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);
  };

  const handleClickedRow = (row: Person) => {
    history.push(`/persons/${row.id}`);
  };

  return (
    <Modal size='xl' show={isOpen} onHide={handleClose}>
      <ModalHeader>
        {T('page.places.events.title', {
          event: getEventTypePlural(type),
          place: place.name
        })}
      </ModalHeader>
      <Modal.Body>
        {count > MaxEvents ? <>
          <p>{T('page.places.events.toomany')}</p>
          <p style={{ textAlign: 'center' }}>
            <PageLink page={PageID.AdvancedSearch} className='btn btn-primary'>
              {T('page.advancedSearch.title')}
            </PageLink>
          </p>
        </> : (
          <Table
            displayedItems={pagePersons}
            columns={columns}
            rowKey={rowKey}
            onPageChanged={setPage}
            onSortChanged={handleSortChanged}
            noun='person'
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            page={page}
            pageSize={PageSize}
            totalItems={allPersons.total}
            onClickItem={handleClickedRow}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
