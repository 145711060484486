import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { T, singular } from '../../Translate';
import API from '../../api';
import { DocumentCollection } from '../../models/Collection';
import { PageProps, Page } from '../Page';
import { CollectionEditor } from './CollectionEditor';
import { Icon } from '../../components/Icons';
import { useDocumentCollection } from '../../FunctionalAPI';
import { PageID } from '../../utils/PageLinking';

interface EditCollectionPageProps extends PageProps {
  id: string;
}

export default (props: EditCollectionPageProps) => {
  const { id, history, loading, via } = props;
  const [collection,,setCollection] = useDocumentCollection(loading, id);

  const handleSave = async (collection: DocumentCollection) => {
    const saved = await loading.saving(
      API.updateCollection(collection),
      singular('collection')
    );
    setCollection(saved);
  };

  const handleCancel = () => history.push('/collections');

  return (
    <Page
      id={PageID.EditCollection}
      entity={id}
      icon={Icon.Box}
      title={collection.composedTitle()}
      via={via || PageID.ListCollections}
    >
      <Row className="justify-content-md-center">
        <Col sm={8}>
          <Card style={{ marginTop: '1em' }}>
            <Card.Body>
              <Card.Title>{T('page.collection.edit.title')}</Card.Title>
              <Card.Text as="div">
                <CollectionEditor
                  collection={collection}
                  onSave={handleSave}
                  onCancel={handleCancel}
                  history={history}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
}
