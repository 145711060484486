import React from 'react';
import Autocomplete from 'react-autocomplete';
import {
  IMilitaryEntity,
  MilitaryEntity,
  MilitaryEntityType
} from '../../models/MilitaryEntity';
import api from '../../api';
import { useElementID } from '../../utils/ElementID';
import { T } from '../../Translate';
import { InvalidFeedback } from './InvalidFeedback';

interface MilitaryEntityInputProps extends MilitaryEntityInputComponentProps {
  labelColumns?: number;
  inputColumns?: number;

  label: string;
  error?: string;
}

export const MilitaryEntityInput = (props: MilitaryEntityInputProps) => {
  const {
    types,
    onSelected,
    value,

    labelColumns = 4,
    inputColumns,
    label,
    error
  } = props;
  const id = useElementID('meinput');

  return (
    <div className="form-group row">
      <label htmlFor={id} className={`col-sm-${labelColumns} control-label`}>{label}</label>
      <div className={`col-sm-${inputColumns || (12 - labelColumns)}`}>
        <MilitaryEntityInputComponent
          types={types}
          onSelected={onSelected}
          value={value}
          invalid={error !== undefined}
        />
        {error && <InvalidFeedback error={error} />}
      </div>
    </div>
  );
}

interface MilitaryEntityInputComponentProps {
  types: MilitaryEntityType[];
  value: IMilitaryEntity|undefined;
  onSelected: (value: IMilitaryEntity) => void;
  invalid?: boolean;
}
interface MilitaryEntityInputComponentState {
  options: MilitaryEntity[],
  value: string;
  modal: null | JSX.Element;
}

export class MilitaryEntityInputComponent extends React.PureComponent<MilitaryEntityInputComponentProps, MilitaryEntityInputComponentState> {
  requestTimer?: NodeJS.Timer;

  constructor(props: MilitaryEntityInputComponentProps) {
    super(props);

    this.state = {
      options: [],
      value: props.value ? new MilitaryEntity(props.value).name : '',
      modal: null
    };
  }

  componentWillReceiveProps(newProps: MilitaryEntityInputComponentProps) {
    if (newProps.types !== this.props.types)
      this.scheduleSearch(newProps.types, this.state.value);
  }

  componentDidUpdate(oldProps: MilitaryEntityInputComponentProps) {
    if (this.props.value && this.props.value !== oldProps.value)
      this.setState({ value: new MilitaryEntity(this.props.value).name });
  }

  handleValueChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value;
    // is this a valid uuid?
    let match = value.match(/.*([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/);
    if (match) {
      this.setState({ options: [] });
      api.getMilitaryEntity(match[1])
        .then(result => {
          if (match && result)
            this.handleSelected(match[1], new MilitaryEntity(result));
        });
    } else {
      this.scheduleSearch(this.props.types, value);
    }

    this.setState({ value });
  }

  handleSelected = (value: string, item: MilitaryEntity) => {
    this.props.onSelected(item.entity);

    this.setState({
      value: '',
      options: []
    });
  }

  scheduleSearch(types: MilitaryEntityType[], value: string) {
    if (this.requestTimer) {
      clearTimeout(this.requestTimer);
      this.requestTimer = undefined;
    }

    if (!value) {
      this.setState({ options: [] });
    } else {
      this.requestTimer = setTimeout(() => this.searchReferences(types), 500);
    }
  }

  searchReferences(types: MilitaryEntityType[]) {
    this.requestTimer = undefined;
    api.findMilitaryEntities({ type: { '$in': types } }, this.state.value, 0, 10)
      .then(entities => this.setState({
        options: entities.data.map(entity => new MilitaryEntity(entity))
      }));
  }

  render() {
    const { options, value } = this.state;
    const { invalid = false } = this.props;
    return <>
      <Autocomplete
        inputProps={{ className: invalid ? 'form-control is-invalid' : 'form-control' }}
        getItemValue={item => item.name}
        items={options.length === 0 ? [undefined] : options}
        menuStyle={{ padding: '0.25em', position: 'fixed', background: 'white', border: '1px solid #ccc', zIndex: 100 }}
        renderItem={(item: MilitaryEntity|undefined, isHighlighted: boolean) =>
          item === undefined ? (
            <div style={{ color: '#888', fontSize: 10 }}>{
              value.length === 0
                ? T('component.search.typeToSearch')
                : (this.requestTimer ? T('component.search.searching') : T('component.search.noResults'))
            }</div>
          ) : (
            <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white', padding: '0.25em', cursor: 'default' }}>
              {item.name}
            </div>
          )
        }
        value={value}
        onChange={this.handleValueChanged}
        onSelect={this.handleSelected}
      />
      {this.state.modal}
    </>;
  }
}
