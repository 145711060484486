import React, { ReactNode } from 'react';
import { Icon } from './Icons';
import { Card } from 'react-bootstrap';

interface CardHeaderProps {
  icon: Icon;
  title: string;
  buttons?: ReactNode;
  children?: ReactNode
}
export const CardHeader = (props: CardHeaderProps) => {
  const { icon, title, buttons, children } = props;
  return (
    <Card.Header>
      {buttons && <div style={{ float: 'right' }}>{buttons}</div>}
      <h4 className='card-title'>
        <i className={icon} />{'  '}
        {title}
      </h4>
      {children}
    </Card.Header>
  );
}
