import 'bootstrap-notify';
import { Icon } from "../components/Icons";
import { NotificationManager } from 'react-notifications';
import '../components/Notifications.scss';

interface NotificationSettings {
  icon?: Icon;
  message: string;
  timeout?: number;
  style?: 'primary'|'info'|'success'|'warning'|'danger';
}

export function showNotification(settings: NotificationSettings) {
  const { message, timeout = 3000, style = 'primary' } = settings;
  switch (style) {
    case 'primary':
    case 'success':
      NotificationManager.success(message, undefined, timeout);
      break;
    case 'info':
      NotificationManager.info(message, undefined, timeout);
      break;
    case 'warning':
      NotificationManager.warning(message, undefined, timeout);
      break;
    case 'danger':
      NotificationManager.error(message, undefined, timeout);
      break;
  }
}
