import React from 'react';
import BaseModal, { ModalProps, BaseModalState } from '../../modals/BaseModal';
import { T } from '../../Translate';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import {
  PersonEvent,
  PersonEventType,
  getEventIcon,
  getEventTypeName,
  WhereEventType,
  MilitaryEventType,
  JailedType,
  MemoratedKind,
  translateWhereEventType,
  translateMilitaryEventType,
  translateJailedEventType,
  translateMemoratedKind,
  ICWXRMEvent
} from '../../models/PersonEvent';
import { PlaceInput } from '../../components/inputs/PlaceInput';
import { TextInput } from '../../components/inputs/TextInput';
import { DateInput, DateInputValue, inputDateToSortable } from '../../components/inputs/DateInput';
import { SelectInput } from '../../components/inputs/SelectInput';
import { TextareaInput } from '../../components/inputs/TextareaInput';

import styles from './index.module.scss';
import { IPlace, isPlace } from '../../models/Place';
import { Armies } from '../../models/MilitaryEntity';
import { IMemorial, MemorialType } from '../../models/Memorial';
import { MemorialInput } from '../../components/inputs/MemorialInput';
import api from '../../api';
import { getErrorDescription } from '../../models/ValidationResult';
import { ButtonRowModalFooter } from '../../components/ButtonRow';
import { RegimentInputGroup } from './inputs/RegimentInput';
import { UnitInputGroup } from './inputs/UnitInput';

interface EditEventModalProps extends ModalProps<PersonEvent> {
  event: PersonEvent;
}
interface EditEventModalState extends BaseModalState {
  startDate: DateInputValue;
  startDateError?: string;
  endDate: DateInputValue;
  endDateError?: string;
  place: IPlace|undefined;
  placeError?: string;
  address: string;
  addressError?: string;
  comments: string;
  commentsError?: string;

  schoolName: string;
  schoolNameError?: string;
  schoolDirection: string;
  schoolDirectionError?: string;

  whereType: WhereEventType;
  whereTypeError?: string;

  workCompanyName: string;
  workCompanyNameError?: string;
  workProfession: string;
  workProfessionError?: string;

  militaryType: MilitaryEventType;
  militaryTypeError?: string;
  militaryArmy: string;
  militaryArmyError?: string;
  militaryRegiment: string;
  militaryRegimentError?: string;
  militaryUnitNr: string;
  militaryUnitNrError?: string;
  militaryUnit: string;
  militaryUnitError?: string;
  militaryUnitSub: string;
  militaryUnitSubError?: string;
  militaryRank: string;
  militaryRankError?: string;
  militaryServiceNumber: string;
  militaryServiceNumberError?: string;

  jailedType: JailedType;
  jailedTypeError?: string;

  injuredWound: string;
  injuredWoundError?: string;
  injuredCause: string;
  injuredCauseError?: string;

  diedReason: string;
  diedReasonError?: string;

  memoratedMemorial: IMemorial|undefined;
  memoratedMemorialError?: string;
  memoratedKind: MemoratedKind;
  memoratedKindError?: string;
  memoratedRef: string;
  memoratedRefError?: string;
  memoratedPlace?: IPlace;

  decoratedDecoration: string;
  decoratedDecorationError?: string;
  decoratedCitation: string;
  decoratedCitationError?: string;

  cwxrmBatch: string;
  cwxrmBatchError?: string;
  cwxrmCsvId: string;
  cwxrmCsvIdError?: string;
  cwxrmFirstName: string;
  cwxrmFirstNameError?: string;
  cwxrmLastName: string;
  cwxrmLastNameError?: string;
  cwxrmMail: string;
  cwxrmMailError?: string;
  cwxrmGroup: string;
  cwxrmGroupError?: string;
}

const ARMY_OPTIONS: JSX.Element[] = Armies.map(army => (
  <option key={army} value={army}>{army}</option>
));

export class EditEventModal extends BaseModal<PersonEvent, EditEventModalProps, EditEventModalState> {
  whereTypeOptions: JSX.Element[];
  militaryTypeOptions: JSX.Element[];
  jailedOptions: JSX.Element[];
  memoratedKindOptions: JSX.Element[];

  constructor(props: EditEventModalProps) {
    super(props, 'xl');

    this.whereTypeOptions = Object.values(WhereEventType).map(type => (
      <option key={type} value={type}>{translateWhereEventType(type)}</option>
    ));

    this.militaryTypeOptions = Object.values(MilitaryEventType).map(type => (
      <option key={type} value={type}>{translateMilitaryEventType(type)}</option>
    ));

    this.jailedOptions = Object.values(JailedType).map(type => (
      <option key={type} value={type}>{translateJailedEventType(type)}</option>
    ));

    this.memoratedKindOptions = [MemoratedKind.Original, MemoratedKind.Current].map(type => (
      <option key={type} value={type}>{translateMemoratedKind(type)}</option>
    ));

    this.state = {
      visible: true,

      startDate: { day: '', month: '', year: '' },
      endDate: { day: '', month: '', year: '' },
      place: undefined,
      address: '',
      comments: '',

      schoolName: '',
      schoolDirection: '',

      workCompanyName: '',
      workProfession: '',

      whereType: WhereEventType.Fugitive,

      militaryType: MilitaryEventType.ActiveService,
      militaryArmy: '',
      militaryRegiment: '',
      militaryUnit: '',
      militaryUnitNr: '',
      militaryUnitSub: '',
      militaryRank: '',
      militaryServiceNumber: '',

      jailedType: JailedType.Jailed,

      injuredWound: '',
      injuredCause: '',

      diedReason: '',

      memoratedMemorial: undefined,
      memoratedRef: '',
      memoratedKind: MemoratedKind.Current,

      decoratedDecoration: '',
      decoratedCitation: '',

      cwxrmBatch: '',
      cwxrmCsvId: '',
      cwxrmFirstName: '',
      cwxrmLastName: '',
      cwxrmMail: '',
      cwxrmGroup: ''
    };
  }

  componentDidMount() {
    this.load(this.props.event);
  }

  load(event: PersonEvent) {
    this.setState({
      startDate: {
        day: event.start_day === null ? '' : event.start_day.toString(),
        month: event.start_month === null ? '' : event.start_month.toString(),
        year: event.start_year === null ? '' : event.start_year.toString()
      },
      startDateError: undefined,
      endDate: {
        day: event.end_day === null ? '' : event.end_day.toString(),
        month: event.end_month === null ? '' : event.end_month.toString(),
        year: event.end_year === null ? '' : event.end_year.toString()
      },
      endDateError: undefined,
      place: isPlace(event.extend_place) ? event.extend_place : undefined,
      placeError: undefined,
      address: event.topo || '',
      addressError: undefined,
      comments: event.description,
      commentsError: undefined,

      schoolName: '',
      schoolNameError: undefined,
      schoolDirection: '',
      schoolDirectionError: undefined,

      workCompanyName: '',
      workCompanyNameError: undefined,
      workProfession: '',
      workProfessionError: undefined,

      whereType: WhereEventType.Fugitive,
      whereTypeError: undefined,

      militaryType: MilitaryEventType.ActiveService,
      militaryTypeError: undefined,
      militaryArmy: '',
      militaryArmyError: undefined,
      militaryRegiment: '',
      militaryRegimentError: undefined,
      militaryUnit: '',
      militaryUnitError: undefined,
      militaryUnitNr: '',
      militaryUnitNrError: undefined,
      militaryUnitSub: '',
      militaryUnitSubError: undefined,
      militaryRank: '',
      militaryRankError: undefined,
      militaryServiceNumber: '',
      militaryServiceNumberError: undefined,

      jailedType: JailedType.Jailed,
      jailedTypeError: undefined,

      injuredWound: '',
      injuredWoundError: undefined,
      injuredCause: '',
      injuredCauseError: undefined,

      diedReason: '',
      diedReasonError: undefined,

      memoratedMemorial: undefined,
      memoratedMemorialError: undefined,
      memoratedRef: '',
      memoratedRefError: undefined,
      memoratedKind: MemoratedKind.Current,
      memoratedKindError: undefined,

      decoratedDecoration: '',
      decoratedDecorationError: undefined,
      decoratedCitation: '',
      decoratedCitationError: undefined,

      cwxrmBatch: '',
      cwxrmBatchError: undefined,
      cwxrmCsvId: '',
      cwxrmCsvIdError: undefined,
      cwxrmFirstName: '',
      cwxrmFirstNameError: undefined,
      cwxrmLastName: '',
      cwxrmLastNameError: undefined,
      cwxrmMail: '',
      cwxrmMailError: undefined,
      cwxrmGroup: '',
      cwxrmGroupError: undefined
    });

    switch (event.type) {
      case PersonEventType.School:
        this.setState({
          schoolName: event.school_name,
          schoolDirection: event.school_direction
        });
        break;
      case PersonEventType.Where:
        this.setState({
          whereType: event.where_reason
        });
        break;
      case PersonEventType.Work:
        this.setState({
          workCompanyName: event.work_company_name,
          workProfession: event.work_profession
        });
        break;
      case PersonEventType.Military:
        this.setState({
          militaryType: event.enlisted_reason,
          militaryArmy: event.enlisted_army,
          militaryRegiment: event.enlisted_regt,
          militaryUnit: event.enlisted_unit,
          militaryUnitNr: event.enlisted_nr,
          militaryUnitSub: event.enlisted_sub,
          militaryServiceNumber: event.enlisted_number,
          militaryRank: event.enlisted_rank
        });
        break;
      case PersonEventType.Jailed:
        this.setState({
          jailedType: event.jailed_type
        });
        break;
      case PersonEventType.Injured:
        this.setState({
          injuredCause: event.injured_cause,
          injuredWound: event.injured_wound
        });
        break;
      case PersonEventType.Died:
        this.setState({
          diedReason: event.death_reason
        });
        break;
      case PersonEventType.Memorated:
        this.setState({
          memoratedMemorial: event.extend_memorial,
          memoratedRef: event.memorial_ref,
          memoratedKind: event.memorial_kind
        });
        break;
      case PersonEventType.Decorated:
        this.setState({
          decoratedDecoration: event.decoration,
          decoratedCitation: event.citation
        });
        break;
      case PersonEventType.CWXRM:
        this.setState({
          cwxrmBatch: event.batch || '',
          cwxrmCsvId: (event.csv_id || '').toString(),
          cwxrmFirstName: event.first_name || '',
          cwxrmLastName: event.last_name || '',
          cwxrmMail: event.email || '',
          cwxrmGroup: event.group || ''
        });
        api.getEvent(event._id).then(event => {
          const cwxrmEvent = event as ICWXRMEvent;
          this.setState({
            cwxrmBatch: cwxrmEvent.batch || '',
            cwxrmCsvId: (cwxrmEvent.csv_id || '').toString(),
            cwxrmFirstName: cwxrmEvent.first_name || '',
            cwxrmLastName: cwxrmEvent.last_name || '',
            cwxrmMail: cwxrmEvent.email || '',
            cwxrmGroup: cwxrmEvent.group || ''
          });
        });
        break;
    }
  }

  toEvent(): PersonEvent {
    const { event } = this.props;
    const {
      startDate,
      endDate,
      place,
      address,
      schoolName,
      schoolDirection,
      workCompanyName,
      workProfession,
      whereType,
      militaryType,
      militaryArmy,
      militaryRegiment,
      militaryUnitNr,
      militaryUnit,
      militaryUnitSub,
      militaryRank,
      militaryServiceNumber,
      jailedType,
      injuredWound,
      injuredCause,
      diedReason,
      memoratedMemorial,
      memoratedRef,
      memoratedKind,
      memoratedPlace,
      decoratedDecoration,
      decoratedCitation,
      cwxrmBatch,
      cwxrmCsvId,
      cwxrmFirstName,
      cwxrmLastName,
      cwxrmMail,
      cwxrmGroup,
      comments
    } = this.state;


    const startDay = startDate.day === '' ? null : parseInt(startDate.day);
    const startMonth = startDate.month === '' ? null : parseInt(startDate.month);
    const startYear = startDate.year === '' ? null : parseInt(startDate.year);
    const endDay = endDate.day === '' ? null : parseInt(endDate.day);
    const endMonth = endDate.month === '' ? null : parseInt(endDate.month);
    const endYear = endDate.year === '' ? null : parseInt(endDate.year);
    const sortStart = inputDateToSortable(startDate);
    const sortEnd = inputDateToSortable(endDate);

    const newEvent = Object.assign({}, event, {
      start_day: startDay,
      start_month: startMonth,
      start_year: startYear,
      sort_start_date: sortStart,
      end_day: endDay,
      end_month: endMonth,
      end_year: endYear,
      sort_end_date: sortEnd,
      place_id: place ? place._id: null,
      extend_place: place,
      topo: address,
      description: comments
    }) as PersonEvent; // TypeScript isn't that smart here
    switch (newEvent.type) {
      case PersonEventType.School:
        newEvent.school_name =  schoolName;
        newEvent.school_direction = schoolDirection;
        break;
      case PersonEventType.Where:
        newEvent.where_reason = whereType;
        break;
      case PersonEventType.Work:
        newEvent.work_company_name = workCompanyName;
        newEvent.work_profession = workProfession;
        break;
      case PersonEventType.Military:
        newEvent.enlisted_reason = militaryType;
        newEvent.enlisted_army = militaryArmy;
        newEvent.enlisted_regt = militaryRegiment;
        newEvent.enlisted_unit = militaryUnit;
        newEvent.enlisted_nr = militaryUnitNr;
        newEvent.enlisted_sub = militaryUnitSub;
        newEvent.enlisted_rank = militaryRank;
        newEvent.enlisted_number = militaryServiceNumber;
        break;
      case PersonEventType.Jailed:
        newEvent.jailed_type = jailedType;
        break;
      case PersonEventType.Injured:
        newEvent.injured_wound = injuredWound;
        newEvent.injured_cause = injuredCause;
        break;
      case PersonEventType.Hospitalized:
        break;
      case PersonEventType.Died:
        newEvent.death_reason = diedReason;
        break;
      case PersonEventType.Memorated:
        newEvent.memorial_id = memoratedMemorial ? memoratedMemorial._id : null;
        newEvent.memorial_kind = memoratedKind;
        newEvent.memorial_ref = memoratedRef;
        if (memoratedMemorial) {
          newEvent.extend_memorial = {
            ...memoratedMemorial,
            loc: memoratedMemorial.calc_place_loc,
            place_name: memoratedMemorial.calc_place_name
          }
          newEvent.extend_place = memoratedPlace
        }
        break;
      case PersonEventType.Decorated:
        newEvent.decoration = decoratedDecoration;
        newEvent.citation = decoratedCitation;
        break;
      case PersonEventType.CWXRM:
        newEvent.batch = cwxrmBatch;
        newEvent.csv_id = parseInt(cwxrmCsvId);
        newEvent.first_name = cwxrmFirstName;
        newEvent.last_name = cwxrmLastName;
        newEvent.email = cwxrmMail;
        newEvent.group = cwxrmGroup;
        break;
    }
    return newEvent;
  }

  handleStartDateChanged = (date: DateInputValue) => {
    this.setState({
      startDate: date,
      startDateError: undefined
    });
  }

  handleEndDateChanged = (date: DateInputValue) => {
    this.setState({
      endDate: date,
      endDateError: undefined
    });
  }

  handlePlaceChanged = (newPlace: IPlace|undefined) => {
    this.setState({
      place: newPlace,
      placeError: undefined
    });

  }

  handleAddressChanged = (address: string) => {
    this.setState({
      address,
      addressError: undefined
    });
  }

  handleCommentsChanged = (comments: string) => {
    this.setState({
      comments,
      commentsError: undefined
    });
  }

  handleSchoolNameChanged = (schoolName: string) => {
    this.setState({
      schoolName,
      schoolNameError: undefined
    });
  }

  handleSchoolDirectionChanged = (schoolDirection: string) => {
    this.setState({
      schoolDirection,
      schoolDirectionError: undefined
    });
  }

  handleWhereTypeChanged = (value: string) => {
    this.setState({
      whereType: value as WhereEventType,
      whereTypeError: undefined
    });
  }

  handleWorkCompanyNameChanged = (workCompanyName: string) => {
    this.setState({
      workCompanyName,
      workCompanyNameError: undefined
    });
  }

  handleWorkProfessionChanged = (workProfession: string) => {
    this.setState({
      workProfession,
      workProfessionError: undefined
    });
  }

  handleMilitaryTypeChanged = (value: string) => {
    this.setState({
      militaryType: value as MilitaryEventType,
      militaryTypeError: undefined
    });
  }

  handleMilitaryArmyChanged = (value: string) => {
    this.setState({
      militaryArmy: value,
      militaryArmyError: undefined
    });
  }

  handleMilitaryRegimentChanged = (militaryRegiment: string) => {
    this.setState({
      militaryRegiment,
      militaryRegimentError: undefined
    });
  }

  handleMilitaryUnitNrChanged = (militaryUnitNr: string) => {
    this.setState({
      militaryUnitNr,
      militaryUnitNrError: undefined
    });
  }

  handleMilitaryUnitChanged = (militaryUnit: string) => {
    this.setState({
      militaryUnit,
      militaryUnitError: undefined
    });
  }

  handleMilitaryUnitSubChanged = (militaryUnitSub: string) => {
    this.setState({
      militaryUnitSub,
      militaryUnitSubError: undefined
    });
  }

  handleMilitaryRankChanged = (militaryRank: string) => {
    this.setState({
      militaryRank,
      militaryRankError: undefined
    });
  }

  handleMilitaryServiceNumberChanged = (militaryServiceNumber: string) => {
    this.setState({
      militaryServiceNumber,
      militaryServiceNumberError: undefined
    });
  }

  handleJailedTypeChanged = (value: string) => {
    this.setState({
      jailedType: value as JailedType,
      jailedTypeError: undefined
    });
  }

  handleInjuredWoundChanged = (injuredWound: string) => {
    this.setState({
      injuredWound,
      injuredWoundError: undefined
    });
  }

  handleInjuredCauseChanged = (injuredCause: string) => {
    this.setState({
      injuredCause,
      injuredCauseError: undefined
    });
  }

  handleDiedReasonChanged = (diedReason: string) => {
    this.setState({
      diedReason,
      diedReasonError: undefined
    });
  }

  handleMemoratedMemorialChanged = async (memorial: IMemorial) => {

    let place = memorial.place_id ? await api.getPlace(memorial.place_id) : undefined
    this.setState({
      memoratedMemorial: memorial,
      memoratedMemorialError: undefined,
      memoratedPlace: place
    });
  }

  handleMemoratedRefChanged = (memoratedRef: string) => {
    this.setState({
      memoratedRef,
      memoratedRefError: undefined
    });
  }

  handleMemoratedKindChanged = (value: string) => {
    this.setState({
      memoratedKind: value as MemoratedKind,
      memoratedKindError: undefined
    });
  }

  handleDecoratedDecorationChanged = (decoratedDecoration: string) => {
    this.setState({
      decoratedDecoration,
      decoratedDecorationError: undefined
    });
  }

  handleDecoratedCitationChanged = (decoratedCitation: string) => {
    this.setState({
      decoratedCitation,
      decoratedDecorationError: undefined
    });
  }

  handleCWXRMBatchChanged = (cwxrmBatch: string) => {
    this.setState({
      cwxrmBatch,
      cwxrmBatchError: undefined
    });
  }

  handleCWXRMCSVIdChanged = (cwxrmCsvId: string) => {
    this.setState({
      cwxrmCsvId,
      cwxrmCsvIdError: undefined
    });
  }

  handleCWXRMFirstNameChanged = (cwxrmFirstName: string) => {
    this.setState({
      cwxrmFirstName,
      cwxrmFirstNameError: undefined
    });
  }

  handleCWXRMLastNameChanged = (cwxrmLastName: string) => {
    this.setState({
      cwxrmLastName,
      cwxrmLastNameError: undefined
    });
  }

  handleCWXRMMailChanged = (cwxrmMail: string) => {
    this.setState({
      cwxrmMail,
      cwxrmMailError: undefined
    });
  }

  handleCWXRMGroupChanged = (cwxrmGroup: string) => {
    this.setState({
      cwxrmGroup,
      cwxrmGroupError: undefined
    });
  }

  handleClickedCancel = () => {
    this.close();
  }

  handleClickedSave = () => {
    const event = this.toEvent();
    api.validateEvent(event, event._id ? 'update' : 'create')
      .then(result => {
        if (result.status !== 'OK') {
          const errors = result.data || {};
          this.setState({
            startDateError: getErrorDescription(errors.start_day),
            endDateError: getErrorDescription(errors.end_day),
            placeError: getErrorDescription(errors.place_id),
            addressError: getErrorDescription(errors.topo),
            commentsError: getErrorDescription(errors.description),

            schoolNameError: getErrorDescription(errors.school_name),
            schoolDirectionError: getErrorDescription(errors.school_direction),
            whereTypeError: getErrorDescription(errors.where_reason),
            workCompanyNameError: getErrorDescription(errors.work_company_name),
            workProfessionError: getErrorDescription(errors.work_profession),
            militaryTypeError: getErrorDescription(errors.enlisted_reason),
            militaryArmyError: getErrorDescription(errors.enlisted_army),
            militaryRegimentError: getErrorDescription(errors.enlisted_regt),
            militaryUnitError: getErrorDescription(errors.enlisted_unit),
            militaryUnitNrError: getErrorDescription(errors.enlisted_nr),
            militaryUnitSubError: getErrorDescription(errors.enlisted_sub),
            militaryRankError: getErrorDescription(errors.enlisted_rank),
            militaryServiceNumberError: getErrorDescription(errors.enlisted_number),
            jailedTypeError: getErrorDescription(errors.jailed_type),
            injuredWoundError: getErrorDescription(errors.injured_wound),
            injuredCauseError: getErrorDescription(errors.injured_cause),
            diedReasonError: getErrorDescription(errors.reason),
            memoratedMemorialError: getErrorDescription(errors.memorial_id),
            memoratedRefError: getErrorDescription(errors.memorial_ref),
            memoratedKindError: getErrorDescription(errors.memorial_kind),
            decoratedDecorationError: getErrorDescription(errors.decoration),
            decoratedCitationError: getErrorDescription(errors.citation),
            cwxrmBatchError: getErrorDescription(errors.batch),
            cwxrmCsvIdError: getErrorDescription(errors.csv_id),
            cwxrmFirstNameError: getErrorDescription(errors.first_name),
            cwxrmLastNameError: getErrorDescription(errors.last_name),
            cwxrmMailError: getErrorDescription(errors.email),
            cwxrmGroupError: getErrorDescription(errors.group)
          });
          return;
        }
        this.resolve(event);
      });
  }

  getEventLeftFields() {
    const { event } = this.props;
    const {
      startDate,
      startDateError,
      endDate,
      endDateError,
      place,
      placeError,
      address,
      addressError,

      schoolName,
      schoolNameError,
      schoolDirection,
      schoolDirectionError,

      workCompanyName,
      workCompanyNameError,
      workProfession,
      workProfessionError,

      whereType,
      whereTypeError,

      militaryType,
      militaryTypeError,
      militaryArmy,
      militaryArmyError,
      militaryRegiment,
      militaryRegimentError,
      militaryUnitNr,
      militaryUnitNrError,
      militaryUnit,
      militaryUnitError,
      militaryUnitSub,
      militaryUnitSubError,
      militaryRank,
      militaryRankError,
      militaryServiceNumber,
      militaryServiceNumberError,

      jailedType,
      jailedTypeError,

      injuredWound,
      injuredWoundError,
      injuredCause,
      injuredCauseError,

      diedReason,
      diedReasonError,

      memoratedMemorial,
      memoratedMemorialError,
      memoratedRef,
      memoratedRefError,
      memoratedKind,
      memoratedKindError,

      decoratedDecoration,
      decoratedDecorationError,
      decoratedCitation,
      decoratedCitationError,

      cwxrmBatch,
      cwxrmBatchError,
      cwxrmCsvId,
      cwxrmCsvIdError,
      cwxrmFirstName,
      cwxrmFirstNameError,
      cwxrmLastName,
      cwxrmLastNameError,
      cwxrmMail,
      cwxrmMailError,
      cwxrmGroup,
      cwxrmGroupError,
    } = this.state;
    const { type } = event;

    const result: JSX.Element[] = [];
    if (type === PersonEventType.Decorated
        || type === PersonEventType.CWXRM
        || type === PersonEventType.Military
        || type === PersonEventType.Born
        || type === PersonEventType.Died) {
      // only start date
      result.push(
        <DateInput
          key='startDate'
          name='date'
          label={T('event.date')}
          value={startDate}
          onChange={this.handleStartDateChanged}
          error={startDateError}
          readOnly={type === PersonEventType.Born || type === PersonEventType.Died}
        />
      );
    } else {
      // start and end date
      result.push(
        <DateInput
          key='startDate'
          name='start-date'
          label={T('event.startDate')}
          value={startDate}
          onChange={this.handleStartDateChanged}
          error={startDateError}
        />
      );
      result.push(
        <DateInput
          key='endDate'
          name='end-date'
          label={T('event.endDate')}
          value={endDate}
          onChange={this.handleEndDateChanged}
          error={endDateError}
        />
      );
    }
    if (type !== PersonEventType.Memorated
      && type !== PersonEventType.Decorated)
    result.push(
      <PlaceInput
        key='place'
        name='place'
        label={T('event.place')}
        value={place}
        onChange={this.handlePlaceChanged}
        error={placeError}
        target={'window'}
      />
    );
    if ( type === PersonEventType.Born
      || type === PersonEventType.Where
      || type === PersonEventType.Military
      || type === PersonEventType.Jailed
      || type === PersonEventType.Injured
      || type === PersonEventType.Hospitalized
      || type === PersonEventType.Died) {
      result.push(
        <TextInput
          key='address'
          name='address'
          label={T('event.address')}
          value={address}
          onChange={this.handleAddressChanged}
          error={addressError}
        />
      );
    }
    if (type === PersonEventType.School) {
      result.push(
        <TextInput
          key='schoolName'
          name='school-name'
          label={T('event.schoolName')}
          value={schoolName}
          onChange={this.handleSchoolNameChanged}
          error={schoolNameError}
        />,
        <TextInput
          key='schoolDirection'
          name='school-direction'
          label={T('event.schoolDirection')}
          value={schoolDirection}
          onChange={this.handleSchoolDirectionChanged}
          error={schoolDirectionError}
        />
      );
    }
    if (type === PersonEventType.Where) {
      result.push(
        <SelectInput
          key='whereType'
          name='where-type'
          label={T('event.whereType')}
          value={whereType}
          onChange={this.handleWhereTypeChanged}
          optionElements={this.whereTypeOptions}
          error={whereTypeError}
        />
      );
    }
    if (type === PersonEventType.Work) {
      result.push(
        <TextInput
          key='workCompanyName'
          name='company-name'
          label={T('event.workCompanyName')}
          value={workCompanyName}
          onChange={this.handleWorkCompanyNameChanged}
          error={workCompanyNameError}
        />,
        <TextInput
          key='workProfession'
          name='work-profession'
          label={T('event.workProfession')}
          value={workProfession}
          onChange={this.handleWorkProfessionChanged}
          error={workProfessionError}
        />
      );
    }
    if (type === PersonEventType.Military) {
      result.push(
        <SelectInput
          key='militaryType'
          name='military-type'
          label={T('event.militaryType')}
          value={militaryType}
          onChange={this.handleMilitaryTypeChanged}
          optionElements={this.militaryTypeOptions}
          error={militaryTypeError}
        />,
        <SelectInput
          key='militaryArmy'
          label={T('event.militaryArmy')}
          name='military-army'
          value={militaryArmy}
          onChange={this.handleMilitaryArmyChanged}
          optionElements={[
            <option key='none' value=''>{T('army.selectOne')}</option>,
            ...ARMY_OPTIONS
          ]}
          error={militaryArmyError}
        />,
        <RegimentInputGroup
          key='regiment'
          label={T('event.militaryRegiment')}
          value={militaryRegiment}
          onChange={this.handleMilitaryRegimentChanged}
          error={militaryRegimentError}
          army={militaryArmy}
        />,
        <UnitInputGroup
          key='unit'
          label={T('event.militaryUnitAndNr')}
          unitNrValue={militaryUnitNr}
          onChangeUnitNr={this.handleMilitaryUnitNrChanged}
          unitValue={militaryUnit}
          onChangeUnit={this.handleMilitaryUnitChanged}
          error={militaryUnitError || militaryUnitNrError}
          army={militaryArmy}
          regiment={militaryRegiment}
        />,
        <TextInput
          key='sub'
          name='military-unit-sub'
          label={T('event.militaryUnitSub')}
          value={militaryUnitSub}
          onChange={this.handleMilitaryUnitSubChanged}
          error={militaryUnitSubError}
        />,
        <TextInput
          key='rank'
          name='military-rank'
          label={T('event.militaryRank')}
          value={militaryRank}
          onChange={this.handleMilitaryRankChanged}
          error={militaryRankError}
        />,
        <TextInput
          key='serviceNumber'
          name='military-service-number'
          label={T('event.militaryServiceNumber')}
          value={militaryServiceNumber}
          onChange={this.handleMilitaryServiceNumberChanged}
          error={militaryServiceNumberError}
        />
      );
    }
    if (type === PersonEventType.Jailed) {
      result.push(
        <SelectInput
          key='jailedType'
          name='jailed-type'
          label={T('event.jailedType')}
          value={jailedType}
          onChange={this.handleJailedTypeChanged}
          optionElements={this.jailedOptions}
          error={jailedTypeError}
        />
      );
    }
    if (type === PersonEventType.Injured) {
      result.push(
        <TextInput
          key='injuredWound'
          name='injured-wound'
          label={T('event.injuredWound')}
          value={injuredWound}
          onChange={this.handleInjuredWoundChanged}
          error={injuredWoundError}
        />,
        <TextInput
          key='injuredCause'
          label={T('event.injuredCause')}
          name='injured-cause'
          value={injuredCause}
          onChange={this.handleInjuredCauseChanged}
          error={injuredCauseError}
        />
      );
    }
    if (type === PersonEventType.Died) {
      result.push(
        <TextInput
          key='diedReason'
          name='died-reason'
          label={T('event.diedReason')}
          value={diedReason}
          onChange={this.handleDiedReasonChanged}
          error={diedReasonError}
        />
      );
    }
    if (type === PersonEventType.Memorated) {
      result.push(
        <MemorialInput
          label={T('event.memoratedMemorial')}
          value={memoratedMemorial}
          onChange={this.handleMemoratedMemorialChanged}
          error={memoratedMemorialError}
        />,
        <TextInput
          name='memorated-ref'
          label={T('event.memoratedRef')}
          value={memoratedRef}
          onChange={this.handleMemoratedRefChanged}
          error={memoratedRefError}
        />
      );
      if (memoratedMemorial?.type === MemorialType.Cemetery) {
        result.push(
          <SelectInput
            name='memorated-kind'
            label={T('event.memoratedKind')}
            value={memoratedKind}
            onChange={this.handleMemoratedKindChanged}
            optionElements={this.memoratedKindOptions}
            error={memoratedKindError}
          />
        );
      }
    }
    if (type === PersonEventType.Decorated) {
      result.push(
        <TextInput
          name='decorated-decoration'
          label={T('event.decoratedDecoration')}
          value={decoratedDecoration}
          onChange={this.handleDecoratedDecorationChanged}
          error={decoratedDecorationError}
        />,
        <TextInput
          name='decorated-citation'
          label={T('event.decoratedCitation')}
          value={decoratedCitation}
          onChange={this.handleDecoratedCitationChanged}
          error={decoratedCitationError}
        />
      );
    }
    if (type === PersonEventType.CWXRM) {
      result.push(
        <TextInput
          key='cwxrmBatch'
          name='cwxrm-batch'
          label={T('event.cwxrmBatch')}
          value={cwxrmBatch}
          onChange={this.handleCWXRMBatchChanged}
          error={cwxrmBatchError}
          readOnly={true}
        />,
        <TextInput
          key='cwxrmCsvId'
          name='cwxrm-csv-id'
          label={T('event.cwxrmCsvId')}
          value={cwxrmCsvId}
          onChange={this.handleCWXRMCSVIdChanged}
          error={cwxrmCsvIdError}
          readOnly={true}
        />,
        <TextInput
          key='cwxrmFirstName'
          name='cwxrm-first-name'
          label={T('event.cwxrmFirstName')}
          value={cwxrmFirstName}
          onChange={this.handleCWXRMFirstNameChanged}
          error={cwxrmFirstNameError}
        />,
        <TextInput
          key='cwxrmLastName'
          name='cwxrm-last-name'
          label={T('event.cwxrmLastName')}
          value={cwxrmLastName}
          onChange={this.handleCWXRMLastNameChanged}
          error={cwxrmLastNameError}
        />,
        <TextInput
          key='cwxrmMail'
          name='cwxrm-mail'
          label={T('event.cwxrmMail')}
          value={cwxrmMail}
          onChange={this.handleCWXRMMailChanged}
          error={cwxrmMailError}
        />,
        <TextInput
          key='cwxrmGroup'
          name='cwxrm-group'
          label={T('event.cwxrmGroup')}
          value={cwxrmGroup}
          onChange={this.handleCWXRMGroupChanged}
          error={cwxrmGroupError}
        />
      );
    }
    return result;
  }

  getEventRightFields() {
    const { comments } = this.state;
    const result: JSX.Element[] = [];
    result.push(
      <TextareaInput
        key='comments'
        name='comments'
        label={T('event.comments')}
        value={comments}
        onChange={this.handleCommentsChanged}
        rows={8}
      />
    );
    return result;
  }

  renderModal() {
    const { event } = this.props;

    return <>
      <Modal.Header closeButton className={styles.eventHeader}>
        <Modal.Title>
          <i className={getEventIcon(event.type)} />
          {' '}
          {getEventTypeName(event.type)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col lg={6}>
              {this.getEventLeftFields()}
            </Col>
            <Col lg={6}>
              {this.getEventRightFields()}
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <ButtonRowModalFooter>
      <Button variant='secondary' onClick={this.handleClickedCancel}>
          {T('generic.action.cancel')}
        </Button>
        <Button variant='primary' onClick={this.handleClickedSave}>
          {T('generic.action.save')}
        </Button>
      </ButtonRowModalFooter>
    </>;
  }
}
