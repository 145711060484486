import React from 'react';
import $ from 'jquery';
import { PageProps, Page } from '../Page';
import { Icon } from '../../components/Icons';
import { T, plural } from '../../Translate';
import api from '../../api';
import { SpecialFunctionArguments, ValidationErrors, IRepeatableSpecialFunctionInput, SpecialFunctionResult } from '../../models/SpecialFunction';
import { SpecialFunctionForm } from '../SpecialFunctions/SpecialFunctionForm';
import { getEventIcon, PersonEventType, getEventTypeName } from '../../models/PersonEvent';
import { Button } from 'react-bootstrap';
import { FormCard } from '../../components/FormCard';
import { TopTabs } from '../../components/TopTabs';
import { MarkdownView } from '../../components/MarkdownView';
import { SpecialFunctionResultTable } from '../SpecialFunctions/SpecialFunctionResultTable';
import { updatePagesStateAction } from '../../redux/Actions';
import { BottomButtonRow } from '../../components/ButtonRow';
import { useAppSelector, useAppDispatch } from '../../utils/Functional';
import { useFunctionDefinition } from '../../FunctionalAPI';
import ExportFieldModal from './ExportFieldModal';
import { PageID } from '../../utils/PageLinking';

export interface AdvancedSearchStateProps {
  args: SpecialFunctionArguments;
  errors: ValidationErrors;
  result?: SpecialFunctionResult;
  tab: string;
}

function getFilterIcon(type: string): Icon {
  return getEventIcon(type as PersonEventType) || Icon.ListUl;
}

function getFilterTitle(type: string): string {
  const name = type === 'event'
    ? T('page.advancedSearch.addFilter.event')
    : getEventTypeName(type as PersonEventType);

  return T('page.advancedSearch.addFilter.title', { type: name });
}

const NoSelection: string[] = [];
const NoSelector = () => {};

export default (props: PageProps) => {
  const { history, loading, modals, via } = props;

  const dispatch = useAppDispatch();
  const pageState = useAppSelector(state => state.pagesState.advancedSearch);
  const definition = useFunctionDefinition('FindPersonAdvancedInternal');
 
  const { args, result, errors, tab } = pageState;

  const updatePageState = (updates: Partial<AdvancedSearchStateProps>) => {
    dispatch(updatePagesStateAction('advancedSearch', updates));
  };

  const handleClickedReset = () => updatePageState({ args: {} });

  const handleClickedExecute = async () => {
    const result = await loading.loading(api.callFunction('FindPersonAdvancedInternal', args), plural('person'));
    updatePageState({ result, tab: 'results' });
  };

  const handleTabChanged = (tab: string) => updatePageState({ tab });

  const handleClickedCSV = async () => {
    const fields = await modals.show(props => <ExportFieldModal {...props} />);
    if (fields === undefined)
      return; // cancel
    
    const urlParams = {
      format: 'csvfile',
      method: 'callFunctionCsv',
      params: JSON.stringify({
        name: 'FindPersonAdvancedExtended',
        token: api.token,
        arguments: Object.assign({}, args, { fields })
      })
    };
    var url = api.url + '?' + $.param(urlParams);
    window.open(url, '_blank');
  };

  const renderRepeatableSelector = (
    field: IRepeatableSpecialFunctionInput,
    onClickedAdd: (type: string) => void
  ) => {
    return <>
      <p>{T('page.advancedSearch.addFilter.info')}</p>
      <div>
        {field.types.map(type => (
          <Button
            key={type.name}
            title={getFilterTitle(type.name)}
            onClick={() => onClickedAdd(type.name)}
            style={{ marginRight: 5 }}
          >
            <i className={getFilterIcon(type.name)} />
          </Button>
        ))}
      </div>
    </>;
  }

  const renderParametersTab = () => (
    <FormCard
      icon={Icon.Gears}
      title={T('page.advancedSearch.parameters')}
      style={{ marginTop: '1em' }}
    >
      {definition && (
        <SpecialFunctionForm
          args={args}
          func={definition}
          errors={errors}
          onArgumentsChange={args => updatePageState({ args })}
          renderRepeatable={renderRepeatableSelector}
        />
      )}
      <BottomButtonRow>
        <Button onClick={handleClickedReset}>
          {T('generic.action.reset')}
        </Button>
        <Button onClick={handleClickedExecute}>
          {T('generic.action.execute')}
        </Button>
      </BottomButtonRow>
    </FormCard>
  );

  const renderResultsTab = () => {
    const suffix = (
        result
        && result.type === 'table'
        && (' (' + (result.total > result.data.length ? result.data.length + '/' : '') + result.total + ')')
      ) || '';
    return (
      <FormCard icon={Icon.Users} title={T('page.advancedSearch.results') + suffix}>
        {renderResultsTabContent()}
      </FormCard>
    );
  };

  const renderResultsTabContent = () => {
    if (!result)
      return <div />;
    
    if (result.type === 'text') {
      return <MarkdownView content={result.content} />;
    } else {
      return <>
        <div style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
          <Button onClick={handleClickedCSV}>CSV</Button>
        </div>
        <SpecialFunctionResultTable result={result} history={history} selection={NoSelection} onSelected={NoSelector} />
      </>;
    }
  }

  return (
    <Page
      id={PageID.AdvancedSearch}
      icon={Icon.Search}
      title={T('page.advancedSearch.title')}
      via={via}
    >
      <TopTabs
        id='advancedSearchTabs'
        activeKey={tab}
        onSelect={handleTabChanged}
        tabs={[
          {
            id: 'parameters',
            title: T('page.advancedSearch.parameters'),
            icon: Icon.Gears,
            content: renderParametersTab
          }, {
            id: 'results',
            title: T('page.advancedSearch.results'),
            icon: Icon.Users,
            content: renderResultsTab,
            badgeContent: result && result.type === 'table' ? () => result.total.toString() : undefined
          }
        ]}
      />
    </Page>
  );
}
