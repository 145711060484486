import React from 'react';
import { T, Language } from '../../Translate';
import API from '../../api';
import { History } from 'history';
import { PageInfo } from '../../pages/Page';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/icon.png';

interface NoLoginAppBarProps {
  history: History;
  loggedIn: boolean;
  page: PageInfo;
  onLanguageChanged: (language: Language) => void;
}
export default class NoLoginAppBar extends React.Component<NoLoginAppBarProps> {
  getLanguageListener(language: Language) {
    return (e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      this.props.onLanguageChanged(language);
    }
  }

  handleClickedLogout = () => {
    API.logout();
    this.props.history.push('/login');
  }

  handleClickedUserSettings = () => {
    
  }

  isLoggedIn() {
    return true;
  }

  render() {
    // use either 'container' or 'container-fluid'; container-fluid will make
    // the navbar fill the whole page width
    return (
      <nav className='navbar navbar-expand-lg'>
        <div className='container'>
          <div className='navbar-wrapper'>
            <div className='logo'>
              <Link className='simple-text logo-mini' to='/'>
                <img src={logo} alt='' />
              </Link>
              <Link className='simple-text logo-normal' to='/'>
                {T('app.title')}
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
