import React from 'react';
import { TextStyles } from "../../config/styles";
import { Document, getDocumentCategoryName } from "../../models/Document";
import { Badge } from "react-bootstrap";


interface CategoryCellProps {
  document: Document
}

export const CategoryCell = (props: CategoryCellProps) => {
  const { document } = props;

  return (
    <div style={{display: "flex", flexDirection: "row", justifyContent:'flex-start', alignItems:'baseline'}}>
      <span style={{...TextStyles.text.large}}>{getDocumentCategoryName(document.category)}</span><br/>
      {document.parentId &&
        <Badge style={{marginLeft: '0.25rem'}} variant={'secondary'}>{document.parentId ? 'SUB' : ''}</Badge>
      }
    </div>
  );
}
