import React from 'react';
import CompactCheckboxInput from '../../../components/inputs/CompactCheckboxInput';
import { getEventNameForPlace, PersonEvent } from '../../../models/PersonEvent';
import { IPlace, Place } from '../../../models/Place';

interface EventCheckboxProps {
  event: PersonEvent;
  linkedEventIds: string[];
  readOnly: boolean;
  onChanged: (checked: boolean, eventId: string) => void;
}

export default (props: EventCheckboxProps) => {
  const { event, linkedEventIds, readOnly, onChanged } = props;
  const place = new Place(event.extend_place as IPlace).describe();
  return (
    <CompactCheckboxInput
      label={getEventNameForPlace(event.type, place)}
      value={linkedEventIds.includes(event._id)}
      onChange={checked => onChanged(checked, event._id)}
      readOnly={readOnly}
      labelStyle={{ fontSize: '0.875rem' }}
    />
  );
}
